import BCard from "components/B/card";
import React, { useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	AccurateService,
	CommonService,
	HubService,
	OrderService
} from "api/axios";
import { Loading, LocalDialog, Spacer } from "components";
import { Button, Card, CardBody, Table } from "reactstrap";
import { getPriceFormatted } from "helper/product";
import { ChevronLeft } from "react-bootstrap-icons";
import { formatComma, handleResponseFile } from "helper";
import BStatus from "components/B/status";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import { getRouteName, routeMapping } from "routes";
import { MuiFileInput } from "mui-file-input";
import { LoadingButton } from "@mui/lab";
import { useListSearchParams } from "hooks";
import ModalEditSKU from "./modalEditSKU";
import UpdateSaleOrder from "./UpdateSaleOrder";
import ModalApproveSO from "./ModalApproveSO";

const SODetail = () => {
	const location = useLocation();
	const permission = useAppSelector((state) => state.user.permission);
	const soPermission = useMemo(() => {
		const routeName = getRouteName(location.pathname, routeMapping);

		if (routeName) {
			return permission[routeName];
		}
		return {};
	}, [location, permission]);

	//accurate disable
	const { isAccurateDisabled: accurateStatusDisabled } = useAppSelector(
		(state) => state.setting
	);
	const isAccurateDisabled =
		process.env.REACT_APP_IS_ACCURATE_DISABLED === "true" ||
		accurateStatusDisabled;

	const navigate = useNavigate();
	const params = useParams();
	const id = params?.id;
	const { page } = useListSearchParams();
	const [projectDetails, setProjectDetails] = React.useState<any[]>([]);
	const [files, setFiles] = React.useState<any[]>([]);
	const [uploadFile, setUploadFile] = React.useState<File>();
	const [summary, setSummary] = React.useState<any[]>([]);
	const [updates, setUpdates] = React.useState<any[]>([]);
	const [sphNumber, setSphNumber] = React.useState<string>("");
	const [PoProducts, setPoProducts] = React.useState<any[]>([]);
	const [status, setStatus] = React.useState<string>("");
	const [resData, setResData] = React.useState<ApiPO | null>(null);
	const [signedSo, setSignedSo] = React.useState<
		{
			name: string;
			type: string;
			data: { id: string; name: string; type: string; url: string };
		}[]
	>();
	const [poId, setPoId] = React.useState<string>("");
	const [BatchingPlantSO, setBatchingPlantSO] = React.useState("");
	const [isEditProduct, setIsEditProduct] = React.useState(false);
	const [isApproveSO, setIsApproveSO] = React.useState(false);
	const [poNumber, setPoNumber] = React.useState<string>("");
	const [shippingAddress, setShippingAddress] = React.useState<string>("");
	const [accurateAddress, setAccureateAddress] = React.useState<string>("");
	const [mainPic, setMainPic] = React.useState<string>("");
	const [phoneMainPic, setPhoneMainPic] = React.useState<string>("");
	const [accurateBranchId, setAccurateBranchId] = React.useState<number>(0);
	const [accurateCustomerNo, setAccurateCustomerNo] =
		React.useState<number>();
	const [quantityPO, setQuantityPO] = React.useState<any>();
	const [skuNames, setSkuNames] = React.useState<any>();
	const [requestedProductsId, setRequestedProductsId] = React.useState<any>();
	const [defaultSKU, setDefaultSKU] = React.useState<any>();
	const [customerId, setCustomerId] = React.useState<string>("");

	const [isReject, setIsReject] = React.useState({ isOpen: false });

	const dispatch = useAppDispatch();

	const { isLoading, refetch, data } = useQuery<ApiOnePO, ApiError>(
		["query-purchase-orders"],
		async () => {
			return await OrderService.getOnePO(id ?? "");
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const defaultSKU = res.data.PoProducts.map((item) => {
					return {
						labelStr: item?.ReqProduct?.SKU?.sku
							? `${item?.ReqProduct?.SKU?.sku} - ${item?.ReqProduct?.SKU?.technicalDescription}`
							: item?.ReqProduct?.SKU?.sku,
						valueStr: item?.ReqProduct?.SKU?.sku
					};
				});
				const totalQuantityPO = res.data.PoProducts.map(
					(item) => item.requestedQuantity
				);
				const sumQuantity = totalQuantityPO.reduce(
					(acc, curr) => acc + curr,
					0
				);
				const isCompany = !!res?.data?.Project?.companyName;
				const isCustomer = !!res?.data?.Project?.Customer;
				setSphNumber(res.data.number);
				setStatus(res.data.status);
				setResData(res.data);
				setPoNumber(res.data?.customerNumber);
				setAccureateAddress(
					res?.data?.Project?.addressAccurate as string
				);
				setShippingAddress(
					res?.data?.Project?.ShippingAddress?.line1 as string
				);
				setMainPic(res?.data?.Project.mainPic.name);
				setPhoneMainPic(res?.data?.Project.mainPic.phone);
				setAccurateBranchId(res?.data?.branchAccurateId);
				setAccurateCustomerNo(
					res?.data?.Project?.Customer?.customerAccurateId
				);
				setQuantityPO(sumQuantity);
				setPoId(res?.data?.id);
				setCustomerId(res?.data?.Project?.Customer?.id as string);
				try {
					const PoProducts = res?.data?.PoProducts?.map((el) => {
						const totalPricePoProduct =
							el.requestedQuantity * el.ReqProduct.offeringPrice;
						return {
							...el,
							totalPricePoProduct
						};
					});
					const SkuNames = res?.data?.PoProducts.map((el) => {
						return el.ReqProduct?.SKU?.sku;
					});
					const reqProductsId = res?.data?.PoProducts.map((el) => {
						return el.ReqProduct.id;
					});
					setPoProducts(PoProducts);
					setSkuNames(SkuNames);

					setRequestedProductsId(reqProductsId);
					setDefaultSKU(defaultSKU);
				} catch (e) {
					console.log("error:", e);
				}

				try {
					const projectDetails = [
						(!isCompany && !isCustomer) ||
						(isCompany && !isCustomer)
							? {
									type: "noCustomer"
							  }
							: null,
						isCustomer && {
							name: "Tipe Pelanggan",
							type: "customerTypeChip",
							data:
								res?.data?.Project.Customer?.type === "COMPANY"
									? "PERUSAHAAN"
									: res?.data?.Project.Customer?.type
						},
						isCompany && !isCustomer
							? {
									name: "Tipe Pelanggan",
									type: "customerType",
									data: "PERUSAHAAN"
							  }
							: null,
						isCustomer
							? {
									name: "Nama Pelanggan",
									data:
										res?.data?.Project.Customer
											?.displayName ?? "-"
							  }
							: null,
						{
							name: "Nama Proyek",
							data: res?.data.Project.name
						},
						isCompany
							? {
									name: "Perusahaan",
									data: res?.data?.Project?.Company
										?.displayName
							  }
							: null,
						{
							name: "PIC Utama",
							type: "pic",
							data: {
								name: res?.data.Project.mainPic.name,
								position: res?.data?.Project?.mainPic?.position,
								phone: res?.data?.Project?.mainPic?.phone,
								email: res?.data?.Project?.mainPic?.email
							}
						},
						res?.data?.Project?.Pics.length > 1 && {
							name: "PICs",
							type: "pics",
							data: {
								pics: res?.data?.Project?.Pics,
								mainPIC: res?.data?.Project?.mainPic
							}
						},
						{
							name: "Alamat Penagihan",
							type: "address",
							data: {
								line1: res?.data?.Project?.BillingAddress
									?.line1,
								lat: res?.data?.Project?.BillingAddress?.lat,
								lon: res?.data?.Project?.BillingAddress?.lon
							}
						},
						{
							name: "Alamat Pengiriman",
							type: "address",
							data: {
								line1: res?.data?.Project?.ShippingAddress
									?.line1,
								lat: res?.data?.Project?.ShippingAddress?.lat,
								lon: res?.data?.Project?.ShippingAddress?.lon
							}
						}
					];
					setProjectDetails(projectDetails);
				} catch (e) {
					//
				}

				try {
					const files =
						res?.data?.PurchaseOrderDocs?.map((v, idx) => {
							return {
								name: v?.type,
								type: "file",
								data: v?.File
							};
						}) ?? [];
					const so = res.data.PurchaseOrderDocs.filter(
						(el) => el.type === "BRIK_SIGNED"
					).map((el) => ({
						name: el?.type,
						type: "file",
						data: el?.File
					}));
					setSignedSo(so);
					setFiles(files);
				} catch (e) {
					//
				}

				try {
					const totalPriceProduct = res?.data?.PoProducts?.map(
						(el) => {
							return (
								el.ReqProduct.offeringPrice *
								el.requestedQuantity
							);
						}
					);
					const totalDeliveryPrice =
						res?.data?.QuotationLetter?.QuotationRequest
							?.totalDeliveryPrice;
					const totalPriceSum = totalPriceProduct.reduce(
						(acc, currentValue) => {
							return acc + currentValue;
						},
						0
					);

					const summary = [
						{
							name: "Nama Sales",
							type: "text",
							data: res?.data?.Project?.User?.fullName
						},
						{
							name: "Tol",
							type: "boolean",
							data: res?.data?.QuotationLetter?.QuotationRequest
								?.viaTol
						},
						{
							name: "Tipe Pembayaran",
							type: "chips",
							data: [
								res?.data?.QuotationLetter?.QuotationRequest
									?.paymentType == "CREDIT"
									? {
											text: "Credit",
											color: "green",
											textColor: "white"
									  }
									: {
											text: "Cash",
											color: "blue",
											textColor: "white"
									  }
							]
						},
						res?.data?.isExpired
							? {
									name: "Tanggal Kadaluwarsa",
									type: "date",
									data: new Date()
							  }
							: null,
						res?.data?.QuotationLetter?.QuotationRequest?.distance
							? {
									name: "Jarak",
									data: `${res?.data?.QuotationLetter?.QuotationRequest?.distance} km`
							  }
							: null,
						{
							type: "line"
						},
						totalDeliveryPrice
							? {
									name: "Subtotal",
									type: "price",
									data: res?.data?.totalPrice
							  }
							: null,
						totalDeliveryPrice
							? {
									name: "Pengiriman (Kuantitas dibawah 7m3)",
									type: "price",
									data: totalDeliveryPrice
							  }
							: null,
						totalDeliveryPrice
							? {
									name: "Jumlah Keseluruhan",
									type: "price",
									data:
										res?.data?.totalPrice +
										(totalDeliveryPrice ?? 0),
									format: "bold"
							  }
							: {
									name: "Jumlah",
									type: "price",
									data: totalPriceSum,
									format: "bold"
							  }
					];
					setSummary(summary);

					setBatchingPlantSO(res?.data?.batchingPlantId as string);

					// const npwp = res.data.
					const updates = [
						{
							name: "Foto KTP",
							type: "photo",
							data: res.data.Project.ProjectDocs.find(
								(el) => el.Document.name === "Foto KTP"
							)
						},
						{
							name: "Foto NPWP",
							type: "photo",
							data: res.data.Project.ProjectDocs.find(
								(el) => el.Document.name === "Foto NPWP"
							)
						}
					];

					setUpdates(updates);
				} catch (e) {
					//
				}
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const [localDialog, setLocalDialog] = React.useState({
		isOpen: false,
		onClose: () => setLocalDialog({ ...localDialog, isOpen: false }),
		onOpen: () => setLocalDialog({ ...localDialog, isOpen: true }),
		isOpenSO: false,
		onCloseSO: () => setLocalDialog({ ...localDialog, isOpenSO: false }),
		onOpenSO: () => setLocalDialog({ ...localDialog, isOpenSO: true }),
		isOpenSoNumber: false,
		onCloseSoNumber: () =>
			setLocalDialog((prev) => ({ ...prev, isOpenSoNumber: false })),
		onOpenSoNumber: () =>
			setLocalDialog((prev) => ({ ...prev, isOpenSoNumber: true }))
	});

	const { mutate: sendFile } = useMutation<
		ApiResponseFiles,
		ApiError,
		FormData,
		ApiError
	>(async (_files: FormData) => {
		return await CommonService.uploadFile(_files);
	});
	const { mutate, isLoading: loadingSingedSo } = useMutation(
		async (_files: PayloadSignedSO[]) => {
			return await OrderService.uploadSignedSO(id as string, _files);
		},
		{
			onSuccess: (response) => {
				localDialog.onClose();
				refetch();
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const { mutateAsync: uploadFileAws, isLoading: loadingUpload } =
		useMutation<ApiResponseFiles, ApiError, FormData, ApiError>(
			async (_files: FormData) => {
				return await CommonService.uploadFile(_files);
			}
		);

	const { mutateAsync: purchaseOrderFile, isLoading: loadingPut } =
		useMutation(async (files: QuotationFile[]) => {
			return await OrderService.putPurchaseOrder(id as string, files);
		});
	const { mutateAsync: pdfGenerate, isLoading: loadingPdf } = useMutation(
		async () => {
			return await HubService.pdfGenSaleOrder(id as string);
		},
		{
			onSuccess: () => {
				refetch();
			}
		}
	);
	// const { mutateAsync: signSO, isLoading: loadingSignSO } = useMutation(
	// 	async () => {
	// 		return await OrderService.signSO(id as string);
	// 	},
	// 	{
	// 		onSuccess: (response) => {
	// 			refetch();
	// 		},
	// 		onError: (err: ApiError) => {
	// 			dispatch(
	// 				openAlert({
	// 					body: err.response?.data.message,
	// 					color: "danger"
	// 				})
	// 			);
	// 		}
	// 	}
	// );

	async function submitHandler() {
		try {
			const _file = new FormData();
			_file.append("photos", uploadFile as File);
			_file.append("name", "SO");
			const response = await uploadFileAws(_file);
			const data = handleResponseFile(response.data, [
				uploadFile as File
			]);
			const payload = data.map((el) => ({
				...el,
				type: "BRIK"
			}));
			await purchaseOrderFile(payload);
			setUploadFile(undefined);
			refetch();
			localDialog.onClose();
			dispatch(
				openAlert({
					body: "Berhasil Upload SO!",
					color: "success"
				})
			);
		} catch (error) {
			dispatch(
				openAlert({
					body: "Gagal Upload SO",
					color: "danger"
				})
			);
		}
	}

	const onSubmit = () => {
		if (!uploadFile) {
			dispatch(
				openAlert({
					body: "Mohon untuk memilih file terlebih dahulu",
					color: "danger"
				})
			);
			return;
		}

		const _sendFile = new FormData();
		_sendFile.append("photos", uploadFile as File);
		_sendFile.append("name", "SIGNED_SO");
		sendFile(_sendFile, {
			onSuccess: (res) => {
				const data = handleResponseFile(res.data, [uploadFile as File]);
				const payload: PayloadSignedSO[] = data.map((el) => ({
					...el,
					type: "BRIK_SIGNED"
				}));
				mutate(payload);
				setUploadFile(undefined);
				dispatch(
					openAlert({
						body: "SO Berhasil Disetujui",
						color: "success"
					})
				);
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		});
	};

	// get customer accurate detail
	const { data: customerAccurateDetail } = useQuery<any, ApiError>(
		["query-customer-accurate-detail", true],
		async () => {
			return await AccurateService.getCustomerAccurateDetail({
				id: data?.data?.Project?.Customer?.customerAccurateId as number
			});
		},
		{
			enabled: !!data?.data.Project?.Customer?.customerAccurateId,
			onError(err: any) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
			}
		}
	);

	const patchStatus = useMutation(
		async (status: "CONFIRMED" | "DECLINED") => {
			return await OrderService.updatePO(id as string, status);
		},
		{
			onSuccess: (response) => {
				refetch();
				dispatch(
					openAlert({
						body: "Berhasil Merubah Status SO",
						color: "success"
					})
				);
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const handleButton = (status: "CONFIRMED" | "DECLINED") => () => {
		patchStatus.mutate(status);
		setIsReject({ isOpen: false });
	};

	// const handleSignSO = () => () => {
	// 	signSO();
	// 	dispatch(
	// 		openAlert({
	// 			body: "SO Berhasil Disetujui",
	// 			color: "success"
	// 		})
	// 	);
	// };

	if (
		isLoading ||
		patchStatus.isLoading ||
		loadingUpload ||
		loadingSingedSo
	) {
		return <Loading />;
	}

	const handleClickApprove = () => {
		const isEmptySKU = PoProducts.some(
			(product) => product?.ReqProduct?.SKU === null
		);

		if (isEmptySKU) {
			dispatch(
				openAlert({
					body: "Silakan pilih SKU terlebih dahulu",
					color: "danger"
				})
			);
		} else {
			setIsApproveSO(true);
		}
	};

	const handleClickLink = () => {
		const isEmptySKU = PoProducts.some(
			(product) => product?.ReqProduct?.SKU === null
		);
		const quotationAccurateId =
			data?.data.QuotationLetter.accurateQuotationId;

		if (!quotationAccurateId && !isAccurateDisabled) {
			dispatch(
				openAlert({
					body: "Silakan Link SPH dengan Accurate terlebih dahulu",
					color: "danger"
				})
			);
		} else if (isEmptySKU) {
			dispatch(
				openAlert({
					body: "Silakan pilih SKU terlebih dahulu",
					color: "danger"
				})
			);
		} else {
			setIsApproveSO(true);
		}
	};

	return (
		<div className="p-4 pt-6">
			{isEditProduct && data ? (
				<ModalEditSKU
					isOpen={isEditProduct}
					setIsOpen={setIsEditProduct}
					refetch={refetch}
					populatedSKU={data ? defaultSKU : undefined}
				/>
			) : null}

			{isApproveSO ? (
				<ModalApproveSO
					isOpen={isApproveSO}
					setIsOpen={setIsApproveSO}
					refetch={refetch}
					poId={poId}
					poNumber={poNumber}
					shippingAddress={shippingAddress}
					accurateAddress={accurateAddress}
					mainPic={mainPic}
					phoneMainPic={phoneMainPic}
					accurateBranchId={accurateBranchId}
					accurateCustomerNo={accurateCustomerNo}
					customerAccurateId={customerAccurateDetail?.data?.id}
					customerAccurateName={
						(customerAccurateDetail?.data?.name as string) ?? ""
					}
					defaultCustomer={
						customerAccurateDetail?.data
							? {
									id: customerAccurateDetail?.data?.id,
									name: customerAccurateDetail?.data?.name,
									mobilePhone:
										customerAccurateDetail?.data
											?.mobilePhone,
									whatsappNo:
										customerAccurateDetail?.data
											?.whatsappNo,
									customerNo:
										customerAccurateDetail?.data
											?.customerNo,
									email: customerAccurateDetail?.data?.email,
									salesmanNameList:
										customerAccurateDetail?.data
											?.salesmanNameList
							  }
							: undefined
					}
					batchingPlantId={BatchingPlantSO}
					quantity={quantityPO}
					sku={skuNames}
					poProductId={requestedProductsId}
					customerId={customerId}
				/>
			) : null}

			<UpdateSaleOrder
				number={data?.data?.brikNumber as string}
				localDialog={localDialog}
				setLocalDialog={setLocalDialog}
				refetch={refetch}
			/>

			<div className="d-flex flex-row align-items-center mb-2">
				<div
					className="b-back-btn border mr-3"
					onClick={() => {
						navigate(-1);
					}}
				>
					<ChevronLeft />
				</div>
				<h1 className="mb-0 mr-2">{data?.data?.brikNumber}</h1>
				{data?.data?.status !== "DECLINED" && (
					<Button
						size="sm"
						color="warning"
						className="confirm-button decline-button ml-1"
						onClick={() => {
							localDialog.onOpenSoNumber();
						}}
					>
						Ubah Nomor SO
					</Button>
				)}
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						flex: 1
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							gap: "0.5rem"
						}}
					>
						<BStatus>
							{status === "CONFIRMED" ? "Diajukan" : status}
						</BStatus>

						{/* Link to Accurate*/}
						{status === "APPROVED" &&
						!data?.data.saleOrderAccurateId &&
						soPermission &&
						soPermission.update &&
						!isAccurateDisabled ? (
							<div>
								<Button
									size="sm"
									color="success"
									onClick={handleClickLink}
								>
									Link
								</Button>
							</div>
						) : null}
					</div>

					{data?.data?.status !== "DECLINED" && (
						<div className="d-flex align-items-center ml-2">
							<Button
								size="sm"
								// style={{ marginLeft: "470px" }}
								color="primary"
								className="confirm-button approve-button"
								onClick={() => {
									localDialog.onOpenSO();
								}}
							>
								Upload SO
							</Button>

							<div style={{ display: "flex", gap: "0.5rem" }}>
								{/* Approve */}
								{status === "CONFIRMED" &&
								soPermission &&
								soPermission.update ? (
									<div>
										<Button
											size="sm"
											color="success"
											onClick={handleClickApprove}
										>
											Setujui
										</Button>
									</div>
								) : null}

								{/* Reject */}
								{status === "CONFIRMED" &&
								soPermission &&
								soPermission.update ? (
									<div>
										<Button
											size="sm"
											color="danger"
											onClick={() => {
												setIsReject({ isOpen: true });
											}}
										>
											Tolak
										</Button>
									</div>
								) : null}
							</div>

							{status !== "CONFIRMED" &&
							soPermission &&
							soPermission.update ? (
								<LoadingButton
									size="small"
									color="success"
									variant="contained"
									className="confirm-button approve-button"
									onClick={() => {
										pdfGenerate();
									}}
									loading={loadingPdf}
									sx={{
										color: "#fff",
										backgroundColor: "#2dce89",
										borderColor: "#2dce89",
										boxShadow:
											"0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
										borderRadius: "0.375rem"
									}}
								>
									Generate SO
								</LoadingButton>
							) : null}
						</div>
					)}
				</div>
			</div>
			<Card className="">
				<CardBody>
					{data?.data.status !== "DECLINED" && (
						<div className="d-flex justify-content-end">
							<Button
								size="sm"
								color="warning"
								className="confirm-button approve-button"
								onClick={() => {
									setIsEditProduct(true);
								}}
							>
								Pilih SKU
							</Button>
						</div>
					)}

					<Table responsive hover striped>
						<thead>
							<tr>
								<th
									className="border-top-0"
									style={{ textAlign: "left" }}
								>
									No
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "left" }}
								>
									Nama
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "center" }}
								>
									SKU
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "center" }}
								>
									Mutu
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "left" }}
								>
									Slump
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "left" }}
								>
									Metode
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "right" }}
								>
									Harga
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "center" }}
								>
									Kuantitas (m³)
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "right" }}
								>
									Jumlah
								</th>
							</tr>
						</thead>
						<tbody>
							{PoProducts?.map((item, idx) => (
								<tr key={idx}>
									<td style={{ textAlign: "left" }}>
										{idx + 1}
									</td>
									<td style={{ textAlign: "left" }}>
										{item?.ReqProduct?.product?.name ?? "-"}{" "}
										{["B", "D"].includes(
											item?.ReqProduct?.SKU?.sku[8]
										)
											? " - Screening"
											: null}
									</td>
									<td style={{ textAlign: "left" }}>
										{item?.ReqProduct?.SKU?.sku ?? "-"}
									</td>
									<td style={{ textAlign: "left" }}>
										{item?.ReqProduct?.SKU
											?.technicalDescription ?? "-"}
									</td>
									<td style={{ textAlign: "left" }}>
										{/* {item?.ReqProduct?.product?.properties
											?.slump ?? "-"} */}
										{item?.ReqProduct?.SKU?.slump
											? item?.ReqProduct?.SKU?.slump?.split(
													" - "
											  )[1] +
											  " " +
											  item?.ReqProduct?.SKU?.slumpTolerance?.split(
													" - "
											  )[1]
											: item?.ReqProduct
													?.specifications?.[
													"slump-a"
											  ] +
											  " ± " +
											  item?.ReqProduct
													?.specifications?.[
													"slump-b"
											  ]}
									</td>
									<td style={{ textAlign: "left" }}>
										{item?.ReqProduct?.pouringMethod ?? "-"}
									</td>
									<td style={{ textAlign: "right" }}>
										{getPriceFormatted(
											item?.ReqProduct?.offeringPrice,
											"Rp "
										)}
									</td>
									<td style={{ textAlign: "center" }}>
										{item?.requestedQuantity
											? formatComma(
													`${item?.requestedQuantity} M³`
											  )
											: "0 M³"}
									</td>
									<td style={{ textAlign: "right" }}>
										{getPriceFormatted(
											item?.ReqProduct?.offeringPrice *
												item?.requestedQuantity,
											"Rp "
										)}
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</CardBody>
			</Card>
			<div className="d-flex flex-column flex-lg-row">
				<div className="d-flex flex-column mb-3" style={{ flex: 0.7 }}>
					<BCard
						className="mt-3"
						title="Ringkasan SO"
						data={summary}
					/>
					<BCard
						className="mt-3"
						title="Berkas Pelanggan"
						data={updates}
					/>
					<BCard
						className="mt-3"
						title="SO Tanda Tangan"
						data={signedSo}
					/>
				</div>
				<div className="spacer-v" />
				<div className="d-flex flex-column mt-3" style={{ flex: 0.3 }}>
					<BCard title="Rincian Proyek" data={projectDetails} />
					<i className="bi bi-download"></i>
					<BCard className="mt-3" title="Dokumen" data={files} />
				</div>
			</div>

			<LocalDialog
				isOpen={localDialog.isOpen}
				backdrop={"static"}
				header="Upload SO Tanda Tangan"
				// onClick={onSubmit}
				onClose={() => {
					localDialog.onClose();
				}}
			>
				{/* <BForm forms={forms} /> */}
				<MuiFileInput
					onChange={(newFile) => {
						if (newFile && !Array.isArray(newFile)) {
							setUploadFile(newFile);
						} else {
							setUploadFile(undefined);
						}
					}}
					value={uploadFile}
					placeholder="Insert a file"
					multiple={false}
					label="Pilih File"
					inputProps={{
						accept: "application/pdf, image/png, image/jpeg"
					}}
				/>
				<Spacer size="large" />
				<div className="modal-custom-footer">
					<LoadingButton
						color="error"
						onClick={() => {
							setUploadFile(undefined);
							localDialog.onClose();
						}}
						loading={loadingUpload || loadingPut}
						disabled={loadingUpload || loadingPut}
					>
						<span style={{ textTransform: "capitalize" }}>
							Batal
						</span>
					</LoadingButton>
					<LoadingButton
						color="error"
						variant="contained"
						onClick={onSubmit}
						loading={loadingUpload || loadingPut}
						disabled={loadingUpload || loadingPut}
						sx={{ backgroundColor: "red", color: "white" }}
					>
						<span style={{ textTransform: "capitalize" }}>
							Upload{" "}
						</span>
					</LoadingButton>
				</div>
			</LocalDialog>

			<LocalDialog
				isOpen={localDialog.isOpenSO}
				backdrop={"static"}
				header="Upload SO"
				onClose={() => {
					setUploadFile(undefined);
					localDialog.onCloseSO();
				}}
			>
				<div>
					<MuiFileInput
						onChange={(newFile) => {
							if (newFile && !Array.isArray(newFile)) {
								setUploadFile(newFile);
							} else {
								setUploadFile(undefined);
							}
						}}
						value={uploadFile}
						placeholder="Insert a file"
						multiple={false}
						label="Pilih File"
						inputProps={{
							accept: "application/pdf, image/png, image/jpeg"
						}}
					/>
					<Spacer size="large" />
					<div className="modal-custom-footer">
						<LoadingButton
							color="error"
							onClick={() => {
								setUploadFile(undefined);
								localDialog.onCloseSO();
							}}
							loading={loadingUpload || loadingPut}
							disabled={loadingUpload || loadingPut}
						>
							<span style={{ textTransform: "capitalize" }}>
								Batal
							</span>
						</LoadingButton>
						<LoadingButton
							color="error"
							variant="contained"
							onClick={submitHandler}
							loading={loadingUpload || loadingPut}
							disabled={loadingUpload || loadingPut}
							sx={{ backgroundColor: "red", color: "white" }}
						>
							<span style={{ textTransform: "capitalize" }}>
								Upload{" "}
							</span>
						</LoadingButton>
					</div>
				</div>
			</LocalDialog>

			{/* Reject SO  */}
			<LocalDialog
				isOpen={isReject.isOpen}
				backdrop={"static"}
				header="Penolakan Sale Order"
				onClose={() => {
					setIsReject({ isOpen: false });
				}}
			>
				<span>Yakin ingin menolak Sale Order ini?</span>

				<div
					className="modal-custom-footer"
					style={{ marginTop: "1rem" }}
				>
					<LoadingButton
						color="error"
						disabled={isLoading}
						onClick={() => {
							setIsReject({ isOpen: false });
						}}
					>
						<span style={{ textTransform: "capitalize" }}>
							Batal
						</span>
					</LoadingButton>
					<LoadingButton
						color="error"
						variant="contained"
						onClick={handleButton("DECLINED")}
						loading={isLoading}
						disabled={isLoading}
						sx={{ backgroundColor: "red", color: "white" }}
					>
						<span style={{ textTransform: "capitalize" }}>Iya</span>
					</LoadingButton>
				</div>
			</LocalDialog>
		</div>
	);
};

export default SODetail;
