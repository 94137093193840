import { CommonService } from "api/axios";
import { Header, MTable } from "components";
import { MUIDataTableColumnDef } from "mui-datatables";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { openAlert } from "store/features/alert/AlertSlice";
import { useAppDispatch } from "store/hooks";
import { useDebounce } from "use-debounce";
import CreateDispatcher from "./CreateDispatcher";

const dispatcherColumns: MUIDataTableColumnDef[] = [
	{
		name: "name",
		label: "Nama"
	}
	// {
	// 	name: "createdAt",
	// 	label: "Tanggal Dibuat"
	// }
];

export default function Dispatcher() {
	const [page, setPage] = useState(1);
	const [searchQuery, setSearchQuery] = useState<string | null>("");
	const dispatch = useAppDispatch();
	const [searchDispatcher] = useDebounce(searchQuery, 500);
	const { isLoading, refetch, data } = useQuery<ApiDispatchers, ApiError>(
		["query-dispatcher", searchDispatcher, page],
		async () => {
			return await CommonService.getDispatchers({
				page,
				search: searchDispatcher
			});
		},
		{
			enabled: true,
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	return (
		<>
			<Header />
			<Container
				style={{
					position: "relative",
					top: "-10rem"
				}}
				fluid
			>
				<Row>
					<Col>
						<Card>
							<CardBody>
								<CreateDispatcher refetch={refetch} />
								<MTable
									columns={dispatcherColumns}
									tableData={data?.data?.data || []}
									tableTitle=""
									totalItems={data?.data?.totalItems || 0}
									currentPage={data?.data?.currentPage || 0}
									selectableRowsHideCheckboxes
									setPage={(page: number) => {
										setPage(page);
									}}
									onSearchChange={(text: string | null) => {
										setSearchQuery(text);
									}}
									rowsPerPage={10}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
}
