import { getUserTypeColor } from "helper";
import React from "react";
import { Badge } from "reactstrap";

interface IProps {
	children: string;
}

const BUserType = (props: IProps) => {
	const { children } = props;
	return (
		<Badge color="null" style={{ ...getUserTypeColor(children) }}>{children}</Badge>
	);
};

export default BUserType;
