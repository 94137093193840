import React, { CSSProperties } from "react";
import AsyncSelect from "react-select/async";
import Select, { components } from "react-select";

// label: string
// placeholder: string
// type: "number" | "text" | "email" | "date" | "quantity" | "dropdown" | "file" | "switch" | "autocomplete" | "pic"
// value?: string | Date | number | File | Blob | boolean
// onChange?: any
// disable?: boolean
// options?: options[]
// maxQuantity?: number | (() => number)

const groupStyles = {
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between"
};
const groupBadgeStyles: CSSProperties = {
	backgroundColor: "#EBECF0",
	borderRadius: "2em",
	color: "#172B4D",
	display: "inline-block",
	fontSize: 12,
	fontWeight: "normal",
	lineHeight: "1",
	minWidth: 1,
	padding: "0.16666666666667em 0.5em",
	textAlign: "center"
};

// const formatGroupLabel = (data: GroupedOption) => (
// 	<div style={groupStyles}>
// 		<span>{data.label}</span>
// 		<span style={groupBadgeStyles}>{data.options.length}</span>
// 	</div>
// );

const DOInvoice = (props: any) => {
	return (
		<components.MultiValueLabel {...props}>
			{props.data.number}
		</components.MultiValueLabel>
	);
};

const ProjectInvoice = (props: any) => {
	return (
		<components.SingleValue {...props}>
			{props.data.name}
		</components.SingleValue>
	);
};

const DefaultMulti = (props: any) => {
	return <components.MultiValueLabel {...props} />;
};

const BDropdown = (el: ElementForm) => {
	if (el.type === "autocomplete") {
		const component = () => {
			if (el.for === "DOINVOICE") {
				return DOInvoice;
			}

			if (el.for === "PROJECTINVOICE") {
				return ProjectInvoice;
			}

			return DefaultMulti;
		};

		return (
			<Select
				onInputChange={el.loadOption}
				onChange={el.onChange}
				value={el.value as options}
				components={{
					...(component() && {
						MultiValueLabel: component(),
						...(el.for === "PROJECTINVOICE" && {
							SingleValue: component()
						})
					})
				}}
				getOptionLabel={(option: options) => option.labelStr as string}
				getOptionValue={(option: options) => option.valueStr as string}
				options={el.options}
				isMulti={el.multiple}
				onMenuScrollToBottom={el.onReachBottom}
				isLoading={el.isLoading}
				styles={
					{
						// menu: (base) => ({
						// 	...base,
						// 	// backgroundColor: "red",
						// 	height: "10%"
						// })
					}
				}
			/>
		);
	}

	return (
		<Select
			value={el.value as options}
			onChange={el.onChange}
			getOptionLabel={(option: options) => option.labelStr as string}
			getOptionValue={(option: options) => option.valueStr as string}
			options={el.options}
			isClearable={!el.isDropdownNotClearable ? true : false}
			backspaceRemovesValue={true}
			isMulti={el.isMulti}
			placeholder={el.placeholder}
		/>
	);
};

export default BDropdown;
