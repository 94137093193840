import { InventoryService } from "api/axios";
import BCard from "components/B/card";
import { Loading, LocalDialog } from "components";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useMemo, useState, useEffect } from "react";
import { ChevronLeft } from "react-bootstrap-icons";
import { useMutation, useQuery } from "react-query";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardBody, CardTitle, Row } from "reactstrap";
import { openAlert } from "store/features/alert/AlertSlice";
import { formatIDR } from "helper";
import { getRouteName, routeMapping } from "routes";
import { useLocalDialog } from "hooks";
import BForm from "components/B/form";
import BMTable from "components/B/BMTable";

const SKUColumn = [
	{
		name: "sku",
		label: "Nomor SKU",
		options: {
			filter: false,
			sort: false
		}
	}
];

const ProductDetail = () => {
	const navigate = useNavigate();
	const [title, setTitle] = useState<string>("");
	const [productDetail, setProductDetail] = useState<any[]>([]);
	const [category, setCategory] = useState<string>("");
	const params = useParams();
	const id = params?.id;
	const product_id = params?.id;
	const dispatch = useAppDispatch();
	const [isOpen, setIsOpen] = useState(false);

	// permission for edit product
	const location = useLocation();
	const permission = useAppSelector((state) => state.user.permission);

	const productPermission = useMemo(() => {
		const routeName = getRouteName(location.pathname, routeMapping);
		if (routeName) {
			return permission[routeName];
		}
		return {};
	}, [location, permission]);

	const { isLoading, refetch, data } = useQuery<
		{ data: ApiProduct },
		ApiError
	>(
		["query-products"],
		async () => {
			return await InventoryService.getOneProduct(id ?? "");
		},
		{
			enabled: true,
			onSuccess: (res) => {
				setTitle(res?.data?.displayName);
				setCategory(res?.data?.Category.name);
				try {
					const productDetails = [
						{
							name: "Nama Produk",
							data: res?.data?.displayName
						},
						{
							name: "Kategori",
							data: res?.data?.Category?.Parent?.name
						},
						{
							name: "Sub Kategori",
							data: res?.data?.Category?.name
						},
						{
							name: "Properti",
							type: "textBold",
							format: "bold"
						},
						{
							name: "Slump",
							data: res?.data?.properties?.slump
								? res?.data?.properties?.slump
								: "-"
						},
						{
							name: "Harga Awal",
							data: "Rp. " + formatIDR(res?.data?.Price?.price)
						},
						{
							name: "Deskripsi",
							data: res?.data?.description
								? res?.data?.description
								: "-"
						}
					];
					setProductDetail(productDetails);
				} catch (e) {
					//
				}
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const [payloadProduct, setPayloadProduct] = useState<IProductPayload>({
		skus: [""]
	});
	const { localDialog, setLocalDialog } = useLocalDialog();

	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		} else {
			localDialog.onClose();
		}
	}, [isOpen]);

	const forms: ElementForm[] = React.useMemo(() => {
		const _form: ElementForm[] = [
			{
				label: "Nama Produk",
				type: "text",
				value: payloadProduct.name,
				onChange: (e: any) => {
					setPayloadProduct({
						...payloadProduct,
						name: e.target.value
					});
				},
				placeholder: "Masukkan Nama Produk"
			},
			{
				label: "Kategori Produk",
				type: "dropdown",
				value: payloadProduct.category,
				onChange: (selectedOption: any) => {
					setPayloadProduct({
						...payloadProduct,
						category: selectedOption
					});
				},
				options: [
					{
						labelStr: "NFA",
						valueStr: "NFA"
					},
					{
						labelStr: "FA",
						valueStr: "FA"
					}
				],
				placeholder: "Pilih Kategori Produk"
			},
			{
				label: "Harga",
				type: "text",
				value: payloadProduct?.price,
				onChange: (e: any) => {
					setPayloadProduct({
						...payloadProduct,
						price: e.target.value
					});
				},
				placeholder: "Masukkan Harga Produk"
			}
		];
		return _form;
	}, [payloadProduct]);

	const { mutate, isLoading: loadingCreate } = useMutation<
		ApiResponseProduct,
		ApiError,
		IProductPayload,
		ApiError
	>(
		async (data: IProductPayload) => {
			return await InventoryService.updateProduct(id as string, data);
		},
		{
			onSuccess: (res) => {
				localDialog.onClose();
				refetch();
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const onSubmit = () => {
		const payload = {
			...payloadProduct,
			category: (payloadProduct?.category as any)?.valueStr
		};
		mutate(payload);
	};

	const [tableData, setTableData] = React.useState<
		Omit<ApiProductSkus, "data">
	>({
		message: "",
		success: true,
		currentPage: 1,
		totalItems: 10,
		totalPages: 1,
		dataTable: []
	});

	useQuery<ApiProductSkus, ApiError>(
		["query-product_skus"],
		async () => {
			return await InventoryService.getAllProductSkus(product_id ?? "");
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res.data?.map((el: any, i: any) => ({
					id: el.id,
					values: [
						{
							objectKey: "id",
							value: el.id,
							type: "text",
							render: false
						},
						{
							objectKey: "sku",
							value: el?.SKU?.sku,
							type: "text"
						}
					]
				}));
				setTableData(() => ({
					...res,
					dataTable: (data as DataTable[]) ?? []
				}));
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const resetEditPayloadProduct = () => {
		setPayloadProduct({
			name: "",
			category: null,
			price: 0
		});
	};
	if (isLoading) return <Loading />;

	return (
		<>
			{/* Container Detail */}
			<div className="p-4 pt-6">
				{/* Header Detail */}
				<div className="d-flex flex-row justify-content-between align-items-center mb-2">
					<div className="d-flex align-items-center">
						<div
							className="b-back-btn border mr-3"
							onClick={() => {
								navigate(-1);
							}}
						>
							<ChevronLeft />
						</div>
						<h1 className="mb-0 mr-2">
							{title} - {category}
						</h1>
					</div>

					{/* {productPermission && productPermission.update ? */}
					<div className="d-flex align-items-center">
						<Button
							onClick={() => {
								localDialog.onOpen();
							}}
							size="sm"
							color="warning"
							className="confirm-button approve-button"
						>
							Ubah
						</Button>
						{/* : null} */}
					</div>
				</div>

				{/* Detail Body Detail */}
				<div className="d-flex flex-column flex-lg-row">
					<div
						className="d-flex flex-column mb-3"
						style={{ flex: 0.7 }}
					>
						{/* Table */}
						<Row>
							<div className="col">
								<BMTable
									tableTitle="Daftar SKU Produk"
									hideFilter
									columns={SKUColumn}
									tableData={
										tableData.dataTable as DataTable[]
									}
									totalItems={tableData.totalItems as number}
									currentPage={
										tableData.currentPage as number
									}
								/>
							</div>
						</Row>
					</div>
					<div className="spacer-v" />
					<div className="d-flex flex-column" style={{ flex: 0.3 }}>
						<BCard title="Rincian Produk" data={productDetail} />
					</div>
				</div>
			</div>

			<LocalDialog
				isOpen={localDialog.isOpen}
				backdrop={"static"}
				header="Ubah Produk"
				// onClick={onSubmit}
				onClose={() => {
					resetEditPayloadProduct();
					localDialog.onClose();
					setIsOpen(false);
				}}
			>
				<BForm forms={forms} />
				<div className="modal-custom-footer">
					<LoadingButton
						color="error"
						onClick={() => {
							localDialog.onClose();
							setIsOpen(false);
						}}
						loading={loadingCreate}
						disabled={loadingCreate}
					>
						<span style={{ textTransform: 'capitalize' }}>Batal</span>
					</LoadingButton>
					<LoadingButton
						color="warning"
						variant="contained"
						type="submit"
						onClick={onSubmit}
						loading={loadingCreate}
						disabled={loadingCreate}
					// sx={{ backgroundColor: "red", color: "white" }}
					>
						<span style={{ textTransform: 'capitalize' }}>Ubah</span>
					</LoadingButton>
				</div>
			</LocalDialog>
		</>
	);
};
export default ProductDetail;
