import React, { useMemo, useState, useEffect } from "react";
import { Button, Container, Row, Form, FormGroup, Input } from "reactstrap";
import { useMutation, useQuery } from "react-query";
import moment from "moment";

import { CommonService, InventoryService, OrderService } from "api/axios";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { closeDialog, openDialog } from "store/features/dialog/DialogSlice";
import {
	dateStr,
	formatComma,
	formatDate,
	formatLinkSheet,
	getStatusTrx,
	toSheet
} from "helper";
import { useListSearchParams } from "hooks";
import {
	BStatus,
	BTable,
	Header,
	Loading,
	LocalDialog,
	StatusChip
} from "components";
import { getPriceFormatted } from "helper/product";
import { openAlert } from "store/features/alert/AlertSlice";
import BForm from "components/B/form";
import { useLocation, useNavigate } from "react-router-dom";
import { getRouteName, routeMapping } from "routes";
import BMTable from "components/B/BMTable";
import { useDebounce } from "use-debounce";
import UpdateDo from "./UpdateDo";

interface PayloadDO {
	scheduleId?: {
		number: string;
		id: string;
	};
	date?: Date;
	quantity?: number;
	sealNumber?: string;
	licenseNumber?: number;
	number?: string;
	driver?: {
		id: string;
		fullName: string;
	};
	truck?: {
		id: string;
		plate_number: string;
	};
	// workOrderId?: string;
	// skuId?: string;
	// mixDesignId?: string;
	sku?: {
		id: string;
		sku: string;
	};
	mixDesign?: {
		id: string;
		name: string;
	};
	workOrder?: {
		id: string;
		number: string;
	};
	inputWoNumber?: string;
}

const doColumns = [
	{
		name: "No DO",
		label: "NO. DO",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "Ada Di Accurate",
		label: "Ada Di Accurate",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "Hard Copy Sudah Di Terima?",
		label: "Hard Copy Sudah Di Terima?",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "Do Date",
		label: "tanggal pengiriman",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "status",
		label: "STATUS",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				return <BStatus data={value.data}>{value.status}</BStatus>;
			}
		}
	},
	{
		name: "No Schedule",
		label: "No. Jadwal",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "No WO",
		label: "No. WO",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "salesName",
		label: "Nama Sales",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "product",
		label: "PRODUK",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "Quantity",
		label: "Kuantitas (m³)",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "licenseNumber",
		label: "NO. TM",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "driverName",
		label: "Nama Driver",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "Seal Number",
		label: "No. Seal",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "dateIssued",
		label: "TANGGAL DIBUAT",
		options: {
			filter: false,
			sort: false
		}
	}
];

export default function Delivery() {
	const location = useLocation();
	const [searchDoNumber, setSearchDoNumber] = useState<string>();
	const [searchQDoNumber] = useDebounce(searchDoNumber, 500);
	const permission = useAppSelector((state) => state.user.permission);
	const deliverOrderPermission = useMemo(() => {
		const routeName = getRouteName(location.pathname, routeMapping);

		if (routeName) {
			return permission[routeName];
		}
		return {};
	}, [location, permission]);
	const [tableData, setTableData] = React.useState<ApiDeliveries>({
		message: "",
		success: true,
		currentPage: 1,
		totalItems: 10,
		totalPages: 1,
		dataTable: []
	});
	const { page, date, searchParams } = useListSearchParams();
	const navigate = useNavigate();
	const salesId = searchParams.get("sales");
	const [selectedDeliveryOrders, setSelectedDeliveryOrders] = useState<any[]>(
		[]
	);

	const { bp } = useAppSelector((store) => store.batching);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const doNumberParam = queryParams.get("search?do-number");
		if (doNumberParam) {
			setSearchDoNumber(doNumberParam);
		} else {
			setSearchDoNumber("");
		}
	}, [location.search]);

	// query summary
	useQuery<ApiDeliveries, ApiError>(
		["query-summary", date, salesId, bp?.valueStr],
		async () => {
			const sumDate = {
				...date
			};
			if (!date.endDate) {
				sumDate.startDate = moment()
					.add(0, "days")
					.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
					.toDate();
				sumDate.endDate = moment()
					.add(2, "days")
					.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
					.toDate();
			}
			return await OrderService.getAllDO(1, 1, {
				date: sumDate,
				salesId: salesId as string,
				batchingPlantId: bp?.valueStr
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				setTableData({
					...tableData,
					summary: res.data?.totalItems
				});
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	// query table
	const { isLoading, refetch: refetchTable } = useQuery<
		ApiDeliveries,
		ApiError
	>(
		[
			"query-quotations",
			page,
			date,
			salesId,
			bp?.valueStr,
			searchQDoNumber
		],
		async () => {
			return await OrderService.getAllDO(page, 15, {
				doNumber: searchQDoNumber,
				date,
				salesId: salesId as string,
				batchingPlantId: bp?.valueStr
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res.data?.data.map((el, index) => ({
					id: el.id,
					values: [
						{
							objectKey: "id",
							value: index + 1,
							type: "text",
							render: false
						},
						{
							objectKey: "Ada Di Accurate",
							value: el.deliveryOrderAccurateId ? "Ya" : "Tidak",
							type: "text"
						},
						{
							objectKey: "Hard Copy Sudah Di Terima?",
							value: el.accurateCharfield9 || "-",
							type: "text"
						},
						{
							objectKey: "No DO",
							value: el.number ?? "-",
							type: "text"
						},
						{
							objectKey: "Do Date",
							// value: formatDate(el.date),
							value: moment(el.date).format("yyyy-MM-DD"),
							type: "text"
						},
						{
							objectKey: "dateIssued",
							// value: moment(el.date).format("yyyy-MM-DD"),
							value: moment(el.createdAt).format(
								"yyyy-MM-DD HH:mm"
							),
							type: "text"
						},
						{
							objectKey: "status",
							value: el.status
								? {
										status: el.status,
										data: el
								  }
								: "-",
							type: "status"
						},
						{
							objectKey: "No Schedule",
							value: el.Schedule?.number ?? "-",
							type: "text"
						},
						{
							objectKey: "No WO",
							value: el?.WorkOrder?.number ?? "-",
							type: "text"
						},
						{
							objectKey: "salesName",
							value: el.Project?.User?.fullName ?? "-",
							type: "text"
						},
						{
							objectKey: "product",
							value: el.Schedule?.SaleOrder?.Product?.name ?? "-",
							type: "text"
						},
						{
							objectKey: "Quantity",
							value: el?.quantity
								? formatComma(`${el.quantity} M³`)
								: "0 M³",
							type: "text",
							align: "end"
						},
						{
							objectKey: "licenseNumber",
							value: `${
								el?.Vehicle?.internalId ??
								el?.Vehicle?.internal_id
							} / ${
								el?.Vehicle?.plateNumber ??
								el?.Vehicle?.plate_number
							}`,
							type: "text"
						},
						{
							objectKey: "driverName",
							value: el?.ActualDriver?.name ?? "-",
							type: "text"
						},
						{
							objectKey: "Seal Number",
							value: el.sealNumber ?? "-",
							type: "text"
						}
						// {
						// 	objectKey: "files",
						// 	value: el.QuotationLetterFiles,
						// 	type: "files",
						// },
					]
				}));

				setTableData(() => ({
					...tableData,
					...res,
					dataTable: (data as DataTable[]) ?? []
				}));
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const { refetch, isLoading: loadingSheet } = useQuery<ApiDeliveries, Error>(
		["query-download-do", date, salesId, bp?.valueStr],
		async () => {
			if (!date.endDate) {
				date.endDate = date.startDate;
			}
			return await OrderService.getAllDO(1, 1000, {
				date,
				salesId: salesId as string,
				batchingPlantId: bp?.valueStr
			});
		},
		{
			enabled: false,
			refetchOnWindowFocus: false
		}
	);

	const dispatch = useAppDispatch();
	const downloadSheet = () => {
		refetch({}).then((res) => {
			if (!res.data || (res?.data?.data?.data?.length ?? 0) < 1) {
				dispatch(
					openDialog({
						header: "Tidak ada laporan",
						body: `Tidak ada laporan di tanggal ${dateStr(
							date
						)}. Silahkan pilih tanggal lain`,
						onClick: "close"
					})
				);
				return;
			}

			if (res.data.data?.data) {
				const dataSheet = res.data.data.data.map((el, i) => {
					// const sphFile = el.QuotationLetter?.QuotationLetterFiles?.find(el => el?.type === "LETTER");
					// const poFile = el.PurchaseOrderDocs?.find(el => el.type === "CUSTOMER");
					const recipientNumber = el.recipientNumber
						? `+62${el.recipientNumber}`
						: "-";
					return {
						// No: i + 1,
						"Nama Sales": el.Project?.User.fullName,
						"No PO": el.Schedule.PurchaseOrder.customerNumber,
						"No SO": el.Schedule.PurchaseOrder.brikNumber,
						"No DO": el.number,
						"Nama Proyek": el.Project?.name,
						"Nama Penerima": el.recipientName ?? "-",
						"Kontak Penerima": recipientNumber,
						Alamat: `http://maps.google.com/maps?z=3&t=m&q=loc:${el.Project?.ShippingAddress?.lat}+${el.Project?.ShippingAddress?.lon}`,
						"Tipe Pelanggan": el.Project.Customer?.type,
						Volume: `${el.quantity}`,
						Tanggal: formatDate(el.date),
						Status: getStatusTrx(el.status)
					};
				});

				toSheet(dataSheet, date, "Delivery Order");
				dispatch(closeDialog());
			}
		});
	};

	const onDownload = () => {
		dispatch(
			openDialog({
				header: "Unduh laporan",
				body: `Apakah kamu yakin ingin mengunduh laporan ${dateStr(
					date
				)}?`,
				onClick: downloadSheet
			})
		);
	};

	// create do
	// option truck
	const [optionsSchedule, setOptionSchedule] = React.useState<any[]>([]);
	useQuery<ApiSchedules, ApiError>(
		["query-Schedule", bp?.valueStr],
		async () => {
			const sumDate = {
				...date
			};
			if (!date.endDate) {
				sumDate.startDate = moment()
					.set({ hour: 0, minute: 0, millisecond: 0 })
					.toDate();
				sumDate.endDate = moment()
					.add(1, "days")
					.set({ hour: 0, minute: 0, millisecond: 0 })
					.toDate();
			}
			return await OrderService.getAllSchedules(1, 99, {
				dropdown: true,
				batchingPlantId: bp?.valueStr
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res.data?.data.map((el) => {
					return {
						...el,
						id: el.id,
						valueStr: el.id,
						labelStr: el.number
					};
				});
				setOptionSchedule(data as any[]);
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);
	const [optionTrucks, setOptionTrucks] = React.useState<any[]>([]);
	const [optionDrivers, setOptionDrivers] = React.useState<any[]>([]);
	useQuery<ApiDrivers, ApiError>(
		["query-driver"],
		async () => {
			return await CommonService.getAllDrivers();
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res.data.map((el) => {
					return {
						...el,
						id: el.id,
						valueStr: el.id,
						labelStr: el.fullName
					};
				});
				setOptionDrivers(data);
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);
	useQuery<ApiVehicles, ApiError>(
		["query-vehicle"],
		async () => {
			return await InventoryService.getAllVehicles();
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res.data.map((el) => {
					return {
						...el,
						id: el.id,
						text: `${el?.internalId ?? el?.internal_id} / ${
							el?.plateNumber ?? el?.plate_number
						}`,
						valueStr: el?.plateNumber ?? el?.plate_number,
						labelStr: `${el?.internalId ?? el?.internal_id} / ${
							el?.plateNumber ?? el?.plate_number
						}`
					};
				});
				setOptionTrucks(data);
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	// Option SKU
	const [optionSKUS, setOptionSKUS] = useState<any[]>([]);
	useQuery<SKUDropdown, ApiError>(
		["query-skus"],
		async () => {
			return await InventoryService.getAllSKU({
				page
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res?.data?.map((el) => {
					return {
						...el,
						id: el.id,
						valueStr: el.id,
						labelStr: el.sku
					};
				});
				setOptionSKUS(data);
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const [optionMixDesign, setOptionMixDesign] = useState<any[]>([]);
	useQuery<MixDesignDropdown, ApiError>(
		["query-mix-designs", page],
		async () => {
			return await InventoryService.getAllMixDesign({
				page,
				size: 100
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res?.data?.map((el) => {
					return {
						...el,
						id: el.id,
						valueStr: el.id,
						labelStr: el.name
					};
				});
				setOptionMixDesign(data);
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const handleDoNumberSearch = (newValue: string) => {
		setSearchDoNumber(newValue);

		const queryParams = new URLSearchParams();
		queryParams.set("page", "1");

		if (newValue) {
			queryParams.set("search?do-number", newValue);
		}

		navigate({
			pathname: location.pathname,
			search: queryParams.toString()
		});
	};

	const [payloadDO, setPayloadDO] = React.useState<PayloadDO>();
	const forms: ElementForm[] = React.useMemo(() => {
		return [
			{
				label: "Nomor Jadwal",
				type: "dropdown",
				value: payloadDO?.scheduleId,
				placeholder: "Pilih Jadwal",
				disable: false,
				options: optionsSchedule,
				onChange: (e: any) => {
					setPayloadDO({
						...payloadDO,
						scheduleId: e
					});
				}
			},
			{
				label: "Nomor WO",
				type: "text",
				value: payloadDO?.inputWoNumber,
				placeholder: "WO/202342377 (kosongkan jika auto)",
				disable: false,
				onChange: (e: any) => {
					setPayloadDO({
						...payloadDO,
						inputWoNumber: e.target.value
					});
				}
			},
			{
				label: "Nomor DO",
				type: "text",
				value: payloadDO?.number,
				placeholder: "DO/202342377 (kosongkan jika auto)",
				disable: false,
				onChange: (e: any) => {
					setPayloadDO({
						...payloadDO,
						number: e.target.value
					});
				}
			},
			{
				label: "Tanggal",
				type: "date",
				value: payloadDO?.date,
				onChange: (e: any) =>
					setPayloadDO({
						...payloadDO,
						date: e.target.value
					}),
				placeholder: "Pilih Tanggal",
				min: true
			},
			{
				label: "Jumlah",
				type: "number",
				value: payloadDO?.quantity,
				onChange: (e: any) =>
					setPayloadDO({
						...payloadDO,
						quantity: e.target.value
					}),
				placeholder: "Masukan Jumlah"
				// maxQuantity: (() => {
				// 	const total = data?.data.DeliveryOrders.reduce((a, b) => a + b.quantity, 0);
				// 	if (total) {
				// 		return (data?.data.quantity as number) - total;
				// 	}
				// 	return data?.data.quantity as number;
				// })()
			},
			{
				label: "Nomor Seal",
				type: "text",
				value: payloadDO?.sealNumber,
				onChange: (e: any) =>
					setPayloadDO({
						...payloadDO,
						sealNumber: e.target.value
					}),
				placeholder: "Masukan Seal"
			},
			{
				label: "Truk",
				type: "dropdown",
				value: payloadDO?.truck,
				onChange: (e: any) => {
					setPayloadDO({
						...payloadDO,
						truck: e
					});
				},
				placeholder: "Pilih Truk",
				options: optionTrucks
			},
			{
				label: "Driver",
				type: "dropdown",
				value: payloadDO?.driver,
				onChange: (e: any) => {
					setPayloadDO({
						...payloadDO,
						driver: e
					});
				},
				placeholder: "Pilih Driver",
				options: optionDrivers
			},
			{
				label: "SKU",
				type: "dropdown",
				value: payloadDO?.sku,
				onChange: (e: any) => {
					setPayloadDO({
						...payloadDO,
						sku: e
					});
				},
				placeholder: "Pilih SKU",
				options: optionSKUS
			},
			{
				label: "Mix Design",
				type: "dropdown",
				value: payloadDO?.mixDesign,
				onChange: (e: any) => {
					setPayloadDO({
						...payloadDO,
						mixDesign: e
					});
				},
				placeholder: "Pilih Mix Design",
				options: optionMixDesign
			}
		];
	}, [
		optionTrucks,
		payloadDO,
		optionDrivers,
		optionsSchedule,
		optionSKUS,
		optionMixDesign
	]);

	const [localDialog, setLocalDialog] = React.useState({
		isOpen: false,
		onClose: () => setLocalDialog({ ...localDialog, isOpen: false }),
		onOpen: () => setLocalDialog({ ...localDialog, isOpen: true })
	});

	const [loading, setIsLoading] = useState(false);

	const resetPayloadDO = () => {
		setPayloadDO({});
	};

	const selectedIndex = useMemo(() => {
		const indexes: number[] = [];

		tableData.data?.data.forEach((deliveryOrder, index) => {
			const foundDo = selectedDeliveryOrders.find((deli) => {
				return deli.id === deliveryOrder.id;
			});
			if (foundDo) {
				indexes.push(index);
			}
		});
		return indexes;
	}, [selectedDeliveryOrders, tableData.data?.data]);

	return (
		<>
			<Header data={tableData.summary} date={date} show />
			<Container className="mt--7" fluid>
				{/* Table */}
				<Row>
					<div className="col">
						<BMTable
							tableTitle=""
							header={
								<>
									{/* {deliverOrderPermission &&
									deliverOrderPermission.create ? (
										<Button
											style={{ float: "right" }}
											onClick={() => {
												setIsLoading(false);
												localDialog.onOpen();
											}}
											size="sm"
											color="success"
											className="confirm-button approve-button"
										>
											Buat DO
										</Button>
									) : null} */}
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											paddingBottom: "0.5rem"
										}}
									>
										<Form>
											<FormGroup
												style={{
													display: "flex",
													gap: "1rem"
												}}
											>
												<Input
													placeholder="NO. DO"
													value={searchDoNumber}
													onChange={(e) =>
														handleDoNumberSearch(
															e.target.value
														)
													}
												/>
											</FormGroup>
										</Form>
									</div>
									<UpdateDo
										deliveryOrders={selectedDeliveryOrders}
										refetch={() => {
											refetchTable();
											setSelectedDeliveryOrders([]);
										}}
									/>
								</>
							}
							columns={doColumns}
							tableData={tableData.dataTable as DataTable[]}
							totalItems={tableData.data?.totalItems as number}
							currentPage={tableData.data?.currentPage as number}
							onDownload={onDownload}
							filterSales
							options={{
								onRowClick(rowData, rowMeta) {
									if (tableData && tableData.dataTable) {
										const id =
											tableData.dataTable[
												rowMeta.rowIndex
											]?.id;
										if (id !== undefined) {
											navigate(
												`/order/delivery-orders/${id}`
											);
										}
									}
								}
							}}
							isHideSelect={false}
							selectedIndex={selectedIndex}
							setSelectedRows={(index) => {
								setSelectedDeliveryOrders((prev) => {
									const currentValue = [...prev];
									const foundId =
										tableData?.data?.data?.[index]?.id;
									if (foundId) {
										currentValue.push(
											tableData?.data?.data?.[index]
										);
									}
									return currentValue;
								});
							}}
							setDeselectRows={(index) => {
								setSelectedDeliveryOrders((prev) => {
									const currentValue = [...prev];
									const foundId =
										tableData?.data?.data?.[index]?.id;
									if (foundId) {
										// const index =
										// 	currentValue.indexOf(foundId);
										const index = currentValue.findIndex(
											(el) => el.id === foundId
										);
										if (index > -1) {
											currentValue.splice(index, 1);
										}
									}
									return currentValue;
								});
							}}
							isRowSelectable={(dataIndex) => {
								return !!tableData?.data?.data?.[dataIndex]
									?.deliveryOrderAccurateId;
							}}
						/>
					</div>
				</Row>
			</Container>

			<LocalDialog
				isOpen={localDialog.isOpen}
				backdrop={true}
				header="Buat DO Baru"
				loading={loading}
				onClose={() => {
					resetPayloadDO();
					setIsLoading(false);
					localDialog.onClose();
				}}
			>
				<BForm forms={forms} />
			</LocalDialog>
		</>
	);
}
