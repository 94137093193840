/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { useState } from "react";
import { NavLink as NavLinkRRD, Link, useLocation } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
	Collapse,
	Navbar,
	NavItem,
	NavLink,
	Nav,
	Container,
	Row,
	Col
} from "reactstrap";
import BForm from "components/B/form";
import { useQuery } from "react-query";
import { CommonService } from "api/axios";
import { useDispatch } from "react-redux";
import { openAlert } from "store/features/alert/AlertSlice";
import { changeBP, loadBP } from "store/features/batching/BatchingSlice";
import { useAppSelector } from "store/hooks";

const ItemList = (props) => {
	const location = useLocation();
	const [collapseOpen, setCollapseOpen] = useState(
		location.pathname.includes(props.layout) || false
	);
	if (props.show === false) return null;
	return (
		<>
			<NavItem>
				<span
					className="nav-link"
					style={{ cursor: "pointer" }}
					tag={NavLinkRRD}
					onClick={() => {
						setCollapseOpen(!collapseOpen);
					}}
				>
					{!!props.icon && <i className={props.icon} />}
					{!!props.iconComponent && (
						<i>
							<props.iconComponent />
						</i>
					)}
					{props.name}
				</span>
			</NavItem>
			<Collapse isOpen={collapseOpen} style={{ paddingLeft: "1rem" }}>
				{props.childrens.map((el, key) => {
					if (el.show === false) return null;
					else
						return (
							<NavItem key={key}>
								<NavLink
									to={
										(el.layout || "") +
										(props.layout || "") +
										el.path
									}
									tag={NavLinkRRD}
									onClick={props.closeCollapse}
								>
									{!!el.icon && <i className={el.icon} />}
									{!!el.iconComponent && (
										<i>
											<el.iconComponent />
										</i>
									)}
									{el.name}
								</NavLink>
							</NavItem>
						);
				})}
			</Collapse>
		</>
	);
};

const Sidebar = (props) => {
	const [collapseOpen, setCollapseOpen] = useState();
	// verifies if routeName is the one active (in browser input)
	// toggles collapse between opened and closed (true/false)
	const toggleCollapse = () => {
		setCollapseOpen((data) => !data);
	};
	// closes the collapse
	const closeCollapse = () => {
		setCollapseOpen(false);
	};
	// creates the links that appear in the left menu / Sidebar
	const createLinks = (routes, location) => {
		return routes.map((prop, key) => {
			if (prop?.childrens?.length > 0) {
				return (
					<ItemList
						key={key}
						closeCollapse={closeCollapse}
						location={location}
						{...prop}
					/>
				);
			}

			if (prop.show === false) return null;
			let path = prop.path;
			if (prop.layout) {
				path = prop.layout + path;
			}
			return (
				<NavItem key={key}>
					<NavLink to={path} tag={NavLinkRRD} onClick={closeCollapse}>
						{!!prop.icon && <i className={prop.icon} />}
						{!!prop.iconComponent && (
							<i>
								<prop.iconComponent />
							</i>
						)}
						{prop.name}
					</NavLink>
				</NavItem>
			);
		});
	};

	const { routes, logo, location } = props;
	let navbarBrandProps;
	if (logo && logo.innerLink) {
		navbarBrandProps = {
			to: logo.innerLink,
			tag: Link
		};
	} else if (logo && logo.outterLink) {
		navbarBrandProps = {
			href: logo.outterLink,
			target: "_blank"
		};
	}

	const { options, bp } = useAppSelector((state) => state.batching);
	const dispatch = useDispatch();
	const { isLoading, refetch } = useQuery(
		["query-bp"],
		async () => {
			return await CommonService.getBP();
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const options = res?.data?.data?.map((el) => {
					return {
						...el,
						valueStr: el?.id,
						labelStr: el?.name
					};
				});

				dispatch(
					loadBP({
						payload: options
					})
				);
				if (!bp.id) {
					const defaultValue = options.find((option) => {
						return option.name === "BP-LEGOK";
					});

					dispatch(
						changeBP({
							payload: defaultValue
						})
					);
				}
			},
			onError: (err) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const forms = React.useMemo(() => {
		return [
			{
				label: "Batching Plant",
				type: "dropdown",
				value: bp,
				options: options,
				isDropdownNotClearable: true,
				onChange: (e) => {
					dispatch(
						changeBP({
							payload: e
						})
					);
				}
			}
		];
	}, [options, bp]);

	return (
		<Navbar
			className={[
				"navbar-vertical fixed-left navbar-light bg-white",
				props?.className
			].join(" ")}
			expand="md"
			id="sidenav-main"
		>
			<Container fluid>
				{/* Toggler */}
				<button
					className="navbar-toggler"
					type="button"
					onClick={toggleCollapse}
				>
					<span className="navbar-toggler-icon" />
				</button>
				{/* Brand */}
				{logo ? (
					<>
						<img
							alt="..."
							src={require("../../assets/img/brand/logo-v4.png")}
							className="logo-brik mb-4 ml-auto mr-auto"
						/>
					</>
				) : null}

				<Collapse navbar isOpen={collapseOpen}>
					{/* Collapse header */}
					<div className="navbar-collapse-header d-md-none">
						<Row>
							{logo ? (
								<Col className="collapse-brand" xs="6">
									{logo.innerLink ? (
										<Link to={logo.innerLink}>
											<img
												alt={logo.imgAlt}
												src={logo.imgSrc}
											/>
										</Link>
									) : (
										<a href={logo.outterLink}>
											<img
												alt={logo.imgAlt}
												src={logo.imgSrc}
											/>
										</a>
									)}
								</Col>
							) : null}
							<Col className="collapse-close" xs="6">
								<button
									className="navbar-toggler"
									type="button"
									onClick={toggleCollapse}
								>
									<span />
									<span />
								</button>
							</Col>
						</Row>
					</div>
					<BForm forms={forms} />
					<Nav navbar>{createLinks(routes, location)}</Nav>
				</Collapse>
			</Container>
		</Navbar>
	);
};

Sidebar.defaultProps = {
	routes: [{}]
};

Sidebar.propTypes = {
	// links that will be displayed inside the component
	routes: PropTypes.arrayOf(PropTypes.object),
	logo: PropTypes.shape({
		// innerLink is for links that will direct the user within the app
		// it will be rendered as <Link to="...">...</Link> tag
		innerLink: PropTypes.string,
		// outterLink is for links that will direct the user outside the app
		// it will be rendered as simple <a href="...">...</a> tag
		outterLink: PropTypes.string,
		// the image src of the logo
		imgSrc: PropTypes.string.isRequired,
		// the alt for the img
		imgAlt: PropTypes.string.isRequired
	})
};

export default Sidebar;
