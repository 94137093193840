import { LocalDialog } from "components";
import BCard from "components/B/card";
import { useLocalDialog } from "hooks";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Button, Label } from "reactstrap";
interface ModalProps {
    isOpen: boolean;
    isLoading?: boolean;
    isError?: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    summary: any
    projectDetails: any
    onApprove: () => void;
}

export default function ModalApproval({isOpen, setIsOpen, summary, onApprove, projectDetails}: ModalProps) {
const {localDialog} = useLocalDialog();
const [isCheck, setIsCheck] = React.useState(false);

useEffect(() => {
    if(isOpen) {
        localDialog.onOpen();
    }
}, [isOpen]);

const soEntry = summary.find((item: { name: string }) => item.name === "SO");
const soValue = soEntry ? soEntry.data : "N/A"; // Fallback if SO is not found

    return (
    <main>
        <LocalDialog
        isOpen={isOpen}
        backdrop={"static"}
        header="Konfirmasi Approval"
        onClose={() => {
            localDialog.onClose();
            setTimeout(() => {
                setIsOpen(false);
            });
        }}
        >
        <div style={{display: "flex", gap: "10px", alignItems: "stretch", marginTop: "5px"}}>
            <BCard
            title="Ringkasan Jadwal"
            data={summary}
            />
            <BCard
			title="Rincian Proyek"
			data={projectDetails}
            />
        </div>
        <div className="mt-3 mb-3">
            <p style={{color: "red"}} className="text-sm">Pastikan Pembayaran Sale Order {soValue} Sudah Benar *</p>
        </div>
         <div className="mt-3 flex gap-2">
            <input type="checkbox"
            checked={isCheck}
            onChange={() => setIsCheck(!isCheck)}
            />
            <Label className="ml-2">Konfirmasi Penyetujuan Jadwal</Label>
        </div>

        <div className="mt-5">
            <Button size="sm"
            color="success"
            disabled={!isCheck}
            onClick={() => {
              onApprove();
              setIsOpen(false);
            }}>Setujui</Button>
        </div>
        </LocalDialog>
    </main>
);
}
