import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useMemo,
	useState
} from "react";
import { LocalDialog, MTable, StatusChip } from "components";
import { useLocalDialog } from "hooks";
import { CommonService } from "api/axios";
import { useMutation, useQuery } from "react-query";
import {
	Button,
	Card,
	CardBody,
	CloseButton,
	UncontrolledCollapse
} from "reactstrap";
import { useDebounce } from "use-debounce";
import { MUIDataTableColumnDef } from "mui-datatables";
import { XOctagon } from "react-bootstrap-icons";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";

export default function MergeCustomer({
	isOpen,
	setIsOpen,
	refetch: refetchCustomer
}: {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	refetch: () => void;
}) {
	const dispatch = useAppDispatch();
	const { localDialog } = useLocalDialog();
	const [searchQ, setSearchQ] = useState<string | undefined>(undefined);
	const [searchDebounced] = useDebounce(searchQ, 500);
	const [page, setPage] = useState(1);
	const [totalItems, setTotalItems] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [customerMaster, setCustomerMaster] = useState<
		ApiCustomer | undefined
	>(undefined);
	const [selectedCustomer, setSelectedCustomer] = useState<string[]>([]);

	const custColumn: MUIDataTableColumnDef[] = [
		{
			name: "id",
			label: "ID",
			options: {
				filter: false,
				sort: false
			}
		},
		{
			name: "displayName",
			label: "Nama Pelanggan",
			options: {
				filter: false,
				sort: false
			}
		},
		{
			name: "type",
			label: "Tipe Pelanggan",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value: any) => {
					if (value === undefined) {
						return "-";
					} else {
						if (value === "COMPANY") {
							return <StatusChip value={"PERUSAHAAN"} />;
						}
						return <StatusChip value={"INDIVIDU"} />;
					}
				}
			}
		},
		{
			name: "paymentType",
			label: "Tipe Pembayaran",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value: any) => {
					if (!value) {
						return <StatusChip value={"CASH"} />;
					} else {
						return <StatusChip value={value} />;
					}
				}
			}
		},
		{
			name: "nik",
			label: "No. KTP",
			options: {
				filter: false,
				sort: false
			}
		},
		{
			name: "npwp",
			label: "No. NPWP",
			options: {
				filter: false,
				sort: false
			}
		},
		{
			name: "id",
			label: "Action",
			options: {
				filter: false,
				sort: false,
				customBodyRenderLite(dataIndex, rowIndex) {
					if (!customerMaster) {
						return (
							<Button
								onClick={() => {
									setCustomerMaster(
										CustomersData?.data?.data[dataIndex]
									);
								}}
							>
								Pilih Master
							</Button>
						);
					}
					return "-";
				}
			}
		}
	];
	const { isLoading, data: CustomersData = {} as ApiCustomers } = useQuery<
		ApiCustomers,
		ApiError
	>(
		["query-customers", page, searchDebounced],
		async () => {
			return await CommonService.getAllCustomer(page, 10, {
				search: searchDebounced
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				// const data = res.data.data.map((el, i) => ({
				// 	id: el.id,
				// 	values: [
				// 		{
				// 			objectKey: "id",
				// 			value: el.id, //15 is size data, look axios service,
				// 			type: "text",
				// 			render: false
				// 		},
				// 		{
				// 			objectKey: "no",
				// 			value: i + 1 + 15 * (page - 1), //15 is size data, look axios service,
				// 			type: "text"
				// 		},
				// 		{
				// 			objectKey: "type",
				// 			value:
				// 				el.type === "COMPANY"
				// 					? "PERUSAHAAN"
				// 					: el.type,
				// 			type: "text"
				// 		},
				// 		{
				// 			objectKey: "name",
				// 			value: el.displayName,
				// 			type: "text"
				// 		},
				// 		{
				// 			objectKey: "paymentType",
				// 			value:
				// 				el.paymentType === null
				// 					? "CASH"
				// 					: el.paymentType,
				// 			type: "text"
				// 		},
				// 		{
				// 			objectKey: "NIK",
				// 			value: el.nik === "0" || !el.nik ? "-" : el.nik,
				// 			type: "text"
				// 		},
				// 		{
				// 			objectKey: "npwp",
				// 			value:
				// 				el.npwp === "0" || !el.npwp ? "-" : el.npwp,
				// 			type: "text"
				// 		}
				// 	]
				// }));
				// setTableData(() => ({
				// 	...res,
				// 	...res.data,
				// 	dataTable: (data as DataTable[]) ?? []
				// }));
			},
			onError: (err: ApiError) => {
				// dispatch(
				// 	openAlert({
				// 		body: err.response?.data.message,
				// 		color: "danger"
				// 	})
				// );
			}
		}
	);

	const { mutateAsync, isLoading: mergerLoading } = useMutation(
		async ({
			masterCustomerId,
			slaveCustomerIds
		}: {
			slaveCustomerIds: string[];
			masterCustomerId: string;
		}) => {
			return await CommonService.mergeCustomer({
				masterCustomerId,
				slaveCustomerIds
			});
		}
	);

	async function submitMerge() {
		const res = await mutateAsync({
			masterCustomerId: customerMaster?.id as string,
			slaveCustomerIds: selectedCustomer
		});
		setIsOpen(false);
		dispatch(
			openAlert({
				body: res.message,
				color: "success"
			})
		);
		refetchCustomer();
	}

	const selectedIndex = useMemo(() => {
		const indexes: number[] = [];
		CustomersData?.data?.data.forEach((customer, index) => {
			if (selectedCustomer.includes(customer.id)) {
				indexes.push(index);
			}
		});
		return indexes;
	}, [selectedCustomer, CustomersData]);

	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		}
	}, [isOpen]);

	return (
		<LocalDialog
			isOpen={localDialog.isOpen}
			backdrop={true}
			header={"Gabungkan Pelanggan"}
			onClose={() => {
				localDialog.onClose();
				setTimeout(() => {
					setIsOpen(false);
				}, 500);
			}}
		>
			<div>
				{!customerMaster
					? "Pilih Pelanggan Utama"
					: "Pilih Pelanggan untuk digabungkan"}

				{customerMaster ? (
					<Card>
						<CardBody>
							<div className="d-flex flex-column">
								<b>Master</b>
								<div>ID: {customerMaster?.id}</div>
								<div>
									Name Pelanggan:&nbsp;{" "}
									{customerMaster?.displayName}
								</div>
								<div>
									Tipe Pelanggan:&nbsp;
									{customerMaster?.type === "COMPANY" ? (
										<StatusChip value={"Perusahaan"} />
									) : (
										<StatusChip value={"Individu"} />
									)}
									{/* <StatusChip
										value={customerMaster?.type as string}
									/> */}
								</div>
								<div>
									Tipe Pembayaran:&nbsp;
									<StatusChip
										value={
											customerMaster?.paymentType
												? (customerMaster?.paymentType as string)
												: "CASH"
										}
									/>
								</div>
								<div>
									KTP:&nbsp;
									{customerMaster?.nik
										? customerMaster?.nik
										: "-"}
								</div>
								<div>
									NPWP:&nbsp;
									{customerMaster?.npwp
										? customerMaster?.npwp
										: "-"}
								</div>
							</div>
							<button
								className="close"
								onClick={() => {
									setCustomerMaster(undefined);
									setSelectedCustomer([]);
								}}
								type="button"
								style={{
									position: "absolute",
									right: -5,
									top: -12
								}}
							>
								<XOctagon />
							</button>
						</CardBody>
					</Card>
				) : null}

				<MTable
					totalItems={
						CustomersData?.data?.totalItems
							? CustomersData?.data?.totalItems
							: 0
					}
					columns={custColumn}
					tableData={CustomersData?.data?.data}
					rowsPerPage={rowsPerPage}
					rowsPerPageOptions={[5, 10, 15, 100]}
					currentPage={
						CustomersData?.data?.currentPage
							? CustomersData?.data?.currentPage
							: 1
					}
					tableTitle=""
					selectableRowsHideCheckboxes={!customerMaster}
					setPage={(page) => {
						setPage(page);
					}}
					onSearchChange={(text: string | null) => {
						if (text) {
							setSearchQ(text);
						} else {
							setSearchQ(undefined);
						}
					}}
					selectedRows={selectedIndex}
					setSelectedRows={(index: number) => {
						setSelectedCustomer((curr) => {
							const newSelectedIds = [...curr];
							newSelectedIds.push(
								CustomersData?.data?.data[index]?.id as string
							);
							return newSelectedIds;
						});
					}}
					setDeselectRows={(index: number) => {
						setSelectedCustomer((curr) => {
							const newSelectedIds = [...curr];

							return newSelectedIds.filter(
								(id) =>
									id !== CustomersData?.data?.data[index]?.id
							);
						});
					}}
					options={{
						isRowSelectable(dataIndex, selectedRows) {
							return (
								CustomersData?.data?.data[dataIndex]?.id !==
								customerMaster?.id
							);
						}
					}}
				/>
				{/* {CustomersData?.data?.data?.map((customer) => {
					return (
						<div className="d-flex flex-column">
							Nama Pelanggan{customer.displayName}
							<Button
								id={`cust-${customer.id}`}
								style={{
									marginBottom: "1rem"
								}}
							>
								Lihat Proyek
							</Button>
							<UncontrolledCollapse
								toggler={`#cust-${customer.id}`}
							>
								<Card>
									<CardBody>
										{customer?.Projects.map((project) => {
											return (
												<div>
													Nama Proyek: {project.name}
												</div>
											);
										})}
									</CardBody>
								</Card>
							</UncontrolledCollapse>
						</div>
					);
				})} */}
				<div className="modal-custom-footer">
					<LoadingButton
						color="error"
						onClick={() => {
							localDialog.onClose();
							setTimeout(() => {
								setIsOpen(false);
							}, 500);
						}}
						loading={mergerLoading}
						disabled={mergerLoading}
					>
						<span style={{ textTransform: "capitalize" }}>
							Batal
						</span>
					</LoadingButton>
					<LoadingButton
						color="error"
						variant="contained"
						loading={mergerLoading}
						disabled={
							mergerLoading ||
							!(customerMaster && !!selectedCustomer.length)
						}
						onClick={submitMerge}
						// sx={{ backgroundColor: "red", color: "white" }}
					>
						<span style={{ textTransform: "capitalize" }}>
							Gabungkan Pelanggan
						</span>
					</LoadingButton>
				</div>
			</div>
		</LocalDialog>
	);
}
