import React, { useMemo, useState } from "react";
import { DashboardService } from "api/axios";
import { BarChart } from "components";
import { MonthStartEnd } from "helper/WeekNumberToDateRange";
import moment from "moment";
import { useQuery } from "react-query";

const thisMonth = moment().endOf("month").format("YYYY-MM-DD");
const buttons = [
	{
		value: "VISIT",
		title: "VISIT"
	},
	{
		value: "SPH",
		title: "SPH"
	},
	{
		value: "PO",
		title: "PO"
	},
	{
		value: "SCHEDULING",
		title: "SCHEDULING"
	},
	{
		value: "DO",
		title: "DO"
	},
	{
		value: "REJECTED",
		title: "REJECTED"
	}
];

const { startDate: initialMonthStartDate, endDate: initialMonthEndDate } =
	MonthStartEnd(thisMonth, 3);

export default function SalesSoChart() {
	const [dateRange, setDateRange] = useState({
		startDate: initialMonthStartDate,
		endDate: initialMonthEndDate
	});
	const [filterType, setFilterType] = useState("monthly");
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [salesSo, setSalesSo] = useState({} as SalesSoResponse);

	useQuery(
		["sales-so", dateRange, page, filterType],
		async () => {
			const { startDate, endDate } = dateRange;
			return await DashboardService.salesSoCount({
				startDate,
				endDate,
				type: filterType,
				page
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				setSalesSo(res.data.data as SalesSoResponse);
				setTotalPages(res.data.data.totalPages);
			}
		}
	);

	const transformedData = useMemo(() => {
		const soData: {
			label: string;
			[key: string]: string | number;
		}[] = [];
		if (salesSo.sales) {
			salesSo.sales.reverse().forEach((sales) => {
				soData.push({
					label: sales.fullName as string,
					"Jumlah SO": +sales.numSalesOrders
				});
			});
		}
		return soData;
	}, [salesSo]);

	return (
		<BarChart
			data={transformedData}
			labels={["Jumlah SO"]}
			title="Sales/SO"
			indexBy={"label"}
			layout="horizontal"
			filterOnChange={({ dateRange, type }) => {
				setDateRange(dateRange);
				setFilterType(type);
			}}
			leftLegend=""
			bottomLegend=""
			setPage={(page) => {
				setPage((curr) => {
					if (curr !== page) {
						return page;
					}
					return curr;
				});
			}}
			totalPage={totalPages}
			initialDate={{
				startDate: initialMonthStartDate,
				endDate: initialMonthEndDate
			}}
		/>
	);
}
