import { getCustomerTypeColor } from "helper";
import React from "react";
import { Badge } from "reactstrap";

interface IProps {
	isCompany: boolean;
}

const BCustomerType = (props: IProps) => {
	const { isCompany } = props;
	return (
		<Badge color="null" style={{ ...getCustomerTypeColor(isCompany ? "COMPANY" : "INDIVIDU") }}>{isCompany ? "Perusahaan" : "Individu"}</Badge>
	);
};

export default BCustomerType;
