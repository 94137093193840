/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from "react";
import ToggleButtons from "./ToggleButtons";
import MonthPicker from "./MonthPicker";
import Weekpicker from "./Weekpicker";
import moment from "moment";
import {
	MonthStartEnd,
	WeekNumberToDateRange
} from "helper/WeekNumberToDateRange";

export type FilterType = "monthly" | "weekly";
interface ChartFilterProps {
	onClick: (type: FilterType, dateRange: onChangeProp) => void;
	initialDate?: {
		startDate: string;
		endDate: string;
	};
}
export type onChangeProp = {
	startDate: string;
	endDate: string;
};

const thisMonth = moment().endOf("month").format("YYYY-MM-DD");
const { startDate: initialMonthStartDate, endDate: initialMonthEndDate } =
	MonthStartEnd(thisMonth, 3);

export default function ChartFilter({
	onClick,
	initialDate
}: ChartFilterProps) {
	const [filterType, setFilterType] = useState("monthly");
	const [dateRange, setDateRange] = useState<onChangeProp>({
		startDate: initialDate ? initialDate.startDate : initialMonthStartDate,
		endDate: initialDate ? initialDate.endDate : initialMonthEndDate
	});

	function pickerTypeHandle(type: FilterType) {
		setFilterType((currType) => {
			if (currType !== type) {
				onClick(type, dateRange);
			}
			return type;
		});
	}

	return (
		<>
			<div className="filter-selector">
				<ToggleButtons
					onClick={pickerTypeHandle}
					buttons={[
						{
							value: "monthly",
							title: "Monthly"
						},
						{
							value: "weekly",
							title: "Weekly"
						}
					]}
				/>
			</div>
			<div className="filter-wrapper">
				{filterType === "monthly" ? (
					<MonthPicker
						onChange={(months: onChangeProp) => {
							onClick(filterType, months);
						}}
						initialDate={{
							startDate: {
								year: moment(initialDate?.startDate).get(
									"year"
								),
								month:
									moment(initialDate?.startDate).get(
										"month"
									) + 1
							},
							endDate: {
								year: moment(initialDate?.endDate).get("year"),
								month:
									moment(initialDate?.endDate).get("month") +
									1
							}
						}}
					/>
				) : null}
				{filterType === "weekly" ? (
					<Weekpicker
						onChange={(dateRange) => {
							setDateRange(dateRange);
							onClick(filterType, dateRange);
						}}
					/>
				) : null}
			</div>
		</>
	);
}
