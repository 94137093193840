import { LocalDialog } from "components";
import { useLocalDialog } from "hooks";
import { useDebounce } from "use-debounce";
import React, { Fragment } from "react";
import { QueryObserverResult, useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { useAppDispatch, useAppSelector } from "store/hooks";
import * as Yup from "yup";
import styles from "./createPayment.module.scss";
import Select, { OptionProps } from "react-select";
import { LoadingButton } from "@mui/lab";
import { ErrorMessage, Form, Formik } from "formik";
import { openAlert } from "store/features/alert/AlertSlice";
import { FinanceService, HubService } from "api/axios";

const depositSchema = Yup.object().shape({
	// number: Yup.string().matches(
	// 	/^DEP\/BRIK-\w+\/\d{4}\/\d{2}\/\d{5}$/,
	// 	"Nomor Deposit Harus Seperti DEP/BRIK-XXX/2023/12/00001"
	// ),
	// customer: Yup.object()
	// 	.shape({
	// 		valueStr: Yup.string().required("Customer harus diisi"),
	// 		labelStr: Yup.string().required("Customer harus diisi"),
	// 		customerAccurateId: Yup.string().required("Customer harus diisi")
	// 	})
	// 	.required("Customer harus dipilih"),
	// batchingPlant: Yup.object()
	// 	.shape({
	// 		valueStr: Yup.string().required("Customer harus diisi"),
	// 		labelStr: Yup.string().required("Customer harus diisi")
	// 	})
	// 	.required("Batching Plant harus dipilih"),
	// type: Yup.object()
	// 	.shape({
	// 		valueStr: Yup.string().required("Tipe harus diisi"),
	// 		labelStr: Yup.string().required("Tipe harus diisi")
	// 	})
	// 	.required("Tipe harus diisi")
	// type: Yup.string().required("Tipe harus diisi"),
	// amount: Yup.string().required("Jumlah harus diisi"),
	// paymentDate: Yup.string().required("Tanggal Pembayaran harus diisi"),
	// paymentFile: Yup.mixed()
	// 	.required("File Pembayaran Dibutuhkan")

	// 	.test("fileSize", "File harus lebih kecil dari 5MB", (value: any) => {
	// 		if (value) {
	// 			// return value.size <= 3145728;
	// 			return value.size <= 5242880;
	// 		}
	// 		return true;
	// 	}),
	customerAccurate: Yup.object().required("Pelanggan Accurate harus diisi"),
	description: Yup.string().optional()
});

const Option = (props: OptionProps<CustomerAccurate>) => {
	return (
		<div
			className={styles.selectCustom}
			{...("innerProps" in props && props.innerProps)}
			style={{
				cursor: props.isDisabled ? "default" : "pointer",
				backgroundColor: props.isDisabled ? "lightgray" : "white"
			}}
		>
			<div>
				<div className={styles.selectInfo}>
					<p>{props.data.name}</p>
					<b>{props.data.customerNo}</b>
				</div>
				<div className={styles.contact}>
					<p>
						HP: <b>{props.data.mobilePhone || "-"}</b>
					</p>
					<p>
						WA: <b>{props.data.whatsappNo || "-"}</b>
					</p>
					<p>
						email: <b>{props.data.email || "-"}</b>
					</p>
				</div>
			</div>
		</div>
	);
};

const AccurateCustomerSelector = (props: {
	customers: CustomerAccurate[];
	setSearchCustomer: (value: string) => void;
	setFieldValue: (field: string, value: any) => void;
	values: any;
	errors: any;
	defaultAccurateCustomer?: CustomerAccurate;
	isLoading?: boolean;
	isDisabled?: boolean;
}) => {
	const {
		customers,
		setSearchCustomer,
		setFieldValue,
		values,
		errors,
		defaultAccurateCustomer,
		isDisabled
	} = props;
	React.useEffect(() => {
		setFieldValue("customerAccurate", defaultAccurateCustomer);
	}, [defaultAccurateCustomer]);
	return (
		<FormGroup>
			<Label htmlFor="searchCustomer">Pelanggan Accurate *</Label>

			<Select
				options={customers}
				components={{ Option }}
				getOptionLabel={(option: CustomerAccurate) => option.name}
				getOptionValue={(option: CustomerAccurate) => option.customerNo}
				onInputChange={(inputValue: string) => {
					setSearchCustomer(inputValue);
				}}
				onChange={(e) => {
					setFieldValue("customerAccurate", e);
				}}
				isMulti={false}
				value={values.customerAccurate}
				defaultValue={defaultAccurateCustomer}
				isDisabled={isDisabled}
				styles={{
					control(base, state) {
						return {
							...base,
							borderColor: errors.customerAccurate
								? "red"
								: "#cad1d7"
						};
					}
				}}
				// isLoading={customerListLoading || customerDetailLoading}
			/>

			<ErrorMessage
				name={"customerAccurate"}
				component="div"
				className="error-msg"
			/>
		</FormGroup>
	);
};

export default function CompletePayment(props: {
	refetch: () => Promise<QueryObserverResult<ApiOnePayment, ApiError>>;
}) {
	const { refetch } = props;
	const dispatch = useAppDispatch();
	const params = useParams();
	const id = params?.id;
	const { localDialog } = useLocalDialog();
	const { bp } = useAppSelector((store) => store.batching);
	const [searchCustomer, setSearchCustomer] = React.useState("");
	const [searchCustomerDebounced] = useDebounce(searchCustomer, 500);
	console.log(
		searchCustomerDebounced,
		" searchCustomerDebounced ",
		searchCustomer
	);

	const { data: customersAccurate, isLoading: customerListLoading } =
		useQuery<ApiResponsePlain<CustomerAccurate[]>>(
			["query-customer-accurate", searchCustomerDebounced],
			async () => {
				return await HubService.getCustomersAccurate({
					keywords: searchCustomerDebounced || ""
				});
			},
			{
				onError(err: any) {
					dispatch(
						openAlert({
							body: "message",
							color: "danger"
						})
					);
				},
				enabled: true
			}
		);

	const { mutateAsync: updatePayment } = useMutation(
		async (payload: {
			customerAccurateId: number;
			description: string;
		}) => {
			return await FinanceService.updatePaymentNumber(
				id as string,
				undefined,
				bp.id as string,
				payload.customerAccurateId,
				payload.description,
				"SUBMITTED"
			);
		},
		{
			onSuccess: () => {
				refetch();
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	return (
		<Fragment>
			<Button
				size="sm"
				color="success"
				className="confirm-button approve-button"
				onClick={() => {
					localDialog.onOpen();
				}}
			>
				Lengkapi Data
			</Button>
			<LocalDialog
				isOpen={localDialog.isOpen}
				backdrop={"static"}
				header="Lengkapi Data Deposit"
				onClose={() => {
					localDialog.onClose();
				}}
			>
				<Formik
					initialValues={{
						customerAccurate: null as CustomerAccurate | null,
						description: ""
					}}
					onSubmit={async (values, { setSubmitting }) => {
						// console.log("submit");
						setSubmitting(true);
						// const _sendFile = new FormData();
						// _sendFile.append("photos", values.paymentFile as File);
						// _sendFile.append("name", "DEPOSIT");
						// const res = await sendFileAsync(_sendFile);
						// const data = handleResponseFile(res.data, [
						// 	uploadFile as File
						// ]);
						// const files = data.map((el) => ({
						// 	fileId: el.fileId
						// }));

						const _payload = {
							// projectId: payload.project?.id,
							// customerId: values?.customer?.valueStr,
							// invoiceId: payload.invoice?.id,
							// amount: parseIDR(values.amount as string),
							// type: values.type,
							// paymentDate: moment(
							// 	values.paymentDate as string
							// ).valueOf(),
							// files: files,
							// number: values.number,
							// batchingPlantId: values.batchingPlant?.valueStr,
							batchingPlantId: bp.valueStr,
							customerAccurateId: values.customerAccurate
								?.id as number,
							description: values.description
							// taxStreet: detailAccurateCustomer?.data?.taxStreet
						};
						// const bPlant = values.batchingPlant?.labelStr
						// 	?.split("-")[1]
						// 	?.toLowerCase();
						// const branch = branchAccurate?.data?.d?.find(
						// 	(br) => br.name.toLowerCase() === bPlant
						// );

						// handleCreateLoading(true);
						// mutate(_payload);
						// const resp =
						// await postPayment(_payload);
						// const depNumber = resp.data?.number;
						// const payloadAccurate = {
						// 	dpAmount: parseIDR(values.amount as string),
						// 	branchName: branch?.name,
						// 	description: values.description,
						// 	number: depNumber,
						// 	customerNo: values.customerAccurate?.customerNo
						// };
						// await createDownPaymentAccurate(payloadAccurate);
						await updatePayment(_payload);
						dispatch(
							openAlert({
								body: "Berhasil Membuat Pembayaran",
								color: "success"
							})
						);
					}}
					validationSchema={depositSchema}
				>
					{({
						errors,
						handleChange,
						values,
						setFieldValue,
						isSubmitting,
						touched
					}) => {
						return (
							<Form>
								<AccurateCustomerSelector
									customers={customersAccurate?.data || []}
									setSearchCustomer={setSearchCustomer}
									setFieldValue={setFieldValue}
									values={values}
									errors={errors}
									isLoading={customerListLoading}
								/>

								<FormGroup>
									<Label htmlFor="description">
										Keterangan
									</Label>
									<Input
										name="description"
										type="textarea"
										invalid={!!errors.description}
										onChange={handleChange}
										// value={values.description}
										placeholder={"Keterangan Pembayaran"}
									/>

									<ErrorMessage
										name={"description"}
										component="div"
										className="error-msg"
									/>
								</FormGroup>

								<div className="modal-custom-footer">
									<LoadingButton
										color="error"
										onClick={() => {
											localDialog.onClose();
											setTimeout(() => {
												localDialog.onClose();
											});
										}}
										loading={isSubmitting}
										disabled={isSubmitting}
									>
										<span
											style={{
												textTransform: "capitalize"
											}}
										>
											Batal
										</span>
									</LoadingButton>
									<LoadingButton
										color="error"
										variant="contained"
										type="submit"
										loading={isSubmitting}
										disabled={isSubmitting}
										sx={{
											backgroundColor: "red",
											color: "white"
										}}
									>
										<span
											style={{
												textTransform: "capitalize"
											}}
										>
											Buat
										</span>
									</LoadingButton>
								</div>
							</Form>
						);
					}}
				</Formik>
			</LocalDialog>
		</Fragment>
	);
}
