/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import moment from "moment";
import React from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

interface IProps {
	data?: any
	date?: DRange
	show?: boolean
}

const Header = (props: IProps) => {
	const { data, date, show } = props;

	const stringDate = React.useMemo(() => {
		if (!date?.endDate || moment(date.startDate).isSame(moment(date.endDate), "day")) {
			// return moment(date?.startDate).calendar(moment());
			return moment(date?.startDate).format("D-MMM");
		}

		return `${moment(date?.startDate).format("D-MMM")} - ${moment(date?.endDate).format("D-MMM")}`;
	}, [date]);

	return (
		<>
			<div className="header pb-8 pt-5 pt-md-8">
				<Container fluid>
					<div className="header-body">
						{/* Card stats */}
						{
							show &&
							<Row>
								<Col lg="6" xl="3">
									<Card className="card-stats mb-4 mb-xl-0">
										<CardBody>
											<Row>
												<div className="col">
													<CardTitle
														tag="h5"
														className="text-uppercase text-muted mb-0"
													>
														Total
													</CardTitle>
													<span className="h2 font-weight-bold mb-0">
														{data}
													</span>
												</div>
												<Col className="col-auto">
													<div className="icon icon-shape bg-danger text-white rounded-circle shadow">
														<i className="fas fa-chart-bar" />
													</div>
												</Col>
											</Row>
											<p className="mt-3 mb-0 text-muted text-sm">
												{/* <span className="text-success mr-2">
												<i className="fa fa-arrow-up" /> 3.48%
											</span>{" "} */}
												<span className="text-nowrap">{stringDate}</span>
											</p>
										</CardBody>
									</Card>
								</Col>
							</Row>
						}
					</div>
				</Container>
			</div>
		</>
	);
};

export default Header;
