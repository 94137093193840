import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import "leaflet/dist/leaflet.css";

import { MapContainer, TileLayer, Polyline, useMap, Marker } from "react-leaflet";
import { LatLngBounds, LatLngExpression } from "leaflet";
import L from "leaflet";

// import { MarkerLayer, Marker } from "react-leaflet-marker";
// import Marker from "react-leaflet-enhanced-marker";
// import { useLeafletIcon } from "react-leaflet-icon";
interface IProps {
	className?: string;
	data?: LatLngExpression[];
	showMarkers?: boolean;
	markers?: LatLngExpression[];
	padding?: number;
}

const pointWhiteIcon = new L.Icon({
	iconUrl: "/icons/point-w.svg",
	iconRetinaUrl: "/icons/point-w.svg",
	popupAnchor: [-0, -0],
	iconSize: [24, 24],
});

const pointCustomerIcon = new L.Icon({
	iconUrl: "/icons/point-c.svg",
	iconRetinaUrl: "/icons/point-c.svg",
	popupAnchor: [-0, -0],
	iconSize: [24, 24],
});

function LineComponent({
	className,
	data,
	padding
}: IProps = {
		data: [],
	}) {
	const map: any = useMap();
	const [line, setLine] = useState<LatLngExpression[]>([]);

	useEffect(() => {
		console.log({ map });
		if (data) {
			const polyline: LatLngExpression[] = data.map((latLng: any) => latLng.slice().reverse());
			setLine(polyline);
			const lat = polyline.map((p: any) => p[0]);
			const lng = polyline.map((p: any) => p[1]);
			const bounds = new LatLngBounds({
				lat: Math.min.apply(null, lat),
				lng: Math.min.apply(null, lng)
			}, {
				lat: Math.max.apply(null, lat),
				lng: Math.max.apply(null, lng)
			});
			console.log({
				polyline,
				lat,
				lng,
				bounds
			});
			map.fitBounds(bounds, {
				padding: [padding || 8, padding || 8]
			});
			map.invalidateSize();
		}
	}, [data]);
	return <>
		<Polyline className={className} pathOptions={{ color: "#cda400", weight: 6 }} positions={line} />;
		<Polyline className={className} pathOptions={{ color: "#ffcd03", weight: 2 }} positions={line} />;
	</>;
}

function BMapPreview({
	className,
	data,
	showMarkers,
	markers,
	padding,
}: IProps = {
		data: [],
		showMarkers: false,
		markers: [],
	}) {
	const center: LatLngExpression = [0, 0];
	// const [markerIcon, MarkerIcon] = useLeafletIcon();
	return (
		<div
			className={[styles.bMapPreview, className].join(" ")}>
			<MapContainer
				className={[styles.mapContainer].join(" ")}
				zoomControl={false}
				center={center}
				zoom={13}
				scrollWheelZoom={false}
				zoomSnap={0}
			>
				{/* <TileLayer
			url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
		/> */}
				<TileLayer
					url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
				/>
				{data?.length &&
					<>
						<LineComponent
							data={data}
							padding={padding}
						/>
					</>
				}
				{markers?.length && showMarkers && markers.map((marker, idx: number) => {
					return (idx == markers.length - 1 ? <Marker
						position={(marker as any[]).reverse() as LatLngExpression}
						key={idx}
						icon={pointCustomerIcon}
					/> : <Marker
						position={(marker as any[]).reverse() as LatLngExpression}
						key={idx}
						icon={pointWhiteIcon}
					/>);
				})}
			</MapContainer>
		</div >
	);
}
const dataComparator = (prevProps: any, nextProps: any) => {
	return JSON.stringify(prevProps.data) == JSON.stringify(nextProps.data) &&
		JSON.stringify(prevProps.markers) == JSON.stringify(nextProps.markers);
};

export default React.memo(BMapPreview, dataComparator);
