export default function RoutesAccess({ flagValues }: { flagValues: any }) {
	try {
		const blackList: string[] = [];

		const commonKeys = ["create", "view", "update", "delete"];
		Object.keys(flagValues).forEach((feature) => {
			if (flagValues[feature]) {
				Object.keys(flagValues[feature]).forEach((el) => {
					if (el === "view") {
						if (!flagValues[feature][el]) {
							blackList.push(feature);
						}
					}
					if (!commonKeys.includes(el)) {
						if (!flagValues[feature][el]) {
							blackList.push(el);
						}
					}
				});
			}
		});

		return blackList;
	} catch (error) {
		console.log(error, "RoutesAccess error flagsmith");

		return [];
	}
}
//userType: string[], flagValues: string
