import * as React from "react";
import AddIcon from "@mui/icons-material/Add";
import "./FileUpload.scss";
import { CommonService, OrderService } from "api/axios";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import Loading from "components/B/loading";

export default function FileUpload({
	files,
	setFiles,
	setListFiles
}: {
	files: any;
	setFiles: any;
	setListFiles: any;
}) {
	//1).kalau dibuka file nya maka sudah terpopulate dengan data yang sudah ada sebelumnya
	//2).ditengah ada tombol upload jadi kalau pencet upload maka data terupload dan juga jumlah file bertambah
	//3).di samping list file ada tombol ubah, jadi kalau dipencet ubah maka akan ngetriger ubah file yang sudah ada menjadi file yang baru dipilih

	// const [uploadFile, setUploadFile ] = React.useState([]);
	const params = useParams();
	const id = params?.id;
	const dispatch = useAppDispatch();

	const { mutateAsync: sendFile, isLoading: loadingUpload } = useMutation<
		ApiResponseFiles,
		ApiError,
		FormData,
		ApiError
	>(async (_files: FormData) => {
		const res = await CommonService.uploadFile(_files);
		return res;
	});

	// const { mutateAsync: uploadWOFile, isLoading } = useMutation(
	// 	async (data: any) => {
	// 		return await OrderService.filesWO(id as string, data);
	// 	},
	// 	{
	// 		onSuccess: (res) => {
	// 			dispatch(
	// 				openAlert({
	// 					body: "Berhasil upload hasil produksi",
	// 					color: "success"
	// 				})
	// 			);
	// 		},
	// 		onError: (err: ApiError) => {
	// 			openAlert({
	// 				body: err.response?.data?.message,
	// 				color: "danger"
	// 			});
	// 		}
	// 	}
	// );

	const handleFile = async (e: any) => {
		const uploadFile = e.target.files[0];
		const _sendFile = new FormData();
		_sendFile.append("photos", uploadFile);
		_sendFile.append("name", "WO");
		const response = await sendFile(_sendFile);

		// const payloadWoFile = {
		// 	files: [
		// 		{
		// 			fileId: response?.data[0]?.id,
		// 			type: response?.data[0]?.type
		// 		}
		// 	]
		// };

		const payloadWoFile = {
			fileId: response?.data[0]?.id,
			type: response?.data[0]?.type,
			name: response?.data[0]?.name,
			url: response?.data[0]?.url
		};

		setListFiles((currentFiles: any) => [...currentFiles, payloadWoFile]);

		// const createWoFile = await uploadWOFile(payloadWoFile);

		// if (createWoFile.success) {
		//     refetch();
		// }
	};

	// if (isLoading) {
	// 	return <Loading />;
	// }

	return (
		<div className="file-card">
			<div className="file-input">
				<input type="file" name="upload" onChange={handleFile} />

				<button>
					<i>
						<AddIcon />
					</i>
				</button>
			</div>
			<p className="main">Support files</p>
			<p className="info">PDF, JPG, PNG</p>
		</div>
	);
}
