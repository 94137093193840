import React, { useState } from "react";
import OtpInput from "react18-input-otp";
import { useMutation, UseMutationResult } from "react-query";
import {
	Button,
	Card,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupText,
	InputGroup,
	Col
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { CommonService } from "api/axios";
import { useAppDispatch } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import { setUserRole } from "store/features/user/UserSlice";

interface ILogin {
	emailOrPhone: string | number;
	otp: string;
}

const Login = () => {
	// ${API_URL}/common/m/auth/login
	const timer = React.useRef<NodeJS.Timeout | undefined>();
	const [countdownOTP, setCountdownOTP] = useState<number>(10);
	const navigate = useNavigate();
	const [loginInput, setLoginInput] = useState<ILogin>({
		emailOrPhone: "",
		otp: ""
	});
	// const [loginState, setLoginState] = useState({
	// 	emailOrPhoneLoading: false,
	// 	otpLoading: false,
	// 	emailOrPhoneErrorMessage: "",
	// 	otpErrorMessage: "",
	// });
	const [otpMode, setOtpMode] = useState<boolean>(false);
	// const isLoginInputANumber = !isNaN(loginInput.emailOrPhone);
	const signInText = "Masukkan no. telpon kerja untuk melanjutkan";

	const dispatch = useAppDispatch();
	const login = useMutation(
		async (payload: loginAuth) => {
			return await CommonService.login(payload);
		},
		{
			onSuccess: (response) => {
				if ("caches" in window) {
					caches.keys().then(function (cacheNames) {
						cacheNames.forEach(function (cacheName) {
							caches.delete(cacheName);
						});
					});
				}

				// console.log(document.cookie, "cookie?");
				const { data } = response;
				if (data.accessToken) {
					localStorage.setItem("user", data.phone);
					localStorage.setItem("AccessToken", data.accessToken);
					navigate("/database/categories", {
						replace: true
					});
				}
				if (data.roles && Array.isArray(data.roles)) {
					const roles: string[] = [];
					data.roles.forEach((role: string) => {
						roles.push(role.toLocaleLowerCase());
					});

					// const roles = data.roles.map((role: string) =>
					// 	role.toLocaleLowerCase()
					// );
					dispatch(setUserRole(roles));
				}
				if (!otpMode) {
					setOtpMode(true);
				}
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	React.useEffect(() => {
		if (otpMode) {
			timer.current = setInterval(() => {
				if (countdownOTP !== 0) {
					setCountdownOTP((curr) => curr - 1);
				}
			}, 1000);
		} else {
			clearInterval(timer.current);
		}
		return () => {
			clearInterval(timer.current);
		};
	}, [countdownOTP, otpMode]);

	const handleChange = (enteredOtp: string) => {
		setLoginInput((curr) => {
			return {
				...curr,
				otp: enteredOtp
			};
		});
	};

	const handleLogin = (type: "OTP" | "LOGIN") => (e: any) => {
		if (type === "OTP") {
			e.preventDefault();
			// setOtpMode(true);
			const payload: loginAuth = {
				phone: loginInput.emailOrPhone as string
			};
			// // if (isLoginInputANumber) {
			// payload.phone = loginInput.emailOrPhone;
			// // } else {
			// // 	payload.email = loginInput.emailOrPhone;
			// // }
			login.mutate(payload);
		}

		if (type === "LOGIN") {
			const payload = {
				phone: loginInput.emailOrPhone as string,
				otp: loginInput.otp
			};
			login.mutate(payload);
		}
	};

	return (
		<Col
			lg="5"
			md="7"
			className="d-flex flex-column justify-content-center align-items-center"
		>
			<Card className="bg-white border-1">
				<CardBody className="px-lg-5 py-lg-5">
					<div className="d-flex flex-column justify-content-center align-items-center">
						<img
							alt="..."
							src={require("../../assets/img/brand/logo-v4.png")}
							className="logo-brik mb-4"
						/>
						<h3>Akses BOD Web</h3>
					</div>
					{!otpMode ? (
						<FormLogin
							setLoginInput={setLoginInput}
							signInText={signInText}
							login={login}
							loginInput={loginInput}
							handleLogin={handleLogin}
						/>
					) : (
						<FormOtp
							countdownOTP={countdownOTP}
							handleChange={handleChange}
							login={login}
							loginInput={loginInput}
							setCountdownOTP={setCountdownOTP}
							setOtpMode={setOtpMode}
							handleLogin={handleLogin}
						/>
					)}
				</CardBody>
			</Card>
			<div className="login-footer d-flex flex-column align-items-center justify-content-center">
				<img
					alt="..."
					src={require("../../assets/img/brand/logo-text.png")}
					className="logo-brik-text mb-2 mt-4"
				/>
				<p>© 2023 PT Bangun Rancang Indonesia Kita</p>
			</div>
		</Col>
	);
};

export default Login;

interface IFormLogin {
	signInText: string;
	setLoginInput: React.Dispatch<React.SetStateAction<ILogin>>;
	loginInput: ILogin;
	login: UseMutationResult<any, unknown, loginAuth, unknown>;
	handleLogin: (type: "OTP" | "LOGIN") => (e: any) => void;
}

const FormLogin = ({
	signInText,
	setLoginInput,
	loginInput,
	login,
	handleLogin
}: IFormLogin) => {
	return (
		<>
			<div className="text-center text-muted mb-4">
				<small>{signInText}</small>
			</div>
			<Form role="form" onSubmit={handleLogin("OTP")}>
				<FormGroup className="mb-3">
					<InputGroup className="input-bg">
						<InputGroupText className="bg-none border-0">
							{/* {
									!loginInput.emailOrPhone || !isLoginInputANumber ? <i className="ni ni-email-83" /> : null
								}
								{
									!!loginInput.emailOrPhone && isLoginInputANumber ? "+62" : null
								} */}
							{"+62"}
						</InputGroupText>
						<Input
							placeholder="No. Telpon"
							type="text"
							className="bg-none border-0"
							onChange={(e) => {
								setLoginInput((curr) => {
									return {
										...curr,
										emailOrPhone: e.target.value
									};
								});
							}}
							// onKeyPress={(e) => {
							// 	console.log("masuk1");
							// 	if (e.keyCode === 13) {
							// 		e.preventDefault();
							// 		console.log("masuk");
							// 		handleLogin("OTP")(e);
							// 	}
							// }}
							value={loginInput.emailOrPhone}
						/>
					</InputGroup>
				</FormGroup>
				<div className="text-center">
					<Button
						style={{ width: "100%", backgroundColor: "#ed110d" }}
						className="my-4"
						color="primary"
						type="button"
						disabled={!loginInput.emailOrPhone || login.isLoading}
						onClick={handleLogin("OTP")}
					>
						{login.isLoading ? <div id="loading" /> : "Kirim OTP "}
					</Button>
				</div>
			</Form>
		</>
		// <CardBody>
		// </CardBody>
	);
};

interface IFormOtp {
	setOtpMode: React.Dispatch<React.SetStateAction<boolean>>;
	loginInput: ILogin;
	handleChange: (enteredOtp: string) => void;
	countdownOTP: number;
	setCountdownOTP: React.Dispatch<React.SetStateAction<number>>;
	login: UseMutationResult<any, unknown, loginAuth, unknown>;
	handleLogin: (type: "OTP" | "LOGIN") => (e: any) => void;
}

const FormOtp = ({
	setOtpMode,
	loginInput,
	handleChange,
	countdownOTP,
	setCountdownOTP,
	login,
	handleLogin
}: IFormOtp) => {
	return (
		<>
			{/* <Card className="bg-white border-1">
				<a
					className="absolute-back"
					onClick={() => {
						setOtpMode(false);
					}}
				>
					<i className="ni ni-bold-left" />
				</a>
				<CardBody className="px-lg-5 py-lg-5"> */}
			<div className="text-center text-muted mb-4">
				<small>
					Masukkan kode OTP yang sudah di kirim ke +62
					{loginInput.emailOrPhone}.
				</small>
			</div>
			<Form role="form">
				<FormGroup className="mb-3 otp-container">
					<OtpInput
						className="otp-style"
						value={loginInput.otp}
						onChange={handleChange}
						numInputs={6}
						onSubmit={handleLogin("LOGIN")}
					/>
				</FormGroup>
				<div className="text-center">
					<div style={{ display: "flex", justifyContent: "center" }}>
						<p>Tidak menerima OTP?</p>
						&nbsp;
						{countdownOTP ? (
							<p>
								kirim lagi{" "}
								{countdownOTP
									? `(00:${countdownOTP
											.toString()
											.padStart(2, "0")})`
									: ""}
							</p>
						) : (
							<a
								className="resend-otp"
								onClick={() => {
									const payload: loginAuth = {
										phone: loginInput.emailOrPhone as string
									};
									// if (isLoginInputANumber) {
									// payload.phone = loginInput.emailOrPhone;
									// } else {
									// 	payload.email = loginInput.emailOrPhone;
									// }
									setCountdownOTP(10);
									login.mutate(payload);
								}}
							>
								kirim lagi{" "}
								{countdownOTP
									? `(00:${countdownOTP
											.toString()
											.padStart(2, "0")})`
									: ""}
							</a>
						)}
					</div>
					<Button
						style={{ width: "100%", backgroundColor: "#ed110d" }}
						className="my-4"
						color="primary"
						type="button"
						disabled={!loginInput.otp}
						onClick={handleLogin("LOGIN")}
					>
						{login.isLoading ? <div id="loading" /> : "Verifikasi"}
					</Button>
				</div>
			</Form>
			{/* </CardBody>
			</Card> */}
		</>
	);
};
