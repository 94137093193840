import { LoadingButton } from "@mui/lab";
import {
	AccurateService,
	CommonService,
	InventoryService,
	OrderService
} from "api/axios";
import { LoadingSpinner, LocalDialog, Spacer } from "components";
import BDropdown from "components/B/dropdown";
import { useListSearchParams, useLocalDialog } from "hooks";
import { useDebounce } from "use-debounce";
import React, {
	ChangeEvent,
	Dispatch,
	SetStateAction,
	useEffect,
	useState
} from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import ReactSelect, {
	components,
	OptionProps,
	SingleValue
} from "react-select";
import Creatable from "react-select/creatable";
import { Button, Col, Input, Label, Row } from "reactstrap";
import { openAlert } from "store/features/alert/AlertSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { formatDateID } from "helper";
import BForm from "components/B/form";

// interfaces

interface AccurateWarehouse {
	scrapWarehouse: boolean;
	defaultWarehouse: boolean;
	locationId: number;
	optLock: number;
	name: string;
	description: null;
	pic: null;
	id: number;
	suspended: boolean;
}
interface AccurateBranch {
	defaultBranch: boolean;
	name: string;
	id: number;
	suspended: boolean;
}

interface PayloadDO {
	scheduleId?: {
		number: string;
		id: string;
	};
	date?: string;
	quantity?: number;
	sealNumber?: string;
	licenseNumber?: number;
	number?: string;
	driver?: {
		id: string;
		fullName: string;
	};
	truck?: {
		id: string;
		plate_number: string;
		labelStr: string;
		valueStr: string;
	};
	mixDesignId?: string;
	inputSku?: string;
	mixDesign?: {
		id: string;
		name: string;
	};
	inputWoNumber?: string;
	isSameSeal?: boolean;
	customerAccurate?: SingleValue<CustomerAccurate>;
	addressAccurate?: {
		valueStr: number | null;
		labelStr: string;
	} | null;
	addressAccurateText?: string;
	volumeKumulatifAccurate?: number;
	biayaMobilisasiAccurate?: number;
	biayaTolAccurate?: number;
	jarakPengirimanAccurate?: number;
	saleOrderAccurate?: SingleValue<SaleOrderAccurate>;
	saleOrderItem?: SingleValue<DetailItem>;
	loadingStartTime?: string;
	loadingEndTime?: string;
	driverAccurate?: {
		valueStr: string;
		labelStr: string;
	};
	dispatcher?: {
		valueStr: string;
		labelStr: string;
	};
	actualDriver?: SingleValue<
		ActualDrivers & { label: string; value: string; __isNew__: true }
	>;
	warehouse?: SingleValue<AccurateWarehouse>;
	branch?: SingleValue<AccurateBranch>;
}

interface CustomerAccurate {
	mobilePhone: string;
	name: string;
	id: number;
	whatsappNo: string;
	customerNo: string;
	email: string;
}

interface Dispatchers {
	id: string;
	name: string;
}

export default function LinkDeliveryOrder({
	isOpen,
	setIsOpen,
	refetch,
	doNumber,
	woNumber,
	customerAccurateName,
	customerAccurateId,
	customerName,
	defaultCustomer,
	scheduleId,
	doId
}: {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	refetch: () => void;
	doNumber: string;
	woNumber: number;
	customerAccurateName: string;
	customerAccurateId: number;
	customerName: string;
	defaultCustomer?: CustomerAccurate;
	scheduleId?: string;
	doId: string;
}) {
	const dispatch = useAppDispatch();
	const params = useParams();
	const id = params?.id;
	const { page } = useListSearchParams();
	const { bp } = useAppSelector((store) => store.batching);

	const [loadingButton, setIsLoadingButton] = useState(false);

	// payload DO
	const [payloadLinkDO, setPayloadLinkDO] = React.useState<PayloadDO>();

	// batching plant
	const [batchingPlantId, setBatchingPlantId] = useState<string>("");
	const [moistureContent, setMoistureContent] = useState<object[]>([]);
	const [mcDate, setMcDate] = useState<string>("");
	const [bpName, setBpName] = useState<string>("");

	// sku
	const [skuSelected, setSkuSelected] = React.useState<any>({});

	// WO's
	const [WOs, setWOs] = React.useState<DeliveryOrder[]>([]);

	// address accurate
	const [addressAccurate, setAddressAccurate] = React.useState<string>("");

	// customer accurate states
	const [customerPaging, setCustomerPaging] = React.useState(1);
	const [searchCustomer, setSearchCustomer] =
		React.useState(customerAccurateName);

	const [searchCustomerDebounced] = useDebounce(searchCustomer, 500);
	const [customersList, setCustomersList] = useState<CustomerAccurate[]>([]);

	// so states
	const [soAccurateId, setSOAccurateId] = React.useState<number>();
	const [sorPaging, setSoPaging] = React.useState(1);
	const [searchSo, setSearchSo] = useState("");
	const [searchSoDebounced] = useDebounce(searchSo, 500);

	const [optionTrucks, setOptionTrucks] = React.useState<any[]>([]);
	const [optionDrivers, setOptionDrivers] = React.useState<any[]>([]);
	const [shouldShowSealInput, setShouldShowSealInput] =
		React.useState<boolean>(true);

	// accurate disable checking
	const { isAccurateDisabled: accurateStatusDisabled } = useAppSelector(
		(state) => state.setting
	);
	const isAccurateDisabled =
		process.env.REACT_APP_IS_ACCURATE_DISABLED === "true" ||
		accurateStatusDisabled;

	// address state
	const [selectedAddress, setSelectedAddress] = React.useState<{
		labelStr: string;
		valueStr: number | null;
	} | null>(null);

	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		}
	}, [isOpen]);

	// schedule detail fetch
	const { isLoading, data } = useQuery<{ data: ScheduleResponse }, ApiError>(
		["query-schedule"],
		async () => {
			return await OrderService.getOneSchedule(scheduleId ?? "");
		},
		{
			onSuccess(res) {
				const WOs = res.data.WorkOrders;
				setWOs(WOs as any);

				const SKU = res.data.SaleOrder.PoProduct.RequestedProduct.SKU;
				setSkuSelected(SKU);

				const accurateAddress = res.data.Project.addressAccurate;
				setAddressAccurate(accurateAddress as string);

				const soAccurateId = res.data.PurchaseOrder.saleOrderAccurateId;
				setSOAccurateId(soAccurateId);
			},
			enabled: true,
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	// find its WO
	const foundItem = WOs.find((item) => item.deliveryOrderId === id);

	const idWOSelected = {
		id: foundItem?.id,
		number: foundItem?.number,
		date: foundItem?.date,
		quantity: foundItem?.quantity
	};

	// useEffect
	useEffect(() => {
		if (data) {
			const bpId = data?.data?.batchingPlantId;
			const initialPayload: PayloadDO = {
				scheduleId: {
					id: data.data.id as string,
					number: data.data.number as string
				},
				inputWoNumber: idWOSelected?.number?.toString(),
				inputSku: `${skuSelected?.sku}`,
				quantity: idWOSelected.quantity,
				date: idWOSelected?.date?.toString().split("T")[0] as string,
				addressAccurate: {
					valueStr: null,
					labelStr: addressAccurate || ""
				},
				volumeKumulatifAccurate:
					WOs.reduce((acc, curr) => {
						if (curr.number === idWOSelected.number) {
							return acc;
						}
						if (
							curr.status === "CANCELLED" ||
							curr.status === "REJECTED" ||
							curr.status === "DECLINED"
						) {
							return acc;
						}
						return acc + curr.quantity;
					}, 0) + idWOSelected.quantity!
			};
			setPayloadLinkDO(initialPayload);
			setBatchingPlantId(bpId as string);
		}
	}, [data]);

	useEffect(() => {
		if (addressAccurate) {
			setSelectedAddress({
				valueStr: null,
				labelStr: addressAccurate
			});
		}
	}, []);

	// customer accurate call
	const { data: customersAccurate } = useQuery(
		["query-customer", customerPaging, searchCustomerDebounced],
		async () => {
			return await AccurateService.getAccurateCustomers({
				page: customerPaging,
				keywords: searchCustomerDebounced
			});
		},
		{
			onSuccess(data) {
				setCustomersList((prev) => {
					return prev.concat(data?.data?.d);
				});

				const defaultValueCustomer = data?.data?.d?.find(
					(cust: CustomerAccurate) => {
						return cust.id === customerAccurateId;
					}
				);

				if (defaultValueCustomer) {
					setPayloadLinkDO((prev) => ({
						...prev,
						customerAccurate: defaultValueCustomer
					}));
				}
			},
			onError(err: any) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
				let message = "Accurate Error/ Tolong Login Ulang";

				if (err?.response?.data?.error?.data?.error) {
					message = `Accurate Error/ ${err?.response?.data?.error?.status} - ${err?.response?.data?.error?.data?.error}`;
				}
				dispatch(
					openAlert({
						body: message,
						color: "danger"
					})
				);
			},
			enabled: !isAccurateDisabled
		}
	);

	// detail customer accurate
	const {
		data: accurateCustomerDetails,
		isLoading: accurateCustomerDetailsLoading
	} = useQuery(
		["query-customer-details", payloadLinkDO?.customerAccurate?.id],
		async () => {
			const res = await AccurateService.getAccurateCustomer({
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				id: payloadLinkDO!.customerAccurate!.id
			});

			setSelectedAddress({
				valueStr: null,
				labelStr: res.data?.shipStreet || ""
			});
			setPayloadLinkDO((prev) => ({
				...prev,
				addressAccurateText: res.data?.shipStreet || addressAccurate
			}));

			return {
				addresses: res.data?.detailShipAddress
					.map((address: any) => {
						return {
							labelStr: address.address,
							valueStr: address.id
						};
					})
					.concat([
						{
							labelStr: res.data.shipStreet,
							valueStr: null
						}
					]),
				shipStreet: res.data.shipStreet,
				branchId: res.data.branchId
			};
		},
		{
			enabled: !!payloadLinkDO?.customerAccurate?.id,
			onError(err: any) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
				let message = "Accurate Error/ Tolong Login Ulang";

				if (err?.response?.data?.error?.data?.error) {
					message = `Accurate Error/ ${err?.response?.data?.error?.status} - ${err?.response?.data?.error?.data?.error}`;
				}
				dispatch(
					openAlert({
						body: message,
						color: "danger"
					})
				);
			}
		}
	);

	const { data: saleOrders } = useQuery(
		[
			"query-dispatch-driver",
			payloadLinkDO?.customerAccurate,
			searchSoDebounced,
			sorPaging
		],
		async () => {
			return await AccurateService.getAccurateSalesOrder({
				customerId: payloadLinkDO?.customerAccurate?.id,
				keywords: searchSoDebounced,
				page: sorPaging
			});
		},
		{
			enabled: !!payloadLinkDO?.customerAccurate && !isAccurateDisabled,
			onSuccess(data) {
				const defaultValueSo = data?.data?.d?.find(
					(so: SaleOrderAccurate) => {
						return so.id === soAccurateId;
					}
				);
				if (defaultValueSo) {
					setPayloadLinkDO((prev) => ({
						...prev,
						saleOrderAccurate: defaultValueSo,
						branch:
							branches?.data?.d?.find(
								(branch: AccurateBranch) => {
									return (
										branch.id === defaultValueSo?.branchId
									);
								}
							) || branches?.data?.d[0]
					}));
				}
			},
			onError(err: any) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
				let message = "Accurate Error/ Tolong Login Ulang";

				if (err?.response?.data?.error?.data?.error) {
					message = `Accurate Error/ ${err?.response?.data?.error?.status} - ${err?.response?.data?.error?.data?.error}`;
				}
				dispatch(
					openAlert({
						body: message,
						color: "danger"
					})
				);
			}
		}
	);

	const { data: saleOrderDetail } = useQuery(
		["query-so-detail", payloadLinkDO?.saleOrderAccurate],
		async () => {
			return await AccurateService.getAccurateSalesOrderDetail({
				id: payloadLinkDO?.saleOrderAccurate?.id
			});
		},
		{
			enabled: !!payloadLinkDO?.saleOrderAccurate,
			onError(err: any) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
				let message = "Accurate Error/ Tolong Login Ulang";

				if (err?.response?.data?.error?.data?.error) {
					message = `Accurate Error/ ${err?.response?.data?.error?.status} - ${err?.response?.data?.error?.data?.error}`;
				}
				dispatch(
					openAlert({
						body: message,
						color: "danger"
					})
				);
			}
		}
	);

	const { data: doNumbers } = useQuery(
		["query-do-numbers", bp.id],
		async () => {
			const res = await OrderService.getDONumbers(
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				bp.id!
			);

			setPayloadLinkDO((prev) => {
				return {
					...prev,
					number: res.data.next
				};
			});
			return res.data;
		},
		{
			enabled: !!bp.id
		}
	);

	const { data: dispatchers } = useQuery(
		["query-dispatchers"],
		async () => {
			return await CommonService.getDispatchers({ size: 100 });
		},
		{
			enabled: true,
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const { data: branches } = useQuery(
		["query-branches"],
		async () => {
			return await AccurateService.getAccurateBranch({});
		},
		{
			onError(err: any) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
				let message = "Accurate Error/ Tolong Login Ulang";

				if (err?.response?.data?.error?.data?.error) {
					message = `Accurate Error/ ${err?.response?.data?.error?.status} - ${err?.response?.data?.error?.data?.error}`;
				}
				dispatch(
					openAlert({
						body: message,
						color: "danger"
					})
				);
			},
			enabled: !isAccurateDisabled
		}
	);

	const { data: warehouses } = useQuery(
		["query-warehouses"],
		async () => {
			return await AccurateService.getAccurateWarehouse({});
		},
		{
			enabled: !isAccurateDisabled,
			onError(err: any) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
				let message = "Accurate Error/ Tolong Login Ulang";

				if (err?.response?.data?.error?.data?.error) {
					message = `Accurate Error/ ${err?.response?.data?.error?.status} - ${err?.response?.data?.error?.data?.error}`;
				}
				dispatch(
					openAlert({
						body: message,
						color: "danger"
					})
				);
			}
		}
	);

	const { data: drivers } = useQuery(
		["query-actual-driver"],
		async () => {
			return await OrderService.getActualDrivers();
		},
		{
			onError(err: any) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
				let message = "Accurate Error/ Tolong Login Ulang";

				if (err?.response?.data?.error?.data?.error) {
					message = `Accurate Error/ ${err?.response?.data?.error?.status} - ${err?.response?.data?.error?.data?.error}`;
				}
				dispatch(
					openAlert({
						body: message,
						color: "danger"
					})
				);
			}
		}
	);

	useQuery<ApiDrivers, ApiError>(
		["query-driver", page],
		async () => {
			return await CommonService.getAllDrivers();
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res.data.map((el) => {
					return {
						...el,
						id: el.id,
						valueStr: el.id,
						labelStr: el.fullName
					};
				});
				setOptionDrivers(data);
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);
	useQuery<ApiVehicles, ApiError>(
		["query-vehicle", page],
		async () => {
			return await InventoryService.getAllVehicles();
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res.data.map((el) => {
					return {
						...el,
						id: el.id,
						text: `${el?.internalId ?? el?.internal_id} / ${
							el?.plateNumber ?? el?.plate_number
						}`,
						valueStr: el?.plateNumber ?? el?.plate_number,
						labelStr: `${el?.internalId ?? el?.internal_id} / ${
							el?.plateNumber ?? el?.plate_number
						}`
					};
				});
				setOptionTrucks(data);
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	// get on moisture content by batching plant
	const { data: moistureContentData } = useQuery(
		["query-moisture-content", batchingPlantId],
		async () => {
			return await InventoryService.getOneMoistureContentByBp(
				batchingPlantId
			);
		},
		{
			enabled: true,
			onSuccess: (res) => {
				setMcDate(formatDateID(new Date(res?.data?.updatedAt)));
				setBpName(res?.data?.BatchingPlant?.name ?? "-");
				try {
					const moisutureData = [
						{
							name: "MC Pasir",
							data: `${res?.data?.mcPasir ?? "-"} %`
						},
						{
							name: "MC Pasir",
							data: `${res?.data?.mcAbuBatu ?? "-"} %`
						},
						{
							name: "MC Split",
							data: `${res?.data?.mcSplit ?? "-"} %`
						},
						{
							name: "MC Screening",
							data: `${res?.data?.mcScreening ?? "-"} %`
						}
					];
					setMoistureContent(moisutureData);
				} catch (error) {
					console.log(error);
				}
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const Option = (props: OptionProps<CustomerAccurate>) => {
		return (
			<div
				style={{
					padding: "0px 10px"
				}}
			>
				{props.data.customerNo}
				<components.Option {...props} />
				<p
					style={{
						fontSize: "medium"
					}}
				>
					HP: <b>{props.data.mobilePhone}</b>
					WA:<b>{props.data.whatsappNo}</b>
					email: <b>{props.data.email}</b>
				</p>
			</div>
		);
	};

	const forms: ElementForm[] = React.useMemo(() => {
		const _form: ElementForm[] = [
			{
				label: "Tanggal",
				type: "date",
				value: payloadLinkDO?.date,
				onChange: (e: ChangeEvent<HTMLInputElement>) =>
					setPayloadLinkDO({
						...payloadLinkDO,
						date: e.target.value
					}),
				placeholder: "Pilih Tanggal",
				min: true
			},
			{
				label: "Kuantitas (m³)",
				type: "number",
				value: payloadLinkDO?.quantity,
				onChange: (e: ChangeEvent<HTMLInputElement>) =>
					setPayloadLinkDO({
						...payloadLinkDO,
						quantity: Number(e.target.value)
					}),
				placeholder: "Masukan Jumlah"
			},
			{
				label: "Truk",
				type: "dropdown",
				value: payloadLinkDO?.truck,
				onChange: (e: any) => {
					setPayloadLinkDO({
						...payloadLinkDO,
						truck: e
					});
				},
				placeholder: "Pilih Truk",
				options: optionTrucks
			},
			{
				label: "HP TM",
				type: "dropdown",
				value: payloadLinkDO?.driver,
				onChange: (e: any) => {
					setPayloadLinkDO({
						...payloadLinkDO,
						driver: e
					});
				},
				placeholder: "Pilih HP TM",
				options: optionDrivers
			},
			{
				label: "Nomor Seal Sama Dengan Nomor DO",
				type: "switch",
				value: shouldShowSealInput,
				onChange: (e: ChangeEvent<HTMLInputElement>) => {
					const newState = {
						...payloadLinkDO,
						isSameSeal: e.target.checked
					};
					if (!shouldShowSealInput) {
						newState.sealNumber = "";
					}
					setPayloadLinkDO(newState);
					setShouldShowSealInput(e.target.checked);
				}
			}
		];

		if (!shouldShowSealInput) {
			_form.push({
				label: "Nomor Seal",
				type: "text",
				value: payloadLinkDO?.sealNumber,
				onChange: (e: ChangeEvent<HTMLInputElement>) =>
					setPayloadLinkDO({
						...payloadLinkDO,
						sealNumber: e.target.value
					}),
				placeholder: "Masukan Seal"
			});
		}

		return _form;
	}, [optionTrucks, payloadLinkDO, optionDrivers, customersAccurate]);

	const accurateForm: ElementForm[] = React.useMemo(() => {
		const _form: ElementForm[] = [
			{
				label: "Nama Dispatcher",
				type: "dropdown",
				placeholder: "Ketik nama dispatcher",
				options: dispatchers?.data?.data?.map(
					(dispatcher: Dispatchers) => {
						return {
							valueStr: dispatcher.name,
							labelStr: dispatcher.name
						};
					}
				),
				value: payloadLinkDO?.dispatcher,
				onChange: (e: any) => {
					setPayloadLinkDO({
						...payloadLinkDO,
						dispatcher: e
					});
				}
			},
			{
				// TODO: ensure backend has new logic for this
				label: "SKU" + " " + skuSelected?.description,
				type: "text",
				value: payloadLinkDO?.inputSku,
				placeholder: "SKU",
				onChange: (e: ChangeEvent<HTMLInputElement>) => {
					setPayloadLinkDO({
						...payloadLinkDO,
						inputSku: e.target.value
					});
				},
				disable: false
			},
			// {
			// 	label: "Nama Driver Accurate",
			// 	type: "dropdown",
			// 	placeholder: "Ketik nama driver",
			// 	options: driverDispatch?.data?.d?.driver?.map(
			// 		(driver: string) => {
			// 			return {
			// 				valueStr: driver,
			// 				labelStr: driver
			// 			};
			// 		}
			// 	),
			// 	value: payloadDO?.driverAccurate,
			// 	onChange: (e: any) => {
			// 		setPayloadLinkDO({
			// 			...payloadDO,
			// 			driverAccurate: e
			// 		});
			// 	}
			// },
			{
				label: "Jam Mulai Loading",
				type: "time",
				value: payloadLinkDO?.loadingStartTime,
				disable: false,
				onChange: (e: ChangeEvent<HTMLInputElement>) => {
					setPayloadLinkDO({
						...payloadLinkDO,
						loadingStartTime: e.target.value
					});
				}
			},
			{
				label: "Jam Selesai Loading",
				type: "time",
				value: payloadLinkDO?.loadingEndTime,
				disable: false,
				onChange: (e: ChangeEvent<HTMLInputElement>) => {
					setPayloadLinkDO({
						...payloadLinkDO,
						loadingEndTime: e.target.value
					});
				}
			}
		];
		return _form;
	}, [customersAccurate, dispatchers, payloadLinkDO]);

	const accurateSecond: ElementForm[] = React.useMemo(() => {
		return [
			{
				label: "Biaya Mobilisasi (IDR)",
				type: "number",
				value: payloadLinkDO?.biayaMobilisasiAccurate,
				disable: false,
				onChange: (e: ChangeEvent<HTMLInputElement>) => {
					setPayloadLinkDO({
						...payloadLinkDO,
						biayaMobilisasiAccurate: Number(e.target.value)
					});
				}
			},
			{
				label: "Volume Kumulatif (m³)",
				type: "number",
				value: payloadLinkDO?.volumeKumulatifAccurate,
				disable: false,
				onChange: (e: ChangeEvent<HTMLInputElement>) => {
					setPayloadLinkDO({
						...payloadLinkDO,
						volumeKumulatifAccurate: Number(e.target.value)
					});
				}
			},
			{
				label: "Biaya Tol (IDR)",
				type: "number",
				value: payloadLinkDO?.biayaTolAccurate,
				disable: false,
				onChange: (e: ChangeEvent<HTMLInputElement>) => {
					setPayloadLinkDO({
						...payloadLinkDO,
						biayaTolAccurate: Number(e.target.value)
					});
				}
			},
			{
				label: "Jarak Pengiriman (KM)",
				type: "number",
				value: payloadLinkDO?.jarakPengirimanAccurate,
				disable: false,
				onChange: (e: ChangeEvent<HTMLInputElement>) => {
					setPayloadLinkDO({
						...payloadLinkDO,
						jarakPengirimanAccurate: Number(e.target.value)
					});
				}
			}
		];
	}, [payloadLinkDO]);

	const [localDialog, setLocalDialog] = React.useState({
		isOpen: false,
		onClose: () => setLocalDialog({ ...localDialog, isOpen: false }),
		onOpen: () => setLocalDialog({ ...localDialog, isOpen: true })
	});

	const { mutateAsync } = useMutation(
		async (payload: Partial<deliveryOrderArgAccurate>) => {
			return await AccurateService.linkDeliveryOrder(doId, { payload });
		},
		{
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data?.message,
						color: "danger"
					})
				);
				setIsLoadingButton(false);
			}
		}
	);

	const { mutateAsync: updateDo, isLoading: updateLoading } = useMutation(
		async (payload: PayloadCreateDO) => {
			return await OrderService.updateDO(payload.id as string, payload);
		},
		{
			onSuccess: (response) => {
				refetch();
				localDialog.onClose();
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const onSubmit = async () => {
		try {
			setIsLoadingButton(true);
			if (
				!payloadLinkDO?.quantity ||
				Number(payloadLinkDO?.quantity) <= 0
			) {
				dispatch(
					openAlert({
						body: "masukan jumlah DO",
						color: "danger"
					})
				);
				return;
			}

			if (!payloadLinkDO?.customerAccurate) {
				dispatch(
					openAlert({
						body: "pilih pelanggan accurate",
						color: "danger"
					})
				);
				return;
			}
			// if (!payloadLinkDO?.date) {
			// 	dispatch(
			// 		openAlert({
			// 			body: "pilih tanggal DO",
			// 			color: "danger"
			// 		})
			// 	);
			// 	return;
			// }
			if (!payloadLinkDO?.saleOrderItem) {
				dispatch(
					openAlert({
						body: "pilih barang SO",
						color: "danger"
					})
				);
				return;
			}

			if (!payloadLinkDO?.dispatcher) {
				dispatch(
					openAlert({
						body: "pilih dispatcher",
						color: "danger"
					})
				);
				return;
			}

			if (!payloadLinkDO?.actualDriver) {
				dispatch(
					openAlert({
						body: "pilih driver",
						color: "danger"
					})
				);
				return;
			}

			const inputDateString = payloadLinkDO.date;

			// Create a Date object from the input string
			const inputDate = new Date(inputDateString as string);

			// Extract day, month, and year components
			const day = inputDate.getDate();
			const month = inputDate.getMonth() + 1; // Months are zero-based, so add 1
			const year = inputDate.getFullYear();

			// Format the date in the desired format
			const formattedDate = day + "/" + month + "/" + year;

			const accurateDoPayload: Partial<deliveryOrderArgAccurate> = {
				scheduleId: scheduleId,
				number: doNumber,
				toAddress: payloadLinkDO?.addressAccurateText,
				customerNo: payloadLinkDO.customerAccurate?.customerNo,
				transDate: formattedDate,
				itemNo: payloadLinkDO?.saleOrderItem?.item?.no,
				quantity: payloadLinkDO?.quantity,
				salesOrderNumber: payloadLinkDO?.saleOrderAccurate?.number,
				noTm: payloadLinkDO?.truck?.labelStr,
				driver: payloadLinkDO?.actualDriver?.label,
				startLoadingTime: payloadLinkDO?.loadingStartTime,
				endLoadingTime: payloadLinkDO?.loadingEndTime,
				dispatcher: payloadLinkDO?.dispatcher?.valueStr,
				skuNo: payloadLinkDO?.inputSku,
				woNo: payloadLinkDO?.inputWoNumber,
				cumulativeVolume: payloadLinkDO?.volumeKumulatifAccurate,
				mobilizationFee: payloadLinkDO?.biayaMobilisasiAccurate,
				deliveryDistance: payloadLinkDO?.jarakPengirimanAccurate,
				tollFee: payloadLinkDO?.biayaTolAccurate,
				warehouseName: payloadLinkDO?.warehouse?.name,
				branchId: payloadLinkDO?.branch?.id
			};

			const data = await mutateAsync(accurateDoPayload);

			if (!data?.data?.s) {
				throw data?.data?.d;
			}

			const accurateDoId = data?.data?.r?.id;

			await updateDo({
				id: id,
				deliveryOrderAccurateId: accurateDoId,
				accurateData: {
					...accurateDoPayload,
					customerAccurate: payloadLinkDO.customerAccurate,
					itemSalesOrder: payloadLinkDO?.saleOrderItem,
					salesOrderAccurate: payloadLinkDO?.saleOrderAccurate,
					warehouse: payloadLinkDO?.warehouse,
					branch: payloadLinkDO?.branch,
					address: payloadLinkDO?.addressAccurateText,
					detailItemId: data?.data.r?.detailItem[0]?.id
				}
			});

			refetch();
			localDialog.onClose();

			dispatch(
				openAlert({
					body: "Berhasil Link Delivery Order dengan Accurate",
					color: "success"
				})
			);
			setIsOpen(false);
		} catch (error: any) {
			console.log(error, "error accurate");
			if (error.length) {
				dispatch(
					openAlert({
						body: error?.join(","),
						color: "danger"
					})
				);
				refetch();
				localDialog.onClose();
				setIsOpen(false);
			} else {
				dispatch(
					openAlert({
						body: error.response?.data.message,
						color: "danger"
					})
				);
			}
		} finally {
			setIsLoadingButton(false);
		}
	};

	return (
		<>
			<LocalDialog
				isOpen={localDialog.isOpen}
				backdrop={"static"}
				header="Link Delivery Order to Accurate"
				loading={loadingButton}
				onClick={onSubmit}
				onClose={() => {
					localDialog.onClose();
					setTimeout(() => {
						setIsOpen(false);
					}, 500);
				}}
				bodyStyle={{
					width: "1000px"
				}}
			>
				<div>
					<Row>
						<Col md="12">
							<label>Nomor DO</label>
							<div>
								<Label style={{ color: "grey" }}>
									{doNumber}
								</Label>
							</div>
						</Col>
					</Row>

					<Row>
						<Col md="12">
							<label>Nomor WO</label>
							<div>
								<Label style={{ color: "grey" }}>
									{woNumber}
								</Label>
							</div>
						</Col>
					</Row>

					<Row>
						<Col md="12">
							<label>Nama Pelanggan:</label>
							<div>
								<Label style={{ color: "grey" }}>
									{customerName}
								</Label>
							</div>
						</Col>
					</Row>

					<Row>
						<Col md="12">
							<label>
								Pelanggan Accurate
								<span
									style={{
										color: "red",
										marginLeft: "5px"
									}}
								>
									*
								</span>
							</label>

							<ReactSelect
								options={customersList}
								components={{ Option }}
								getOptionLabel={(option: CustomerAccurate) =>
									option.name
								}
								getOptionValue={(option: CustomerAccurate) =>
									option.customerNo
								}
								onMenuScrollToBottom={() => {
									if (
										customersAccurate?.data?.sp?.pageCount >
										customerPaging
									) {
										setCustomerPaging((prev) => prev + 1);
									}
								}}
								onInputChange={(inputValue: string) => {
									setSearchCustomer(inputValue);
								}}
								onChange={(e) => {
									setPayloadLinkDO((prev) => {
										return {
											...prev,
											addressAccurate: null,
											saleOrderAccurate: null,
											saleOrderItem: null,
											customerAccurate: e
										};
									});
								}}
								isMulti={false}
								required
								defaultValue={defaultCustomer}
								isDisabled={isAccurateDisabled}
							/>
						</Col>
					</Row>
					<Spacer size="small" />

					{/* alamat */}
					<Row>
						<Col md="12">
							<label>Alamat</label>
							<BDropdown
								type="dropdown"
								label="Alamat Pengiriman"
								isDisabled={
									!payloadLinkDO?.customerAccurate ||
									isAccurateDisabled
								}
								options={
									accurateCustomerDetails?.addresses || []
								}
								onChange={(e: any) => {
									setSelectedAddress(e);
									setPayloadLinkDO((prev) => {
										return {
											...prev,
											addressAccurateText: e.labelStr
										};
									});
								}}
								isMulti={false}
								placeholder={`${
									accurateCustomerDetails?.addresses
										? accurateCustomerDetails?.addresses
												?.length
										: 0
								} Pilihan Alamat`}
								defaultValue={`${
									accurateCustomerDetails?.addresses
										? accurateCustomerDetails?.addresses
												?.length
										: 0
								} Pilihan Alamat`}
								value={
									selectedAddress
										? selectedAddress.labelStr
										: ""
								}
							/>
						</Col>
					</Row>
					<Spacer size="small" />

					{/* field address */}
					<Row>
						<Col md="12">
							<label>
								Alamat Pengiriman
								<span
									style={{
										color: "red",
										marginLeft: "5px"
									}}
								>
									*
								</span>
							</label>
							{accurateCustomerDetailsLoading ? (
								<div
									style={{
										width: "100%",
										height: "100px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center"
									}}
								>
									<LoadingSpinner />
								</div>
							) : (
								<Input
									type="textarea"
									value={payloadLinkDO?.addressAccurateText}
									disabled={
										!payloadLinkDO?.customerAccurate &&
										!(
											payloadLinkDO?.addressAccurate &&
											accurateCustomerDetails?.addresses
												.length > 0
										)
									}
									rows={4}
									onChange={(e) => {
										// setSelectedAddress((prev) => ({
										// 	valueStr: prev?.valueStr || null,
										// 	labelStr: e.target.value
										// }));
										setPayloadLinkDO((prev) => {
											return {
												...prev,
												addressAccurateText:
													e.target.value
											};
										});
									}}
								/>
							)}
						</Col>
					</Row>
					<Spacer size="small" />

					<Row>
						<Col md="12">
							<label>
								Sale Order / Pesanan Accurate
								<span
									style={{
										color: "red",
										marginLeft: "5px"
									}}
								>
									*
								</span>
							</label>
							<ReactSelect
								options={saleOrders?.data?.d || []}
								value={payloadLinkDO?.saleOrderAccurate}
								getOptionLabel={(option: SaleOrderAccurate) =>
									option.number
								}
								getOptionValue={(option: SaleOrderAccurate) =>
									option.number
								}
								onChange={(e) => {
									setPayloadLinkDO((prev) => {
										return {
											...prev,
											saleOrderAccurate: e,
											saleOrderItem: null,
											branch:
												branches?.data?.d?.find(
													(
														branch: AccurateBranch
													) => {
														return (
															branch.id ===
															payloadLinkDO
																?.saleOrderAccurate
																?.branchId
														);
													}
												) || branches?.data?.d[0]
										};
									});
								}}
								isMulti={false}
								// value={payloadLinkDO?.customerAccurate}
								isDisabled={!payloadLinkDO?.customerAccurate}
								required
								onInputChange={(input: string) => {
									setSearchSo(input);
								}}
								onMenuScrollToBottom={() => {
									if (
										saleOrders?.data?.sp?.pageCount >
										sorPaging
									) {
										setSoPaging((prev) => prev + 1);
									}
								}}
							/>
						</Col>
					</Row>
					<Spacer size="small" />

					<Row>
						<Col md="12">
							<label>
								Barang Sales Order
								<span
									style={{
										color: "red",
										marginLeft: "5px"
									}}
								>
									*
								</span>
							</label>
							<ReactSelect
								options={
									saleOrderDetail?.data?.d?.detailItem || []
								}
								value={payloadLinkDO?.saleOrderItem}
								getOptionLabel={(option: DetailItem) =>
									option.item.no +
									" - " +
									option.item.name +
									" / " +
									`${option.availableQuantity} M³`
								}
								getOptionValue={(option: DetailItem) =>
									option.item.no
								}
								onChange={(e) => {
									setPayloadLinkDO((prev) => {
										return {
											...prev,
											saleOrderItem: e,
											warehouse:
												warehouses?.data?.d?.find(
													(
														warehouse: AccurateWarehouse
													) => {
														return (
															warehouse.id ===
															payloadLinkDO
																?.saleOrderItem
																?.warehouseId
														);
													}
												) || warehouses?.data?.d[0]
										};
									});
								}}
								isMulti={false}
								// value={payloadLinkDO?.customerAccurate}
								isDisabled={!payloadLinkDO?.saleOrderAccurate}
								required
							/>
						</Col>
					</Row>
					<Spacer size="small" />

					<Row>
						<Col md="12">
							<label>
								Cabang / BP
								<span
									style={{
										color: "red",
										marginLeft: "5px"
									}}
								>
									*
								</span>
							</label>
							{payloadLinkDO?.saleOrderAccurate ? (
								<ReactSelect
									options={branches?.data?.d || []}
									value={payloadLinkDO?.branch}
									defaultValue={
										branches?.data?.d?.find(
											(branch: AccurateBranch) => {
												return (
													branch.id ===
													payloadLinkDO
														?.saleOrderAccurate
														?.branchId
												);
											}
										) || branches?.data?.d[0]
									}
									getOptionLabel={(option: AccurateBranch) =>
										option.name
									}
									getOptionValue={(option: AccurateBranch) =>
										option.id.toString()
									}
									onChange={(e) => {
										setPayloadLinkDO((prev) => {
											return {
												...prev,
												branch: e
											};
										});
									}}
									isMulti={false}
									required
								/>
							) : null}
						</Col>
					</Row>
					<Spacer size="small" />

					<Row>
						<Col md="12">
							<label>
								Driver
								<span
									style={{
										color: "red",
										marginLeft: "5px"
									}}
								>
									*
								</span>
							</label>
							<Creatable
								isClearable
								options={
									drivers?.data?.map(
										(driver: ActualDrivers) => {
											return {
												...driver,
												label: driver.name,
												value: driver.id
											};
										}
									) || []
								}
								onChange={(e) => {
									setPayloadLinkDO((prev: any) => {
										return {
											...prev,
											actualDriver: e
										};
									});
								}}
								isMulti={false}
								required
							/>
						</Col>
					</Row>
					<Spacer size="small" />

					{/* forms */}
					<BForm forms={accurateForm} />
					<div
						style={{
							display: "flex",
							gap: "40px"
						}}
					>
						<div
							style={{
								flex: 1
							}}
						>
							<BForm forms={forms} />
						</div>
						<div
							style={{
								flex: 1
							}}
						>
							<BForm forms={accurateSecond} />
						</div>
					</div>
				</div>
			</LocalDialog>
		</>
	);
}
