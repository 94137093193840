import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useMemo,
	useState
} from "react";
import { LocalDialog, MTable, StatusChip } from "components";
import { useLocalDialog } from "hooks";
import { CommonService } from "api/axios";
import { useMutation, useQuery } from "react-query";
import {
	Button,
	Card,
	CardBody,
	CloseButton,
	UncontrolledCollapse
} from "reactstrap";
import { useDebounce } from "use-debounce";
import { MUIDataTableColumnDef } from "mui-datatables";
import { XOctagon } from "react-bootstrap-icons";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import { useParams } from "react-router-dom";

export default function MergeProjects({
	isOpen,
	setIsOpen,
	refetch: refetchCustomer
}: {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	refetch: () => void;
}) {
	const dispatch = useAppDispatch();
	const { localDialog } = useLocalDialog();
	const [searchQ, setSearchQ] = useState<string | undefined>(undefined);
	const [searchDebounced] = useDebounce(searchQ, 500);
	const [page, setPage] = useState(1);
	const [totalItems, setTotalItems] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [projectMaster, setCustomerMaster] = useState<any>(undefined);
	const [selectedCustomer, setSelectedCustomer] = useState<string[]>([]);
	const params = useParams();
	const id = params?.id;
	const custColumn: MUIDataTableColumnDef[] = [
		{
			name: "id",
			label: "ID",
			options: {
				filter: false,
				sort: false
			}
		},
		{
			name: "name",
			label: "Nama Proyek",
			options: {
				filter: false,
				sort: false
			}
		},
		{
			name: "ShippingAddress",
			label: "Alamat Pengiriman",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value: any) => {
					if (value?.line2) {
						return value.line2;
					}
					return "-";
				}
			}
		},
		// {
		// 	name: "lastOrder",
		// 	label: "PEMESANAN TERAKHIR",
		// 	options: {
		// 		filter: false,
		// 		sort: false
		// 	}
		// },
		{
			name: "Pic",
			label: "PIC",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value: any) => {
					if (value?.name) {
						return value.name;
					}
					return "-";
				}
			}
		},
		{
			name: "Pic",
			label: "No. Tel PIC",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value: any) => {
					if (value?.phone) {
						return value.phone;
					}
					return "-";
				}
			}
		},
		{
			name: "User",
			label: "SALES",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value: any) => {
					if (value?.fullName) {
						return value.fullName;
					}
					return "-";
				}
			}
		},
		// {
		// 	name: "type",
		// 	label: "Tipe Proyek",
		// 	options: {
		// 		filter: false,
		// 		sort: false,
		// 		customBodyRender: (value: any) => {
		// 			if (!value) {
		// 				return "-";
		// 			} else {
		// 				if (value === "COMPANY") {
		// 					return <StatusChip value={"PERUSAHAAN"} />;
		// 				}
		// 				return <StatusChip value={"INDIVIDU"} />;
		// 			}
		// 		}
		// 	}
		// },
		{
			name: "id",
			label: "Action",
			options: {
				filter: false,
				sort: false,
				customBodyRenderLite(dataIndex, rowIndex) {
					if (!projectMaster) {
						return (
							<Button
								onClick={() => {
									setCustomerMaster(
										projectsData?.data?.data[dataIndex]
									);
								}}
							>
								Pilih Master
							</Button>
						);
					}
					return "-";
				}
			}
		}
	];
	const { isLoading, data: projectsData } = useQuery(
		["query-projects", page, searchDebounced],
		async () => {
			return await CommonService.getProjectsByCustomer({
				page,
				size: rowsPerPage,
				search: searchDebounced,
				customerId: id as string
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				// const data = res.data.data.map((el, i) => ({
				// 	id: el.id,
				// 	values: [
				// 		{
				// 			objectKey: "id",
				// 			value: el.id, //15 is size data, look axios service,
				// 			type: "text",
				// 			render: false
				// 		},
				// 		{
				// 			objectKey: "no",
				// 			value: i + 1 + 15 * (page - 1), //15 is size data, look axios service,
				// 			type: "text"
				// 		},
				// 		{
				// 			objectKey: "type",
				// 			value:
				// 				el.type === "COMPANY"
				// 					? "PERUSAHAAN"
				// 					: el.type,
				// 			type: "text"
				// 		},
				// 		{
				// 			objectKey: "name",
				// 			value: el.displayName,
				// 			type: "text"
				// 		},
				// 		{
				// 			objectKey: "paymentType",
				// 			value:
				// 				el.paymentType === null
				// 					? "CASH"
				// 					: el.paymentType,
				// 			type: "text"
				// 		},
				// 		{
				// 			objectKey: "NIK",
				// 			value: el.nik === "0" || !el.nik ? "-" : el.nik,
				// 			type: "text"
				// 		},
				// 		{
				// 			objectKey: "npwp",
				// 			value:
				// 				el.npwp === "0" || !el.npwp ? "-" : el.npwp,
				// 			type: "text"
				// 		}
				// 	]
				// }));
				// setTableData(() => ({
				// 	...res,
				// 	...res.data,
				// 	dataTable: (data as DataTable[]) ?? []
				// }));
			},
			onError: (err: ApiError) => {
				// dispatch(
				// 	openAlert({
				// 		body: err.response?.data.message,
				// 		color: "danger"
				// 	})
				// );
			}
		}
	);

	const { mutateAsync, isLoading: mergerLoading } = useMutation(
		async ({
			masterProjectId,
			slaveProjectIds
		}: {
			slaveProjectIds: string[];
			masterProjectId: string;
		}) => {
			return await CommonService.mergeProjects({
				masterProjectId,
				slaveProjectIds
			});
		}
	);

	async function submitMerge() {
		const res = await mutateAsync({
			masterProjectId: projectMaster?.id as string,
			slaveProjectIds: selectedCustomer
		});
		setIsOpen(false);
		dispatch(
			openAlert({
				body: res.message,
				color: "success"
			})
		);
		refetchCustomer();
	}

	const selectedIndex = useMemo(() => {
		const indexes: number[] = [];
		projectsData?.data?.data.forEach((project: any, index: number) => {
			if (selectedCustomer.includes(project.id)) {
				indexes.push(index);
			}
		});
		return indexes;
	}, [selectedCustomer, projectsData]);

	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		}
	}, [isOpen]);

	return (
		<LocalDialog
			isOpen={localDialog.isOpen}
			backdrop={true}
			header={"Gabungkan Proyek"}
			onClose={() => {
				localDialog.onClose();
				setTimeout(() => {
					setIsOpen(false);
				}, 500);
			}}
		>
			<div>
				{!projectMaster
					? "Pilih Proyek Utama"
					: "Pilih Proyek untuk digabungkan"}

				{projectMaster ? (
					<Card>
						<CardBody>
							<div className="d-flex flex-column">
								<b>Master</b>
								<div>ID: {projectMaster?.id}</div>
								<div>
									Name Proyek:&nbsp; {projectMaster?.name}
								</div>
								<div>
									Alamat Pengiriman:&nbsp;{" "}
									{projectMaster?.ShippingAddress?.line1}
								</div>

								<div>
									PIC:&nbsp;
									{projectMaster?.Pic
										? projectMaster?.Pic?.name
										: "-"}
								</div>
								<div>
									No. Tel PIC:&nbsp;
									{projectMaster?.Pic
										? projectMaster?.Pic?.phone
										: "-"}
								</div>
								<div>
									Sales:&nbsp;
									{projectMaster?.User
										? projectMaster?.User.fullName
										: "-"}
								</div>
							</div>
							<button
								className="close"
								onClick={() => {
									setCustomerMaster(undefined);
									setSelectedCustomer([]);
								}}
								type="button"
								style={{
									position: "absolute",
									right: -5,
									top: -12
								}}
							>
								<XOctagon />
							</button>
						</CardBody>
					</Card>
				) : null}

				<MTable
					totalItems={
						projectsData?.data?.totalItems
							? projectsData?.data?.totalItems
							: 0
					}
					columns={custColumn}
					tableData={projectsData?.data?.data}
					rowsPerPage={rowsPerPage}
					rowsPerPageOptions={[5, 10, 15, 100]}
					setRowsPerPage={setRowsPerPage}
					currentPage={
						projectsData?.data?.currentPage
							? projectsData?.data?.currentPage
							: 1
					}
					tableTitle=""
					selectableRowsHideCheckboxes={!projectMaster}
					setPage={(page) => {
						setPage(page);
					}}
					onSearchChange={(text: string | null) => {
						if (text) {
							setSearchQ(text);
						} else {
							setSearchQ(undefined);
						}
					}}
					selectedRows={selectedIndex}
					// typeSelectableRows="multiple"
					setSelectedRows={(index: number) => {
						setSelectedCustomer((curr) => {
							const newSelectedIds = [...curr];
							newSelectedIds.push(
								projectsData?.data?.data[index]?.id as string
							);
							return newSelectedIds;
						});
					}}
					setDeselectRows={(index: number) => {
						setSelectedCustomer((curr) => {
							const newSelectedIds = [...curr];
							return newSelectedIds.filter(
								(id) =>
									id !== projectsData?.data?.data[index]?.id
							);
						});
					}}
					options={{
						isRowSelectable(dataIndex, selectedRows) {
							return (
								projectsData?.data?.data[dataIndex]?.id !==
								projectMaster?.id
							);
						}
					}}
				/>
				{/* {projectsData?.data?.data?.map((customer) => {
					return (
						<div className="d-flex flex-column">
							Nama Pelanggan{customer.displayName}
							<Button
								id={`cust-${customer.id}`}
								style={{
									marginBottom: "1rem"
								}}
							>
								Lihat Proyek
							</Button>
							<UncontrolledCollapse
								toggler={`#cust-${customer.id}`}
							>
								<Card>
									<CardBody>
										{customer?.Projects.map((project) => {
											return (
												<div>
													Nama Proyek: {project.name}
												</div>
											);
										})}
									</CardBody>
								</Card>
							</UncontrolledCollapse>
						</div>
					);
				})} */}
				<div className="modal-custom-footer">
					<LoadingButton
						color="error"
						onClick={() => {
							localDialog.onClose();
							setTimeout(() => {
								setIsOpen(false);
							}, 500);
						}}
						loading={mergerLoading}
						disabled={mergerLoading}
					>
						<span style={{ textTransform: "capitalize" }}>
							Batal
						</span>
					</LoadingButton>
					<LoadingButton
						color="error"
						variant="contained"
						loading={mergerLoading}
						disabled={
							mergerLoading ||
							!(projectMaster && !!selectedCustomer.length)
						}
						onClick={submitMerge}
					// sx={{ backgroundColor: "red", color: "white" }}
					>
						<span style={{ textTransform: "capitalize" }}>
							Gabungkan Proyek
						</span>
					</LoadingButton>
				</div>
			</div>
		</LocalDialog>
	);
}
