/* eslint-disable indent */
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";

const colors = {
	grey: "#D7D7D7",
	yellow: "#FFE885",
	orange: "#FFD494",
	red: "#FF8F8F",
	black: "#191919",
	white: "#FFFFFF",
	errorPic: "#F6D7DC",
	offYellow: "#FFFFE5",
	green: "#C2FCC8",
	blue: "#C2DBFC"
};

const Weekday = [
	"Senin",
	"Selasa",
	"Rabu",
	"Kamis",
	"Jumat",
	"Sabtu",
	"Minggu"
];

export interface AccurateCreds {
	accessToken: string;
	refreshToken: string;
	user: any;
	session: string;
	host: string;
	expiredAt?: string;
}

function getAccurateCreds(): AccurateCreds | undefined | void {
	try {
		return JSON.parse(localStorage.getItem("accurateCreds") as string);
	} catch (error) {
		console.log(error, "error get accurate creds");
	}
}

const dateStr = (date: DRange) => {
	if (
		!date?.endDate ||
		moment(date.startDate).isSame(moment(date.endDate), "day")
	) {
		return moment(date?.startDate).format("D-MMMM");
	}

	return `${moment(date?.startDate).format("D-MMMM")} - ${moment(
		date?.endDate
	).format("D-MMMM")}`;
};

const toSheet = (dataSheet: any[], date: DRange, name: string) => {
	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";

	// url to hyperlink
	// for (const r in dataSheet) {
	// 	for (const key in dataSheet[r]) {
	// 		const cellValue = dataSheet[r][key];
	// 		// if string only
	// 		if (typeof cellValue === "string" && cellValue.includes("http")) {
	// 			if (cellValue.startsWith('"') && cellValue.endsWith('"')) {
	// 				dataSheet[r][key] = `=HYPERLINK(${cellValue})`;
	// 			} else {
	// 				dataSheet[r][key] = `=HYPERLINK("${cellValue}")`;
	// 			}
	// 		}
	// 	}
	// }

	// create a worksheet and workbook
	const ws = XLSX.utils.json_to_sheet(dataSheet);
	const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
	const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

	// create a Blob from the workbook
	const data = new Blob([excelBuffer], { type: fileType });

	// format the date and save the file
	const dateSheet = dateStr(date);
	FileSaver.saveAs(data, `${name} ${dateSheet}${fileExtension}`);

	// const fileType = "text/csv;charset=UTF-8";
	// const fileExtension = ".csv";
	// const csv = convertArrayToCSV(dataSheet);
	// const data = new Blob([csv], { type: fileType });
	// const dateSheet = dateStr(date);
	// FileSaver.saveAs(data, `${name} ${dateSheet}${fileExtension}`);
};

const convertToCSV = (arr: any[]) => {
	const array = [Object.keys(arr[0])].concat(arr);

	return [
		"sep=|",
		...array.map((it) => {
			const items = Object.values(it);
			for (const i in items) {
				const st =
					!!items?.[i] && typeof items[i] == "string"
						? items[i] ?? ""
						: "";

				if (st.includes("http")) {
					if (st?.startsWith("\"") && st?.endsWith("\"")) {
						items[i] = `=HYPERLINK(${st})`;
					} else {
						items[i] = `=HYPERLINK("${st}")`;
					}
				}
			}
			return items.join("|");
		})
	].join("\n");
};

const convertArrayToCSV = (arr: any[]): string => {
	if (arr.length === 0) return "";

	const headers = Object.keys(arr[0]);
	const csvRows = arr.map((obj) =>
		headers
			.map((header) => {
				let value = obj[header];
				if (typeof value === "string" && value.includes(",")) {
					value = `"${value}"`;
				}
				return value;
			})
			.join(",")
	);

	return [headers.join(","), ...csvRows].join("\n");
};

const formatDate = (date: Date) => {
	if (!moment(date).isValid()) return "-";
	moment.locale("id");
	return moment(date).format("DD/MM/YYYY HH:mm");
};

const formatDateOnly = (date: Date) => {
	if (!moment(date).isValid()) return "-";
	return moment(date).format("DD/MM/YYYY");
};

const formatTimeOnly = (date: Date) => {
	const newDate = new Date(date);
	const hours = newDate.getHours();
	const minutes = newDate.getMinutes();
	const formattedHours = hours < 10 ? "0" + hours : hours;
	const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
	const formattedTime = `${formattedHours}:${formattedMinutes}`;
	return formattedTime;
};

const formatDateID = (date: Date) => {
	if (!moment(date).isValid()) return "-";

	const days = Weekday[new Date(date).getDay() - 1];
	const formatter = new Intl.DateTimeFormat("id", {
		dateStyle: "medium",
		timeStyle: "short",
		timeZone: "Asia/Jakarta"
	});
	return days + " " + formatter.format(date);
};

const formatPhone = (phone: string) => {
	if (!phone) return;
	// return phone;
	return `+62 ${phone.slice(0, 3)} ${phone.slice(3)}`;
};

const formatComma = (number: any) => {
	if (!number) return "-";
	return (number = number.toString().replace(".", ","));
};

const formatLinkSheet = (url: string, alias: string) => {
	return `"${url}"; "${alias}"`;
};

const getStatusByType = (status: string, type?: "SO", data?: any) => {
	if (type == "SO") {
		data = data as ApiPO;

		const docs = data?.PurchaseOrderDocs ?? [];
		let hasUnsigned = false;
		let hasSigned = false;
		for (const d of docs) {
			if (
				d.type == "BRIK_UNSIGNED" ||
				d.type == "SO_UNSIGNED" ||
				d.type == "BRIK"
			) {
				hasUnsigned = true;
			}
			if (d.type == "BRIK_SIGNED" || d.type == "SO_SIGNED") {
				hasSigned = true;
			}
		}

		if (
			data?.status == "APPROVED" &&
			data?.Project?.Customer?.type === "INDIVIDU"
		) {
			hasSigned = true;
		}

		if (hasSigned) {
			status = "SIGNED";
		} else if (hasUnsigned) {
			status = "AWAITING_SIGNATURE";
		} else {
			status = "SUBMITTED";
		}
	}
	return status;
};

const getStatusColor = (status: string) => {
	const _status = status.trim().toUpperCase();
	const numberStatus = parseInt(status);
	switch (_status) {
		case "DIAJUKAN":
		case "SUBMITTED":
		case "DALAM PRODUKSI":
			return { backgroundColor: colors.grey, color: colors.black };
		case "INACTIVE":
			return { backgroundColor: colors.black, color: colors.grey };
		case "CEK BARANG":
		case "PEMERIKSAAN":
		case "DALAM PERJALANAN":
		case "VISIT":
		case "PARTIALLY PAID":
		case "WB_OUT":
		case "ON_DELIVERY":
		case "ARRIVED":
		case "LOADING_STARTED":
		case "LOADING_FINISHED":
		case "CONFIRMED":
			return { backgroundColor: colors.yellow, color: colors.black };
		case "PERSIAPAN":
		case "BERLANGSUNG":
		case "BONGKAR":
		case "AWAITING_SIGNATURE":
		case "MENUNGGU TANDA TANGAN":
		case "RECEIVED":
		case "AWAIT_WB_IN":
		case "POURING_STARTED":
		case "POURING_FINISHED":
		case "DRIVER_RETURNED":
			return { backgroundColor: colors.orange, color: colors.black };
		case "DISETUJUI":
		case "DITERIMA":
		case "DRAFT":
		case "APPROVED":
		case "SELESAI":
		case "SIGNED":
		case "SPH":
		case "PAID":
		case "CASH":
		case "FINISHED":
		case "COMPLETED":
		case "DRIVER_FINISHED":
		case "SECURITY_FINISHED":
			return { backgroundColor: colors.green, color: colors.black };
		case "KADALUARSA":
			return { backgroundColor: colors.black, color: colors.white };
		case "DITOLAK":
		case "DECLINED":
		case "REJECTED":
		case "CANCELLED":
			return { backgroundColor: colors.red, color: colors.black };
		case "COMPANY":
		case "PERUSAHAAN":
		case "BIRU":
			return { backgroundColor: colors.blue, color: colors.black };
		case "INDIVIDU":
		case "DELIVERED":
			return { backgroundColor: colors.orange, color: colors.black };
		//////////////// Batching Plants ////////////////
		case "LGK":
			return { backgroundColor: "#BFEAF4", color: colors.black };
		case "BLRJ":
			return { backgroundColor: "#FDCBFC", color: colors.black };
		case "CKRG":
			return { backgroundColor: "#FFD8E0", color: colors.black };
	}
	if (numberStatus >= 7) {
		return { backgroundColor: colors.green, color: colors.black };
	}
	if (numberStatus >= 0 && numberStatus <= 6) {
		return { backgroundColor: colors.orange, color: colors.black };
	}
	if (numberStatus < 0) {
		return { backgroundColor: colors.red, color: colors.black };
	}
	return { backgroundColor: colors.grey, color: colors.black };
};
const getStatusTrx = (id: string) => {
	switch (id) {
		case "CONFIRMED":
		case "DRAFT":
			return "Diterbitkan";
		case "SUBMITTED":
			return "Diajukan";
		case "CREATED":
			return "Dibuat";
		case "INACTIVE":
			return "Idle";
		case "PERSIAPAN":
		case "PARTIALLY_PROCESSED":
			return "Persiapan";
		case "DISETUJUI":
		case "PARTIALLY_PAID":
		case "APPROVED":
			return "Disetujui";
		case "PAID":
			return "Diterima";
		case "AWAITING_SIGNATURE":
			return "Menunggu Tanda Tangan";
		case "DITOLAK":
		case "DECLINED":
			return "Ditolak";
		case "CANCELLED":
			return "Dibatalkan";
		case "DALAM PRODUKSI":
			return "Dalam Produksi";
		case "DALAM PERJALANAN":
			return "Dalam Perjalanan";
		case "SELESAI":
		case "SIGNED":
			return "Selesai";
		case "CEK BARANG":
			return "Cek Barang";
		case "PEMERIKSAAN":
			return "Pemeriksaan";
		case "BERLANGSUNG":
		case "IN_PROGRESS":
			return "Berlangsung";
		case "BONGKAR":
			return "Bongkar";
		case "KADALUARSA":
			return "Kadaluarsa";
		case "DITERIMA":
		case "RECEIVED":
			return "Diterima";
		case "FINISHED":
		case "COMPLETED":
			return "Selesai";
		case "WB_OUT":
			return "WB Selesai";
		case "ON_DELIVERY":
			return "Perjalanan (Proyek)";
		case "ARRIVED":
			return "Sampai";
		case "AWAIT_WB_IN":
			return "Perjalanan (Plant)";
		case "LOADING_STARTED":
			return "Loading Dimulai";
		case "LOADING_FINISHED":
			return "Loading Selesai";
		case "POURING_STARTED":
			return "Penuangan Dimulai";
		case "POURING_FINISHED":
			return "Penuangan Selesai";
		case "AWAITING_FOR_PAYMENT":
			return "Menunggu Pembayaran";
		case "DRIVER_RETURNED":
			return "Driver Kembali Ke Plant";
		case "DRIVER_FINISHED":
		case "SECURITY_FINISHED":
			return "Selesai";
		//////////////// Batching Plants ////////////////
		case "LGK":
			return "Legok";
		case "BLRJ":
			return "Balaraja";
		case "CKRG":
			return "Cikarang";
		default:
			return id;
	}
};

const getCustomerTypeColor = (status: string) => {
	const _status = status.trim().toUpperCase();
	switch (_status) {
		case "COMPANY":
			return { backgroundColor: colors.blue, color: colors.black };
		default:
			return { backgroundColor: colors.orange, color: colors.black };
	}
};

const getUserTypeColor = (type?: string) => {
	const _type = type?.trim().toUpperCase();
	switch (_type) {
		case "ADMIN":
			return { backgroundColor: colors.red, color: colors.white };
		case "SALES":
			return { backgroundColor: colors.orange, color: colors.black };
		case "DRIVER":
			return { backgroundColor: colors.blue, color: colors.black };
		default:
			return { backgroundColor: colors.orange, color: colors.black };
	}
};

const getBatchingPlantColor = (name?: string) => {
	const _name = name?.trim().toUpperCase();
	switch (_name) {
		case "BP-LEGOK":
			return { backgroundColor: colors.red, color: colors.white };
		case "BP-BALARAJA":
			return { backgroundColor: colors.orange, color: colors.black };
		case "BP-CIKARANG":
			return { backgroundColor: colors.blue, color: colors.black };
		default:
			return { backgroundColor: colors.orange, color: colors.black };
	}
};

const handleResponseFile = (files: ResponseFile[], originalFile: File[]) => {
	const result: { documentId: string; fileId: string }[] = [];
	files.forEach((photo) => {
		const photoName = `${photo.name}.${photo.type}`;
		const photoNamee = `${photo.name}.jpg`;
		let foundPhoto;
		let type;
		for (const documentId in originalFile) {
			if (
				Object.prototype.hasOwnProperty.call(originalFile, documentId)
			) {
				const photoData = originalFile[documentId];
				if (photoData) {
					if (
						photoData.name === photoName ||
						photoData.name === photoNamee
					) {
						foundPhoto = documentId;
						if (photoData?.type) {
							type = photoData.type;
						}
					}
				}
			}
		}
		if (foundPhoto) {
			result.push({
				documentId: foundPhoto,
				fileId: photo.id,
				...(type && {
					type
				})
			});
		}
	});

	return result;
};

const formatIDR = (number: number | null | undefined) => {
	if (!number) return "0";
	const numericValue = Number(number.toString().replace(/[^0-9]/g, ""));
	return new Intl.NumberFormat("id-ID", { minimumFractionDigits: 0 })
		.format(numericValue)
		.replace(/\D00$/, "");
};

const parseIDR = (value: string) => {
	const numericValue = Number(value.toString().replace(/[^\d]/g, ""));
	return numericValue;
};

const formatTime = (dateString: string) => {
	if (!dateString) {
		return "_";
	}
	const date = new Date(dateString);
	const hours = date.getHours();
	const minutes = date.getMinutes();

	// Use String.padStart to ensure minutes always have two digits
	const formattedMinutes = String(minutes).padStart(2, "0");

	return `${hours}:${formattedMinutes}`;
};

export {
	dateStr,
	toSheet,
	formatDate,
	formatDateID,
	formatTime,
	formatLinkSheet,
	getStatusColor,
	getStatusTrx,
	getStatusByType,
	getCustomerTypeColor,
	getUserTypeColor,
	getBatchingPlantColor,
	handleResponseFile,
	formatPhone,
	formatIDR,
	formatComma,
	parseIDR,
	formatDateOnly,
	formatTimeOnly,
	getAccurateCreds,
	convertArrayToCSV
};
