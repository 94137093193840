import React from "react";

type Props = {
  size: "small" | "medium" | "large";
};

const SPACER_SIZE = {
  small: "8px",
  medium: "16px",
  large: "32px",
};

const Spacer: React.FC<Props> = ({ size }) => (
  <div style={{ height: SPACER_SIZE[size], width: SPACER_SIZE[size] }} />
);

export default Spacer;
