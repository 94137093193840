import { useLocalDialog } from "hooks";
import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { LocalDialog, Spacer } from "components";
import { useAppDispatch } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import { OrderService } from "api/axios";
import { useMutation, useQuery } from "react-query";
import { Formik, FieldArray, Form, Field } from "formik";
import { Input } from "reactstrap";
import { LoadingButton } from "@mui/lab";
import { getPriceFormatted } from "helper/product";

export default function UpdateRequestedProduct({
	isOpen,
	setIsOpen,
	refetch
}: {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	refetch: () => void;
}) {
	const params = useParams();
	const id = params?.id;
	const { localDialog } = useLocalDialog();
	const dispatch = useAppDispatch();
	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		}
	}, [isOpen]);

	const { isLoading, data } = useQuery<{ data: ApiQuotation }, ApiError>(
		["query-quotations-product-get"],
		async () => {
			return await OrderService.getOneQuotation(id ?? "");
		},
		{
			enabled: true,
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			},
			refetchOnMount: true,
			cacheTime: 0
		}
	);

	const RequestedProducts = useMemo(() => {
		const products: {
			offeringPrice: number;
			id: string;
			name: string;
			quantity: number;
			unit: string;
		}[] = [];
		const requestedProd = data?.data.QuotationRequest.RequestedProducts;
		if (requestedProd?.length) {
			requestedProd.forEach((prod) => {
				products.push({
					offeringPrice: prod.offeringPrice,
					id: prod.id,
					name: prod.name,
					quantity: prod.quantity,
					unit: prod.unit === "M3" ? "M³" : prod.unit
				});
			});
		}

		return products;
	}, [data]);

	const { mutateAsync: updateReqProduct } = useMutation<
		any,
		ApiError,
		RequestedProductsPut[],
		ApiError
	>(
		async (data: RequestedProductsPut[]) => {
			const res = await OrderService.putRequestedProducts(
				id as string,
				data
			);
			return res;
		},
		{
			onError(error) {
				console.log(error, "createAddress error 155");
			}
		}
	);

	return (
		<LocalDialog
			isOpen={localDialog.isOpen}
			backdrop={"static"}
			header="Ubah Produk"
			onClose={() => {
				localDialog.onClose();
				setTimeout(() => {
					setIsOpen(false);
				}, 500);
			}}
			rightBtnTitle="Edit"
			leftBtnTitle="Batal"
		>
			<div>
				{isLoading ? null : (
					<Formik
						initialValues={{
							RequestedProducts: RequestedProducts
						}}
						onSubmit={async (values, { setSubmitting }) => {
							try {
								setSubmitting(true);
								const payload = values.RequestedProducts.map(
									(prod) => {
										return {
											id: prod.id,
											offeringPrice: prod.offeringPrice
										};
									}
								);
								await updateReqProduct(payload);
								refetch();
								localDialog.onClose();
								setTimeout(() => {
									setIsOpen(false);
								}, 500);
								dispatch(
									openAlert({
										body: "Berhasil ubah harga produk",
										color: "secondary"
									})
								);
							} catch (error) {
								console.log(error, "updateReqProduct error");

								dispatch(
									openAlert({
										body: "Gagal ubah harga produk",
										color: "danger"
									})
								);
							} finally {
								setSubmitting(false);
							}
						}}
					>
						{({ values, handleChange, isSubmitting }) => {
							return (
								<Form>
									{values.RequestedProducts.map(
										(prod, index) => (
											<div key={prod.id}>
												{prod.name}
												<div className="d-flex align-items-center justify-content-between">
													<div
														style={{
															width: "30%"
														}}
													>
														<Input
															type="number"
															name={`RequestedProducts[${index}].offeringPrice`}
															onChange={
																handleChange
															}
															value={
																values
																	.RequestedProducts[
																	index
																]?.offeringPrice
															}
														/>
													</div>
													X
													<div>
														{
															values
																.RequestedProducts[
																index
															]?.quantity
														}{" "}
														{
															values
																.RequestedProducts[
																index
															]?.unit
														}
													</div>
													=
													<div>
														{getPriceFormatted(
															Number(
																values
																	.RequestedProducts[
																	index
																]?.quantity
															) *
															Number(
																values
																	.RequestedProducts[
																	index
																]
																	?.offeringPrice
															),
															"Rp "
														)}
														{ }
													</div>
												</div>
											</div>
										)
									)}
									<Spacer size="medium" />
									<div className="modal-custom-footer">
										<LoadingButton
											color="error"
											onClick={() => {
												localDialog.onClose();
												setTimeout(() => {
													setIsOpen(false);
												}, 500);
											}}
											loading={isSubmitting}
											disabled={isSubmitting}
										>
											<span style={{ textTransform: 'capitalize' }}>Batal</span>
										</LoadingButton>
										<LoadingButton
											color="warning"
											variant="contained"
											type="submit"
											loading={isSubmitting}
											disabled={isSubmitting}
										>
											<span style={{ textTransform: 'capitalize' }}>Ubah</span>
										</LoadingButton>
									</div>
								</Form>
							);
						}}
					</Formik>
				)}
			</div>
		</LocalDialog>
	);
}
