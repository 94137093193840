/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { useLocation, Route, Routes } from "react-router-dom";

import Sidebar from "components/Sidebar/Sidebar.js";
import { useFlags } from "flagsmith/react";
import RoutesAccess from "helper/RoutesAccess";
import NotFound from "views/auth/NotFound";

import { routes } from "routes.ts";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { setPermission } from "store/features/user/UserSlice";
import { Icon } from "@iconify/react";

import styles from "./Sidebarless.module.scss";

function mergeValues(flags) {
	let mergedValues = {};

	for (const key in flags) {
		if (
			Object.prototype.hasOwnProperty.call(flags, key) &&
			Object.prototype.hasOwnProperty.call(flags[key], "value") &&
			Object.prototype.hasOwnProperty.call(flags[key], "enabled") &&
			flags[key].enabled === true
		) {
			const parsedValue = JSON.parse(flags[key].value);
			for (const prop in parsedValue) {
				if (Object.prototype.hasOwnProperty.call(parsedValue, prop)) {
					const propValue = parsedValue[prop];
					if (
						Object.prototype.hasOwnProperty.call(mergedValues, prop)
					) {
						const data = { ...mergedValues[prop] };
						for (const key in propValue) {
							if (Object.hasOwnProperty.call(propValue, key)) {
								const value = propValue[key];
								if (value) {
									data[key] = value;
								}
							}
						}
						if (Object.keys(data)) {
							mergedValues[prop] = data;
						}
					} else {
						mergedValues[prop] = propValue;
					}
				}
			}
		}
	}
	return mergedValues;
}

const Sidebarless = (props: any) => {
	const { userRoles } = useAppSelector((state) => state.user);
	const dispatch = useAppDispatch();
	const mainContent = React.useRef(null);
	const location = useLocation();
	const flags = useFlags(userRoles);
	// const mergedValues = mergeValues(flags);
	const mergedValues = React.useMemo(() => {
		return mergeValues(flags);
	}, [flags]);
	const [sidebarHidden, setSidebarHidden] = useState(true);
	// dispatch(setPermission(mergedValues));
	// const { role_access } = flags;

	let hidePages = [];

	// if (role_access.enabled) {
	hidePages = RoutesAccess({
		flagValues: mergedValues
	});
	// }

	React.useEffect(() => {
		dispatch(setPermission(mergedValues));
	}, [mergedValues]);

	React.useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		mainContent.current.scrollTop = 0;
	}, [location]);

	const getRoutes = (routes) => {
		const sidebarlessRoutes = routes.filter((prop) => {
			return (
				prop.layout === "/sl" ||
				prop?.childrens?.find((child) => child.layout === "/sl")
			);
		});
		return sidebarlessRoutes.map((prop, key) => {
			if (prop?.childrens?.length > 0) {
				return prop.childrens.map((el, key) => {
					if (!hidePages.includes(el.name)) {
						return (
							<Route
								path={(prop.layout || "") + el.path}
								element={<el.component />}
								key={key}
							/>
						);
					}
				});
			} else {
				let path = prop.path;
				return (
					<Route path={path} element={<prop.component />} key={key} />
				);
			}
		});
	};

	function SidebardRoutes(routes) {
		return routes.map((prop) => {
			//
			// let condition = true;
			if (hidePages.includes(prop.name)) {
				prop.show = false;
				// condition = false;
				// return null;
			}

			if (prop?.childrens?.length) {
				prop?.childrens.forEach((child) => {
					if (hidePages.includes(child.name)) {
						// condition = false;
						child.show = false;
					}
				});
			}
			// if (prop.name === "Finance") {
			// 	console.log(condition, "condition");
			// }

			return prop;
		});
	}

	return (
		<>
			<Sidebar
				{...props}
				className={[
					props?.className,
					styles.sidebar,
					// styles.sidebarHidden
					sidebarHidden ? styles.sidebarHidden : ""
				].join(" ")}
				routes={SidebardRoutes(routes)}
				logo={{
					innerLink: "/",
					imgSrc: require("../assets/img/brand/brik-logo.png"),
					imgAlt: "..."
				}}
			/>
			<div
				className={[
					"main-content",
					styles.mainContent,
					// styles.mSidebarHidden
					sidebarHidden ? styles.mSidebarHidden : ""
				].join(" ")}
				ref={mainContent}
			>
				<div
					className={[
						styles.sidebarToggleContainer,
						sidebarHidden ? styles.sidebarToggleContainerHidden : ""
					].join(" ")}
				>
					<div
						className={[styles.toggleButton].join(" ")}
						onClick={() => {
							setSidebarHidden(!sidebarHidden);
						}}
					>
						<Icon
							className={[
								styles.toggleIcon,
								!sidebarHidden ? styles.toggleIconHidden : ""
							].join(" ")}
							icon="octicon:chevron-right-24"
							fontSize={24}
							color="white"
						/>
						<Icon
							className={[
								styles.toggleIcon,
								sidebarHidden ? styles.toggleIconHidden : ""
							].join(" ")}
							icon="octicon:chevron-left-24"
							fontSize={24}
							color="white"
						/>
					</div>
				</div>
				<Routes>
					{getRoutes(routes)}
					<Route path="*" element={<NotFound />} />
				</Routes>
				{/* <Container fluid>
          <AdminFooter />
        </Container> */}
			</div>
		</>
	);
};

export default Sidebarless;
