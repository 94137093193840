import { CommonService } from "api/axios";
import BCard from "components/B/card";
import React, { useState } from "react";
import { ChevronLeft } from "react-bootstrap-icons";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
	Button,
	Card,
	CardBody,
	CardTitle,
	Container,
	Row,
	Table
} from "reactstrap";
import Select from "react-select";
import { openAlert } from "store/features/alert/AlertSlice";
import { useAppDispatch } from "store/hooks";
import { BStatus, StatusChip } from "components";

export default function ProjectDetail() {
	const navigate = useNavigate();
	const params = useParams();
	const dispatch = useAppDispatch();
	const id = params?.id;

	const [lastApprovedRequestId, setLastApprovedRequestId] = useState(null);

	const [projectDetails, setProjectDetails] = React.useState<any[]>([]);
	const [projectName, setProjectName] = React.useState<string>();

	const { refetch, data } = useQuery<any, ApiError>(
		["query-get-speciment", id],
		async () => {
			return await CommonService.getSpecimentByProject(id as string);
		},
		{
			onSuccess: (res) => {
				try {
					//
				} catch (error) {
					console.log(error);
				}
			}
		}
	);

	const { isLoading } = useQuery<ApiOneProject, ApiError>(
		["query-getproject", id],
		async () => {
			return await CommonService.getOneProject(id as string);
		},
		{
			onSuccess: (res) => {
				const isCompany = !!res?.data?.companyName;
				const isCustomer = !!res?.data?.Customer;
				try {
					const projectDetails = [
						// (!isCompany && !isCustomer) ||
						// (isCompany && !isCustomer)
						// 	? {
						// 			type: "noCustomer"
						// 	  }
						// 	: null,
						isCustomer && {
							name: "Tipe Pelanggan",
							type: "customerTypeChip",
							data:
								res?.data?.Customer?.type === "COMPANY"
									? "PERUSAHAAN"
									: res?.data?.Customer?.type
						},
						isCompany && !isCustomer
							? {
									name: "Tipe Pelanggan",
									type: "customerType",
									data: "PERUSAHAAN"
							  }
							: null,
						isCustomer
							? {
									name: "Nama Pelanggan",
									data:
										res?.data?.Customer?.displayName ?? "-"
							  }
							: null,
						{
							name: "Nama Proyek",
							data: res?.data?.name
						},
						isCompany
							? {
									name: "Perusahaan",
									data: res?.data?.Company?.displayName
							  }
							: null,
						{
							name: "PIC Utama",
							type: "pic",
							data: {
								name: res?.data?.mainPic?.name,
								position: res?.data?.mainPic?.position,
								phone: res?.data?.mainPic?.phone,
								email: res?.data?.mainPic?.email
							}
						},
						res?.data?.Pics.length > 1 && {
							name: "PICs",
							type: "pics",
							data: {
								pics: res?.data?.Pics,
								mainPIC: res?.data?.mainPic
							}
						},
						{
							name: "Alamat Penagihan",
							type: "address",
							data: {
								line1: res?.data?.BillingAddress?.line1,
								lat: res?.data?.BillingAddress?.lat,
								lon: res?.data?.BillingAddress?.lon
							}
						},
						{
							name: "Alamat Pengiriman",
							type: "address",
							data: {
								line1: res?.data?.ShippingAddress?.line1,
								lat: res?.data?.ShippingAddress?.lat,
								lon: res?.data?.ShippingAddress?.lon
							}
						}
					];
					setProjectDetails(projectDetails);
					setProjectName(res?.data?.name);
				} catch (e) {
					//
				}
			}
		}
	);

	const approveStatus = useMutation(
		async (id) => {
			return await CommonService.approveSpecimenRequest(id as any);
		},
		{
			onSuccess: (response) => {
				refetch();
				dispatch(
					openAlert({
						body: "Berhasil Menyetujui Specimen Request",
						color: "success"
					})
				);

				if (lastApprovedRequestId) {
					refetch();
				}

				setLastApprovedRequestId(response.data.id);
			},

			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
				console.log("error:", err);
			}
		}
	);

	const rejectStatus = useMutation(
		async (id) => {
			return await CommonService.rejectSpecimenRequest(id as any);
		},
		{
			onSuccess: (response) => {
				refetch();
				dispatch(
					openAlert({
						body: "Berhasil Menolak Specimen Request",
						color: "success"
					})
				);
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
				console.log("error:", err);
			}
		}
	);

	const handleApprove = async (id: any) => {
		try {
			await approveStatus.mutateAsync(id);
		} catch (error) {
			console.log("error:", error);
		}
	};

	const handleDecline = async (id: any) => {
		try {
			await rejectStatus.mutateAsync(id);
		} catch (error) {
			console.log("error:", error);
		}
	};

	// Sort by status
	const sortedData = data?.data?.sort((a: any, b: any) => {
		if (a.status === "Approved" && b.status !== "Approved") {
			return -1;
		} else if (a.status !== "Approved" && b.status === "Approved") {
			return 1;
		} else {
			return 0;
		}
	});

	return (
		<div className="p-4 pt-6">
			<div className="d-flex flex-row align-items-center mb-2">
				<div
					className="b-back-btn border mr-3"
					onClick={() => {
						navigate(-1);
					}}
				>
					<ChevronLeft />
				</div>

				<h1 className="mb-0 mr-2">{projectName}</h1>
			</div>

			<div className="d-flex flex-column flex-lg-row">
				<div className="d-flex flex-column mb-3" style={{ flex: 0.7 }}>
					{!sortedData ? (
						<Container>
							<Row>
								<div className="col d-flex justify-content-center">
									<h1>
										PERMINTAAN BENDA UJI TIDAK DITEMUKAN /
										BELUM DIBUAT
									</h1>
								</div>
							</Row>
						</Container>
					) : (
						sortedData?.map((item: any, idx: number) => {
							return (
								<Card style={{ marginTop: "10px" }} key={idx}>
									<CardBody>
										<CardTitle
											tag="h5"
											style={{
												margin: 0,
												display: "flex",
												justifyContent: "space-between"
											}}
										>
											Permintaan Benda Uji {idx + 1}
											<div className="d-flex flex-row">
												<div
													style={{
														display: "flex",
														justifyContent:
															"space-between",
														// flex: 3,
														alignItems: "center"
													}}
												>
													{item?.status ===
													"Pending" ? (
														<div>
															<Button
																size="sm"
																color="success"
																className="confirm-button approve-button"
																onClick={() =>
																	handleApprove(
																		item.id
																	)
																}
															>
																Approve
															</Button>
															<Button
																size="sm"
																color="danger"
																className="confirm-button decline-button ml-1"
																onClick={() =>
																	handleDecline(
																		item.id
																	)
																}
															>
																Decline
															</Button>
														</div>
													) : (
														<div>
															<StatusChip
																value={
																	item.status
																}
															/>
														</div>
													)}
												</div>
											</div>
										</CardTitle>

										<div className="mt-3">
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													gap: "2rem"
												}}
											>
												<BCard
													title="Rincian Specimen"
													data={[
														{
															name: "Interval",
															type: "text",
															data:
																item.samplingIntervalQuantity ??
																"-"
														},
														{
															name: "Metode Pembayaran",
															type: "text",
															data:
																item.paymentMethod ??
																"-"
														}
													]}
												/>

												{/* Mapping summaryInvoice */}
												<BCard
													title="Rincian Invoice"
													data={[
														{
															name: "Nama",
															type: "text",
															data:
																item.invoiceName ??
																"-"
														},
														{
															name: "No. Telepon",
															type: "text",
															data:
																item.invoicePhone ??
																"-"
														},
														{
															name: "Alamat Penagihan",
															type: "text",
															data:
																item.invoiceAddress ??
																"-"
														}
													]}
												/>

												{item.fileUrls.length > 0 ? (
													<BCard
														title="Foto Benda Uji"
														data={item.fileUrls.map(
															(url: any) => ({
																name: "Foto Benda Uji",
																type: "newFile",
																data: {
																	name: url.substring(
																		url.lastIndexOf(
																			"/"
																		) + 1
																	),
																	type: url.substring(
																		url.lastIndexOf(
																			"."
																		) + 1
																	),
																	url: url
																}
															})
														)}
													/>
												) : null}

												{/* Conditional Render Beam */}
												{item.specimens.Beam.length >
													0 && (
													<Card>
														<CardBody>
															<CardTitle
																tag="h5"
																style={{
																	margin: 0,
																	display:
																		"flex",
																	justifyContent:
																		"space-between"
																}}
															>
																Set Beam
															</CardTitle>

															<div
																style={{
																	display:
																		"flex",
																	flexDirection:
																		"row",
																	gap: "1rem",
																	marginTop:
																		"1rem"
																}}
															>
																<div
																	style={{
																		display:
																			"flex",
																		flexDirection:
																			"row",
																		flexWrap:
																			"wrap",
																		gap: "1rem"
																	}}
																>
																	{/* Mapping the specimen for each type */}
																	{item?.specimens?.Beam?.map(
																		(
																			beamItem: any,
																			index: number
																		) => {
																			return (
																				<Card
																					key={
																						index
																					}
																					style={{
																						width: "220px"
																					}}
																				>
																					<CardBody>
																						<CardTitle tag="h5">
																							{
																								beamItem.name
																							}
																						</CardTitle>
																						<div
																							style={{
																								marginTop:
																									"1rem",
																								overflowX:
																									"auto"
																							}}
																						>
																							<Table
																								borderless
																								style={{
																									border: "none",
																									borderSpacing:
																										"0"
																								}}
																							>
																								<tbody>
																									<tr
																										style={{
																											border: "none"
																										}}
																									>
																										<td
																											style={{
																												border: "none",
																												textAlign:
																													"center",
																												padding:
																													"0.2rem"
																											}}
																										>
																											Jumlah
																										</td>
																										<td
																											style={{
																												border: "none",
																												textAlign:
																													"center",
																												padding:
																													"0.2rem"
																											}}
																										>
																											Lab
																										</td>
																										<td
																											style={{
																												border: "none",
																												textAlign:
																													"center",
																												padding:
																													"0.2rem"
																											}}
																										>
																											Umur
																										</td>
																									</tr>
																									<tr
																										style={{
																											border: "none"
																										}}
																									>
																										<td
																											style={{
																												border: "none",
																												padding:
																													"0.3rem",
																												textAlign:
																													"center"
																											}}
																										>
																											{beamItem.quantity ??
																												"-"}
																										</td>
																										<td
																											style={{
																												border: "none",
																												padding:
																													"0.3rem",
																												textAlign:
																													"center"
																											}}
																										>
																											{beamItem.lab ===
																											"Other"
																												? beamItem.otherLab
																												: beamItem.lab ??
																												  "-"}
																										</td>
																										<td
																											style={{
																												border: "none",
																												padding:
																													"0.3rem",
																												textAlign:
																													"center"
																											}}
																										>
																											{beamItem.age ??
																												"-"}
																										</td>
																									</tr>
																								</tbody>
																							</Table>
																						</div>
																					</CardBody>
																				</Card>
																			);
																		}
																	)}
																</div>
															</div>
														</CardBody>
													</Card>
												)}

												{/* Conditional Render Cube*/}
												{item.specimens.Cube.length >
													0 && (
													<Card>
														<CardBody>
															<CardTitle
																tag="h5"
																style={{
																	margin: 0,
																	display:
																		"flex",
																	justifyContent:
																		"space-between"
																}}
															>
																Set Cube
															</CardTitle>

															<div
																style={{
																	display:
																		"flex",
																	flexDirection:
																		"row",
																	gap: "1rem",
																	marginTop:
																		"1rem"
																}}
															>
																<div
																	style={{
																		display:
																			"flex",
																		flexDirection:
																			"row",
																		flexWrap:
																			"wrap",
																		gap: "1rem"
																	}}
																>
																	{/* Mapping the specimen for each type */}
																	{item?.specimens?.Cube?.map(
																		(
																			cubeItem: any,
																			index: number
																		) => {
																			return (
																				<Card
																					key={
																						index
																					}
																					style={{
																						width: "220px"
																					}}
																				>
																					<CardBody>
																						<CardTitle tag="h5">
																							{
																								cubeItem.name
																							}
																						</CardTitle>
																						<div
																							style={{
																								marginTop:
																									"1rem",
																								overflowX:
																									"auto"
																							}}
																						>
																							<Table
																								borderless
																								style={{
																									border: "none",
																									borderSpacing:
																										"0"
																								}}
																							>
																								<tbody>
																									<tr
																										style={{
																											border: "none"
																										}}
																									>
																										<td
																											style={{
																												border: "none",
																												textAlign:
																													"center",
																												padding:
																													"0.2rem"
																											}}
																										>
																											Jumlah
																										</td>
																										<td
																											style={{
																												border: "none",
																												textAlign:
																													"center",
																												padding:
																													"0.2rem"
																											}}
																										>
																											Lab
																										</td>
																										<td
																											style={{
																												border: "none",
																												textAlign:
																													"center",
																												padding:
																													"0.2rem"
																											}}
																										>
																											Umur
																										</td>
																									</tr>
																									<tr
																										style={{
																											border: "none"
																										}}
																									>
																										<td
																											style={{
																												border: "none",
																												padding:
																													"0.3rem",
																												textAlign:
																													"center"
																											}}
																										>
																											{cubeItem.quantity ??
																												"-"}
																										</td>
																										<td
																											style={{
																												border: "none",
																												padding:
																													"0.3rem",
																												textAlign:
																													"center"
																											}}
																										>
																											{cubeItem.lab ===
																											"Other"
																												? cubeItem.otherLab
																												: cubeItem.lab ??
																												  "-"}
																										</td>
																										<td
																											style={{
																												border: "none",
																												padding:
																													"0.3rem",
																												textAlign:
																													"center"
																											}}
																										>
																											{cubeItem.age ??
																												"-"}
																										</td>
																									</tr>
																								</tbody>
																							</Table>
																						</div>
																					</CardBody>
																				</Card>
																			);
																		}
																	)}
																</div>
															</div>
														</CardBody>
													</Card>
												)}

												{/* Conditional Render Cylinder */}
												{item.specimens.Cylinder
													.length > 0 && (
													<Card>
														<CardBody>
															<CardTitle
																tag="h5"
																style={{
																	margin: 0,
																	display:
																		"flex",
																	justifyContent:
																		"space-between"
																}}
															>
																Set Cylinder
															</CardTitle>

															<div
																style={{
																	display:
																		"flex",
																	flexDirection:
																		"row",
																	gap: "1rem",
																	marginTop:
																		"1rem"
																}}
															>
																<div
																	style={{
																		display:
																			"flex",
																		flexDirection:
																			"row",
																		flexWrap:
																			"wrap",
																		gap: "1rem"
																	}}
																>
																	{/* Mapping the specimen for each type */}
																	{item?.specimens?.Cylinder?.map(
																		(
																			cylinderItem: any,
																			index: number
																		) => {
																			return (
																				<Card
																					key={
																						index
																					}
																					style={{
																						width: "220px"
																					}}
																				>
																					<CardBody>
																						<CardTitle tag="h5">
																							{
																								cylinderItem.name
																							}
																						</CardTitle>
																						<div
																							style={{
																								marginTop:
																									"1rem",
																								overflowX:
																									"auto"
																							}}
																						>
																							<Table
																								borderless
																								style={{
																									border: "none",
																									borderSpacing:
																										"0"
																								}}
																							>
																								<tbody>
																									<tr
																										style={{
																											border: "none"
																										}}
																									>
																										<td
																											style={{
																												border: "none",
																												textAlign:
																													"center",
																												padding:
																													"0.2rem"
																											}}
																										>
																											Jumlah
																										</td>
																										<td
																											style={{
																												border: "none",
																												textAlign:
																													"center",
																												padding:
																													"0.2rem"
																											}}
																										>
																											Lab
																										</td>
																										<td
																											style={{
																												border: "none",
																												textAlign:
																													"center",
																												padding:
																													"0.2rem"
																											}}
																										>
																											Umur
																										</td>
																									</tr>
																									<tr
																										style={{
																											border: "none"
																										}}
																									>
																										<td
																											style={{
																												border: "none",
																												padding:
																													"0.3rem",
																												textAlign:
																													"center"
																											}}
																										>
																											{cylinderItem.quantity ??
																												"-"}
																										</td>
																										<td
																											style={{
																												border: "none",
																												padding:
																													"0.3rem",
																												textAlign:
																													"center"
																											}}
																										>
																											{cylinderItem.lab ===
																											"Other"
																												? cylinderItem.otherLab
																												: cylinderItem.lab ??
																												  "-"}
																										</td>
																										<td
																											style={{
																												border: "none",
																												padding:
																													"0.3rem",
																												textAlign:
																													"center"
																											}}
																										>
																											{cylinderItem.age ??
																												"-"}
																										</td>
																									</tr>
																								</tbody>
																							</Table>
																						</div>
																					</CardBody>
																				</Card>
																			);
																		}
																	)}
																</div>
															</div>
														</CardBody>
													</Card>
												)}
											</div>
										</div>
									</CardBody>
								</Card>
							);
						})
					)}
					{/* Rincian Specimen Request // Mapped Specimen Request */}
				</div>

				<div className="spacer-v" />

				{/* Rincian Proyek */}
				<div
					className="d-flex flex-column"
					style={{ flex: 0.3, marginTop: "10px" }}
				>
					<BCard title="Rincian Proyek" data={projectDetails} />
				</div>
			</div>
		</div>
	);
}
