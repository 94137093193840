import React from "react";

import {
	GoogleMap,
	StandaloneSearchBox,
	Marker,
	LoadScript,
	useJsApiLoader
} from "@react-google-maps/api";

let markerArray = [];
const MapComponent = () => {
	const [state, setState] = React.useState<{
		currentLocation: {
			lat: number;
			lng: number;
		};
		markers: any[];
		bounds: null;
	}>({
		currentLocation: { lat: 0, lng: 0 },
		markers: [],
		bounds: null
	});
	let searchBox: { getPlaces: () => any };

	const onMapLoad = (map: any) => {
		navigator?.geolocation.getCurrentPosition(
			({ coords: { latitude: lat, longitude: lng } }) => {
				const pos = { lat, lng };
				setState({ ...state, currentLocation: pos });
			}
		);
		google.maps.event.addListener(map, "bounds_changed", () => {
			setState({ ...state, bounds: map.getBounds() });
		});
	};

	const onSBLoad = (ref: any) => {
		searchBox = ref;
	};

	const onPlacesChanged = () => {
		markerArray = [];
		const results = searchBox?.getPlaces();
		for (let i = 0; i < results.length; i++) {
			const place = results[i].geometry.location;
			markerArray.push(place);
		}
		setState({ ...state, markers: markerArray });
	};

	return (
		<div>
			<div id="searchbox">
				<StandaloneSearchBox
					onLoad={onSBLoad}
					onPlacesChanged={onPlacesChanged}
					bounds={state.bounds as unknown as google.maps.LatLngBounds}
				>
					<input
						type="text"
						placeholder="Customized your placeholder"
						style={{
							boxSizing: "border-box",
							border: "1px solid transparent",
							width: "240px",
							height: "32px",
							padding: "0 12px",
							borderRadius: "3px",
							boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
							fontSize: "14px",
							outline: "none",
							textOverflow: "ellipses",
							position: "absolute",
							left: "50%",
							marginLeft: "-120px"
						}}
					/>
				</StandaloneSearchBox>
			</div>
			<br />
			<div>
				<GoogleMap
					center={state.currentLocation}
					zoom={10}
					onLoad={(map) => onMapLoad(map)}
					mapContainerStyle={{ height: "400px", width: "800px" }}
				>
					{state.markers.map(
						(
							mark:
								| google.maps.LatLng
								| google.maps.LatLngLiteral,
							index: React.Key | null | undefined
						) => (
							<Marker key={index} position={mark} />
						)
					)}
				</GoogleMap>
			</div>
		</div>
	);
};
const Map = () => {
	return <MapComponent />;
};

export default Map;
