import moment from "moment";
export function WeekNumberToDateRange(weekNumber: string) {
	const startDateMoment = moment(weekNumber).startOf("isoWeek");
	const endDateMoment = moment(startDateMoment).add(6, "days").endOf("day");
	const startDate = startDateMoment.format("YYYY-MM-DD");
	const endDate = endDateMoment.format("YYYY-MM-DD");
	return {
		startDate,
		endDate
	};
}
export function MonthStartEnd(endDate: string, monthRange: number) {
	const end = moment(endDate, "YYYY-MM-DD");
	const start = end.clone().subtract(monthRange, "months").startOf("month");

	const formattedStartDate = start.format("YYYY-MM-DD");
	const formattedEndDate = end.format("YYYY-MM-DD");

	return {
		startDate: formattedStartDate,
		endDate: formattedEndDate
	};
}
