/* eslint-disable indent */
export const getPriceFormatted = (amount, prefix = "") => {
	let delimiter = "."; // replace comma if desired
	amount = String(amount);
	var aSplit = amount.split(",", 2);
	let d = aSplit[1];
	let i = parseInt(aSplit[0]);
	if (isNaN(i)) {
		return "";
	}
	let minus = "";
	if (i < 0) {
		minus = "-";
	}
	i = Math.abs(i);
	let n = new String(i);
	aSplit = [];
	while (n.length > 3) {
		let nn = n.substr(n.length - 3);
		aSplit.unshift(nn);
		n = n.substr(0, n.length - 3);
	}
	if (n.length > 0) {
		aSplit.unshift(n);
	}
	n = aSplit.join(delimiter);
	if (!d || d.length < 1) {
		amount = n;
	} else {
		amount = n + "." + d;
	}
	amount = minus + amount;
	return prefix + amount;
};

// get product discount price
export const getDiscountPrice = (price, discount) => {
	return discount && discount > 0
		? (price - price * (discount / 100)).toFixed(2)
		: price;
};

//get total quantity in schedule
export const getDeliveredQuantity = (workOrder) => {
	let totalQuantity = 0;
	workOrder.forEach((workOrder) => {
		if (workOrder.DeliveryOrder && workOrder.quantity) {
			// Tambahkan quantity dari DeliveryOrder ke totalQuantity
			if (
				workOrder.DeliveryOrder?.status !== "CANCELLED" &&
				workOrder.DeliveryOrder?.status !== "REJECTED"
			) {
				totalQuantity += workOrder.DeliveryOrder.quantity;
			}
		}
	});
	return totalQuantity;
};
