import { DashboardService } from "api/axios";
import { BarChart } from "components";
import {
	MonthStartEnd,
	WeekNumberToDateRange
} from "helper/WeekNumberToDateRange";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";

const lastWeek = moment().subtract(1, "week").format("YYYY-[W]WW");
const { startDate: initialStartDate, endDate: initialEndDate } =
	WeekNumberToDateRange(lastWeek);

const thisMonth = moment().endOf("month").format("YYYY-MM-DD");
const { startDate: initialMonthStartDate, endDate: initialMonthEndDate } =
	MonthStartEnd(thisMonth, 3);

export default function BestSellingProductsBarChart() {
	const [bestProducts, setBestProducts] =
		useState<BestSellingProductsChartData>(
			{} as BestSellingProductsChartData
		);
	const [page, setPage] = useState(1);
	const [dateRange, setDateRange] = useState({
		startDate: initialStartDate,
		endDate: initialEndDate
	});
	const [totalPages, setTotalPages] = useState(1);
	useQuery(
		["sales-funnel", dateRange, page],
		async () => {
			const { startDate, endDate } = dateRange;
			return await DashboardService.bestSellingProduct({
				startDate,
				endDate,
				page,
				size: 10
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				setBestProducts(res.data.data);
				setTotalPages(res.data.data.totalPages);
			}
		}
	);

	const transformedData = useMemo(() => {
		let trfData: {
			label: string;
			[key: string]: string | number;
		}[] = [];
		if (bestProducts?.productsResponse) {
			trfData = bestProducts?.productsResponse.reverse().map((prod) => {
				return {
					label: prod.label,
					"Volume Terjual": prod.total_quantity,
					["Volume Terjual" + "Color"]: "hsl(0, 77%, 52%)",
					"Volume Terjadwal": prod.scheduled_quantity
				};
				// eslint-disable-next-line no-mixed-spaces-and-tabs
			});
		}
		return trfData;
	}, [bestProducts]);

	return (
		<>
			<BarChart
				data={transformedData}
				labels={["Volume Terjual", "Volume Terjadwal"]}
				title="Best Selling Products"
				indexBy={"label"}
				layout="horizontal"
				filterOnChange={({ dateRange }) => {
					setDateRange(dateRange);
				}}
				leftLegend="Mutu Produk"
				bottomLegend="Volume (m³)"
				setPage={(page) => {
					setPage((curr) => {
						if (curr !== page) {
							return page;
						}
						return curr;
					});
				}}
				totalPage={totalPages}
				initialDate={{
					startDate: initialMonthStartDate,
					endDate: initialMonthEndDate
				}}
			/>
		</>
	);
}
