import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import LoadingButton from "@mui/lab/LoadingButton";

interface IProps {
	isOpen: boolean;
	// isOpenSO: boolean;
	backdrop: boolean | "static";
	header: React.ReactChild;
	// body: React.ReactChild,
	onClick?: (() => void) | "close";
	onClose: () => void;
	children: any;
	rightBtnTitle?: string;
	leftBtnTitle?: string;
	fullScreen?: boolean;
	loading?: boolean;
	disableRightBtn?: boolean;
	bodyStyle?: React.CSSProperties | undefined;
}

const LocalDialog = (props: IProps) => {
	const {
		isOpen,
		onClick,
		header,
		backdrop,
		onClose,
		children,
		fullScreen = false,
		rightBtnTitle = "Buat",
		leftBtnTitle = "Batal",
		disableRightBtn = false,
		loading,
		bodyStyle
	} = props;

	return (
		<Modal isOpen={isOpen} centered backdrop={backdrop} toggle={onClose}>
			{header && (
				<ModalHeader
					toggle={onClose}
					close={
						<button
							className="close"
							onClick={onClose}
							type="button"
						>
							&times;
						</button>
					}
				>
					{header}
				</ModalHeader>
			)}
			<ModalBody
				style={{
					...(fullScreen && {
						minWidth: "80vw",
						minHeight: "80%"
					}),
					paddingTop: "0px",
					...(bodyStyle || {})
				}}
			>
				{children}
			</ModalBody>
			{onClick && (
				<ModalFooter>
					<LoadingButton
						color="error"
						onClick={onClose}
						style={{ textTransform: "capitalize" }}
					>
						{leftBtnTitle}
					</LoadingButton>
					{typeof onClick === "function" && (
						<LoadingButton
							color="error"
							variant="contained"
							loading={loading}
							type="submit"
							onClick={onClick}
							disabled={disableRightBtn}
							sx={{
								backgroundColor: "red",
								color: "white",
								textTransform: "capitalize"
							}}
						>
							{rightBtnTitle}
						</LoadingButton>
					)}
				</ModalFooter>
			)}
		</Modal>
	);
};

export default LocalDialog;
