import React, { useEffect } from "react";
import { Alert, Button, Col, Container } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { closeAlert, toggleAlert } from "store/features/alert/AlertSlice";

const BDialog = () => {
	const { isOpen, body, color } = useAppSelector((state) => state.alert);
	const dispatch = useAppDispatch();
	const onDismiss = () => {
		dispatch(toggleAlert());
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			dispatch(closeAlert());
		}, 5000);
		return () => {
			clearTimeout(timeout);
		};
	}, [isOpen]);

	return (
		<Col className="">
			<Alert
				color={color}
				isOpen={isOpen}
				style={{
					zIndex: 1074,
					right: "50%",
					left: "50%",
					top: "10px",
					position: "fixed",
					transform: "translate(-50%, 0%)",
					width: "400px" // hard code
					// marginTop: "0.5rem"
				}}
				onClick={onDismiss}
			>
				{body}
				{/* <Button color="" onClick={onDismiss} style={{
					position: "relative",
					float: "right",
					top: -15,
					right: 0
				}}>x</Button> */}
			</Alert>
		</Col>
	);
};

export default BDialog;
