import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";

// Define a type for the slice state
interface DialogState {
	isOpen: boolean,
	backdrop: boolean,
	header: React.ReactChild,
	body: React.ReactChild,
	onClick?: (() => void) | "close",
	type?: "INFO" | "FORM"
	onSubmit?: any;
}

interface PayloadActionDialog {
	backdrop?: boolean,
	header?: React.ReactChild,
	body: React.ReactChild,
	onClick?: (() => void) | "close",
	type?: "INFO" | "FORM"
	onSubmit?: any;
}

// Define the initial state using that type
const initialState: DialogState = {
	isOpen: false,
	backdrop: true,
	header: "",
	body: "",
};

export const dialogSlice = createSlice({
	name: "modal",
	initialState,
	reducers: {
		openDialog: (state, action: PayloadAction<PayloadActionDialog>) => {
			const { payload: { body, onClick, header, backdrop, onSubmit, type } } = action;
			state.header = header as React.ReactChild;
			state.backdrop = backdrop as boolean;
			state.body = body;
			state.onClick = onClick as () => void;
			state.isOpen = true;
			state.onSubmit = onSubmit ?? undefined;
			state.type = type ?? undefined;
			return state;
		},
		closeDialog: (state) => {
			state = initialState;
			return state;
		},
		toggleDialog: (state) => {
			state.isOpen = !state.isOpen;
			return state;
		}
	},
});

export const { closeDialog, openDialog, toggleDialog } = dialogSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.modal;

export default dialogSlice.reducer;
