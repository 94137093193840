import BCard from "components/B/card";
import React, { useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { FinanceService } from "api/axios";
import { Loading } from "components";
import { Button } from "reactstrap";
import { getPriceFormatted } from "helper/product";
import { ChevronLeft } from "react-bootstrap-icons";

import BStatus from "components/B/status";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import { getRouteName, routeMapping } from "routes";
import { useLocalDialog } from "hooks";
import UpdatePayment from "./UpdatePayment";
import CompletePayment from "./CompletePayment";

const DepositDetail = () => {
	const location = useLocation();
	const permission = useAppSelector((state) => state.user.permission);
	const paymentPermission = useMemo(() => {
		const routeName = getRouteName(location.pathname, routeMapping);

		if (routeName) {
			return permission[routeName];
		}
		return {};
	}, [location, permission]);
	const navigate = useNavigate();
	const params = useParams();
	const id = params?.id;
	const [customerDetails, setCustomerDetails] = React.useState<any[]>([]);
	const [files, setFiles] = React.useState<any[]>([]);
	const [summary, setSummary] = React.useState<any[]>([]);
	const [status, setStatus] = React.useState<string>("");

	const dispatch = useAppDispatch();
	const { isLoading, refetch, data, isRefetching } = useQuery<
		ApiOnePayment,
		ApiError
	>(
		["one-deposit"],
		async () => {
			return await FinanceService.getOnePayment(id as string);
		},
		{
			enabled: true,
			onSuccess: (res) => {
				// setSphNumber(res.data.number);
				setStatus(res.data.status);

				// try {
				// 	const po = res?.data.PurchaseOrder;
				// 	setPo(po);
				// } catch (e) {
				// 	//
				// }

				// try {
				// const projectDetails = [
				// 	!!res?.data?.Account?.Customer ||
				// 		(!!res?.data?.Account?.Customer && {
				// 			type: "noCustomer"
				// 		}),
				// 	res?.data?.Account.Project?.Customer && {
				// 		name: "Tipe Pelanggan",
				// 		type: "customerTypeChip",
				// 		data:
				// 			res?.data?.Account?.Customer?.type ===
				// 			"COMPANY"
				// 				? "PERUSAHAAN"
				// 				: res?.data?.Account?.Customer?.type
				// 	},
				// 	res?.data?.Account?.Customer
				// 		? {
				// 				name: "Nama Pelanggan",
				// 				data:
				// 					res?.data?.Account?.Customer
				// 						?.displayName ?? "-"
				// 		  }
				// 		: null,

				// 	{
				// 		name: "Nama Proyek",
				// 		data: res?.data.Account?.Project?.name ?? "-"
				// 	},

				// 	{
				// 		name: "PIC Utama",
				// 		type: "pic",
				// 		data: {
				// 			name: res?.data.Account?.Project?.mainPic.name,
				// 			position:
				// 				res?.data?.Account?.Project?.mainPic?.position,
				// 			phone: res?.data?.Account?.Project?.mainPic?.phone,
				// 			email: res?.data?.Account?.Project?.mainPic?.email
				// 		}
				// 	},
				// 	res?.data?.Account?.Project?.Pics.length > 1 && {
				// 		name: "PICs",
				// 		type: "pics",
				// 		data: {
				// 			pics: res?.data?.Account?.Project?.Pics,
				// 			mainPIC: res?.data?.Account?.Project?.mainPic
				// 		}
				// 	},
				// 	{
				// 		name: "Alamat Penagihan",
				// 		type: "address",
				// 		data: {
				// 			line1: res?.data?.Account?.Project?.BillingAddress
				// 				?.line1,
				// 			lat: res?.data?.Account?.Project?.BillingAddress
				// 				?.lat,
				// 			lon: res?.data?.Account?.Project?.BillingAddress
				// 				?.lon
				// 		}
				// 	},
				// 	{
				// 		name: "Alamat Pengiriman",
				// 		type: "address",
				// 		data: {
				// 			line1: res?.data?.Account?.Project?.ShippingAddress
				// 				?.line1,
				// 			lat: res?.data?.Account?.Project?.ShippingAddress
				// 				?.lat,
				// 			lon: res?.data?.Account?.Project?.ShippingAddress
				// 				?.lon
				// 		}
				// 	},
				// 	{
				// 		name: "Alamat Proyek",
				// 		type: "address",
				// 		data: {
				// 			line1: res?.data?.Account?.Project?.LocationAddress
				// 				?.line1,
				// 			lat: res?.data?.Account?.Project?.LocationAddress
				// 				?.lat,
				// 			lon: res?.data?.Account?.Project?.LocationAddress
				// 				?.lon
				// 		}
				// 	}
				// ];

				const customerDetails = [
					!!res?.data?.Account?.Customer ||
						(!!res?.data?.Account?.Project && {
							type: "noCustomer"
						}),

					res?.data?.Account?.Customer && {
						name: "Tipe Customer",
						type: "customerTypeChip",
						data:
							res?.data?.Account?.Customer?.type === "COMPANY"
								? "PERUSAHAAN"
								: res?.data?.Account?.Customer?.type
					},
					{
						name: "Nama Customer",
						data: res?.data?.Account?.Customer?.displayName ?? "-"
					},

					{
						name: "PIC Utama",
						type: "pic",
						data: {
							name: res?.data.Account?.Customer?.Pic?.name,
							position:
								res?.data?.Account?.Customer?.Pic?.position,
							phone: res?.data?.Account?.Customer?.Pic?.phone,
							email: res?.data?.Account?.Customer?.Pic?.email
						}
					},
					// res?.data?.Account?.Project?.Pics?.length > 1 && {
					// 	name: "PICs",
					// 	type: "pics",
					// 	data: {
					// 		pics: res?.data?.Account?.Customer?.Pic,
					// 		mainPIC: res?.data?.Account?.mainPic
					// 	}
					// },
					{
						name: "Alamat Penagihan",
						type: "address",
						data: {
							line1: res?.data?.Account?.Customer?.BillingAddress
								?.line1,
							lat: res?.data?.Account?.Customer?.BillingAddress
								?.lat,
							lon: res?.data?.Account?.Customer?.BillingAddress
								?.lon
						}
					}
				];

				setCustomerDetails(customerDetails);

				const files =
					res?.data?.PaymentFiles?.map((file, index) => {
						return {
							name: file?.File?.name,
							type: "file",
							data: file?.File
						};
					}) ?? [];
				setFiles(files);
				// } catch (e) {
				// 	//
				// }

				// try {
				const summary = [
					res.data.type === "DEPOSIT" && {
						name: "Dibuat Oleh",
						type: "text",
						data: res?.data?.User?.fullName ?? "-"
					},
					// {
					// 	name: "PO",
					// 	type: "text",
					// 	data: res?.data?.PurchaseOrder?.customerNumber
					// },
					// {
					// 	name: "SO",
					// 	type: "text",
					// 	data: res?.data?.PurchaseOrder?.brikNumber
					// },
					// {
					// 	name: "Total PO",
					// 	type: "text",
					// 	data: getPriceFormatted(
					// 		res?.data?.PurchaseOrder?.totalPrice,
					// 		"Rp "
					// 	)
					// },
					{
						name: "Jumlah Deposit",
						type: "text",
						data: res?.data?.amount
							? getPriceFormatted(res?.data?.amount, "Rp ")
							: "Rp -"
					}
				];
				setSummary(summary);
				// } catch (e) {
				// 	//
				// }
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const patchStatus = useMutation(
		async (status: "APPROVED" | "DECLINED" | "SUBMITTED") => {
			return await FinanceService.updatePayment(id as string, status);
		},
		{
			onSuccess: () => {
				refetch();
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const handleButton =
		(status: "APPROVED" | "DECLINED" | "SUBMITTED") => () => {
			patchStatus.mutate(status);
		};

	const { localDialog, setLocalDialog } = useLocalDialog();

	if (isLoading || patchStatus.isLoading || isRefetching) {
		return <Loading />;
	}

	return (
		<div className="p-4 pt-6">
			<div className="d-flex flex-row align-items-center mb-2">
				<UpdatePayment
					localDialog={localDialog}
					setLocalDialog={setLocalDialog}
					refetch={refetch}
					number={data?.data.number as string}
				/>
				<div
					className="b-back-btn border mr-3"
					onClick={() => {
						navigate(-1);
					}}
				>
					<ChevronLeft />
				</div>
				<h1 className="mb-0 mr-2">{data?.data.number}</h1>
				<Button
					size="sm"
					color="warning"
					className="confirm-button decline-button ml-1"
					onClick={() => {
						localDialog.onOpen();
					}}
				>
					Ubah Nomor Deposit
				</Button>

				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						flex: 1,
						alignItems: "center"
					}}
				>
					<BStatus>{status}</BStatus>

					{status === "SUBMITTED" &&
					paymentPermission &&
					paymentPermission.update ? (
						<div>
							{/* <Button
								onClick={handleButton("APPROVED")}
								size="sm"
								color="success"
								className="confirm-button approve-button"
							>
								Approve
							</Button> */}
							<Button
								onClick={handleButton("DECLINED")}
								size="sm"
								color="danger"
								className="confirm-button decline-button ml-1"
							>
								Decline
							</Button>
						</div>
					) : null}
					{status === "INCOMPLETE" ? (
						<CompletePayment refetch={refetch} />
					) : null}
				</div>
			</div>
			<div className="d-flex flex-column flex-lg-row">
				<div className="d-flex flex-column mb-3" style={{ flex: 0.7 }}>
					<BCard title="Ringkasan Pembayaran" data={summary} />
					<BCard className="mt-3" title="Dokumen" data={files} />
				</div>
				<div className="spacer-v" />
				<div className="d-flex flex-column" style={{ flex: 0.3 }}>
					<BCard title="Rincian Pelanggan" data={customerDetails} />
					<i className="bi bi-download"></i>
				</div>
			</div>
		</div>
	);
};

export default DepositDetail;
