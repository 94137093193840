import React, { useEffect } from "react";
import * as Yup from "yup";
import { useLocalDialog } from "hooks";
import { Dispatch, SetStateAction } from "react";
import { LocalDialog } from "components";
import { Form, Formik, ErrorMessage, Field } from "formik";
import { FormGroup, Input, Label } from "reactstrap";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "react-query";
import { CommonService } from "api/axios";
import { useAppDispatch } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";

interface payloadEditSchedule {
	date: string;
	withTechnician: boolean;
}

interface EditScheduleModalProps {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	date: any;
	reqTechnician: boolean;
	editIdSchedule: string;
	refetch: () => void;
	hasDo: boolean;
}

const editScheduleSchema = Yup.object().shape({
	date: Yup.string().required("Tanggal Pengiriman Diperlukan"),
	time: Yup.string().required("Jam Pengiriman Diperlukan")
});

export default function EditScheduleModal({
	isOpen,
	setIsOpen,
	date,
	editIdSchedule,
	reqTechnician,
	refetch,
	hasDo
}: EditScheduleModalProps) {
	const { localDialog } = useLocalDialog();
	const modifiedDate = date.split("T");
	const dispatch = useAppDispatch();
	const newDate = new Date(date);
	const hours = newDate.getHours();
	const minutes = newDate.getMinutes();
	const formattedHours = hours < 10 ? "0" + hours : hours;
	const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
	const formattedTime = `${formattedHours}:${formattedMinutes}`;

	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		}
	}, [isOpen]);

	const { mutateAsync: editSchedule } = useMutation(
		async (data: payloadEditSchedule) => {
			const res = await CommonService.editSchedule(data, editIdSchedule);
			return res;
		}
	);

	return (
		<LocalDialog
			isOpen={isOpen}
			backdrop={"static"}
			header="Ubah Jadwal"
			onClose={() => {
				localDialog.onClose();
				setTimeout(() => {
					setIsOpen(false);
				});
			}}
		>
			{/* Konten modal */}
			<Formik
				initialValues={{
					date: modifiedDate[0] ? modifiedDate[0] : "",
					time: formattedTime,
					withTechnician: reqTechnician
				}}
				validationSchema={editScheduleSchema}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					try {
						const modifiedDate = values.date.split("-");
						const year = modifiedDate[0];
						const month = +modifiedDate[1] - 1;
						const day = modifiedDate[2];
						const tempHours = values.time.split(":");
						const hour = Number(tempHours[0]);
						const minute = Number(tempHours[1]);
						const combineDate = new Date(
							year,
							month,
							day,
							hour,
							minute
						);
						const res = await editSchedule({
							date: combineDate.getTime().toString(),
							withTechnician: values.withTechnician
						});
						setIsOpen(false);
						dispatch(
							openAlert({
								body: res.message,
								color: "success"
							})
						);
						refetch();
					} catch (error) {
						dispatch(
							openAlert({
								body: "Gagal mengubah schedule",
								color: "danger"
							})
						);
					}
				}}
			>
				{({ errors, handleChange, isSubmitting, values }) => {
					return (
						<Form>
							<FormGroup>
								{/* <div>
									{hasDo && (
										<p
											style={{
												color: "red",
												fontSize: "13px"
											}}
										>
											Jadwal yang sudah memiliki DO tidak
											bisa di ubah
										</p>
									)}
								</div> */}
								<div style={{ display: "flex", gap: "1em" }}>
									<div
										style={{
											flex: 1,
											display: "flex",
											flexDirection: "column"
										}}
									>
										<Label
											style={{ marginTop: "1em" }}
											htmlFor="tanggal"
										>
											Tanggal Tiba Di Lokasi
										</Label>
										<Input
											name="date"
											type="date"
											value={values.date}
											onChange={handleChange}
											min={
												new Date()
													.toISOString()
													.split("T")[0]
											}
											disabled={hasDo}
										/>
										<ErrorMessage
											name="date"
											component="div"
											className="error-msg"
										/>
									</div>
									<div
										style={{
											flex: 1,
											display: "flex",
											flexDirection: "column",
											justifyContent: "right"
										}}
									>
										<Label style={{ marginTop: "1em" }}>
											Jam Tiba Di Lokasi
										</Label>
										<Input
											type="time"
											name="time"
											className="form-control"
											onChange={handleChange}
											value={values.time}
											disabled={hasDo}
										/>
										<ErrorMessage
											name="time"
											component="div"
											className="error-msg"
										/>
									</div>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										gap: "10px",
										marginTop: "10px"
									}}
								>
									<Field
										type="checkbox"
										name="withTechnician"
										checked={values.withTechnician}
										onChange={handleChange}
									/>
									<Label
										check
										htmlFor="withTechnician"
										style={{
											marginRight: "25px",
											marginLeft: "1px"
										}}
									>
										Request Teknisi
									</Label>
								</div>
							</FormGroup>
							<div className="modal-custom-footer">
								<LoadingButton
									color="error"
									onClick={() => {
										localDialog.onClose();
										setTimeout(() => {
											setIsOpen(false);
										}, 500);
									}}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Batal
									</span>
								</LoadingButton>
								<LoadingButton
									color="warning"
									variant="contained"
									type="submit"
									loading={isSubmitting}
									// disabled={isSubmitting || hasDo}
									sx={{
										backgroundColor: "#FF6600",
										color: "white"
									}}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Ubah
									</span>
								</LoadingButton>
							</div>
						</Form>
					);
				}}
			</Formik>
		</LocalDialog>
	);
}
