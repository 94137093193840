import React, { useEffect, useState } from "react";
import styles from "./RouteEditForm.module.scss";
import LocalDialog from "./localDialog";
import { useAppSelector } from "store/hooks";
import BDropdown from "./dropdown";
import { Autocomplete, Button, InputAdornment, TextField } from "@mui/material";
import BSwitch from "./Switch";
import { Icon } from "@iconify/react";
import { CommonService } from "api/axios";
import BMapPreview from "./MapPreview";

const BRouteEditForm = (props: {
	data: any;
	isOpen: boolean;
	onClose?: any;
	innerClassName?: any;
	routes?: any;
	projectId?: string | undefined;
}) => {
	const { isOpen, onClose, innerClassName, routes, projectId } = props;
	const { options: _bpOptions } = useAppSelector((state) => state.batching);
	const bpOptions = _bpOptions.map((opt) => { return { ...opt, label: `${opt.labelStr} - ${opt.code}` }; });

	const [data, setData] = useState<any>({});
	const [route, setRoute] = useState<any>(null);
	const [previewCoordinates, setPreviewCoordinates] = useState<any>([]);
	const [parseLoading, setParseLoading] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		// console.log(routes, 'routes');
		let route: any;
		if (data?.batchingPlantId) {
			route = (routes?.find((r: any) => r?.data?.batchingPlantId == data?.batchingPlantId)?.data || null) as any;
			if (route) {
				setData((oldValue: any) => {
					return {
						...oldValue,
						batchingPlant: data?.batchingPlant,
						batchingPlantId: data?.batchingPlantId,
						...(route || {}),
						lastGmapUrl: route?.gmapUrl,
					};
				});
			}
		}
		setRoute(route);
		if (route == null || !data?.batchingPlantId) {
			setData({
				batchingPlant: data?.batchingPlant,
				batchingPlantId: data?.batchingPlantId,
			});
		}
	}, [data?.batchingPlantId, routes]);

	useEffect(() => {
		setPreviewCoordinates(data?.route?.geometry?.coordinates || []);
	}, [data?.route?.geometry?.coordinates]);
	useEffect(() => {
		console.log({ previewCoordinates });
	}, [previewCoordinates]);

	const onParse = async () => {
		try {
			setParseLoading(true);
			const res = await CommonService.parseGoogleMaps({
				url: data?.gmapUrl
			});
			setData((oldValue: any) => {
				return {
					...oldValue,
					route: res?.data?.route,
					lastGmapUrl: oldValue?.gmapUrl
				};
			});
			console.log(res);
		}
		catch (e) {
			console.log(e);
		}
		setParseLoading(false);
	};

	const onSubmit = async () => {
		try {
			setLoading(true);
			if (!data?.gmapUrl || !data?.batchingPlantId || !projectId || !data?.route) throw new Error("Incomplete");
			const res = await CommonService.createOrUpdateRoute({
				url: data?.gmapUrl,
				batchingPlantId: data?.batchingPlantId,
				projectId: projectId,
				route: data?.route,
				isSurveyed: data?.isSurveyed,
				updateShippingAddress: data?.updateShippingAddress
			});
			console.log({ res });
			onClose();
		} catch (e) {
			console.log(e);
		}
		setLoading(false);
	};

	return (
		<LocalDialog
			isOpen={isOpen}
			backdrop={"static"}
			header="Tambah / Edit Route"
			onClick={() => {
				onSubmit();
			}}
			bodyStyle={{ paddingBottom: 0 }}
			loading={loading}
			rightBtnTitle="Simpan"
			onClose={() => {
				onClose();
				setData({});
				setLoading(false);
			}}
			disableRightBtn={!data?.gmapUrl || !data?.batchingPlantId || !projectId || !data?.route || !(data?.gmapUrl == data?.lastGmapUrl && data?.gmapUrl && data?.route)}
		>
			<div className={[styles.innerContainer, innerClassName].join(" ")}>
				<div className={styles.formContainer}>
					{
						// BP
						// Line
						// Url - button load
						// Route Surveyed
						// Recalc Count

					}
					<Autocomplete
						disablePortal
						options={bpOptions}
						value={data.batchingPlant || null}
						isOptionEqualToValue={(option: any, value: any) => {
							return option?.id === value?.id;
						}}
						onChange={(e: any, value: any) => {
							setData((oldValue: any) => {
								return {
									...oldValue,
									batchingPlant: value,
									batchingPlantId: value?.id
								};
							});
						}}
						renderInput={(params) => <TextField {...params} label="Batching Plant / Asal" variant="filled" />}
					/>
					{data.batchingPlantId && <>
						<div className="break-line" />
						<TextField
							label="URL Google Maps"
							variant="filled"
							value={data.gmapUrl || ""}
							onChange={(e: any) => {
								setData((oldValue: any) => {
									return {
										...oldValue,
										gmapUrl: e.target.value
									};
								});
							}}
							InputProps={{
								endAdornment: <InputAdornment position="end">
									<div className={styles.adornmentActions}>
										{(data?.gmapUrl == data?.lastGmapUrl && !!data?.gmapUrl && !!data?.route) &&
											<Icon icon="radix-icons:check" className={styles.checkIcon} />
										}
										{!(data?.gmapUrl == data?.lastGmapUrl && data?.gmapUrl && data?.route) && <Button
											variant="contained"
											disabled={
												(!data?.gmapUrl ||
													!(data.gmapUrl.startsWith("https://") || data.gmapUrl.startsWith("http://")) ||
													!(data.gmapUrl.includes("goo.gl") || data.gmapUrl.includes("google")) ||
													!data.gmapUrl.includes("maps")) ||
												(!!route?.gmapUrl && !!route?.gmapUrl == data?.gmapUrl) ||
												parseLoading
											}
											// color="#ffcd03"
											style={{ textTransform: "initial", boxShadow: "none" }}
											onClick={onParse}
										>
											Tarik
										</Button>}
									</div>
								</InputAdornment>
							}}
						/>
						<div className={styles.splitInputContainer}>
							<div className={styles.spacedContainer}>
								<p className={styles.label}>Sudah di Survey?</p>
								<BSwitch
									checked={data.isSurveyed || false}
									onChange={((e: any, value: any) => {
										setData((oldValue: any) => {
											return {
												...oldValue,
												isSurveyed: value
											};
										});
									})}
								/>
							</div>
							<div className={styles.verticalLine} />
							<div className={styles.spacedContainer}>
								<p className={styles.label}>Recalculate Count:</p>
								<p className={styles.value}>{data?.recalculateCount || "-"}</p>
							</div>
						</div>
						<div style={{ flex: 1 }} />
						<div className={styles.spacedContainer}>
							<p className={styles.label}>Update Titik Proyek?</p>
							<BSwitch
								checked={data.updateShippingAddress || false}
								onChange={((e: any, value: any) => {
									setData((oldValue: any) => {
										return {
											...oldValue,
											updateShippingAddress: value
										};
									});
								})}
							/>
						</div>

					</>}
				</div>
				{data?.batchingPlantId && <div className={styles.mapContainer}>
					{/* {previewCoordinates.length > 0 ? */}
					<BMapPreview
						// key={`${JSON.stringify(previewCoordinates)}`}
						data={previewCoordinates}
						className={styles.mapPreview}
						showMarkers={true}
						markers={data?.route?.initialWaypoints}
						padding={20}
					/>
					{/* :
						<div className={[styles.mapPreview, styles.noRoute].join(" ")} />
					} */}
				</div>}
			</div>
		</LocalDialog>
	);
};

export default BRouteEditForm;
