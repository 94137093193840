import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";

// Define a type for the slice state
interface DialogState {
	options: options[]
	bp: options
}

interface PayloadActionDialog {
	// onChange?: (() => void),
	payload?: options | options[]
}

// Define the initial state using that type
const initialState: DialogState = {
	options: [],
	bp: {}
};

export const dialogSlice = createSlice({
	name: "modal",
	initialState,
	reducers: {
		changeBP: (state, action: PayloadAction<PayloadActionDialog>) => {
			const { payload: { payload } } = action;
			return {
				...state,
				...payload && {
					bp: payload
				}
			};
		},
		loadBP: (state, action: PayloadAction<PayloadActionDialog>) => {
			const { payload: { payload } } = action;
			return {
				...state,
				...payload && {
					options: payload as options[]
				}
			};
		},
	},
});

export const { changeBP, loadBP } = dialogSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.modal;

export default dialogSlice.reducer;
