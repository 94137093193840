import * as React from "react";
import * as Yup from "yup";
import { LocalDialog } from "components";
import { useLocalDialog } from "hooks";
import { useDebounce } from "use-debounce";
import { Dispatch, SetStateAction, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FormGroup, Input, Label } from "reactstrap";
import { useMutation, useQuery } from "react-query";
import { CommonService } from "api/axios";
import Select from "react-select";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";

interface PayloadCreateSchedule {
	saleOrderId: string;
	projectId: string;
	purchaseOrderId: string;
	quotationLetterId: string;
	quantity: number;
	date: string;
	consecutive: boolean;
	withTechnician: boolean;
	status: string;
	pouringMethod: string;
	batchingPlantId: string;
	interval: number | null;
}

const createScheduleSchema = Yup.object().shape({
	batchingPlantId: Yup.object().shape({
		valueStr: Yup.string().required("Lokasi Batching Plant Dibutuhkan"),
		labelStr: Yup.string().required("Lokasi Batching Plant Dibutuhkan")
	}),
	customer: Yup.object().shape({
		valueStr: Yup.string().required("Nama Pelanggan Dibutuhkan"),
		labelStr: Yup.string().required("Nama Pelanggan Dibutuhkan")
	}),
	projectId: Yup.object().shape({
		valueStr: Yup.string().required("Nama Proyek Dibutuhkan"),
		labelStr: Yup.string().required("Nama Proyek Dibutuhkan")
	}),
	purchaseOrderId: Yup.object().shape({
		valueStr: Yup.string().required("Purchase Order Dibutuhkan"),
		labelStr: Yup.string().required("Purchase Order Dibutuhkan")
	}),
	date: Yup.date().required("Tanggal pengiriman diperlukan"),
	time: Yup.string().required("Jam pengiriman diperlukan"),
	pouringMethod: Yup.object().shape({
		valueStr: Yup.string().required("Metode Penuangan harus dipilih"),
		labelStr: Yup.string().required("Metode Penuangan harus dipilih")
	}),
	quantity: Yup.number()
		.typeError("Kuantitas harus berupa angka")
		.required("Kuantitas harus diisi")
		.positive("Kuantitas harus lebih dari 0"),
	saleOrderId: Yup.object().shape({
		valueStr: Yup.string().required("Product harus dipilih"),
		labelStr: Yup.string().required("Product harus dipilih")
	})
});

export default function CreateSchedule({
	isOpen,
	setIsOpen,
	refetch
}: {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	refetch: () => void;
}) {
	const { localDialog } = useLocalDialog();
	const [nameCustomer, setNameCustomer] = React.useState<string>("");
	const [searchCustomerQ] = useDebounce(nameCustomer, 500);
	const [optionProject, setOptionProject] = React.useState<any>([]);
	const [projectSelected, setProjectSelected] = React.useState<any>("");
	const [purchaseOrderSelected, setPurchaseOrderSelected] =
		React.useState<any>("");
	const dispatch = useAppDispatch();
	const [pouringMethod, setpouringMethod] = React.useState<any>([
		{
			valueStr: "Manual",
			labelStr: "Manual"
		},
		{
			valueStr: "Concrete Pump",
			labelStr: "Concrete Pump"
		}
	]);
	const [saleOrderId, setSaleOrderId] = React.useState<any>("");
	const [optionsProduct, setOptionsProduct] = React.useState<any>([]);
	const [categoryProduct, setCategoryProduct] = React.useState<any>([]);
	const [isSelected, setIsSelected] = React.useState<boolean>();

	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		}
	}, [isOpen]);

	const { data: customers } = useQuery<any, ApiError>(
		["query-search-customer", searchCustomerQ],
		async () => {
			return await CommonService.getAllCustomer(1, 20, {
				search: searchCustomerQ
			});
		},
		{
			enabled: searchCustomerQ?.length ? true : false
		}
	);

	const optionCustomer = React.useMemo(() => {
		if (customers?.data?.data?.length) {
			const data = customers?.data?.data.map((el: any) => {
				return {
					...el,
					valueStr: el.id,
					labelStr: el.displayName
				};
			});
			return data;
		}
		return [];
	}, [customers]);

	const { data: purchOrder } = useQuery<any, ApiError>(
		["query-detail-purchaseOrders", projectSelected],
		async () => {
			return await CommonService.getPurchaseOrderByProject(
				projectSelected
			);
		},
		{
			enabled: !!projectSelected
		}
	);

	const optionPurchaseOrder = React.useMemo(() => {
		if (purchOrder?.data) {
			const data = purchOrder?.data
				.map((el: any) => {
					// remove PO where actualQuantity is greater than or equal to totalRequestedQuantity
					// if (el.actualQuantity < el.totalRequestedQuantity) {
						return {
							...el,
							valueStr: el.id,
							labelStr: `${el.number} \nSCH: ${el.totalScheduledQuantity}/${el.totalRequestedQuantity} m3 | ACT: ${el.actualQuantity}/${el.totalRequestedQuantity} m3`
						};
					// }
					// return null;
				})
				.filter(Boolean);
			return data;
		}
		return [];
	}, [purchOrder]);

	const { data: saleOrder } = useQuery<any, ApiError>(
		["query-saleOrder", purchaseOrderSelected],
		async () => {
			return await CommonService.getSaleOrderByOurchaseOrder(
				purchaseOrderSelected
			);
		},
		{
			enabled: !!purchaseOrderSelected
		}
	);

	const optionProduct = React.useMemo(() => {
		if (saleOrder) {
			const data = saleOrder?.data.map((el: any) => {
				return {
					...el,
					valueStr: el.id,
					labelStr: `${el.PurchaseOrderProduct?.RequestedProduct?.displayName} ${el.PurchaseOrderProduct?.RequestedProduct?.Product?.Category?.displayN}`
				};
			});
			return data;
		}
		return [];
	}, [saleOrder]);

	const { data: batchingPlant } = useQuery<any, ApiError>(
		["query-batchingPlant", true],
		async () => {
			return await CommonService.getBP();
		},
		{
			enabled: true
		}
	);

	const listBatchingPlant = React.useMemo(() => {
		if (batchingPlant?.data?.data?.length) {
			const data = batchingPlant?.data.data.map((el: any) => {
				return {
					...el,
					valueStr: el.id,
					labelStr: el.name
				};
			});
			return data;
		}
		return [];
	}, [batchingPlant]);

	const { mutateAsync: createSchedule } = useMutation(
		async (data: PayloadCreateSchedule) => {
			const res = await CommonService.createSchedule(data);
			return res;
		}
	);

	const labelStyle = {
		// marginBottom: "1em", // Adjust this value as needed
		marginTop: "1em" // Adjust this value as needed
	};

	const selectStyles = {
		option: (provided: any) => ({
			...provided,
			whiteSpace: "pre-wrap" // Preserve line breaks
		}),
		valueLabel: (provided: any) => ({
			...provided,
			whiteSpace: "pre-wrap" // Preserve line breaks
		})
	};

	return (
		<LocalDialog
			isOpen={localDialog.isOpen}
			backdrop={"static"}
			header="Buat Jadwal Baru"
			onClose={() => {
				localDialog.onClose();
				setTimeout(() => {
					setIsOpen(false);
				}, 500);
			}}
		>
			<Formik
				initialValues={{
					customer: {
						labelStr: "",
						valueStr: ""
					},
					saleOrderId: {
						labelStr: "",
						valueStr: ""
					},
					projectId: {
						labelStr: "",
						valueStr: ""
					},
					purchaseOrderId: {
						labelStr: "",
						valueStr: ""
					},
					quotationLetterId: {
						labelStr: "",
						valueStr: ""
					},
					quantity: 0,
					date: "",
					time: "",
					consecutive: false,
					withTechnician: false,
					pouringMethod: {
						labelStr: "",
						valueStr: ""
					},
					batchingPlantId: {
						labelStr: "",
						valueStr: ""
					},
					interval: 0,
					status: "SUBMITTED"
				}}
				validationSchema={createScheduleSchema}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					try {
						const temp = new Date(values.date);
						const year = temp.getFullYear();
						const month = temp.getMonth();
						const day = temp.getDate();
						const tempHours = values.time.split(":");
						const hour = Number(tempHours[0]);
						const minute = Number(tempHours[1]);
						const combineDate = new Date(
							year,
							month,
							day,
							hour,
							minute
						);
						const res = await createSchedule({
							saleOrderId: values?.saleOrderId?.valueStr,
							projectId: values?.projectId?.valueStr,
							purchaseOrderId: values?.purchaseOrderId?.valueStr,
							quotationLetterId:
								values?.quotationLetterId?.valueStr,
							quantity: values?.quantity,
							date: combineDate.getTime().toString(),
							consecutive: values?.consecutive,
							withTechnician: values?.withTechnician,
							status: values?.status,
							pouringMethod: values?.pouringMethod?.valueStr,
							batchingPlantId: values?.batchingPlantId?.valueStr,
							interval:
								values?.interval > 0 ? values.interval : null
						});
						setIsOpen(false);
						dispatch(
							openAlert({
								body: res.message,
								color: "success"
							})
						);
						refetch();
					} catch (error) {
						dispatch(
							openAlert({
								body: "Gagal menambah Schedule baru",
								color: "danger"
							})
						);
					}
				}}
			>
				{({
					errors,
					handleChange,
					isSubmitting,
					values,
					setFieldValue
				}) => {
					const handleConsecutiveChange = (
						e: React.ChangeEvent<HTMLInputElement>
					) => {
						handleChange(e);
						setFieldValue("interval", 0);
						setIsSelected(e.target.checked);
					};
					return (
						<Form>
							<FormGroup>
								<Label style={labelStyle}>
									Pilih Batching Plant
								</Label>
								<Select
									onChange={(change) => {
										if (change) {
											values.batchingPlantId.labelStr =
												change.labelStr as string;
											values.batchingPlantId.valueStr =
												change.valueStr as string;
											setFieldValue("batchingPlantId", {
												labelStr: change.labelStr,
												valueStr: change.valueStr
											});
										}
									}}
									name="batchingPlantId"
									value={values.batchingPlantId}
									options={listBatchingPlant}
									getOptionLabel={(option: options) =>
										option.labelStr as string
									}
									getOptionValue={(option: options) =>
										option.valueStr as string
									}
									isDisabled={false} // Field pertama selalu aktif
								/>
								<ErrorMessage
									name={"batchingPlantId.labelStr"}
									component="div"
									className="error-msg"
								/>

								<Label
									style={labelStyle}
									htmlFor="searchCustomer"
								>
									Pelanggan
								</Label>
								<Select
									onInputChange={(text) => {
										setNameCustomer(text);
									}}
									onChange={(change) => {
										if (change) {
											setFieldValue("customer", {
												labelStr: change.labelStr,
												valueStr: change.valueStr
											});
											setFieldValue("projectId", {
												labelStr: "",
												valueStr: ""
											});
											setFieldValue("purchaseOrderId", {
												labelStr: "",
												valueStr: ""
											});
											setFieldValue("saleOrderId", {
												labelStr: "",
												valueStr: ""
											});
											const projOpt = change.Projects.map(
												(el: any) => {
													return {
														...el,
														valueStr: el.id,
														labelStr: el.name
													};
												}
											);

											setOptionProject(projOpt);
											// setFieldValue("customer", {})
											values.customer.labelStr =
												change?.labelStr as string;
											values.customer.valueStr =
												change?.valueStr as string;
										}
									}}
									name="customer"
									value={values.customer}
									options={optionCustomer}
									getOptionLabel={(option: options) =>
										option.labelStr as string
									}
									getOptionValue={(option: options) =>
										option.labelStr as string
									}
									noOptionsMessage={() =>
										"Ketik Nama Customer"
									}
									isDisabled={false}
								/>
								<ErrorMessage
									name={"customer.labelStr"}
									component="div"
									className="error-msg"
								/>
								<Label style={labelStyle} htmlFor="Project">
									Proyek
								</Label>
								<Select
									onChange={(change) => {
										if (change) {
											setFieldValue("projectId", {
												labelStr: change.labelStr,
												valueStr: change.valueStr
											});
											setProjectSelected(change.valueStr);
											values.projectId.labelStr =
												change?.labelStr as string;
											values.projectId.valueStr =
												change?.valueStr as string;
										}
									}}
									name="projectId"
									value={values.projectId}
									options={optionProject}
									getOptionLabel={(option: options) =>
										option.labelStr as string
									}
									getOptionValue={(option: options) =>
										option.valueStr as string
									}
									noOptionsMessage={() => "Ketik Nama Proyek"}
									isDisabled={
										values.customer.valueStr ? false : true
									}
								/>
								<ErrorMessage
									name={"projectId.labelStr"}
									component="div"
									className="error-msg"
								/>
								<Label
									style={labelStyle}
									htmlFor="purchaseOrder"
								>
									Pilih PO
								</Label>
								<Select
									onChange={(change) => {
										if (change) {
											setFieldValue("purchaseOrderId", {
												labelStr: change.labelStr,
												valueStr: change.valueStr
											});
											setFieldValue("quotationLetterId", {
												labelStr:
													change.quotationLetterId,
												valueStr:
													change.quotationLetterId
											});
											setPurchaseOrderSelected(
												change.valueStr
											);
											values.purchaseOrderId.valueStr =
												change?.valueStr as string;
											values.purchaseOrderId.labelStr =
												change?.labelStr as string;
											// values.quotationLetterId =
											//     change?.quotationLetterId as string;
										}
									}}
									name="purchaseOrderId"
									value={values.purchaseOrderId}
									options={optionPurchaseOrder}
									getOptionLabel={(option: options) =>
										option.labelStr as string
									}
									getOptionValue={(option: options) =>
										option.valueStr as string
									}
									styles={selectStyles}
									// noOptionsMessage={() => "Ketik Nama Customer"}
									isDisabled={
										values.projectId.valueStr ? false : true
									}
								/>
								<ErrorMessage
									name={"purchaseOrderId.labelStr"}
									component="div"
									className="error-msg"
								/>
								<Label style={labelStyle}>Produk</Label>
								<Select
									onChange={(change) => {
										if (change) {
											setFieldValue("saleOrderId", {
												labelStr: change.labelStr,
												valueStr: change.valueStr
											});
											values.saleOrderId.valueStr =
												change?.valueStr as string;
											values.saleOrderId.labelStr =
												change?.labelStr as string;
										}
									}}
									name="saleOrderId"
									value={values.saleOrderId}
									options={optionProduct}
									getOptionLabel={(option: options) =>
										option.labelStr as string
									}
									getOptionValue={(option: options) =>
										option.valueStr as string
									}
									isDisabled={
										values.purchaseOrderId.valueStr
											? false
											: true
									}
								/>
								<ErrorMessage
									name={"saleOrderId.labelStr"}
									component="div"
									className="error-msg"
								/>

								{/* <Label style={labelStyle} htmlFor="tanggal">Tanggal & Jam pengiriman</Label> */}

								<div style={{ display: "flex", gap: "1em" }}>
									<div
										style={{
											flex: 1,
											display: "flex",
											flexDirection: "column"
										}}
									>
										<Label
											style={labelStyle}
											htmlFor="tanggal"
										>
											Tanggal Tiba Di Lokasi
										</Label>
										<Input
											name="date"
											type="date"
											value={values.date}
											onChange={handleChange}
											min={
												new Date()
													.toISOString()
													.split("T")[0]
											}
										/>
										<ErrorMessage
											name="date"
											component="div"
											className="error-msg"
										/>
									</div>
									<div
										style={{
											flex: 1,
											display: "flex",
											flexDirection: "column",
											justifyContent: "right"
										}}
									>
										<Label
											style={labelStyle}
											htmlFor="tanggal"
										>
											Jam Tiba Di Lokasi
										</Label>
										<Input
											type="time"
											name="time"
											className="form-control"
											onChange={handleChange}
											value={values.time}
										/>

										<ErrorMessage
											name="time"
											component="div"
											className="error-msg"
										/>
									</div>
								</div>

								<div style={{ display: "flex", gap: "1em" }}>
									<div
										style={{
											flex: 1,
											display: "flex",
											flexDirection: "column"
										}}
									>
										<Label style={labelStyle}>
											Metode Penuangan
										</Label>
										<Select
											name="pouringMethod"
											onChange={(change) => {
												if (change) {
													setFieldValue(
														"pouringMethod",
														{
															labelStr:
																change.labelStr,
															valueStr:
																change.valueStr
														}
													);
													values.pouringMethod.valueStr =
														change?.valueStr as string;
													values.pouringMethod.labelStr =
														change?.labelStr as string;
												}
											}}
											options={pouringMethod}
											getOptionLabel={(option: options) =>
												option.labelStr as string
											}
											getOptionValue={(option: options) =>
												option.valueStr as string
											}
											placeholder={"Metode Penuangan"}
										/>
										<ErrorMessage
											name="pouringMethod.valueStr"
											component="div"
											className="error-msg"
										/>
									</div>
									<div
										style={{
											flex: 1,
											display: "flex",
											flexDirection: "column",
											justifyContent: "right"
										}}
									>
										<Label style={labelStyle}>
											Kuantitas (m³)
										</Label>
										<Input
											name="quantity"
											type="number"
											placeholder="m³"
											value={values.quantity}
											onChange={handleChange}
											style={{
												height: "38px"
											}}
										/>
										<ErrorMessage
											name="quantity"
											component="div"
											className="error-msg"
										/>
									</div>
								</div>

								<div style={{ display: "flex", gap: "1em" }}>
									<div
										style={{
											flex: 1,
											display: "flex",
											flexDirection: "column"
										}}
									>
										<Label style={labelStyle}>
											Interval (m)
										</Label>
										<Input
											name="interval"
											type="number"
											placeholder="m"
											disabled={isSelected}
											value={values.interval}
											onChange={handleChange}
											// value={values.quantity}
											// onChange={handleChange}
											style={{
												height: "38px"
											}}
										/>
										<ErrorMessage
											name="quantity"
											component="div"
											className="error-msg"
										/>
									</div>
								</div>

								<div
									style={{
										display: "flex",
										alignItems: "center",
										gap: "20px"
									}}
								></div>
							</FormGroup>

							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: "10px"
								}}
							>
								<Field
									type="checkbox"
									name="consecutive"
									checked={values.consecutive}
									onChange={handleConsecutiveChange}
								/>
								<Label
									check
									htmlFor="consecutive"
									style={{
										marginRight: "25px",
										marginLeft: "1px"
									}}
								>
									Konsekutif
								</Label>

								<Field
									type="checkbox"
									name="withTechnician"
									checked={values.withTechnician}
									onChange={handleChange}
								/>

								<Label
									check
									htmlFor="withTechnician"
									style={{
										marginRight: "25px",
										marginLeft: "1px"
									}}
								>
									Request teknisi
								</Label>
							</div>

							<div className="modal-custom-footer">
								<LoadingButton
									color="error"
									onClick={() => {
										localDialog.onClose();
										setTimeout(() => {
											setIsOpen(false);
										});
									}}
									loading={isSubmitting}
									disabled={isSubmitting}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Batal
									</span>
								</LoadingButton>
								<LoadingButton
									color="error"
									variant="contained"
									type="submit"
									loading={isSubmitting}
									disabled={isSubmitting}
									sx={{
										backgroundColor: "red",
										color: "white"
									}}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Buat
									</span>
								</LoadingButton>
							</div>
						</Form>
					);
				}}
			</Formik>
		</LocalDialog>
	);
}
