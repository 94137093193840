import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";

interface IUser {
	userRoles: string[];
	permission: {
		[key: string]: {
			[key: string]: boolean;
		};
	};
}

const initialState: IUser = {
	userRoles: [],
	permission: {}
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUserRole: (state, action: PayloadAction<string[]>) => {
			state.userRoles = action.payload;
			return state;
		},
		setPermission: (
			state,
			action: PayloadAction<{
				[key: string]: {
					[key: string]: boolean;
				};
			}>
		) => {
			state.permission = action.payload;
			return state;
		}
	}
});

export const { setUserRole, setPermission } = userSlice.actions;

export const selectCount = (state: RootState) => state.user;

export default userSlice.reducer;
