import React, { useMemo, useState } from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { useLocalDialog } from "hooks";
import { Loading, LocalDialog, MTable } from "components";
import BForm from "components/B/form";
import { FinanceService } from "api/axios";
import {
	QueryObserverResult,
	RefetchOptions,
	RefetchQueryFilters,
	useMutation,
	useQuery
} from "react-query";
import moment from "moment";
import { formatIDR, parseIDR } from "helper";
import { useDispatch } from "react-redux";
import { openAlert } from "store/features/alert/AlertSlice";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { getRouteName, routeMapping } from "routes";

const historiColumns = [
	{
		name: "tanggal",
		label: "Tanggal",
		options: {
			filter: false,
			sort: false
		}
	},
	// {
	// 	name: "proyek",
	// 	label: "Proyek",
	// 	options: {
	// 		filter: false,
	// 		sort: false
	// 	}
	// },
	{
		name: "note",
		label: "Catatan",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "tipe",
		label: "Tipe Pembayaran",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "status",
		label: "Status",
		options: {
			filter: false,
			sort: false
		}
	},
	// {
	// 	name: "bukti",
	// 	label: "Bukti",
	// 	options: {
	// 		filter: false,
	// 		sort: false
	// 	}
	// },
	{
		name: "noTransaksi",
		label: "No. Transaksi",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "total",
		label: "Jumlah",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				if (value.type === "PLUS") {
					return <p className="payment-in">+ {value.value}</p>;
				}
				return <p className="payment-out">{value.value}</p>;
			}
		}
	}
];

interface IProps {
	id: string;
	projects: {
		id: string;
		nama: string;
		city: string;
		lastOrder: string | Date;
		pic: string;
		sales: string;
	}[];
	type: string;
	refetchSummary: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
	) => Promise<QueryObserverResult<ApiCustomerSummary, ApiError>>;
}

export default function PaymentHistory(props: IProps) {
	const location = useLocation();
	const permission = useAppSelector((state) => state.user.permission);
	const customerPermission = useMemo(() => {
		const routeName = getRouteName(location.pathname, routeMapping);

		if (routeName) {
			return permission[routeName];
		}
		return {};
	}, [location, permission]);
	const { id, projects, type, refetchSummary } = props;

	const dispatch = useDispatch();
	// get
	const [typeTrx, setTypeTrx] = useState({
		valueStr: "",
		labelStr: ""
	});
	const [page, setPage] = useState(1);
	const [totalItems, setTotalItems] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [tableData, setTableData] = useState<
		{
			id: string;
			[key: string]: string | number;
		}[]
	>([]);
	const { refetch } = useQuery<ApiCustomerTransactions, ApiError>(
		[
			"query-transactions",
			id,
			typeTrx.valueStr,
			rowsPerPage,
			page,
			projects
		],
		async () => {
			return FinanceService.getCustomerTransactions(id, {
				inOut: typeTrx.valueStr,
				size: rowsPerPage,
				page: page
			});
		},
		{
			enabled: !!id,
			onSuccess: (res) => {
				const data: any[] = res.data.data.map((el) => {
					let bukti;
					let date;
					if (typeTrx.valueStr === "IN") {
						bukti = el?.Payment?.PaymentFiles?.[0]?.File.url ?? "-";
					} else {
						bukti =
							el?.DeliveryOrder?.DeliveryOrderFile?.[0]?.File
								.url ?? "-";
					}
					return {
						id: el.id,
						tanggal: moment(el.createdAt).format("DD/MM/YYYY"),
						proyek:
							el.sourceType === "DO"
								? el.DeliveryOrder?.Project?.name
								: "-",
						accountType: el.Account?.type ?? "-",
						note: el.notes ?? "-",
						tipe: el.sourceType
							? el.sourceType === "PAYMENT"
								? (el.Payment?.type as string)
								: el.sourceType
							: (el.Payment?.type as string),
						status: el.status ?? "-",
						noTransaksi: el.Payment?.number ?? ("-" as string),
						// noTransaksi: el.Payment.
						// "bukti": bukti,
						total:
							{
								value: `Rp. ${formatIDR(el.amount) as string}`,
								type: el.type
							} ?? "-"
					};
				});

				setTotalItems(res.data.totalItems as number);
				setCurrentPage(res.data.currentPage as number);
				setTableData(data);
			}
		}
	);

	// mutation
	const [payload, setPayload] = useState({
		projectId: {
			valueStr: "",
			labelStr: ""
		},
		amount: "",
		type: {
			valueStr: "PLUS",
			labelStr: "Penambahan"
		},
		sourceType: "ADJUSTMENT",
		status: "MUTATED",
		notes: "",
		accountType: {
			valueStr: "CBD",
			labelStr: "CASH"
		}
	});

	// const forms: ElementForm[] = React.useMemo(() => {
	// 	return [
	// 		{
	// 			label: "Tipe",
	// 			type: "dropdown",
	// 			value: payload.type,
	// 			options: [
	// 				{
	// 					valueStr: "PLUS",
	// 					labelStr: "Penambahan"
	// 				},
	// 				{
	// 					valueStr: "MINUS",
	// 					labelStr: "Pengurangan"
	// 				}
	// 			],
	// 			placeholder: "Tipe Transaksi",
	// 			onChange: (e: any) => {
	// 				setPayload({
	// 					...payload,
	// 					type: e
	// 				});
	// 			}
	// 		},
	// 		{
	// 			label: "Jumlah",
	// 			type: "money",
	// 			value: payload.amount,
	// 			placeholder: "Cth Rp 500.000",
	// 			onChange: (e: any) => {
	// 				const value = formatIDR(e.target.value);
	// 				setPayload({
	// 					...payload,
	// 					amount: value as string
	// 				});
	// 			}
	// 		},
	// 		{
	// 			label: "Catatan",
	// 			type: "text",
	// 			value: payload.notes,
	// 			placeholder: "Tulis Catatan",
	// 			onChange: (e: any) => {
	// 				setPayload({
	// 					...payload,
	// 					notes: e.target.value
	// 				});
	// 			}
	// 		}
	// 	];
	// }, [payload, projects]);

	const accountTypeOptions = [
		{
			valueStr: "CBD",
			labelStr: "CASH"
		}
	];

	if (type === "CREDIT") {
		accountTypeOptions.push({
			valueStr: "CREDIT",
			labelStr: "KREDIT"
		});
	}

	const forms: ElementForm[] = React.useMemo(() => {
		const _form: ElementForm[] = [
			{
				label: "Tipe",
				type: "dropdown",
				value: payload.type,
				options: [
					{
						valueStr: "PLUS",
						labelStr: "Penambahan"
					},
					{
						valueStr: "MINUS",
						labelStr: "Pengurangan"
					}
				],
				placeholder: "Tipe Transaksi",
				onChange: (e: any) => {
					setPayload({
						...payload,
						type: e
					});
				}
			},
			{
				label: "Tipe Akun",
				type: "dropdown",
				value: payload.accountType,
				options: accountTypeOptions,
				placeholder: "Tipe Akun",
				onChange: (e: any) => {
					setPayload({
						...payload,
						accountType: e
					});
				}
			}
		];

		_form.push(
			{
				label: "Jumlah",
				type: "money",
				value: payload.amount,
				placeholder: "Cth Rp 500.000",
				onChange: (e: any) => {
					const value = formatIDR(e.target.value);
					setPayload({
						...payload,
						amount: value as string
					});
				}
			},
			{
				label: "Catatan",
				type: "text",
				value: payload.notes,
				placeholder: "Tulis Catatan",
				onChange: (e: any) => {
					setPayload({
						...payload,
						notes: e.target.value
					});
				}
			}
		);

		return _form;
	}, [payload, projects, type]);

	const { mutate, isLoading: loadingAdd } = useMutation<
		ApiResponseAdjustment,
		ApiError,
		PayloadAdjustment,
		ApiError
	>(
		async (_payload: PayloadAdjustment) => {
			const res = await FinanceService.createAdjustment(_payload);
			return res;
		},
		{
			onSuccess: (e) => {
				dispatch(
					openAlert({
						body: "Berhasil Membuat Penyesuaian",
						color: "success"
					})
				);
				localDialog.onClose();
				setPayload({
					projectId: {
						valueStr: "",
						labelStr: ""
					},
					amount: "",
					type: {
						valueStr: "",
						labelStr: ""
					},
					sourceType: "ADJUSTMENT",
					status: "MUTATED",
					notes: "",
					accountType: {
						valueStr: "",
						labelStr: ""
					}
				});
				refetch();
				refetchSummary();
			},
			onError: (err) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const { localDialog } = useLocalDialog();
	// const onSubmit = () => {
	// 	const payloadMutateCREDIT: PayloadAdjustment = {
	// 		customerId: id,
	// 		amount: parseIDR(payload.amount),
	// 		type: payload.type.valueStr,
	// 		sourceType: "ADJUSTMENT",
	// 		status: "PENDING",
	// 		notes: payload.notes.length ? payload.notes : null
	// 	};

	// 	mutate(payloadMutateCREDIT);
	// };

	const onSubmit = () => {
		const payloadMutate: PayloadAdjustment = {
			customerId: id,
			amount: parseIDR(payload.amount),
			type: payload.type.valueStr,
			sourceType: "ADJUSTMENT",
			status: "PENDING",
			notes: payload.notes.length ? payload.notes : null,
			accountType: payload.accountType.valueStr
		};

		mutate(payloadMutate);
	};

	if (loadingAdd) {
		return <Loading />;
	}

	return (
		<>
			{/* Histori Pelanggan */}
			<Card style={{ marginTop: "10px" }}>
				<CardBody>
					<CardTitle
						tag="h5"
						style={{
							margin: 0,
							display: "flex",
							justifyContent: "space-between"
						}}
					>
						Riwayat Pembayaran Pelanggan
						{/*  */}
						<div
							style={{
								display: "flex",
								alignContent: "center",
								alignItems: "center",
								gap: "1rem"
							}}
						>
							{/* <BForm
								forms={[
									{
										label: "",
										type: "dropdown",
										placeholder: "",
										value: typeTrx,
										onChange: (e: any) => {
											setTypeTrx(e);
										},
										options: [
											{
												valueStr: "IN",
												labelStr: "Pembayaran"
											},
											{
												valueStr: "OUT",
												labelStr: "Pemakaian"
											}
										],
										isDropdownNotClearable: true
									}
								]}
							/> */}
							{customerPermission && customerPermission.update ? (
								<Button
									onClick={() => {
										localDialog.onOpen();
									}}
									size="sm"
									color="success"
									className="confirm-button approve-button"
								>
									Buat Penyesuaian
								</Button>
							) : null}
						</div>
					</CardTitle>
					<div className="break-line"></div>

					<MTable
						columns={historiColumns}
						tableData={tableData}
						tableTitle=""
						rowsPerPage={rowsPerPage}
						rowsPerPageOptions={[5, 10, 15, 100]}
						setPage={(page) => {
							setPage(page);
						}}
						setRowsPerPage={(rows) => {
							setRowsPerPage(rows);
						}}
						totalItems={totalItems}
						options={{
							selectableRows: undefined
						}}
						isSearchVisible={false}
						currentPage={currentPage}
					/>
				</CardBody>
			</Card>

			<LocalDialog
				isOpen={localDialog.isOpen}
				backdrop={"static"}
				header={"Buat Penyesuaian Baru"}
				onClick={onSubmit}
				onClose={() => {
					localDialog.onClose();
				}}
			>
				<BForm forms={forms} />
			</LocalDialog>
		</>
	);
}
