import { LocalDialog } from "components";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { useListSearchParams, useLocalDialog } from "hooks";
import React, { useMemo, useState } from "react";
import { Dispatch, SetStateAction, useEffect } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import Select from "react-select";
import { useQuery } from "react-query";
import {
	AccurateService,
	HubService,
	InventoryService,
	OrderService,
	ProductivityService
} from "api/axios";
import { LoadingButton } from "@mui/lab";
import { openAlert } from "store/features/alert/AlertSlice";
import { useAppDispatch } from "store/hooks";
import { FileEarmarkRichtext, Truck } from "react-bootstrap-icons";
import ReactSelect, { OptionProps, components } from "react-select";
import styles from "./modalApproveTm.module.scss";
import * as Yup from "yup";
import { useDebounce } from "use-debounce";

const approvalSchema = Yup.object().shape({
	customerAccurateId: Yup.string().required("Nama Pelanggan harus dipilih *"),
	paymentTermName: Yup.string().required("Syarat Pembayaran harus dipilih *"),
	// poNumber: Yup.string().required("No. PO harus diisi *"),
	shippingAddress: Yup.string().required("Alamat harus diisi *"),
	branchId: Yup.string().required("Cabang harus dipilih *"),
	date: Yup.string().required("Tanggal Pengiriman harus dipilih *"),
	mainPic: Yup.string().required("PIC Pengiriman harus diisi *"),
	phoneMainPic: Yup.string().required("Telepon PIC harus diisi *"),
	approvalSKU: Yup.string().required("Persetujuan SKU harus dipilih *"),
	approvalCharge: Yup.string().required(
		"Persetujuan Biaya Mobilisasi harus dipilih *"
	),
	deliveryMethod: Yup.string().required("Jenis Pengiriman harus dipilih *"),
	taxMethod: Yup.string().required("PKP/Non PKP harus dipilih *"),
	volumePO: Yup.number().required("Volume PO harus diisi *")
});

export function ModalApproveTrialMix({
	isOpen,
	setIsOpen,
	refetch,
	trialMixId,
	trialMixProducts,
	customerAccurateName,
	customerAccurateId,
	mainPic,
	shippingAddress,
	picPhone,
	quantity,
	defaultCustomer
}: {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	refetch: () => void;
	trialMixId: string;
	trialMixProducts: any;
	customerAccurateName: string;
	customerAccurateId: number;
	mainPic: any;
	shippingAddress: any;
	picPhone: any;
	quantity: number;
	accurateBranchId: any;
	defaultCustomer: any;
}) {
	const { localDialog, setLocalDialog } = useLocalDialog();
	const [nameSku, setNameSku] = useState<string>("");
	const searchQSku = useDebounce(nameSku, 500);
	const { page, size } = useListSearchParams();
	const [technicianOptions, setTechnicianOptions] = useState();
	const [customerPaging, setCustomerPaging] = React.useState(1);
	const [searchCustomer, setSearchCustomer] = useState(customerAccurateName);
	const [searchCustomerDebounced] = useDebounce(searchCustomer, 500);
	const [customersList, setCustomersList] = useState<CustomerAccurate[]>([]);
	const dispatch = useAppDispatch();
	const [payloadSO, setPayloadSO] = React.useState<any>();
	const [tempSkuOrder, setTempSkuOrder] = useState();

	const labelStyle = {
		// marginBottom: "1em", // Adjust this value as needed
		marginTop: "1em" // Adjust this value as needed
	};

	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		}
	}, [isOpen]);

	// Get ALL SKU
	const { data: SKUS } = useQuery<any, ApiError>(
		["query-search-sku", searchQSku],
		async () => {
			return await InventoryService.getAllSKU({
				page,
				size: 20,
				skuName: searchQSku[0]
			});
		},
		{
			enabled: searchQSku[0]?.length ? true : false
		}
	);

	console.log(searchCustomerDebounced, "searchCustomerDebounced 109");

	// get list customer accurate
	const { data: customersAccurate } = useQuery<any, ApiError>(
		["query-customer-accurate", customerPaging, searchCustomerDebounced],
		async () => {
			return await AccurateService.getListCustomerAccurate({
				page: customerPaging,
				keywords: searchCustomerDebounced
			});
		},
		{
			onSuccess(data) {
				setCustomersList((prev) => {
					return prev.concat(data?.data);
				});
				const defaultValueCustomer = data?.data?.d?.find(
					(cust: CustomerAccurate) => {
						return cust.id === customerAccurateId;
					}
				);

				if (defaultValueCustomer) {
					setPayloadSO((prev: any) => ({
						...prev,
						customerAccurate: defaultValueCustomer
					}));
				}
			},
			onError(err: any) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
				let message = "Accurate Error/ Tolong Login Ulang";

				if (err?.response?.data?.error?.data?.error) {
					message = `Accurate Error/ ${err?.response?.data?.error?.status} - ${err?.response?.data?.error?.data?.error}`;
				}
				dispatch(
					openAlert({
						body: message,
						color: "danger"
					})
				);
			}
		}
	);

	// get list payment term
	const { data: paymentTerms } = useQuery<any, ApiError>(
		["query-payment-terms", true],
		async () => {
			return await AccurateService.getListPaymentTerms({});
		},
		{
			enabled: true
		}
	);

	const listPaymentTermns = React.useMemo(() => {
		if (paymentTerms?.data?.length) {
			const data = paymentTerms?.data.map((el: any) => {
				return {
					valueStr: el.id,
					labelStr: el.name
				};
			});
			return data;
		}
		return [];
	}, [paymentTerms]);

	// get branch
	const { data: branch } = useQuery<any, ApiError>(
		["query-branch", true],
		async () => {
			return await AccurateService.getBranch({});
		},
		{
			enabled: true
		}
	);

	const listBranch = React.useMemo(() => {
		if (branch?.data?.d?.length) {
			return branch?.data?.d?.map((el: any) => {
				return {
					valueStr: el.id,
					labelStr: el.name as string
				};
			});
		}
		return [];
	}, [branch]);

	// appproval SKU options
	const approvalOptions = [
		{ value: "Ya", label: "Ya" },
		{ value: "Tidak", label: "Tidak" }
	];

	const deliveryMethodOption = [
		{ value: "Franco", label: "Franco" },
		{ value: "Loco", label: "Loco" }
	];

	const taxMethodOption = [
		{ value: "PKP", label: "PKP" },
		{ value: "Non PKP", label: "Non PKP" }
	];

	const Option = (props: OptionProps<CustomerAccurate>) => {
		return (
			<div className={styles.selectCustom}>
				{props.data.customerNo}
				<components.Option {...props} />
				<p className={styles.selectInfo}>
					HP: <b>{props.data.mobilePhone}</b>
					WA:<b>{props.data.whatsappNo}</b>
					email: <b>{props.data.email}</b>
				</p>
			</div>
		);
	};

	const optionSKUS = useMemo(() => {
		if (SKUS?.data?.length) {
			const data = SKUS?.data.map((el: any) => {
				return {
					...el,
					valueStr: el.id,
					labelStr: el.description
						? `${el.sku} - ${el.description}`
						: el.sku
				};
			});
			return data;
		}
		return [];
	}, [SKUS]);

	const { isLoading: technicianLoading } = useQuery(
		["get-all-technician"],
		() => HubService.getAllTechnicians(),
		{
			onSuccess(response) {
				const technicians = response.data;
				const mapped = technicians.map((technician: any) => {
					return {
						value: technician.id,
						label: technician.full_name
					};
				});
				setTechnicianOptions(mapped);
			}
		}
	);

	const { data: skuOrder } = useQuery<
		ApiResponsePlain<[keyof IskuSpec]>,
		ApiError
	>(
		["query-sku-order"],
		async () => {
			return await HubService.getSkuOrder();
		},
		{
			enabled: true,
			onSuccess(data) {
				if (data) {
					setTempSkuOrder(data as any);
				}
			}
		}
	);

	function sliceSkus(skus: string[]) {
		const result: string[] = [];

		skus.forEach((sku) => {
			const slicedSku = [
				sku.slice(0, 1),
				sku.slice(1, 3),
				sku.slice(3, 4),
				sku.slice(4, 7),
				sku.slice(7, 8),
				sku.slice(8, 9),
				sku.slice(9, 11),
				sku.slice(11, 12),
				sku.slice(12, 13),
				sku.slice(13, 14),
				sku.slice(14)
			];
			result.push(slicedSku as any);
		});

		return result;
	}

	function assignValues(
		slicedSku: any[][],
		skuOrder: any,
		requestedProductsId: string[]
	): any[] {
		const result: any[] = [];

		slicedSku.forEach((sku, index) => {
			const skuObj: any = {
				requestedProductId: requestedProductsId[index],
				specifications: {}
			};

			skuOrder?.data.forEach((key: string, i: number) => {
				skuObj.specifications[key] = sku[i];
			});

			result.push(skuObj);
		});

		return result;
	}

	return (
		<LocalDialog
			isOpen={localDialog.isOpen}
			backdrop={"static"}
			header="Informasi Persetujuan Trial Mix"
			onClose={() => {
				localDialog.onClose();
				setTimeout(() => {
					setIsOpen(false);
				}, 500);
				setIsOpen(false);
			}}
		>
			<Formik
				initialValues={{
					trialMixProducts: trialMixProducts.map((product: any) => ({
						trialMixProductId: product.id,
						requestedProductId: product.RequestedProduct.id,
						skuId: {
							labelStr: "",
							valueStr: ""
						}
					})),
					assignedTechnicians: [],
					paymentTermName: "",
					poNumber: "",
					shippingAddress: shippingAddress || "",
					branchId: null,
					notes: "",
					taxable: false,
					inclusiveTax: false,
					date: "",
					approvalSKU: "",
					approvalCharge: "",
					deliveryMethod: "",
					taxMethod: "",
					mainPic: mainPic || "",
					phoneMainPic: picPhone || "",
					volumePO: quantity,
					customerAccurateId: customerAccurateId,
					branchAccurateId: null
				}}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					try {
						const payloadArrTp = values.trialMixProducts.map(
							(el: any) => {
								if (!el.skuId.valueStr) {
									throw dispatch(
										openAlert({
											body: "SKU Wajib Diisi",
											color: "danger"
										})
									);
								}
								return {
									trialMixProductId: el.trialMixProductId,
									skuId: el.skuId.valueStr,
									sku: el.skuId.labelStr.split(" ")[0],
									requestedProductId: el.requestedProductId
								};
							}
						);

						if (!values.assignedTechnicians.length) {
							throw dispatch(
								openAlert({
									body: "Pilih Minimal 1 Tekhnisi",
									color: "danger"
								})
							);
						}

						const technicianIds = values.assignedTechnicians.map(
							(el: any) => el.value
						);

						const formattedDate = values.date
							.split("-")
							.reverse()
							.join("-")
							.replace(/-/g, "/");

						console.log({
							customerNo: values.customerAccurateId,
							paymentTermName: values.paymentTermName,
							customerNumber: values.poNumber,
							branchId: values.branchId,
							transDate: formattedDate,
							taxable: values.taxable,
							inclusiveTax: values.inclusiveTax,
							mainPIC: values.mainPic,
							phonePIC: values.phoneMainPic,
							approvalSKU: values.approvalSKU,
							approvalMobilization: values.approvalCharge,
							deliveryMethod: values.deliveryMethod,
							taxMethod: values.taxMethod,
							volumePO: values.volumePO,
							description: values.notes
							// requestedProducts: skuObjects
						});
						const generateSlicedSku = values.trialMixProducts.map(
							(el: any) => {
								const skus = el.skuId?.labelStr.split(" ");
								const skuNoVersion = skus[0];

								return skuNoVersion;
							}
						);
						const skuNoVersion = generateSlicedSku.map((str: any) =>
							str.slice(0, -2)
						);

						const slicedArray = sliceSkus(skuNoVersion);
						const poProductIds = values.trialMixProducts.map(
							(el: any) => {
								return el.requestedProductId;
							}
						);

						const skuObjects: any[] = assignValues(
							slicedArray as any,
							skuOrder,
							poProductIds
						);

						const generateTrialMixProducts = payloadArrTp.map(
							(el: any) => {
								const temp = skuObjects.find((skuObj) => {
									return (
										skuObj.requestedProductId ===
										el.requestedProductId
									);
								});
								return {
									...el,
									specifications: temp.specifications
								};
							}
						);

						await ProductivityService.approveTrialMix(
							trialMixId,
							"approve",
							{
								technicianIds,
								trialMixProducts: generateTrialMixProducts,
								customerNo: values.customerAccurateId,
								paymentTermName: values.paymentTermName,
								customerNumber: values.poNumber,
								branchId: values.branchId,
								transDate: formattedDate,
								taxable: values.taxable,
								inclusiveTax: values.inclusiveTax,
								mainPIC: values.mainPic,
								phonePIC: values.phoneMainPic,
								approvalSKU: values.approvalSKU,
								approvalMobilization: values.approvalCharge,
								deliveryMethod: values.deliveryMethod,
								taxMethod: values.taxMethod,
								volumePO: values.volumePO,
								description: values.notes
							}
						);
						refetch();
						dispatch(
							openAlert({
								body: "Trial Mix Berhasil Disetujui",
								color: "success"
							})
						);
						setIsOpen(false);
					} catch (error) {
						dispatch(
							openAlert({
								body: "Trial mix Gagal Disetujui",
								color: "danger"
							})
						);
					}
				}}
				validationSchema={approvalSchema}
			>
				{({
					isSubmitting,
					values,
					setFieldValue,
					errors,
					touched,
					handleChange
				}) => {
					return (
						<Form>
							<FormGroup>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										gap: "7rem"
									}}
								>
									{/* Sub judul */}
									{/* Info Lainnya */}
									<div
										style={{
											display: "flex",
											flexDirection: "column"
										}}
									>
										<div
											style={{
												display: "flex",
												gap: "0.5rem"
											}}
										>
											<FileEarmarkRichtext
												style={{ marginTop: "4px" }}
											/>
											<h3>Info Lainnya</h3>
										</div>

										<hr
											style={{
												width: "30rem",
												marginTop: "0rem",
												marginBottom: "1rem",
												height: "0.5px",
												background: "#E1E1E1"
											}}
										/>
										{/* Approve Trial Mix Fields */}
										<FieldArray name="trialMixProducts">
											{() => (
												<div>
													{trialMixProducts?.map(
														(
															product: any,
															index: any
														) => (
															<div
																key={index}
																className=""
															>
																<Label
																	name={`trialMixProducts[${index}].RequestedProduct.displayName`}
																>
																	{product
																		?.RequestedProduct
																		?.displayName +
																		" " +
																		product
																			?.RequestedProduct
																			?.Product
																			?.Category
																			?.name}
																</Label>
																<Select
																	noOptionsMessage={() =>
																		"Ketik Nama SKU"
																	}
																	placeholder={
																		""
																	}
																	isDisabled={
																		false
																	}
																	onInputChange={(
																		text
																	) => {
																		setNameSku(
																			text
																		);
																	}}
																	options={
																		optionSKUS
																	}
																	getOptionLabel={(
																		option: options
																	) =>
																		option.labelStr as string
																	}
																	getOptionValue={(
																		option: options
																	) =>
																		option.labelStr as string
																	}
																	onChange={(
																		change
																	) => {
																		if (
																			change
																		) {
																			setFieldValue(
																				`trialMixProducts[${index}].skuId`,
																				{
																					valueStr:
																						change.id,
																					labelStr:
																						change.description
																							? `${change.sku} - ${change.description}`
																							: change.sku
																				}
																			);
																			// setFieldValue(
																			// 	`trialMixProducts[${index}].skuId`,
																			// 	{
																			// 		valueStr:
																			// 			change.id,
																			// 		labelStr:
																			// 			change.description
																			// 				? `${change.sku} - ${change.description}`
																			// 				: change.sku
																			// 	}
																			// );
																		}
																	}}
																/>
															</div>
														)
													)}
												</div>
											)}
										</FieldArray>

										<Label style={labelStyle}>
											Di Assign Ke Teknisi
										</Label>

										<Select
											isMulti
											name="technicians"
											options={technicianOptions}
											placeholder="Pilih Teknisi"
											value={values.assignedTechnicians}
											onChange={(newVal) => {
												setFieldValue(
													"assignedTechnicians",
													[...newVal]
												);
											}}
										/>

										<ErrorMessage
											name={
												"assignedTechnicians[0].valueStr"
											}
											component="div"
											className="error-msg"
										/>

										{/* Contents */}
										<div
											style={{
												display: "flex",
												flexDirection: "column"
											}}
										>
											<div
												style={{
													marginTop: "1rem"
												}}
											>
												<Label>Nama Pelanggan</Label>

												<ReactSelect
													name="customerAccurateId"
													options={customersList}
													components={{ Option }}
													getOptionLabel={(
														option: CustomerAccurate
													) => option.name}
													getOptionValue={(
														option: CustomerAccurate
													) => option.customerNo}
													onMenuScrollToBottom={() => {
														if (
															customersAccurate
																?.data?.sp
																?.pageCount >
															customerPaging
														) {
															setCustomerPaging(
																(prev) =>
																	prev + 1
															);
															setSearchCustomer(
																""
															);
														}
													}}
													onInputChange={(
														inputValue: string
													) => {
														setSearchCustomer(
															inputValue
														);
													}}
													onChange={(e: any) => {
														if (e) {
															setFieldValue(
																"customerAccurateId",
																e.customerNo
															);
														}
													}}
													isMulti={false}
													required
													defaultValue={
														defaultCustomer
													}
												/>
												{/* {JSON.stringify(errors)} */}
												{errors.customerAccurateId &&
												touched.customerAccurateId ? (
													<div
														className={
															styles.errorMessage
														}
													>
														{
															errors.customerAccurateId
														}
													</div>
												) : null}
											</div>

											<div
												style={{
													marginTop: "1rem"
												}}
											>
												<Label>Syarat Pembayaran</Label>

												<Select
													name="paymentTermName"
													placeholder="Cari/Pilih"
													options={listPaymentTermns}
													getOptionLabel={(
														option: options
													) =>
														option.labelStr as string
													}
													getOptionValue={(
														option: options
													) =>
														option.valueStr as string
													}
													onChange={(
														selected: any
													) => {
														if (selected) {
															setFieldValue(
																"paymentTermName",
																selected.labelStr
															);
														} else {
															setFieldValue(
																"paymentTermName",
																null
															);
														}
													}}
												></Select>
												{errors.paymentTermName &&
												touched.paymentTermName ? (
													<div
														className={
															styles.errorMessage
														}
													>
														{errors.paymentTermName}
													</div>
												) : null}
											</div>

											<div
												style={{
													marginTop: "1rem"
												}}
											>
												<Label>No. PO</Label>
												<Input
													placeholder="Tulis nomor PO"
													// value={values.poNumber}
													onChange={(e) =>
														setFieldValue(
															"poNumber",
															e.target.value
														)
													}
												></Input>
												{errors.poNumber &&
												touched.poNumber ? (
													<div
														className={
															styles.errorMessage
														}
													>
														{errors.poNumber}
													</div>
												) : null}
											</div>

											<div
												style={{
													marginTop: "1rem"
												}}
											>
												<Label>Alamat</Label>
												<Input
													type="textarea"
													bsSize="lg"
													value={
														values.shippingAddress
													}
													onChange={(e) =>
														setFieldValue(
															"shippingAddress",
															e.target.value
														)
													}
												></Input>
												{/* {errors.shippingAddress &&
													touched.shippingAddress ? (
														<div
															className={
																styles.errorMessage
															}
														>
															{
																errors.shippingAddress
															}
														</div>
													) : null} */}
											</div>

											<div
												style={{
													marginTop: "1rem"
												}}
											>
												<Label>Cabang</Label>
												<Select
													name="branchId"
													placeholder="Cari/Pilih Cabang"
													options={listBranch}
													defaultValue={listBranch[0]}
													getOptionLabel={(
														option: options
													) =>
														option.labelStr as string
													}
													getOptionValue={(
														option: options
													) =>
														option.valueStr as string
													}
													onChange={(
														selected: any
													) => {
														if (selected) {
															setFieldValue(
																"branchId",
																selected.valueStr
															);
														} else {
															setFieldValue(
																"branchId",
																null
															);
														}
													}}
												></Select>
												{errors.branchId &&
												touched.branchId ? (
													<div
														className={
															styles.errorMessage
														}
													>
														{errors.branchId}
													</div>
												) : null}
											</div>

											<div
												style={{
													marginTop: "1rem"
												}}
											>
												<Label>Keterangan</Label>
												<Input
													name="notes"
													type="textarea"
													onChange={(e) =>
														setFieldValue(
															"notes",
															e.target.value
														)
													}
												></Input>
											</div>

											{/* Sub judul */}
											{/* Total */}
											{/* <div
											style={{
												display: "flex",
												flexDirection: "column",
												marginTop: "2rem"
											}}
										>
											<div
												style={{
													display: "flex",
													marginLeft: "15rem",
													justifyContent:
														"space-between"
												}}
											>
												<Label>Sub Total:</Label>
												<span>Rp. 1.000.000</span>
											</div>

											<div
												style={{
													display: "flex",
													marginLeft: "15rem",
													justifyContent:
														"space-between"
												}}
											>
												<Label>Diskon:</Label>
												<span>Rp. 50.000</span>
											</div>

											<div
												style={{
													display: "flex",
													marginLeft: "15rem",
													justifyContent:
														"space-between"
												}}
											>
												<Label>Total:</Label>
												<span>Rp. 950.000</span>
											</div>
										</div> */}
										</div>
									</div>

									{/* flex ke bawah */}
									<div
										style={{
											display: "flex",
											flexDirection: "column"
										}}
									>
										{/* Sub judul */}
										{/* Info Pajak */}
										<div
											style={{
												display: "flex",
												flexDirection: "column"
											}}
										>
											<div
												style={{
													display: "flex",
													flexDirection: "column"
												}}
											>
												<div
													style={{
														display: "flex",
														gap: "0.5rem"
													}}
												>
													<FileEarmarkRichtext
														style={{
															marginTop: "4px"
														}}
													/>
													<h3>Info Pajak</h3>
												</div>

												<hr
													style={{
														width: "30rem",
														marginTop: "0rem",
														marginBottom: "0rem",
														height: "0.5px",
														background: "#E1E1E1"
													}}
												/>
											</div>

											{/* Contents */}
											<div
												style={{
													display: "flex",
													flexDirection: "column"
												}}
											>
												<div
													style={{
														marginTop: "1rem"
													}}
												>
													<Label>Pajak</Label>
													<div
														style={{
															display: "flex",
															gap: "2rem",
															marginLeft: "1.3rem"
														}}
													>
														<Label check>
															<Input
																name="taxable"
																type="checkbox"
																// checked={
																// 	values.taxable
																// }
																onChange={
																	handleChange
																}
															/>
															Kena Pajak
														</Label>

														<Label check>
															<Input
																name="inclusiveTax"
																type="checkbox"
																// checked={
																// 	values.inclusiveTax
																// }
																onChange={
																	handleChange
																}
															/>
															Total Termasuk Pajak
														</Label>
													</div>
												</div>
											</div>
										</div>

										{/* Sub judul */}
										{/* Info Pengiriman */}
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												marginTop: "1rem"
											}}
										>
											<div
												style={{
													display: "flex",
													gap: "0.5rem"
												}}
											>
												<Truck
													style={{
														marginTop: "4px"
													}}
												/>
												<h3>Info Pengiriman</h3>
											</div>

											<hr
												style={{
													width: "30rem",
													marginTop: "0rem",
													marginBottom: "0rem",
													height: "0.5px",
													background: "#E1E1E1"
												}}
											/>

											{/* Contents */}
											<div
												style={{
													display: "flex",
													flexDirection: "column"
												}}
											>
												<div
													style={{
														marginTop: "1rem"
													}}
												>
													<Label>
														Tanggal Pengiriman
													</Label>
													<Input
														name="date"
														type="date"
														// value={values.date}
														onChange={handleChange}
														min={
															new Date()
																.toISOString()
																.split("T")[0]
														}
													/>
													{errors.date &&
													touched.date ? (
														<div
															className={
																styles.errorMessage
															}
														>
															{errors.date}
														</div>
													) : null}
												</div>
											</div>
										</div>

										{/* Sub judul */}
										{/* Info tambahan */}
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												marginTop: "1rem"
											}}
										>
											<div
												style={{
													display: "flex",
													gap: "0.5rem"
												}}
											>
												<FileEarmarkRichtext
													style={{
														marginTop: "4px"
													}}
												/>
												<h3>Info Tambahan</h3>
											</div>

											<hr
												style={{
													width: "30rem",
													marginTop: "0rem",
													marginBottom: "0rem",
													height: "0.5px",
													background: "#E1E1E1"
												}}
											/>

											{/* Contents */}
											<div
												style={{
													display: "flex",
													flexDirection: "column"
												}}
											>
												<div
													style={{
														marginTop: "1rem"
													}}
												>
													<Label>
														PIC Pengiriman *
													</Label>
													<Input
														type="text"
														value={values.mainPic}
														onChange={(e) =>
															setFieldValue(
																"mainPic",
																e.target.value
															)
														}
													/>

													{errors.mainPic &&
													touched.mainPic ? (
														<div
															className={
																styles.errorMessage
															}
														>
															{
																errors.mainPic as any
															}
														</div>
													) : null}
												</div>

												<div
													style={{
														marginTop: "1rem"
													}}
												>
													<Label>
														Telepon PIC Pengiriman *
													</Label>
													<Input
														type="text"
														value={
															values.phoneMainPic
														}
														onChange={(e) =>
															setFieldValue(
																"phoneMainPic",
																e.target.value
															)
														}
													/>
													{errors.phoneMainPic &&
													touched.phoneMainPic ? (
														<div
															className={
																styles.errorMessage
															}
														>
															{
																errors.phoneMainPic as any
															}
														</div>
													) : null}
												</div>

												<div
													style={{
														marginTop: "1rem"
													}}
												>
													<Label>
														Perlu Approval SKU *
													</Label>
													<Select
														placeholder="Cari/Pilih"
														options={
															approvalOptions
														}
														value={approvalOptions.find(
															(option) =>
																option.value ===
																values.approvalSKU
														)}
														onChange={(
															option: any
														) =>
															setFieldValue(
																"approvalSKU",
																option.value
															)
														}
													></Select>
													{errors.approvalSKU &&
													touched.approvalSKU ? (
														<div
															className={
																styles.errorMessage
															}
														>
															{errors.approvalSKU}
														</div>
													) : null}
												</div>

												<div
													style={{
														marginTop: "1rem"
													}}
												>
													<Label>
														Perlu Approval Biaya
														Mobilisasi *
													</Label>
													<Select
														placeholder="Cari/Pilih"
														options={
															approvalOptions
														}
														value={approvalOptions.find(
															(option) =>
																option.value ===
																values.approvalCharge
														)}
														onChange={(
															option: any
														) =>
															setFieldValue(
																"approvalCharge",
																option.value
															)
														}
													></Select>
													{errors.approvalCharge &&
													touched.approvalCharge ? (
														<div
															className={
																styles.errorMessage
															}
														>
															{
																errors.approvalCharge
															}
														</div>
													) : null}
												</div>

												<div
													style={{
														marginTop: "1rem"
													}}
												>
													<Label>
														Jenis Pengiriman *
													</Label>
													<Select
														placeholder="Cari/Pilih"
														// defaultValue={
														// 	deliveryMethodOption[0]
														// }
														options={
															deliveryMethodOption
														}
														value={deliveryMethodOption.find(
															(option) =>
																option.value ===
																values.deliveryMethod
														)}
														onChange={(
															option: any
														) =>
															setFieldValue(
																"deliveryMethod",
																option.value
															)
														}
													></Select>
													{errors.deliveryMethod &&
													touched.deliveryMethod ? (
														<div
															className={
																styles.errorMessage
															}
														>
															{
																errors.deliveryMethod
															}
														</div>
													) : null}
												</div>

												<div
													style={{
														marginTop: "1rem"
													}}
												>
													<Label>PKP/Non PKP</Label>
													<Select
														placeholder="Cari/Pilih"
														options={
															taxMethodOption
														}
														value={taxMethodOption.find(
															(option) =>
																option.value ===
																values.taxMethod
														)}
														onChange={(
															option: any
														) =>
															setFieldValue(
																"taxMethod",
																option.value
															)
														}
													></Select>
													{errors.taxMethod &&
													touched.taxMethod ? (
														<div
															className={
																styles.errorMessage
															}
														>
															{errors.taxMethod}
														</div>
													) : null}
												</div>

												<div
													style={{
														marginTop: "1rem"
													}}
												>
													<Label>Volume PO</Label>
													<Input
														type="number"
														value={values.volumePO}
														onChange={(e) =>
															setFieldValue(
																"volumePO",
																e.target.value
															)
														}
													></Input>
													{errors.volumePO &&
													touched.volumePO ? (
														<div
															className={
																styles.errorMessage
															}
														>
															{errors.volumePO}
														</div>
													) : null}
												</div>
											</div>
										</div>
									</div>
								</div>
							</FormGroup>
							<div className="modal-custom-footer">
								<LoadingButton
									color="error"
									onClick={() => {
										localDialog.onClose();
										setTimeout(() => {
											setIsOpen(false);
										}, 500);
									}}
									loading={isSubmitting}
									disabled={isSubmitting}
								>
									<span
										style={{
											textTransform: "capitalize"
										}}
									>
										Batal
									</span>
								</LoadingButton>
								<LoadingButton
									color="success"
									variant="contained"
									type="submit"
									loading={isSubmitting}
									disabled={isSubmitting}
									sx={{
										color: "#fff",
										backgroundColor: "#2dce89",
										borderColor: "#2dce89",
										boxShadow:
											"0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)"
									}}
								>
									<span
										style={{
											textTransform: "capitalize"
										}}
									>
										Approve
									</span>
								</LoadingButton>
							</div>
						</Form>
					);
				}}
			</Formik>
		</LocalDialog>
	);
}
