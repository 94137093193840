import { getPriceFormatted } from "helper/product";
import moment from "moment";
import "moment/locale/id";
import React from "react";
import {
	Download,
	Check,
	X,
	BoxArrowUpRight,
	GeoAlt,
	Key
} from "react-bootstrap-icons";
import { NavLink as NavLinkRRD, useLocation } from "react-router-dom";
import {
	Badge,
	Button,
	Card,
	CardBody,
	CardFooter,
	CardSubtitle,
	CardTitle,
	Dropdown,
	DropdownMenu,
	DropdownToggle,
	Label,
	NavLink,
	Pagination,
	PaginationItem,
	PaginationLink,
	Table,
	UncontrolledDropdown
} from "reactstrap";
import useListSearchParams from "../../hooks/useQuery";
import BAddressCard from "./addressCard";
import BCustomerType from "./customerType";
import BPIC from "./pic";
import BStatus from "./status";
import BCompetitor from "./competitor";
import BForm from "./form";
import StatusChip from "./StatusChip";
import BMapPreview from "./MapPreview";
import { Icon } from "@iconify/react";
import { formatDate } from "helper";

interface IProps {
	data?: any[] | any;
	title?: string;
	className?: string;
	isEdit?: boolean;
	type?: string;
	date?: any;
	titleActions?: any;
}

const BCard = (props: IProps) => {
	const { data, title, className, isEdit, date, type, titleActions } = props;

	if (type === "projectInvoice") {
		return (
			<Card className={className}>
				<CardBody>
					<CardTitle
						tag="h5"
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between"
						}}
					>
						<span>{title}</span>
						<span>Customer: {data?.customer}</span>
					</CardTitle>
				</CardBody>
			</Card>
		);
	}
	if (type === "doInvoice") {
		return (
			<Card className={className}>
				<CardBody>
					<CardTitle
						tag="h5"
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between"
						}}
					>
						<span>{title}</span>
						<span>
							Tanggal:{" "}
							{moment(date).locale("id").format("DD/MM/YYYY")}
						</span>
					</CardTitle>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between"
						}}
					>
						<span>Produk: {data?.product}</span>
						<span>Jumlah: {data?.qty}</span>
					</div>
				</CardBody>
			</Card>
		);
	}

	return (
		<Card className={className}>
			<CardBody>
				{!!title && (
					<>
						<CardTitle
							style={{
								margin: 0,
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between"
							}}
						>
							{/* {title} */}
							<h5 style={{ margin: 0 }}>{title}</h5>
							{titleActions && (
								<div className="card-title-actions">
									{titleActions}
								</div>
							)}
						</CardTitle>
						<div className="break-line" />
					</>
				)}
				{(data as any[])?.map((v, idx) => {
					if (!v) {
						return null;
					}
					// else if (v?.type === "button") {
					// 	return null;
					// }
					else if (v?.type === "noCustomer") {
						return (
							<Card key={idx}>
								<div style={{ padding: "1em" }}>
									<h5>
										Proyek belum dihubungkan ke Pelanggan,
										mohon sambungkan ke Pelanggan terlebih
										dahulu
									</h5>
								</div>
							</Card>
						);
					} else if (v?.type === "summaryTest") {
						return (
							<div
								style={{
									display: "inline-block",
									paddingRight: "1rem",
									marginTop: "10px",
									width: "50%"
								}}
								key={idx}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										gap: "0.5rem"
									}}
								>
									<div>{v?.name}</div>
									<div
										style={{
											backgroundColor: "#F2F2F2",
											// width: "180px",
											display: "flex",
											flexDirection: "column",
											borderRadius: "5px"
										}}
									>
										<p
											style={{
												textAlign: "center",
												marginTop: "10px",
												fontSize: "26px"
											}}
										>
											<strong>{v?.data}</strong>
										</p>
									</div>
								</div>
							</div>
						);
					} else if (v?.type === "summaryQuan") {
						return (
							<div
								style={{
									display: "inline-block",
									paddingRight: "1rem",
									marginTop: "10px",
									width: "100%"
									// minWidth: "66%"
								}}
								key={idx}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										gap: "0.5rem"
									}}
								>
									<div>{v?.name}</div>
									<div
										style={{
											backgroundColor: "#F2F2F2",
											// width: "375px",
											display: "flex",
											flexDirection: "column",
											borderRadius: "5px"
										}}
									>
										<p
											style={{
												textAlign: "center",
												marginTop: "10px",
												fontSize: "26px"
											}}
										>
											<strong>{v?.data}</strong>
										</p>
									</div>
								</div>
							</div>
						);
					} else if (v?.type === "summaryPrice") {
						const isNegative = v?.data.includes("-");
						return (
							<div
								key={idx}
								style={{
									display: "inline-block",
									paddingRight: "1rem",
									marginTop: "10px",
									minWidth: "99%"
								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										gap: "0.5rem"
									}}
								>
									<div>{v?.name}</div>
									<div
										style={{
											backgroundColor: "#F2F2F2",
											// width: "575px",
											display: "flex",
											flexDirection: "column",
											borderRadius: "5px"
										}}
									>
										<p
											style={{
												textAlign: "center",
												marginTop: "10px",
												fontSize: "26px",
												color: isNegative
													? "red"
													: "black"
											}}
										>
											<strong>{v?.data}</strong>
										</p>
									</div>
								</div>
							</div>
						);
					} else if (v?.type === "summaryCredit") {
						const isNegative = v?.data.includes("-");
						return (
							<div
								key={idx}
								style={{
									display: "inline-block",
									paddingRight: "1rem",
									marginTop: "10px",
									minWidth: "99%"
								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										gap: "0.5rem"
									}}
								>
									<div>{v?.name}</div>
									<div
										style={{
											backgroundColor: "#F2F2F2",
											// width: "575px",
											display: "flex",
											flexDirection: "column",
											borderRadius: "5px"
										}}
									>
										<p
											style={{
												textAlign: "center",
												marginTop: "10px",
												fontSize: "26px",
												color: isNegative
													? "red"
													: "black"
											}}
										>
											<strong>{v?.data}</strong>

											<hr
												style={{
													width: "80%",
													marginTop: "0rem",
													marginBottom: "0rem",
													height: "1px",
													background: "#E1E1E1"
												}}
											/>
											<div
												style={{
													display: "flex",
													justifyContent: "center",
													gap: "1rem"
												}}
											>
												<p
													style={{
														color: "rgba(0, 0, 0, 0.25)",
														fontSize: "26px",
														marginBottom: "0px"
													}}
												>
													Limit
												</p>
												<p
													style={{
														color: "rgba(0, 0, 0, 0.25)",
														fontSize: "26px",
														marginBottom: "0px"
													}}
												>
													{v?.data2}
												</p>
											</div>
										</p>
									</div>
								</div>
							</div>
						);
					} else if (v?.type === "button") {
						return (
							<div className="form-text" key={`button-${idx}`}>
								<p className="display-text">{v?.name}</p>
								<Button
									color={v?.data?.color ?? "primary"}
									size="sm"
									onClick={v?.data?.onClick ?? undefined}
								>
									<div className="inner-btn">
										<p>{v?.data?.text}</p>
										{v?.data?.iconRight && (
											<div className="ml-2">
												{v?.data?.iconRight}
											</div>
										)}
									</div>
								</Button>
							</div>
						);
					} else if (v?.type === "card") {
						return (
							<div key={`text-${idx}`}>
								<div className="form-text">
									<p className="display-text">{v?.name}</p>
								</div>
								<BCard
									key={`card-${idx}`}
									title={v?.title}
									data={v?.data}
									isEdit={isEdit}
								/>
							</div>
						);
					} else if (v?.type === "chips") {
						return (
							<div key={`text-${idx}`}>
								<div className="form-text" key={`text-${idx}`}>
									<p className="display-text">{v?.name}</p>
									<div className="d-flex">
										{v?.data?.map((v: any, idx: number) => {
											return (
												<Badge
													key={`chip-${idx}`}
													className="ml-1"
													style={{
														color:
															v?.textColor ??
															"white"
													}}
													color={
														v?.color ?? undefined
													}
												>
													{v?.text}
												</Badge>
											);
										})}
									</div>
								</div>
							</div>
						);
					} else if (v?.type === "customerType") {
						return (
							<div
								className="form-text"
								key={`customerType-${idx}`}
							>
								<p className="display-text">{v?.name}</p>
								<BCustomerType isCompany={v?.data} />
							</div>
						);
					} else if (v?.type === "customerTypeChip") {
						return (
							<div
								className="form-text"
								key={`customerType-${idx}`}
							>
								<p className="display-text">{v?.name}</p>
								<StatusChip value={v?.data} />
							</div>
						);
					} else if (v?.type === "pic") {
						return (
							<div key={`text-${idx}`}>
								<div className="form-text" key={`text-${idx}`}>
									<p className="display-text">{v?.name}</p>
								</div>
								<BPIC
									data={v?.data}
									key={`pic-${idx}`}
									isEdit={isEdit}
								/>
							</div>
						);
					} else if (v?.type === "pics") {
						const filteredPics = v?.data?.pics?.filter(
							(pics: any) => pics.id !== v?.data?.mainPIC.id
						);
						return (
							<div key={`text-${idx}`}>
								<div className="form-text" key={`text-${idx}`}>
									<p className="display-text">{v?.name}</p>
								</div>
								{filteredPics.map((pics: any, idx: number) => {
									return (
										<BPIC
											data={pics}
											key={`pics-${idx} `}
											isEdit={isEdit}
										/>
									);
								})}
								{/* {v?.data
									?.slice(1)
									.map((p: any, idx: number) => {
										return (
											<BPIC
												data={p}
												key={`pics-${idx} `}
												isEdit={isEdit}
											/>
										);
									})} */}
							</div>
						);
					} else if (v?.type === "address") {
						return (
							<div key={`text-${idx}`}>
								<div className="form-text" key={`text-${idx}`}>
									<p className="display-text">{v?.name}</p>
								</div>
								<BAddressCard
									data={v?.data}
									key={`address-${idx}`}
									isEdit={isEdit}
								/>
							</div>
						);
					} else if (v?.type === "competitors") {
						return (
							<div key={`text-${idx}`}>
								<div className="form-text" key={`text-${idx}`}>
									<p className="display-text">{v?.name}</p>
								</div>
								{v?.data?.map((p: any, idx: number) => {
									return (
										<BCompetitor
											data={p}
											key={`competitor-${idx}`}
										/>
									);
								})}
							</div>
						);
					} else if (v?.type === "photos") {
						return (
							<div
								className="d-flex"
								style={{ flexWrap: "wrap" }}
								key={`photos-${idx}`}
							>
								{v?.data?.map((p: any, idx: number) => {
									return (
										<div
											className="mr-2 mb-2"
											style={{
												width: "100px",
												height: "100px",
												borderRadius: "0.5rem",
												border: "1px solid #0000000d"
											}}
											key={`photos-i-${idx}`}
										></div>
									);
								})}
							</div>
						);
					} else if (v?.type === "video") {
						return (
							<div
								className="form-text"
								style={{ alignItems: "flex-start" }}
								key={`text-${idx}`}
							>
								<div className="left-text">
									<p className="display-text">{v?.name}</p>
									{v?.data?.File?.createdAt && (
										<p
											className="display-text"
											style={{
												fontSize: "0.9em",
												fontWeight: 500
											}}
										>
											{formatDate(
												v?.data?.File?.createdAt
											)}
										</p>
									)}
								</div>
								{v?.data ? (
									<div
										className="card-photo"
										style={{
											position: "relative",
											maxHeight: "200px",
											overflow: "hidden"
										}}
									>
										{isEdit && v?.form ? (
											<>
												<video src={v?.File?.url} />
												<div
													className="overlay"
													style={{
														// minHeight: "1rem",
														height: "50px"
														// backgroundColor: "transparent"
														// position: "relative",
													}}
												>
													<BForm forms={[v?.form]} />
												</div>
											</>
										) : (
											<>
												{v.data?.File?.type ===
												"pdf" ? (
													<embed
														src={v?.data?.File?.url}
														type="application/pdf"
														width="100%"
														height="200px"
													></embed>
												) : (
													<>
														<video
															src={
																v?.data?.File
																	?.url
															}
														/>
													</>
												)}
												<div
													className="overlay"
													onClick={(e) => {
														e.preventDefault();
														window.open(
															v?.data?.File?.url,
															"_blank"
														);
													}}
												>
													<BoxArrowUpRight color="white" />
												</div>
											</>
										)}
									</div>
								) : isEdit && v?.form ? (
									<div
										className={
											v?.form?.preview ? "card-photo" : ""
										}
									>
										{v?.form?.preview ? (
											<>
												<img src={v?.form?.preview} />
												<div
													className="overlay"
													style={{
														minHeight: "5rem"
													}}
												>
													<BForm forms={[v?.form]} />
												</div>
											</>
										) : (
											<div
												className=""
												style={{
													minHeight: "5rem"
												}}
											>
												<BForm forms={[v?.form]} />
											</div>
										)}
									</div>
								) : (
									<p
										className="display-data error"
										style={{ color: "red" }}
									>
										N/A
									</p>
								)}
							</div>
						);
					} else if (v?.type === "photo") {
						return (
							<div
								className="form-text"
								style={{ alignItems: "flex-start" }}
								key={`text-${idx}`}
							>
								<div className="left-text">
									<p className="display-text">{v?.name}</p>
									{v?.data?.File?.createdAt && (
										<p
											className="display-text"
											style={{
												fontSize: "0.9em",
												fontWeight: 500
											}}
										>
											{formatDate(
												v?.data?.File?.createdAt
											)}
										</p>
									)}
								</div>
								{v?.data ? (
									<div
										className="card-photo"
										style={{ position: "relative" }}
									>
										{isEdit && v?.form ? (
											<>
												<img
													src={
														v?.form?.preview ||
														v?.data?.File?.url
													}
												/>
												<div
													className="overlay"
													style={
														{
															// minHeight: "20rem",
															// backgroundColor: "transparent"
															// position: "relative",
														}
													}
												>
													<BForm forms={[v?.form]} />
												</div>
											</>
										) : (
											<>
												{v.data?.File?.type ===
												"pdf" ? (
													<embed
														src={v?.data?.File?.url}
														type="application/pdf"
														width="100%"
														height="200px"
													></embed>
												) : (
													<img
														src={v?.data?.File?.url}
													/>
												)}

												<div
													className="overlay"
													onClick={(e) => {
														e.preventDefault();
														window.open(
															v?.data?.File?.url,
															"_blank"
														);
													}}
												>
													<BoxArrowUpRight color="white" />
												</div>
											</>
										)}
									</div>
								) : isEdit && v?.form ? (
									<div
										className={
											v?.form?.preview ? "card-photo" : ""
										}
									>
										{v?.form?.preview ? (
											<>
												<img src={v?.form?.preview} />
												<div
													className="overlay"
													style={{
														minHeight: "5rem"
													}}
												>
													<BForm forms={[v?.form]} />
												</div>
											</>
										) : (
											<div
												className=""
												style={{
													minHeight: "5rem"
												}}
											>
												<BForm forms={[v?.form]} />
											</div>
										)}
									</div>
								) : (
									<p
										className="display-data error"
										style={{ color: "red" }}
									>
										N/A
									</p>
								)}
							</div>
						);
					} else if (v?.type === "line") {
						return (
							<div className="break-line" key={`line-${idx}`} />
						);
					} else if (v?.type === "file") {
						return (
							<div
								className="file-item"
								key={`file-${idx}`}
								onClick={(e) => {
									e.preventDefault();
									window.open(v?.data?.url, "_blank");
								}}
							>
								<p className="file-text">
									{v?.data?.name
										.replace(" - letter", "")
										.replace(" - pos", "")
										.replace(" - unsigned", "")}
									<BStatus className="ml-2">
										{v?.data?.type}
									</BStatus>
									<BStatus className="ml-2">
										{v?.name}
									</BStatus>
									<BStatus className="ml-2">
										{new Date(
											v?.data.createdAt
										).toLocaleDateString("id-ID", {
											day: "2-digit",
											month: "long",
											year: "numeric"
										})}
									</BStatus>
								</p>
								<Download />
							</div>
						);
					} else if (v?.type === "newFile") {
						return (
							<div
								className="file-item"
								key={`file-${idx}`}
								onClick={(e) => {
									e.preventDefault();
									window.open(v?.data?.url, "_blank");
								}}
							>
								<p className="file-text">
									{v?.data?.name}
									<BStatus className="ml-2">
										{v?.data?.type}
									</BStatus>
									<BStatus className="ml-2">
										{v?.name}
									</BStatus>
								</p>
								<Download />
							</div>
						);
					} else if (v?.type === "price") {
						return (
							<div className="form-text" key={`price-${idx}`}>
								<p
									className={`display-text ${
										v?.format == "bold"
											? "font-weight-bold"
											: ""
									}`}
								>
									{v?.name}
								</p>
								{v?.data ? (
									<p
										className={`display-data ${
											v?.format == "bold"
												? "font-weight-bold"
												: ""
										}`}
									>
										{getPriceFormatted(v?.data, "Rp ")}
									</p>
								) : (
									"-"
								)}
							</div>
						);
					} else if (v?.type === "textBold") {
						return (
							<div className="form-text" key={`text-${idx}`}>
								<p
									className={`display-text ${
										v?.format == "bold"
											? "font-weight-bold"
											: ""
									}`}
								>
									{v?.name}
								</p>
								<p
									className={`display-data ${
										v?.format == "bold"
											? "font-weight-bold"
											: ""
									}`}
								>
									{v?.data}
								</p>
							</div>
						);
					} else if (v?.type === "date") {
						return (
							<div className="form-text" key={`date-${idx}`}>
								<p
									className={`display-text ${
										v?.format == "bold"
											? "font-weight-bold"
											: ""
									}`}
								>
									{v?.name}
								</p>
								<p
									className={`display-data ${
										v?.format == "bold"
											? "font-weight-bold"
											: ""
									}`}
								>
									{v?.data
										? moment(v?.data)
												.locale("id")
												.format("DD/MM/YYYY")
										: "N/A"}
								</p>
							</div>
						);
					} else if (v?.type === "datetime") {
						return (
							<div className="form-text" key={`datetime-${idx}`}>
								<p
									className={`display-text ${
										v?.format == "bold"
											? "font-weight-bold"
											: ""
									}`}
								>
									{v?.name}
								</p>
								<p
									className={`display-data ${
										v?.format == "bold"
											? "font-weight-bold"
											: ""
									}`}
								>
									{v?.data
										? moment(v?.data)
												.locale("id")
												.format("DD/MM/YYYY - HH:mm")
										: "N/A"}
								</p>
							</div>
						);
					} else if (v?.type === "boolean") {
						return (
							<div className="form-text" key={`boolean-${idx}`}>
								<p
									className={`display-text ${
										v?.format == "bold"
											? "font-weight-bold"
											: ""
									}`}
								>
									{v?.name}
								</p>
								<p
									className={`display-data ${
										v?.format == "bold"
											? "font-weight-bold"
											: ""
									}`}
								>
									{v?.data ? (
										<Check size={24} color="#2dce89" />
									) : (
										<X size={24} color="#f5365c" />
									)}
								</p>
							</div>
						);
					} else if (v?.type === "money") {
						return (
							<div className="form-text" key={`text-${idx}`}>
								<p className="display-text">{v?.name}</p>
								{isEdit && v?.form ? (
									<p className="display-data">
										<BForm forms={[v?.form]} />
									</p>
								) : (
									<p className="display-data">
										{v?.data ? "Rp." + v.data : "-"}
									</p>
								)}
							</div>
						);
					} else if (v?.type === "route") {
						return (
							<div
								className="form-text route"
								key={`text-${idx}`}
							>
								<div className="left-text">
									<div className="left-text">
										<p
											className="display-text"
											style={{
												display: "flex",
												alignItems: "center"
											}}
										>
											{v?.data?.BatchingPlant?.code}
											{v?.data?.isSurveyed && (
												<Icon
													className="ml-1"
													icon="fluent:checkmark-starburst-16-filled"
													color="green"
													fontSize={16}
												/>
											)}
										</p>
									</div>
									<p className="display-text-time">
										{new Date(
											v?.data?.route?.duration * 1000
										)
											.toISOString()
											.slice(11, 16)}{" "}
										|{" "}
										{(
											v?.data?.route?.distance / 1000
										).toFixed(2)}{" "}
										km
									</p>
									<div
										className="route-gmap-btn d-flex align-items-center"
										onClick={(e) => {
											e.preventDefault();
											window.open(
												v?.data?.gmapUrl,
												"_blank"
											);
										}}
									>
										<p className="route-gmap-title">
											Buka di Google Maps
										</p>
										<BoxArrowUpRight size={16} />
									</div>
								</div>
								<BMapPreview
									className="display-text"
									data={v?.data?.route?.geometry?.coordinates}
								/>
							</div>
						);
					} else {
						return (
							<div className="form-text" key={`text-${idx}`}>
								<p className="display-text">{v?.name}</p>
								{isEdit && v?.form ? (
									<p className="display-data">
										<BForm forms={[v?.form]} />
									</p>
								) : (
									<p className="display-data">{v?.data}</p>
								)}
							</div>
						);
					}
				})}
			</CardBody>
		</Card>
	);
};

export default BCard;
