/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { useFlags } from "flagsmith/react";
import RoutesAccess from "helper/RoutesAccess";
import NotFound from "views/auth/NotFound";

import { routes } from "routes.ts";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { setPermission } from "store/features/user/UserSlice";

function mergeValues(flags) {
	let mergedValues = {};

	try {
		for (const key in flags) {
			if (
				Object.prototype.hasOwnProperty.call(flags, key) &&
				Object.prototype.hasOwnProperty.call(flags[key], "value") &&
				Object.prototype.hasOwnProperty.call(flags[key], "enabled") &&
				flags[key].enabled === true
			) {
				const parsedValue = JSON.parse(flags[key].value);
				for (const prop in parsedValue) {
					if (
						Object.prototype.hasOwnProperty.call(parsedValue, prop)
					) {
						const propValue = parsedValue[prop];
						if (
							Object.prototype.hasOwnProperty.call(
								mergedValues,
								prop
							)
						) {
							const data = { ...mergedValues[prop] };
							for (const key in propValue) {
								if (
									Object.hasOwnProperty.call(propValue, key)
								) {
									const value = propValue[key];
									if (value) {
										data[key] = value;
									}
								}
							}
							if (Object.keys(data)) {
								mergedValues[prop] = data;
							}
						} else {
							mergedValues[prop] = propValue;
						}
					}
				}
			}
		}
	} catch (error) {
		console.log(flags, "mergeValues", "error", error);
	}
	return mergedValues;
}

const Admin = (props) => {
	const { userRoles } = useAppSelector((state) => state.user);
	const dispatch = useAppDispatch();

	// const { isHideSideBar } = useAppSelector((state) => state.alert);
	const mainContent = React.useRef(null);
	const location = useLocation();
	const flags = useFlags(userRoles);
	// const mergedValues = mergeValues(flags);
	const mergedValues = React.useMemo(() => {
		return mergeValues(flags);
	}, [flags]);
	// dispatch(setPermission(mergedValues));
	// const { role_access } = flags;

	let hidePages = [];

	// if (role_access.enabled) {
	hidePages = RoutesAccess({
		flagValues: mergedValues
	});
	// }

	React.useEffect(() => {
		dispatch(setPermission(mergedValues));
	}, [mergedValues]);

	React.useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		mainContent.current.scrollTop = 0;
		window.parent.postMessage(
			{
				location: location
			},
			"*"
		);
	}, [location]);

	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop?.childrens?.length > 0) {
				return prop.childrens.map((el, key) => {
					if (!hidePages.includes(el.name)) {
						return (
							<Route
								path={(el.layout || "") + prop.layout + el.path}
								element={<el.component />}
								key={key}
							/>
						);
					}
				});
			} else {
				let path = prop.path;
				if (prop.layout) {
					path = prop.layout + path;
				}
				return (
					<Route path={path} element={<prop.component />} key={key} />
				);
			}
		});
	};

	function SidebardRoutes(routes) {
		return routes.map((prop) => {
			//
			// let condition = true;
			if (hidePages.includes(prop.name)) {
				prop.show = false;
				// condition = false;
				// return null;
			}

			if (prop?.childrens?.length) {
				prop?.childrens.forEach((child) => {
					if (hidePages.includes(child.name)) {
						// condition = false;
						child.show = false;
					}
				});
			}
			// if (prop.name === "Finance") {
			// 	console.log(condition, "condition");
			// }

			return prop;
		});
	}

	const getBrandText = (path) => {
		let name = "BRIK";
		// if (path === "/") {
		// 	name = "Dashboard";
		// }
		for (let i = 0; i < routes.length; i++) {
			if (routes[i]?.childrens?.length > 0) {
				// eslint-disable-next-line no-loop-func
				routes[i].childrens.forEach((el) => {
					if (location.pathname.includes(el.path)) {
						name = el.name;
					}
				});
			}
			if (
				location.pathname.indexOf(routes[i].layout + routes[i].path) !==
				-1
			) {
				name = routes[i].name;
			}
		}
		return name;
	};

	return (
		<>
			{/* {!isHideSideBar ? (
				<Sidebar
					{...props}
					routes={SidebardRoutes(routes)}
					logo={{
						innerLink: "/",
						imgSrc: require("../assets/img/brand/brik-logo.png"),
						imgAlt: "..."
					}}
				/>
			) : null} */}

			<div className="main-content" ref={mainContent}>
				<AdminNavbar
					{...props}
					brandText={getBrandText(location.pathname)}
				/>
				<Routes>
					{getRoutes(routes)}
					<Route path="*" element={<NotFound />} />
				</Routes>
				{/* <Container fluid>
          <AdminFooter />
        </Container> */}
			</div>
		</>
	);
};

export default Admin;
