import React, { useState, useEffect } from "react";
import { BStatus, BTable, Header, Loading, StatusChip } from "components";
import { useListSearchParams } from "hooks";
import moment from "moment";
import { useQuery } from "react-query";
import { OrderService } from "api/axios";
import {
	dateStr,
	formatDate,
	formatLinkSheet,
	getStatusTrx,
	toSheet
} from "helper";
import { Button, Container, Row, Form, FormGroup, Input } from "reactstrap";
import { closeDialog, openDialog } from "store/features/dialog/DialogSlice";
import { getPriceFormatted } from "helper/product";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import { useNavigate, useLocation } from "react-router-dom";
import BMTable from "components/B/BMTable";
import CreateSchedule from "./ScheduleCreate";
import { useDebounce } from "use-debounce";

const scheduleColumn = [
	{
		name: "Number",
		label: "No. Jadwal",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "status",
		label: "STATUS",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				return <BStatus data={value.data}>{value.status}</BStatus>;
			}
		}
	},
	{
		name: "PONumber",
		label: "NO. PO",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "SONumber",
		label: "NO. SO",
		options: {
			filter: false,
			sort: false
		}
	},
	// {
	// 	name: "DOCount",
	// 	label: "Jumlah DO",
	// 	options: {
	// 		filter: false,
	// 		sort: false
	// 	}
	// },
	{
		name: "clientName",
		label: "Pelanggan / PIC",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "projectName",
		label: "NAMA PROYEK",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "address",
		label: "KOTA",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "clientCategory",
		label: "Tipe Pelanggan",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				if (value === undefined) {
					return "-";
				} else {
					return <StatusChip value={value} />;
				}
			}
		}
	},
	{
		name: "salesName",
		label: "NAMA SALES",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "deliveryAt",
		label: "TARGET PENGIRIMAN",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "createdDate",
		label: "Tanggal Dibuat",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "updatedAt",
		label: "UPDATE TERAKHIR",
		options: {
			filter: false,
			sort: false
		}
	}
];

export default function Schedules() {
	const [tableData, setTableData] = React.useState<
		Omit<ApiSchedules, "data">
	>({
		message: "",
		success: true,
		currentPage: 1,
		totalItems: 15,
		totalPages: 1,
		dataTable: []
	});
	const { page, date, searchParams } = useListSearchParams();
	const [showCreateForm, setShowCreateForm] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const [searchScheduleNumber, setSearchScheduleNumber] = useState<string>();
	const [searchProjectName, setSearchProjectName] = useState<string>();
	const [searchCustomerPic, setSearchCustomerPic] = useState<string>();
	const [searchQScheduleNumber] = useDebounce(searchScheduleNumber, 500);
	const [searchQProjectName] = useDebounce(searchProjectName, 500);
	const [searchQCustomerPic] = useDebounce(searchCustomerPic, 500);
	const salesId = searchParams.get("sales");

	const { bp } = useAppSelector((store) => store.batching);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const scheculeNumberParam = queryParams.get("search?schedule-number");
		const projectNameParam = queryParams.get("search?project-name");
		const customerPicParam = queryParams.get("search?customer-pic");

		if (scheculeNumberParam) {
			setSearchScheduleNumber(scheculeNumberParam);
		} else {
			setSearchScheduleNumber(undefined);
		}
		if (projectNameParam) {
			setSearchProjectName(projectNameParam);
		} else {
			setSearchProjectName("");
		}
		if (customerPicParam) {
			setSearchCustomerPic(customerPicParam);
		} else {
			setSearchCustomerPic(undefined);
		}
	}, [location.search]);

	useQuery<ApiSchedules, ApiError>(
		["query-summary", date, salesId, bp?.valueStr],
		async () => {
			const sumDate = {
				...date
			};
			if (!date.endDate) {
				sumDate.startDate = moment()
					.set({ hour: 0, minute: 0, millisecond: 0 })
					.toDate();
				sumDate.endDate = moment()
					.add(1, "days")
					.set({ hour: 0, minute: 0, millisecond: 0 })
					.toDate();
			}
			return await OrderService.getAllSchedules(1, 1, {
				date: sumDate,
				salesId: salesId as string,
				batchingPlantId: bp?.valueStr
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				// res.data?.data.forEach((data) => data.wi)
				setTableData((current) => {
					return {
						...current,
						summary: res.data?.totalItems
					};
				});
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const { isLoading, refetch: RefetchAllSchedules } = useQuery<
		ApiSchedules,
		ApiError
	>(
		[
			"query-schedule",
			searchQScheduleNumber,
			searchQProjectName,
			searchQCustomerPic,
			page,
			date,
			salesId,
			bp?.valueStr
		],
		async () => {
			return await OrderService.getAllSchedules(page, 15, {
				scheduleNumber: searchQScheduleNumber,
				projectName: searchQProjectName,
				date,
				salesId: salesId as string,
				batchingPlantId: bp?.valueStr,
				customerName: searchQCustomerPic
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res.data?.data.map((el, index) => {
					let cityName = "-";
					const project = el?.Project;
					if (project?.BillingAddress) {
						if (project?.BillingAddress?.Postal?.City) {
							cityName = project?.BillingAddress.Postal?.City
								.name as string;
						}
					}
					if (project?.LocationAddress) {
						if (project?.LocationAddress?.Postal?.City) {
							cityName = project?.LocationAddress.Postal?.City
								.name as string;
						}
					}
					if (project?.ShippingAddress) {
						if (project?.ShippingAddress?.Postal?.City) {
							cityName = project?.ShippingAddress?.Postal?.City
								.name as string;
						}
					}
					return {
						id: el.id,
						values: [
							{
								objectKey: "id",
								value: index + 1,
								type: "text",
								render: false
							},
							// {
							// 	objectKey: "no",
							// 	value: (index + 1) + (15 * (page - 1)), //15 is size data, look axios service,
							// 	type: "text",
							// },
							{
								objectKey: "Number",
								value: el?.number ?? "-",
								type: "text"
							},
							{
								objectKey: "status",
								value: el.status
									? {
											status: el.status,
											data: el
									  }
									: "-",
								type: "status"
							},
							{
								objectKey: "PONumber",
								value: el?.PurchaseOrder?.customerNumber
									? el?.PurchaseOrder?.customerNumber == ""
										? "-"
										: el?.PurchaseOrder?.customerNumber
									: "-",
								type: "text"
							},
							{
								objectKey: "SONumber",
								value: el?.PurchaseOrder?.brikNumber ?? "-",
								type: "text"
							},
							// {
							// 	objectKey: "DOCount",
							// 	value:
							// 		el.deliveryOrderCount === 0
							// 			? "-"
							// 			: el.deliveryOrderCount,
							// 		(el?.deliveryOrderCount ?? 0) > 0
							// 			? `${el?.deliveryOrderCount
							// 			} (${el?.DeliveryOrders.reduce(
							// 				(accumulator, currentValue) =>
							// 					accumulator +
							// 					(currentValue?.quantity ??
							// 						0),
							// 				0
							// 			)} ${el?.SaleOrder?.Product?.unit
							// 			} / ${el?.quantity} ${el?.SaleOrder?.Product?.unit
							// 			})`
							// 			: "-",
							// 	type: "text"
							// },
							{
								objectKey: "clientName",
								value:
									el?.Project?.Customer?.displayName ??
									el?.Project?.mainPic?.name,
								type: "text"
							},
							{
								objectKey: "projectName",
								value: el?.Project?.name ?? "-",
								type: "text"
							},
							{
								objectKey: "address",
								value: cityName,
								type: "text"
							},
							{
								objectKey: "clientCategory",
								value:
									el?.Project?.Customer?.type === "COMPANY"
										? "PERUSAHAAN"
										: el?.Project?.Customer?.type,
								type: "customerType"
							},
							{
								objectKey: "salesName",
								value: el?.Project?.User?.fullName ?? "-",
								type: "text"
							},
							{
								objectKey: "deliveryAt",
								value: formatDate(el?.date),
								type: "text"
							},
							{
								objectKey: "createdDate",
								value: formatDate(el?.createdAt),
								type: "text"
							},
							{
								objectKey: "updatedAt",
								value: formatDate(el?.updatedAt),
								type: "text"
							}
						]
					};
				});

				setTableData(() => ({
					...tableData,
					...res,
					dataTable: (data as DataTable[]) ?? []
				}));
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const handleScheduleNumberSearch = (newValue: string) => {
		setSearchScheduleNumber(newValue);

		const queryParams = new URLSearchParams();
		queryParams.set("page", "1");

		if (newValue) {
			queryParams.set("search?schedule-number", newValue);
		}

		if (searchProjectName) {
			queryParams.set("search?project-name", searchProjectName);
		}

		if (searchCustomerPic) {
			queryParams.set("search?customer-pic", searchCustomerPic);
		}

		navigate({
			pathname: location.pathname,
			search: queryParams.toString()
		});
	};

	const handleProjectNameSearch = (newValue: string) => {
		setSearchProjectName(newValue);

		const queryParams = new URLSearchParams();
		queryParams.set("page", "1");

		if (searchScheduleNumber) {
			queryParams.set("search?schedule-number", searchScheduleNumber);
		}

		if (newValue) {
			queryParams.set("search?project-name", newValue);
		}

		if (searchCustomerPic) {
			queryParams.set("search?customer-pic", searchCustomerPic);
		}

		navigate({
			pathname: location.pathname,
			search: queryParams.toString()
		});
	};

	const handleCustomerPicSearch = (newValue: string) => {
		setSearchCustomerPic(newValue);

		const queryParams = new URLSearchParams();
		queryParams.set("page", "1");

		if (newValue) {
			queryParams.set("search?customer-pic", newValue);
		}

		if (searchScheduleNumber) {
			queryParams.set("search?schedule-number", searchScheduleNumber);
		}

		if (searchProjectName) {
			queryParams.set("search?project-name", searchProjectName);
		}

		navigate({
			pathname: location.pathname,
			search: queryParams.toString()
		});
	};

	const dispatch = useAppDispatch();

	const { refetch, isLoading: loadingSheet } = useQuery<ApiSchedules, Error>(
		["query-download-schedule", date, salesId, bp?.valueStr],
		async () => {
			if (!date.endDate) {
				date.endDate = date.startDate;
			}
			return await OrderService.getAllSchedules(1, 100, {
				date,
				salesId: salesId as string,
				batchingPlantId: bp?.valueStr
			});
		},
		{
			enabled: false,
			refetchOnWindowFocus: false
		}
	);
	const downloadSheet = () => {
		refetch({}).then((res) => {
			if (!res.data || (res?.data?.data?.data?.length ?? 0) < 1) {
				dispatch(
					openDialog({
						header: "Tidak ada laporan",
						body: `Tidak ada laporan di tanggal ${dateStr(
							date
						)}. Silahkan pilih tanggal lain`,
						onClick: "close"
					})
				);
				return;
			}

			if (res.data.data?.data) {
				const dataSheet = res.data.data.data.map((el, i) => {
					// const sphFile = el.QuotationLetterFiles.find(el => el.type === "LETTER");
					return {
						// No: i + 1,
						"Nama Sales": el?.Project?.User?.fullName,
						"No SO": el.PurchaseOrder.brikNumber,
						"Nama Proyek": el?.Project?.name,
						"Tahap Proyek": el?.Project?.stage,
						"Nama Pelanggan":
							el?.Project?.Customer?.displayName ??
							el?.Project?.mainPic?.name,
						"Kontak PIC": `+62${el?.Project?.mainPic?.phone}`,
						Alamat: `http://maps.google.com/maps?z=3&t=m&q=loc:${el?.Project?.ShippingAddress?.lat}+${el?.Project?.ShippingAddress?.lon}`,
						Status: getStatusTrx(el.status),
						// "SPH": formatLinkSheet(sphFile?.File.url ?? "-", sphFile?.File.name?? "-"),
						// "Total Harga": `Rp ${getPriceFormatted(el.totalPrice)}`,
						"Tanggal Terbit": formatDate(el.createdAt),
						"Tanggal Delivery": formatDate(el.date)
					};
				});

				toSheet(dataSheet, date, "Schedules");
				dispatch(closeDialog());
			}
		});
	};

	const onDownload = () => {
		dispatch(
			openDialog({
				header: "Unduh laporan",
				body: `Apakah kamu yakin ingin mengunduh laporan ${dateStr(
					date
				)}?`,
				onClick: () => {
					downloadSheet();
				}
			})
		);
	};

	// if (isLoading || loadingSheet) {
	// 	return <Loading />;
	// }

	return (
		<>
			<Header data={tableData.summary} date={date} show />
			<Container className="mt--7" fluid>
				{/* Table */}
				<Row>
					{showCreateForm ? (
						<CreateSchedule
							isOpen={showCreateForm}
							setIsOpen={setShowCreateForm}
							refetch={RefetchAllSchedules}
						/>
					) : null}
					<div className="col">
						<BMTable
							tableTitle=""
							columns={scheduleColumn}
							tableData={tableData.dataTable as DataTable[]}
							totalItems={tableData.data?.totalItems as number}
							currentPage={tableData.data?.currentPage as number}
							onDownload={onDownload}
							header={
								<>
									<Button
										style={{ float: "right" }}
										size="sm"
										color="success"
										className="confirm-button approve-button"
										onClick={() => {
											setShowCreateForm(true);
										}}
									>
										Buat Jadwal
									</Button>
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											paddingBottom: "0.5rem"
										}}
									>
										<Form>
											<FormGroup
												style={{
													display: "flex",
													gap: "1rem"
												}}
											>
												<Input
													placeholder="NO. JADWAL"
													value={searchScheduleNumber}
													onChange={(e) =>
														handleScheduleNumberSearch(
															e.target.value
														)
													}
												/>
												<Input
													placeholder="NAMA PROYEK"
													value={searchProjectName}
													onChange={(e) =>
														handleProjectNameSearch(
															e.target.value
														)
													}
												/>
												<Input
													placeholder="PELANGGAN / PIC"
													value={searchCustomerPic}
													onChange={(e) =>
														handleCustomerPicSearch(
															e.target.value
														)
													}
												/>
											</FormGroup>
										</Form>
									</div>
								</>
							}
							filterSales
							options={{
								onRowClick(rowData, rowMeta) {
									const id =
										tableData.dataTable[rowMeta.rowIndex]
											?.id;
									navigate(`/order/schedule/${id}`);
								}
							}}
						/>
						{/* <BTable
							currentPage={page}
							totalPage={tableData.data?.totalPages as number}
							totalItem={tableData.data?.totalItems as number}
							columns={[
								// {
								// 	key: "id",
								// 	value: "No",
								// },
								{
									key: "number",
									value: "No Schedule"
								},
								{
									key: "status",
									value: "Status"
								},
								{
									key: "PONumber",
									value: "No PO"
								},
								{
									key: "SONumber",
									value: "No SO"
								},
								{
									key: "DOCount",
									value: "DO Count"
								},
								{
									key: "clientName",
									value: "Nama PT / PIC"
								},
								{
									key: "projectName",
									value: "Nama Proyek"
								},
								{
									key: "address",
									value: "Kota"
								},
								{
									key: "clientCategory",
									value: "Tipe Pembayaran"
								},
								{
									key: "salesName",
									value: "Nama Sales"
								},
								{
									key: "deliveryAt",
									value: "Target Pengiriman"
								},
								{
									key: "createdDate",
									value: "Dibuat"
								},
								{
									key: "updatedDate",
									value: "Update Terakhir"
								}
							]}
							data={tableData.dataTable as DataTable[]}
							onDownload={onDownload}
							filterSales
						/> */}
					</div>
				</Row>
			</Container>
		</>
	);
}
