/* eslint-disable no-constant-condition */
import BCard from "components/B/card";
import React, { useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	AccurateService,
	CommonService,
	InventoryService,
	OrderService
} from "api/axios";
import { Loading } from "components";
import { Button } from "reactstrap";
import { BoxArrowUpRight, ChevronLeft } from "react-bootstrap-icons";
import {
	formatComma,
	formatDate,
	formatIDR,
	formatTimeOnly,
	handleResponseFile,
	parseIDR
} from "helper";
import BStatus from "components/B/status";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { openAlert, setIsHideSideBar } from "store/features/alert/AlertSlice";
import BForm from "components/B/form";
import moment from "moment";
import imageCompression from "browser-image-compression";
import { getRouteName, routeMapping } from "routes";
import { SingleValue } from "react-select";
import LinkDeliveryOrder from "./LinkToAccurate";

interface ChangeFile extends ApiFile {
	newFile?: File;
}

interface AddCost {
	mobilititation: string;
	park: string;
	toll: string;
	extra: string;
	notes: string;
}

interface PayloadDO {
	scheduleId?: {
		number: string;
		id: string;
		valueStr: string;
		labelStr: string;
	};
	date?: string;
	quantity?: number;
	sealNumber?: string;
	licenseNumber?: number;
	invoiceId?: string;
	number?: string;
	driver?: {
		id: string;
		fullName: string;
		valueStr: string;
		labelStr: string;
	};
	truck?: {
		id: string;
		plate_number: string;
		valueStr: string;
		labelStr: string;
	};
	status?: {
		valueStr: string;
		labelStr: string;
	};
	files?: ChangeFile[];
	newFile?: {
		id: string;
		fileId: string;
		type: string;
	};
	addCost?: AddCost;
	rejectedNotes?: string;
	loadingStart?: string;
	loadingEnd?: string;
	plantDeparture?: string;
	projectArrive?: string;
	pouringStart?: string;
	pouringEnd?: string;
	plantReturn?: string;
	plantArrive?: string;
	accurateStartLoadingTime?: string;
	accurateEndLoadingTime?: string;
	actualDriver?: SingleValue<
		ActualDrivers & {
			labelStr: string;
			valueStr: string;
		}
	>;
}

const DODetail = () => {
	const location = useLocation();
	const permission = useAppSelector((state) => state.user.permission);
	const deliverOrderPermission = useMemo(() => {
		const routeName = getRouteName(location.pathname, routeMapping);

		if (routeName) {
			return permission[routeName];
		}
		return {};
	}, [location, permission]);
	const navigate = useNavigate();
	const params = useParams();
	const id = params?.id;
	const [projectDetails, setProjectDetails] = React.useState<any[]>([]);
	const [files, setFiles] = React.useState<any[]>([]);
	const [updates, setUpdates] = React.useState<any[]>([]);
	const [summary, setSummary] = React.useState<any[]>([]);
	const [times, setTimes] = React.useState<any[]>([]);
	const [sphNumber, setSphNumber] = React.useState<string>("");
	const [status, setStatus] = React.useState<string>("");

	// open do state
	const [isCreateDO, setIsCreateDO] = React.useState(false);

	// accurate disable
	const { isAccurateDisabled: accurateStatusDisabled } = useAppSelector(
		(state) => state.setting
	);
	const isAccurateDoDisabled =
		process.env.REACT_APP_IS_ACCURATE_DISABLED === "true" ||
		accurateStatusDisabled;

	const dispatch = useAppDispatch();
	const { isLoading, refetch, data } = useQuery<ApiOneDO, ApiError>(
		["query-quotations"],
		async () => {
			return await OrderService.getOneDO(id ?? "");
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const isCompany = !!res?.data?.Project?.companyName;
				const isCustomer = !!res?.data?.Project?.Customer;
				setSphNumber(res.data?.number);
				setStatus(res.data?.status);
				try {
					const projectDetails = [
						(!isCompany && !isCustomer) ||
						(isCompany && !isCustomer)
							? {
									type: "noCustomer"
							  }
							: null,
						isCustomer && {
							name: "Tipe Pelanggan",
							type: "customerTypeChip",
							data: res?.data?.Project?.Customer?.type
						},
						isCompany && !isCustomer
							? {
									name: "Tipe Pelanggan",
									type: "customerType",
									data: "PERUSAHAAN"
							  }
							: null,
						isCustomer
							? {
									name: "Nama Pelanggan",
									data:
										res?.data?.Project.Customer
											?.displayName ?? "-"
							  }
							: null,
						{
							name: "Nama Proyek",
							data: res?.data?.Project?.name
						},
						{
							name: "PIC Utama",
							type: "pic",
							data: {
								name: res?.data?.Project?.mainPic?.name,
								position: res?.data?.Project?.mainPic?.position,
								phone: res?.data?.Project?.mainPic?.phone,
								email: res?.data?.Project?.mainPic?.email
							}
						},
						res?.data?.Project?.Pics.length > 1 && {
							name: "PICs",
							type: "pics",
							data: {
								pics: res?.data?.Project?.Pics,
								mainPIC: res?.data?.Project?.mainPic
							}
						},
						{
							name: "Alamat Penagihan",
							type: "address",
							data: {
								line1: res?.data?.Project?.BillingAddress
									?.line1,
								lat: res?.data?.Project?.BillingAddress?.lat,
								lon: res?.data?.Project?.BillingAddress?.lon
							}
						},
						{
							name: "Alamat Pengiriman",
							type: "address",
							data: {
								line1: res?.data?.Project?.ShippingAddress
									?.line1,
								lat: res?.data?.Project?.ShippingAddress?.lat,
								lon: res?.data?.Project?.ShippingAddress?.lon
							}
						}
					];
					setProjectDetails(projectDetails);

					// prefilled for edit
					setPayloadDO({
						accurateEndLoadingTime:
							res?.data?.accurateEndLoadingTime,
						accurateStartLoadingTime:
							res?.data?.accurateStartLoadingTime,
						loadingStart: res?.data?.loadingStart
							? formatTimeOnly(res?.data?.loadingStart)
							: undefined,
						loadingEnd: res?.data?.loadingEnd
							? formatTimeOnly(res?.data?.loadingEnd)
							: undefined,
						plantDeparture: res?.data?.plantDeparture
							? formatTimeOnly(res?.data?.plantDeparture)
							: undefined,
						projectArrive: res?.data?.projectArrive
							? formatTimeOnly(res?.data?.projectArrive)
							: undefined,
						pouringStart: res?.data?.pouringStart
							? formatTimeOnly(res?.data?.pouringStart)
							: undefined,
						pouringEnd: res?.data?.pouringEnd
							? formatTimeOnly(res?.data?.pouringEnd)
							: undefined,
						plantReturn: res?.data?.plantReturn
							? formatTimeOnly(res?.data?.plantReturn)
							: undefined,
						plantArrive: res?.data?.plantArrive
							? formatTimeOnly(res?.data?.plantArrive)
							: undefined,
						scheduleId: {
							number: res.data.Schedule.number,
							id: res.data.Schedule.id,
							valueStr: res.data.Schedule.id,
							labelStr: res.data.Schedule.number
						},
						date: moment(res.data.date).format("yyyy-MM-DD"),
						quantity: res.data.quantity,
						sealNumber: res.data.sealNumber,
						number: res.data.number,
						truck: {
							id: res.data.Vehicle.id,
							plate_number: res.data.Vehicle
								.plateNumber as string,
							valueStr: res.data.Vehicle.id,
							labelStr: res.data.Vehicle.plateNumber as string
						},
						status: {
							valueStr: res.data.status,
							labelStr: res.data.status
						},
						files: res.data.DeliveryOrderFile,
						addCost: {
							mobilititation: formatIDR(
								res.data.mobilizationFee
									? Number(res.data.mobilizationFee)
									: null
							),
							toll: formatIDR(
								res.data.toll ? Number(res.data.toll) : null
							),
							park: formatIDR(
								res.data.parking
									? Number(res.data.parking)
									: null
							),
							extra: formatIDR(
								res.data.additionalPrice
									? Number(res.data.additionalPrice)
									: null
							),
							notes: res.data.notes
						} as unknown as AddCost,
						rejectedNotes: res.data.rejectedNotes,
						actualDriver: {
							...res?.data?.Driver,
							labelStr: res?.data?.Driver?.name as string,
							valueStr: res?.data?.Driver?.id as string,
							id: res?.data?.Driver?.id as string,
							name: res?.data?.Driver?.name as string
						}
					});
				} catch (e) {
					//
				}

				try {
					const files = [];
					const filterOutTypes = [
						"WEIGHT_OUT",
						"WEIGHT_IN",
						"DO_WEIGHT_OUT",
						"DO_WEIGHT_IN",
						"DO_RETURN_SECURITY",
						"DO_RETURN_TRUCK_CONDITION_SECURITY"
					];
					for (const d of res?.data?.DeliveryOrderFile || []) {
						if (!filterOutTypes.includes(d.type)) {
							files.push({
								name: d?.type,
								type: "file",
								data: d?.File
							});
						}
					}
					setFiles(files);
				} catch (e) {
					//
				}

				try {
					const files: ApiFile[] = res?.data?.DeliveryOrderFile;
					const weights: ResponseWeight[] = res?.data?.Weight;
					const w = {
						out: weights.find((v) =>
							[
								"WEIGHT_OUT",
								"WB_OUT_DO",
								"WB_OUT_RESULT"
							].includes(v.type)
						),
						in: weights.find((v) => v.type == "WEIGHT_IN")
					};
					const _s = (res?.data?.status ?? "").trim().toUpperCase();
					const done = {
						wbDispatch: [
							"WB_OUT",
							"ON_DELIVERY",
							"RECEIVED",
							"AWAIT_WB_IN",
							"FINISHED"
						].includes(_s),
						secDispatch: [
							"ON_DELIVERY",
							"RECEIVED",
							"AWAIT_WB_IN",
							"FINISHED"
						].includes(_s),
						drvArrival: [
							"RECEIVED",
							"AWAIT_WB_IN",
							"FINISHED"
						].includes(_s),
						secReturn: ["AWAIT_WB_IN", "FINISHED"].includes(_s),
						wbReturn: ["FINISHED"].includes(_s)
					};

					const updates = [
						// {
						// 	name: `${
						// 		done.wbDispatch ? "✅ " : ""
						// 	}Weigh Bridge - Dispatch`,
						// 	type: "card",
						// 	data: [
						// 		{
						// 			name: "Foto DO",
						// 			type: "photo",
						// 			data: files.find(
						// 				(v) =>
						// 					v.type == "WB_OUT_DO" ||
						// 					v.type == "WEIGHT_OUT"
						// 			)
						// 		},
						// 		{
						// 			name: "Foto Hasil",
						// 			type: "photo",
						// 			data: files.find(
						// 				(v) =>
						// 					v.type == "WB_OUT_RESULT" ||
						// 					v.type == "WEIGHT_OUT"
						// 			)
						// 		},
						// 		{
						// 			name: "Berat",
						// 			data: w.out
						// 				? `${w.out?.weight} ${w.out?.unit}`
						// 				: "N/A"
						// 		}
						// 	]
						// },
						{
							name: `${
								done.secDispatch ? "✅ " : ""
							}Security - Dispatch`,
							type: "card",
							data: [
								{
									name: "Foto DO",
									type: "photo",
									data: files.find(
										(v) =>
											v.type == "DO_SECURITY" ||
											v.type == "DO_DEPARTURE_SECURITY"
									)
								},
								{
									name: "Foto Driver",
									type: "photo",
									data: files.find(
										(v) => v.type == "DO_DRIVER_SECURITY"
									)
								},
								{
									name: "Foto No. Polisi TM",
									type: "photo",
									data: files.find(
										(v) =>
											v.type == "DO_LICENSE_SECURITY" ||
											v.type ==
												"DO_TRUCK_CONDITION_SECURITY"
									)
								},
								{
									name: "Foto Segel",
									type: "photo",
									data: files.find(
										(v) => v.type == "DO_SEAL_SECURITY"
									)
								},
								{
									name: "Foto Kondom",
									type: "photo",
									data: files.find(
										(v) => v.type == "DO_KONDOM_SECURITY"
									)
								},
								{
									name: "Foto Odometer",
									type: "photo",
									data: files.find(
										(v) => v.type == "DO_ODOMETER_SECURITY"
									)
								},
								{
									name: "Foto Tanki Solar [Atas]",
									type: "photo",
									data: files.find(
										(v) =>
											v.type ==
											"DO_TANKI_SOLAR_ATAS_SECURITY"
									)
								},
								{
									name: "Foto Tanki Solar [Bawah]",
									type: "photo",
									data: files.find(
										(v) =>
											v.type ==
											"DO_TANKI_SOLAR_BAWAH_SECURITY"
									)
								},
								{
									name: "Foto Roda Depan Kanan",
									type: "photo",
									data: files.find(
										(v) =>
											v.type ==
											"DO_RODA_DEPAN_KANAN_SECURITY"
									)
								},
								{
									name: "Foto Roda Depan Kiri",
									type: "photo",
									data: files.find(
										(v) =>
											v.type ==
											"DO_RODA_DEPAN_KIRI_SECURITY"
									)
								},
								{
									name: "Foto Roda Belakang Kanan",
									type: "photo",
									data: files.find(
										(v) =>
											v.type ==
											"DO_RODA_BELAKANG_KANAN_SECURITY"
									)
								},
								{
									name: "Foto Roda Belakang Kiri",
									type: "photo",
									data: files.find(
										(v) =>
											v.type ==
											"DO_RODA_BELAKANG_KIRI_SECURITY"
									)
								}
							]
						},
						{
							name: `${
								done.drvArrival ? "✅ " : ""
							}Driver -  Arrival`,
							type: "card",
							data: [
								{
									name: "Foto Tiba di Lokasi",
									type: "photo",
									data: files.find(
										(v) =>
											v.type == "DO_DRIVER_ARRIVE_PROJECT"
									)
								},
								{
									name: "Foto Gentong TM Terisi Beton",
									type: "photo",
									data: files.find(
										(v) => v.type == "DO_DRIVER_BNIB"
									)
								},
								{
									name: "Foto TM Menuang Beton",
									type: "photo",
									data: files.find(
										(v) => v.type == "DO_DRIVER_UNBOXING"
									)
								},
								{
									name: "Foto Gentong TM Kosong",
									type: "photo",
									data: files.find(
										(v) => v.type == "DO_DRIVER_EMPTY"
									)
								},
								{
									name: "Foto saat DO Ditandatangani",
									type: "photo",
									data: files.find(
										(v) => v.type == "DO_DRIVER_DO"
									)
								},
								{
									name: "Foto Penerima DO di Proyek",
									type: "photo",
									data: files.find(
										(v) => v.type == "DO_DRIVER_RECEIPIENT"
									)
								},
								files.find(
									(v) => v.type == "DO_DRIVER_WATER"
								) && {
									name: "Foto Penambahan Air",
									type: "photo",
									data: files.find(
										(v) => v.type == "DO_DRIVER_WATER"
									)
								},
								files.find(
									(v) => v.type == "DO_DRIVER_FINISH_PROJECT"
								) && {
									name: "Foto Tambahan",
									type: "photo",
									data: files.find(
										(v) =>
											v.type == "DO_DRIVER_FINISH_PROJECT"
									)
								},
								{
									name: "Nama Penerima",
									data: res?.data?.recipientName
								},
								{
									name: "No. Telp Penerima",
									data: res?.data?.recipientNumber
								}
							]
						},
						{
							name: `${
								done.secReturn ? "✅ " : ""
							}Security - Return`,
							type: "card",
							data: [
								{
									name: "Foto DO",
									type: "photo",
									data: files.find(
										(v) => v.type == "DO_RETURN_SECURITY"
									)
								},
								{
									name: "Foto Kondisi TM",
									type: "photo",
									data: files.find(
										(v) =>
											v.type ==
											"DO_RETURN_TRUCK_CONDITION_SECURITY"
									)
								},
								{
									name: "Terdapat Sisa Muatan dalam TM",
									data: true,
									type: "boolean"
								},
								{
									name: "Kondisi TM",
									data: res?.data?.conditionTruck
								}
							]
						}
						// {
						// 	name: `${}`
						// }
						// {
						// 	name: `${
						// 		done.wbReturn ? "✅ " : ""
						// 	}Weigh Bridge - Return`,
						// 	type: "card",
						// 	data: [
						// 		{
						// 			name: "Foto DO",
						// 			type: "photo",
						// 			data: files.find(
						// 				(v) => v.type == "DO_WEIGHT_IN"
						// 			)
						// 		},
						// 		{
						// 			name: "Foto Hasil",
						// 			type: "photo",
						// 			data: files.find(
						// 				(v) => v.type == "WEIGHT_IN"
						// 			)
						// 		},
						// 		{
						// 			name: "Berat",
						// 			data: w.in
						// 				? `${w.in?.weight} ${w.in?.unit}`
						// 				: "N/A"
						// 		}
						// 	]
						// }
					];

					setUpdates(updates);
				} catch (e) {
					//
				}

				try {
					const summary = [
						{
							name: "Nama Sales",
							type: "text",
							data: res?.data?.Project?.User?.fullName
						},
						{
							name: "Produk",
							type: "text",
							data: res?.data?.Schedule?.SaleOrder?.Product?.name
						},
						{
							name: "Kuantitas",
							type: "text",
							data: formatComma(`${res?.data?.quantity} M³`)
						},
						{
							name: "Driver",
							type: "text",
							data: res?.data?.ActualDriver?.name
						},
						{
							name: "HP TM",
							type: "text",
							data: res?.data?.User?.fullName
						},
						{
							name: "No. TM",
							type: "text",
							data: `${
								res?.data?.Vehicle?.internalId ??
								res?.data?.Vehicle?.internal_id
							} / ${
								res?.data?.Vehicle?.plateNumber ??
								res?.data?.Vehicle?.plate_number
							}`
						},
						{
							name: "Tracking",
							type: "button",
							data: {
								text: "Buka",
								iconRight: <BoxArrowUpRight size={16} />,
								color: "primary",
								onClick: (e: any) => {
									e?.preventDefault();
									window.open(res.data.urlTracker, "_blank");
								}
							}
						}
					];
					setSummary(summary);
				} catch (e) {
					//
				}
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const patchStatus = useMutation(
		async (status: "CONFIRMED" | "DECLINED") => {
			return await OrderService.updatePO(id as string, status);
		},
		{
			onSuccess: (response) => {
				refetch();
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const { data: drivers } = useQuery(
		["query-actual-driver"],
		async () => {
			return await OrderService.getActualDrivers();
		},
		{
			onError(err: any) {
				let message = "Accurate Error/ Tolong Login Ulang";

				if (err?.response?.data?.error?.data?.error) {
					message = `Accurate Error/ ${err?.response?.data?.error?.status} - ${err?.response?.data?.error?.data?.error}`;
				}
				dispatch(
					openAlert({
						body: message,
						color: "danger"
					})
				);
			}
		}
	);

	const usedFileTypes = [
		"WB_OUT_DO",
		"WB_OUT_RESULT",
		"DO_SECURITY",
		"DO_DEPARTURE_SECURITY",
		"DO_DRIVER_SECURITY",
		"DO_ODOMETER_SECURITY",
		"DO_TANKI_SOLAR_ATAS_SECURITY",
		"DO_TANKI_SOLAR_BAWAH_SECURITY",
		"DO_RODA_DEPAN_KANAN_SECURITY",
		"DO_RODA_DEPAN_KIRI_SECURITY",
		"DO_RODA_BELAKANG_KANAN_SECURITY",
		"DO_RODA_BELAKANG_KIRI_SECURITY",
		"DO_LICENSE_SECURITY",
		"DO_TRUCK_CONDITION_SECURITY",
		"DO_SEAL_SECURITY",
		"DO_KONDOM_SECURITY",
		"DO_DRIVER_ARRIVE_PROJECT",
		"DO_DRIVER_BNIB",
		"DO_DRIVER_WATER",
		"DO_TM_LOADING",
		"DO_INITIAL_ODOMETER",
		"DO_ADDITIONAL_LOADING",
		"DO_DRIVER_ARRIVE_PROJECT",
		"DO_DRIVER_BNIB",
		"DO_ADDITIONAL_ARRIVED",
		// "VIDEO"
		"DO_ADDITIONAL_POURING",
		"DO_DRIVER_EMPTY",
		"DO_RETURN_ODOMETER",
		"DO_ADDITIONAL_RETURNED",
		"DO_DRIVER_DO",
		"DO_DRIVER_RECEIPIENT"
	];

	// edit dialog
	// create do
	// option truck
	const [optionsSchedule, setOptionSchedule] = React.useState<any[]>([]);
	useQuery<ApiSchedules, ApiError>(
		["query-summary"],
		async () => {
			return await OrderService.getAllSchedules(1, 100, {
				dropdown: true
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res.data?.data.map((el) => {
					return {
						...el,
						id: el.id,
						valueStr: el.id,
						labelStr: el.number
					};
				});
				setOptionSchedule(data as any[]);
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);
	const [optionTrucks, setOptionTrucks] = React.useState<any[]>([]);
	const [optionDrivers, setOptionDrivers] = React.useState<any[]>([]);
	useQuery<ApiDrivers, ApiError>(
		["query-driver", data],
		async () => {
			return await CommonService.getAllDrivers();
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const options = res.data.map((el) => {
					if (el.id === data?.data?.driverId) {
						setPayloadDO({
							...payloadDO,
							driver: {
								...el,
								valueStr: el.id,
								labelStr: el.fullName
							}
						});
					}
					return {
						...el,
						id: el.id,
						valueStr: el.id,
						labelStr: el.fullName
					};
				});
				setOptionDrivers(options);
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);
	useQuery<ApiVehicles, ApiError>(
		["query-vehicle"],
		async () => {
			return await InventoryService.getAllVehicles();
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res.data.map((el) => {
					return {
						...el,
						id: el.id,
						text: `${el?.internalId ?? el?.internal_id} / ${
							el?.plateNumber ?? el?.plate_number
						}`,
						valueStr: el?.plateNumber ?? el?.plate_number,
						labelStr: `${el?.internalId ?? el?.internal_id} / ${
							el?.plateNumber ?? el?.plate_number
						}`
					};
				});
				setOptionTrucks(data);
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const [payloadDO, setPayloadDO] = React.useState<PayloadDO>();

	React.useEffect(() => {
		// summary
		if (true) {
			const summary = [
				{
					name: "Nama Sales",
					type: "text",
					data: data?.data?.Project?.User?.fullName
				},
				{
					name: "Produk",
					type: "text",
					data: data?.data?.Schedule?.SaleOrder?.Product?.name
				},
				{
					name: "Kuantitas",
					type: "text",
					data: data?.data?.quantity
						? formatComma(`${data?.data?.quantity} M³`)
						: "0 M³",
					form: {
						label: "",
						type: "quantity",
						value:
							payloadDO?.quantity !== undefined
								? payloadDO?.quantity?.toString()
								: "",
						onChange: (e: any) => {
							const newQuantity = parseFloat(e.target.value);
							setPayloadDO({
								...payloadDO,
								quantity: isNaN(newQuantity)
									? undefined
									: newQuantity
							});
						},
						placeholder: "Edit kuantitas"
					}
				},
				{
					name: "Driver",
					type: "text",
					data: data?.data?.ActualDriver?.name,
					// data: data?.data?.Driver?.name,
					form: {
						label: "",
						type: "dropdown",
						value: payloadDO?.actualDriver,
						onChange: (e: any) => {
							setPayloadDO((prev: any) => {
								return {
									...prev,
									actualDriver: e
								};
							});
						},
						placeholder: "Pilih Driver",
						options:
							drivers?.data?.map((driver: ActualDrivers) => {
								return {
									...driver,
									labelStr: driver.name,
									valueStr: driver.id
								};
							}) || []
					}
				},
				{
					name: "HP TM",
					type: "text",
					data: data?.data?.User
						? (data?.data?.User?.fullName
								? `${data?.data?.User?.fullName}`
								: "-") +
						  (data?.data?.User?.phone
								? ` (0${data?.data?.User?.phone})`
								: "")
						: "-",
					form: {
						label: "",
						type: "dropdown",
						value: payloadDO?.driver,
						onChange: (e: any) => {
							setPayloadDO({
								...payloadDO,
								driver: e
							});
						},
						placeholder: "Pilih Driver",
						options: optionDrivers
					}
				},
				{
					name: "No. TM",
					type: "text",
					data: `${
						data?.data?.Vehicle?.internalId ??
						data?.data?.Vehicle?.internal_id
					} / ${
						data?.data?.Vehicle?.plateNumber ??
						data?.data?.Vehicle?.plate_number
					}`,
					form: {
						label: "",
						type: "dropdown",
						value: payloadDO?.truck,
						onChange: (e: any) => {
							setPayloadDO({
								...payloadDO,
								truck: e
							});
						},
						placeholder: "Pilih Truck",
						options: optionTrucks
					}
				},
				{
					name: "Tracking",
					type: "button",
					data: {
						text: "Buka",
						iconRight: <BoxArrowUpRight size={16} />,
						color: "primary",
						onClick: (e: any) => {
							e?.preventDefault();
							window.open(data?.data.urlTracker, "_blank");
						}
					}
				},
				{
					name: "Jumlah",
					type: "text",
					data: "Rp " + formatIDR(data?.data?.totalPrice as number)
				},
				{
					name: "Tanggal Pengiriman",
					type: "text",
					// data: momemnt(data?.data?.date as Date).format('dd-mm-yyyy')
					data: moment(data?.data?.date).format("yyyy-MM-DD")
				},
				{
					name: "No WO",
					type: "text",
					// data: momemnt(data?.data?.date as Date).format('dd-mm-yyyy')
					data: data?.data?.WorkOrder?.number ?? "-"
				},
				{
					name: "Accurate Jam Mulai Loading",
					type: "text",
					data: data?.data?.accurateStartLoadingTime
						? data?.data?.accurateStartLoadingTime
						: "-",
					form: {
						label: "",
						type: "time",
						value: payloadDO?.accurateStartLoadingTime,
						onChange: (e: any) => {
							setPayloadDO({
								...payloadDO,
								accurateStartLoadingTime: e.target.value
							});
						}
					}
				},
				{
					name: "Accurate Jam Selesai Loading",
					type: "text",
					data: data?.data?.accurateEndLoadingTime
						? data?.data?.accurateEndLoadingTime
						: "-",
					form: {
						label: "",
						type: "time",
						value: payloadDO?.accurateEndLoadingTime,
						onChange: (e: any) => {
							setPayloadDO({
								...payloadDO,
								accurateEndLoadingTime: e.target.value
							});
						}
					}
				}

				// {
				// 	name: "Accurate Jam Mulai Loading",
				// 	type: "text",
				// 	// data: momemnt(data?.data?.date as Date).format('dd-mm-yyyy')
				// 	data: data?.data?.WorkOrder?.number ?? "-"
				// }
			];
			setSummary(summary);
			const times = [
				{
					name: "Jam Mulai Loading",
					type: "text",
					data: data?.data?.loadingStart
						? moment(data?.data?.loadingStart).format("HH:mm")
						: "-",
					form: {
						label: "",
						type: "time",
						value: payloadDO?.loadingStart,
						onChange: (e: any) => {
							setPayloadDO({
								...payloadDO,
								loadingStart: e.target.value
							});
						}
					}
				},
				{
					name: "Jam Selesai Loading",
					type: "text",
					data: data?.data?.loadingEnd
						? moment(data?.data?.loadingEnd).format("HH:mm")
						: "-",
					form: {
						label: "",
						type: "time",
						value: payloadDO?.loadingEnd,
						onChange: (e: any) => {
							setPayloadDO({
								...payloadDO,
								loadingEnd: e.target.value
							});
						}
					}
				},
				{
					name: "Jam Berangkat",
					type: "text",
					data: data?.data?.plantDeparture
						? moment(data?.data?.plantDeparture).format("HH:mm")
						: "-",
					form: {
						label: "",
						type: "time",
						value: payloadDO?.plantDeparture,
						onChange: (e: any) => {
							setPayloadDO({
								...payloadDO,
								plantDeparture: e.target.value
							});
						}
					}
				},
				{
					name: "Jam Tiba Di Lokasi",
					type: "text",
					data: data?.data?.projectArrive
						? moment(data?.data?.projectArrive).format("HH:mm")
						: "-",
					form: {
						label: "",
						type: "time",
						value: payloadDO?.projectArrive,
						onChange: (e: any) => {
							setPayloadDO({
								...payloadDO,
								projectArrive: e.target.value
							});
						}
					}
				},
				{
					name: "Jam Mulai Penuangan",
					type: "text",
					data: data?.data?.pouringStart
						? moment(data?.data?.pouringStart).format("HH:mm")
						: "-",
					form: {
						label: "",
						type: "time",
						value: payloadDO?.pouringStart,
						onChange: (e: any) => {
							setPayloadDO({
								...payloadDO,
								pouringStart: e.target.value
							});
						}
					}
				},
				{
					name: "Jam Selesai Penuangan",
					type: "text",
					data: data?.data?.pouringEnd
						? moment(data?.data?.pouringEnd).format("HH:mm")
						: "-",
					form: {
						label: "",
						type: "time",
						value: payloadDO?.pouringEnd,
						onChange: (e: any) => {
							setPayloadDO({
								...payloadDO,
								pouringEnd: e.target.value
							});
						}
					}
				},
				{
					name: "Jam Balik Ke Plant",
					type: "text",
					data: data?.data?.plantReturn
						? moment(data?.data?.plantReturn).format("HH:mm")
						: "-",
					form: {
						label: "",
						type: "time",
						value: payloadDO?.plantReturn,
						onChange: (e: any) => {
							setPayloadDO({
								...payloadDO,
								plantReturn: e.target.value
							});
						}
					}
				},
				{
					name: "Jam Tiba Di Plant",
					type: "text",
					data: data?.data?.plantArrive
						? moment(data?.data?.plantArrive).format("HH:mm")
						: "-",
					form: {
						label: "",
						type: "time",
						value: payloadDO?.plantArrive,
						onChange: (e: any) => {
							setPayloadDO({
								...payloadDO,
								plantArrive: e.target.value
							});
						}
					}
				},
				{
					name: "Step",
					type: "text",
					data: data?.data?.step ?? "-"
				}
			];
			setTimes(times);
		}
	}, [optionTrucks, optionDrivers, payloadDO, optionsSchedule, data]);

	React.useEffect(() => {
		// foto
		if (true && data?.data.DeliveryOrderFile) {
			const files: ApiFile[] = data?.data?.DeliveryOrderFile as ApiFile[];
			const videoFiles: any = files.filter(
				(file) => file.File.type === "mp4"
			);
			const objVideo = {
				data: undefined,
				name: "List Video Penuangan",
				type: "video"
			};

			// const videoFiles: any = data?.data?.DeliveryOrderFile.filter
			const weights: ResponseWeight[] = data?.data
				?.Weight as ResponseWeight[];
			const w = {
				out: weights.find((v) =>
					["WEIGHT_OUT", "WB_OUT_DO", "WB_OUT_RESULT"].includes(
						v.type
					)
				),
				in: weights.find((v) => v.type == "WEIGHT_IN")
			};
			const _s = (data?.data?.status ?? "").trim().toUpperCase();
			const done = {
				wbDispatch: [
					"WB_OUT",
					"ON_DELIVERY",
					"RECEIVED",
					"AWAIT_WB_IN",
					"FINISHED"
				].includes(_s),
				secDispatch: [
					"ON_DELIVERY",
					"RECEIVED",
					"AWAIT_WB_IN",
					"FINISHED"
				].includes(_s),
				drvArrival: ["RECEIVED", "AWAIT_WB_IN", "FINISHED"].includes(
					_s
				),
				secReturn: ["AWAIT_WB_IN", "FINISHED"].includes(_s),
				wbReturn: ["FINISHED"].includes(_s)
			};

			const updates = [
				// {
				// 	name: `${
				// 		done.wbDispatch ? "✅ " : ""
				// 	}Weigh Bridge - Dispatch`,
				// 	type: "card",
				// 	data: [
				// 		{
				// 			name: "Foto DO",
				// 			type: "photo",
				// 			data: files.find(
				// 				(v) =>
				// 					v.type == "WB_OUT_DO" ||
				// 					v.type == "WEIGHT_OUT"
				// 			),
				// 			form: {
				// 				label: "Ganti Foto",
				// 				type: "file",
				// 				preview: payloadDO?.files?.find(
				// 					(v) =>
				// 						v.type == "WB_OUT_DO" ||
				// 						v.type == "WEIGHT_OUT"
				// 				)?.newFile
				// 					? URL.createObjectURL(
				// 							payloadDO?.files?.find(
				// 								(v) =>
				// 									v.type == "WB_OUT_DO" ||
				// 									v.type == "WEIGHT_OUT"
				// 							)?.newFile as Blob
				// 					  )
				// 					: "",
				// 				placeholder: "Pilih File",
				// 				style: {
				// 					backgroundColor: "transparent",
				// 					border: "none",
				// 					position: "absolute",
				// 					top: "50%",
				// 					left: "46%",
				// 					transform: "translate(-50%, -30%)"
				// 				},
				// 				onChange: (e: any) => {
				// 					const filteredFile = payloadDO?.files?.find(
				// 						(v) =>
				// 							v.type == "WB_OUT_DO" ||
				// 							v.type == "WEIGHT_OUT"
				// 					);
				// 					const newFiles = [
				// 						...(payloadDO?.files as ChangeFile[])
				// 					];
				// 					if (filteredFile) {
				// 						// parsedFile = {
				// 						// 	...filteredFile
				// 						// };
				// 						const file = filteredFile as any;
				// 						file.newFile = e.target.files[0];
				// 						file.preview = URL.createObjectURL(
				// 							e.target.files[0]
				// 						);

				// 						const index =
				// 							payloadDO?.files?.findIndex(
				// 								(el) => el.id == file.id
				// 							);
				// 						if (index && index != -1) {
				// 							newFiles[index] = file;
				// 						}
				// 					} else {
				// 						const newFile = {
				// 							type: "WB_OUT_DO",
				// 							newFile: e.target.files[0],
				// 							preview: URL.createObjectURL(
				// 								e.target.files[0]
				// 							)
				// 						};
				// 						newFiles.splice(
				// 							0,
				// 							0,
				// 							newFile as unknown as ChangeFile
				// 						);
				// 					}
				// 					setPayloadDO({
				// 						...payloadDO,
				// 						files: newFiles
				// 					});
				// 				}
				// 			}
				// 		},
				// 		{
				// 			name: "Foto Hasil",
				// 			type: "photo",
				// 			data: files.find(
				// 				(v) =>
				// 					v.type == "WB_OUT_RESULT" ||
				// 					v.type == "WEIGHT_OUT"
				// 			),
				// 			form: {
				// 				label: "Ganti Foto",
				// 				type: "file",
				// 				preview: payloadDO?.files?.find(
				// 					(v) =>
				// 						v.type == "WB_OUT_RESULT" ||
				// 						v.type == "WEIGHT_OUT"
				// 				)?.newFile
				// 					? URL.createObjectURL(
				// 							payloadDO?.files?.find(
				// 								(v) =>
				// 									v.type == "WB_OUT_RESULT" ||
				// 									v.type == "WEIGHT_OUT"
				// 							)?.newFile as Blob
				// 					  )
				// 					: "",
				// 				placeholder: "Pilih File",
				// 				style: {
				// 					backgroundColor: "transparent",
				// 					border: "none",
				// 					position: "absolute",
				// 					top: "50%",
				// 					left: "46%",
				// 					transform: "translate(-50%, -30%)"
				// 				},
				// 				onChange: (e: any) => {
				// 					const filteredFile = payloadDO?.files?.find(
				// 						(v) =>
				// 							v.type == "WB_OUT_RESULT" ||
				// 							v.type == "WEIGHT_OUT"
				// 					);
				// 					const newFiles = [
				// 						...(payloadDO?.files as ChangeFile[])
				// 					];
				// 					if (filteredFile) {
				// 						const file = filteredFile as any;
				// 						file.newFile = e.target.files[0];
				// 						file.preview = URL.createObjectURL(
				// 							e.target.files[0]
				// 						);

				// 						const index =
				// 							payloadDO?.files?.findIndex(
				// 								(el) => el.id == file.id
				// 							);
				// 						if (index && index != -1) {
				// 							newFiles[index] = file;
				// 						}
				// 					} else {
				// 						const newFile = {
				// 							type: "WB_OUT_RESULT",
				// 							newFile: e.target.files[0],
				// 							preview: URL.createObjectURL(
				// 								e.target.files[0]
				// 							)
				// 						};
				// 						newFiles.splice(
				// 							1,
				// 							0,
				// 							newFile as unknown as ChangeFile
				// 						);
				// 					}
				// 					setPayloadDO({
				// 						...payloadDO,
				// 						files: newFiles
				// 					});
				// 				}
				// 			}
				// 		},
				// 		{
				// 			name: "Berat",
				// 			data: w.out
				// 				? `${w.out?.weight} ${w.out?.unit}`
				// 				: "N/A"
				// 		}
				// 	]
				// },
				{
					name: "[Driver] Loading",
					type: "card",
					data: [
						{
							name: "TM Sedang Loading",
							type: "photo",
							data: files.find((v) => v.type == "DO_TM_LOADING"),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) => v.type == "DO_TM_LOADING"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) => v.type == "DO_TM_LOADING"
											)?.newFile as Blob
									  )
									: "",
								placeHolder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) => v.type == "DO_TM_LOADING"
									);

									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];

									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);

										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_TM_LOADING",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};

										newFiles.splice(
											17,
											0,
											newFile as unknown as ChangeFile
										);
									}

									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Odometer Mulai",
							type: "photo",
							data: files.find(
								(v) => v.type == "DO_INITIAL_ODOMETER"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) => v.type == "DO_INITIAL_ODOMETER"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type ==
													"DO_INITIAL_ODOMETER"
											)?.newFile as Blob
									  )
									: "",
								placeHolder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) => v.type == "DO_INITIAL_ODOMETER"
									);

									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];

									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);

										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_INITIAL_ODOMETER",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};

										newFiles.splice(
											18,
											0,
											newFile as unknown as ChangeFile
										);
									}

									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Lainnya",
							type: "photo",
							data: files.find(
								(v) => v.type == "DO_ADDITIONAL_LOADING"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) => v.type == "DO_ADDITIONAL_LOADING"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type ==
													"DO_ADDITIONAL_LOADING"
											)?.newFile as Blob
									  )
									: "",
								placeHolder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) => v.type == "DO_ADDITIONAL_LOADING"
									);

									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];

									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);

										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_ADDITIONAL_LOADING",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};

										newFiles.splice(
											19,
											0,
											newFile as unknown as ChangeFile
										);
									}

									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						}
					]
				},
				{
					name: `${
						done.secDispatch ? "✅ " : ""
					}[Security] Keberangkatan`,
					type: "card",
					data: [
						{
							name: "Foto DO",
							type: "photo",
							data: files.find(
								(v) =>
									v.type == "DO_SECURITY" ||
									v.type == "DO_DEPARTURE_SECURITY"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) =>
										v.type == "DO_SECURITY" ||
										v.type == "DO_DEPARTURE_SECURITY"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type == "DO_SECURITY" ||
													v.type ==
														"DO_DEPARTURE_SECURITY"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) =>
											v.type == "DO_SECURITY" ||
											v.type == "DO_DEPARTURE_SECURITY"
									);
									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];
									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);
										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_SECURITY",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};
										newFiles.splice(
											2,
											0,
											newFile as unknown as ChangeFile
										);
									}
									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Foto Driver",
							type: "photo",
							data: files.find(
								(v) => v.type == "DO_DRIVER_SECURITY"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) => v.type == "DO_DRIVER_SECURITY"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type ==
													"DO_DRIVER_SECURITY"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) => v.type == "DO_DRIVER_SECURITY"
									);
									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];
									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);
										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_DRIVER_SECURITY",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};
										newFiles.splice(
											3,
											0,
											newFile as unknown as ChangeFile
										);
									}
									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Foto No. Polisi TM",
							type: "photo",
							data: files.find(
								(v) =>
									v.type == "DO_LICENSE_SECURITY" ||
									v.type == "DO_TRUCK_CONDITION_SECURITY"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) =>
										v.type == "DO_LICENSE_SECURITY" ||
										v.type == "DO_TRUCK_CONDITION_SECURITY"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type ==
														"DO_LICENSE_SECURITY" ||
													v.type ==
														"DO_TRUCK_CONDITION_SECURITY"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) =>
											v.type == "DO_LICENSE_SECURITY" ||
											v.type ==
												"DO_TRUCK_CONDITION_SECURITY"
									);
									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];
									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);
										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type:
												"DO_LICENSE_SECURITY" ||
												"DO_TRUCK_CONDITION_SECURITY",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};
										newFiles.splice(
											4,
											0,
											newFile as unknown as ChangeFile
										);
									}
									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Foto Segel",
							type: "photo",
							data: files.find(
								(v) => v.type == "DO_SEAL_SECURITY"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) => v.type == "DO_SEAL_SECURITY"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type == "DO_SEAL_SECURITY"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) => v.type == "DO_SEAL_SECURITY"
									);
									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];
									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);
										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_SEAL_SECURITY",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};
										newFiles.splice(
											5,
											0,
											newFile as unknown as ChangeFile
										);
									}
									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Foto Kondom",
							type: "photo",
							data: files.find(
								(v) => v.type == "DO_KONDOM_SECURITY"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) => v.type == "DO_KONDOM_SECURITY"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type ==
													"DO_KONDOM_SECURITY"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) => v.type == "DO_KONDOM_SECURITY"
									);
									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];
									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);
										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_KONDOM_SECURITY",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};
										newFiles.splice(
											6,
											0,
											newFile as unknown as ChangeFile
										);
									}
									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Foto Odometer",
							type: "photo",
							data: files.find(
								(v) => v.type == "DO_ODOMETER_SECURITY"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) => v.type == "DO_ODOMETER_SECURITY"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type ==
													"DO_ODOMETER_SECURITY"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) => v.type == "DO_ODOMETER_SECURITY"
									);
									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];
									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);
										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_ODOMETER_SECURITY",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};
										newFiles.splice(
											3,
											0,
											newFile as unknown as ChangeFile
										);
									}
									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Foto Tanki Solar [Atas]",
							type: "photo",
							data: files.find(
								(v) => v.type == "DO_TANKI_SOLAR_ATAS_SECURITY"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) =>
										v.type == "DO_TANKI_SOLAR_ATAS_SECURITY"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type ==
													"DO_TANKI_SOLAR_ATAS_SECURITY"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) =>
											v.type ==
											"DO_TANKI_SOLAR_ATAS_SECURITY"
									);
									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];
									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);
										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_TANKI_SOLAR_ATAS_SECURITY",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};
										newFiles.splice(
											3,
											0,
											newFile as unknown as ChangeFile
										);
									}
									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Foto Tanki Solar [Bawah]",
							type: "photo",
							data: files.find(
								(v) => v.type == "DO_TANKI_SOLAR_BAWAH_SECURITY"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) =>
										v.type ==
										"DO_TANKI_SOLAR_BAWAH_SECURITY"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type ==
													"DO_TANKI_SOLAR_BAWAH_SECURITY"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) =>
											v.type ==
											"DO_TANKI_SOLAR_BAWAH_SECURITY"
									);
									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];
									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);
										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_TANKI_SOLAR_BAWAH_SECURITY",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};
										newFiles.splice(
											3,
											0,
											newFile as unknown as ChangeFile
										);
									}
									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Foto Roda Depan Kanan",
							type: "photo",
							data: files.find(
								(v) => v.type == "DO_RODA_DEPAN_KANAN_SECURITY"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) =>
										v.type == "DO_RODA_DEPAN_KANAN_SECURITY"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type ==
													"DO_RODA_DEPAN_KANAN_SECURITY"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) =>
											v.type ==
											"DO_RODA_DEPAN_KANAN_SECURITY"
									);
									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];
									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);
										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_RODA_DEPAN_KANAN_SECURITY",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};
										newFiles.splice(
											3,
											0,
											newFile as unknown as ChangeFile
										);
									}
									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Foto Roda Depan Kiri",
							type: "photo",
							data: files.find(
								(v) => v.type == "DO_RODA_DEPAN_KIRI_SECURITY"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) =>
										v.type == "DO_RODA_DEPAN_KIRI_SECURITY"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type ==
													"DO_RODA_DEPAN_KIRI_SECURITY"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) =>
											v.type ==
											"DO_RODA_DEPAN_KIRI_SECURITY"
									);
									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];
									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);
										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_RODA_DEPAN_KIRI_SECURITY",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};
										newFiles.splice(
											3,
											0,
											newFile as unknown as ChangeFile
										);
									}
									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Foto Roda Belakang Kanan",
							type: "photo",
							data: files.find(
								(v) =>
									v.type == "DO_RODA_BELAKANG_KANAN_SECURITY"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) =>
										v.type ==
										"DO_RODA_BELAKANG_KANAN_SECURITY"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type ==
													"DO_RODA_BELAKANG_KANAN_SECURITY"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) =>
											v.type ==
											"DO_RODA_BELAKANG_KANAN_SECURITY"
									);
									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];
									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);
										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_RODA_BELAKANG_KANAN_SECURITY",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};
										newFiles.splice(
											3,
											0,
											newFile as unknown as ChangeFile
										);
									}
									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Foto Roda Belakang Kiri",
							type: "photo",
							data: files.find(
								(v) =>
									v.type == "DO_RODA_BELAKANG_KIRI_SECURITY"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) =>
										v.type ==
										"DO_RODA_BELAKANG_KIRI_SECURITY"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type ==
													"DO_RODA_BELAKANG_KIRI_SECURITY"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) =>
											v.type ==
											"DO_RODA_BELAKANG_KIRI_SECURITY"
									);
									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];
									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);
										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_RODA_BELAKANG_KIRI_SECURITY",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};
										newFiles.splice(
											3,
											0,
											newFile as unknown as ChangeFile
										);
									}
									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						}
					]
				},
				{
					name: `${done.drvArrival ? "✅ " : ""}[Driver] Proyek`,
					type: "card",
					data: [
						{
							name: "Foto Tiba di Lokasi",
							type: "photo",
							data: files.find(
								(v) => v.type == "DO_DRIVER_ARRIVE_PROJECT"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) => v.type == "DO_DRIVER_ARRIVE_PROJECT"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type ==
													"DO_DRIVER_ARRIVE_PROJECT"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const options = {
										maxSizeMB: 1,
										maxWidthOrHeight: 1920,
										useWebWorker: true
									};

									imageCompression(
										e.target.files[0],
										options
									).then((compressedFile) => {
										const filteredFile =
											payloadDO?.files?.find(
												(v) =>
													v.type ==
													"DO_DRIVER_ARRIVE_PROJECT"
											);
										const newFiles = [
											...(payloadDO?.files as ChangeFile[])
										];
										if (filteredFile) {
											const file = filteredFile as any;
											file.newFile = compressedFile;

											file.preview =
												URL.createObjectURL(
													compressedFile
												);

											const index =
												payloadDO?.files?.findIndex(
													(el) => el.id == file.id
												);
											if (index && index != -1) {
												newFiles[index] = file;
											}
										} else {
											const newFile = {
												type: "DO_DRIVER_ARRIVE_PROJECT",
												newFile: compressedFile,
												preview:
													URL.createObjectURL(
														compressedFile
													)
											};
											newFiles.splice(
												7,
												0,
												newFile as unknown as ChangeFile
											);
										}
										setPayloadDO({
											...payloadDO,
											files: newFiles
										});
									});
								}
							}
						},
						{
							name: "Foto Gentong TM Terisi Beton",
							type: "photo",
							data: files.find((v) => v.type == "DO_DRIVER_BNIB"),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) => v.type == "DO_DRIVER_BNIB"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type == "DO_DRIVER_BNIB"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) => v.type == "DO_DRIVER_BNIB"
									);
									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];
									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);
										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_DRIVER_BNIB",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};
										newFiles.splice(
											8,
											0,
											newFile as unknown as ChangeFile
										);
									}
									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Foto TM Menuang Beton",
							type: "photo",
							data: files.find(
								(v) => v.type == "DO_DRIVER_UNBOXING"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) => v.type == "DO_DRIVER_UNBOXING"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type ==
													"DO_DRIVER_UNBOXING"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) => v.type == "DO_DRIVER_UNBOXING"
									);
									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];
									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);
										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_DRIVER_UNBOXING",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};
										newFiles.splice(
											9,
											0,
											newFile as unknown as ChangeFile
										);
									}
									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Foto Gentong TM Kosong",
							type: "photo",
							data: files.find(
								(v) => v.type == "DO_DRIVER_EMPTY"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) => v.type == "DO_DRIVER_EMPTY"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type == "DO_DRIVER_EMPTY"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) => v.type == "DO_DRIVER_EMPTY"
									);
									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];
									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);
										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_DRIVER_EMPTY",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};
										newFiles.splice(
											10,
											0,
											newFile as unknown as ChangeFile
										);
									}
									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Foto DO saat Ditandatangani",
							type: "photo",
							data: files.find((v) => v.type == "DO_DRIVER_DO"),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) => v.type == "DO_DRIVER_DO"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) => v.type == "DO_DRIVER_DO"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) => v.type == "DO_DRIVER_DO"
									);
									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];
									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);
										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_DRIVER_DO",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};
										newFiles.splice(
											11,
											0,
											newFile as unknown as ChangeFile
										);
									}
									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Foto Penerima DO di Proyek",
							type: "photo",
							data: files.find(
								(v) => v.type == "DO_DRIVER_RECEIPIENT"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) => v.type == "DO_DRIVER_RECEIPIENT"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type ==
													"DO_DRIVER_RECEIPIENT"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) => v.type == "DO_DRIVER_RECEIPIENT"
									);
									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];
									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);
										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_DRIVER_RECEIPIENT",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};
										newFiles.splice(
											12,
											0,
											newFile as unknown as ChangeFile
										);
									}
									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Foto Penambahan Air",
							type: "photo",
							data: files.find(
								(v) => v.type == "DO_DRIVER_WATER"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) => v.type == "DO_DRIVER_WATER"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type == "DO_DRIVER_WATER"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) => v.type == "DO_DRIVER_WATER"
									);
									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];
									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);
										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_DRIVER_WATER",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};
										newFiles.splice(
											13,
											0,
											newFile as unknown as ChangeFile
										);
									}
									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						...(videoFiles.length > 0
							? videoFiles.map((el: any) => {
									return {
										name: el.type,
										type: "video",
										data: {
											File: el.File
										}
									};
							  })
							: [objVideo]),
						{
							name: "Foto Tambahan",
							type: "photo",
							data: files.find(
								(v) => v.type == "DO_DRIVER_FINISH_PROJECT"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) => v.type == "DO_DRIVER_FINISH_PROJECT"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type ==
													"DO_DRIVER_FINISH_PROJECT"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) =>
											v.type == "DO_DRIVER_FINISH_PROJECT"
									);
									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];
									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);
										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_DRIVER_FINISH_PROJECT",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};
										newFiles.splice(
											14,
											0,
											newFile as unknown as ChangeFile
										);
									}
									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Nama Penerima",
							data: data?.data?.recipientName
						},
						{
							name: "No. Telp Penerima",
							data: data?.data?.recipientNumber
						}
					]
				},
				// {
				// 	name: "Driver-Arrive Project",
				// 	type: "card",
				// 	data: [
				// 		{
				// 			name: "Foto Tiba di Lokasi",
				// 			type: "photo",
				// 			data: files.find(
				// 				(v) => v.type == "DO_DRIVER_ARRIVE_PROJECT"
				// 			),
				// 			form: {
				// 				label: "Ganti Foto",
				// 				type: "file",
				// 				preview: payloadDO?.files?.find(
				// 					(v) => v.type == "DO_DRIVER_ARRIVE_PROJECT"
				// 				)?.newFile
				// 					? URL.createObjectURL(
				// 						payloadDO?.files?.find(
				// 							(v) =>
				// 								v.type ==
				// 								"DO_DRIVER_ARRIVE_PROJECT"
				// 						)?.newFile as Blob
				// 					)
				// 					: "",
				// 				placeHolder: "Pilih File",
				// 				style: {
				// 					backgroundColor: "transparent",
				// 					border: "none",
				// 					position: "absolute",
				// 					top: "50%",
				// 					left: "46%",
				// 					transform: "translate(-50%, -30%)"
				// 				},
				// 				onChange: (e: any) => {

				// 					const filteredFile = payloadDO?.files?.find(
				// 						(v) => v.type == "DO_DRIVER_ARRIVE_PROJECT"
				// 					);

				// 					const newFiles = [
				// 						...(payloadDO?.files as ChangeFile[])
				// 					];

				// 					if (filteredFile) {
				// 						const file = filteredFile as any;
				// 						file.newFile = e.target.files[0];
				// 						file.preview = URL.createObjectURL(
				// 							e.target.files[0]
				// 						);

				// 						const index =
				// 							payloadDO?.files?.findIndex(
				// 								(el) => el.id == file.id
				// 							);

				// 						if (index && index != -1) {
				// 							newFiles[index] = file;
				// 						}

				// 					} else {
				// 						const newFile = {
				// 							type: "DO_DRIVER_ARRIVE_PROJECT",
				// 							newFile: e.target.files[0],
				// 							preview: URL.createObjectURL(
				// 								e.target.files[0]
				// 							)
				// 						};

				// 						newFiles.splice(
				// 							20,
				// 							0,
				// 							newFile as unknown as ChangeFile
				// 						);
				// 					}

				// 					setPayloadDO({
				// 						...payloadDO,
				// 						files: newFiles
				// 					});
				// 				}
				// 			}
				// 		},
				// 		{
				// 			name: "Foto Gentong TM Terisi Beton",
				// 			type: "photo",
				// 			data: files.find(
				// 				(v) => v.type == "DO_DRIVER_BNIB"
				// 			),
				// 			form: {
				// 				label: "Ganti Foto",
				// 				type: "file",
				// 				preview: payloadDO?.files?.find(
				// 					(v) => v.type == "DO_DRIVER_BNIB"
				// 				)?.newFile
				// 					? URL.createObjectURL(
				// 						payloadDO?.files?.find(
				// 							(v) =>
				// 								v.type ==
				// 								"DO_DRIVER_BNIB"
				// 						)?.newFile as Blob
				// 					)
				// 					: "",
				// 				placeHolder: "Pilih File",
				// 				style: {
				// 					backgroundColor: "transparent",
				// 					border: "none",
				// 					position: "absolute",
				// 					top: "50%",
				// 					left: "46%",
				// 					transform: "translate(-50%, -30%)"
				// 				},
				// 				onChange: (e: any) => {

				// 					const filteredFile = payloadDO?.files?.find(
				// 						(v) => v.type == "DO_DRIVER_BNIB"
				// 					);

				// 					const newFiles = [
				// 						...(payloadDO?.files as ChangeFile[])
				// 					];

				// 					if (filteredFile) {
				// 						const file = filteredFile as any;
				// 						file.newFile = e.target.files[0];
				// 						file.preview = URL.createObjectURL(
				// 							e.target.files[0]
				// 						);

				// 						const index =
				// 							payloadDO?.files?.findIndex(
				// 								(el) => el.id == file.id
				// 							);

				// 						if (index && index != -1) {
				// 							newFiles[index] = file;
				// 						}

				// 					} else {
				// 						const newFile = {
				// 							type: "DO_DRIVER_BNIB",
				// 							newFile: e.target.files[0],
				// 							preview: URL.createObjectURL(
				// 								e.target.files[0]
				// 							)
				// 						};

				// 						newFiles.splice(
				// 							21,
				// 							0,
				// 							newFile as unknown as ChangeFile
				// 						);
				// 					}

				// 					setPayloadDO({
				// 						...payloadDO,
				// 						files: newFiles
				// 					});
				// 				}
				// 			}
				// 		},
				// 		{
				// 			name: "Lainnya",
				// 			type: "photo",
				// 			data: files.find(
				// 				(v) => v.type == "DO_ADDITIONAL_ARRIVED"
				// 			),
				// 			form: {
				// 				label: "Ganti Foto",
				// 				type: "file",
				// 				preview: payloadDO?.files?.find(
				// 					(v) => v.type == "DO_ADDITIONAL_ARRIVED"
				// 				)?.newFile
				// 					? URL.createObjectURL(
				// 						payloadDO?.files?.find(
				// 							(v) =>
				// 								v.type ==
				// 								"DO_ADDITIONAL_ARRIVED"
				// 						)?.newFile as Blob
				// 					)
				// 					: "",
				// 				placeHolder: "Pilih File",
				// 				style: {
				// 					backgroundColor: "transparent",
				// 					border: "none",
				// 					position: "absolute",
				// 					top: "50%",
				// 					left: "46%",
				// 					transform: "translate(-50%, -30%)"
				// 				},
				// 				onChange: (e: any) => {

				// 					const filteredFile = payloadDO?.files?.find(
				// 						(v) => v.type == "DO_ADDITIONAL_ARRIVED"
				// 					);

				// 					const newFiles = [
				// 						...(payloadDO?.files as ChangeFile[])
				// 					];

				// 					if (filteredFile) {
				// 						const file = filteredFile as any;
				// 						file.newFile = e.target.files[0];
				// 						file.preview = URL.createObjectURL(
				// 							e.target.files[0]
				// 						);

				// 						const index =
				// 							payloadDO?.files?.findIndex(
				// 								(el) => el.id == file.id
				// 							);

				// 						if (index && index != -1) {
				// 							newFiles[index] = file;
				// 						}

				// 					} else {
				// 						const newFile = {
				// 							type: "DO_ADDITIONAL_ARRIVED",
				// 							newFile: e.target.files[0],
				// 							preview: URL.createObjectURL(
				// 								e.target.files[0]
				// 							)
				// 						};

				// 						newFiles.splice(
				// 							22,
				// 							0,
				// 							newFile as unknown as ChangeFile
				// 						);
				// 					}

				// 					setPayloadDO({
				// 						...payloadDO,
				// 						files: newFiles
				// 					});
				// 				}
				// 			}
				// 		}
				// 	]
				// },
				// {
				// 	name: "Driver-Pouring",
				// 	type: "card",
				// 	data: videoFiles.length > 0 ? videoFiles.map((el: any) => {
				// 		return {
				// 			name: el.type,
				// 			type: "video",
				// 			data: {
				// 				File: el.File
				// 			}
				// 		};
				// 	}) : [objVideo]
				// },
				{
					name: "[Driver] Kembali ke Plant",
					type: "card",
					data: [
						{
							name: "Cuci Gentong",
							type: "photo",
							data: files.find(
								(v) => v.type == "DO_DRIVER_EMPTY"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) => v.type == "DO_DRIVER_EMPTY"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type == "DO_DRIVER_EMPTY"
											)?.newFile as Blob
									  )
									: "",
								placeHolder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) => v.type == "DO_DRIVER_EMPTY"
									);

									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];

									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);

										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_DRIVER_EMPTY",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};

										newFiles.splice(
											23,
											0,
											newFile as unknown as ChangeFile
										);
									}

									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Odometer Kembali",
							type: "photo",
							data: files.find(
								(v) => v.type == "DO_RETURN_ODOMETER"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) => v.type == "DO_RETURN_ODOMETER"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type ==
													"DO_RETURN_ODOMETER"
											)?.newFile as Blob
									  )
									: "",
								placeHolder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) => v.type == "DO_RETURN_ODOMETER"
									);

									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];

									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);

										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_RETURN_ODOMETER",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};

										newFiles.splice(
											24,
											0,
											newFile as unknown as ChangeFile
										);
									}

									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Lainnya",
							type: "photo",
							data: files.find(
								(v) => v.type == "DO_ADDITIONAL_RETURNED"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) => v.type == "DO_ADDITIONAL_RETURNED"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type ==
													"DO_ADDITIONAL_RETURNED"
											)?.newFile as Blob
									  )
									: "",
								placeHolder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) =>
											v.type == "DO_ADDITIONAL_RETURNED"
									);

									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];

									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);

										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_ADDITIONAL_RETURNED",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};

										newFiles.splice(
											25,
											0,
											newFile as unknown as ChangeFile
										);
									}

									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						}
					]
				},
				{
					name: `${
						done.secReturn ? "✅ " : ""
					}[Security] Kembali ke Plant`,
					type: "card",
					data: [
						{
							name: "Foto DO",
							type: "photo",
							data: files.find(
								(v) => v.type == "DO_RETURN_SECURITY"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) => v.type == "DO_RETURN_SECURITY"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type ==
													"DO_RETURN_SECURITY"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) => v.type == "DO_RETURN_SECURITY"
									);
									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];
									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);
										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_RETURN_SECURITY",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};
										newFiles.splice(
											15,
											0,
											newFile as unknown as ChangeFile
										);
									}
									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Foto Kondisi TM",
							type: "photo",
							data: files.find(
								(v) =>
									v.type ==
									"DO_RETURN_TRUCK_CONDITION_SECURITY"
							),
							form: {
								label: "Ganti Foto",
								type: "file",
								preview: payloadDO?.files?.find(
									(v) =>
										v.type ==
										"DO_RETURN_TRUCK_CONDITION_SECURITY"
								)?.newFile
									? URL.createObjectURL(
											payloadDO?.files?.find(
												(v) =>
													v.type ==
													"DO_RETURN_TRUCK_CONDITION_SECURITY"
											)?.newFile as Blob
									  )
									: "",
								placeholder: "Pilih File",
								style: {
									backgroundColor: "transparent",
									border: "none",
									position: "absolute",
									top: "50%",
									left: "46%",
									transform: "translate(-50%, -30%)"
								},
								onChange: (e: any) => {
									const filteredFile = payloadDO?.files?.find(
										(v) =>
											v.type ==
											"DO_RETURN_TRUCK_CONDITION_SECURITY"
									);
									const newFiles = [
										...(payloadDO?.files as ChangeFile[])
									];
									if (filteredFile) {
										const file = filteredFile as any;
										file.newFile = e.target.files[0];
										file.preview = URL.createObjectURL(
											e.target.files[0]
										);

										const index =
											payloadDO?.files?.findIndex(
												(el) => el.id == file.id
											);
										if (index && index != -1) {
											newFiles[index] = file;
										}
									} else {
										const newFile = {
											type: "DO_RETURN_TRUCK_CONDITION_SECURITY",
											newFile: e.target.files[0],
											preview: URL.createObjectURL(
												e.target.files[0]
											)
										};
										newFiles.splice(
											16,
											0,
											newFile as unknown as ChangeFile
										);
									}
									setPayloadDO({
										...payloadDO,
										files: newFiles
									});
								}
							}
						},
						{
							name: "Terdapat Sisa Muatan dalam TM",
							data: true,
							type: "boolean"
						},
						{
							name: "Kondisi TM",
							data: data?.data?.conditionTruck
						}
					]
				}
				// {
				// 	name: "Administration",
				// 	type: "card",
				// 	data: [
				// 		{
				// 			name: "Foto DO",
				// 			type: "photo",
				// 			data: files.find(
				// 				(v) => v.type == "DO_DRIVER_DO"
				// 			),
				// 			form: {
				// 				label: "Ganti Foto",
				// 				type: "file",
				// 				preview: payloadDO?.files?.find(
				// 					(v) => v.type == "DO_DRIVER_DO"
				// 				)?.newFile
				// 					? URL.createObjectURL(
				// 						payloadDO?.files?.find(
				// 							(v) =>
				// 								v.type ==
				// 								"DO_DRIVER_DO"
				// 						)?.newFile as Blob
				// 					)
				// 					: "",
				// 				placeHolder: "Pilih File",
				// 				style: {
				// 					backgroundColor: "transparent",
				// 					border: "none",
				// 					position: "absolute",
				// 					top: "50%",
				// 					left: "46%",
				// 					transform: "translate(-50%, -30%)"
				// 				},
				// 				onChange: (e: any) => {

				// 					const filteredFile = payloadDO?.files?.find(
				// 						(v) => v.type == "DO_DRIVER_DO"
				// 					);

				// 					const newFiles = [
				// 						...(payloadDO?.files as ChangeFile[])
				// 					];

				// 					if (filteredFile) {
				// 						const file = filteredFile as any;
				// 						file.newFile = e.target.files[0];
				// 						file.preview = URL.createObjectURL(
				// 							e.target.files[0]
				// 						);

				// 						const index =
				// 							payloadDO?.files?.findIndex(
				// 								(el) => el.id == file.id
				// 							);

				// 						if (index && index != -1) {
				// 							newFiles[index] = file;
				// 						}

				// 					} else {
				// 						const newFile = {
				// 							type: "DO_DRIVER_DO",
				// 							newFile: e.target.files[0],
				// 							preview: URL.createObjectURL(
				// 								e.target.files[0]
				// 							)
				// 						};

				// 						newFiles.splice(
				// 							26,
				// 							0,
				// 							newFile as unknown as ChangeFile
				// 						);
				// 					}

				// 					setPayloadDO({
				// 						...payloadDO,
				// 						files: newFiles
				// 					});
				// 				}
				// 			}
				// 		},
				// 		{
				// 			name: "Penerima",
				// 			type: "photo",
				// 			data: files.find(
				// 				(v) => v.type == "DO_DRIVER_RECEIPIENT"
				// 			),
				// 			form: {
				// 				label: "Ganti Foto",
				// 				type: "file",
				// 				preview: payloadDO?.files?.find(
				// 					(v) => v.type == "DO_DRIVER_RECEIPIENT"
				// 				)?.newFile
				// 					? URL.createObjectURL(
				// 						payloadDO?.files?.find(
				// 							(v) =>
				// 								v.type ==
				// 								"DO_DRIVER_RECEIPIENT"
				// 						)?.newFile as Blob
				// 					)
				// 					: "",
				// 				placeHolder: "Pilih File",
				// 				style: {
				// 					backgroundColor: "transparent",
				// 					border: "none",
				// 					position: "absolute",
				// 					top: "50%",
				// 					left: "46%",
				// 					transform: "translate(-50%, -30%)"
				// 				},
				// 				onChange: (e: any) => {

				// 					const filteredFile = payloadDO?.files?.find(
				// 						(v) => v.type == "DO_DRIVER_RECEIPIENT"
				// 					);

				// 					const newFiles = [
				// 						...(payloadDO?.files as ChangeFile[])
				// 					];

				// 					if (filteredFile) {
				// 						const file = filteredFile as any;
				// 						file.newFile = e.target.files[0];
				// 						file.preview = URL.createObjectURL(
				// 							e.target.files[0]
				// 						);

				// 						const index =
				// 							payloadDO?.files?.findIndex(
				// 								(el) => el.id == file.id
				// 							);

				// 						if (index && index != -1) {
				// 							newFiles[index] = file;
				// 						}

				// 					} else {
				// 						const newFile = {
				// 							type: "DO_DRIVER_RECEIPIENT",
				// 							newFile: e.target.files[0],
				// 							preview: URL.createObjectURL(
				// 								e.target.files[0]
				// 							)
				// 						};

				// 						newFiles.splice(
				// 							27,
				// 							0,
				// 							newFile as unknown as ChangeFile
				// 						);
				// 					}

				// 					setPayloadDO({
				// 						...payloadDO,
				// 						files: newFiles
				// 					});
				// 				}
				// 			}
				// 		}
				// 	]
				// }
				// {
				// 	name: `${done.wbReturn ? "✅ " : ""}Weigh Bridge - Return`,
				// 	type: "card",
				// 	data: [
				// 		{
				// 			name: "Foto DO",
				// 			type: "photo",
				// 			data: files.find((v) => v.type == "DO_WEIGHT_IN"),
				// 			form: {
				// 				label: "Ganti Foto",
				// 				type: "file",
				// 				preview: payloadDO?.files?.find(
				// 					(v) => v.type == "DO_WEIGHT_IN"
				// 				)?.newFile
				// 					? URL.createObjectURL(
				// 							payloadDO?.files?.find(
				// 								(v) => v.type == "DO_WEIGHT_IN"
				// 							)?.newFile as Blob
				// 					  )
				// 					: "",
				// 				placeholder: "Pilih File",
				// 				style: {
				// 					backgroundColor: "transparent",
				// 					border: "none",
				// 					position: "absolute",
				// 					top: "50%",
				// 					left: "46%",
				// 					transform: "translate(-50%, -30%)"
				// 				},
				// 				onChange: (e: any) => {
				// 					const filteredFile = payloadDO?.files?.find(
				// 						(v) => v.type == "DO_WEIGHT_IN"
				// 					);
				// 					const newFiles = [
				// 						...(payloadDO?.files as ChangeFile[])
				// 					];
				// 					if (filteredFile) {
				// 						const file = filteredFile as any;
				// 						file.newFile = e.target.files[0];
				// 						file.preview = URL.createObjectURL(
				// 							e.target.files[0]
				// 						);

				// 						const index =
				// 							payloadDO?.files?.findIndex(
				// 								(el) => el.id == file.id
				// 							);
				// 						if (index && index != -1) {
				// 							newFiles[index] = file;
				// 						}
				// 					} else {
				// 						const newFile = {
				// 							type: "DO_WEIGHT_IN",
				// 							newFile: e.target.files[0],
				// 							preview: URL.createObjectURL(
				// 								e.target.files[0]
				// 							)
				// 						};
				// 						newFiles.splice(
				// 							17,
				// 							0,
				// 							newFile as unknown as ChangeFile
				// 						);
				// 					}
				// 					setPayloadDO({
				// 						...payloadDO,
				// 						files: newFiles
				// 					});
				// 				}
				// 			}
				// 		},
				// 		{
				// 			name: "Foto Hasil",
				// 			type: "photo",
				// 			data: files.find((v) => v.type == "WEIGHT_IN"),
				// 			form: {
				// 				label: "Ganti Foto",
				// 				type: "file",
				// 				preview: payloadDO?.files?.find(
				// 					(v) => v.type == "WEIGHT_IN"
				// 				)?.newFile
				// 					? URL.createObjectURL(
				// 							payloadDO?.files?.find(
				// 								(v) => v.type == "WEIGHT_IN"
				// 							)?.newFile as Blob
				// 					  )
				// 					: "",
				// 				placeholder: "Pilih File",
				// 				style: {
				// 					backgroundColor: "transparent",
				// 					border: "none",
				// 					position: "absolute",
				// 					top: "50%",
				// 					left: "46%",
				// 					transform: "translate(-50%, -30%)"
				// 				},
				// 				onChange: (e: any) => {
				// 					const filteredFile = payloadDO?.files?.find(
				// 						(v) => v.type == "WEIGHT_IN"
				// 					);
				// 					const newFiles = [
				// 						...(payloadDO?.files as ChangeFile[])
				// 					];
				// 					if (filteredFile) {
				// 						const file = filteredFile as any;
				// 						file.newFile = e.target.files[0];
				// 						file.preview = URL.createObjectURL(
				// 							e.target.files[0]
				// 						);

				// 						const index =
				// 							payloadDO?.files?.findIndex(
				// 								(el) => el.id == file.id
				// 							);
				// 						if (index && index != -1) {
				// 							newFiles[index] = file;
				// 						}
				// 					} else {
				// 						const newFile = {
				// 							type: "WEIGHT_IN",
				// 							newFile: e.target.files[0],
				// 							preview: URL.createObjectURL(
				// 								e.target.files[0]
				// 							)
				// 						};
				// 						newFiles.splice(
				// 							18,
				// 							0,
				// 							newFile as unknown as ChangeFile
				// 						);
				// 					}
				// 					setPayloadDO({
				// 						...payloadDO,
				// 						files: newFiles
				// 					});
				// 				}
				// 			}
				// 		},
				// 		{
				// 			name: "Berat",
				// 			data: w.in ? `${w.in?.weight} ${w.in?.unit}` : "N/A"
				// 		}
				// 	]
				// }
			];
			setUpdates(updates);
		}
	}, [payloadDO]);

	const [localDialog, setLocalDialog] = React.useState({
		isOpen: false,
		onClose: () => setLocalDialog({ ...localDialog, isOpen: false }),
		onOpen: () => setLocalDialog({ ...localDialog, isOpen: true })
	});

	const { mutate: sendFile, isLoading: loadingUpload } = useMutation<
		ApiResponseFiles,
		ApiError,
		FormData,
		ApiError
	>(async (_files: FormData) => {
		const res = await CommonService.uploadFile(_files);
		return res;
	});
	const { mutate, isLoading: createLoading } = useMutation(
		async (payload: PayloadCreateDO) => {
			return await OrderService.updateDO(
				data?.data.id as string,
				payload
			);
		},
		{
			onSuccess: (response) => {
				refetch();
				localDialog.onClose();
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const { mutateAsync: updateDoAccurate } = useMutation(
		async ({
			doId,
			startLoadingTime,
			endLoadingTime,
			branchId,
			saleOrderItemId,
			quantity
		}: {
			doId?: string;
			startLoadingTime?: string;
			endLoadingTime?: string;
			branchId?: string;
			saleOrderItemId?: unknown;
			quantity?: unknown;
		}) => {
			return await AccurateService.updateDeliveryOrder({
				doId: doId,
				startLoadingTime: startLoadingTime,
				endLoadingTime: endLoadingTime,
				branchId: branchId,
				saleOrderItemId,
				quantity
			});
		},
		{
			onError(err: any) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
				let message = "Accurate Error/ Tolong Login Ulang";

				if (err?.response?.data?.error?.data?.error) {
					message = `Accurate Error/ ${err?.response?.data?.error?.status} - ${err?.response?.data?.error?.data?.error}`;
					localStorage.removeItem("accurateCreds");
					// window.location.reload();
				}
				dispatch(
					openAlert({
						body: message,
						color: "danger"
					})
				);
			}
		}
	);

	const addCost = React.useMemo(() => {
		if (status === "FINISHED") {
			return [
				{
					name: "Biaya Mobilisasi",
					type: "money",
					data: payloadDO?.addCost?.mobilititation,
					form: {
						label: "",
						type: "money",
						value: payloadDO?.addCost?.mobilititation,
						onChange: (e: any) => {
							const value = formatIDR(e.target.value);
							setPayloadDO({
								...payloadDO,
								addCost: {
									...payloadDO?.addCost,
									mobilititation: value
								} as AddCost
							});
						},
						placeholder: "Biaya Mobilisasi"
					}
				},
				{
					name: "Biaya Parkir",
					type: "money",
					data: payloadDO?.addCost?.park,
					form: {
						label: "",
						type: "money",
						value: payloadDO?.addCost?.park,
						onChange: (e: any) => {
							const value = formatIDR(e.target.value);
							setPayloadDO({
								...payloadDO,
								addCost: {
									...payloadDO?.addCost,
									park: value
								} as AddCost
							});
						},
						placeholder: "Biaya Parkir"
					}
				},
				{
					name: "Tol",
					type: "money",
					data: payloadDO?.addCost?.toll,
					form: {
						label: "",
						type: "money",
						value: payloadDO?.addCost?.toll,
						onChange: (e: any) => {
							const value = formatIDR(e.target.value);
							setPayloadDO({
								...payloadDO,
								addCost: {
									...payloadDO?.addCost,
									toll: value
								} as AddCost
							});
						},
						placeholder: "Biaya Toll"
					}
				},
				{
					name: "Biaya Lain-lain",
					type: "money",
					data: payloadDO?.addCost?.extra,
					form: {
						label: "",
						type: "money",
						value: payloadDO?.addCost?.extra,
						onChange: (e: any) => {
							const value = formatIDR(e.target.value);
							setPayloadDO({
								...payloadDO,
								addCost: {
									...payloadDO?.addCost,
									extra: value
								} as AddCost
							});
						},
						placeholder: "Biaya Lain-lain"
					}
				},
				{
					name: "Catatan",
					type: "text",
					data: payloadDO?.addCost?.notes,
					form: {
						label: "",
						type: "text",
						value: payloadDO?.addCost?.notes,
						disable: !payloadDO?.addCost?.extra?.length,
						onChange: (e: any) => {
							setPayloadDO({
								...payloadDO,
								addCost: {
									...payloadDO?.addCost,
									notes: e.target.value
								} as AddCost
							});
						},
						placeholder: "Catatan Biaya Lain-lain"
					}
				}
			];
		}

		return [];
	}, [localDialog.isOpen, payloadDO]);

	const handleButton = (type: "EDIT" | "SAVE") => async () => {
		const accurateDoId = data?.data?.deliveryOrderAccurateId;
		// patchStatus.mutate(status);
		if (type === "EDIT") {
			localDialog.onOpen();
			return;
		}

		if (type === "SAVE") {
			// if (!payloadDO?.quantity || Number(payloadDO?.quantity) <= 0) {
			// 	dispatch(openAlert({
			// 		body: "masukan jumlah DO",
			// 		color: "danger"
			// 	}));
			// 	return;
			// }
			const _sendFile = new FormData();
			_sendFile.append("name", "Update Delivery Order BOD");
			const newFilesOnly = payloadDO?.files
				? payloadDO?.files?.filter((el) => !!el.newFile)
				: [];
			newFilesOnly.forEach((el, index) => {
				if (el.newFile) {
					const renamedFile = new File(
						[el.newFile],
						index + el.newFile.name,
						{
							type: el.newFile.type
						}
					);

					_sendFile.append("photos", renamedFile as File);
				}
			});

			const upload = _sendFile.getAll("photos");

			if (accurateDoId) {
				try {
					await updateDoAccurate({
						doId: accurateDoId,
						startLoadingTime: payloadDO?.accurateStartLoadingTime,
						endLoadingTime: payloadDO?.accurateEndLoadingTime,
						branchId: data?.data?.accurateData?.branchId,
						saleOrderItemId: data?.data?.accurateData?.detailItemId,
						quantity: Number(payloadDO?.quantity) as number
					});
				} catch (error) {
					console.log(error, "error updateDoAccurate");
				}
				// accurateData = result?.data?.r;
			}

			if (upload.length) {
				sendFile(_sendFile, {
					onSuccess: (res) => {
						const uploadFiles = payloadDO?.files?.filter(
							(el) => el.newFile
						);
						const pairResult = uploadFiles?.map(
							(el: ChangeFile, index) => {
								if (el.newFile) {
									const renamedFile = new File(
										[el.newFile],
										index + el.newFile.name,
										{
											type: el.newFile.type
										}
									);
									return renamedFile;
								}
								return el.newFile;
							}
						);

						const data = handleResponseFile(
							res.data,
							pairResult as unknown as File[]
						);

						const payload = data.map((el, index) => {
							return {
								...el,
								type: JSON.parse(JSON.stringify(uploadFiles))[
									index
								].type,
								id: JSON.parse(JSON.stringify(uploadFiles))[
									index
								].id
							};
						});
						const payloadDo = {
							loadingStart: payloadDO?.loadingStart
								? `${payloadDO?.date}:${payloadDO?.loadingStart}`
								: null,
							loadingEnd: payloadDO?.loadingEnd
								? `${payloadDO?.date}:${payloadDO?.loadingEnd}`
								: null,
							plantDeparture: payloadDO?.plantDeparture
								? `${payloadDO?.date}:${payloadDO?.plantDeparture}`
								: null,
							projectArrive: payloadDO?.projectArrive
								? `${payloadDO?.date}:${payloadDO?.projectArrive}`
								: null,
							pouringStart: payloadDO?.pouringStart
								? `${payloadDO?.date}:${payloadDO?.pouringStart}`
								: null,
							pouringEnd: payloadDO?.pouringEnd
								? `${payloadDO?.date}:${payloadDO?.pouringEnd}`
								: null,
							plantReturn: payloadDO?.plantReturn
								? `${payloadDO?.date}:${payloadDO?.plantReturn}`
								: null,
							plantArrive: payloadDO?.plantArrive
								? `${payloadDO?.date}:${payloadDO?.plantArrive}`
								: null,
							scheduleId: payloadDO?.scheduleId?.id as string,
							date: payloadDO?.date as string,
							quantity: Number(payloadDO?.quantity) as number,
							sealNumber: payloadDO?.sealNumber as string,
							driverId: payloadDO?.driver?.id as string,
							vehicleId: payloadDO?.truck?.id as string,
							status: payloadDO?.status?.valueStr,
							number: payloadDO?.number,
							doFiles: payload
						};
						console.log(
							payloadDo,
							"dikirim ke backend <<<<<<<<<<<<<<<<<<<"
						);

						mutate(payloadDo as PayloadCreateDO);
					},
					onError: (err: ApiError) => {
						dispatch(
							openAlert({
								body: err.response?.data.message,
								color: "danger"
							})
						);
					}
				});
			} else {
				const payloadDeliveryOrder = {
					loadingStart: payloadDO?.loadingStart
						? `${payloadDO?.date}:${payloadDO?.loadingStart}`
						: null,
					accurateEndLoadingTime: payloadDO?.accurateEndLoadingTime,
					accurateStartLoadingTime:
						payloadDO?.accurateStartLoadingTime,
					loadingEnd: payloadDO?.loadingEnd
						? `${payloadDO?.date}:${payloadDO?.loadingEnd}`
						: null,
					plantDeparture: payloadDO?.plantDeparture
						? `${payloadDO?.date}:${payloadDO?.plantDeparture}`
						: null,
					projectArrive: payloadDO?.projectArrive
						? `${payloadDO?.date}:${payloadDO?.projectArrive}`
						: null,
					pouringStart: payloadDO?.pouringStart
						? `${payloadDO?.date}:${payloadDO?.pouringStart}`
						: null,
					pouringEnd: payloadDO?.pouringEnd
						? `${payloadDO?.date}:${payloadDO?.pouringEnd}`
						: null,
					plantReturn: payloadDO?.plantReturn
						? `${payloadDO?.date}:${payloadDO?.plantReturn}`
						: null,
					plantArrive: payloadDO?.plantArrive
						? `${payloadDO?.date}:${payloadDO?.plantArrive}`
						: null,
					scheduleId: payloadDO?.scheduleId?.id as string,
					date: payloadDO?.date as string,
					quantity: Number(payloadDO?.quantity) as number,
					sealNumber: payloadDO?.sealNumber as string,
					driverId: payloadDO?.driver?.id as string,
					vehicleId: payloadDO?.truck?.id as string,
					status: payloadDO?.status?.valueStr,
					number: payloadDO?.number,
					mobilizationFee: payloadDO?.addCost?.mobilititation
						? parseIDR(payloadDO?.addCost?.mobilititation)
						: null,
					toll: payloadDO?.addCost?.toll
						? parseIDR(payloadDO?.addCost?.toll)
						: null,
					parking: payloadDO?.addCost?.park
						? parseIDR(payloadDO?.addCost?.park)
						: null,
					additionalPrice: payloadDO?.addCost?.extra
						? parseIDR(payloadDO?.addCost?.extra)
						: null,
					notes: payloadDO?.addCost?.notes ?? undefined,
					rejectedNotes: payloadDO?.rejectedNotes,
					actualDriverId: payloadDO?.actualDriver?.id
				} as PayloadCreateDO;

				payloadDeliveryOrder.accurateData = {
					...data?.data?.accurateData,
					startLoadingTime: payloadDO?.accurateStartLoadingTime,
					endLoadingTime: payloadDO?.accurateEndLoadingTime
				};

				mutate(payloadDeliveryOrder as PayloadCreateDO);
			}
		}
	};

	// customer accurate detail
	const {
		data: accurateCustomerDetails,
		isLoading: accurateIsLoading,
		isError: accurateIsError
	} = useQuery(
		["query-customer-details"],
		async () => {
			const res = await AccurateService.getAccurateCustomer({
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				id: data?.data.Project?.Customer?.customerAccurateId as number
			});
			return res;
		},
		{
			enabled:
				!!data?.data.Project?.Customer?.customerAccurateId &&
				!isAccurateDoDisabled,
			onError(err: any) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
				let message = "Accurate Error/ Tolong Login Ulang";

				if (err?.response?.data?.error?.data?.error) {
					message = `Accurate Error/ ${err?.response?.data?.error?.status} - ${err?.response?.data?.error?.data?.error}`;
					localStorage.removeItem("accurateCreds");
					// window.location.reload();
				}
				dispatch(
					openAlert({
						body: message,
						color: "danger"
					})
				);
			}
		}
	);

	if (isLoading || patchStatus.isLoading || createLoading || loadingUpload) {
		return <Loading />;
	}

	return (
		<>
			<div className="p-4 pt-6">
				<LinkDeliveryOrder
					isOpen={isCreateDO}
					setIsOpen={setIsCreateDO}
					refetch={refetch}
					doNumber={data?.data.number as string}
					woNumber={data?.data.WorkOrder.number as number}
					customerAccurateId={
						data?.data.Project.Customer
							?.customerAccurateId as number
					}
					customerAccurateName={
						(accurateCustomerDetails?.data?.name as string) ?? ""
					}
					customerName={
						data?.data?.Project?.Customer?.displayName as string
					}
					defaultCustomer={
						accurateCustomerDetails?.data
							? {
									id: accurateCustomerDetails?.data?.id,
									name: accurateCustomerDetails?.data?.name,
									mobilePhone:
										accurateCustomerDetails?.data
											?.mobilePhone,
									whatsappNo:
										accurateCustomerDetails?.data
											?.whatsappNo,
									customerNo:
										accurateCustomerDetails?.data
											?.customerNo,
									email: accurateCustomerDetails?.data?.email
							  }
							: undefined
					}
					scheduleId={data?.data?.Schedule.id}
					doId={data?.data.id as string}
				/>

				<div className="d-flex flex-row align-items-center mb-2">
					<div
						className="b-back-btn border mr-3"
						onClick={() => {
							if (localDialog.isOpen) {
								localDialog.onClose();
								return;
							}
							navigate(-1);
						}}
					>
						<ChevronLeft />
					</div>
					{localDialog.isOpen ? (
						<BForm
							forms={[
								{
									label: "",
									type: "text",
									value: payloadDO?.number,
									placeholder: "",
									disable: false,
									onChange: (e: any) => {
										setPayloadDO({
											...payloadDO,
											number: e.target.value
										});
									}
								}
							]}
						/>
					) : (
						<h1 className="mb-0 mr-2">{data?.data?.number}</h1>
					)}
					<div
						style={{
							display: "flex",
							// justifyContent: "space-between",
							flex: 1,
							alignItems: "center",
							marginLeft: "1rem",
							gap: "1rem"
						}}
					>
						{localDialog.isOpen ? (
							<>
								<BForm
									forms={[
										{
											label: "",
											type: "dropdown",
											value: payloadDO?.status,
											placeholder: "",
											disable: false,
											onChange: (e: any) => {
												setPayloadDO({
													...payloadDO,
													status: e
												});
											},
											options: [
												{
													valueStr: "SUBMITTED",
													labelStr: "SUBMITTED"
												},
												{
													valueStr: "WB_OUT",
													labelStr: "WB_OUT"
												},
												{
													valueStr: "ON_DELIVERY",
													labelStr: "ON_DELIVERY"
												},
												{
													valueStr: "RECEIVED",
													labelStr: "RECEIVED"
												},
												// {
												// 	valueStr: "AWAIT_WB_IN",
												// 	labelStr: "AWAIT_WB_IN"
												// },
												{
													valueStr: "ARRIVED",
													labelStr: "ARRIVED"
												},
												{
													valueStr: "FINISHED",
													labelStr: "FINISHED"
												},
												{
													valueStr: "REJECTED",
													labelStr: "REJECTED"
												},
												{
													valueStr: "CANCELLED",
													labelStr: "CANCELLED"
												}
											]
										}
									]}
								/>
							</>
						) : (
							<BStatus>{status}</BStatus>
						)}
						{payloadDO?.status &&
						(payloadDO.status.labelStr == "REJECTED" ||
							payloadDO?.status?.labelStr == "CANCELLED") &&
						localDialog.isOpen ? (
							<BForm
								forms={[
									{
										label: "",
										type: "text",
										value: payloadDO?.rejectedNotes,
										placeholder: "",
										disable: false,
										onChange: (e: any) => {
											setPayloadDO({
												...payloadDO,
												rejectedNotes: e.target.value
											});
										}
									}
								]}
							/>
						) : (
							payloadDO?.status &&
							(payloadDO.status.labelStr == "REJECTED" ||
								payloadDO?.status?.labelStr == "CANCELLED") && (
								<p className="mb-0 mr-2">
									Alasan {payloadDO?.status?.labelStr}:{" "}
									{payloadDO?.rejectedNotes}
								</p>
							)
						)}

						<div style={{ position: "absolute", right: "1.5rem" }}>
							{!data?.data.deliveryOrderAccurateId &&
								deliverOrderPermission &&
								deliverOrderPermission.update && (
									<Button
										size="sm"
										color="success"
										className="confirm-button approve-button"
										onClick={() => {
											setIsCreateDO(true);
										}}
									>
										Link
									</Button>
								)}

							{deliverOrderPermission &&
							deliverOrderPermission.update ? (
								<Button
									onClick={handleButton(
										localDialog.isOpen ? "SAVE" : "EDIT"
									)}
									size="sm"
									color="warning"
									className="confirm-button approve-button"
									// style={{
									// 	position: "absolute",
									// 	right: "1.5rem"
									// }}
								>
									{localDialog.isOpen ? "Simpan" : "Ubah"}
								</Button>
							) : null}
						</div>
					</div>
				</div>
				<div className="d-flex flex-column flex-lg-row">
					<div
						className="d-flex flex-column mb-3"
						style={{ flex: 0.7 }}
					>
						<BCard
							title="Ringkasan DO"
							data={summary}
							isEdit={localDialog.isOpen}
						/>
						<BCard
							className="mt-3"
							title="Update DO"
							data={updates}
							isEdit={localDialog.isOpen}
						/>
					</div>
					<div className="spacer-v" />
					<div className="d-flex flex-column" style={{ flex: 0.3 }}>
						<BCard title="Rincian Proyek" data={projectDetails} />
						<BCard
							className="mt-3"
							title="LKO"
							data={times}
							isEdit={localDialog.isOpen}
						/>
						{status === "FINISHED" && (
							<BCard
								className="mt-3"
								title="Biaya Tambahan"
								data={addCost}
								isEdit={localDialog.isOpen}
							/>
						)}
						<BCard
							className="mt-3"
							title="Dokumen"
							data={files.filter((v) => {
								return !usedFileTypes.includes(v.name);
							})}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default DODetail;
