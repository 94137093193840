import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";

const initialState = {
	isAccurateDisabled: true
};

export const settingSlice = createSlice({
	name: "setting",
	initialState,
	reducers: {
		setAccurateDisabled: (state, action: PayloadAction<boolean>) => {
			state.isAccurateDisabled = action.payload;
			return state;
		}
	}
});

export const { setAccurateDisabled } = settingSlice.actions;

export const selectCount = (state: RootState) => state.setting;

export default settingSlice.reducer;
