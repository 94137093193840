const updateQueryParams = (newParams: any) => {
	const queryParams = new URLSearchParams(location.search);

	Object.keys(newParams).forEach((key) => {
		if (newParams[key]) {
			queryParams.set(key, newParams[key]);
		} else {
			queryParams.delete(key);
		}
	});

	return queryParams;
};

export { updateQueryParams };
