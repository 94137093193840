import * as React from "react";
import { Dispatch, SetStateAction, useEffect } from "react";
// import { useLocalDialog } from "hooks";
// import LocalDialog from "../localDialog";
import FileUpload from "./FileUpload/FileUpload";
import FileList from "./FileList/FileList";

export default function MultipleFile({
	// refetch,
	listFiles,
	setFiles,
	uploadFile,
	setUploadFile,
	setListFiles
}: {
	// refetch: () => void;
	//triger
	listFiles: any;
	setFiles: any;
	uploadFile: any;
	setUploadFile: any;
	setListFiles: any;
}) {
	return (
		<div className="multiple">
			<p className="title">Hasil Produksi</p>
			<FileUpload
				files={listFiles}
				setFiles={setUploadFile}
				setListFiles={setListFiles}
			/>
			<FileList files={listFiles} setListFiles={setListFiles} />
			{listFiles && <p></p>}
		</div>
	);
}
