import React, { Ref, useEffect, useState } from "react";
import styles from "./index.module.scss";
import BMap from "components/B/GLMap";
import MarkerTM from "components/Marker/TM";
import MarkerBP from "components/Marker/BP";
import MarkerDestination from "components/Marker/Destination";
import { MapRef } from "react-map-gl";

interface IItem {
	lon: number;
	lat: number;
	label?: string;
	id?: string;
	color?: string;
	key?: string;
}

export default function LiveVehicleMap(props: any) {
	const [mapItems, setMapItems] = useState<IItem[]>([]);
	const [mapDestinations, setMapDestinations] = useState<IItem[]>([]);

	useEffect(() => {
		const destinations: any = {};
		const vehicles: IItem[] = [];
		for (const vehicle of props?.vehicles || []) {
			if (vehicle?.gpsShown) {
				vehicles.push(vehicle?.selectedGpsData);
				const project = vehicle?.do?.Project;
				if (project?.ShippingAddress) {
					const key = `${project?.Customer?.displayName}-${project?.ShippingAddress?.lat},${project?.ShippingAddress?.lon}`;
					if (!destinations[key]) {
						destinations[key] = {
							...project?.ShippingAddress,
							label: project?.Customer?.displayName,
							color: vehicle?.color,
							key
						};
					}
				}
			}
		}
		setMapDestinations(Object.values(destinations) as IItem[]);
		setMapItems([...vehicles, ...(Object.values(destinations) as IItem[])]);
	}, [props?.vehicles]);

	const onMapLoad = ({
		map,
		ref
	}: {
		map: any;
		ref: Ref<MapRef> | undefined;
	}) => {
		//
	};

	return (
		<BMap className={styles.mainContainer} items={mapItems} traffic={true}>
			{props?.vehicles?.map((tm: any) => {
				if (tm.isGps && tm.gpsShown) {
					const gpsData =
						(tm?.gpsData || [])[tm.selectedGpsType || tm.gpsType] ||
						tm?.selectedGpsData;
					if (
						gpsData?.lon &&
						gpsData?.lat &&
						!isNaN(gpsData?.lon) &&
						!isNaN(gpsData?.lat)
					) {
						return (
							<MarkerTM
								longitude={gpsData?.lon}
								latitude={gpsData?.lat}
								key={tm.internalId}
								direction={gpsData?.direction}
								label={tm?.internalId}
								labelGrey={
									!(
										gpsData?.status == "IDLE" ||
										gpsData?.status == "MOVING"
									)
								}
								iconGrey={gpsData?.status != "MOVING"}
							/>
						);
					}
				}
				return <></>;
			})}
			{props?.batchingPlants?.map((bp: IItem) => {
				return (
					<MarkerBP longitude={bp?.lon} latitude={bp?.lat} key={bp?.label} label={bp?.label} />
				);
			})}
			{mapDestinations.map((destination: any) => {
				if (destination?.lon && destination?.lat) {
					return (
						<MarkerDestination
							longitude={destination?.lon}
							latitude={destination?.lat}
							key={destination?.id}
							label={destination?.label}
							color={destination?.color}
							onMapLoad={onMapLoad}
						/>
					);
				}
				return <></>;
			})}
		</BMap>
	);
}
