import React, { useEffect, useMemo, useState } from "react";

// core components
import Header from "../../../components/Headers/Header";
import { useQuery } from "react-query";
import {
	Button,
	Card,
	CardBody,
	Container,
	Form,
	FormGroup,
	Input,
	Row
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useListSearchParams, useLocalDialog } from "hooks";
import { MTable } from "components";
import { InventoryService } from "api/axios";
import { openAlert } from "store/features/alert/AlertSlice";
import { useDispatch } from "react-redux";
import { useDebounce } from "use-debounce";
import CreateDriver from "./CreateDriver";
import { useAppSelector } from "store/hooks";
import { getRouteName, routeMapping } from "routes";

// Column BMTable
const driversColumn = [
	{
		name: "name",
		label: "Nama Driver",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "phone",
		label: "No. Telp",
		options: {
			filter: false,
			sort: false
		}
	}
];

export default function Drivers() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const permission = useAppSelector((state) => state.user.permission);

	const driverPermission = useMemo(() => {
		const routeName = getRouteName(location.pathname, routeMapping);

		if (routeName) {
			return permission[routeName];
		}
		return {};
	}, [location, permission]);

	const {
		page,
		setPage,
		size,
		setSize,
		date,
		setParams,
		clearParams,
		removeParams
	} = useListSearchParams();

	// states
	const [driversList, setDriversList] = useState<DriverTableData[]>([]);
	const [searchDriverName, setSearchDriverName] = useState<string>();
	const [searchQName] = useDebounce(searchDriverName, 500);
	const [totalItems, setTotalItems] = useState<number>();
	const [currentPage, setCurrentPage] = useState<number>();

	const [isCreateDriver, setIsCreateDriver] = useState(false);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const driverNameParam = queryParams.get("search?driver-name");
		if (driverNameParam) {
			setSearchDriverName(driverNameParam);
		} else {
			setSearchDriverName(undefined);
		}
	}, [location.search]);

	const { refetch, isLoading } = useQuery<ApiNewDrivers, ApiError>(
		["query-drivers", page, searchQName],
		async () => {
			return await InventoryService.getAllDrivers(page, 15, {
				search: searchQName
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const drivers = res.data;
				const mappedData = drivers?.map((data) => {
					return {
						id: data.id,
						name: data.name ?? "-",
						phone: data.phone ?? "-",
						userId: data.userId ?? "-"
					};
				});

				setDriversList(mappedData);
				setTotalItems(res.totalItems);
				const currPage = res.currentPage;
				if (currPage) {
					setCurrentPage(currPage);
				}
			},
			onError: (err) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const handleDriverNameSearch = (newValue: string) => {
		setSearchDriverName(newValue);

		const queryParams = new URLSearchParams();
		queryParams.set("page", "1");

		if (newValue) {
			queryParams.set("search?driver-name", newValue);
		}

		navigate({
			pathname: location.pathname,
			search: queryParams.toString()
		});
	};

	return (
		<>
			<Header />
			{/* Page content */}
			<Container
				style={{
					position: "relative",
					top: "-10rem"
				}}
				fluid
			>
				<Row>
					{isCreateDriver ? (
						<CreateDriver
							isOpen={isCreateDriver}
							setIsOpen={setIsCreateDriver}
							refetch={refetch}
						/>
					) : null}
					<div className="col">
						<Card>
							{/* Headers */}
							<CardBody>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between"
									}}
								>
									<div>
										<Form>
											<FormGroup
												style={{
													display: "flex",
													gap: "1rem"
												}}
											>
												<Input
													placeholder="Nama Driver"
													value={searchDriverName}
													onChange={(e) =>
														handleDriverNameSearch(
															e.target.value
														)
													}
												/>
											</FormGroup>
										</Form>
									</div>

									<div>
										{driverPermission &&
										driverPermission.create ? (
											<Button
												onClick={() => {
													setIsCreateDriver(true);
												}}
												size="sm"
												color="success"
												className="confirm-button approve-button"
											>
												Buat Driver
											</Button>
										) : null}
									</div>
								</div>

								{/* Line break */}
								<div className="break-line mt-3"></div>

								{/* Table Driver*/}
								<MTable
									tableTitle=""
									columns={driversColumn}
									tableData={driversList}
									totalItems={totalItems as number}
									currentPage={currentPage}
									selectableRowsHideCheckboxes={true}
									hideFilter
									isSearchVisible={false}
									setPage={(page) => {
										setPage(page);
									}}
									setRowsPerPage={(size) => {
										setSize(size);
									}}
									options={
										{
											// onRowClick(rowData, rowMeta) {
											// 	if (tableData && tableData.dataTable) {
											// 		const id =
											// 			tableData.dataTable[
											// 				rowMeta.rowIndex
											// 			]?.id;
											// 		if (id !== undefined) {
											// 			navigate(
											// 				`/database/vehicles/${id}`
											// 			);
											// 		}
											// 	}
											// }
										}
									}
								/>
							</CardBody>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
}
