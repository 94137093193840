import React, { useMemo, useEffect, useState } from "react";
import { Button, Container, Form, FormGroup, Input, Row } from "reactstrap";
// core components
import Header from "../../../components/Headers/Header";

import useListSearchParams from "../../../hooks/useQuery";
import { useQuery } from "react-query";
import { CommonService } from "../../../api/axios";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import CreateCustomer from "./CustomerCreate";
import { routeMapping, getRouteName } from "routes";
import { StatusChip } from "components";
import BMTable from "components/B/BMTable";
import MergeCustomer from "./MergeCustomer";

const custColumn = [
	{
		name: "no",
		label: "No",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "type",
		label: "Tipe Pelanggan",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				if (value === undefined) {
					return "-";
				} else {
					return <StatusChip value={value} />;
				}
			}
		}
	},
	{
		name: "name",
		label: "Nama Pelanggan",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "paymentType",
		label: "Tipe Pembayaran",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				return <StatusChip value={value} />;
			}
		}
	},
	{
		name: "NIK",
		label: "No. KTP",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "npwp",
		label: "No. NPWP",
		options: {
			filter: false,
			sort: false
		}
	}
];

const Customer = () => {
	const navigate = useNavigate();
	const [isMergeCustomerOpen, setIsMergeCustomerOpen] = useState(false);
	const [searchCustomerName, setSearchCustomerName] = useState<string>();
	const [searchQName] = useDebounce(searchCustomerName, 500);
	const [isCreateCustomer, setIsCreateCustomer] = useState(false);
	const [tableData, setTableData] = React.useState<
		Omit<ApiCustomers, "data">
	>({
		message: "",
		success: true,
		currentPage: 1,
		totalItems: 15,
		totalPages: 1,
		dataTable: []
	});
	const { page, setPage } = useListSearchParams();
	const location = useLocation();
	const permission = useAppSelector((state) => state.user.permission);
	const customerPermission = useMemo(() => {
		const routeName = getRouteName(location.pathname, routeMapping);

		if (routeName) {
			return permission[routeName];
		}
		return {};
	}, [location, permission]);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const customerNameParam = queryParams.get("search?customer-name");
		if (customerNameParam) {
			setSearchCustomerName(customerNameParam);
		} else {
			setSearchCustomerName(undefined);
		}
	}, [location.search]);

	const dispatch = useAppDispatch();
	const { isLoading, refetch } = useQuery<ApiCustomers, ApiError>(
		["query-customers", page, searchQName],
		async () => {
			return await CommonService.getAllCustomer(page, 15, {
				search: searchQName
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res.data.data.map((el, i) => ({
					id: el.id,
					values: [
						{
							objectKey: "id",
							value: el.id, //15 is size data, look axios service,
							type: "text",
							render: false
						},
						{
							objectKey: "no",
							value: i + 1 + 15 * (page - 1), //15 is size data, look axios service,
							type: "text"
						},
						{
							objectKey: "type",
							value:
								el.type === "COMPANY" ? "PERUSAHAAN" : el.type,
							type: "text"
						},
						{
							objectKey: "name",
							value: el.displayName,
							type: "text"
						},
						{
							objectKey: "paymentType",
							value:
								el.paymentType === null
									? "CASH"
									: el.paymentType,
							type: "text"
						},
						{
							objectKey: "NIK",
							value: el.nik === "0" || !el.nik ? "-" : el.nik,
							type: "text"
						},
						{
							objectKey: "npwp",
							value: el.npwp === "0" || !el.npwp ? "-" : el.npwp,
							type: "text"
						}
					]
				}));

				setTableData(() => ({
					...res,
					...res.data,
					dataTable: (data as DataTable[]) ?? []
				}));
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const handleCustomerNameSearch = (newValue: string) => {
		setSearchCustomerName(newValue);

		const queryParams = new URLSearchParams();
		queryParams.set("page", "1");

		if (newValue) {
			queryParams.set("search?customer-name", newValue);
		}

		navigate({
			pathname: location.pathname,
			search: queryParams.toString()
		});
	};

	// if (isLoading) {
	// 	return <Loading />;
	// }
	// console.log(customerPermission, "customerPermission");

	return (
		<>
			<Header />
			{/* Page content */}
			<Container
				style={{
					position: "relative",
					top: "-10rem"
				}}
				fluid
			>
				{/* Table */}
				<Row>
					{isCreateCustomer ? (
						<CreateCustomer
							isOpen={isCreateCustomer}
							setIsOpen={setIsCreateCustomer}
							refetch={refetch}
						/>
					) : null}
					{isMergeCustomerOpen ? (
						<MergeCustomer
							isOpen={isMergeCustomerOpen}
							setIsOpen={setIsMergeCustomerOpen}
							refetch={refetch}
						/>
					) : null}

					<div className="col">
						<BMTable
							tableTitle=""
							hideFilter
							header={
								<>
									{customerPermission &&
									customerPermission.create ? (
										<Button
											style={{ float: "right" }}
											onClick={() => {
												setIsCreateCustomer(true);
											}}
											size="sm"
											color="success"
											className="confirm-button approve-button"
										>
											Buat Pelanggan Baru
										</Button>
									) : null}

									<Button
										style={{ float: "right" }}
										size="sm"
										color="info"
										className="confirm-button approve-button"
										onClick={() => {
											setIsMergeCustomerOpen(true);
										}}
									>
										Gabungkan Pelanggan
									</Button>

									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											paddingBottom: "0.5rem"
										}}
									>
										<Form>
											<FormGroup
												style={{
													display: "flex",
													gap: "1rem"
												}}
											>
												<Input
													placeholder="NAMA PELANGGAN"
													value={searchCustomerName}
													onChange={(e) =>
														handleCustomerNameSearch(
															e.target.value
														)
													}
												/>
											</FormGroup>
										</Form>
									</div>
								</>
							}
							columns={custColumn}
							tableData={tableData.dataTable as DataTable[]}
							totalItems={tableData.totalItems as number}
							currentPage={tableData.currentPage as number}
							options={{
								onRowClick(rowData, rowMeta) {
									if (tableData && tableData.dataTable) {
										const id =
											tableData.dataTable[
												rowMeta.rowIndex
											]?.id;
										if (id !== undefined) {
											navigate(
												`/database/customers/${id}`
											);
										}
									}
								}
							}}
						/>
						{/* <BTable
							currentPage={page}
							totalPage={tableData.totalPages as number}
							totalItem={tableData.totalItems as number}
							columns={[
								{
									key: "no",
									value: "No"
								},
								{
									key: "customerType",
									value: "Customer Type"
								},
								{
									key: "customerName",
									value: "Customer Name"
								},
								{
									key: "paymentType",
									value: "Tipe"
								},
								{
									key: "ktp",
									value: "No KTP"
								},
								{
									key: "npwp",
									value: "No NPWP"
								}
							]}
							data={tableData.dataTable as DataTable[]}
							hideFilter
							header={
								<>
									{customerPermission &&
									customerPermission.create ? (
										<Button
											style={{ float: "right" }}
											onClick={() => {
												setIsCreateCustomer(true);
											}}
											size="sm"
											color="success"
											className="confirm-button approve-button"
										>
											Pelanggan Baru
										</Button>
									) : null}

									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											paddingBottom: "0.5rem"
										}}
									>
										<Form>
											<FormGroup
												style={{
													display: "flex",
													gap: "1rem"
												}}
											>
												<Input
													placeholder="Nama Pelanggan"
													onChange={(e) => {
														setSearchCustomerName(
															e.target.value
														);
														setPage(1);
													}}
												/>
											</FormGroup>
										</Form>
									</div>
								</>
							}
						/> */}
					</div>
				</Row>
			</Container>
		</>
	);
};

export default Customer;
