import React, { useState, useEffect } from "react";
import { Container, Row, Form, FormGroup, Input, Button } from "reactstrap";
import { useQuery } from "react-query";
import moment from "moment";
import Select from "react-select";
import { OrderService } from "api/axios";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { closeDialog, openDialog } from "store/features/dialog/DialogSlice";
import {
	dateStr,
	formatDate,
	formatLinkSheet,
	getStatusTrx,
	toSheet
} from "helper";
import { useListSearchParams } from "hooks";
import { BStatus, Header, Loading, StatusChip } from "components";
import { getPriceFormatted } from "helper/product";
import { openAlert } from "store/features/alert/AlertSlice";
import { useNavigate, useLocation } from "react-router-dom";
import BMTable from "components/B/BMTable";
import { useDebounce } from "use-debounce";
import CreatePo from "./CreatePo";
import { updateQueryParams } from "helper/filter";

const poColumn = [
	{
		name: "No PO",
		label: "No. PO",
		options: {
			sort: false,
			filter: false
		}
	},
	{
		name: "status",
		label: "Status",
		options: {
			sort: false,
			filter: false,
			customBodyRender: (value: any) => {
				return <BStatus>{value.status}</BStatus>;
			}
		}
	},
	{
		name: "No SPH",
		label: "No. SPH",
		options: {
			sort: false,
			filter: false
		}
	},
	{
		name: "clientName",
		label: "Pelanggan / PIC",
		options: {
			sort: false,
			filter: false
		}
	},
	{
		name: "projectName",
		label: "Proyek",
		options: {
			sort: false,
			filter: false
		}
	},
	{
		name: "address",
		label: "Kota",
		options: {
			sort: false,
			filter: false
		}
	},
	{
		name: "clientCategory",
		label: "Tipe Pelanggan",
		options: {
			sort: false,
			filter: false,
			customBodyRender: (value: any) => {
				if (value === undefined) {
					return "-";
				} else {
					return <StatusChip value={value} />;
				}
			}
		}
	},
	{
		name: "salesName",
		label: "Nama Sales",
		options: {
			sort: false,
			filter: false
		}
	},
	{
		name: "totalPrice",
		label: "Jumlah",
		options: {
			sort: false,
			filter: false
		}
	},
	{
		name: "dateIssued",
		label: "Tanggal Terima",
		options: {
			sort: false,
			filter: false
		}
	}
];

export default function PO() {
	const [tableData, setTableData] = useState<ApiPOs>({
		message: "",
		success: true,
		currentPage: 1,
		totalItems: 10,
		totalPages: 1,
		dataTable: []
	});

	const [showCreateForm, setShowCreateForm] = useState(false);
	const { bp } = useAppSelector((store) => store.batching);
	const { page, date, searchParams } = useListSearchParams();
	const navigate = useNavigate();
	const location = useLocation();
	const [searchPoNumber, setSearchPoNumber] = useState<string>();
	const [searchProjectName, setSearchProjectName] = useState<string>();
	const [searchCustomerPic, setSearchCustomerPic] = useState<string>();
	const [searchQPoNumber] = useDebounce(searchPoNumber, 500);
	const [searchQProjectName] = useDebounce(searchProjectName, 500);
	const [searchQCustomerPic] = useDebounce(searchCustomerPic, 500);
	const [selectedStatus, setSelectedStatus] = useState<string | undefined>(
		undefined
	);
	const salesId = searchParams.get("sales");

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const poNumberParam = queryParams.get("search?po-number");
		const projectNameParam = queryParams.get("search?project-name");
		const customerPicParam = queryParams.get("search?customer-pic");

		if (poNumberParam) {
			setSearchPoNumber(poNumberParam);
		} else {
			setSearchPoNumber(undefined);
		}
		if (projectNameParam) {
			setSearchProjectName(projectNameParam);
		} else {
			setSearchProjectName(undefined);
		}
		if (customerPicParam) {
			setSearchCustomerPic(customerPicParam);
		} else {
			setSearchCustomerPic(undefined);
		}
	}, [location.search]);

	// query summary
	useQuery<ApiPOs, ApiError>(
		["query-summary", date, salesId, bp?.valueStr],
		async () => {
			const sumDate = {
				...date
			};
			if (!date.endDate) {
				sumDate.startDate = moment()
					.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
					.toDate();
				sumDate.endDate = moment()
					.add(1, "days")
					.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
					.toDate();
			}
			return await OrderService.getAllPOs(1, 1, {
				date: sumDate,
				salesId: salesId as string,
				batchingPlantId: bp?.valueStr
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				setTableData({
					...tableData,
					summary: res.data?.totalItems
				});
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	// query table
	const { refetch: RefectAllPo } = useQuery<ApiPOs, ApiError>(
		[
			"query-po",
			searchQPoNumber,
			searchQProjectName,
			searchQCustomerPic,
			selectedStatus,
			page,
			date,
			salesId,
			bp?.valueStr
		],
		async () => {
			return await OrderService.getAllPOs(page, 15, {
				poNumber: searchQPoNumber,
				projectName: searchQProjectName,
				date,
				salesId: salesId as string,
				batchingPlantId: bp?.valueStr,
				customerName: searchQCustomerPic,
				status: selectedStatus
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res.data?.data.map((el, index) => {
					let cityName = "-";
					const project = el?.Project;
					if (project?.BillingAddress) {
						if (project?.BillingAddress?.Postal?.City) {
							cityName = project?.BillingAddress.Postal?.City
								.name as string;
						}
					}
					if (project?.LocationAddress) {
						if (project?.LocationAddress?.Postal?.City) {
							cityName = project?.LocationAddress.Postal?.City
								.name as string;
						}
					}
					if (project?.ShippingAddress) {
						if (project?.ShippingAddress?.Postal?.City) {
							cityName = project?.ShippingAddress?.Postal?.City
								.name as string;
						}
					}
					return {
						id: el.id,
						values: [
							{
								objectKey: "id",
								value: index + 1,
								type: "text",
								render: false
							},
							// {
							// 	objectKey: "no",
							// 	value: (index + 1) + (15 * (page - 1)), //15 is size data, look axios service,
							// 	type: "text",
							// },
							{
								objectKey: "No PO",
								value: el.customerNumber
									? el.customerNumber == ""
										? "-"
										: el.customerNumber
									: "-",
								type: "text"
							},
							{
								objectKey: "status",
								value: el.status
									? {
											status: el.status,
											data: el
									  }
									: "-",
								type: "status"
							},
							{
								objectKey: "No SPH",
								value: el?.QuotationLetter?.number ?? "-",
								type: "text"
							},
							{
								objectKey: "clientName",
								value:
									el.Project?.Customer?.displayName ??
									el.Project?.mainPic.name,
								type: "text"
							},
							{
								objectKey: "projectName",
								value: el.Project?.name ?? "-",
								type: "text"
							},
							// {
							// 	objectKey: "clientPhone",
							// 	value: el.Project.mainPic?.phone,
							// 	type: "text",
							// },
							{
								objectKey: "address",
								value: cityName,
								type: "text"
							},
							{
								objectKey: "clientCategory",
								value:
									el?.Project?.Customer?.type === "COMPANY"
										? "PERUSAHAAN"
										: el?.Project?.Customer?.type,
								type: "customerType"
							},
							{
								objectKey: "salesName",
								value: el.Project?.User?.fullName ?? "-",
								type: "text"
							},
							{
								objectKey: "totalPrice",
								value:
									`Rp ${getPriceFormatted(el.totalPrice)}` ??
									"-",
								type: "text",
								align: "end"
							},
							{
								objectKey: "dateIssued",
								value: formatDate(el.createdAt),
								type: "text"
							}
							// {
							// 	objectKey: "files",
							// 	value: el.QuotationLetterFiles,
							// 	type: "files",
							// },
						]
					};
				});

				setTableData(() => ({
					...tableData,
					...res,
					dataTable: (data as DataTable[]) ?? []
				}));
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const handlePoNumberSearch = (newValue: string) => {
		setSearchPoNumber(newValue);
		const queryParams = updateQueryParams({
			"search?po-number": newValue,
			page: "1"
		});

		navigate({
			pathname: location.pathname,
			search: queryParams.toString()
		});
	};

	const handleProjectNameSearch = (newValue: string) => {
		setSearchProjectName(newValue);

		const queryParams = updateQueryParams({
			"search?project-name": newValue,
			page: "1"
		});
		navigate({
			pathname: location.pathname,
			search: queryParams.toString()
		});
	};

	const handleCustomerPicSearch = (newValue: string) => {
		setSearchCustomerPic(newValue);

		const queryParams = updateQueryParams({
			"search?customer-pic": newValue,
			page: "1"
		});

		navigate({
			pathname: location.pathname,
			search: queryParams.toString()
		});
	};

	const handleStatusChange = (newValue: any) => {
		setSelectedStatus(newValue?.value);

		const queryParams = updateQueryParams({
			"search?status": newValue,
			page: "1"
		});

		navigate({
			pathname: location.pathname,
			search: queryParams.toString()
		});
	};

	const { refetch, isLoading: loadingSheet } = useQuery<ApiPOs, Error>(
		["query-download-visitation", date, bp?.valueStr],
		async () => {
			if (!date.endDate) {
				date.endDate = date.startDate;
			}
			return await OrderService.getAllPOs(1, 100, {
				date,
				salesId: salesId as string,
				batchingPlantId: bp?.valueStr
			});
		},
		{
			enabled: false,
			refetchOnWindowFocus: false
		}
	);

	const dispatch = useAppDispatch();
	const downloadSheet = () => {
		refetch({}).then((res) => {
			if (!res.data || (res?.data?.data?.data?.length ?? 0) < 1) {
				dispatch(
					openDialog({
						header: "Tidak ada laporan",
						body: `Tidak ada laporan di tanggal ${dateStr(
							date
						)}. Silahkan pilih tanggal lain`,
						onClick: "close"
					})
				);
				return;
			}

			if (res.data.data?.data) {
				const dataSheet = res.data.data.data.map((el, i) => {
					const sphFile =
						el.QuotationLetter?.QuotationLetterFiles?.find(
							(el) => el?.type === "LETTER"
						);
					const poFile = el.PurchaseOrderDocs?.find(
						(el) => el.type === "CUSTOMER"
					);
					return {
						// No: i + 1,
						"Nama Sales": el.Project?.User.fullName,
						"No SPH": el.QuotationLetter?.number,
						"No PO": el.customerNumber,
						"Nama Proyek": el.Project?.name,
						// "Tahap Proyek": el.Project.stage,
						"Nama Pelanggan":
							el.Project?.Customer?.displayName ??
							el.Project?.mainPic?.name,
						"Kontak PIC": `+62${el.Project?.mainPic?.phone}`,
						Alamat: `http://maps.google.com/maps?z=3&t=m&q=loc:${el.Project?.ShippingAddress?.lat}+${el.Project?.ShippingAddress?.lon}`,
						Status: getStatusTrx(el.status),
						SPH: sphFile?.File.url ?? "-",
						PO: poFile?.File.url ?? "-",
						"Harga Total": `Rp ${getPriceFormatted(el.totalPrice)}`,
						"Tanggal Terbit": formatDate(el.createdAt)
					};
				});

				toSheet(dataSheet, date, "PO");
				dispatch(closeDialog());
			}
		});
	};

	const onDownload = () => {
		dispatch(
			openDialog({
				header: "Unduh laporan",
				body: `Apakah kamu yakin ingin mengunduh laporan ${dateStr(
					date
				)}?`,
				onClick: downloadSheet
			})
		);
	};

	const statusOptions = [
		{ value: "APPROVED", label: "Disetujui" },
		{ value: "CONFIRMED", label: "Diterbitkan" },
		{ value: "SUBMITTED", label: "Diajukan" },
		{ value: "DECLINED", label: "Ditolak" }
	];

	// if (isLoading || loadingSheet) {
	// 	return <Loading />;
	// }

	return (
		<>
			<Header data={tableData.summary} date={date} show />
			<Container className="mt--7" fluid>
				{/* Table */}
				<Row>
					{showCreateForm ? (
						<CreatePo
							isOpen={showCreateForm}
							setIsOpen={setShowCreateForm}
							refetch={RefectAllPo}
						/>
					) : null}
					<div className="col">
						<BMTable
							tableTitle=""
							onDownload={onDownload}
							header={
								<>
									<Button
										style={{ float: "right" }}
										size="sm"
										color="success"
										className="confirm-button approve-button"
										onClick={() => {
											setShowCreateForm(true);
										}}
									>
										Buat PO
									</Button>
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											paddingBottom: "0.5rem"
										}}
									>
										<Form>
											<FormGroup
												style={{
													display: "flex",
													gap: "1rem"
												}}
											>
												<Input
													placeholder="NO. PO"
													value={searchPoNumber}
													onChange={(e) =>
														handlePoNumberSearch(
															e.target.value
														)
													}
												/>
												<Input
													placeholder="NAMA PROYEK"
													value={searchProjectName}
													onChange={(e) =>
														handleProjectNameSearch(
															e.target.value
														)
													}
												/>
												<Input
													placeholder="PELANGGAN / PIC"
													value={searchCustomerPic}
													onChange={(e) =>
														handleCustomerPicSearch(
															e.target.value
														)
													}
												/>
												<Select
													name="purchaseOrderStatus"
													placeholder="Status"
													isClearable
													value={statusOptions.find(
														(option) =>
															option.value ===
															selectedStatus
													)}
													options={statusOptions}
													// onChange={(
													// 	selectedOption
													// ) => {
													// 	setSelectedStatus(
													// 		selectedOption
													// 			? selectedOption.value
													// 			: undefined
													// 	);

													// 	const queryParams =
													// 		new URLSearchParams();
													// 	queryParams.set(
													// 		"page",
													// 		"1"
													// 	);

													// 	if (searchPoNumber) {
													// 		queryParams.set(
													// 			"search?po-number",
													// 			searchPoNumber
													// 		);
													// 	}

													// 	if (searchProjectName) {
													// 		queryParams.set(
													// 			"search?project-name",
													// 			searchProjectName
													// 		);
													// 	}

													// 	if (searchCustomerPic) {
													// 		queryParams.set(
													// 			"search?customer-pic",
													// 			searchCustomerPic
													// 		);
													// 	}

													// 	if (selectedOption) {
													// 		queryParams.set(
													// 			"search?status",
													// 			selectedOption.value
													// 		);
													// 	}

													// 	navigate({
													// 		pathname:
													// 			location.pathname,
													// 		search: queryParams.toString()
													// 	});
													// }}
													onChange={
														handleStatusChange as any
													}
													getOptionLabel={(option) =>
														option.label as string
													}
													getOptionValue={(option) =>
														option.value as string
													}
													styles={{
														container: (
															provided
														) => ({
															...provided,
															width: "100%"
														}),
														control: (
															provided
														) => ({
															...provided,
															width: "100%"
														})
													}}
												/>
											</FormGroup>
										</Form>
									</div>
								</>
							}
							filterSales
							columns={poColumn}
							tableData={tableData.dataTable as DataTable[]}
							totalItems={tableData.data?.totalItems as number}
							currentPage={tableData.data?.currentPage as number}
							options={{
								onRowClick(rowData, rowMeta) {
									if (tableData && tableData.dataTable) {
										const id =
											tableData.dataTable[
												rowMeta.rowIndex
											]?.id;
										if (id !== undefined) {
											// navigate(`/order/delivery-orders/${item.id}`);/
											navigate(
												`/order/purchase-orders/${id}`
											);
										}
									}
								}
							}}
						/>
						{/* <BTable
							currentPage={page}
							totalPage={tableData.data?.totalPages as number}
							totalItem={tableData.data?.totalItems as number}
							columns={[
								// {
								// 	key: "id",
								// 	value: "No",
								// },
								{
									key: "customerNumber",
									value: "No PO"
								},
								{
									key: "status",
									value: "Status"
								},
								{
									key: "sphNumber",
									value: "No SPH"
								},
								{
									key: "clientName",
									value: "PT / PIC"
								},
								{
									key: "projectName",
									value: "Proyek"
								},
								// {
								// 	key: "clientPhone",
								// 	value: "Client's Phone Number",
								// },
								{
									key: "address",
									value: "Kota"
								},
								{
									key: "clientCategory",
									value: "Tipe"
								},
								{
									key: "salesName",
									value: "Sales"
								},
								{
									key: "totalPrice",
									value: "Total",
									align: "end"
								},
								{
									key: "dateIssued",
									value: "Tanggal Terima"
								}
								// {
								// 	key: "files",
								// 	value: "Files",
								// },
							]}
							data={tableData.dataTable as DataTable[]}
							onDownload={onDownload}
							filterSales
						/> */}
					</div>
				</Row>
			</Container>
		</>
	);
}
