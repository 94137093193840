import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import { useQuery } from "react-query";
import Header from "../../../components/Headers/Header";
import BTable from "../../../components/B/table";
import useListSearchParams from "../../../hooks/useQuery";
import { InventoryService } from "../../../api/axios";
import Loading from "../../../components/B/loading";
import { useAppDispatch } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import BMTable from "components/B/BMTable";

const catColumn = [
	{
		name: "no",
		label: "No",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "Category Name",
		label: "Nama Kategori",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "Category Code",
		label: "Kode Kategori",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "Sub Category Name",
		label: "Nama Sub Kategori",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "Sub Category Code",
		label: "Kode Sub Kategori",
		options: {
			filter: false,
			sort: false
		}
	}
];

interface categoryType {
	id: string;
	categoryName: string;
	categoryCode: string;
	subCatName: string;
	subCatCode: string;
}

export default function Category() {
	const { page } = useListSearchParams();
	const [tableData, setTableData] = React.useState<
		Omit<ApiCategories, "data">
	>({
		message: "",
		success: true,
		currentPage: 1,
		totalItems: 10,
		totalPages: 1,
		dataTable: []
	});

	const dispatch = useAppDispatch();
	const { isLoading } = useQuery<ApiCategories, ApiError>(
		["query-categories", page],
		async () => {
			return await InventoryService.getAllCategories(page);
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const categoryContainer: categoryType[] = [];
				res.data.forEach((cat, index) => {
					const defaultValue: categoryType = {
						id: "-",
						categoryName: "-",
						categoryCode: "-",
						subCatName: "-",
						subCatCode: "-"
					};
					if (cat.id) {
						defaultValue.id = cat.id;
					}
					if (cat.display_name) {
						defaultValue.categoryName = cat.display_name;
					}
					if (cat.name) {
						defaultValue.categoryCode = cat.name;
					}
					if (cat.Parent) {
						if (cat.Parent.display_name) {
							defaultValue.subCatName = cat.Parent.display_name;
						}
						if (cat.Parent.name) {
							defaultValue.subCatCode = cat.Parent.name;
						}
					}
					categoryContainer.push(defaultValue);
				});

				const data = categoryContainer.map((el, index) => ({
					id: `${el.id}`,
					values: [
						{
							objectKey: "id",
							value: el.id,
							type: "text",
							render: false
						},
						{
							objectKey: "no",
							value: index + 1 + 15 * (page - 1), //15 is size data, look axios service,
							type: "text"
						},
						{
							objectKey: "Category Name",
							value: el.categoryName,
							type: "text"
						},
						{
							objectKey: "Category Code",
							value: el.categoryCode,
							type: "text"
						},
						{
							objectKey: "Sub Category Name",
							value: el.subCatName,
							type: "text"
						},
						{
							objectKey: "Sub Category Code",
							value: el.subCatCode,
							type: "text"
						}
					]
				}));

				setTableData(() => ({
					...res,
					dataTable: (data as DataTable[]) ?? []
				}));
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<>
			<Header />
			<Container
				style={{
					position: "relative",
					top: "-10rem"
				}}
				fluid
			>
				{/* Table */}
				<Row>
					<div className="col">
						<BMTable
							tableTitle=""
							columns={catColumn}
							tableData={tableData.dataTable as DataTable[]}
							totalItems={tableData.totalItems as number}
							currentPage={tableData.currentPage as number}
						/>

						{/* <BTable
							currentPage={page}
							totalPage={tableData.totalPages as number}
							totalItem={tableData.totalItems as number}
							columns={[
								{
									key: "no",
									value: "No"
								},
								{
									key: "name",
									value: "Category Name"
								},
								{
									key: "category",
									value: "Category Code"
								},
								{
									key: "sub",
									value: "Sub Category Name"
								},
								{
									key: "price",
									value: "Sub Category Code"
								}
							]}
							data={tableData.dataTable as DataTable[]}
							disabled
						/> */}
					</div>
				</Row>
			</Container>
		</>
	);
}
