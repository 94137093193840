import React, { Dispatch, SetStateAction, useEffect } from "react";
import { InventoryService } from "api/axios";
import { useLocalDialog } from "hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import { useMutation } from "react-query";
import { useAppDispatch } from "store/hooks";
import * as Yup from "yup";
import { LocalDialog } from "components";
import { Formik, Form } from "formik";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import LoadingButton from "@mui/lab/LoadingButton";

const CreateDriverSchema = Yup.object().shape({
	name: Yup.string().required("Nama Driver Wajib Diisi"),
	phone: Yup.string().required("Nomor WhatsApp Wajib Diisi")
});

export default function CreateDriver({
	isOpen,
	setIsOpen,
	refetch
}: {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	refetch: () => void;
}) {
	const { localDialog } = useLocalDialog();
	const dispatch = useAppDispatch();

	const { mutateAsync: createDriver } = useMutation<
		ApiResponseDriver,
		ApiError,
		PayloadDriver,
		ApiError
	>(
		async (data: PayloadDriver) => {
			const res = await InventoryService.createDriver(data);
			return res;
		},
		{
			onError: (err) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		}
	}, [isOpen]);

	return (
		<LocalDialog
			isOpen={localDialog.isOpen}
			backdrop={true}
			header="Buat Driver Baru"
			onClose={() => {
				localDialog.onClose();
				setTimeout(() => {
					setIsOpen(false);
				}, 500);
			}}
			rightBtnTitle="Edit"
			leftBtnTitle="Batalkan"
		>
			<>
				<Formik
					initialValues={{
						name: "",
						phone: ""
					}}
					validationSchema={CreateDriverSchema}
					onSubmit={async (values) => {
						try {
							await createDriver({
								name: values.name,
								phone: values.phone
							});
							setIsOpen(false);
							dispatch(
								openAlert({
									body: "Berhasil Menambahkan Driver",
									color: "success"
								})
							);
							refetch();
						} catch (error) {
							console.log(error, "error create driver");

							dispatch(
								openAlert({
									body: "Gagal Menambahkan Driver",
									color: "danger"
								})
							);
						}
					}}
				>
					{({ errors, handleChange, isSubmitting, values }) => {
						return (
							<Form>
								<FormGroup>
									<Label
										htmlFor="displayName"
										style={{ marginTop: "1rem" }}
									>
										Nama Pelanggan
									</Label>
									<Input
										name="name"
										invalid={!!errors.name}
										onChange={handleChange}
										value={values.name}
										placeholder="Nama Driver"
									/>
									<FormFeedback>{errors?.name}</FormFeedback>

									<Label
										htmlFor="displayName"
										style={{ marginTop: "1rem" }}
									>
										No. Telp
									</Label>
									<Input
										name="phone"
										invalid={!!errors.phone}
										onChange={handleChange}
										value={values.phone}
										placeholder="Nomor WhatsApp"
									/>
									<FormFeedback>{errors?.phone}</FormFeedback>
								</FormGroup>

								<div className="modal-custom-footer">
									<LoadingButton
										color="error"
										onClick={() => {
											localDialog.onClose();
											setTimeout(() => {
												setIsOpen(false);
											}, 500);
										}}
										loading={isSubmitting}
										disabled={isSubmitting}
									>
										<span
											style={{
												textTransform: "capitalize"
											}}
										>
											Batal
										</span>
									</LoadingButton>
									<LoadingButton
										color="error"
										variant="contained"
										type="submit"
										loading={isSubmitting}
										disabled={isSubmitting}
										sx={{
											backgroundColor: "red",
											color: "white"
										}}
									>
										<span
											style={{
												textTransform: "capitalize"
											}}
										>
											Buat
										</span>
									</LoadingButton>
								</div>
							</Form>
						);
					}}
				</Formik>
			</>
		</LocalDialog>
	);
}
