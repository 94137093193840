import BCard from "components/B/card";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { GpskuService, InventoryService, ProductivityService } from "api/axios";
import { Button, Card, CardBody, CardTitle, Table } from "reactstrap";
import { ChevronLeft } from "react-bootstrap-icons";
import BStatus from "components/B/status";
import { BTracker, Loading, LocalDialog } from "components";
import { useAppDispatch } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import BForm from "components/B/form";
import StateManagedSelect from "react-select/dist/declarations/src/stateManager";
import LoadingButton from "@mui/lab/LoadingButton";

const VehicleDetail = () => {
	const navigate = useNavigate();
	const params = useParams();
	const id = params?.id;
	const [projectDetails, setProjectDetails] = React.useState<any[]>([]);
	const [data, setData] = React.useState<VehicleResponse | null>(null);
	// const [files, setFiles] = React.useState<any[]>([]);
	const [summary, setSummary] = React.useState<any[]>([]);
	const [title, setTitle] = React.useState<string>("");
	const [products, setProducts] = React.useState<any[]>([]);
	const [status, setStatus] = React.useState<string>("");
	const [trackerData, setTrackerData] = React.useState<ApiGpsku | null>(null);
	const dispatch = useAppDispatch();
	const { refetch } = useQuery<{ data: VehicleResponse }, ApiError>(
		["query-vehicle"],
		async () => {
			return await InventoryService.getOneVehicle(id ?? "");
		},
		{
			enabled: true,
			onSuccess: (res) => {
				setTitle(
					`${res?.data?.internalId ?? res?.data?.internal_id} / ${res?.data?.plateNumber ?? res?.data?.plate_number
					}`
				);
				setStatus(res?.data?.status);

				try {
					const projectDetails = [
						{
							name: "Tipe Kendaraan",
							type: "chips",
							data: [
								{
									text: res?.data?.type
								}
							]
						},
						{
							name: "No. Polisi",
							data:
								res?.data?.plateNumber ??
								res?.data?.plate_number
						},
						{
							name: "No. TM",
							data:
								res?.data?.internalId ?? res?.data?.internal_id
						},
						{
							name: "GPS",
							type: "boolean",
							data: res?.data?.isGps ?? res?.data?.is_gps
						},
						{
							name: "Tipe GPS",
							data: res?.data?.gpsType ?? "-"
						},
						{
							name: "GPS ID",
							data: res?.data?.deviceId ?? "-"
						}
					];
					setPayloadDO({
						device_id: `${res?.data?.deviceId}`,
						internalId: res?.data?.internalId,
						isGps: res?.data?.isGps,
						plateNumber: res?.data?.plateNumber,
						status: res?.data?.status,
						type: res?.data?.type,
						gpsType: res?.data?.gpsType
							? {
								labelStr: res?.data?.gpsType,
								valueStr: res?.data?.gpsType
							}
							: { labelStr: "GPS KU", valueStr: "GPS_KU" }
					});
					setProjectDetails(projectDetails);
				} catch (e) {
					//
				}
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const [payloadDO, setPayloadDO] = React.useState<IVehiclePayload>({
		internalId: "",
		isGps: false,
		plateNumber: "",
		status: "",
		type: "",
		gpsType: "",
		device_id: ""
	});

	const forms: ElementForm[] = React.useMemo(() => {
		const _form: ElementForm[] = [
			{
				label: "Internal Id",
				type: "text",
				value: payloadDO?.internalId,
				onChange: (e: any) =>
					setPayloadDO({
						...payloadDO,
						internalId: e.target.value
					}),
				placeholder: "Masukan Internal Id",
				required: true
			},
			{
				label: "No. Polisi",
				type: "text",
				value: payloadDO?.plateNumber,
				onChange: (e: any) =>
					setPayloadDO({
						...payloadDO,
						plateNumber: e.target.value
					}),
				placeholder: "Masukan Plate Number",
				required: true
			},
			{
				label: "GPS",
				type: "switch",
				value: payloadDO?.isGps,
				onChange: (e: any) =>
					setPayloadDO({
						...payloadDO,
						isGps: !payloadDO.isGps,
						gpsType: "",
						device_id: "",
					})
			}
		];

		if (payloadDO.isGps) {
			_form.push({
				label: "Tipe Gps",
				type: "dropdown",
				value: payloadDO?.gpsType,
				onChange: (e: any) =>
					setPayloadDO({
						...payloadDO,
						gpsType: e
					}),
				placeholder: "Pilih Tipe",
				required: true,
				options: [
					{
						valueStr: "HINO",
						labelStr: "HINO"
					},
					{
						valueStr: "GPS_KU",
						labelStr: "GPS KU"
					},
					{
						valueStr: "PROVIEW",
						labelStr: "PROVIEW"
					}
				]
			});
			_form.push({
				label: "Nomor GPS",
				type: "text",
				value: payloadDO?.device_id,
				onChange: (e: any) =>
					setPayloadDO({
						...payloadDO,
						device_id: e.target.value
					}),
				placeholder: "Masukan Device ID",
				required: true
			});
		}

		return _form;
	}, [data, payloadDO]);

	const handleButton = (type: string) => () => {
		if (type === "EDIT") {
			localDialog.onOpen();
		}

		if (type === "SUBMIT") {
			if (!payloadDO.internalId || !payloadDO.plateNumber) {
				dispatch(
					openAlert({
						body: "Semua field harus diisi",
						color: "danger"
					})
				);
				return;
			}

			if (!payloadDO.isGps) {
				setPayloadDO({
					...payloadDO,
					gpsType: "",
					device_id: "",
				});

				const payloadToSend = {
					...payloadDO,
					gpsType: "-",
					deviceId: "-",
				};

				mutate(payloadToSend);
			} else if (!payloadDO.gpsType || !payloadDO.device_id) {
				dispatch(
					openAlert({
						body: "Semua field harus diisi",
						color: "danger"
					})
				);
				return;
			} else {
				const payloadToSend = {
					...payloadDO,
					gpsType: payloadDO.gpsType?.valueStr,
					deviceId: payloadDO.device_id,
				};

				mutate(payloadToSend);
			}

		}

	};

	const [localDialog, setLocalDialog] = React.useState({
		isOpen: false,
		onClose: () => setLocalDialog({ ...localDialog, isOpen: false }),
		onOpen: () => setLocalDialog({ ...localDialog, isOpen: true })
	});

	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		} else {
			localDialog.onClose();
		}
	}, [isOpen]);

	const { mutate, isLoading } = useMutation(
		async (data: IVehiclePayload) => {
			return await InventoryService.updateVehicle(id as string, data);
		},
		{
			onSuccess: () => {
				localDialog.onClose();
				refetch();
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	if (isLoading) return <Loading />;

	return (
		<div className="p-4 pt-6">
			<div className="d-flex flex-row align-items-center mb-2">
				<div
					className="b-back-btn border mr-3"
					onClick={() => {
						navigate(-1);
					}}
				>
					<ChevronLeft />
				</div>
				<h1 className="mb-0 mr-2">{title}</h1>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						flex: 1,
						alignItems: "center"
					}}
				>
					<BStatus>{status}</BStatus>
					<div>
						<Button
							onClick={handleButton("EDIT")}
							size="sm"
							color="warning"
							className="confirm-button approve-button"
						>
							Ubah
						</Button>
					</div>
				</div>
			</div>
			<div className="d-flex flex-column flex-lg-row">
				<div className="d-flex flex-column mb-3" style={{ flex: 0.7 }}>
					<Card className="">
						<CardBody>
							<CardTitle tag="h5" style={{ margin: 0 }}>
								Pelacakan
							</CardTitle>
							<div className="break-line" />
							{/* <BTracker /> */}
						</CardBody>
					</Card>
				</div>
				<div className="spacer-v" />
				<div className="d-flex flex-column" style={{ flex: 0.3 }}>
					<BCard title="Rincian Kendaraan" data={projectDetails} />
				</div>
			</div>

			<LocalDialog
				isOpen={localDialog.isOpen}
				backdrop={"static"}
				// body={<BForm forms={forms} />}
				header="Ubah Kendaraan"
				onClose={() => {
					localDialog.onClose();
					setIsOpen(false);
				}}
			>
				<BForm forms={forms} />
				<div className="modal-custom-footer">
					<LoadingButton
						color="error"
						onClick={() => {
							localDialog.onClose();
							setIsOpen(false);
						}}
						// loading={loadingCreate}
						// disabled={loadingCreate}
					>
						<span style={{ textTransform: 'capitalize' }}>Batal</span>
					</LoadingButton>
					<LoadingButton
						color="warning"
						variant="contained"
						type="submit"
						onClick={handleButton("SUBMIT")}
						// loading={loadingCreate}
						// disabled={loadingCreate}
					// sx={{ backgroundColor: "red", color: "white" }}
					>
						<span style={{ textTransform: 'capitalize' }}>Ubah</span>
					</LoadingButton>
				</div>
			</LocalDialog>
		</div>
	);
};

export default VehicleDetail;
