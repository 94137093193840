import { getBatchingPlantColor } from "helper";
import React from "react";
import { Badge } from "reactstrap";

interface IProps {
	children: string;
}

const BPlantColor = (props: IProps) => {
	const { children } = props;
	return (
		<Badge color="null" style={{ ...getBatchingPlantColor(children) }}>
			{children}
		</Badge>
	);
};

export default BPlantColor;
