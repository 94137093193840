import React from "react";
import { useLocation } from "react-router-dom";
import { Badge, Card, CardBody } from "reactstrap";

interface IProps {
	data?: any;
}

const BCompetitor = (props: IProps) => {
	const { data } = props;

	return (
		<Card className="b-competitor" style={{ marginBottom: "10px" }}>
			<CardBody>
				<p className="b-competitor-name">{data?.name}</p>
				<div className="b-competitor-data mt-2">
					<p>MOU: {data?.mou}</p>
					<p>Exclusive: {data?.exclusive}</p>
					<p>Kendala: {data?.problem}</p>
					<p>Harapan: {data?.hope}</p>
				</div>
			</CardBody>
		</Card>
	);
};

export default BCompetitor;
