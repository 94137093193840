import React from "react";

import "split-pane-react/esm/themes/default.css";
import styles from "./index.module.scss";
const extrasUrl = process.env.REACT_APP_EXTRAS;

const SkuVue = () => {
	return (
		<div className={styles.mainContainer}>
			<iframe className={styles.iframe} src={`${extrasUrl}/sku`}></iframe>
		</div>
	);
};

export default SkuVue;
