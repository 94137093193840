import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { LocalDialog } from "components";
import { useLocalDialog } from "hooks";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { useMutation, useQuery } from "react-query";
import { CommonService, OrderService } from "api/axios";
import { useAppDispatch } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import { useParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDebounce } from "use-debounce";
import Select from "react-select";
import { useMemo } from "react";

const EditUserSchema = Yup.object().shape({
	// displayName: Yup.string(),
	// .min(4, "Minimum 4 karakter")
	// .max(50, "Terlalu Panjang")
	// .required("Dibutuhkan"),
	// type: Yup.string(),
	// .required("Dibutuhkan"),
	// nik: Yup.string(),
	// .required("NIK Di butuhkan"),
	// .matches(/^\d{16}$/, "NIK harus 16 digit"),
	// npwp: Yup.string(),
	// .min(15, "NPWP minimal 15 karakter")
	// .required("NPWP Dibutuhkan"),
	email: Yup.string().email("Email Invalid").notRequired(),
	fullName: Yup.string(),
	// .min(4, "Minimum 4 Karakter")
	// .max(50, "Terlalu Panjang")
	// .required("Dibutuhkan"),
	// position: Yup.string(),
	// .max(50, "Terlalu Panjang").required("Dibutuhkan"),
	phone: Yup.string(),
	bp: Yup.array()
		.of(
			Yup.object().shape({
				bp_id: Yup.string(),
				bp_name: Yup.string()
			})
		)
		.notRequired(),
	roles: Yup.array()
		.of(
			Yup.object().shape({
				role_id: Yup.string(),
				role_name: Yup.string()
			})
		)
		.notRequired()
	// .matches(/^\d+$/, "Nomor telepon harus angka")
	// .max(50, "Terlalu Panjang")
	// .required("Dibutuhkan"),
	// address: Yup.object(),
	// .required("Dibutuhkan"),
	// description: Yup.string()
	// .notRequired()
});

interface RoleOptions {
	readonly value: string;
	readonly label: string;
	readonly color?: string;
	readonly isFixed?: boolean;
	readonly isDisabled?: boolean;
}

interface BpOptions {
	readonly value: string;
	readonly label: string;
	readonly color?: string;
	readonly isFixed?: boolean;
	readonly isDisabled?: boolean;
}

interface InitialUser {
	fullName: string;
	phone: string;
	email: string;
	batchingPlantIds: BpOptions[];
	roles: RoleOptions[];
	status?: {
		value: "ACTIVE" | "BANNED" | "INACTIVE" | "BLOCKED";
		label: "ACTIVE" | "BANNED" | "INACTIVE" | "BLOCKED";
	};
	// status?: "ACTIVE" | "BANNED" | "INACTIVE" | "BLOCKED";
}

interface RoleResponse extends ApiResponse {
	data: { id: string; name: string }[];
}

interface ApiResponseBP extends ApiResponse {
	data: {
		data: { id: string; code: string; addressId?: string; name: string }[];
	};
}

export default function EditUser({
	isOpen,
	setIsOpen,
	userId,
	refetch
}: // customerDetail,
// refetch,
// picDetail,
// billingAddress
{
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	userId: string;
	refetch: () => void;
	// customerDetail: {
	// 	displayName: string;
	// 	type: "COMPANY" | "INDIVIDU";
	// 	ktp: string;
	// 	npwp: string;
	// };
	// billingAddress: {
	// 	line1: string;
	// 	line2: string;
	// 	lat: string;
	// 	lon: string;
	// 	id: string;
	// 	postalId: number;
	// };
	// picDetail: {
	// 	name: string;
	// 	position: string;
	// 	phone: string;
	// 	email: string;
	// 	id: string;
	// };
	// refetch: () => void;
}) {
	const { localDialog } = useLocalDialog();
	const [userData, setUserData] = useState({}) as any;
	const [optionTypeInterface, setOptionTypeInterface] = useState([
		{
			valueStr: "BRIK_INTERFACE",
			labelStr: "BRIK - Interface"
		},
		{
			valueStr: "BRIK_OUTSOURCE",
			labelStr: "BRIK - Outsource"
		}
	]);
	const [selectedGroup, setSelectedGroup] = useState("");
	const [batchingPlants, setBatchingPlants] = useState([]) as any;
	const [bpOptions, setBpOptions] = useState<BpOptions[]>([]);
	const [rolesOptions, setRolesOptions] = useState<RoleOptions[]>([]);
	const [nameCustomerLark, setNameCustomerLark] = useState<string>("");
	const searchQUserLark = useDebounce(nameCustomerLark, 1000);
	const [larkInterface, setLarkInterface] = useState<string>("");
	const [initialUser, setInitialUser] = useState<InitialUser>({
		phone: "",
		email: "",
		fullName: "",
		batchingPlantIds: [],
		roles: [],
		status: undefined
	});

	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		}
	}, [isOpen, batchingPlants]);

	const { isLoading: roleLoading } = useQuery<RoleResponse, ApiError>(
		["get-roles-modal"],
		() => CommonService.getAllRoles(),
		{
			onSuccess(response) {
				const roles = response.data;
				const mapped = roles.map((role) => {
					return {
						value: role.id,
						label: role.name
					};
				});
				setRolesOptions(mapped);
			}
		}
	);

	const { isLoading: getBatchingPlantLoading } = useQuery<
		ApiResponseBP,
		ApiError
	>(["get-batching-plants"], () => CommonService.getBP(), {
		onSuccess(response) {
			const bp = response.data.data;
			const mapped = bp.map((bp: any) => {
				return {
					value: bp.id,
					label: bp.name
				};
			});
			setBatchingPlants(bp);
			setBpOptions(mapped);
		}
	});

	const { isLoading: getUserLoading } = useQuery(
		["get-one-user"],
		() => CommonService.getOneUser(userId),
		{
			onSuccess(response) {
				const user = response.data;
				const userBatchingPlants = batchingPlants.filter((bp: any) =>
					user.batchingPlantIds?.includes(bp.id)
				);
				setInitialUser((curr) => {
					return {
						...curr,
						phone: user.phone,
						email: user.email,
						fullName: user.fullName,
						batchingPlantIds: userBatchingPlants?.map((bp: any) => {
							return {
								value: bp.id,
								label: bp.name
							};
						}),
						roles: user.UserRoles?.map((role: any) => {
							return {
								value: role?.Role?.id,
								label: role?.Role?.name
							};
						}),
						status: {
							value: user.status,
							label: user.status
						}
					};
				});

				// const roles = response.data;
				// // console.log(roles, "rolesss");
				// const mapped = roles.map((role) => {
				// 	return {
				// 		value: role.id,
				// 		label: role.name
				// 	};
				// });
				// setRolesOptions(mapped);
				setUserData(user);
			}
		}
	);

	const { mutateAsync: updateUser } = useMutation<
		ApiCreatePicResponse,
		ApiError,
		PayloadCreateUser,
		ApiError
	>(async (data: PayloadCreateUser) => {
		const res = await CommonService.updateUser(userId as string, data);
		return res;
	});

	const { data: larkUser, isLoading: larkUserLoading } = useQuery<
		any,
		ApiError
	>(
		["query-lark-user", searchQUserLark, larkInterface],
		async () => {
			return await OrderService.getUserLarkByEmail({
				typeInterface: larkInterface,
				email: searchQUserLark[0] as string
			});
		},
		{
			enabled: searchQUserLark[0]?.length ? true : false
		}
	);

	const { data: groupLark, isLoading: grouplarkLoading } = useQuery<
		any,
		ApiError
	>(
		["query-lark-group", larkInterface],
		async () => {
			return await OrderService.getLarkGroup(larkInterface);
		},
		{
			enabled: larkInterface ? true : false
		}
	);

	const larkGroupOptions = useMemo(() => {
		if (groupLark?.data) {
			const data = groupLark.data.map((el: any) => {
				return {
					...el,
					valueStr: el.chat_id,
					labelStr: el.name
				};
			});
			return data;
		}
		return [];
	}, [groupLark]);

	const { data: userlarkByGroup, isLoading: loadingUserLarkByGroup } =
		useQuery<any, ApiError>(
			["query-lark-user", larkInterface, selectedGroup],
			async () => {
				return await OrderService.getLarkUserByGroup(
					selectedGroup,
					larkInterface
				);
			},
			{
				enabled: selectedGroup ? true : false
			}
		);

	const larkuserByGroupOptions = useMemo(() => {
		if (userlarkByGroup) {
			const data = userlarkByGroup?.data.map((el: any) => {
				return {
					...el,
					valueStr: el.member_id,
					labelStr: el.name
				};
			});

			return data;
		}
		return [];
	}, [userlarkByGroup?.data]);

	const larkUserOptions = useMemo(() => {
		if (larkUser?.data.length) {
			const data = larkUser.data
				.filter((el: any) => el.user_id)
				.map((el: any) => {
					return {
						...el,
						valueStr: el.user_id,
						labelStr: el.email
					};
				});
			return data;
		}
		return [];
	}, [larkUser?.data]);

	const labelStyle = {
		// marginBottom: "1em", // Adjust this value as needed
		marginTop: "1em" // Adjust this value as needed
	};
	return (
		<LocalDialog
			isOpen={localDialog.isOpen}
			backdrop={"static"}
			header="Ubah Pengguna"
			onClose={() => {
				localDialog.onClose();
				setTimeout(() => {
					setIsOpen(false);
				}, 500);
			}}
			rightBtnTitle="Edit"
			leftBtnTitle="Batalkan"
		>
			{getUserLoading ? (
				<h1>Loading...</h1>
			) : (
				<Formik
					initialValues={{
						fullName: initialUser.fullName,
						phone: initialUser.phone,
						email: initialUser.email,
						batchingPlantIds: initialUser.batchingPlantIds,
						roles: initialUser.roles,
						status: initialUser.status,
						typeInterface: {
							labelStr: "",
							valueStr: ""
						},
						selectedGrubLark: {
							labelStr: "",
							valueStr: ""
						},
						selectedUserLark: {
							labelStr: "",
							valueStr: ""
						}
					}}
					validationSchema={EditUserSchema}
					onSubmit={async (values, { setSubmitting }) => {
						// same shape as initial values
						setSubmitting(true);
						const payload = {
							...values,
							batchingPlantIds: values.batchingPlantIds.map(
								(bp) => bp.value
							),
							roles: values.roles.map((role) => {
								return {
									role_id: role.value,
									role_name: role.label
								};
							}),
							status: values?.status?.value,
							larkUserId: values?.selectedUserLark?.valueStr
						};

						await updateUser(payload);
						// setTimeout(() => {
						// console.log(values, "onSubmitvalue");
						// 	setSubmitting(false);
						// }, 2000);
						// setIsLoading(true);
						// console.log(values, "values initial");

						// try {
						// 	let placeResp;
						// 	if (values.address.valueStr !== "undefined") {
						// 		placeResp = await CommonService.getPlaceDetail(
						// 			values.address.valueStr as string
						// 		);
						// 	}

						// 	await updateAddress({
						// 		line1: values.address.labelStr,
						// 		id: billingAddress.id,
						// 		postalId:
						// 			placeResp?.result?.PostalId ??
						// 			billingAddress.postalId,
						// 		line2: values.description,
						// 		lat: placeResp?.result?.lat ?? billingAddress.lat,
						// 		lon: placeResp?.result?.lon ?? billingAddress.lon
						// 	});
						// 	// }
						// 	await updatePic({
						// 		name: values.name,
						// 		position: values.position,
						// 		type: "PROJECT",
						// 		phone: values.phone,
						// 		email: values.email
						// 	});
						// 	await updateCustomer({
						// 		...values,
						// 		type: values.type as "COMPANY" | "INDIVIDU"
						// 	});
						refetch();
						setSubmitting(false);
						localDialog.onClose();
						setTimeout(() => {
							setIsOpen(false);
						}, 500);
						// 	refetch();
						// } catch (error) {
						// 	console.log(error, "error update customer");

						// 	dispatch(
						// 		openAlert({
						// 			body: "Gagal memperbarui data pelanggan",
						// 			color: "danger"
						// 		})
						// 	);
						// }
					}}
				>
					{({
						errors,
						handleChange,
						isSubmitting,
						values,
						setFieldValue
					}) => {
						return (
							<Form>
								<FormGroup>
									<Label htmlFor="displayName">Nama</Label>
									<Input
										name="fullName"
										invalid={!!errors.fullName}
										onChange={handleChange}
										value={values.fullName}
										placeholder="Nama User"
										disabled={getUserLoading}
									/>
									<FormFeedback invalid={!!errors.fullName}>
										{errors.fullName}
									</FormFeedback>
									{/* <div className="input-style">
									<Label>Type</Label>
									<Field
										component="select"
										name="type"
										onChange={handleChange}
										className="form-control"
										multiple={false}
										value={values.type}
									>
										<option value="COMPANY">
											PERUSAHAAN
										</option>
										<option value="INDIVIDU">
											INDIVIDU
										</option>
									</Field>
								</div>
								<FormFeedback invalid={!!errors.type}>
									{errors.type}
								</FormFeedback> */}
									<Label style={labelStyle}>No. Telpon</Label>
									<Input
										name="phone"
										invalid={!!errors.phone}
										onChange={handleChange}
										value={values.phone}
										placeholder="No. Telpon User"
										disabled={getUserLoading}
									/>
									<FormFeedback invalid={!!errors.phone}>
										{errors.phone}
									</FormFeedback>
									<Label style={labelStyle}>Email</Label>
									<Input
										name="email"
										invalid={!!errors.email}
										onChange={handleChange}
										value={values.email}
										placeholder="Email User"
										disabled={getUserLoading}
									/>
									<FormFeedback invalid={!!errors.email}>
										{errors.email}
									</FormFeedback>

									<Label style={labelStyle}>
										Batching Plant
									</Label>
									<Select
										isMulti
										name="batchingPlantIds"
										options={bpOptions}
										placeholder="Pilih Batching Plant"
										isLoading={getBatchingPlantLoading}
										isDisabled={getUserLoading}
										value={values.batchingPlantIds}
										onChange={(newValue) => {
											setFieldValue("batchingPlantIds", [
												...newValue
											]);
										}}
									/>

									<Label style={labelStyle}>Role</Label>
									<Select
										isMulti
										name="roles"
										options={rolesOptions}
										placeholder="Role User..."
										isLoading={roleLoading}
										isDisabled={getUserLoading}
										value={values.roles}
										onChange={(newVal) => {
											// values.roles = [...newVal];
											// setFormikState({
											//     ...values,
											//     roles: [...newVal]
											// })
											// setFormikState(role)
											setFieldValue("roles", [...newVal]);
										}}
									/>

									<Label style={labelStyle}>Status</Label>
									<Select
										isMulti={false}
										name="status"
										options={[
											{
												value: "ACTIVE",
												label: "ACTIVE"
											},
											{
												value: "INACTIVE",
												label: "INACTIVE"
											},
											{
												value: "BLOCKED",
												label: "BLOCKED"
											},
											{
												value: "BANNED",
												label: "BANNED"
											}
										]}
										placeholder="Status User..."
										isLoading={roleLoading}
										isDisabled={getUserLoading}
										value={values.status}
										onChange={(newVal) => {
											setFieldValue("status", newVal);
										}}
									/>
									<Label style={labelStyle}>
										Pilih Tipe Interface
									</Label>
									<Select
										options={optionTypeInterface}
										getOptionLabel={(option: options) =>
											option.labelStr as string
										}
										getOptionValue={(option: options) =>
											option.valueStr as string
										}
										onChange={(newVal) => {
											if (newVal) {
												setLarkInterface(newVal?.valueStr as string);
												setFieldValue("typeInterface", {
													labelStr: newVal.labelStr,
													valueStr: newVal.valueStr
												});

												setFieldValue("selectedGrubLark", {
													labelStr: "",
													valueStr: ""
												});

												setFieldValue("selectedUserLark", {
													labelStr: "",
													valueStr: ""
												});
											}
										}}

										value={values.typeInterface}
									/>
									<Label style={labelStyle}>
										Group Di Lark
									</Label>
									<Select
									placeholder={""}
										options={larkGroupOptions}
										getOptionLabel={(option: options) =>
											option.labelStr as string
										}
										getOptionValue={(option: options) =>
											option.labelStr as string
										}
										onChange={(change: any) => {
											if (change) {
												setSelectedGroup(
													change.valueStr
												);

												setFieldValue("selectedGrubLark", {
													labelStr: change.labelStr,
													valueStr: change.valueStr
												});

												setFieldValue("selectedUserLark", {
													labelStr: "",
													valueStr: ""
												});
											}
										}}
										isDisabled={values.typeInterface.valueStr ? false : true}
										isLoading={grouplarkLoading}
									/>

									<Label style={labelStyle}>
										Pengguna Di Grub Lark
									</Label>
									<Select
										options={larkuserByGroupOptions}
										getOptionLabel={(option: options) =>
											option.labelStr as string
										}
										getOptionValue={(option: options) =>
											option.labelStr as string
										}
										onChange={(change: any) => {
											if (change) {
												setFieldValue(
													"selectedUserLark",
													{
														valueStr: change.valueStr,
														labelStr: change.labelStr
													}
												);
											}
										}}
										isLoading={loadingUserLarkByGroup}

										isDisabled={
											values.selectedGrubLark.valueStr ? false : true
										}

										value={values.selectedUserLark}

									/>

								</FormGroup>

								<div className="modal-custom-footer">
									<LoadingButton
										color="error"
										onClick={() => {
											localDialog.onClose();
											setTimeout(() => {
												setIsOpen(false);
											}, 500);
										}}
										loading={isSubmitting}
										disabled={isSubmitting}
									>
										<span
											style={{
												textTransform: "capitalize"
											}}
										>
											Batal
										</span>
									</LoadingButton>
									<LoadingButton
										color="warning"
										variant="contained"
										type="submit"
										loading={isSubmitting || getUserLoading}
										disabled={
											isSubmitting || getUserLoading
										}
									>
										<span
											style={{
												textTransform: "capitalize"
											}}
										>
											Ubah
										</span>
									</LoadingButton>
								</div>
							</Form>
						);
					}}
				</Formik>
			)}
		</LocalDialog>
	);
}
