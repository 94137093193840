import * as React from "react";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
// import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import "./FileItem.scss";
import { CommonService, OrderService } from "api/axios";
import { useMutation } from "react-query";
import { useAppDispatch } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import Loading from "components/B/loading";

export default function FileItem({
	files,
	indexFile,
	setFiles,
	listFiles
}: {
	files: any;
	indexFile: number;
	setFiles: any;
	listFiles: any;
}) {
	const [isEditing, setIsEditing] = React.useState(false);
	const [selectedFile, setSelectedFile] = React.useState<any>({});

	const dispatch = useAppDispatch();

	// const { mutateAsync: updateFile, isLoading: loadingUpload } = useMutation(
	// 	async (data: any) => {
	// 		const res = await OrderService.updateFileWo(
	// 			files.id as string,
	// 			data
	// 		);
	// 		return res;
	// 	}
	// );

	const { mutateAsync: sendFile, isLoading: loadingUpload } = useMutation<
		ApiResponseFiles,
		ApiError,
		FormData,
		ApiError
	>(async (_files: FormData) => {
		const res = await CommonService.uploadFile(_files);
		return res;
	});

	const handleFileChange = async (e: any) => {
		const uploadFile = e.target.files[0];
		const _sendFile = new FormData();
		_sendFile.append("photos", uploadFile);
		_sendFile.append("name", "WO");
		const response = await sendFile(_sendFile);

		const payloadWoFile = {
			fileId: response?.data[0]?.id,
			type: response?.data[0]?.type,
			name: response?.data[0]?.name,
			url: response?.data[0]?.url
		};

		const replicaListItem = [...listFiles];

		replicaListItem[indexFile] = payloadWoFile;
		setFiles(replicaListItem);

		// const updatedFileWo = await updateFile(payloadWoFile as any);
		// refetch();
		setIsEditing(false);
	};

	const handleEditClick = () => {
		setIsEditing(true);
	};

	// if (isLoading) {
	// 	return <Loading />;
	// }

	return (
		<ul className="list-item" key={files.id}>
			<InsertDriveFileIcon />
			{isEditing ? (
				<input type="file" onChange={handleFileChange} />
			) : (
				<p>{files?.file?.name || files?.name}</p>
			)}
			{/* <div className="actions">
				{isEditing ? null : ( // <EditIcon onClick={handleEditClick} />
					<EditIcon onClick={handleEditClick} />
				)}
			</div> */}
		</ul>
	);
}
