import React, { useMemo } from "react";
import moment from "moment";
import { Button, Container, Row } from "reactstrap";
import { useMutation, useQuery } from "react-query";

import useListSearchParams from "../../../hooks/useQuery";
import { CommonService, ProductivityService } from "../../../api/axios";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import Header from "../../../components/Headers/Header";
import BTable from "../../../components/B/table";
import Loading from "../../../components/B/loading";
import { dateStr, formatDate, toSheet } from "../../../helper";
import { useLocalDialog } from "hooks";
import { LocalDialog, StatusChip } from "components";
import BForm from "components/B/form";
import { openAlert } from "store/features/alert/AlertSlice";
import { useLocation } from "react-router-dom";
import { getRouteName, routeMapping } from "routes";
import BMTable from "components/B/BMTable";

const assignColumn = [
	{
		name: "Project.User.fullName",
		label: "Nama Sales",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "Project.User.assignee",
		label: "Assign By",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "clientName",
		label: "Pelanggan / PIC",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "Project.name",
		label: "Proyek",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "finishDate",
		label: "Tanggal",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "status",
		label: "Status",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				return <StatusChip value={value} />;
			}
		}
	}
];

export default function AssignVisitations() {
	const location = useLocation();
	const permission = useAppSelector((state) => state.user.permission);
	const assignVisitationPermission = useMemo(() => {
		const routeName = getRouteName(location.pathname, routeMapping);

		if (routeName) {
			return permission[routeName];
		}
		return {};
	}, [location, permission]);
	const [tableData, setTableData] = React.useState<ApiVisitations>({
		message: "",
		success: true,
		dataTable: []
	});

	const { page, date, searchParams } = useListSearchParams();
	const [state, setState] = React.useState<AssignVisitation>({});
	const { localDialog } = useLocalDialog();
	const salesId = searchParams.get("sales");
	const { bp } = useAppSelector((store) => store.batching);

	// query table
	const { isLoading, refetch } = useQuery<ApiVisitations, ApiError>(
		["query-visitations", page, date, salesId, bp?.valueStr],
		async () => {
			return await ProductivityService.getAllVisitations(page, 15, {
				date,
				assignee: "true",
				salesId: salesId as string,
				batchingPlantId: bp?.valueStr
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const { success } = res;
				if (!success) return;
				const data = res.data?.data.map((el, index) => ({
					id: el.id,
					values: [
						{
							objectKey: "id",
							value: el.id ?? "-",
							type: "text",
							render: false
						},
						// {
						// 	objectKey: "no",
						// 	value: (index + 1) + (15 * (page -1)), //15 is size data, look axios service,
						// 	type: "text",
						// },
						{
							objectKey: "Project.User.fullName",
							value: el.user.fullName ?? "-",
							type: "text"
						},
						{
							objectKey: "Project.User.assignee",
							value: el.assignee?.fullName ?? "-",
							type: "text"
						},
						{
							objectKey: "clientName",
							value:
								el.Project.Customer?.displayName ??
								el.Project.mainPic.name,
							type: "text"
						},
						{
							objectKey: "Project.name",
							value: el.Project.name ?? "-",
							type: "text"
						},
						// {
						// 	objectKey: "dateVisit",
						// 	value: formatDate(el.dateVisit),
						// 	type: "text"
						// },
						{
							objectKey: "finishDate",
							value: formatDate(el.dateVisit),
							type: "text"
						},
						{
							objectKey: "status",
							value: el.status,
							type: "status"
						}
					]
				}));

				setTableData(() => ({
					...tableData,
					...res,
					dataTable: (data as DataTable[]) ?? []
				}));
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const dispatch = useAppDispatch();

	// buat jadwal baru
	//option sales
	useQuery<IUsers, ApiError>(
		["query-option-sales"],
		async () => {
			return await CommonService.getUsers(1, 100, "SALES");
		},
		{
			enabled: localDialog.isOpen,
			onSuccess: (res) => {
				if (!res.data?.data) return;
				const optionsSales = res.data.data.map((el) => {
					return {
						...el,
						valueStr: el.id,
						labelStr: el.fullName
					};
				});
				setState({
					...state,
					Sales: {
						...state?.Sales,
						options: optionsSales
					}
				});
			}
		}
	);

	//option admin
	useQuery<IUsers, ApiError>(
		["query-option-assignee"],
		async () => {
			return await CommonService.getUsers(1, 100, "ADMIN");
		},
		{
			enabled: localDialog.isOpen,
			onSuccess: (res) => {
				if (!res.data?.data) return;
				const optionsAdmin = res.data.data.map((el) => {
					return {
						...el,
						valueStr: el.id,
						labelStr: el.fullName
					};
				});
				setState({
					...state,
					assignee: {
						...state?.assignee,
						options: optionsAdmin
					}
				});
			}
		}
	);

	//option project
	useQuery<ApiResponseGetAllProject, ApiError>(
		["query-option-project"],
		async () => {
			return await CommonService.getAllProject(1, 100);
		},
		{
			enabled: localDialog.isOpen,
			onSuccess: (res) => {
				if (!res.data?.data) return;
				const optionsProject = res.data.data.map((el) => {
					return {
						...el,
						valueStr: el.id,
						labelStr: el.name
					};
				});
				setState({
					...state,
					Project: {
						...state?.Project,
						options: optionsProject
					}
				});
			}
		}
	);

	const forms: ElementForm[] = React.useMemo(() => {
		return [
			{
				label: "Nama Assignee",
				type: "dropdown",
				value: state?.assignee,
				placeholder: "Pilih Sales",
				onChange: (e: any) => {
					setState({
						...state,
						assignee: e
					});
				},
				options: state?.assignee?.options
			},
			{
				label: "Nama Sales",
				type: "dropdown",
				value: state?.Sales,
				placeholder: "Pilih Sales",
				onChange: (e: any) => {
					setState({
						...state,
						Sales: {
							...e,
							options: state?.Sales?.options
						}
					});
				},
				options: state?.Sales?.options
			},
			{
				label: "Nama Proyek",
				type: "dropdown",
				value: state?.Project,
				placeholder: "Pilih / Tulis Nama Proyek",
				onChange: (e: any) => {
					setState({
						...state,
						Project: {
							...e,
							options: state?.Project?.options
						},
						companyDisplayName: e.Company?.displayName
							? e.Company.displayName
							: "",
						pic: e.Pic
					});
				},
				options: state?.Project?.options
			},
			{
				label: "Nama Perusahaan (kosongkan jika individu)",
				type: "text",
				value: state?.companyDisplayName,
				placeholder: "Tulis Nama Perusahaan",
				onChange: (e: any) => {
					setState({
						...state,
						companyDisplayName: e.target.value
					});
				}
			},
			{
				label: "PIC Proyek",
				type: "pic",
				value: state?.pic,
				placeholder: "Pilih / Tulis Nama Perusahaan",
				onChange: (key: any) => (e: any) => {
					setState({
						...state,
						pic: {
							...state?.pic,
							[key]: e.target.value as string
						} as PICModel
					});
				}
			},
			{
				label: "Tanggal",
				type: "date",
				value: state?.date,
				onChange: (e: any) =>
					setState({
						...state,
						date: e.target.value
					}),
				placeholder: "Pilih Tanggal",
				min: true
			}
			// {
			// 	label: "Lokasi Proyek",
			// 	type: "map",
			// 	value: "",
			// 	onChange: (e: any) => {
			// 		console.log(e);
			// 	},
			// 	placeholder: "Pilih Lokasi"
			// },
		] as ElementForm[];
	}, [state]);

	const { mutate, isLoading: loadingCreate } = useMutation<
		ApiResponseAssign,
		ApiError,
		ApiCreateVisitationAssign,
		ApiError
	>(
		async (data: ApiCreateVisitationAssign) => {
			return await ProductivityService.assignVisitation(data);
		},
		{
			onSuccess: (res) => {
				setState({});
				localDialog.onClose();
				refetch();
			},
			onError: (err) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);
	const onSubmit = () => {
		const isPicChange = () => {
			if (
				state?.Project?.Pic?.name?.toLowerCase() !==
				state?.pic?.name.toLocaleLowerCase()
			) {
				return true;
			}
			if (
				state?.Project?.Pic?.position?.toLowerCase() !==
				state?.pic?.position.toLocaleLowerCase()
			) {
				return true;
			}
			if (
				state?.Project?.Pic?.email?.toLowerCase() !==
				state?.pic?.email.toLocaleLowerCase()
			) {
				return true;
			}
			if (
				state?.Project?.Pic?.phone?.toLowerCase() !==
				state?.pic?.phone.toLocaleLowerCase()
			) {
				return true;
			}
			return false;
		};
		const payload: ApiCreateVisitationAssign = {
			assignee: {
				id: state?.assignee?.id as string,
				fullName: state?.assignee?.fullName as string
			},
			pic: [
				{
					...state?.pic,
					isSelected: true,
					...(isPicChange() && {
						id: undefined
					})
				}
			] as PICModel[],
			project: {
				name: state?.Project?.name as string,
				companyDisplayName: state?.companyDisplayName as string,
				id: state?.Project?.id as string
				// location: {
				// 	lat: "",
				// 	line1: "",
				// 	lon: ""
				// }
			},
			sales: {
				id: state?.Sales?.id as string,
				fullName: state?.Sales?.fullName as string
			},
			visitation: {
				// location: {
				// 	lat: "",
				// 	line1: "",
				// 	lon: ""
				// },
				customerType: state?.companyDisplayName
					? "COMPANY"
					: "INDIVIDU",
				bookingDate: new Date(state?.date as Date)?.valueOf() as number,
				isBooking: true
			},
			batchingPlantId: bp?.valueStr
		};
		mutate(payload);
	};

	if (isLoading || loadingCreate) {
		return <Loading />;
	}

	return (
		<>
			<Header data={tableData.summary} date={date} show={false} />
			<Container className="mt--7" fluid>
				{/* Table */}
				<Row>
					<div className="col">
						<BMTable
							tableTitle=""
							header={
								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										paddingBottom: "0.5rem"
									}}
								>
									<Button
										onClick={() => {
											localDialog.onOpen();
											console.log("first");
										}}
										size="sm"
										color="success"
										className="confirm-button approve-button"
									>
										Buat Jadwal Kunjungan
									</Button>
								</div>
							}
							columns={assignColumn}
							tableData={tableData.dataTable as DataTable[]}
							totalItems={tableData.data?.totalItems as number}
							currentPage={tableData.data?.currentPage as number}
						// onDownload
						/>
						{/* <BTable
							header={
								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										paddingBottom: "0.5rem"
									}}
								>
									{assignVisitationPermission &&
									assignVisitationPermission.create ? (
										<Button
											onClick={() => {
												localDialog.onOpen();
											}}
											size="sm"
											color="success"
											className="confirm-button approve-button"
										>
											Buat Jadwal
										</Button>
									) : null}
								</div>
							}
							currentPage={page}
							totalPage={tableData.data?.totalPages as number}
							totalItem={tableData.data?.totalItems as number}
							columns={[
								// {
								// 	key: "id",
								// 	value: "No",
								// },
								{
									key: "salesName",
									value: "Sales Name"
								},
								{
									key: "assignee",
									value: "Assign By"
								},
								{
									key: "clientName",
									value: "PT / PIC"
								},
								{
									key: "projectVisited",
									value: "Proyek"
								},
								// {
								// 	key: "dateVisit",
								// 	value: "Visit Date",
								// },
								{
									key: "finishDate",
									value: "Tanggal"
								},
								{
									key: "status",
									value: "Status"
								}
							]}
							data={tableData.dataTable as DataTable[]}
							filterSales
							disabled
							// onDownload={onDownload}
						/> */}
					</div>
				</Row>
			</Container>
			<LocalDialog
				isOpen={localDialog.isOpen}
				backdrop={"static"}
				header="Buat Jadwal Kunjungan Baru"
				onClick={onSubmit}
				onClose={() => {
					localDialog.onClose();
				}}
			>
				<BForm forms={forms} />
			</LocalDialog>
		</>
	);
}
