import { BarChart, FunnelChart } from "components";
import React, { useMemo, useState } from "react";
import moment from "moment";
import {
	MonthStartEnd,
	WeekNumberToDateRange
} from "helper/WeekNumberToDateRange";
import { useQuery } from "react-query";
import { DashboardService } from "api/axios";
import { nivoDataFunnel } from "components/B/LineChart";

const lastWeek = moment().subtract(1, "week").format("YYYY-[W]WW");
const { startDate: initialStartDate, endDate: initialEndDate } =
	WeekNumberToDateRange(lastWeek);

const thisMonth = moment().endOf("month").format("YYYY-MM-DD");
const { startDate: initialMonthStartDate, endDate: initialMonthEndDate } =
	MonthStartEnd(thisMonth, 3);

export default function SalesFunnelBarChart() {
	const [salesFunnel, setSalesFunnel] = useState<SalesFunnelResponse>(
		{} as SalesFunnelResponse
	);

	const [dateRange, setDateRange] = useState({
		startDate: initialStartDate,
		endDate: initialEndDate
	});
	const [filterType, setFilterType] = useState("monthly");
	useQuery(
		["sales-funnel", dateRange, filterType],
		async () => {
			const { startDate, endDate } = dateRange;
			return await DashboardService.getSalesFunnel({
				startDate,
				endDate,
				type: filterType
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				setSalesFunnel(res.data.data);
			}
		}
	);

	const transformedData: nivoDataFunnel[] = useMemo(() => {
		const trfData: nivoDataFunnel[] = [];
		const visitation = {
			id: "visitation",
			value: 0,
			label: "VISITATION"
		};
		const sph = {
			id: "sph",
			value: 0,
			label: "SPH"
		};
		const po = {
			id: "purchase_order",
			value: 0,
			label: "PO"
		};
		const so = { id: "sales_order", value: 0, label: "SO" };
		if (!salesFunnel.visitationCount) {
			trfData.push(visitation);
			trfData.push(sph);
			trfData.push(po);
			trfData.push(so);
			return trfData;
		}
		salesFunnel.visitationCount.forEach((el) => {
			visitation.value += +el.count;
		});
		trfData.push(visitation);
		salesFunnel.sphCount.forEach((el) => {
			sph.value += +el.count;
		});
		trfData.push(sph);
		salesFunnel.poCount.forEach((el) => {
			po.value += +el.count;
		});
		trfData.push(po);
		salesFunnel.soCount.forEach((el) => {
			so.value += +el.count;
		});
		trfData.push(so);
		return trfData;
	}, [salesFunnel]);

	return (
		<>
			<FunnelChart
				data={transformedData}
				labels={[]}
				indexBy=""
				filterOnChange={({ type, dateRange }) => {
					setDateRange(dateRange);
					setFilterType(type);
				}}
				initialDate={{
					startDate: initialMonthStartDate,
					endDate: initialMonthEndDate
				}}
				title="Visitation -> SPH -> PO -> SO"
			/>
		</>
	);
}
