import { Marker } from "react-map-gl";
import styles from "./index.module.scss";
import React, { useEffect, useRef } from "react";

const MarkerDestination = (props: any) => {
	const markerRef = useRef<mapboxgl.Marker>(null);
	useEffect(() => {
		if (markerRef?.current) {
			const div = markerRef?.current?.getElement();
			console.log({ markerRef: div });
			div.className = `mapboxgl-marker mapboxgl-marker-anchor-bottom ${styles.marker}`;
		}
	}, [markerRef]);
	return (
		<Marker
			ref={markerRef}
			{...props}
			className={[styles.marker].join(" ")}
			anchor="bottom"
			offset={[-2, 20]}
		>
			<div
				className={[styles.iconContainer, props.className].join(" ")}
				style={{ "--color": props.color || "rgb(82, 209, 82)" } as React.CSSProperties}
			>
				<div className={styles.bpContainer}>
					<div className={styles.circleOuter}>
						<div className={styles.circle}>
							<svg
								className={styles.iconSvg}
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 400 400"
							>
								<path
									fillRule="evenodd"
									fill="currentColor"
									d="M66.123 50.646c-2.217.785-5.381 3.701-6.672 6.148l-1.053 1.995-.099 148.906-.1 148.906.832 2.266c4.015 10.938 20.35 10.549 23.783-.567.758-2.452.78-3.661.78-42.911V275l132.324-.002c85.388-.002 132.814-.138 133.705-.386 7.243-2.012 11.025-11.12 7.459-17.962-.438-.841-16.361-22.28-35.385-47.642-19.023-25.363-34.588-46.291-34.588-46.508 0-.217 15.565-21.145 34.588-46.508 19.024-25.362 34.947-46.801 35.385-47.642 3.388-6.5.532-14.436-6.312-17.541-2.362-1.071-281.629-1.231-284.647-.163"
								/>
							</svg>
							<div className={styles.overlay}>
								{props?.label && (
									<div className={styles.textContainer}>
										<p>{props.label}</p>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className={styles.line} />
				</div>
			</div>
		</Marker>
	);
};
export default MarkerDestination;
