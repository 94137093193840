import React, { useEffect } from "react";
import { redirect, useSearchParams } from "react-router-dom";
import { Spinner } from "reactstrap";

export default function Proview() {
	const [searchParams] = useSearchParams();

	useEffect(() => {
		setTimeout(() => {
			if (searchParams.get("urlStream")) {
				window.location.replace(
					atob(searchParams.get("urlStream") as string)
				);
			}
		}, 500);
	}, []);

	return (
		<div>
			<p>Redirecting...</p>
			<Spinner
				style={{
					height: "3rem",
					width: "3rem",
					margin: "0 auto"
				}}
			></Spinner>
		</div>
	);
}
