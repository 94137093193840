import React from "react";
import VideoEl from "./VideoEl";

export interface LiveVideoProps {
	url: string;
	className?: string;
	onReload?: any;
	muted?: boolean;
}

class LiveVehicleVideo extends React.Component<LiveVideoProps, any> {
	child: React.RefObject<any>;

	constructor(props: LiveVideoProps) {
		super(props);
		this.child = React.createRef();
	}

	loadPlayer = () => {
		this.child.current.loadPlayer();
	};

	startPlayer = () => {
		this.child.current.startPlayer();
	};

	pausePlayer = () => {
		this.child.current.pausePlayer();
	};

	destroyPlayer = () => {
		this.child.current.destroyPlayer();
	};

	override componentDidCatch(error: any, info: any) {
		console.log("Video Error:", { error, info });
	}

	override render() {
		return (<VideoEl ref={this.child} {...this.props} />);
	}
}

export default LiveVehicleVideo;
