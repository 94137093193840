import React from "react";

import "split-pane-react/esm/themes/default.css";
import styles from "./index.module.scss";

const SalesDashboard = () => {
	React.useEffect(() => {
		window.addEventListener("message", function (event) {
			if (
				!event ||
				!event.data ||
				!["SALES", "BATCHING_PLANT"].includes(event.data.type)
			) {
				return;
			}
			const data: { type: "SALES" | "BATCHING_PLANT"; id: string } =
				event.data;
			const url = new URL(window.location.href);
			if (data.type === "SALES") {
				if (data.id) {
					url.searchParams.set("salesId", data.id);
				} else {
					url.searchParams.delete("salesId");
				}
			} else {
				if (data.id) {
					url.searchParams.set("bpId", data.id);
				} else {
					url.searchParams.delete("bpId");
				}
			}

			window.history.pushState({}, "", url.toString());
		});
	}, []);

	// Function to extract query params from the URL
	const queryParams = () => {
		const params = new URLSearchParams(window.location.search);
		return Array.from(params.entries()).reduce((acc, [key, value]) => {
			acc[key] = value;
			return acc;
		}, {} as any);
	};

	// Append the extracted query params to the iframe src
	const iframeSrc = () => {
		const baseUrl = process.env.REACT_APP_EXTRAS + "/sales";
		const params = new URLSearchParams(queryParams()).toString();
		return `${baseUrl}/?${params}`;
	};

	return (
		<div className={styles.mainContainer}>
			<iframe className={styles.iframe} src={iframeSrc()}></iframe>
		</div>
	);
};

export default SalesDashboard;
