import * as React from "react";
import * as Yup from "yup";
import { BStatus, LocalDialog, Spacer, StatusChip } from "components";
import { useLocalDialog } from "hooks";
import { useDebounce } from "use-debounce";
import { Dispatch, SetStateAction, useEffect } from "react";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import { FormGroup, Input, Label } from "reactstrap";
import { useMutation, useQuery } from "react-query";
import { CommonService, OrderService } from "api/axios";
import Select, {
	OptionProps,
	SingleValueProps,
	components
} from "react-select";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import { MuiFileInput } from "mui-file-input";
import { formatIDR, handleResponseFile } from "helper";
import style from "./CreatePo.module.scss";

const labelStyle = {
	// marginBottom: "1em", // Adjust this value as needed
	marginTop: "1em" // Adjust this value as needed
};

type CustomComponentProps =
	| OptionProps<any, false>
	| SingleValueProps<any, false>;

const SingleValueCustomComponent: React.FC<SingleValueProps<any>> = (props) => {
	const { children, data } = props;

	return (
		<components.SingleValue {...props}>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					gap: "1rem",
					alignItems: "baseline",
					width: "100%"
				}}
			>
				{children} {/* This is where the actual value is rendered */}
				<StatusChip
					value={data.type === "COMPANY" ? "PERUSAHAAN" : data.type}
				/>
			</div>
		</components.SingleValue>
	);
};

const SharedCustomComponent = (props: OptionProps<any>) => {
	// Destructure common props used in both Option and SingleValue
	const { data } = "getOptionLabel" in props ? props : props;

	const style =
		"isFocused" in props
			? {
					backgroundColor: props.isFocused ? "lightgray" : "white",
					padding: 10
			  }
			: {
					padding: 10
			  };

	return (
		<div
			{...("innerProps" in props && props.innerProps)}
			style={{
				...style,
				display: "flex",
				justifyContent: "space-between",
				gap: "1rem",
				alignItems: "baseline"
			}}
		>
			<p>{data.labelStr}</p>
			<StatusChip
				value={data.type === "COMPANY" ? "PERUSAHAAN" : data.type}
			/>
		</div>
	);
};

const createSphSchema = Yup.object().shape({
	batchingPlantId: Yup.object().shape({
		valueStr: Yup.string().required("Lokasi Batching Plant Dibutuhkan"),
		labelStr: Yup.string().required("Lokasi Batching Plant Dibutuhkan")
	}),
	customer: Yup.object().shape({
		valueStr: Yup.string().required("Nama Customer Dibutuhkan"),
		labelStr: Yup.string().required("Nama Customer Dibutuhkan")
	}),
	projectId: Yup.object().shape({
		valueStr: Yup.string().required("Nama Proyek Dibutuhkan"),
		labelStr: Yup.string().required("Nama Proyek Dibutuhkan")
	}),
	quotationLetterId: Yup.object().shape({
		valueStr: Yup.string().required("SPH Dibutuhkan"),
		labelStr: Yup.string().required("SPH Dibutuhkan")
	}),
	poProducts: Yup.array().of(
		Yup.object().shape({
			name: Yup.string().required("Nama Produk Dibutuhkan"),
			quantity: Yup.number()
				.min(1, "Kuantitas minimal 1")
				.required("Kuantitas Dibutuhkan")
		})
	),
	poNumber: Yup.string().required("Nomor PO Dibutuhkan")
});

interface optionsQuotation extends ApiQuotation {
	labelStr: string;
	valueStr: string;
}

const SingleValueQuotationComponent: React.FC<SingleValueProps<any>> = (
	props
) => {
	const { children, data } = props;
	let status = "";
	if (data.status === "DRAFT") {
		status = "Disetujui";
	}
	if (data.status === "SUBMITTED") {
		status = "Diajukan";
	}

	return (
		<components.SingleValue {...props}>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					gap: "1rem",
					alignItems: "baseline",
					width: "100%"
				}}
			>
				{children} {/* This is where the actual value is rendered */}
				<StatusChip value={status} />
			</div>
		</components.SingleValue>
	);
};

const OptionQuotation = (props: OptionProps<optionsQuotation>) => {
	const currentDate = new Date();
	const expiryDate = new Date(props.data.expiryDate);
	const createdAtDate = new Date(props.data.createdAt);
	let status: string = props.data.status;
	if (status === "DRAFT") {
		status = "Disetujui";
	}
	if (status === "SUBMITTED") {
		status = "Diajukan";
	}

	return (
		<div
			{...("innerProps" in props && props.innerProps)}
			style={{
				cursor: props.isDisabled ? "default" : "pointer",
				backgroundColor: props.isDisabled ? "lightgray" : "white"
			}}
			className={style.customQuotationOption}
		>
			{props.data.number}
			<div
				style={{
					display: "flex",
					alignItems: "center",
					gap: "10px"
				}}
			>
				{expiryDate <= currentDate ? (
					<p style={{ color: "red", margin: 0 }}>
						Kadaluarsa pada {expiryDate.toISOString().split("T")[0]}
					</p>
				) : (
					<p style={{ margin: 0 }}>
						Dibuat tanggal{" "}
						{createdAtDate.toISOString().split("T")[0]}
					</p>
				)}
				<StatusChip value={status} />
			</div>
			{/* {JSON.stringify(props.data)} */}
			{/* {props.data.number} */}
			{/* <components.Option {...props} /> */}
			{/* <p >
					HP: <b>{props.data.mobilePhone}</b>
					WA:<b>{props.data.whatsappNo}</b>
					email: <b>{props.data.email}</b>
				</p> */}
		</div>
	);
};

export default function CreatePo({
	isOpen,
	setIsOpen,
	refetch
}: {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	refetch: () => void;
}) {
	const { localDialog } = useLocalDialog();
	const [uploadFile, setUploadFile] = React.useState<File>();
	const [nameCustomer, setNameCustomer] = React.useState<string>("");
	const [searchCustomerQ] = useDebounce(nameCustomer, 500);
	const [optionProject, setOptionProject] = React.useState<any>([]);
	const [projectSelected, setProjectSelected] = React.useState<any>("");
	const dispatch = useAppDispatch();
	const [selectedQuotationLetterId, setSelectedQuotationLetterId] =
		React.useState<string>();

	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		}
	}, [isOpen]);

	const { data: customers } = useQuery<any, ApiError>(
		["query-search-customer", searchCustomerQ],
		async () => {
			return await CommonService.getAllCustomer(1, 20, {
				search: searchCustomerQ
			});
		},
		{
			enabled: searchCustomerQ?.length ? true : false
		}
	);

	const { data: quotations } = useQuery<ApiQuotations, ApiError>(
		["query-customer-quotations", projectSelected],
		async () => {
			return await OrderService.getAllQuotations(1, 20, {
				projectId: projectSelected,
				status: "DRAFT"
			});
		},
		{
			enabled: !!projectSelected
		}
	);

	const { data: batchingPlant } = useQuery<any, ApiError>(
		["query-batchingPlant", true],
		async () => {
			return await CommonService.getBP();
		},
		{
			enabled: true
		}
	);

	const { data: quotationProducts } = useQuery<any, ApiError>(
		[
			"query-quotation-products",
			{
				selectedQuotationLetterId
			}
		],
		async () => {
			return await OrderService.getOneQuotation(
				selectedQuotationLetterId as string
			);
		},
		{
			enabled: !!selectedQuotationLetterId
		}
	);

	const optionQuotationProducts = React.useMemo(() => {
		if (
			quotationProducts?.data?.QuotationRequest?.RequestedProducts.length
		) {
			const data =
				quotationProducts?.data?.QuotationRequest?.RequestedProducts.map(
					(el: any) => {
						return {
							...el,
							valueStr: el.id,
							labelStr: el.name
						};
					}
				);
			return data;
		}
		return [];
	}, [quotationProducts]);

	const optionCustomer = React.useMemo(() => {
		if (customers?.data?.data?.length) {
			const data = customers?.data?.data.map((el: any) => {
				return {
					...el,
					valueStr: el.id,
					labelStr: el.displayName
				};
			});
			return data;
		}
		return [];
	}, [customers]);

	const optionQuotations = React.useMemo(() => {
		if (quotations?.data?.data?.length) {
			const data = quotations?.data?.data.map((el) => {
				return {
					...el,
					valueStr: el.id,
					labelStr: el.number
				};
			});

			return data;
		}
		return [];
	}, [quotations]);

	const listBatchingPlant = React.useMemo(() => {
		if (batchingPlant?.data?.data?.length) {
			const data = batchingPlant?.data.data.map((el: any) => {
				return {
					...el,
					valueStr: el.id,
					labelStr: el.name
				};
			});
			return data;
		}
		return [];
	}, [batchingPlant]);

	const { mutateAsync: CreatePurchaseOrder } = useMutation(
		async (data: CreatePurchaseOrderModel) => {
			const res = await OrderService.createPurchaseOrder(data);
			return res;
		}
	);

	const { mutateAsync: uploadFileAws } = useMutation<
		ApiResponseFiles,
		ApiError,
		FormData,
		ApiError
	>(async (_files: FormData) => {
		return await CommonService.uploadFile(_files);
	});

	async function uploadPoFile() {
		try {
			const _file = new FormData();
			_file.append("photos", uploadFile as File);
			_file.append("name", "SIGNED_SO");
			const response = await uploadFileAws(_file);
			const data = handleResponseFile(response.data, [
				uploadFile as File
			]);
			const payload = data.map((el) => ({
				...el,
				type: "BRIK"
			}));
			return payload;
		} catch (error) {
			dispatch(
				openAlert({
					body: "Gagal upload file PO",
					color: "danger"
				})
			);
			return null;
		}
	}

	return (
		<LocalDialog
			isOpen={localDialog.isOpen}
			backdrop={"static"}
			header="Buat PO Baru"
			onClose={() => {
				localDialog.onClose();
				setTimeout(() => {
					setIsOpen(false);
				}, 500);
			}}
		>
			<Formik
				initialValues={{
					customer: {
						labelStr: "",
						valueStr: "",
						type: ""
					},
					billingAddress: "",
					shippingAddress: "",
					paymentType: "",
					projectId: {
						labelStr: "",
						valueStr: ""
					},
					projectDocs: [
						{
							documentId: "",
							fileId: ""
						}
					],
					purchaseOrderId: {
						labelStr: "",
						valueStr: ""
					},
					quotationLetterId: {
						labelStr: "",
						valueStr: ""
					},
					batchingPlantId: {
						labelStr: "",
						valueStr: ""
					},
					distance: 0,
					delivery: {
						labelStr: "",
						valueStr: ""
					},
					poNumber: "",
					isProvideBankGuarantee: false,
					viaTol: false,
					status: "SUBMITTED",
					poProducts: [
						{
							id: "",
							offeringPrice: 0,
							name: "",
							displayName: "",
							quantity: 0,
							unit: "",
							labelStr: "",
							valueStr: ""
						}
					],
					sales: {
						labelStr: "",
						valueStr: ""
					},
					poFiles: [],
					lessThanFive: 0,
					fiveToSix: 0
				}}
				validationSchema={createSphSchema}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					try {
						const totalPrice = values?.poProducts?.reduce(
							(acc, cur) =>
								acc + cur.offeringPrice * cur.quantity,
							0
						);
						const poProducts = values?.poProducts?.map((el) => ({
							requestedProductId: el.id,
							requestedQuantity: el.quantity
						}));

						const payload = {
							batchingPlantId: values?.batchingPlantId?.valueStr,
							projectId: values?.projectId?.valueStr,
							poFiles: [],
							quotationLetterId:
								values?.quotationLetterId.valueStr,
							poNumber: values?.poNumber,
							poProducts,
							totalPrice,
							lessThanFive: values?.lessThanFive,
							fiveToSix: values?.fiveToSix
						};

						const res = await CreatePurchaseOrder(payload);

						if (uploadFile) {
							const [uploadDocPayload] = await Promise.all([
								uploadPoFile()
							]);

							if (uploadDocPayload) {
								await OrderService.putPurchaseOrder(
									res.data.purchaseOrderId,
									uploadDocPayload
								);
							}
						}

						// TODO: call /docs/. This expects poDocs. This is basically the SO file.
						// No clue why called poDocs. If so, then add a new upload input for this

						setIsOpen(false);
						dispatch(
							openAlert({
								body: res.message,
								color: "success"
							})
						);
						refetch();
					} catch (error) {
						dispatch(
							openAlert({
								body: "Gagal menambah Schedule baru",
								color: "danger"
							})
						);
					}
				}}
			>
				{({
					errors,
					handleChange,
					isSubmitting,
					values,
					setFieldValue
				}) => {
					return (
						<Form>
							<FormGroup>
								<Label style={labelStyle}>
									Pilih Batching Plant *
								</Label>
								<Select
									onChange={(change) => {
										if (change) {
											values.batchingPlantId.labelStr =
												change.labelStr as string;
											values.batchingPlantId.valueStr =
												change.valueStr as string;
											setFieldValue("batchingPlantId", {
												labelStr: change.labelStr,
												valueStr: change.valueStr
											});
										}
									}}
									name="batchingPlantId"
									value={values.batchingPlantId}
									options={listBatchingPlant}
									getOptionLabel={(option: options) =>
										option.labelStr as string
									}
									getOptionValue={(option: options) =>
										option.valueStr as string
									}
									isDisabled={false} // Field pertama selalu aktif
								/>
								<ErrorMessage
									name={"batchingPlantId.labelStr"}
									component="div"
									className="error-msg"
								/>

								<Label
									style={labelStyle}
									htmlFor="searchCustomer"
								>
									Pelanggan *
								</Label>
								<Select
									components={{
										Option: SharedCustomComponent,
										SingleValue: SingleValueCustomComponent
									}}
									onInputChange={(text) => {
										setNameCustomer(text);
									}}
									onChange={(change) => {
										if (change) {
											const paymentType =
												change.Accounts[0]?.type;
											const billingAddress =
												change.billingAddressId;

											setFieldValue("customer", {
												labelStr: change.labelStr,
												valueStr: change.valueStr,
												type: change.type
											});

											setFieldValue(
												"paymentType",
												paymentType
											);
											setFieldValue(
												"billingAddress",
												billingAddress
											);

											setFieldValue("projectId", {
												labelStr: "",
												valueStr: ""
											});
											setFieldValue("quotationLetterId", {
												labelStr: "",
												valueStr: ""
											});
											setFieldValue("saleOrderId", {
												labelStr: "",
												valueStr: ""
											});
											const projOpt = change.Projects.map(
												(el: any) => {
													return {
														...el,
														valueStr: el.id,
														labelStr: el.name
														// shipp
													};
												}
											);

											setOptionProject(projOpt);

											// setFieldValue("customer", {})
											values.customer.labelStr =
												change?.labelStr as string;
											values.customer.valueStr =
												change?.valueStr as string;
										}
									}}
									name="customer"
									value={values.customer}
									options={optionCustomer}
									getOptionLabel={(option: options) =>
										option.labelStr as string
									}
									getOptionValue={(option: options) =>
										option.valueStr as string
									}
									noOptionsMessage={() =>
										"Ketik Nama Customer"
									}
									isDisabled={false}
								/>
								{values.customer.type !== "COMPANY" && (
									<p
										style={{
											marginBottom: "0"
										}}
									>
										Tipe pelanggan individu, SO akan terbuat
										secara otomatis
									</p>
								)}
								<ErrorMessage
									name={"customer.labelStr"}
									component="div"
									className="error-msg"
								/>
								<Label style={labelStyle} htmlFor="Project">
									Proyek *
								</Label>
								<Select
									onChange={(change) => {
										if (change) {
											setFieldValue("projectId", {
												labelStr: change.labelStr,
												valueStr: change.valueStr
											});
											// setFieldValue("shippingAddress", change);
											setProjectSelected(change.valueStr);
											values.projectId.labelStr =
												change?.labelStr as string;
											values.projectId.valueStr =
												change?.valueStr as string;
										}
									}}
									name="projectId"
									value={values.projectId}
									options={optionProject}
									getOptionLabel={(option: options) =>
										option.labelStr as string
									}
									getOptionValue={(option: options) =>
										option.valueStr as string
									}
									noOptionsMessage={() => "Ketik Nama Proyek"}
									isDisabled={
										values.customer.valueStr ? false : true
									}
								/>
								<ErrorMessage
									name={"projectId.labelStr"}
									component="div"
									className="error-msg"
								/>
								<Label style={labelStyle} htmlFor="Project">
									SPH *
								</Label>

								<Select
									onChange={(change) => {
										if (change) {
											setFieldValue("quotationLetterId", {
												...change,
												labelStr: change.labelStr,
												valueStr: change.valueStr
											});
											setFieldValue("poProducts", [
												{
													id: "",
													offeringPrice: 0,
													name: "",
													displayName: "",
													quantity: 0,
													unit: "",
													labelStr: "",
													valueStr: ""
												}
											]);
											setSelectedQuotationLetterId(
												change.valueStr
											);
										}
									}}
									name="quotationLetterId"
									value={values.quotationLetterId as any}
									options={optionQuotations}
									getOptionLabel={(option: options) => {
										const currentDate = new Date();
										const expiryDate = new Date(
											option.expiryDate
										);

										return `${option.labelStr as string}  ${
											expiryDate <= currentDate
												? `(Expired at ${
														expiryDate
															.toISOString()
															.split("T")[0]
												  })`
												: ""
										}`;
									}}
									getOptionValue={(option: options) =>
										option.valueStr as string
									}
									noOptionsMessage={() => "Ketik Nama SPH "}
									isDisabled={
										values.customer.valueStr ? false : true
									}
									isOptionDisabled={(option) => {
										if (option.status === "SUBMITTED") {
											return true;
										}

										const currentDate = new Date();
										const expiryDate = new Date(
											option.expiryDate
										);

										return expiryDate <= currentDate;
									}}
									components={{
										Option: OptionQuotation
									}}
									isMulti={false}
								/>
								{/* Modify isOptionDisabled to disable option when quotation is expired, by checking expiryDate, which has format 2023-11-10T02:44:36.555Z */}
								<ErrorMessage
									name={"quotationLetterId.labelStr"}
									component="div"
									className="error-msg"
								/>

								<Label style={labelStyle}>Produk *</Label>
								<FieldArray name="poProducts">
									{({ remove, push, replace }) => (
										<div>
											{values.poProducts?.map(
												(product, index) => (
													<div
														key={index}
														className=""
													>
														<div className="modal-fieldarray">
															<div
																style={{
																	width: "30%"
																}}
															>
																<Label
																	htmlFor={`poProducts.${index}.name`}
																	style={{
																		fontSize:
																			"0.8rem",
																		paddingTop:
																			"1rem"
																	}}
																>
																	Nama
																</Label>
																<Select
																	className="modal-fieldarray-field"
																	name={`poProducts.${index}.name`}
																	onChange={(
																		change
																	) => {
																		if (
																			change
																		) {
																			const newProduct =
																				{
																					...product,
																					...change
																				};
																			replace(
																				index,
																				newProduct
																			);
																		}
																	}}
																	options={
																		optionQuotationProducts
																	}
																	getOptionLabel={(
																		option: any
																	) =>
																		option.labelStr as string
																	}
																	getOptionValue={(
																		option: any
																	) =>
																		option.name as string
																	}
																	isDisabled={
																		values
																			.quotationLetterId
																			.valueStr
																			? false
																			: true
																	}
																	value={
																		product
																	}
																	isOptionDisabled={(
																		option
																	) => {
																		return values.poProducts.some(
																			(
																				el
																			) =>
																				el.id ===
																				option.id
																		)
																			? true
																			: false;
																	}}
																/>
															</div>
															<div>
																<Label
																	htmlFor={`poProducts.${index}.quantity`}
																	style={{
																		fontSize:
																			"0.8rem",
																		paddingTop:
																			"1rem"
																	}}
																>
																	Kuantitas
																</Label>
																<Input
																	name={`poProducts.${index}.quantity`}
																	type="number"
																	onChange={
																		handleChange
																	}
																	value={
																		product.quantity
																	}
																	style={{
																		height: "38px"
																	}}
																	disabled={
																		values
																			.quotationLetterId
																			.valueStr
																			? false
																			: true
																	}
																/>
															</div>
															<div>
																<Label
																	style={{
																		fontSize:
																			"0.8rem",
																		paddingTop:
																			"1rem"
																	}}
																>
																	Harga
																</Label>
																<Input
																	value={
																		product.offeringPrice &&
																		`Rp ${formatIDR(
																			product.offeringPrice
																		)}`
																	}
																	style={{
																		height: "38px"
																	}}
																	disabled={
																		true
																	}
																/>
															</div>

															<LoadingButton
																disabled={
																	values
																		.batchingPlantId
																		.valueStr &&
																	values
																		.poProducts
																		.length >
																		1
																		? false
																		: true
																}
																onClick={() => {
																	remove(
																		index
																	);
																}}
															>
																Hapus
															</LoadingButton>
														</div>
													</div>
												)
											)}
											{values.batchingPlantId
												.valueStr && (
												<LoadingButton
													onClick={() => {
														push({
															offeringPrice: 0,
															name: "",
															quantity: 0,
															unit: ""
														});
														// setProducts([
														// 	...products,
														// 	{
														// 		name: "",
														// 		offeringPrice: 0,
														// 		unit: "",
														// 		quantity: 0
														// 	}
														// ]);
													}}
												>
													Tambah produk
												</LoadingButton>
											)}

											{values.poProducts?.map(
												(_, index) => (
													<div key={index}>
														<ErrorMessage
															name={`poProducts.${index}.name`}
															component="div"
															className="error-msg"
														/>
														<ErrorMessage
															name={`poProducts.${index}.quantity`}
															component="div"
															className="error-msg"
														/>
														<ErrorMessage
															name={`poProducts.${index}.offeringPrice`}
															component="div"
															className="error-msg"
														/>
														<ErrorMessage
															name={`poProducts.${index}.pouringMethod`}
															component="div"
															className="error-msg"
														/>
													</div>
												)
											)}
										</div>
									)}
								</FieldArray>
								<Spacer size="small" />
								<p>
									<strong>Total Harga:</strong> Rp{" "}
									{formatIDR(
										values.poProducts?.reduce(
											(acc, cur) =>
												acc +
												cur.offeringPrice *
													cur.quantity,
											0
										)
									)}
								</p>

								<Spacer size="large" />
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										gap: "1rem",
										alignItems: "start"
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "start"
										}}
									>
										<Label htmlFor={"poFiles"}>
											File PO
										</Label>
										<MuiFileInput
											name="poFiles"
											onChange={(newFile) => {
												if (
													newFile &&
													!Array.isArray(newFile)
												) {
													setUploadFile(newFile);
													setFieldValue(
														"poFiles",
														newFile
													);
												} else {
													setUploadFile(undefined);
													setFieldValue(
														"poFiles",
														null
													);
												}
											}}
											value={uploadFile}
											placeholder="Masukkan file PO"
											multiple={false}
											style={{
												zIndex: 0
											}}
											inputProps={{
												accept: "application/pdf, image/png, image/jpeg"
											}}
										/>
										<ErrorMessage
											name="poFiles"
											component="div"
											className="error-msg"
										/>
									</div>
									<div
										style={{
											height: "100%",
											display: "flex",
											flexDirection: "column",
											alignItems: "start"
										}}
									>
										<Label htmlFor={"poNumber"}>
											Nomor PO *
										</Label>
										<Input
											name={"poNumber"}
											onChange={handleChange}
											value={values.poNumber}
											style={{
												flexGrow: 1,
												height: "100% !important"
											}}
										/>
										<ErrorMessage
											name="poNumber"
											component="div"
											className="error-msg"
										/>
									</div>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										gap: "20px"
									}}
								></div>
							</FormGroup>

							<div className="modal-custom-footer">
								<LoadingButton
									color="error"
									onClick={() => {
										localDialog.onClose();
										setTimeout(() => {
											setIsOpen(false);
										});
									}}
									loading={isSubmitting}
									disabled={isSubmitting}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Batal
									</span>
								</LoadingButton>
								<LoadingButton
									color="error"
									variant="contained"
									type="submit"
									loading={isSubmitting}
									disabled={isSubmitting}
									sx={{
										backgroundColor: "red",
										color: "white"
									}}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Buat
									</span>
								</LoadingButton>
							</div>
						</Form>
					);
				}}
			</Formik>
		</LocalDialog>
	);
}
