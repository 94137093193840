import BCard from "components/B/card";
import React, { useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ProductivityService } from "api/axios";
import { Button, Card, CardBody, CardTitle, Table } from "reactstrap";
import { ChevronLeft } from "react-bootstrap-icons";
import BStatus from "components/B/status";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import BImageList from "components/B/imageList";
import { formatComma } from "helper";
import { getRouteName, routeMapping } from "routes";
import { LocalDialog } from "components";
import { useLocalDialog } from "hooks";

const VisitationDetail = () => {
	const { localDialog } = useLocalDialog();
	const location = useLocation();
	const permission = useAppSelector((state) => state.user.permission);
	const visitDetailPermission = useMemo(() => {
		const routeName = getRouteName(location.pathname, routeMapping);

		if (routeName) {
			return permission[routeName];
		}
		return {};
	}, [location, permission]);

	const navigate = useNavigate();
	const params = useParams();
	const id = params?.id;
	const [projectDetails, setProjectDetails] = React.useState<any[]>([]);
	const [files, setFiles] = React.useState<any[]>([]);
	const [summary, setSummary] = React.useState<any[]>([]);
	const [sales, setSales] = React.useState<string>("");
	const [products, setProducts] = React.useState<any[]>([]);
	const [status, setStatus] = React.useState<string>("");

	const dispatch = useAppDispatch();
	const { isLoading } = useQuery<{ data: ApiVisitation }, ApiError>(
		["query-visitations"],
		async () => {
			return await ProductivityService.getOneVisitation(id ?? "");
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const isCompany = !!res?.data?.Project?.companyName;
				const isCustomer = !!res?.data?.Project?.Customer;
				setSales(res?.data?.Project?.User?.fullName);
				setStatus(res?.data?.status);

				try {
					const products = res?.data?.VisitationProducts;
					setProducts(products);
				} catch (e) {
					//
				}

				try {
					const projectDetails = [
						(!isCompany && !isCustomer) ||
							(isCompany && !isCustomer)
							? {
								type: "noCustomer"
							}
							: null,

						isCustomer && {
							name: "Tipe Pelanggan",
							type: "customerType",
							data: res?.data?.Project.Customer?.type
						},
						isCompany && !isCustomer
							? {
								name: "Tipe Pelanggan",
								type: "customerType",
								data: "PERUSAHAAN"
							}
							: null,
						isCustomer
							? {
								name: "Nama Pelanggan",
								data:
									res?.data?.Project.Customer
										?.displayName ?? "-"
							}
							: null,
						{
							name: "Nama Proyek",
							data: res?.data?.Project?.name
						},
						{
							name: "Tipe Proyek",
							data: res?.data?.Project?.type ?? "-"
						},
						{
							name: "PIC Utama",
							type: "pic",
							data: {
								name: res?.data?.Project?.mainPic?.name,
								position: res?.data?.Project?.mainPic?.position,
								phone: res?.data?.Project?.mainPic?.phone,
								email: res?.data?.Project?.mainPic?.email
							}
						},
						res?.data?.Project?.Pics.length > 1 && {
							name: "PICs",
							type: "pics",
							data: {
								pics: res?.data?.Project?.Pics,
								mainPIC: res?.data?.Project?.mainPic
							}
						},
						{
							name: "Alamat",
							type: "address",
							data: {
								line1: res?.data?.Project?.LocationAddress
									?.line1,
								lat: res?.data?.Project?.LocationAddress?.lat,
								lon: res?.data?.Project?.LocationAddress?.lon
							}
						},
						res?.data?.Project?.Competitors &&
							res?.data?.Project?.Competitors?.length >= 1
							? {
								name: "Kompetitor",
								type: "competitors",
								data: res.data.Project.Competitors
							}
							: null
					];
					setProjectDetails(projectDetails);
				} catch (e) {
					//
				}

				try {
					const files =
						res?.data?.VisitationFiles?.map((v, idx) => {
							return {
								name: v?.type,
								type: "file",
								data: v?.File
							};
						}) ?? [];
					setFiles(files);
				} catch (e) {
					//
				}

				try {
					const summary = [
						{
							name: "Sales",
							type: "text",
							data: res?.data?.Project?.User?.fullName
						},
						{
							name: "Tipe Kunjungan",
							type: "chips",
							data: [
								res?.data?.isBooking
									? {
										text: "Janji",
										color: "blue",
										textColor: "white"
									}
									: {
										text: "Visit",
										color: "green",
										textColor: "white"
									}
							]
						},
						{
							name: "Tanggal Kunjungan",
							type: "date",
							data: res?.data?.finishDate
						},
						{
							name: "Kunjungan Ke",
							type: "text",
							data: res?.data?.order
						},
						{
							name: "Stage",
							type: "text",
							data: res?.data?.Project?.stage
						},
						{
							name: "Kebutuhan Bulan Ke",
							type: "text",
							data: res?.data?.estimationMonth
						},
						{
							name: "Kebutuhan Minggu Ke",
							type: "text",
							data: res?.data?.estimationWeek
						},
						{
							name: "Tipe Pembayaran",
							type: "chips",
							data: [
								res?.data?.paymentType == "CREDIT"
									? {
										text: "Credit",
										color: "green",
										textColor: "white"
									}
									: {
										text: "Cash",
										color: "blue",
										textColor: "white"
									}
							]
						}
					];
					setSummary(summary);
				} catch (e) {
					//
				}
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const deleteVisitation = useMutation(
		async () => {
			return await ProductivityService.deleteVisitation(id as string);
		},
		{
			onSuccess: (response) => {
				dispatch(
					openAlert({
						body: "Data Kunjungan berhasil di hapus",
						color: "success"
					})
				);
				navigate("/productivity/visitation");
			},
			onError: (err: ApiError) => {
				console.log(
					err.response?.data.message,
					"message error deleteVisitation"
				);
				dispatch(
					openAlert({
						body: "Data Kunjungan tidak dapat di hapus",
						color: "danger"
					})
				);
			}
		}
	);

	function handleDelete() {
		deleteVisitation.mutate();
	}
	return (
		<div className="p-4 pt-6">
			<LocalDialog
				isOpen={localDialog.isOpen}
				backdrop={"static"}
				header="Hapus Kunjungan"
				onClick={handleDelete}
				onClose={() => {
					localDialog.onClose();
				}}
				leftBtnTitle="Batal"
				rightBtnTitle="Hapus"
			>
				<h3>Data kunjungan akan terhapus, apakah anda yakin?</h3>
			</LocalDialog>
			<div className="d-flex flex-row align-items-center mb-2">
				<div
					className="b-back-btn border mr-3"
					onClick={() => {
						navigate(-1);
					}}
				>
					<ChevronLeft />
				</div>
				<h1 className="mb-0 mr-2">{sales}</h1>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						flex: 1,
						alignItems: "center"
					}}
				>
					<BStatus>{status}</BStatus>
					{visitDetailPermission && visitDetailPermission.delete ? (
						<div>
							<Button
								onClick={() => {
									localDialog.onOpen();
								}}
								size="sm"
								color="danger"
								className="confirm-button decline-button"
							>
								Hapus
							</Button>
						</div>
					) : null}
				</div>
			</div>
			<div className="d-flex flex-column flex-lg-row">
				<div className="d-flex flex-column mb-3" style={{ flex: 0.7 }}>
					<Card className="">
						<CardBody>
							<Table responsive hover striped>
								<thead>
									<tr>
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											No
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											Nama
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											Kuantitas
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											Metode Penuangan
										</th>
									</tr>
								</thead>
								<tbody>
									{products.map((item, idx) => (
										<tr key={idx}>
											<td style={{ textAlign: "left" }}>
												{idx + 1}
											</td>
											<td style={{ textAlign: "left" }}>
												{item?.Product.name}
											</td>
											<td style={{ textAlign: "left" }}>
												{item?.quantity
													? formatComma(
														`${item.quantity} M³`
													)
													: "0 M³"}
											</td>
											<td style={{ textAlign: "left" }}>
												{item?.pouringMethod || "-"}
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</CardBody>
					</Card>
					<BCard
						className="mt-3"
						title="Rincian Kunjungan"
						data={summary}
					/>
					{/* <BCard className="mt-3" title="Files" data={files} /> */}
					<Card className="mt-3">
						<CardBody>
							<CardTitle tag="h5" style={{ margin: 0 }}>
								Foto
							</CardTitle>
							<div className="break-line" />
							<BImageList data={files} />
						</CardBody>
					</Card>
				</div>
				<div className="spacer-v" />
				<div className="d-flex flex-column" style={{ flex: 0.3 }}>
					<BCard title="Rincian Proyek" data={projectDetails} />
				</div>
			</div>
		</div>
	);
};

export default VisitationDetail;
