import React, { useEffect } from "react";

import "split-pane-react/esm/themes/default.css";
import styles from "./index.module.scss";
const extrasUrl = process.env.REACT_APP_EXTRAS;

const QualityControl = () => {
	useEffect(() => {
		const eventMethod = window.addEventListener
			? "addEventListener"
			: "attachEvent";
		const eventer = window[eventMethod];
		const messageEvent =
			eventMethod == "attachEvent" ? "onmessage" : "message";

		// Listen to message from child window
		eventer(
			messageEvent,
			function (e) {
				const key = e.message ? "message" : "data";
				const data = e[key];
				console.log("message received from iframe: " + data);
				if (JSON.stringify(data).includes("assignment-set")) {
					window.history.replaceState("", "", data);
				} else if (JSON.stringify(data).includes("quality-control")) {
					window.history.replaceState("", "", data);
				}
			},
			false
		);
	}, []);

	return (
		<div className={styles.mainContainer}>
			<iframe
				className={styles.iframe}
				src={`${extrasUrl}/quality-control`}
			></iframe>
		</div>
	);
};

export default QualityControl;
