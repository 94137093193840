import React, { useMemo } from "react";
import BSwitch from "components/B/Switch";
import styles from "./index.module.scss";
import { Spinner } from "reactstrap";

export default function LiveVehicleCameraProview(props: any, ref: any) {
	const proviewStream = useMemo(() => {
		return `https://cloud-platform.proview.id:8080/808gps/open/player/video.html?lang=en&vehiIdno=${props?.internalId
			?.replace(" 8", "")
			.replace(" 7", "")}&jsession=${props?.gpsData?.PROVIEW?.jsession}`;
	}, [props?.gpsData, props?.internalId]);

	return (
		<div
			className={[
				"vehicle-live-camera",
				styles.VehicleLiveCamera,
				props.className
			].join(" ")}
		>
			{props.camShown ? (
				<>
					<div className={styles.headerContainer}>
						<p className={styles.title}>{props?.internalId}</p>
						<p className={styles.title}>{props?.do?.status}</p>
						<p className={styles.title}>{props?.do?.number}</p>
						<BSwitch
							className={styles.switch}
							color="primary"
							checked={props.camShown}
							onChange={() => {
								props.onToggleShowCam(
									props.id,
									!props.camShown
								);
							}}
							disabled={props.camLoading}
							inputProps={{ "aria-label": "controlled" }}
							switchType="camera"
						/>
					</div>
					<div
						style={{
							width: "100%",
							height: "400px"
						}}
					>
						<iframe
							width="100%"
							height="100%"
							// src={`https://proview.io/embed/${props?.internalId}`}
							src={proviewStream}
							allowFullScreen
						/>
						{/* <iframe
							width="100%"
							height="100%"
							// src={`https://proview.io/embed/${props?.internalId}`}
							src={`http://103.30.245.66:88/808gps/open/player/video.html?lang=en&vehiIdno=${props?.internalId?.replace(
								" 0",
								""
							)}&jsession=${props?.gpsData?.PROVIEW?.jsession}`}
							allowFullScreen
						/> */}
					</div>
					{props.camLoading && (
						<div className={styles.overlay}>
							<Spinner
								style={{
									height: "3rem",
									width: "3rem",
									margin: "0 auto"
								}}
							></Spinner>
						</div>
					)}
				</>
			) : null}
		</div>
	);
}
