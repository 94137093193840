/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

import { routes } from "routes.ts";
import NotFound from "views/auth/NotFound";

const Auth = (props) => {
	const mainContent = React.useRef(null);
	const location = useLocation();

	React.useEffect(() => {
		document.body.classList.add("bg-default");
		document.body.classList.add("h-100");
		document.getElementsByTagName("html")[0].classList.add("h-100");
		document.getElementById("root").classList.add("h-100");
		return () => {
			document.body.classList.remove("bg-default");
			document.body.classList.remove("h-100");
			document.getElementsByTagName("html")[0].classList.remove("h-100");
			document.getElementById("root").classList.remove("h-100");
		};
	}, []);
	React.useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		mainContent.current.scrollTop = 0;
	}, [location]);

	const getRoutes = (routes) => {
		const authRoutes = routes.filter((prop) => {
			return prop.layout === "/auth";
		});
		return authRoutes.map((prop, key) => {
			return (
				<Route
					path={prop.path}
					element={<prop.component />}
					key={key}
				/>
			);
		});
	};

	return (
		<>
			<div className="main-content h-100" ref={mainContent}>
				{/* <AuthNavbar /> */}
				{/* <div className="header bg-gradient-white py-7 py-lg-8">
					<Container>
						<div className="header-body text-center mb-7">
							<Row className="justify-content-center">
								<Col lg="5" md="6">
									<h1 className="text-gray">Welcome!</h1>
									<p className="text-gray">BRIK ORDERS AND DELIVERIES</p>
								</Col>
							</Row>
						</div>
					</Container>
					<div className="separator separator-bottom separator-skew zindex-100">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							preserveAspectRatio="none"
							version="1.1"
							viewBox="0 0 2560 100"
							x="0"
							y="0"
						>
							<polygon
								className="polygon-fill"
								points="2560 0 2560 100 0 100"
							/>
						</svg>
					</div>
				</div> */}
				{/* Page content */}
				{/* <Container className="mt--8 pb-5 h-100"> */}
				<Container className="h-100">
					<Row className="h-100 justify-content-center">
						<Routes>
							{getRoutes(routes)}
							<Route path="*" element={<NotFound />} />
						</Routes>
					</Row>
				</Container>
			</div>
			{/* <AuthFooter /> */}
		</>
	);
};

export default Auth;
