import React from "react";
import { useLocation } from "react-router-dom";
import { Badge, Card, CardBody } from "reactstrap";
import BForm from "./form";

interface IProps {
	data?: any;
	isEdit?: boolean;
}

const BPIC = (props: IProps) => {
	const { data, isEdit } = props;
	const location = useLocation();

	return (
		<Card className="b-pic">
			<CardBody>
				<p className="b-pic-name">
					{isEdit ? (
						<>
							<BForm forms={[data?.formName]} />
							<div className="mr-5" />
						</>
					) : (
						data?.name
					)}
					{isEdit ? (
						<BForm forms={[data?.formPosition]} />
					) : (
						<Badge className="b-pic-position">
							{data?.position}
						</Badge>
					)}
				</p>
				{data?.name ? (
					<div
						className={
							isEdit ? "b-pic-name mt-2" : "b-pic-data mt-2"
						}
					>
						{isEdit ? (
							<>
								<BForm forms={[data?.formPhone]} />
								<div className="mr-5" />
							</>
						) : (
							<p className="b-pic-phone">+62 {data?.phone}</p>
						)}
						{isEdit && <BForm forms={[data?.formEmail]} />}
						{!!data?.email && !isEdit && (
							<>
								<div className="break-line-v" />
								<p className="b-pic-email">{data?.email}</p>
							</>
						)}
					</div>
				) : (
					"-"
				)}
			</CardBody>
		</Card>
	);
};

export default BPIC;
