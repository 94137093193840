import React from "react";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

const useListSearchParams = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	let page = 1;
	const pageParam = searchParams.get("page");
	if (pageParam) {
		const n = parseInt(pageParam, 10);
		if (!isNaN(n) && n > 1) {
			page = n;
		}
	}

	const sizeOptions = [10, 15, 20];
	let size = sizeOptions[0];
	const sizeParam = searchParams.get("size");
	if (sizeParam) {
		const n = parseInt(sizeParam, 10);
		if (!isNaN(n) && sizeOptions.includes(n)) {
			size = n;
		}
	}

	const date: DRange = React.useMemo((): DRange => {
		const startDate = searchParams.get("startDate");
		const endDate = searchParams.get("endDate");
		return {
			...(startDate
				? {
						startDate: moment(parseInt(startDate)).toDate()
				  }
				: {
						startDate: new Date()
				  }),
			...(endDate && {
				endDate: moment(parseInt(endDate)).toDate()
			})
		};
	}, [searchParams.get("startDate"), searchParams.get("endDate")]);

	const setPage = (page: number) => {
		if (page > 0) {
			searchParams.set("page", page.toString());
			setSearchParams(searchParams);
		}
	};

	const setSize = (size: number) => {
		if (size > 0) {
			searchParams.set("size", size.toString());
			setSearchParams(searchParams);
		}
	};

	const setDate = (date: DRange) => {
		const newDate = new Date(moment(date.endDate).valueOf() - 1);
		newDate.setDate(newDate.getDate() + 1);

		searchParams.set(
			"startDate",
			moment(date.startDate).valueOf().toString()
		);
		searchParams.set("endDate", newDate.valueOf().toString());
		setSearchParams(searchParams);
	};

	const setParam = (key: string, value: string) => {
		searchParams.set(key, value);
		setSearchParams(searchParams);
	};

	const setParams = (params: { key: string; value: string | any }[]) => {
		if (!params.length) return;
		for (const param of params) {
			if (param.key === "date") {
				const newDate = new Date(
					moment(param.value.endDate).valueOf() - 1
				);
				newDate.setDate(newDate.getDate() + 1);

				searchParams.set(
					"startDate",
					moment(param.value.startDate).valueOf().toString()
				);
				searchParams.set("endDate", newDate.valueOf().toString());
			} else {
				searchParams.set(param.key, param.value);
			}
		}
		setPage(1);
		setSearchParams(searchParams);
	};

	const clearParams = () => {
		setSearchParams();
	};

	const removeParams = (key: string) => {
		searchParams.delete(key);
		setSearchParams(searchParams);
	};

	return {
		page,
		setPage,
		date,
		size,
		setSize,
		setDate,
		clearParams,
		setParam,
		searchParams,
		removeParams,
		setParams
	};
};

export default useListSearchParams;
