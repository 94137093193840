import React, { useMemo, useState, useEffect } from "react";
import {
	Button,
	Card,
	CardBody,
	CardTitle,
	Container,
	Dropdown,
	DropdownMenu,
	DropdownToggle,
	Form,
	FormGroup,
	Input,
	Label,
	Row
} from "reactstrap";
import Header from "../../../components/Headers/Header";
import { FinanceService } from "api/axios";
import { Loading, MTable, StatusChip } from "components";
import { formatDate, formatIDR } from "helper";
import { useListSearchParams } from "hooks";
import { useDebounce } from "use-debounce";
import { DateRangePicker } from "react-date-range";
import CreateInvoice from "./createInvoice";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openAlert } from "store/features/alert/AlertSlice";
import { useAppSelector } from "store/hooks";
import { getRouteName, routeMapping } from "routes";
import moment from "moment";

interface IFilterDate {
	state: DRange[];
	setState: React.Dispatch<
		React.SetStateAction<{
			date?: DRange[] | undefined;
			sales?:
			| {
				id?: string | undefined;
				fullName?: string | undefined;
				options?: options[] | undefined;
			}
			| undefined;
		}>
	>;
}

const invoiceColumns = [
	{
		name: "number",
		label: "No. Tagihan",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "displayName",
		label: "Nama Pelanggan",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "paymentType",
		label: "Metode Pembayaran",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "status",
		label: "Status",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				return <StatusChip value={value} />;
			}
		}
	},
	{
		name: "netDays",
		label: "Jatuh Tempo"
	},
	{
		name: "paymentCondition",
		label: "Syarat Pembayaran",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "issuedDate",
		label: "Tanggal Tagihan",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "dueDateView",
		label: "Tanggal Jatuh Tempo",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "overDue",
		label: "Lewat Jatuh Tempo",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				// console.log(value, " ini dari overDue");

				return <StatusChip value={value} />;
			}
		}
	},
	{
		name: "amountDue",
		label: "Sisa Tagihan",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "total",
		label: "Jumlah Tagihan",
		options: {
			filter: false,
			sort: false
		}
	}
];

export default function Invoice() {
	// const params = useParams();
	// const id = params?.id;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		page,
		setPage,
		size,
		setSize,
		date,
		setParams,
		clearParams,
		removeParams
	} = useListSearchParams();

	const location = useLocation();
	const permission = useAppSelector((state) => state.user.permission);
	const invoicePermission = useMemo(() => {
		const routeName = getRouteName(location.pathname, routeMapping);

		if (routeName) {
			return permission[routeName];
		}
		return {};
	}, [location, permission]);

	const [invoiceList, setInvoiceList] = useState<InvoiceTableData[]>([]);
	const [totalItems, setTotalItems] = useState<number>();
	const [currentPage, setCurrentPage] = useState<number>();
	const [customerNameSearch, setCustomerNameSearch] = useState<string | null>(
		""
	);
	const [invoiceNumSearch, setInvoiceNumSearch] = useState<string | null>("");
	const [dateIssuedQ] = useDebounce(date, 500);
	const [searchQName] = useDebounce(customerNameSearch, 500);
	const [searchQInvoice] = useDebounce(invoiceNumSearch, 500);
	// const [dateIssuedFilter, setDateIssuedFilter] = useState<DRange | null>();
	// const [dateDueDateFilter, setDateDueDateFilter] = useState<DRange | null>();
	// const [dateDueDateQ] = useDebounce(dateDueDateFilter, 500);

	// Date Range
	const [isShowCalendarIssuedDate, setIsShowCalendarIssuedDate] =
		useState<boolean>(true);
	const [isOpenIssuedDate, setIsOpenIssuedDate] = useState<boolean>(false);
	const toggleIssueDate = () => setIsOpenIssuedDate((curr) => !curr);
	// 	const [isShowCalendarDueDate, setIsShowCalendarDueDate] =
	// 		useState<boolean>(true);
	// const [isOpenDueDate, setIsOpenDueDate] = useState<boolean>(false);
	// const toggleDueDate = () => setIsOpenDueDate((curr) => !curr);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const invoiceNumParam = queryParams.get("search?invoice-number");
		const customerNameParam = queryParams.get("search?customer-name");
		if (invoiceNumParam) {
			setInvoiceNumSearch(invoiceNumParam);
		} else {
			setInvoiceNumSearch("");
		}
		if (customerNameParam) {
			setCustomerNameSearch(customerNameParam);
		} else {
			setCustomerNameSearch("");
		}
	}, [location.search]);

	const [state, setState] = useState<{
		date?: DRange[];
	}>({
		date: [
			{
				startDate: date.startDate,
				endDate: date.endDate,
				key: "selection"
			}
		]
	});

	// Button Save for Filter Date Range
	const onSaveIssued = () => {
		toggleIssueDate();
		const params = [];
		// console.log(state, "masuk sini ga sih?");
		for (const key of Object.keys(state)) {
			if (key === "date") {
				if (state?.date?.[0]) {
					params.push({
						key: "date",
						value: state?.date?.[0]
					});
				}
			}
		}
		setParams(params);
		// console.log(params, "ini apa");
	};

	// const onSaveDue = () => {
	// 	toggleDueDate();
	// 	const params = [];
	// 	for (const key of Object.keys(state)) {
	// 		if (key === "date") {
	// 			if (state?.date?.[0]) {
	// 				params.push({
	// 					key: "date",
	// 					value: state?.date?.[0]
	// 				});
	// 			}
	// 		}
	// 	}
	// 	setDateDueDateFilter(params[0]?.value);
	// 	setParams(params);
	// };

	// Clear Button Date Range Filter
	const onClearIssued = (key: "date" | "all") => () => {
		if (key === "all") {
			toggleIssueDate();
			clearParams();
			return;
		}
	};

	// const onClearDue = (key: "date" | "all") => () => {
	// 	if (key === "all") {
	// 		toggleDueDate();
	// 		clearParams();
	// 		setDateDueDateFilter(null);
	// 		return;
	// 	}
	// };

	const FilterDate = ({ state, setState }: IFilterDate) => {
		return (
			<DateRangePicker
				onChange={(item) => {
					setState((prev) => ({
						...prev,
						date: [item.selection as DRange]
					}));
				}}
				months={1}
				direction="horizontal"
				scroll={{ enabled: false }}
				ranges={state}
			/>
		);
	};

	// const { isLoading } =
	// Query Summary
	const { bp } = useAppSelector((store) => store.batching);

	const { refetch, isLoading, isFetchedAfterMount } = useQuery<
		ApiInvoices,
		ApiError
	>(
		[
			"query-getinvoice",
			page,
			size,
			searchQName,
			searchQInvoice,
			dateIssuedQ,
			bp?.valueStr
			// dateDueDateQ
		],
		async () => {
			return await FinanceService.getAllInvoice(page, size, {
				customerName: searchQName,
				invoiceNumber: searchQInvoice,
				date: dateIssuedQ,
				batchingPlantId: bp?.valueStr
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const invoice = res.data;
				// console.log(invoice, "data invoice");
				const payType = {
					CBD: "CASH",
					CREDIT: "CREDIT"
				};
				type paymentType = "CBD" | "CREDIT";
				const mappedData = invoice.data.map((data) => {
					return {
						id: data.id,
						number: data.number,
						displayName: data.Project?.Customer?.displayName,
						paymentType: data.paymentType
							? payType[data.paymentType as paymentType]
							: "-",
						status: data.status ? data.status : "-",
						paymentDuration: data.paymentDuration
							? data.paymentDuration + " Days"
							: "-",
						paymentCondition: data.paymentCondition
							? data.paymentCondition
							: "-",
						issuedDate: data?.issuedDate
						? moment(data?.issuedDate).format(
								"DD MMM YYYY"
						  )
						: "-",
						dueDate: formatDate(data.dueDate),
						overDue: data.dueDateDifference
							? data.dueDateDifference + " Days"
							: "-",
						amountDue: data?.accurateData?.primeOwing
							? "Rp. " + formatIDR(data?.accurateData?.primeOwing)
							: "-",
						total: data.total ? "Rp. " + formatIDR(data.total) : "-",
						netDays: `${data?.accurateData?.paymentTerm?.netDays ?? 0} Hari`,
						dueDateView: data?.accurateData?.dueDateView
							? moment(data?.accurateData?.dueDateView).format(
									"DD MMM YYYY"
							)
							: "-"
					};
				});
				setInvoiceList(mappedData);
				setTotalItems(invoice.totalItems);
				const currPage = invoice.currentPage;
				if (currPage) {
					setCurrentPage(currPage);
				}
			},
			onError: (err) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const handleInvoiceNumSearch = (newValue: string) => {
		setInvoiceNumSearch(newValue);

		const queryParams = new URLSearchParams();
		queryParams.set("page", "1");

		if (newValue) {
			queryParams.set("search?invoice-number", newValue);
		}

		if (customerNameSearch) {
			queryParams.set("search?customer-name", customerNameSearch);
		}

		navigate({
			pathname: location.pathname,
			search: queryParams.toString(),
		});
	};

	const handleCustomerNameSearch = (newValue: string) => {
		setCustomerNameSearch(newValue);

		const queryParams = new URLSearchParams();
		queryParams.set("page", "1");

		if (invoiceNumSearch) {
			queryParams.set("search?invoice-number", invoiceNumSearch);
		}

		if (newValue) {
			queryParams.set("search?customer-name", newValue);
		}

		navigate({
			pathname: location.pathname,
			search: queryParams.toString(),
		});
	};

	// if (isLoading) {
	// 	return <Loading />;
	// }
	// create
	const [localDialog, setLocalDialog] = React.useState({
		isOpen: false,
		onClose: () => setLocalDialog({ ...localDialog, isOpen: false }),
		onOpen: () => setLocalDialog({ ...localDialog, isOpen: true })
	});
	const [loadingCreate, setLoadingCreate] = React.useState(false);

	const handleCreateLoading = (isLoading: boolean) => {
		setLoadingCreate(isLoading);
	};

	if (loadingCreate || (isLoading && isFetchedAfterMount)) {
		return <Loading />;
	}

	return (
		<>
			<Header />

			<Container style={{ position: "relative", top: "-10rem" }} fluid>
				<Row>
					<div className="col">
						{/* Invoice Table */}
						<Card style={{ marginTop: "10px" }}>
							<CardBody>
								{invoicePermission &&
									invoicePermission.create ? (
									<Button
										style={{ float: "right" }}
										onClick={() => {
											localDialog.onOpen();
										}}
										size="sm"
										color="success"
										className="confirm-button approve-button"
									>
										Buat Tagihan
									</Button>
								) : null}

								<CardTitle
									style={{
										display: "flex",
										justifyContent: "flex-start"
									}}
								>
									<Form>
										<FormGroup
											style={{
												display: "flex",
												gap: "1rem"
											}}
										>
											<Input
												placeholder="NO. TAGIHAN"
												value={invoiceNumSearch ?? ""}
												onChange={(e) => handleInvoiceNumSearch(e.target.value)}
											/>

											<Input
												placeholder="NAMA PELANGGAN"
												value={customerNameSearch ?? ""}
												onChange={(e) => handleCustomerNameSearch(e.target.value)}
											/>

											<Dropdown
												isOpen={isOpenIssuedDate}
												toggle={() => null}
												className="dropdown-min"
											>
												<DropdownToggle
													caret
													role="button"
													size="md"
													onClick={toggleIssueDate}
													outline
												>
													TANGGAL TAGIHAN
												</DropdownToggle>

												<DropdownMenu>
													<div className="dropdown-item d-flex justify-content-end">
														<Button
															onClick={() => {
																if (
																	!isShowCalendarIssuedDate
																) {
																	setState({
																		...state,
																		date: [
																			{
																				startDate:
																					date.startDate,
																				endDate:
																					date.endDate,
																				key: "selection"
																			}
																		]
																	});
																} else {
																	setState({
																		...state,
																		date: []
																	});
																	removeParams(
																		"startDate"
																	);
																	removeParams(
																		"endDate"
																	);
																}
																onClearIssued(
																	"date"
																)();
																setIsShowCalendarIssuedDate(
																	(curr) =>
																		!curr
																);
															}}
															size="sm"
															color="secondary"
															outline
														>
															{isShowCalendarIssuedDate
																? "Seluruh Waktu"
																: "Pilih Tanggal"}
														</Button>
													</div>
													<div className="dropdown-item">
														{isShowCalendarIssuedDate ? (
															<>
																<Label for="date">
																	Date
																</Label>
																<div id="date">
																	<FilterDate
																		state={
																			state.date as DRange[]
																		}
																		setState={
																			setState
																		}
																	/>
																</div>
															</>
														) : null}
													</div>

													{/* Footer Dropdown */}
													<div className="dropdown-item flex">
														<Button
															color="primary"
															onClick={
																onSaveIssued
															}
														>
															Save
														</Button>
														<Button
															onClick={onClearIssued(
																"all"
															)}
														>
															Clear
														</Button>
													</div>
												</DropdownMenu>
											</Dropdown>
										</FormGroup>
									</Form>
								</CardTitle>

								{/* Line break */}
								<div className="break-line"></div>

								<MTable
									tableTitle=""
									columns={invoiceColumns}
									tableData={invoiceList}
									currentPage={currentPage}
									rowsPerPage={size}
									rowsPerPageOptions={[10, 15, 100]}
									totalItems={totalItems as number}
									selectableRowsHideCheckboxes={true}
									searchPlaceholder="Cari berdasarkan no tagihan atau nama pelanggan"
									setPage={(page) => {
										// console.log(page, " pras");
										setPage(page);
									}}
									setRowsPerPage={(size) => {
										setSize(size);
									}}
									isSearchVisible={false}
									options={{
										onRowClick(rowData, rowMeta) {
											const id =
												invoiceList[rowMeta.rowIndex]
													?.id;
											// navigate(`/order/delivery-orders/${item.id}`);/
											navigate(`/finance/invoice/${id}`);
										}
									}}
								/>
							</CardBody>
						</Card>
					</div>
				</Row>
			</Container>

			<CreateInvoice
				localDialog={localDialog}
				setLocalDialog={setLocalDialog}
				refetch={refetch}
				handleCreateLoading={handleCreateLoading}
			/>
		</>
	);
}
