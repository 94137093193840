import React from "react";
import { BoxArrowUpRight } from "react-bootstrap-icons";

interface IProps {
	data?: any[];
	className?: string;
}

const BImageList = (props: IProps) => {
	const { className } = props;
	const data = props.data || [];

	return (<div className={`b-image-list ${className}`} style={{ alignItems: "flex-start" }}>
		{data?.map((v, idx) => {
			return (<div className="card-photo" key={idx}>
				<img
					src={v?.data?.url}
				/>
				<div className="overlay"
					onClick={(e) => {
						e.preventDefault();
						window.open(v?.data?.url, "_blank");
					}}
				>
					<BoxArrowUpRight color="white" />
				</div>
			</div>);
		})}
	</div>
	);
};

export default BImageList;
