import React from "react";
import styles from "./index.module.scss";

export default function LoadingSpinner() {
	return (
		<div className={styles.ldsSpinner}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
}
