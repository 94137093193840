import React, { useEffect, useRef, useState, useMemo } from "react";
import { getStatusTrx, getStatusByType } from "helper";

import "split-pane-react/esm/themes/default.css";
import styles from "./index.module.scss";
import LiveVehicleCamera from "components/LiveVehicle/Camera";
import { InventoryService } from "api/axios";
import LiveVehicleStatus from "components/LiveVehicle/Status";
import LiveVehicleMap from "components/LiveVehicle/Map";
import SplitPane, { Pane } from "split-pane-react";
import seedrandom from "seedrandom";
import CameraProview from "components/LiveVehicle/CameraProview";
import { useAppSelector } from "store/hooks";

const Track = () => {
	// const [vehicles, setVehicles] = useState(null);
	const [vehicleIdsSorted, setVehicleIdsSorted] = useState([]);
	// const [camVehicles, setCamVehicles] = useState(null);
	// const [showCams, setShowCams] = useState({});
	const [vehiclesObj, setVehiclesObj] = useState({});
	const [hasCamOn, setHasCamOn] = useState(false);
	// const [loadingCams, setLoadingCams] = useState({});
	const camVehicleRef = useRef([]);
	const [sizes, setSizes] = useState(["40%", "100%"]);

	const { options } = useAppSelector((state) => state.batching);

	const batchingPlants = useMemo(() => {
		const bps = options.map((item)=> {
			const {id, code, address} = item
			return {
				id, 
				label: code,
				...address
			}
		})
		
		return bps
	}, [options]);

	const getLiveVehicles = async () => {
		try {
			if (camVehicleRef.current) {
				for (let cv of camVehicleRef.current) {
					cv.destroyPlayers();
				}
			}
			const vehiclesNetwork = await InventoryService.getLiveVehicles();
			// console.log({ vehicles }, "test ");
			const _vehicles = vehiclesNetwork.data
				.map((vehicle) => {
					let status = vehicle?.do?.status || "";
					const _status =
						getStatusByType(status, "VEHICLE") ?? status ?? "";
					let statusTxt = getStatusTrx(_status);
					return {
						...vehicle,
						do: statusTxt == "Selesai" ? undefined : vehicle?.do
					};
				})
				.sort((a, b) => {
					return a.internalId < b.internalId ? 1 : -1;
				})
				.sort((a, b) => {
					return !!a.do < !!b.do ? 1 : -1;
				});
			setVehicleIdsSorted(_vehicles.map((vehicle) => vehicle.id));
			// const _camVehicles = _vehicles.filter((c) => c.hasCamera);
			// setVehicles(_vehicles);
			// setCamVehicles(_camVehicles);
			const _vehiclesObj = {};
			for (let vehicle of _vehicles) {
				console.log(
					seedrandom(vehicle?.do?.Project?.Customer?.displayName)(),
					vehicle?.do
				);
				if (vehicle.isGps || vehicle.hasCamera) {
					_vehiclesObj[vehicle?.id] = {
						...vehicle,
						color: `hsla(${~~(
							360 *
							seedrandom(
								vehicle?.do?.Project?.Customer?.displayName
							)()
						)}, 70%,  72%, 0.8)`,
						gpsShown: !!vehicle?.do,
						selectedGpsType: vehicle?.gpsType,
						gpsTypes: Object.keys(vehicle?.gpsData || {})
					};
				}
			}
			setVehiclesObj(_vehiclesObj);
		} catch (e) {
			console.log(e);
		}
	};

	const getLiveVehiclesTimed = async () => {
		try {
			console.log("getting live");
			const vehiclesNetwork = await InventoryService.getLiveVehicles();
			const _vehicles = vehiclesNetwork.data
				.map((vehicle) => {
					let status = vehicle?.do?.status || "";
					const _status =
						getStatusByType(status, "VEHICLE") ?? status ?? "";
					let statusTxt = getStatusTrx(_status);
					return {
						...vehicle,
						do: statusTxt == "Selesai" ? undefined : vehicle?.do
					};
				})
				.sort((a, b) => {
					return a.internalId < b.internalId ? 1 : -1;
				})
				.sort((a, b) => {
					return !!a.do < !!b.do ? 1 : -1;
				});
			setVehicleIdsSorted(_vehicles.map((vehicle) => vehicle.id));
			// setVehicles(_vehicles);
			setVehiclesObj((vO) => {
				const vehiclesObj = JSON.parse(JSON.stringify(vO));
				for (let vehicle of _vehicles) {
					if (vehicle?.isGps || vehicle?.hasCamera) {
						vehiclesObj[vehicle?.id] = {
							...vehiclesObj[vehicle?.id],
							...vehicle,
							gpsShown:
								!vehiclesObj[vehicle?.id]?.do && !!vehicle?.do
									? true
									: !!vehiclesObj[vehicle?.id]?.do &&
									  !vehicle?.do
									? false
									: vehiclesObj[vehicle?.id]?.gpsShown,
							// selectedGpsData:
							// 	vehiclesObj[vehicle.id].gpsData?.[
							// 		vehicle.selectedGpsType || vehicle.gpsType
							// 	] || vehiclesObj[vehicle.id].selectedGpsData
							color: `hsla(${~~(
								360 *
								seedrandom(
									vehicle?.do?.Project?.Customer?.displayName
								)()
							)}, 70%,  72%, 0.8)`,
							gpsTypes: Object.keys(vehicle?.gpsData || {})
						};
					}
				}
				return vehiclesObj;
			});
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getLiveVehicles();
		const getLoop = setInterval(() => {
			getLiveVehiclesTimed();
		}, 10000);

		return () => clearInterval(getLoop);
	}, []);

	useEffect(() => {
		let result = false;
		for (let key in vehiclesObj) {
			if (vehiclesObj[key]?.camShown) result = true;
		}
		setHasCamOn(result);
	}, [vehiclesObj]);

	const onToggleShowCam = (id, override) => {
		setVehiclesObj((p) => {
			let set = override;
			if (!!p[id]?.camShown || override === false) set = false;
			else set = true;
			return { ...p, [id]: { ...p[id], camShown: set } };
		});
	};

	const onToggleLoadingCam = (id, override) => {
		setVehiclesObj((p) => {
			let set = override;
			if (!!p[id]?.camLoading || override === false) set = false;
			else set = true;
			return { ...p, [id]: { ...p[id], camLoading: set } };
		});
	};

	const onToggleShowGps = (id, override) => {
		setVehiclesObj((p) => {
			let set = override;
			if (!!p[id]?.gpsShown || override === false) set = false;
			else set = true;
			return { ...p, [id]: { ...p[id], gpsShown: set } };
		});
	};

	const onChangeSelectedGpsType = (id, val) => {
		setVehiclesObj((p) => {
			return {
				...p,
				[id]: {
					...p[id],
					selectedGpsType: val,
					selectedGpsData:
						p[id]?.gpsData?.[val] || p[id]?.selectedGpsData
				}
			};
		});
	};

	return (
		<div className={styles.mainContainer}>
			<div className={styles.vehiclesContainer}>
				{/* {Object.values(vehiclesObj || {})?.map((vehicle, i) => { */}
				{(vehicleIdsSorted || [])?.map((id, i) => {
					const vehicle = (vehiclesObj || {})[id];
					if (vehicle)
						return (
							<LiveVehicleStatus
								key={id}
								{...vehicle}
								className={styles.vehicleStatus}
								onToggleShowCam={onToggleShowCam}
								onToggleShowGps={onToggleShowGps}
								// camShown={!!showCams[vehicle.id]}
								// camLoading={!!loadingCams[vehicle.id]}
								camShown={!!vehicle?.camShown || false}
								camLoading={!!vehicle?.camLoading || false}
								gpsShown={!!vehicle?.gpsShown || false}
								selectedGpsType={vehicle?.selectedGpsType || ""}
								gpsTypes={vehicle?.gpsTypes || []}
								onChangeSelectedGpsType={
									onChangeSelectedGpsType
								}
							/>
						);
					return <></>;
				})}
			</div>
			<SplitPane
				className={styles.splitPane}
				sizes={sizes}
				onChange={(sizes) => setSizes(sizes)}
			>
				<Pane className={styles.pane}>
					<div className={styles.camerasContainer}>
						<div className={styles.camerasContainerInner}>
							{/* {Object.values(vehiclesObj || {})?.map(
								(vehicle, i) => { */}
							{(vehicleIdsSorted || [])?.map((id, i) => {
								const vehicle = (vehiclesObj || {})[id];
								if (vehicle && vehicle?.hasCamera)
									return (
										<CameraProview
											{...vehicle}
											className={[
												styles.camVehicle,
												vehicle?.camShown
													? ""
													: styles.camVehicleHidden
											].join(" ")}
											key={id}
											ref={(el) =>
												(camVehicleRef.current[i] = el)
											}
											onToggleShowCam={onToggleShowCam}
											onToggleLoadingCam={
												onToggleLoadingCam
											}
											camShown={
												!!vehicle?.camShown || false
											}
											camLoading={
												!!vehicle?.camLoading || false
											}
										/>
									);
								return <></>;
							})}
						</div>
						{!hasCamOn && (
							<div className={styles.cameraContainerOverlay}>
								<p>
									Nyalakan setidaknya satu kamera TM untuk
									melihat kamera secara live!
								</p>
							</div>
						)}
					</div>
				</Pane>
				<Pane className={styles.pane}>
					<div className={styles.gpsContainer}>
						<LiveVehicleMap
							// vehiclesObj={vehiclesObj}
							vehicles={Object.values(vehiclesObj || {})}
							batchingPlants={batchingPlants}
						/>
					</div>
				</Pane>
			</SplitPane>
		</div>
	);
};

export default Track;
