import { Header } from "components";
import React from "react";
import { useLocation } from "react-router-dom";
import { Container, Row } from "reactstrap";

export default function NotFound() {
	const location = useLocation();

	return (
		<>
			<Header />
			<Container>
				<Row>
					<div className="col d-flex justify-content-center">
						{location.pathname === "/" ? (
							<h1>
								SILAHKAN PILIH MENU YANG INGIN DI AKSES DI
								SEBELAH KIRI
							</h1>
						) : (
							<h1>
								HALAMAN TIDAK DITEMUKAN / ANDA TIDAK MEMILIKI
								AKSES HALAMAN
							</h1>
						)}
					</div>
				</Row>
			</Container>
		</>
	);
}
