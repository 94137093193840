import React, { useEffect, useState } from "react";
import AdminLayout from "../src/layouts/Admin";
import AuthLayout from "../src/layouts/Auth.js";
import SidebarlessLayout from "../src/layouts/Sidebarless.js";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import ProtectedRoutes from "../src/privateRoute";
import { Provider } from "react-redux";
import { store } from "./store/store";
import BDialog from "./components/B/dialog";
import { BAlert } from "components";
import { LoadScript } from "@react-google-maps/api";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";

import "./app.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setIsHideSideBar } from "store/features/alert/AlertSlice";
import { changeBP } from "store/features/batching/BatchingSlice";

import jwtDecode from "jwt-decode";
import { setUserRole } from "store/features/user/UserSlice";
import { setAccurateDisabled } from "store/features/setting/SettingSlice";

interface TokenDecoded {
	id: string;
	fullName: string;
	email: string;
	phone: string;
	type: string;
	roles: string[];
	iat: number;
	exp: number;
}

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			retry: false,
			staleTime: 5 * 60 * 1000,
			cacheTime: 0
		}
	}
});

const tokenDecode = (token: string) => {
	let result = {} as TokenDecoded;
	try {
		result = jwtDecode(token);
	} catch (err) {
		console.log(err);
	}
	return result;
};

// window.addEventListener("message", (event) => {
// 	if (event.data?.accessToken) {
// 		localStorage.setItem("accurateCreds", JSON.stringify(event.data));
// 	}
// });

export default function App() {
	const [isTokenLoaded, setisTokenLoaded] = useState(false);
	const dispatch = useAppDispatch();
	useEffect(() => {
		window.addEventListener("message", (event) => {
			if (event.data?.accessToken) {
				localStorage.setItem("AccessToken", event.data?.accessToken);
				const decoded = tokenDecode(event.data?.accessToken);
				dispatch(setUserRole(decoded.roles));
				setisTokenLoaded(true);
			}
			if (event.data?.isHideSideBar) {
				try {
					const isHideSideBarPased: boolean = JSON.parse(
						event.data?.isHideSideBar
					);
					if (isHideSideBarPased) {
						dispatch(setIsHideSideBar(isHideSideBarPased));
					}
				} catch (error) {
					console.log(error, "addEventListener error ");
				}
			}
			if (event.data?.selectedBp) {
				const selectedBp = JSON.parse(event.data?.selectedBp);

				dispatch(
					changeBP({
						payload: {
							valueStr: selectedBp?.id,
							labelStr: selectedBp?.name
						}
					})
				);
			}
			if (event.data?.accurateCreds) {
				localStorage.setItem(
					"accurateCreds",
					event.data?.accurateCreds
				);
			}
			if (event.data?.isAccurateDisabled !== null || event.data?.isAccurateDisabled !== undefined) {
				dispatch(setAccurateDisabled(event.data?.isAccurateDisabled));
			}
		});
		const AccessToken = localStorage.getItem("AccessToken");
		if (AccessToken) {
			window.parent.postMessage(
				{
					accessToken: AccessToken
				},
				"*"
			);
		}
	}, []);

	return (
		<FlagsmithProvider
			options={{
				environmentID: process.env.REACT_APP_FLAGSMITH_KEY as string
			}}
			flagsmith={flagsmith}
		>
			{/* <Provider store={store}> */}
			<QueryClientProvider client={queryClient}>
				<BDialog />
				<BAlert />
				<BrowserRouter>
					<Routes>
						<Route path="/auth/*" element={<AuthLayout />} />
						<Route path="/sl/*" element={<SidebarlessLayout />} />
						{isTokenLoaded && (
							<Route
								path="/*"
								element={
									// <LoadScript
									// 	googleMapsApiKey="AIzaSyD8o1pRgkhQY9dcx8Uiqut-ruGKH5WUsH0"
									// 	libraries={["places"]}
									// >
									<ProtectedRoutes>
										{" "}
										<AdminLayout />{" "}
									</ProtectedRoutes>
									// {/* ?</LoadScript> */}
								}
							/>
						)}
					</Routes>
				</BrowserRouter>
			</QueryClientProvider>
			{/* </Provider> */}
		</FlagsmithProvider>
	);
}
