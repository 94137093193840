import React from "react";
import { Modal, ModalBody, Spinner } from "reactstrap";

const Loading = () => {

	return (
		<Modal isOpen={true} centered>
			<ModalBody>
				<div style={{
					display: "flex",
					justifyContent: "center",
					alignSelf: "center",
					alignItems: "center"
				}}>
					<Spinner style={{
						height: "3rem",
						width: "3rem",
						margin: "0 auto",
						// fontSize: 10
					}}>
``
					</Spinner>
				</div>
			</ModalBody>

		</Modal>
	);
};

export default Loading;
