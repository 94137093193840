import { LoadingButton } from "@mui/lab";
import { AccurateService, OrderService, ProductivityService } from "api/axios";
import { BStatus, Loading } from "components";
import React from "react";
import { ChevronLeft } from "react-bootstrap-icons";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { openAlert } from "store/features/alert/AlertSlice";
import { useAppDispatch } from "store/hooks";
import { Button, Card, CardBody, Table } from "reactstrap";
import BCard from "components/B/card";
import { ModalApproveTrialMix } from "./ModalApproveTrialMix";

export default function TrialMixDetail() {
	const params = useParams();
	const navigate = useNavigate();
	const id = params?.id;
	const dispatch = useAppDispatch();
	const [products, setProducts] = React.useState<any[]>([]);
	const [trialMixNumber, setTrialMixNumber] = React.useState<any[]>([]);
	const [status, setStatus] = React.useState<any>("");
	const [projectDetails, setProjectDetails] = React.useState<any>([]);
	const [trialMixProducts, setTrialMixProducts] = React.useState([]);
	const [summary, setSummary] = React.useState<any>([]);
	const [tmFiles, setTmFiles] = React.useState<any>([]);
	const [isApproveTm, setIsApproveTm] = React.useState<any>();
	const [mainPic, setMainPic] = React.useState<string>("");
	const [phoneMainPic, setPhoneMainPic] = React.useState<string>("");
	const [poNumber, setPoNumber] = React.useState<string>("");
	const [shippingAddress, setShippingAddress] = React.useState<string>("");
	const [accurateAddress, setAccureateAddress] = React.useState<string>("");
	const [accurateBranchId, setAccurateBranchId] = React.useState<number>(0);
	const [accurateCustomerNo, setAccurateCustomerNo] =
		React.useState<number>();
	const [quantityPO, setQuantityPO] = React.useState<any>();
	const [skuNames, setSkuNames] = React.useState<any>();

	const { isLoading, refetch, data } = useQuery<any, ApiError>(
		["query-trial-mix", id],
		async () => {
			return await ProductivityService.getOneTrialMix(id as string);
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const isCompany = !!res?.data?.Project?.companyName;
				const isCustomer = !!res?.data?.Customer?.name;
				setTrialMixNumber(res?.data?.number);
				setStatus(res?.data?.status);

				try {
					const modifiedProducts = res?.data?.TrialMixProducts.map(
						(el: any) => {
							return {
								...el,
								quantity: `${el.quantity} m³`,
								temperature: `${el.temperature} °C`,
								slump: `${el.slump} cm`,
								slumpTolerance: `± ${el.slumpTolerance} cm`,
								slumpLoss: `${el.slumpLoss} Jam`,
								strength: `${el.strength} Hari`
							};
						}
					);

					setProducts(modifiedProducts);
				} catch (error) {
					//error
				}

				try {
					const projectDetails = [
						(!isCompany && !isCustomer) ||
						(isCompany && !isCustomer)
							? {
									type: "noCustomer"
							  }
							: null,
						isCustomer && {
							name: "Tipe Pelanggan",
							type: "customerTypeChip",
							data:
								res?.data?.Project?.Customer?.type === "COMPANY"
									? "PERUSAHAAN"
									: res?.data?.Project?.Customer?.type
						},
						isCompany && !isCustomer
							? {
									name: "Tipe Pelanggan",
									type: "customerType",
									data: "PERUSAHAAN"
							  }
							: null,
						isCustomer
							? {
									name: "Nama Pelanggan",
									data:
										res?.data?.Project?.Customer
											?.displayName ?? "-"
							  }
							: null,
						{
							name: "Nama Proyek",
							data: res?.data?.Project?.name
						},
						isCompany
							? {
									name: "Perusahaan",
									data: res?.data?.Project?.Company
										?.displayName
							  }
							: null,
						{
							name: "PIC Utama",
							type: "pic",
							data: {
								name: res?.data?.Project?.mainPic?.name,
								position: res?.data?.Project?.mainPic?.position,
								phone: res?.data?.Project?.mainPic?.phone,
								email: res?.data?.Project?.mainPic?.email
							}
						},
						res?.data?.Project?.Pics.length > 1 && {
							name: "PICs",
							type: "pics",
							data: {
								pics: res?.data?.Project?.Pics,
								mainPIC: res?.data?.Project?.mainPic
							}
						},
						{
							name: "Alamat Penagihan",
							type: "address",
							data: {
								line1: res?.data?.Project?.BillingAddress
									?.line1,
								lat: res?.data?.Project?.BillingAddress?.lat,
								lon: res?.data?.Project?.BillingAddress?.lon
							}
						},
						{
							name: "Alamat Pengiriman",
							type: "address",
							data: {
								line1: res?.data?.Project?.ShippingAddress
									?.line1,
								lat: res?.data?.Project?.ShippingAddress?.lat,
								lon: res?.data?.Project?.ShippingAddress?.lon
							}
						}
					];

					const summary = [
						{
							name: "Nama Sales",
							type: "text",
							data: "Admin - Tech"
						},
						{
							name: "SPH",
							type: "text",
							data: res?.data?.QuotationLetter?.number
						},
						// {
						// 	name: "PO",
						// 	type: "text",
						// 	data: res?.data?.PurchaseOrder?.number ?? "-"
						// },
						{
							name: "SO",
							type: "text",
							data: res?.data?.PurchaseOrder?.brikNumber ?? "-"
						},
						{
							name: "Jadwal",
							type: "text",
							data:
								res?.data?.PurchaseOrder?.TrialMixSchedule
									?.number ?? "-"
						},
						{
							name: "Tanggal Pengujian & jam Pengujian",
							type: "datetime",
							data: res?.data?.date
						},
						{
							name: "Waktu Pembuatan",
							type: "datetime",
							data: res?.data?.createdAt
						}
					];

					const totalQuantityPO = res.data.TrialMixProducts.map(
						(item: any) => item.quantity
					);

					const sumQuantity = totalQuantityPO.reduce(
						(acc: any, curr: any) => acc + curr,
						0
					);

					setProjectDetails(projectDetails);
					setSummary(summary);
					setTrialMixProducts(res?.data?.TrialMixProducts);
					setMainPic(res?.data?.Project?.mainPic?.name);
					setPhoneMainPic(res?.data?.Project?.mainPic?.phone);
					setAccurateBranchId(res?.data?.branchAccurateId);
					setAccurateCustomerNo(
						res?.data?.Customer?.customerAccurateId
					);
					setQuantityPO(sumQuantity);
					setShippingAddress(
						res?.data?.Project?.ShippingAddress?.line1 as string
					);
				} catch (error) {
					//
				}

				try {
					const files =
						res?.data?.TrialMixFiles.map((el: any) => {
							return {
								name: el?.type,
								type: "file",
								data: el?.file
							};
						}) ?? [];
					setTmFiles(files);
				} catch (error) {
					//
				}
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	// get customer accurate detail
	const { data: customerAccurateDetail } = useQuery<any, ApiError>(
		["query-customer-accurate-detail", true],
		async () => {
			return await AccurateService.getCustomerAccurateDetail({
				id: data?.data?.Customer?.customerAccurateId as number
			});
		},
		{
			enabled: true,
			onError(err: any) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
			}
		}
	);

	const patchStatus = useMutation(
		async (status: "approve" | "decline") => {
			return await ProductivityService.updateTrialMix(
				id as string,
				status
			);
		},
		{
			onSuccess: (response) => {
				refetch();
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const handleButton = (status: "approve" | "decline") => () => {
		patchStatus.mutate(status);
	};

	const handleApproveTm = () => {
		setIsApproveTm(true);
	};

	if (isLoading || patchStatus.isLoading) {
		return <Loading />;
	}

	return (
		<div className="p-4 pt-6">
			{isApproveTm ? (
				<ModalApproveTrialMix
					isOpen={isApproveTm}
					setIsOpen={setIsApproveTm}
					refetch={refetch}
					trialMixId={params.id as string}
					trialMixProducts={trialMixProducts}
					customerAccurateId={accurateCustomerNo as number}
					customerAccurateName={
						(customerAccurateDetail?.data?.name as string) ?? ""
					}
					mainPic={mainPic}
					shippingAddress={shippingAddress}
					picPhone={phoneMainPic}
					quantity={quantityPO}
					accurateBranchId={accurateBranchId}
					defaultCustomer={
						customerAccurateDetail?.data
							? {
									id: customerAccurateDetail?.data?.id,
									name: customerAccurateDetail?.data?.name,
									mobilePhone:
										customerAccurateDetail?.data
											?.mobilePhone,
									whatsappNo:
										customerAccurateDetail?.data
											?.whatsappNo,
									customerNo:
										customerAccurateDetail?.data
											?.customerNo,
									email: customerAccurateDetail?.data?.email
							  }
							: undefined
					}
				/>
			) : null}

			<div className="d-flex flex-row align-items-center mb-2">
				<div
					className="b-back-btn border mr-3"
					onClick={() => {
						navigate(-1);
					}}
				>
					<ChevronLeft />
				</div>
				<h1 className="mb-0 mr-2">{trialMixNumber}</h1>
				<div className="mr-2">
					<BStatus className="ml-2">{status}</BStatus>
				</div>
				{status === "SUBMITTED" && (
					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
							flex: 1
						}}
					>
						<Button
							// onClick={handleButton("approve")}
							onClick={handleApproveTm}
							size="sm"
							color="success"
							className="confirm-button approve-button"
						>
							Approve
						</Button>
						<Button
							onClick={handleButton("decline")}
							size="sm"
							color="danger"
							className="confirm-button decline-button ml-1"
						>
							Decline
						</Button>
					</div>
				)}
			</div>

			{/* Trial mix detail */}
			<Card>
				<CardBody>
					<Table responsive hover striped>
						<thead>
							<tr>
								<th
									className="border-top-0"
									style={{ textAlign: "left" }}
								>
									No
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "left" }}
								>
									Nama
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "left" }}
								>
									SKU
								</th>

								<th
									className="border-top-0"
									style={{ textAlign: "left" }}
								>
									Volume
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "left" }}
								>
									Temperature
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "right" }}
								>
									Slump
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "center" }}
								>
									Slump Tolerance
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "right" }}
								>
									Slump Loss
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "right" }}
								>
									Metode Pengujian
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "right" }}
								>
									Strength
								</th>
							</tr>
						</thead>
						<tbody>
							{products?.map((item, idx) => (
								<tr key={idx}>
									<td style={{ textAlign: "left" }}>
										{idx + 1}
									</td>
									<td style={{ textAlign: "left" }}>
										{item?.RequestedProduct?.displayName}
									</td>
									<td style={{ textAlign: "left" }}>
										{item?.SKU?.sku ?? "-"}
									</td>
									<td style={{ textAlign: "left" }}>
										{item?.quantity}
									</td>
									<td style={{ textAlign: "left" }}>
										{item?.temperature}
									</td>
									<td style={{ textAlign: "right" }}>
										{item?.slump}
									</td>
									<td style={{ textAlign: "center" }}>
										{item?.slumpTolerance}
									</td>
									<td style={{ textAlign: "right" }}>
										{item?.slumpLoss}
									</td>
									<td
										style={{
											textAlign: "right",
											whiteSpace: "pre-line"
										}}
									>
										{item?.TrialMixTestingMethods.map(
											(method: any) =>
												method.testingMethod !==
												"Special"
													? method.testingMethod
													: method.specialMethod
										).join(",\n")}
									</td>
									<td style={{ textAlign: "right" }}>
										{item?.strength}
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</CardBody>
			</Card>
			<div className="d-flex flex-column flex-lg-row">
				<div
					className="d-flex flex flex-column mb-3"
					style={{ flex: 0.7 }}
				>
					<BCard
						className="mt-3"
						title="Ringkasan Trial Mix"
						data={summary}
					/>
				</div>
				<div className="spacer-v" />
				<div className="d-flex flex-column mt-3" style={{ flex: 0.3 }}>
					<BCard title="Rincian Proyek" data={projectDetails} />
					<i className="bi bi-download"></i>
					<BCard className="mt-3" title="Dokumen" data={tmFiles} />
				</div>
			</div>
		</div>
	);
}
