import React, { Dispatch, SetStateAction } from "react";
import { AccurateLogin, LocalDialog } from "components";
// import { Button } from "reactstrap";
interface Modalprops {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	isLoading?: boolean;
	isError?: boolean;
}
export default function ModalAccurateLogin({
	isOpen,
	setIsOpen,
	isLoading,
	isError
}: Modalprops) {
	// const [localDialog, setLocalDialog] = React.useState({
	// 	isOpen: false,
	// 	onClose: () => setLocalDialog((prev) => ({ ...prev, isOpen: false })),
	// 	onOpen: () => setLocalDialog((prev) => ({ ...prev, isOpen: true }))
	// });
	return (
		<>
			<LocalDialog
				isOpen={isOpen}
				backdrop={"static"}
				header="Login Dengan akun Accurate"
				onClose={() => {
					setIsOpen(false);
				}}
			>
				<AccurateLogin isError={isError} isLoading={isLoading} />
			</LocalDialog>
		</>
	);
}
