import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { toggleDialog } from "../../store/features/dialog/DialogSlice";

const BDialog = (props: { isLocalOpen?: any; localBody?: any; localOnClick?: any; localHeader?: any; onClose?: any; }) => {
	const { isLocalOpen, localBody, localOnClick, localHeader, onClose } = props;
	const { isOpen, body, onClick, header, backdrop } = useAppSelector(state => state.modal);
	const dispatch = useAppDispatch();

	const toggle = () => {
		dispatch(toggleDialog());
	};
	if (localBody && localOnClick) {
		return (
			<Modal isOpen={isLocalOpen} centered backdrop={backdrop}>
				{
					localHeader &&
					<ModalHeader>
						{localHeader}
					</ModalHeader>
				}
				<ModalBody>
					{localBody}
				</ModalBody>
				{
					localOnClick &&
					<ModalFooter>
						<Button color="error" onClick={onClose} >
							Tidak
						</Button>
						{
							typeof localOnClick === "function" &&
							<Button color="secondary" onClick={localOnClick} >
								Iya
							</Button>
						}
					</ModalFooter>
				}
			</Modal>
		);
	}
	return (
		<Modal isOpen={isOpen} centered backdrop={backdrop} toggle={toggle}>
			{
				header &&
				<ModalHeader toggle={toggle}>
					{header}
				</ModalHeader>
			}
			<ModalBody>
				{body}
			</ModalBody>
			{
				onClick &&
				<ModalFooter>
					<Button color="error" onClick={() => {
						dispatch(toggleDialog());
					}} >
						Tidak
					</Button>
					{
						typeof onClick === "function" &&
						<Button color="secondary" onClick={onClick} >
							Iya
						</Button>
					}
				</ModalFooter>
			}
		</Modal>
	);
};

export default BDialog;
