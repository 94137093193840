import { combineReducers, configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import DialogSlice from "./features/dialog/DialogSlice";
import AlertSlice from "./features/alert/AlertSlice";
import BatchingSlice from "./features/batching/BatchingSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import UserSlice from "./features/user/UserSlice";
import SettingSlice from "./features/setting/SettingSlice";

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["user", "batching"]
};

const rootReducer = combineReducers({
	modal: DialogSlice,
	alert: AlertSlice,
	user: UserSlice,
	batching: BatchingSlice,
	setting: SettingSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false
		}).concat(logger),
	devTools: process.env.NODE_ENV !== "production"
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
