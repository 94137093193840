import React from "react";
import { BoxArrowUpRight } from "react-bootstrap-icons";
import { Button } from "reactstrap";

export default function Files(value: any) {
	return (
		<ul
			className="mb-0"
			style={{
				padding: 0,
				listStylePosition: "inside",
				listStyleType: "none"
			}}
		>
			{(value.value as ApiFile[]).map((el, i) =>
				el.type == "LETTER" ? (
					<Button
						key={i}
						color="secondary"
						className="btn-sm shadow-none d-flex align-items-center"
						onClick={(e) => {
							e.preventDefault();
							window.open(el.File.url, "_blank");
						}}
					>
						{/* {el.File.name.slice(0, -9)} */}
						Buka <BoxArrowUpRight className="ml-1" />
					</Button>
				) : null
			)}
		</ul>
	);
}
