import { LoadingButton } from "@mui/lab";
import Select from "react-select";
import { LocalDialog } from "components";
import { Formik, Form } from "formik";
import { useLocalDialog } from "hooks";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FileEarmarkRichtext, Truck } from "react-bootstrap-icons";
import { FormGroup, Input, Label } from "reactstrap";
import { useMutation, useQuery } from "react-query";
import {
	AccurateService,
	CommonService,
	HubService,
	OrderService
} from "api/axios";
import { useDebounce } from "use-debounce";
import { openAlert } from "store/features/alert/AlertSlice";
import { useAppDispatch } from "store/hooks";
import * as Yup from "yup";
import ReactSelect, {
	components,
	OptionProps,
	SingleValue
} from "react-select";
import styles from "./modalApproveDO.module.scss";
import AccurateCustomerSelector from "views/finance/payment/AccurateCustomerSelector";
import { useAppSelector } from "store/hooks";

interface DetailExpense {
	_status?: DetailStateType;
	accountNo?: string;
	dataClassification10Name?: string;
	dataClassification1Name?: string;
	dataClassification2Name?: string;
	dataClassification3Name?: string;
	dataClassification4Name?: string;
	dataClassification5Name?: string;
	dataClassification6Name?: string;
	dataClassification7Name?: string;
	dataClassification8Name?: string;
	dataClassification9Name?: string;
	departmentName?: string;
	expenseAmount?: number;
	expenseName?: string;
	expenseNotes?: string;
	id?: number;
	salesQuotationNumber?: string;
}

interface DetailItem {
	itemNo: string;
	unitPrice: number;
	_status?: DetailStateType;
	dataClassification10Name?: string;
	dataClassification1Name?: string;
	dataClassification2Name?: string;
	dataClassification3Name?: string;
	dataClassification4Name?: string;
	dataClassification5Name?: string;
	dataClassification6Name?: string;
	dataClassification7Name?: string;
	dataClassification8Name?: string;
	dataClassification9Name?: string;
	departmentName?: string;
	detailName?: string;
	detailNotes?: string;
	id?: number;
	itemCashDiscount?: number;
	itemDiscPercent?: string;
	itemUnitName?: string;
	projectNo?: string;
	quantity?: number;
	salesmanListNumber?: string[];
	useTax1?: boolean;
	useTax2?: boolean;
	useTax3?: boolean;
	salesQuotationNumber?: string;
}

interface PayloadSO {
	customerNo: string;
	detailExpense?: DetailExpense[];
	detailItem?: DetailItem[];
	transDate: Date;
	branchId?: number;
	branchName?: string;
	cashDiscPercent?: string;
	cashDiscount?: number;
	currencyCode?: string;
	description?: string;
	fobName?: string;
	id?: number;
	inclusiveTax?: boolean;
	number?: string;
	paymentTermName?: string;
	poNumber?: string;
	rate?: number;
	shipDate?: Date;
	shipmentName?: string;
	taxable?: boolean;
	toAddress?: string;
	typeAutoNumber?: number;
	"X-Session-ID": string;
}

type DetailStateType = "delete";

const saleOrderSchemaNoAccurate = Yup.object().shape({});

const saleOrderSchema = Yup.object().shape({
	customerAccurateId: Yup.string().required("Nama Pelanggan harus dipilih *"),
	paymentTermName: Yup.string().required("Syarat Pembayaran harus dipilih *"),
	poNumber: Yup.string().required("No. PO harus diisi *"),
	shippingAddress: Yup.string().required("Alamat harus diisi *"),
	branchId: Yup.string().required("Cabang harus dipilih *"),
	date: Yup.string().required("Tanggal Pengiriman harus dipilih *"),
	mainPic: Yup.string().required("PIC Pengiriman harus diisi *"),
	phoneMainPic: Yup.string().required("Telepon PIC harus diisi *"),
	approvalSKU: Yup.string().required("Persetujuan SKU harus dipilih *"),
	approvalCharge: Yup.string().required(
		"Persetujuan Biaya Mobilisasi harus dipilih *"
	),
	deliveryMethod: Yup.string().required("Jenis Pengiriman harus dipilih *"),
	taxMethod: Yup.string().required("PKP/Non PKP harus dipilih *"),
	volumePO: Yup.number().required("Volume PO harus diisi *")
});

export default function ModalApproveSO({
	isOpen,
	setIsOpen,
	refetch,
	poId,
	poNumber,
	poProductId,
	shippingAddress,
	accurateAddress,
	mainPic,
	phoneMainPic,
	accurateBranchId,
	batchingPlantId,
	accurateCustomerNo,
	customerAccurateId,
	customerAccurateName,
	defaultCustomer,
	quantity,
	sku,
	customerId
}: {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	refetch: () => void;
	poId: string;
	poNumber: string;
	shippingAddress: string;
	accurateAddress: string;
	mainPic: string;
	phoneMainPic: string;
	accurateBranchId?: number;
	batchingPlantId: string;
	accurateCustomerNo?: number;
	customerAccurateId?: number;
	customerAccurateName?: string;
	defaultCustomer?: CustomerAccurate;
	quantity: number;
	sku: string[];
	poProductId: string[];
	customerId?: string;
}) {
	const [branchPO, setBranchPO] = useState<any>();
	const [branchDetail, setBranchDetail] = useState<any>({});
	const [branchIdPO, setBranchIdPO] = useState<any>({});
	const [customerNo, setCustomerNo] = useState<number>();
	const [searchCustomer, setSearchCustomer] = useState(customerAccurateName);
	const [searchCustomerDebounced] = useDebounce(searchCustomer, 500);
	const [customersList, setCustomersList] = useState<CustomerAccurate[]>([]);
	const [customerPaging, setCustomerPaging] = React.useState(1);

	const { localDialog, setLocalDialog } = useLocalDialog();
	const dispatch = useAppDispatch();

	const [payloadSO, setPayloadSO] = React.useState<PayloadSO>();
	console.log(searchCustomerDebounced, "searchCustomerDebounced");

	//accurate disable
	const { isAccurateDisabled: accurateStatusDisabled } = useAppSelector(
		(state) => state.setting
	);
	const isAccurateDisabled =
		process.env.REACT_APP_IS_ACCURATE_DISABLED === "true" ||
		accurateStatusDisabled;

	// use Effect
	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		}
	}, [isOpen]);

	// get list customer accurate
	const { data: customersAccurate } = useQuery<any, ApiError>(
		["query-customer-accurate", customerPaging, searchCustomerDebounced],
		async () => {
			return await AccurateService.getListCustomerAccurate({
				page: customerPaging,
				keywords: searchCustomerDebounced
			});
		},
		{
			onSuccess(data) {
				setCustomersList((prev) => {
					return prev.concat(data?.data);
				});
				const defaultValueCustomer = data?.data?.d?.find(
					(cust: CustomerAccurate) => {
						return cust.id === customerAccurateId;
					}
				);

				if (defaultValueCustomer) {
					setPayloadSO((prev: any) => ({
						...prev,
						customerAccurate: defaultValueCustomer
					}));
				}
			},
			onError(err: any) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
				let message = "Accurate Error/ Tolong Login Ulang";

				if (err?.response?.data?.error?.data?.error) {
					message = `Accurate Error/ ${err?.response?.data?.error?.status} - ${err?.response?.data?.error?.data?.error}`;
				}
				dispatch(
					openAlert({
						body: message,
						color: "danger"
					})
				);
			}
		}
	);

	// get list payment term
	const { data: paymentTerms } = useQuery<any, ApiError>(
		["query-payment-terms", true],
		async () => {
			return await AccurateService.getListPaymentTerms({});
		},
		{
			enabled: true
		}
	);

	const listPaymentTermns = React.useMemo(() => {
		if (paymentTerms?.data?.length) {
			const data = paymentTerms?.data.map((el: any) => {
				return {
					valueStr: el.id,
					labelStr: el.name
				};
			});
			return data;
		}
		return [];
	}, [paymentTerms]);

	const defaultAccurateCustomer = React.useMemo(() => {
		if (!defaultCustomer) {
			return undefined;
		}

		return {
			mobilePhone: defaultCustomer?.mobilePhone,
			name: defaultCustomer?.name,
			id: defaultCustomer?.id,
			whatsappNo: defaultCustomer?.whatsappNo,
			customerNo: defaultCustomer?.customerNo,
			email: defaultCustomer?.email,
			salesmanNameList: defaultCustomer?.salesmanNameList
		} as CustomerAccurate;
	}, [defaultCustomer]);

	// get branch
	const { data: branch } = useQuery<any, ApiError>(
		["query-branch", true],
		async () => {
			return await AccurateService.getBranch({});
		},
		{
			enabled: true
		}
	);

	const listBranch = React.useMemo(() => {
		if (branch?.data?.d?.length) {
			return branch?.data?.d?.map((el: any) => {
				return {
					valueStr: el.id,
					labelStr: el.name as string
				};
			});
		}
		return [];
	}, [branch]);

	// get branch from PO
	useEffect(() => {
		if (accurateBranchId && listBranch.length > 0) {
			const selected = listBranch.filter(
				(branch: any) => branch.valueStr === accurateBranchId
			);
			setBranchPO(selected);
		}
	}, [accurateBranchId, listBranch]);

	// get bp detail
	const { data: batchingPlant } = useQuery<any, ApiError>(
		["query-batchingPlant", true],
		async () => {
			return await CommonService.getBP();
		},
		{
			enabled: true
		}
	);

	// no branch PO, but have PO bp id
	useEffect(() => {
		if (branch?.data?.d && batchingPlant?.data?.data) {
			const bpDetail = batchingPlant.data.data.find(
				(bp: any) => bp.id === batchingPlantId
			);
			if (bpDetail) {
				const bpName = bpDetail.name.replace("BP-", "").toLowerCase();
				const capitalizedBpName =
					bpName.charAt(0).toUpperCase() + bpName.slice(1);
				const matchedBranch = branch.data.d.find(
					(branch: any) => branch.name === capitalizedBpName
				);
				if (matchedBranch) {
					const branchFromBpId = {
						valueStr: matchedBranch.id,
						labelStr: matchedBranch.name
					};
					setBranchDetail(branchFromBpId);
				}
			}
		}
	}, [branch, batchingPlant, batchingPlantId]);

	const defaultBranch = listBranch.find(
		(el: any) => el.labelStr === branchDetail.labelStr
	);

	// appproval SKU options
	const approvalOptions = [
		{ value: "Ya", label: "Ya" },
		{ value: "Tidak", label: "Tidak" }
	];

	const deliveryMethodOption = [
		{ value: "Franco", label: "Franco" },
		{ value: "Loco", label: "Loco" }
	];

	const taxMethodOption = [
		{ value: "PKP", label: "PKP" },
		{ value: "Non PKP", label: "Non PKP" }
	];

	const Option = (props: OptionProps<CustomerAccurate>) => {
		return (
			<div className={styles.selectCustom}>
				{props.data.customerNo}
				<components.Option {...props} />
				<p className={styles.selectInfo}>
					HP: <b>{props.data.mobilePhone}</b>
					WA:<b>{props.data.whatsappNo}</b>
					email: <b>{props.data.email}</b>
				</p>
			</div>
		);
	};

	const { data: skuOrder } = useQuery<
		ApiResponsePlain<[keyof IskuSpec]>,
		ApiError
	>(
		["query-sku-order"],
		async () => {
			return await HubService.getSkuOrder();
		},
		{
			enabled: true,
			onSuccess(data) {
				console.log(data.data, "ini data skuOrder");
			}
		}
	);

	const { data: skuSpec } = useQuery<
		ApiResponsePlain<SkuSpecification[]>,
		ApiError
	>(
		["sku-specification", true],
		async () => {
			return await HubService.getSKUSpecification();
		},
		{
			enabled: true,
			onSuccess(data) {
				console.log(data.data, "ini data skuSpec");
			}
		}
	);

	const skuNoVersion = sku.map((str) => str.slice(0, -2));

	function sliceSkus(skus: string[]) {
		const result: string[] = [];

		skus.forEach((sku) => {
			const slicedSku = [
				sku.slice(0, 1),
				sku.slice(1, 3),
				sku.slice(3, 4),
				sku.slice(4, 7),
				sku.slice(7, 8),
				sku.slice(8, 9),
				sku.slice(9, 11),
				sku.slice(11, 12),
				sku.slice(12, 13),
				sku.slice(13, 14),
				sku.slice(14)
			];
			result.push(slicedSku as any);
		});

		return result;
	}

	const slicedArray = sliceSkus(skuNoVersion);

	function assignValues(
		slicedSku: any[][],
		skuOrder: any,
		requestedProductsId: string[]
	): any[] {
		const result: any[] = [];

		slicedSku.forEach((sku, index) => {
			const skuObj: any = {
				requestedProductId: requestedProductsId[index],
				specifications: {}
			};

			skuOrder?.data.forEach((key: string, i: number) => {
				skuObj.specifications[key] = sku[i];
			});

			result.push(skuObj);
		});

		return result;
	}

	const skuObjects: any[] = assignValues(
		slicedArray as any,
		skuOrder,
		poProductId
	);

	return (
		<LocalDialog
			isOpen={localDialog.isOpen}
			backdrop={"static"}
			header="Informasi Persetujuan Sale Order"
			onClose={() => {
				localDialog.onClose();
				setTimeout(() => {
					setIsOpen(false);
				}, 500);
				setIsOpen(false);
			}}
		>
			<Formik
				initialValues={{
					customerId: customerId,
					customerAccurateId: defaultCustomer?.id,
					customerAccurateNo: defaultCustomer?.customerNo,
					paymentTermName: "",
					poNumber: poNumber || "",
					shippingAddress: accurateAddress || shippingAddress || "",
					branchId: null,
					notes: "",
					taxable: false,
					inclusiveTax: false,
					date: "",
					approvalSKU: "",
					approvalCharge: "",
					deliveryMethod: deliveryMethodOption?.[0]?.value ?? "",
					taxMethod: "",
					mainPic: mainPic || "",
					phoneMainPic: phoneMainPic || "",
					volumePO: quantity
				}}
				validationSchema={
					isAccurateDisabled
						? saleOrderSchemaNoAccurate
						: saleOrderSchema
				}
				onSubmit={async (values, { setSubmitting }) => {
					try {
						const formattedDate = values.date
							.split("-")
							.reverse()
							.join("-")
							.replace(/-/g, "/");
						console.log({
							customerId: values.customerId,
							customerAccurateId: values.customerAccurateId,
							customerNo: values.customerAccurateNo,
							paymentTermName: values.paymentTermName,
							customerNumber: values.poNumber,
							branchId: values.branchId,
							transDate: formattedDate,
							taxable: values.taxable,
							inclusiveTax: values.inclusiveTax,
							mainPIC: values.mainPic,
							phonePIC: values.phoneMainPic,
							approvalSKU: values.approvalSKU,
							approvalMobilization: values.approvalCharge,
							deliveryMethod: values.deliveryMethod,
							taxMethod: values.taxMethod,
							volumePO: values.volumePO,
							description: values.notes,
							requestedProducts: skuObjects
						});

						if (!isAccurateDisabled) {
							await OrderService.signSO(poId, {
								customerId: values.customerId,
								customerAccurateId: values.customerAccurateId,
								customerNo: values.customerAccurateNo,
								paymentTermName: values.paymentTermName,
								customerNumber: values.poNumber,
								branchId: values.branchId,
								transDate: formattedDate,
								taxable: values.taxable,
								inclusiveTax: values.inclusiveTax,
								mainPIC: values.mainPic,
								phonePIC: values.phoneMainPic,
								approvalSKU: values.approvalSKU,
								approvalMobilization: values.approvalCharge,
								deliveryMethod: values.deliveryMethod,
								taxMethod: values.taxMethod,
								volumePO: values.volumePO,
								description: values.notes,
								requestedProducts: skuObjects
							});
						} else {
							await OrderService.updatePO(poId, "APPROVED");
						}

						refetch();

						dispatch(
							openAlert({
								body: "SO Berhasil Disetujui",
								color: "success"
							})
						);

						localDialog.onClose();
					} catch (err) {
						console.log(err);
						dispatch(
							openAlert({
								body: "Gagal menyetujui SO",
								color: "danger"
							})
						);
					} finally {
						setSubmitting(false);
					}
				}}
			>
				{({
					errors,
					touched,
					handleChange,
					isSubmitting,
					values,
					setFieldValue
				}) => {
					return (
						<Form>
							<FormGroup>
								{isAccurateDisabled ? (
									<div>
										<h4>Apakah ingin menyetujui SO ini?</h4>
									</div>
								) : (
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											gap: "7rem"
										}}
									>
										{/* Sub judul */}
										{/* Info Lainnya */}
										<div
											style={{
												display: "flex",
												flexDirection: "column"
											}}
										>
											<div
												style={{
													display: "flex",
													gap: "0.5rem"
												}}
											>
												<FileEarmarkRichtext
													style={{ marginTop: "4px" }}
												/>
												<h3>Info Lainnya</h3>
											</div>

											<hr
												style={{
													width: "30rem",
													marginTop: "0rem",
													marginBottom: "0rem",
													height: "0.5px",
													background: "#E1E1E1"
												}}
											/>

											{/* Contents */}
											<div
												style={{
													display: "flex",
													flexDirection: "column"
												}}
											>
												<div
													style={{
														marginTop: "1rem",
														marginBottom: "-1rem"
													}}
												>
													<AccurateCustomerSelector
														customers={
															customersAccurate?.data ||
															[]
														}
														setSearchCustomer={
															setSearchCustomer
														}
														setFieldValue={
															setFieldValue
														}
														values={values}
														errors={errors}
														defaultAccurateCustomer={
															defaultAccurateCustomer
														}
														isDisabled={
															defaultAccurateCustomer
																? true
																: false
														}
														label="Name Pelanggan"
														onChange={(e) => {
															setFieldValue(
																"customerAccurateId",
																e.id
															);
															setFieldValue(
																"customerAccurateNo",
																e.customerNo
															);
														}}
													/>
													{errors.customerAccurateNo &&
													touched.customerAccurateNo ? (
														<div
															className={
																styles.errorMessage
															}
														>
															{
																errors.customerAccurateNo
															}
														</div>
													) : null}
												</div>

												<div
													style={{
														marginTop: "1rem"
													}}
												>
													<Label>
														Syarat Pembayaran
													</Label>

													<Select
														name="paymentTermName"
														placeholder="Cari/Pilih"
														options={
															listPaymentTermns
														}
														getOptionLabel={(
															option: options
														) =>
															option.labelStr as string
														}
														getOptionValue={(
															option: options
														) =>
															option.valueStr as string
														}
														onChange={(
															selected: any
														) => {
															if (selected) {
																setFieldValue(
																	"paymentTermName",
																	selected.labelStr
																);
															} else {
																setFieldValue(
																	"paymentTermName",
																	null
																);
															}
														}}
													></Select>
													{errors.paymentTermName &&
													touched.paymentTermName ? (
														<div
															className={
																styles.errorMessage
															}
														>
															{
																errors.paymentTermName
															}
														</div>
													) : null}
												</div>

												<div
													style={{
														marginTop: "1rem"
													}}
												>
													<Label>No. PO</Label>
													<Input
														placeholder="Tulis nomor PO"
														value={values.poNumber}
														onChange={(e) =>
															setFieldValue(
																"poNumber",
																e.target.value
															)
														}
													></Input>
													{errors.poNumber &&
													touched.poNumber ? (
														<div
															className={
																styles.errorMessage
															}
														>
															{errors.poNumber}
														</div>
													) : null}
												</div>

												<div
													style={{
														marginTop: "1rem"
													}}
												>
													<Label>Alamat</Label>
													<Input
														type="textarea"
														bsSize="lg"
														value={
															values.shippingAddress
														}
														onChange={(e) =>
															setFieldValue(
																"shippingAddress",
																e.target.value
															)
														}
													></Input>
													{errors.shippingAddress &&
													touched.shippingAddress ? (
														<div
															className={
																styles.errorMessage
															}
														>
															{
																errors.shippingAddress
															}
														</div>
													) : null}
												</div>

												<div
													style={{
														marginTop: "1rem"
													}}
												>
													<Label>Cabang</Label>
													<Select
														name="branchId"
														placeholder="Cari/Pilih Cabang"
														options={listBranch}
														defaultValue={
															listBranch[0]
														}
														getOptionLabel={(
															option: options
														) =>
															option.labelStr as string
														}
														getOptionValue={(
															option: options
														) =>
															option.valueStr as string
														}
														onChange={(
															selected: any
														) => {
															if (selected) {
																setFieldValue(
																	"branchId",
																	selected.valueStr
																);
															} else {
																setFieldValue(
																	"branchId",
																	null
																);
															}
														}}
													></Select>
													{errors.branchId &&
													touched.branchId ? (
														<div
															className={
																styles.errorMessage
															}
														>
															{errors.branchId}
														</div>
													) : null}
												</div>

												<div
													style={{
														marginTop: "1rem"
													}}
												>
													<Label>Keterangan</Label>
													<Input
														name="notes"
														type="textarea"
														onChange={(e) =>
															setFieldValue(
																"notes",
																e.target.value
															)
														}
													></Input>
												</div>

												{/* Sub judul */}
												{/* Total */}
												{/* <div
											style={{
												display: "flex",
												flexDirection: "column",
												marginTop: "2rem"
											}}
										>
											<div
												style={{
													display: "flex",
													marginLeft: "15rem",
													justifyContent:
														"space-between"
												}}
											>
												<Label>Sub Total:</Label>
												<span>Rp. 1.000.000</span>
											</div>

											<div
												style={{
													display: "flex",
													marginLeft: "15rem",
													justifyContent:
														"space-between"
												}}
											>
												<Label>Diskon:</Label>
												<span>Rp. 50.000</span>
											</div>

											<div
												style={{
													display: "flex",
													marginLeft: "15rem",
													justifyContent:
														"space-between"
												}}
											>
												<Label>Total:</Label>
												<span>Rp. 950.000</span>
											</div>
										</div> */}
											</div>
										</div>

										{/* flex ke bawah */}
										<div
											style={{
												display: "flex",
												flexDirection: "column"
											}}
										>
											{/* Sub judul */}
											{/* Info Pajak */}
											<div
												style={{
													display: "flex",
													flexDirection: "column"
												}}
											>
												<div
													style={{
														display: "flex",
														flexDirection: "column"
													}}
												>
													<div
														style={{
															display: "flex",
															gap: "0.5rem"
														}}
													>
														<FileEarmarkRichtext
															style={{
																marginTop: "4px"
															}}
														/>
														<h3>Info Pajak</h3>
													</div>

													<hr
														style={{
															width: "30rem",
															marginTop: "0rem",
															marginBottom:
																"0rem",
															height: "0.5px",
															background:
																"#E1E1E1"
														}}
													/>
												</div>

												{/* Contents */}
												<div
													style={{
														display: "flex",
														flexDirection: "column"
													}}
												>
													<div
														style={{
															marginTop: "1rem"
														}}
													>
														<Label>Pajak</Label>
														<div
															style={{
																display: "flex",
																gap: "2rem",
																marginLeft:
																	"1.3rem"
															}}
														>
															<Label check>
																<Input
																	name="taxable"
																	type="checkbox"
																	checked={
																		values.taxable
																	}
																	onChange={
																		handleChange
																	}
																/>
																Kena Pajak
															</Label>

															<Label check>
																<Input
																	name="inclusiveTax"
																	type="checkbox"
																	checked={
																		values.inclusiveTax
																	}
																	onChange={
																		handleChange
																	}
																/>
																Total Termasuk
																Pajak
															</Label>
														</div>
													</div>
												</div>
											</div>

											{/* Sub judul */}
											{/* Info Pengiriman */}
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													marginTop: "1rem"
												}}
											>
												<div
													style={{
														display: "flex",
														gap: "0.5rem"
													}}
												>
													<Truck
														style={{
															marginTop: "4px"
														}}
													/>
													<h3>Info Pengiriman</h3>
												</div>

												<hr
													style={{
														width: "30rem",
														marginTop: "0rem",
														marginBottom: "0rem",
														height: "0.5px",
														background: "#E1E1E1"
													}}
												/>

												{/* Contents */}
												<div
													style={{
														display: "flex",
														flexDirection: "column"
													}}
												>
													<div
														style={{
															marginTop: "1rem"
														}}
													>
														<Label>
															Tanggal Pengiriman
														</Label>
														<Input
															name="date"
															type="date"
															value={values.date}
															onChange={
																handleChange
															}
															min={
																new Date()
																	.toISOString()
																	.split(
																		"T"
																	)[0]
															}
														/>
														{errors.date &&
														touched.date ? (
															<div
																className={
																	styles.errorMessage
																}
															>
																{errors.date}
															</div>
														) : null}
													</div>
												</div>
											</div>

											{/* Sub judul */}
											{/* Info tambahan */}
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													marginTop: "1rem"
												}}
											>
												<div
													style={{
														display: "flex",
														gap: "0.5rem"
													}}
												>
													<FileEarmarkRichtext
														style={{
															marginTop: "4px"
														}}
													/>
													<h3>Info Tambahan</h3>
												</div>

												<hr
													style={{
														width: "30rem",
														marginTop: "0rem",
														marginBottom: "0rem",
														height: "0.5px",
														background: "#E1E1E1"
													}}
												/>

												{/* Contents */}
												<div
													style={{
														display: "flex",
														flexDirection: "column"
													}}
												>
													<div
														style={{
															marginTop: "1rem"
														}}
													>
														<Label>
															PIC Pengiriman *
														</Label>
														<Input
															type="text"
															value={
																values.mainPic
															}
															onChange={(e) =>
																setFieldValue(
																	"mainPic",
																	e.target
																		.value
																)
															}
														/>
														{errors.mainPic &&
														touched.mainPic ? (
															<div
																className={
																	styles.errorMessage
																}
															>
																{errors.mainPic}
															</div>
														) : null}
													</div>

													<div
														style={{
															marginTop: "1rem"
														}}
													>
														<Label>
															Telepon PIC
															Pengiriman *
														</Label>
														<Input
															type="text"
															value={
																values.phoneMainPic
															}
															onChange={(e) =>
																setFieldValue(
																	"phoneMainPic",
																	e.target
																		.value
																)
															}
														/>
														{errors.phoneMainPic &&
														touched.phoneMainPic ? (
															<div
																className={
																	styles.errorMessage
																}
															>
																{
																	errors.phoneMainPic
																}
															</div>
														) : null}
													</div>

													<div
														style={{
															marginTop: "1rem"
														}}
													>
														<Label>
															Perlu Approval SKU *
														</Label>
														<Select
															placeholder="Cari/Pilih"
															options={
																approvalOptions
															}
															value={approvalOptions.find(
																(option) =>
																	option.value ===
																	values.approvalSKU
															)}
															onChange={(
																option: any
															) =>
																setFieldValue(
																	"approvalSKU",
																	option.value
																)
															}
														></Select>
														{errors.approvalSKU &&
														touched.approvalSKU ? (
															<div
																className={
																	styles.errorMessage
																}
															>
																{
																	errors.approvalSKU
																}
															</div>
														) : null}
													</div>

													<div
														style={{
															marginTop: "1rem"
														}}
													>
														<Label>
															Perlu Approval Biaya
															Mobilisasi *
														</Label>
														<Select
															placeholder="Cari/Pilih"
															options={
																approvalOptions
															}
															value={approvalOptions.find(
																(option) =>
																	option.value ===
																	values.approvalCharge
															)}
															onChange={(
																option: any
															) =>
																setFieldValue(
																	"approvalCharge",
																	option.value
																)
															}
														></Select>
														{errors.approvalCharge &&
														touched.approvalCharge ? (
															<div
																className={
																	styles.errorMessage
																}
															>
																{
																	errors.approvalCharge
																}
															</div>
														) : null}
													</div>

													<div
														style={{
															marginTop: "1rem"
														}}
													>
														<Label>
															Jenis Pengiriman *
														</Label>
														<Select
															placeholder="Cari/Pilih"
															defaultValue={
																deliveryMethodOption[0]
															}
															options={
																deliveryMethodOption
															}
															value={deliveryMethodOption.find(
																(option) =>
																	option.value ===
																	values.deliveryMethod
															)}
															onChange={(
																option: any
															) =>
																setFieldValue(
																	"deliveryMethod",
																	option.value
																)
															}
														></Select>
														{errors.deliveryMethod &&
														touched.deliveryMethod ? (
															<div
																className={
																	styles.errorMessage
																}
															>
																{
																	errors.deliveryMethod
																}
															</div>
														) : null}
													</div>

													<div
														style={{
															marginTop: "1rem"
														}}
													>
														<Label>
															PKP/Non PKP
														</Label>
														<Select
															placeholder="Cari/Pilih"
															options={
																taxMethodOption
															}
															value={taxMethodOption.find(
																(option) =>
																	option.value ===
																	values.taxMethod
															)}
															onChange={(
																option: any
															) =>
																setFieldValue(
																	"taxMethod",
																	option.value
																)
															}
														></Select>
														{errors.taxMethod &&
														touched.taxMethod ? (
															<div
																className={
																	styles.errorMessage
																}
															>
																{
																	errors.taxMethod
																}
															</div>
														) : null}
													</div>

													<div
														style={{
															marginTop: "1rem"
														}}
													>
														<Label>Volume PO</Label>
														<Input
															type="number"
															value={
																values.volumePO
															}
															onChange={(e) =>
																setFieldValue(
																	"volumePO",
																	e.target
																		.value
																)
															}
														></Input>
														{errors.volumePO &&
														touched.volumePO ? (
															<div
																className={
																	styles.errorMessage
																}
															>
																{
																	errors.volumePO
																}
															</div>
														) : null}
													</div>
												</div>
											</div>
										</div>
									</div>
								)}
							</FormGroup>
							<div className="modal-custom-footer">
								<LoadingButton
									color="error"
									onClick={() => {
										localDialog.onClose();
										setTimeout(() => {
											setIsOpen(false);
										});
									}}
									// loading={isSubmitting}
									disabled={isSubmitting}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Batal
									</span>
								</LoadingButton>
								<LoadingButton
									color="warning"
									variant="contained"
									type="submit"
									loading={isSubmitting}
									disabled={isSubmitting}
									sx={{
										backgroundColor: "red",
										color: "white"
									}}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Setujui
									</span>
								</LoadingButton>
							</div>
						</Form>
					);
				}}
			</Formik>
		</LocalDialog>
	);
}
