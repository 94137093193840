import { CommonService } from "api/axios";
import BCard from "components/B/card";
import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Badge, Button, Card, CardBody, CardTitle } from "reactstrap";
import { ChevronLeft } from "react-bootstrap-icons";
import moment from "moment";
import { formatComma, formatIDR, getCustomerTypeColor } from "helper";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EditDetailModal from "./EditDetailModal";
import EditDialog from "./EditDialog";
import EditProject from "./EditProject";
import PaymentHistory from "./PaymentHistory";
import EditDocuments from "./EditDocuments";
import { Loading, MTable } from "components";
import AddProject from "./AddProject";
import { Pagination } from "@mui/material";
import { useAppSelector } from "store/hooks";
import { getRouteName, routeMapping } from "routes";
import MergeProjects from "./MergeProjects";

const muiColumns = [
	{
		name: "id",
		label: "ID",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "nama",
		label: "NAMA",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "city",
		label: "KOTA",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "lastOrder",
		label: "PEMESANAN TERAKHIR",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "pic",
		label: "PIC",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "sales",
		label: "SALES",
		options: {
			filter: false,
			sort: false
		}
	}
];

const getMuiTheme = () =>
	createTheme({
		components: {
			MuiPaper: {
				styleOverrides: {
					root: {
						boxShadow: "none !important",
						width: "100%",
						padding: "20px"
					}
				}
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						fontSize: "0.85rem",
						textTransform: "capitalize",
						letterSpacing: "1px",
						// color: #525f7f;
						color: "#525f7f"
					},
					head: {
						fontSize: "0.85rem",
						textTransform: "uppercase"
					}
				}
			},
			MUIDataTableBodyRow: {
				styleOverrides: {
					root: {
						"&:nth-of-type(odd)": {
							backgroundColor: "#e9ecef"
						}
					}
				}
			},
			MUIDataTableBodyCell: {
				styleOverrides: {
					root: {
						// cursor: "pointer"
					}
				}
			}
		}
	});

export default function newCustomerDetail() {
	const navigate = useNavigate();
	const params = useParams();
	const id = params?.id;
	const [customerDetail, setCustomerDetail] = useState<CustomerDetail>(
		{} as CustomerDetail
	);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
	const [isEditProject, setIsEditProject] = useState(false);
	// const [isAddProject, setIsAddProject] = useState(false);
	const [isEditDocument, setIsEditDocument] = useState(false);
	const [isAddProject, setIsAddProject] = useState(false);
	const [isMergeProjects, setIsMergeProjects] = useState(false);
	// const [searchProject, setSearchProject] = useState<string>("");
	// const [projectQuerySearch] = useDebounce(searchProject, 1000);

	// const {
	// 	data,
	// 	isLoading: loadingGetProject,
	// 	refetch: refetchProject
	// } = useQuery<ApiProjectCustomers, ApiError>(
	// 	["query-getprojects", page],
	// 	async () => {
	// 		return CommonService.getAllProjectCustomer(
	// 			page,
	// 			15,
	// 			projectQuerySearch
	// 		);
	// 	},
	// 	{
	// 		// enabled: editCustomer.isOpen,
	// 		onSuccess: (res) => {
	// 			// setTableProject(res.data.data);
	// 			console.log(res.data.data, "ApiProjectCustomers");
	// 		}
	// 	}
	// );

	const location = useLocation();
	const permission = useAppSelector((state) => state.user.permission);
	const customerPermission = useMemo(() => {
		const routeName = getRouteName(location.pathname, routeMapping);

		if (routeName) {
			return permission[routeName];
		}
		return {};
	}, [location, permission]);

	const { refetch, isLoading } = useQuery<
		ApiResponsePlain<CustomerDetailSelected>,
		ApiError
	>(
		["query-getprojects", id],
		async () => {
			return await CommonService.getOneCustomer(id as string);
		},
		{
			// enabled: !isCreate,
			onSuccess: (res) => {
				const customer = res.data;

				setCustomerDetail({
					ktp: customer?.nik || "-",
					npwp: customer?.npwp || "-",
					pin: customer?.pin || 0,
					customerUrl: customer?.customerUrl || "-",
					pic: {
						name: customer?.Pic?.name,
						position: customer?.Pic?.position,
						email: customer?.Pic?.email,
						phone: customer?.Pic?.phone,
						id: customer?.Pic?.id as string
					},
					billingAddress: {
						line1: customer?.BillingAddress?.line1 as string,
						line2: customer?.BillingAddress?.line2,
						lat: customer?.BillingAddress?.lat,
						lon: customer?.BillingAddress?.lon,
						id: customer?.BillingAddress?.id as string,
						postalId: customer?.BillingAddress?.PostalId as number
					},
					name: customer.displayName,
					type: customer.type,
					paymentType: customer.paymentType,
					pinByRoles: customer.pinByRoles || [],
					availableDeposit:
						customer.CustomerDeposit?.availableDeposit,
					customerDocs: customer.CustomerDocs,
					projects: customer.Projects.map((project) => {
						let cityName = "-";
						if (project?.BillingAddress) {
							if (project?.BillingAddress?.Postal?.City) {
								cityName = project?.BillingAddress.Postal?.City
									.name as string;
							}
						}
						if (project?.LocationAddress) {
							if (project?.LocationAddress?.Postal?.City) {
								cityName = project?.LocationAddress.Postal?.City
									.name as string;
							}
						}
						if (project?.ShippingAddress) {
							if (project?.ShippingAddress?.Postal?.City) {
								cityName = project?.ShippingAddress?.Postal
									?.City?.name as string;
							}
						}

						return {
							id: project.id,
							nama: project.name,
							city: cityName,
							lastOrder: project.DeliveryOrders[0]
								? moment(
										project.DeliveryOrders[0]?.date
								  ).format("DD/MM/YYYY")
								: "-",
							pic: project?.Pic ? project?.Pic?.name : "-",
							sales: project?.User?.fullName
						};
					})
				});
			}
		}
	);

	const summary = useMemo(() => {
		const cardData = [];
		if (customerDetail.ktp) {
			cardData.push({
				name: "No. KTP",
				type: "text",
				data: customerDetail.ktp
			});
		}
		if (customerDetail.npwp) {
			cardData.push({
				name: "No. NPWP",
				type: "text",
				data: customerDetail.npwp
			});

			if (customerDetail.customerUrl) {
				cardData.push({
					name: "Tautan Pelanggan",
					type: "text",
					data: customerDetail.customerUrl
				});
			}

			if (customerDetail.pin) {
				cardData.push({
					name: "PIN Admin",
					type: "number",
					data: customerDetail.pin
				});
			}

			if (customerDetail.pinByRoles) {
				for (const key in customerDetail.pinByRoles || {}) {
					cardData.push({
						name:
							"PIN " +
							(customerDetail.pinByRoles[key]?.role
								? (customerDetail.pinByRoles[key]
										?.role as string)
								: ((customerDetail.pinByRoles as any)[
										key
								  ] as string)
							).replace(/\w\S*/g, function (txt) {
								return (
									txt.charAt(0).toUpperCase() +
									txt.substr(1).toLowerCase()
								);
							}),
						type: "number",
						data: key
					});
				}
			}
		}
		if (customerDetail.pic && customerDetail.billingAddress) {
			cardData.push({
				name: "",
				type: "card",
				data: [
					{
						name: "PIC Penagihan",
						type: "pic",
						data: {
							name: customerDetail.pic?.name,
							position: customerDetail.pic?.position,
							phone: `${customerDetail.pic?.phone}`,
							email: customerDetail.pic?.email
						}
					},
					{
						name: "Alamat Penagihan",
						type: "address",
						data: {
							line1: customerDetail.billingAddress?.line1,
							line2: customerDetail.billingAddress?.line2,
							lat: customerDetail.billingAddress?.lat,
							lon: customerDetail.billingAddress?.lon
						}
					}
				]
			});
		}

		return cardData;
	}, [customerDetail]);

	const photos = useMemo(() => {
		const photosData: {
			name: string;
			type: string;
			data: CustomerDocs;
		}[] = [];
		if (customerDetail.customerDocs) {
			customerDetail.customerDocs.forEach((doc) => {
				photosData.push({
					name: doc.Document.name,
					type: "photo",
					data: doc
				});
			});
		}
		return photosData;
	}, [customerDetail]);

	// summary customer
	const {
		data: summaryCustomer,
		isLoading: loadinCustomer,
		refetch: refetchSummary
	} = useQuery<ApiCustomerSummary, ApiError>(
		["query-summary", id],
		async () => {
			return CommonService.getSummary(id as string);
		},
		{
			enabled: true
		}
	);

	const formatIDRMinus = (number: number | null | undefined): string => {
		if (number === null || number === undefined) return "";
		const numericValue = Math.abs(
			Number(number.toString().replace(/[^0-9-]/g, ""))
		);
		const sign = number < 0 ? "-" : "";
		const formattedValue = new Intl.NumberFormat("id-ID").format(
			numericValue
		);
		const formattedAmount = `Rp ${sign}${formattedValue}`.replace(
			/\D00$/,
			""
		);
		return formattedAmount;
	};

	if (isLoading || loadinCustomer) {
		return <Loading />;
	}

	return (
		<>
			<AddProject
				isOpen={isAddProject}
				setIsOpen={setIsAddProject}
				refetch={refetch}
			/>
			<EditDetailModal
				isOpen={isEditModalOpen}
				setIsOpen={setIsEditModalOpen}
				customerDetail={{
					displayName: customerDetail.name as string,
					ktp: customerDetail.ktp as string,
					type: customerDetail.type as "COMPANY" | "INDIVIDU",
					npwp: customerDetail.npwp as string
				}}
				picDetail={{
					name: customerDetail.pic?.name as string,
					position: customerDetail.pic?.position as string,
					email: customerDetail.pic?.email as string,
					phone: customerDetail.pic?.phone as string,
					id: customerDetail.pic?.id as string
				}}
				refetch={refetch}
				billingAddress={{
					line1: customerDetail.billingAddress?.line1 as string,
					line2: customerDetail.billingAddress?.line2 as string,
					lat: customerDetail.billingAddress?.lat as string,
					lon: customerDetail.billingAddress?.lon as string,
					id: customerDetail.billingAddress?.id as string,
					postalId: customerDetail.billingAddress?.postalId as number
				}}
			/>
			<EditDialog
				isOpen={isEditDialogOpen}
				setIsOpen={setIsEditDialogOpen}
				openEditDetail={setIsEditModalOpen}
				openEditProject={setIsEditProject}
				openEditDocument={setIsEditDocument}
			/>
			{isEditDocument && (
				<EditDocuments
					isOpen={isEditDocument}
					setIsOpen={setIsEditDocument}
					customerDocs={customerDetail.customerDocs}
					customerDetail={{
						displayName: customerDetail.name as string,
						ktp: customerDetail.ktp as string,
						type: customerDetail.type as "COMPANY" | "INDIVIDU",
						npwp: customerDetail.npwp as string
					}}
					refetch={refetch}
				/>
			)}
			{isMergeProjects && (
				<MergeProjects
					isOpen={isMergeProjects}
					setIsOpen={setIsMergeProjects}
					refetch={refetch}
				/>
			)}

			{isEditProject && (
				<EditProject
					isOpen={isEditProject}
					setIsOpen={setIsEditProject}
					customerDetail={{
						displayName: customerDetail.name as string,
						ktp: customerDetail.ktp as string,
						type: customerDetail.type as "COMPANY" | "INDIVIDU",
						npwp: customerDetail.npwp as string
					}}
					refetch={refetch}
				/>
			)}

			<div className="p-4 pt-6">
				<div className="d-flex flex-row align-items-center mb-2">
					<div
						className="b-back-btn border mr-3"
						onClick={() => {
							navigate(-1);
						}}
					>
						<ChevronLeft />
					</div>

					<h1 className="mb-0 mr-2">{customerDetail.name}</h1>

					<Badge
						color="null"
						style={{
							...getCustomerTypeColor(
								customerDetail.type ?? "INDIVIDU"
							)
						}}
					>
						{customerDetail.type === "COMPANY"
							? "PERUSAHAAN"
							: customerDetail.type}
					</Badge>

					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
							flex: 1,
							alignItems: "center"
						}}
					>
						{customerPermission && customerPermission.update ? (
							<Button
								size="sm"
								color="warning"
								className="confirm-button approve-button"
								style={{
									float: "right",
									alignSelf: "end",
									width: "25%"
								}}
								onClick={() => {
									setIsEditDialogOpen(true);
								}}
							>
								Ubah
							</Button>
						) : null}
					</div>
				</div>
				<div className="d-flex flex-column flex-lg-row">
					<div
						className="d-flex flex-column mb-3"
						style={{
							flex: 0.6,
							justifyContent: "flex-start"
						}}
					>
						<BCard title="Rincian Data Pelanggan" data={summary} />

						{/* Proyek Pelanggan */}
						<Card style={{ marginTop: "10px" }}>
							<CardBody>
								<CardTitle
									tag="h5"
									style={{
										margin: 0,
										display: "flex",
										justifyContent: "space-between"
									}}
								>
									Proyek Pelanggan
									<div>
										{customerPermission &&
										customerPermission.update ? (
											<Button
												onClick={() => {
													setIsAddProject(true);
												}}
												size="sm"
												color="success"
												className="confirm-button approve-button"
											>
												Buat Proyek Baru
											</Button>
										) : null}

										<Button
											size="sm"
											color="info"
											onClick={() => {
												setIsMergeProjects(true);
											}}
										>
											Gabungkan Proyek
										</Button>
									</div>
								</CardTitle>
								<div className="break-line"></div>
								<ThemeProvider theme={getMuiTheme()}>
									<MUIDataTable
										title={""}
										columns={muiColumns}
										data={customerDetail.projects}
										options={{
											serverSide: false,
											print: false,
											download: false,
											filter: false,
											// pagination: false,

											selectableRowsHeader: false,
											viewColumns: false,
											selectableRowsHideCheckboxes: true,
											searchPlaceholder:
												"Cari nama proyek/ nama sales",
											rowsPerPage: 5,
											rowsPerPageOptions: [],
											onRowClick(rowData, rowMeta) {
												const id = rowData[0];

												if (id !== undefined) {
													navigate(
														`/database/customers/projects/${id}`
													);
												}
											}
										}}
									/>
								</ThemeProvider>
							</CardBody>
						</Card>

						{/* History Pelanggan */}
						{customerPermission && customerPermission.view ? (
							<PaymentHistory
								id={id as string}
								projects={customerDetail.projects}
								type={customerDetail.paymentType as string}
								refetchSummary={refetchSummary}
							/>
						) : null}
					</div>
					<div className="spacer-v" />
					{/* Card on Right Side */}
					<div
						className="d-flex flex-column"
						style={{ flex: 0.4, gap: "15px" }}
					>
						{/* Ringkasan Pelangga Card */}
						<BCard
							title="Ringkasan Pelanggan"
							data={[
								{
									name: "Kunjungan",
									type: "summaryTest",
									data: summaryCustomer?.data?.totalVisitation
										? summaryCustomer?.data?.totalVisitation
										: "0"
								},
								{
									name: "SPH",
									type: "summaryTest",
									data: summaryCustomer?.data?.totalSph
										? summaryCustomer?.data?.totalSph
										: "0"
								},
								{
									name: "SO",
									type: "summaryTest",
									data: summaryCustomer?.data?.totalSO
										? summaryCustomer?.data?.totalSO
										: "0"
								},
								{
									name: "DO",
									type: "summaryTest",
									data: summaryCustomer?.data?.totalDo
										? summaryCustomer?.data?.totalDo
										: "0"
								},
								{
									name: "Kuantitas Terkirim",
									type: "summaryQuan",
									data: summaryCustomer?.data
										.deliveredQuantity
										? formatComma(
												`${summaryCustomer?.data?.deliveredQuantity} m³`
										  )
										: "0 m³"
								},
								{
									name: "Sisa Uang Muka",
									type: "summaryPrice",
									data: summaryCustomer?.data?.pendingBalance
										? formatIDRMinus(
												summaryCustomer?.data
													?.pendingBalance
										  )
										: "Rp. 0"
								},
								{
									name: "Total Uang Muka",
									type: "summaryPrice",
									data: summaryCustomer?.data?.balance
										? formatIDRMinus(
												summaryCustomer?.data
													?.balance
										  )
										: "Rp. 0"
								},
								{
									name: "Sisa Kredit",
									type: "summaryCredit",
									data: summaryCustomer?.data
										?.creditPendingBalance
										? formatIDRMinus(
												summaryCustomer?.data
													?.creditLimit ? Math.abs(summaryCustomer?.data
													?.creditPendingBalance + summaryCustomer?.data
													?.creditLimit) : summaryCustomer?.data
													?.creditPendingBalance
										  )
										: "Rp. 0",
									data2: summaryCustomer?.data?.creditLimit
										? "Rp. " +
										  formatIDR(
												summaryCustomer?.data
													?.creditLimit
										  )
										: "Rp. 0"
								},
								{
									name: "Total Kredit",
									type: "summaryCredit",
									data: summaryCustomer?.data?.creditBalance
										? formatIDRMinus(
													summaryCustomer?.data
													?.creditLimit ? Math.abs(summaryCustomer?.data
													?.creditBalance + summaryCustomer?.data
													?.creditLimit) : summaryCustomer?.data
													?.creditBalance
										  )
										: "Rp. 0",
									data2: summaryCustomer?.data?.creditLimit
										? "Rp. " +
										  formatIDR(
												summaryCustomer?.data
													?.creditLimit
										  )
										: "Rp. 0"
								}
							]}
						/>

						<BCard title="Dokumen Pelanggan" data={photos} />
					</div>
				</div>
			</div>
		</>
	);
}
