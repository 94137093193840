import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { LocalDialog } from "components";
import { useLocalDialog } from "hooks";
import { Button, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { useMutation, useQuery } from "react-query";
import { CommonService } from "api/axios";
import { useAppDispatch } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import { useParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDebounce } from "use-debounce";
import Select from "react-select";

const AddProjectSchema = Yup.object().shape({
	projectName: Yup.string()
		.min(4, "Minimal 4 Karakter")
		.required("Nama proyek Dibutuhkan"),
	// stage: Yup.string().required("Fase proyek Dibutuhkan"),
	assignTo: Yup.string().required("Nama Sales Dibutuhkan"),
	locationAddress: Yup.object()
		.shape({
			labelStr: Yup.string(),
			valueStr: Yup.string()
		})
		.required("Alamat Pengiriman Dibutuhkan"),
	locationFull: Yup.string().required("Alamat Pengiriman Dibutuhkan"),
	billingAddress: Yup.object()
		.shape({
			labelStr: Yup.string(),
			valuestr: Yup.string()
		})
		.required("Di butuhkan"),
	billingFull: Yup.string().required("Alamat Pembayaran Dibutuhkan"),
	// PIC
	name: Yup.string()
		.min(4, "Minimum 4 Karakter")
		.max(50, "Terlalu Panjang")
		.test("is-required", "Nama PIC Dibutuhkan", function (value) {
			return this.parent.name || this.parent.position || this.parent.phone
				? Boolean(value)
				: false;
		}),
	position: Yup.string()
		.min(3, "Minimal 3 Karakter")
		.test("is-required", "Posisi PIC Dibutuhkan", function (value) {
			return this.parent.name || this.parent.position || this.parent.phone
				? Boolean(value)
				: false;
		}),
	phone: Yup.string()
		.matches(/^\d+$/, "Nomor telepon harus angka")
		.max(50, "Terlalu Panjang")
		.test("is-required", "No.Telpon PIC Dibutuhkan", function (value) {
			return this.parent.name || this.parent.position || this.parent.phone
				? Boolean(value)
				: false;
		}),
	email: Yup.string().email("Email Invalid")
	//pic end

	// competitors: Yup.array()
	// 	.of(
	// 		Yup.object().shape({
	// 			name: Yup.string()
	// 				.min(4, "too short")
	// 				.required("Nama pesaing Dibutuhkan"), // these constraints take precedence
	// 			mou: Yup.string().required("PKS/MOU Dibutuhkan"), // these constraints take precedence
	// 			exclusive: Yup.string().required(
	// 				"PKS-nya Eksklusif Dibutuhkan"
	// 			), // these constraints take precedence
	// 			problem: Yup.string().min(4, "too short"), // these constraints take precedence
	// 			hope: Yup.string().min(4, "too short") // these constraints take precedence
	// 			//salary: Yup.string().min(3, "cmon").required("Required") // these constraints take precedence
	// 		})
	// 	)
	// 	.notRequired()
	//.required("Must have friends") // these constraints are shown if and only if inner constraints are satisfied
	//.min(3, "Minimum of 3 friends")
});

export default function AddProject({
	isOpen,
	setIsOpen,
	refetch
}: {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	refetch: () => void;
}) {
	const params = useParams();
	const id = params?.id;
	const { localDialog } = useLocalDialog();
	const dispatch = useAppDispatch();
	const [salesOptions, setSalesOptions] = useState<
		{
			value: string;
			label: string;
		}[]
	>([]);
	const [projectLocText, setProjectLocText] = useState<string>("");
	const [billingLocText, setBillingLocText] = useState<string>("");
	const [projectSearch] = useDebounce(projectLocText, 500);
	const [billingSearch] = useDebounce(billingLocText, 500);
	const [locationErrorStr, setLocationErrorStr] = useState("");

	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		} else {
			localDialog.onClose();
		}
	}, [isOpen]);

	useQuery<IUsers, ApiError>(
		["query-filter-sales"],
		async () => {
			return await CommonService.getUsers(1, 99, "SALES");
		},
		{
			enabled: isOpen,
			onSuccess: (res) => {
				if (!res.data?.data) return;

				setSalesOptions((curr) => {
					if (res.data) {
						return res.data.data.map((sale) => {
							return {
								value: sale.id,
								label: sale.fullName
							};
						});
					} else {
						return curr;
					}
				});
			}
		}
	);
	const { mutateAsync: createPic } = useMutation<
		ApiCreatePicResponse,
		ApiError,
		PayloadCreatePIC,
		ApiError
	>(
		async (data: PayloadCreatePIC) => {
			const res = await CommonService.createPIC(data);
			return res;
		},
		{
			onError(error) {
				console.log(error, "createPIC error 137");
			}
		}
	);

	const { mutateAsync: createAddress } = useMutation<
		ApiResponseCreateAddress,
		ApiError,
		PayloadCreateAddress,
		ApiError
	>(
		async (data: PayloadCreateAddress) => {
			const res = await CommonService.createAddress(data);
			return res;
		},
		{
			onError(error) {
				console.log(error, "createAddress error 155");
			}
		}
	);
	const { mutateAsync: createProject } = useMutation<
		ApiResponseCreateAddress,
		ApiError,
		ICreateProject,
		ApiError
	>(
		async (data: ICreateProject) => {
			const res = await CommonService.createProject(data);
			return res;
		},
		{
			onError(error) {
				console.log(error, "createProject error 171");
			}
		}
	);

	const { data: projectPlaces } = useQuery<ApiPlaces, ApiError>(
		["query-project-places", projectSearch],
		async () => {
			return CommonService.getPlaces(projectSearch as string);
		},
		{
			enabled: projectSearch.length ? true : false
		}
	);
	const { data: billingPlaces } = useQuery<ApiPlaces, ApiError>(
		["query-project-places", billingSearch],
		async () => {
			return CommonService.getPlaces(billingSearch as string);
		},
		{
			enabled: billingSearch.length ? true : false
		}
	);

	const optionProjectLoc = React.useMemo(() => {
		if (projectPlaces?.result.length) {
			const data = projectPlaces.result.map((el) => {
				return {
					...el,
					valueStr: el.place_id,
					labelStr: el.description
				};
			});

			return data;
		}

		return [];
	}, [projectPlaces]);
	const optionBillingLoc = React.useMemo(() => {
		if (billingPlaces?.result.length) {
			const data = billingPlaces.result.map((el) => {
				return {
					...el,
					valueStr: el.place_id,
					labelStr: el.description
				};
			});

			return data;
		}

		return [];
	}, [billingPlaces]);

	const labelStyle = {
		marginTop: "1em"
	};

	return (
		<LocalDialog
			isOpen={localDialog.isOpen}
			backdrop={"static"}
			header="Buat Proyek Baru"
			onClose={() => {
				// localDialog.onClose();
				setIsOpen(false);
			}}
		>
			{/* <div>add project</div> */}
			<Formik
				initialValues={{
					projectName: "",
					// stage: "",
					assignTo: "",
					locationAddress: {
						labelStr: "",
						valueStr: ""
					},
					locationFull: "",
					billingAddress: {
						labelStr: "",
						valueStr: ""
					},
					billingFull: "",
					//pic start
					name: "",
					position: "",
					phone: "",
					email: ""
					//pic end
					// competitors: [
					// 	{
					// 		name: "",
					// 		mou: "",
					// 		exclusive: "",
					// 		problem: "",
					// 		hope: ""
					// 	}
					// ]
				}}
				validationSchema={AddProjectSchema}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					try {
						if (!values.billingAddress.valueStr) {
							setLocationErrorStr("Alamat Pembayaran Dibutuhkan");
							return;
						}
						if (!values.locationAddress.valueStr) {
							setLocationErrorStr("Alamat proyek Dibutuhkan");
							return;
						}
						setLocationErrorStr("");
						const billingResp = await CommonService.getPlaceDetail(
							values.billingAddress.valueStr as string
						);
						const locationResp = await CommonService.getPlaceDetail(
							values.locationAddress.valueStr as string
						);
						const billingAddress = await createAddress({
							line1: values.billingAddress.labelStr,
							postalId: billingResp.result.PostalId,
							line2: values.billingFull,
							lat: billingResp.result.lat,
							lon: billingResp.result.lon
						});
						const locationAddress = await createAddress({
							line1: values.locationAddress.labelStr,
							postalId: locationResp.result.PostalId,
							line2: values.locationFull,
							lat: locationResp.result.lat,
							lon: locationResp.result.lon
						});
						const billingAddressId = billingAddress.data
							.id as string;
						const locationAddressId = locationAddress.data
							.id as string;
						const shippingAddressId = locationAddress.data
							.id as string;
						const customerId = id as string;
						const createdById = values.assignTo;
						const payload: ICreateProject = {
							billingAddressId,
							locationAddressId,
							shippingAddressId,
							customerId,
							createdById,
							// competitors: values.competitors,
							name: values.projectName
							// stage: values.stage
						};
						if (values.name) {
							payload.pic = {
								name: values.name,
								position: values.position,
								phone: values.phone,
								email: values.email,
								type: "PROJECT"
							};
						}
						await createProject(payload);
						refetch();
						setIsOpen(false);
					} catch (error) {
						console.log(error, "error buat proyek baru");
						dispatch(
							openAlert({
								body: "Gagal buat proyek baru",
								color: "danger"
							})
						);
					} finally {
						setSubmitting(false);
					}
				}}
			>
				{({ values, errors, handleChange, isSubmitting, touched }) => {
					return (
						<Form>
							<FormGroup
								style={{
									display: "flex",
									flexDirection: "column",
									gap: "3px"
								}}
							>
								<FormGroup>
									<Label>Assign Kepada Sales</Label>
									<Select
										options={salesOptions}
										placeholder="Pilih Sales"
										name="assignTo"
										onChange={(selected) => {
											if (selected) {
												values.assignTo =
													selected?.value;
											} else {
												values.assignTo = "";
											}
										}}
										styles={{
											control: (baseStyles, state) => {
												if (
													!!errors.assignTo &&
													touched.assignTo
												) {
													return {
														...baseStyles,
														borderColor: "#fb6340"
													};
												} else {
													return {
														...baseStyles
													};
												}
											}
										}}
									/>
									<ErrorMessage
										name={`assignTo`}
										component="div"
										className="error-msg"
									/>
								</FormGroup>
								<FormGroup>
									<Label>Nama Proyek</Label>
									<Input
										name="projectName"
										invalid={!!errors.projectName}
										onChange={handleChange}
										value={values.projectName}
										placeholder="Nama Proyek"
									/>
									<FormFeedback
										invalid={!!errors.projectName}
									>
										{errors.projectName}
									</FormFeedback>
								</FormGroup>
								{/* <div className="input-style">
										<Label>Fase Proyek</Label>
										<Field
											component="select"
											name="stage"
											multiple={false}
											className={
												errors.stage && touched.stage
													? "error-input form-control"
													: "form-control"
											}
										>
											<option
												disabled
												selected
												value={""}
											>
												{" "}
												-- pilih fase proyek --{" "}
											</option>
											<option value="LAND_PREP">
												Persiapan Lahan
											</option>
											<option value="FOUNDATION">
												Pasang Pondasi
											</option>
											<option value="FORMWORK">
												Struktur
											</option>
											<option value="FINISHING">
												Finishing
											</option>
										</Field>
									</div>
									<FormFeedback invalid={!!errors.stage}>
										{errors.stage}
									</FormFeedback> */}
								<FormGroup>
									<Label>Alamat Proyek</Label>
									<Select
										onInputChange={(text) => {
											setProjectLocText(text);
										}}
										onChange={(change) => {
											if (change) {
												values.locationAddress.labelStr =
													change?.labelStr as string;
												values.locationAddress.valueStr =
													change.valueStr as string;
											} else {
												values.locationAddress.labelStr =
													"";
												values.locationAddress.valueStr =
													"";
											}
										}}
										options={optionProjectLoc}
										getOptionLabel={(option) =>
											option.labelStr as string
										}
										getOptionValue={(option) =>
											option.valueStr as string
										}
										name="locationAddress"
										placeholder="Tulis Alamat Pengiriman"
										value={
											values.locationAddress.valueStr
												? {
														labelStr:
															values
																.locationAddress
																.labelStr,
														valueStr:
															values
																.locationAddress
																.valueStr
												  }
												: null
										}
										styles={{
											control: (baseStyles, state) => {
												if (
													!!errors.locationAddress &&
													touched.locationAddress
												) {
													return {
														...baseStyles,
														borderColor: "#fb6340"
													};
												} else {
													return {
														...baseStyles
													};
												}
											}
										}}
									/>

									<ErrorMessage
										name={`locationAddress.labelStr`}
										component="div"
										className="error-msg"
									/>
								</FormGroup>
								<FormGroup>
									<Label> Alamat Lengkap Proyek</Label>
									<Input
										name="locationFull"
										invalid={!!errors.locationFull}
										onChange={handleChange}
										value={values.locationFull}
										placeholder="Alamat Lengkap Proyek"
									/>
									<FormFeedback
										invalid={!!errors.locationFull}
									>
										{errors.locationFull}
									</FormFeedback>
								</FormGroup>
								<FormGroup>
									<Label>Alamat Pembayaran</Label>
									<Select
										onInputChange={(text) => {
											setBillingLocText(text);
										}}
										onChange={(change) => {
											if (change) {
												values.billingAddress.labelStr =
													change?.labelStr as string;
												values.billingAddress.valueStr =
													change.valueStr as string;
											} else {
												values.billingAddress.labelStr =
													"";
												values.billingAddress.valueStr =
													"";
											}
										}}
										options={optionBillingLoc}
										getOptionLabel={(option) =>
											option.labelStr as string
										}
										getOptionValue={(option) =>
											option.valueStr as string
										}
										name="billingAddress"
										placeholder="Tulis Alamat Pembayaran"
										value={
											values.billingAddress.valueStr
												? {
														labelStr:
															values
																.billingAddress
																.labelStr,
														valueStr:
															values
																.billingAddress
																.valueStr
												  }
												: null
										}
										styles={{
											control: (baseStyles, state) => {
												if (
													!!errors.locationAddress
														?.labelStr &&
													touched.locationAddress
														?.labelStr
												) {
													return {
														...baseStyles,
														borderColor: "#fb6340"
													};
												} else {
													return {
														...baseStyles
													};
												}
											}
										}}
									/>
									<ErrorMessage
										name="billingAddress.labelStr"
										component="div"
										className="error-msg"
									/>
								</FormGroup>
								<FormGroup>
									<Label> Alamat Lengkap Pembayaran</Label>
									<Input
										name="billingFull"
										invalid={!!errors.billingFull}
										onChange={handleChange}
										value={values.billingFull}
										placeholder="Alamat Lengkap Pembayaran"
									/>
									<FormFeedback
										invalid={!!errors.billingFull}
									>
										{errors.billingFull}
									</FormFeedback>
								</FormGroup>
								{/* PIC forms */}
								<div className="dropdown-divider"></div>
								PIC
								<FormGroup>
									<Label>Name</Label>
									<Input
										name="name"
										invalid={!!errors.name}
										onChange={handleChange}
										value={values.name}
										placeholder="Nama PIC"
									/>
									<FormFeedback invalid={!!errors.name}>
										{errors.name}
									</FormFeedback>
									<Label>Position</Label>
									<Input
										name="position"
										onChange={handleChange}
										invalid={!!errors.position}
										value={values.position}
										placeholder="Posisi PIC"
									/>
									<FormFeedback invalid={!!errors.position}>
										{errors.position}
									</FormFeedback>
									<Label>Phone</Label>
									<Input
										name="phone"
										onChange={handleChange}
										invalid={!!errors.phone}
										value={values.phone}
										placeholder="No. Telepon PIC"
									/>
									<FormFeedback invalid={!!errors.phone}>
										{errors.phone}
									</FormFeedback>
									<Label>Email</Label>
									<Input
										name="email"
										onChange={handleChange}
										invalid={!!errors.email}
										value={values.email}
										placeholder="Email PIC"
									/>
									<FormFeedback invalid={!!errors.email}>
										{errors.email}
									</FormFeedback>
								</FormGroup>
							</FormGroup>
							<div className="dropdown-divider"></div>
							{/* <FormGroup>
								<FieldArray
									name="competitors"
									render={(arrayHelpers) => {
										return (
											<div>
												{values.competitors.map(
													(competitor, index) => {
														const competitorError =
															errors
																.competitors?.[
																index
															] as
																| {
																		name?: string;
																		mou?: string;
																		exclusive?: string;
																		problem?: string;
																		hope?: string;
																  }
																| undefined;

														return (
															<div
																key={index}
																className="competitor-container"
															>
																<div className="d-flex align-items-center">
																	<Button
																		type="button"
																		onClick={() =>
																			arrayHelpers.remove(
																				index
																			)
																		}
																		color="warning"
																		className="mr-2 confirm-button"
																	>
																		Hapus
																		Kompetitor{" "}
																		{index +
																			1}
																	</Button>
																</div>
																<Label>
																	Nama
																	pesaing/Kompetisi
																</Label>
																<Field
																	name={`competitors[${index}].name`}
																	className={
																		competitorError &&
																		competitorError.name &&
																		touched
																			.competitors?.[
																			index
																		]?.name
																			? "error-input text-controll"
																			: "text-controll"
																	}
																/>
																<ErrorMessage
																	name={`competitors[${index}].name`}
																	component="div"
																	className="error-msg"
																/>

																<div>
																	<Label>
																		Apakah
																		sudah
																		memiliki
																		PKS/MOU?
																	</Label>
																	<ErrorMessage
																		name={`competitors[${index}].mou`}
																		component="div"
																		className="error-msg"
																	/>

																	<div className="radio-wrapper">
																		<input
																			type="radio"
																			id="html"
																			name={`competitors[${index}].mou`}
																			value="Iya"
																			onChange={
																				handleChange
																			}
																		/>
																		<label>
																			Iya
																		</label>
																	</div>
																	<div className="radio-wrapper">
																		<input
																			type="radio"
																			id="html"
																			name={`competitors[${index}].mou`}
																			value="Tidak"
																			onChange={
																				handleChange
																			}
																		/>
																		<label>
																			Tidak
																		</label>
																	</div>
																</div>
																<div>
																	<Label>
																		Apakah
																		PKS-nya
																		Eksklusif?
																	</Label>
																	
																	<ErrorMessage
																		name={`competitors[${index}].exclusive`}
																		component="div"
																		className="error-msg"
																	/>
																	<div className="radio-wrapper">
																		<input
																			type="radio"
																			name={`competitors[${index}].exclusive`}
																			value="Iya"
																			onChange={
																				handleChange
																			}
																		/>
																		<label>
																			Iya
																		</label>
																	</div>
																	<div className="radio-wrapper">
																		<input
																			type="radio"
																			name={`competitors[${index}].exclusive`}
																			value="Tidak"
																			onChange={
																				handleChange
																			}
																		/>
																		<label>
																			Tidak
																		</label>
																	</div>
																</div>
																<Label>
																	Apakah ada
																	masalah yang
																	ditemukan
																	dari
																	supplier
																	beton
																	sekarang?
																</Label>
																<Field
																	name={`competitors[${index}].problem`}
																	as="textarea"
																	className="text-area-controll"
																	placeholder="Tulis masalah yang anda temui"
																/>
																<Label>
																	Harapan apa
																	yang
																	diinginkan
																	dari BRIK?
																</Label>
																<Field
																	name={`competitors[${index}].hope`}
																	as="textarea"
																	className="text-area-controll"
																	placeholder="Tulis harapan anda"
																/>
															</div>
														);
													}
												)}
												<Button
													type="button"
													onClick={() =>
														arrayHelpers.push({
															name: "",
															mou: "",
															exclusive: "",
															problem: "",
															hope: ""
														})
													}
												>
													Tambah Kompetitor
												</Button>
											</div>
										);
									}}
								/>
							</FormGroup> */}

							{/* Footer */}

							<div className="modal-custom-footer">
								<LoadingButton
									color="error"
									onClick={() => {
										localDialog.onClose();
									}}
									loading={isSubmitting}
									disabled={isSubmitting}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Batal
									</span>
								</LoadingButton>
								<LoadingButton
									color="error"
									variant="contained"
									type="submit"
									loading={isSubmitting}
									disabled={isSubmitting}
									sx={{
										backgroundColor: "red",
										color: "white"
									}}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Buat
									</span>
								</LoadingButton>
							</div>
						</Form>
					);
				}}
			</Formik>
		</LocalDialog>
	);
}
