import React from "react";
import PropTypes from "prop-types";

const AccurateChip = ({ value }: any) => {
	const chipDetail = {
		backgroundColor:
			value === true || value === null ? "#F08080" : "#808080",
		iconColor: value === true || value === null ? "#D22B2B" : "black"
	};

	return (
		<div
			style={{
				display: "inline-flex",
				alignItems: "center",
				gap: "0.5rem",
				backgroundColor: chipDetail.backgroundColor,
				padding: "4px 8px",
				borderRadius: "16px",
				color: "white",
				fontSize: "small"
			}}
		>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					gap: "0.5rem",
					textDecoration: value === false ? "line-through" : "none",
					textDecorationColor: value === false ? "black" : ""
				}}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					version="1.1"
					width="12px"
					height="12px"
					viewBox="0 0 2367 2367"
					style={{
						fill: chipDetail.iconColor
					}}
				>
					<g>
						<path d="M 1131.5,-0.5 C 1164.5,-0.5 1197.5,-0.5 1230.5,-0.5C 1526.64,15.6397 1782.31,124.473 1997.5,326C 2192.26,515.325 2310.09,745.159 2351,1015.5C 2387.15,1291.76 2334.82,1549.76 2194,1789.5C 2053.13,2018.68 1857.63,2183.18 1607.5,2283C 1392.2,2364.43 1171.53,2383.1 945.5,2339C 679.39,2282.43 457.89,2151.93 281,1947.5C 107.513,1740.67 13.6796,1501.67 -0.5,1230.5C -0.5,1197.17 -0.5,1163.83 -0.5,1130.5C 17.0085,819.874 135.009,555.04 353.5,336C 570.397,128.106 829.73,15.9395 1131.5,-0.5 Z" />
					</g>
					<g>
						<path
							style={{ opacity: 1 }}
							fill="white"
							d="M 1158.5,353.5 C 1187.86,348.262 1213.86,355.095 1236.5,374C 1256.31,392.466 1273.14,413.3 1287,436.5C 1294.37,449.564 1301.37,462.897 1308,476.5C 1454.92,830.269 1601.26,1184.27 1747,1538.5C 1758.97,1580.42 1760.64,1622.76 1752,1665.5C 1740.17,1713.02 1712.67,1748.19 1669.5,1771C 1637.11,1788.41 1602.78,1799.91 1566.5,1805.5C 1469.71,1535.23 1372.87,1264.89 1276,994.5C 1265.2,968.74 1251.53,944.74 1235,922.5C 1226.01,911.846 1215.51,903.013 1203.5,896C 1179.49,887.088 1157.49,890.421 1137.5,906C 1125.11,917.381 1114.61,930.214 1106,944.5C 1092.2,968.106 1080.53,992.773 1071,1018.5C 977.928,1280.72 884.261,1542.72 790,1804.5C 739.266,1798.36 693.766,1779.53 653.5,1748C 620.521,1717.87 602.688,1680.37 600,1635.5C 597.061,1596.41 602.061,1558.41 615,1521.5C 756.609,1180.61 897.943,839.614 1039,498.5C 1053.31,462.87 1071.97,429.87 1095,399.5C 1108.41,382.567 1124.57,369.067 1143.5,359C 1148.59,357.031 1153.59,355.197 1158.5,353.5 Z"
						/>
					</g>
				</svg>
				<span style={{ color: chipDetail.iconColor }}>Accurate</span>
			</div>
		</div>
	);
};

AccurateChip.propTypes = {
	value: PropTypes.bool
};

AccurateChip.defaultProps = {
	value: undefined
};

export default AccurateChip;
