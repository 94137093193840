import React from "react";
import { LocalDialog } from "components";

import { QueryObserverResult, useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { openAlert } from "store/features/alert/AlertSlice";

import { useAppSelector } from "store/hooks";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import { FormGroup, Input, Label } from "reactstrap";
import { FinanceService, OrderService } from "api/axios";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";

interface IProps {
	localDialog: {
		isOpen: boolean;
		onClose: () => void;
		onOpen: () => void;
	};
	setLocalDialog: React.Dispatch<
		React.SetStateAction<{
			isOpen: boolean;
			onClose: () => void;
			onOpen: () => void;
		}>
	>;
	refetch: () => Promise<
		QueryObserverResult<{ data: ApiQuotation }, ApiError>
	>;
	number: string;
}

const depositSchema = Yup.object().shape({
	number: Yup.string().required("Nomor Deposit harus diisi")
});

const UpdateQuotation = (props: IProps) => {
	const params = useParams();
	const id = params?.id;
	const dispatch = useDispatch();
	const { localDialog, refetch, number, setLocalDialog } = props;

	const { mutateAsync: putNumber } = useMutation(
		async (numberValue: string) => {
			return await OrderService.updateSphNUmber(
				id as string,
				numberValue
			);
		},
		{
			onSuccess: () => {
				refetch();
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	// queries
	// DO

	// states

	// const { mutate, isLoading: loadingAdd } = useMutation<
	// 	ApiResponseCreateInvoice,
	// 	ApiError,
	// 	Partial<PayloadPayment>,
	// 	ApiError
	// >(
	// 	async (_payload: Partial<PayloadPayment>) => {
	// 		const res = await FinanceService.createPayment(_payload);
	// 		return res;
	// 	},
	// 	{
	// 		onSuccess: (e) => {
	// 			localDialog.onClose();
	// 			setPayload({});
	// 			handleCreateLoading(false);
	// 			refetchList();
	// 		},
	// 		onError: (err) => {
	// 			handleCreateLoading(false);
	// 			dispatch(
	// 				openAlert({
	// 					body: err.response?.data.message,
	// 					color: "danger"
	// 				})
	// 			);
	// 		}
	// 	}
	// );

	if (!localDialog.isOpen) return null;

	// if (loadingAdd) {
	// 	return <Loading />;
	// }

	return (
		<LocalDialog
			isOpen={localDialog.isOpen}
			backdrop={"static"}
			// body={<BForm forms={forms} />}
			header="Ubah Nomor SO"
			onClose={() => {
				// setPayload({});
				localDialog.onClose();
			}}
			fullScreen
		>
			<div>
				<Formik
					initialValues={{
						number: number
					}}
					onSubmit={async (values) => {
						await putNumber(values.number);
						localDialog.onClose();
					}}
					validationSchema={depositSchema}
				>
					{({ errors, handleChange, isSubmitting, values }) => (
						<Form>
							<FormGroup>
								<Label htmlFor="number">Nomor SO</Label>
								<Input
									name="number"
									invalid={!!errors.number}
									onChange={handleChange}
									//setSearchCustomerName
									value={values.number}
									placeholder="Nomor SO"
								/>
							</FormGroup>
							<div className="modal-custom-footer">
								<LoadingButton
									color="error"
									onClick={() => {
										setLocalDialog((prev) => {
											return {
												...prev,
												isOpenSoNumber: false
											};
										});
										// localDialog.onClose();
										// setTimeout(() => {
										// 	setIsOpen(false);
										// }, 500);
									}}
									loading={isSubmitting}
									disabled={isSubmitting}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Batal
									</span>
								</LoadingButton>
								<LoadingButton
									color="error"
									variant="contained"
									type="submit"
									loading={isSubmitting}
									disabled={isSubmitting}
									sx={{
										backgroundColor: "red",
										color: "white"
									}}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Buat
									</span>
								</LoadingButton>
							</div>
						</Form>
					)}
				</Formik>
			</div>
			{/* <BForm forms={forms} /> */}
		</LocalDialog>
	);
};

export default UpdateQuotation;
