import React, { useMemo, useEffect } from "react";
import { Button, Card, CardBody, CardTitle, Container, Form, FormGroup, Input, Row } from "reactstrap";
import { useQuery } from "react-query";
import moment from "moment";

import { OrderService, FinanceService } from "api/axios";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { closeDialog, openDialog } from "store/features/dialog/DialogSlice";
import { dateStr, formatDate, formatLinkSheet, toSheet } from "helper";
import { useListSearchParams, useLocalDialog } from "hooks";
import { useDebounce } from "use-debounce";
import { BTable, Header, Loading, MTable, StatusChip } from "components";
import { getPriceFormatted } from "helper/product";
import { openAlert } from "store/features/alert/AlertSlice";
import { MUIDataTableMeta } from "mui-datatables";
import { useLocation, useNavigate } from "react-router-dom";
import CreatePayment from "./createPayment";
import { useFlags } from "flagsmith/react";
import { getRouteName, routeMapping } from "routes";

const muiColumns = [
	{
		name: "number",
		label: "NO. DEPOSIT",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "status",
		label: "STATUS",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (
				value: any
				// tableMeta: MUIDataTableMeta,
				// updateValue: (value: string) => void
			) => {
				return <StatusChip value={value} />;
			}
		}
	},
	{
		name: "totalDeposit",
		label: "TOTAL DEPOSIT",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "customerName",
		label: "NAMA PELANGGAN",
		options: {
			filter: false,
			sort: false
		}
	},
	// {
	// 	name: "projectName",
	// 	label: "NAMA PROYEK",
	// 	options: {
	// 		filter: false,
	// 		sort: false
	// 	}
	// },
	// {
	// 	name: "city",
	// 	label: "KOTA",
	// 	options: {
	// 		filter: false,
	// 		sort: false
	// 	}
	// },
	{
		name: "customerType",
		label: "TIPE PELANGGAN",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (
				value: any
				// tableMeta: MUIDataTableMeta,
				// updateValue: (value: string) => void
			) => {
				if (value === undefined) {
					return "-";
				} else {
					return <StatusChip value={value} />;
				}
			}
		}
	},
	{
		name: "createdBy",
		label: "DIBUAT OLEH",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "date",
		label: "TANGGAL",
		options: {
			filter: false,
			sort: false
		}
	}
];

export default function Deposits() {
	const location = useLocation();
	const permission = useAppSelector((state) => state.user.permission);
	const paymentPermission = useMemo(() => {
		const routeName = getRouteName(location.pathname, routeMapping);

		if (routeName) {
			return permission[routeName];
		}
		return {};
	}, [location, permission]);
	const navigate = useNavigate();
	const [searchText, setSearchText] = React.useState<string>();
	const [searchQ] = useDebounce(searchText, 500);
	const [searchCustomerPic, setSearchCustomerPic] = React.useState<string>();
	const [searchQcustomerPic] = useDebounce(searchCustomerPic, 500);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);

		const paymentNumParams = queryParams.get("search?payment-number");
		const customerNameParams = queryParams.get("search?customer-name");
		if (paymentNumParams) {
			setSearchText(paymentNumParams);
		} else {
			setSearchText("");
		}

		if (customerNameParams) {
			setSearchCustomerPic(customerNameParams);
		} else {
			setSearchCustomerPic("");
		}

	}, [location.search]);

	const handleNumberSearch = (newValue: string) => {
		setSearchText(newValue);

		const queryParams = new URLSearchParams();
		queryParams.set("page", "1");

		if (newValue) {
			queryParams.set("search?payment-number", newValue);
		}

		if (searchCustomerPic) {
			queryParams.set("search?customer-name", searchCustomerPic);
		}

		navigate({
			pathname: location.pathname,
			search: queryParams.toString()
		});
	};

	const handleCustomerSearch = (newValue: string) => {
		setSearchCustomerPic(newValue);

		const queryParams = new URLSearchParams();
		queryParams.set("page", "1");

		if (newValue) {
			queryParams.set("search?customer-name", newValue);
		}

		if (searchText) {
			queryParams.set("search?payment-number", searchText);
		}

		navigate({
			pathname: location.pathname,
			search: queryParams.toString()
		});
	};

	// `${location.pathname}/${el.id}`;
	const [tableData, setTableData] = React.useState<ApiPayments>({
		message: "",
		success: true,
		currentPage: 1,
		totalItems: 10,
		totalPages: 1,
		dataTable: []
	});
	const { page, date, searchParams, setPage } = useListSearchParams();
	const salesId = searchParams.get("sales");
	const { bp } = useAppSelector((store) => store.batching);

	// query table
	const { isLoading, isFetchedAfterMount, refetch } = useQuery<
		ApiPayments,
		ApiError
	>(
		["query-quotations", page, date, salesId, searchQ, searchQcustomerPic, bp?.valueStr],
		async () => {
			// return await OrderService.getAllDeposits(page, 15, {date, salesId: salesId as string});
			return await FinanceService.getAllPayments(page, 15, {
				search: searchQ,
				batchingPlantId: bp?.valueStr,
				customerName: searchQcustomerPic
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res.data?.data.map((data) => {
					return {
						id: data.id,
						number: data.number ?? "-",
						status: data.status ?? "-",
						totalDeposit: data.amount
							? getPriceFormatted(data.amount, "Rp ")
							: "Rp -",
						customerName: data.Account?.Customer
							? data.Account?.Customer?.displayName
							: "-",
						customerType:
							data?.Account?.Project?.Customer?.type === "COMPANY"
								? "PERUSAHAAN"
								: data?.Account?.Customer?.type,
						projectName: "-",
						city: "-",
						createdBy: data?.User?.fullName ?? "-",
						date: formatDate(data.createdAt)
					};
				});
				setTableData((curr) => {
					return {
						...curr,
						dataTable: data ?? [],
						totalItems: res.data?.totalItems,
						totalPages: res.data?.totalPages,
						currentPage: res.data?.currentPage
					};
				});
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const dispatch = useAppDispatch();

	// create payment
	const { localDialog, setLocalDialog } = useLocalDialog();
	const [loadingCreate, setLoadingCreate] = React.useState(false);

	const handleCreateLoading = (isLoading: boolean) => {
		setLoadingCreate(isLoading);
	};
	if ((isLoading && isFetchedAfterMount) || loadingCreate) {
		return <Loading />;
	}

	return (
		<>
			{/* <Header data={tableData.summary} date={date} show /> */}
			<Header />
			<Container className="mt--7" fluid>
				{/* Table */}
				<Row>
					<div className="col">
						<Card style={{}}>
							<CardTitle
								style={{
									display: "flex",
									justifyContent: "space-between",
									marginBottom: "0",
									padding: "1rem"
								}}
							>
								<Form>
									<FormGroup style={{ display: "flex", gap: "1rem" }}>
										<Input
											placeholder="NOMOR DEPOSIT"
											value={searchText}
											onChange={(e) => handleNumberSearch(e.target.value)}
										/>
										<Input
											placeholder="NAMA PELANGGAN"
											value={searchCustomerPic}
											onChange={(e) => handleCustomerSearch(e.target.value)}
										/>
									</FormGroup>
								</Form>
								{paymentPermission && paymentPermission.create && (
									<Button
										onClick={() => {
											localDialog.onOpen();
										}}
										size="sm"
										color="success"
										className="confirm-button approve-button"
										style={{
											justifySelf: "center",
											alignSelf: "center"
										}}
									>
										Buat Pembayaran
									</Button>
								)}
							</CardTitle>

							<CardBody
								style={{
									paddingTop: "0"
								}}
							>
								<div className="break-line"></div>
								<MTable
									columns={muiColumns}
									tableData={tableData.dataTable as any[]}
									tableTitle=""
									totalItems={tableData.totalItems as number}
									currentPage={
										tableData.currentPage as number
									}
									selectableRowsHideCheckboxes={true}
									isSearchVisible={false}
									setPage={(page) => {
										setPage(page);
									}}
									options={{
										onRowClick(rowData, rowMeta) {
											navigate(
												`${location.pathname}/${tableData.dataTable?.[
													rowMeta.dataIndex
												].id
												}`
											);
										}
									}}
								/>
							</CardBody>
						</Card>
					</div>
				</Row>
			</Container>
			<CreatePayment
				localDialog={localDialog}
				setLocalDialog={setLocalDialog}
				refetch={refetch}
				handleCreateLoading={handleCreateLoading}
			/>
		</>
	);
}
