import React, { useEffect, useState } from "react";

import "split-pane-react/esm/themes/default.css";
import styles from "./index.module.scss";
const extrasUrl = process.env.REACT_APP_EXTRAS;
import { AccurateService } from "api/axios";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { openAlert } from "store/features/alert/AlertSlice";
import { AccurateLogin } from "components";
import { getAccurateCreds } from "helper";
import { useAppSelector } from "store/hooks";


const Scheduler = () => {
	const dispatch = useDispatch();
	const { isAccurateDisabled: accurateStatusDisabled } = useAppSelector((state) => state.setting);
	const isAccurateDoDisabled =
	process.env.REACT_APP_IS_ACCURATE_DISABLED === "true" || accurateStatusDisabled;

	const { isError, isLoading } = useQuery(
		["query-customer-details"],
		async () => {
			const res = await AccurateService.checkToken();
			return res;
		},
		{
			onError(err) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
				let message = "Accurate Error/ Tolong Login Ulang";

				if (err?.response?.data?.error?.data?.error) {
					message = `Accurate Error/ ${err?.response?.data?.error?.status} - ${err?.response?.data?.error?.data?.error}`;
					localStorage.removeItem("accurateCreds");
				}
				dispatch(
					openAlert({
						body: message,
						color: "danger"
					})
				);
			},
			enabled: !isAccurateDoDisabled
		}
	);

	const [stopCondition, setStopCondition] = useState(false);

	useEffect(() => {
		let intervalId;
		let timeoutId;
		if (stopCondition) {
			intervalId = setInterval(() => {
				if (getAccurateCreds()?.user) {
					clearInterval(intervalId);
					setStopCondition(false);
					console.log("Function stopped!");
					// window.location.reload();
				} else {
					console.log("Checking the condition...");
				}
			}, 5000); // 5000 milliseconds = 5 seconds

			// Stop the interval after 5 minutes
			timeoutId = setTimeout(() => {
				clearInterval(intervalId);
			}, 300000);
		}

		return () => {
			if (intervalId && timeoutId) {
				clearInterval(intervalId);
				clearTimeout(timeoutId);
			}
		};
	}, [stopCondition]);
	function injectDataAcu() {
		const iframe = document.getElementById("myIframe");

		if (iframe) {
			const data = getAccurateCreds();
			setTimeout(() => {
				iframe.contentWindow.postMessage(data, "*");
			}, 1500);
		}
	}

	return (
		<>
			{!isLoading && !isError ? (
				<div className={styles.mainContainer}>
					<iframe
						id="myIframe"
						className={styles.iframe}
						src={`${extrasUrl}/scheduler`}
						onLoad={injectDataAcu}
					></iframe>
				</div>
			) : (
				<AccurateLogin isError={isError} isLoading={isLoading} />
			)}
		</>
	);
};

export default Scheduler;
