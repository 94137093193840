import {
	BookmarksFill,
	BoxSeamFill,
	CalendarEventFill,
	CardChecklist,
	CardList,
	CartFill,
	Cash,
	ClipboardFill,
	DatabaseFill,
	GraphUpArrow,
	PeopleFill,
	TruckFrontFill,
	CashCoin,
	ReceiptCutoff,
	CalendarCheckFill
} from "react-bootstrap-icons";
import {
	Login,
	Register,
	Category,
	Visitations,
	VisitationDetail,
	Products,
	ProductDetail,
	Quotations,
	QuotationDetail,
	PO,
	Deposits,
	Schedules,
	ScheduleDetail,
	Delivery,
	PODetail,
	SO,
	SODetail,
	DODetail,
	DepositDetail,
	Users,
	Vehicles,
	VehicleDetail,
	AssignVisitations,
	Customer,
	Invoice,
	newCustomerDetail,
	DetailInvoice,
	WorkOrder,
	WODetil,
	ProjectDetail,
	SKUVue,
	AccurateOauth,
	Drivers,
	Proview,
	DispatcherList,
	TrialMix,
	TrialMixDetail,
} from "views";
import SalesDashboard from "views/productivity/sales-dashboard";
import SalesDetails from "views/productivity/sales-dashboard/sales-details";
import QcScheduler from "views/quality-control";
import QcAssignmentDetail from "views/quality-control/assignment-set";
import Scheduler from "views/scheduler";
import Track from "views/track";
// import Maps from "views/examples/Maps";

interface Route {
	name: string;
	layout?: string;
	path?: string;
	iconComponent?: React.ComponentType;
	component?: React.ComponentType;
	show?: boolean;
	childrens?: Route[];
}

export const routes = [
	{
		name: "Database",
		layout: "/database",
		iconComponent: DatabaseFill,
		childrens: [
			{
				path: "/customers",
				name: "Pelanggan",
				iconComponent: BookmarksFill,
				component: Customer
			},
			{
				path: "/customers/:id",
				name: "Customer Detail",
				iconComponent: newCustomerDetail,
				component: newCustomerDetail,
				show: false
			},
			{
				path: "/customers/projects/:id",
				name: "Project Detail",
				component: ProjectDetail,
				show: false
			},
			{
				path: "/categories",
				name: "Kategori",
				iconComponent: BookmarksFill,
				component: Category
			},
			{
				path: "/products",
				name: "Produk",
				iconComponent: BoxSeamFill,
				component: Products
			},
			{
				path: "/products/:id",
				name: "Detil Produk",
				component: ProductDetail,
				layout: "/database",
				show: false
			},
			{
				path: "/vehicles",
				name: "Kendaraan",
				iconComponent: TruckFrontFill,
				component: Vehicles
			},
			{
				path: "/vehicles/:id",
				name: "Detil Kendaraan",
				component: VehicleDetail,
				show: false
			},
			{
				path: "/drivers",
				name: "Driver",
				iconComponent: TruckFrontFill,
				component: Drivers
			},
			{
				path: "/dispatchers",
				name: "Dispatcher",
				iconComponent: PeopleFill,
				component: DispatcherList
			}
		]
	},
	{
		path: "/skus",
		name: "SKU",
		layout: "/sl",
		component: SKUVue,
		iconComponent: BoxSeamFill
	},
	{
		name: "Produktifitas",
		layout: "/productivity",
		iconComponent: GraphUpArrow,
		childrens: [
			{
				path: "/visitation",
				name: "Kunjungan",
				component: Visitations,
				iconComponent: ClipboardFill
			},
			{
				path: "/visitation/:id",
				name: "Detil Kunjungan",
				component: VisitationDetail,
				show: false
			},
			{
				path: "/assign-visitation",
				name: "Jadwal Kunjungan",
				component: AssignVisitations,
				iconComponent: ClipboardFill
			},
			{
				path: "/sales",
				name: "Dashboard Sales",
				layout: "/sl",
				component: SalesDashboard,
				iconComponent: ClipboardFill
			},
			{
				path: "/sales/sales-details/:salesId",
				name: "Sales Details",
				layout: "/sl",
				component: SalesDetails,
				iconComponent: ClipboardFill,
				show: false
			}
		]
	},
	{
		name: "Pesanan",
		layout: "/order",
		iconComponent: CartFill,
		childrens: [
			{
				path: "/quotation",
				name: "Quotation (SPH)",
				component: Quotations,
				iconComponent: CardList
			},
			{
				path: "/trial-mix",
				name: "Trial Mix",
				component: TrialMix,
				iconComponent: CardList
			},
			{
				path: "/trial-mix/:id",
				component: TrialMixDetail,
				name: "Detail Trial Mix",
				show: false
			},
			{
				path: "/quotation/:id",
				name: "Detil SPH",
				component: QuotationDetail,
				show: false
			},
			{
				path: "/purchase-orders",
				name: "Purchase Orders (PO)",
				component: PO,
				iconComponent: CardChecklist
			},
			{
				path: "/purchase-orders/:id",
				name: "Detil PO",
				component: PODetail,
				show: false
			},
			{
				path: "/sales-orders",
				name: "Sales Orders (SO)",
				component: SO,
				iconComponent: CardChecklist
			},
			{
				path: "/sales-orders/:id",
				name: "Detil SO",
				component: SODetail,
				show: false
			},
			{
				path: "/schedule",
				name: "Jadwal",
				component: Schedules,
				iconComponent: CalendarEventFill
			},
			{
				path: "/schedule/:id",
				name: "Detil Jadwal",
				component: ScheduleDetail,
				show: false
			},
			{
				path: "/work-orders",
				name: "Work Orders (WO)",
				component: WorkOrder,
				iconComponent: CardChecklist
			},
			{
				path: "/work-orders/:id",
				name: "Detil WO",
				component: WODetil,
				show: false
			},
			{
				path: "/delivery-orders",
				name: "Delivery Orders (DO)",
				component: Delivery,
				iconComponent: CardChecklist
			},
			{
				path: "/delivery-orders/:id",
				name: "Detil DO",
				component: DODetail,
				show: false
			}
		]
	},
	{
		name: "Keuangan",
		layout: "/finance",
		iconComponent: CashCoin,
		childrens: [
			{
				path: "/invoice",
				name: "Tagihan",
				component: Invoice,
				iconComponent: ReceiptCutoff
			},
			{
				path: "/invoice/:id",
				name: "Detail Invoice",
				component: DetailInvoice,
				show: false
			},
			{
				path: "/deposit",
				name: "Pembayaran",
				component: Deposits,
				iconComponent: Cash
			},
			{
				path: "/deposit/:id",
				name: "Detil Pembayaran",
				component: DepositDetail,
				show: false
			}
		]
	},
	{
		path: "/users",
		name: "Pengguna",
		iconComponent: PeopleFill,
		component: Users
	},
	{
		path: "/track",
		name: "Lacak",
		layout: "/sl",
		iconComponent: PeopleFill,
		component: Track
	},
	{
		path: "/stream",
		name: "Proview",
		layout: "/sl",
		iconComponent: PeopleFill,
		component: Proview,
		show: false
	},
	{
		path: "/scheduler",
		name: "Scheduler",
		layout: "/sl",
		iconComponent: PeopleFill,
		component: Scheduler
	},
	{
		path: "/quality-control",
		name: "Quality Control",
		layout: "/sl",
		iconComponent: CalendarCheckFill,
		component: QcScheduler
	},
	{
		path: "/quality-control/assignment-set/:assignmentSetId",
		name: "Detail Assignment QC",
		layout: "/sl",
		iconComponent: PeopleFill,
		component: QcAssignmentDetail,
		show: false
	},
	{
		path: "/login",
		name: "Masuk",
		icon: "ni ni-key-25 text-info",
		component: Login,
		layout: "/auth",
		show: false
	},
	{
		path: "/register",
		name: "Daftar",
		icon: "ni ni-circle-08 text-pink",
		component: Register,
		layout: "/auth",
		show: false
	},
	{
		path: "/accurate-oauth",
		name: "Accurate Oauth",
		icon: "ni ni-tv-2 text-primary",
		component: AccurateOauth,
		layout: "/sl",
		show: false
	}
];

function createRouteMapping(routes: Route[]) {
	const routeMapping: any = {};

	routes.forEach((route) => {
		if (route.layout && route.childrens) {
			route.childrens.forEach((child) => {
				if (route.layout) {
					routeMapping[route.layout + child.path] = child.name;
				}
			});
		} else {
			routeMapping[route.path as string] = route.name;
		}
	});

	return routeMapping;
}

export function matchUrlWithPathPattern(
	url: string,
	pathPattern: string
): boolean {
	const urlParts: string[] = url.split("/");
	const patternParts: string[] = pathPattern.split("/");

	if (urlParts.length !== patternParts.length) {
		return false;
	}

	for (let i = 0; i < urlParts.length; i++) {
		const urlPart = urlParts[i];
		const patternPart = patternParts[i];

		if (patternPart && patternPart.startsWith(":")) {
			continue;
		}

		if (urlPart !== patternPart) {
			return false;
		}
	}

	return true;
}

export const routeMapping = createRouteMapping(routes);
export function getRouteName(
	url: string,
	routeMapping: { [key: string]: string }
): string | undefined {
	for (const pattern in routeMapping) {
		if (matchUrlWithPathPattern(url, pattern)) {
			return routeMapping[pattern];
		}
	}
	return undefined;
}
