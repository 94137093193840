import React, { useMemo, useState, useEffect } from "react";
import { Button, Container, Row, Form, FormGroup, Input } from "reactstrap";
// core components
import Header from "../../components/Headers/Header";
import BTable from "../../components/B/table";
import useListSearchParams from "../../hooks/useQuery";
import { useMutation, useQuery } from "react-query";
import { CommonService } from "../../api/axios";
import Loading from "../../components/B/loading";
import { formatPhone } from "helper";
import { useLocalDialog } from "hooks";
import { LocalDialog, StatusChip } from "components";
import BForm from "components/B/form";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { getRouteName, routeMapping } from "routes";
// import BMTable from "components/B/BMTable";
import EditUser from "./EditUser";
import BMTable from "components/B/BMTable";
import BUserType from "components/B/userType";
import { useDebounce } from "use-debounce";
import BPlantColor from "components/B/batchingPlant";

interface RoleOptions {
	readonly valueStr: string;
	readonly labelStr: string;
	readonly color?: string;
	readonly isFixed?: boolean;
	readonly isDisabled?: boolean;
}

interface RoleResponse extends ApiResponse {
	data: { id: string; name: string }[];
}

interface ApiResponseBP extends ApiResponse {
	data: {
		data: { id: string; code: string; addressId?: string; name: string }[];
	};
}

const Users = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [searchUserName, setSearchUserName] = useState<string>();
	const [searchQName] = useDebounce(searchUserName, 500);
	const [searchPhone, setSearchPhone] = useState<string>();
	const [searchQPhone] = useDebounce(searchPhone, 500);
	const [isEditUserOpen, setIsEditUserOpen] = useState(false);
	const [selectedUserId, setSelectedUserId] = useState("");
	const [rolesOptions, setRolesOptions] = useState<RoleOptions[]>([]);
	const [batchingPlants, setBatchingPlants] = useState([]) as any;
	const [usersBatchingPlants, setUsersBatchingPlants] = useState([]) as any;
	const { options } = useAppSelector((store) => store.batching);

	const permission = useAppSelector((state) => state.user.permission);
	const userPermission = useMemo(() => {
		const routeName = getRouteName(location.pathname, routeMapping);

		if (routeName) {
			return permission[routeName];
		}
		return {};
	}, [location, permission]);

	const [tableData, setTableData] = React.useState<Omit<IUsers, "data">>({
		message: "",
		success: true,
		currentPage: 1,
		totalItems: 15,
		totalPages: 1,
		dataTable: [],
		data: []
	});
	const { page, setPage } = useListSearchParams();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const searchParam = queryParams.get("search?username");
		const phoneParam = queryParams.get("search?phone");
		if (searchParam) {
			setSearchUserName(searchParam);
		} else {
			setSearchUserName("");
		}
		if (phoneParam) {
			setSearchPhone(phoneParam);
		} else {
			setSearchPhone("");
		}
	}, [location.search]);

	const dispatch = useAppDispatch();

	const usersColumn = [
		{
			name: "id",
			label: "ID",
			options: {
				filter: false,
				sort: false,
				display: false
			}
		},
		{
			name: "no",
			label: "NO",
			options: {
				filter: false,
				sort: false
			}
		},
		{
			name: "fullName",
			label: "Nama Pengguna",
			options: {
				filter: false,
				sort: false
			}
		},
		{
			name: "email",
			label: "Email",
			options: {
				filter: false,
				sort: false
			}
		},
		{
			name: "phone",
			label: "No. Telp",
			options: {
				filter: false,
				sort: false
			}
		},
		{
			name: "UserRoles",
			label: "Tipe Pengguna",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value: any) =>
					value.map((role: any) => (
						<BUserType key={role.id}>{role.Role.name}</BUserType>
					))
			}
		},
		{
			name: "batchingPlants",
			label: "Plant",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value: any) => {
					if (Array.isArray(value)) {
						return value.map((plant: any, idx: number) => (
							<BPlantColor key={idx}>{plant}</BPlantColor>
						));
					} else {
						return value;
					}
				}
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				filter: false,
				sort: false
			}
		}
	];

	const formatBp = (bpIds: string[] | null) => {
		if (!bpIds || bpIds.length === 0) return "-";

		const names = options
			.filter((plant: any) => bpIds.includes(plant.id))
			.map((plant: any) => plant.name);

		return names;
	};

	const { isLoading, refetch } = useQuery<IUsers, ApiError>(
		["query-users", page, searchQName, searchQPhone],
		async () => {
			return await CommonService.getUsers(page, 15, "", {
				userName: searchQName,
				phone: searchQPhone
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				setBatchingPlants(options);
				const data = res.data?.data?.map((el, i) => ({
					id: el.id,
					// 'plateNumber', 'type', 'isGps', 'deviceId', 'internalId', 'status'
					values: [
						{
							objectKey: "id",
							value: el.id, //15 is size data, look axios service,
							type: "text",
							render: false
						},
						{
							objectKey: "no",
							value: i + 1 + 15 * (page - 1), //15 is size data, look axios service,
							type: "text"
						},
						{
							objectKey: "fullName",
							value: el.fullName ?? "-",
							type: "text"
						},
						{
							objectKey: "email",
							value: el.email ?? "-",
							type: "text"
						},
						{
							objectKey: "phone",
							value: formatPhone(el?.phone) ?? "-",
							type: "text"
						},
						// {
						// 	objectKey: "UserGroup.type",
						// 	value: el.UserGroup?.type,
						// 	type: "userType"
						// },
						{
							objectKey: "UserRoles",
							value: el.UserRoles ?? "-",
							type: "userRoles"
						},
						{
							objectKey: "batchingPlants",
							value:
								formatBp(el.batchingPlantIds as string[]) ??
								"-",
							type: "text"
						},
						{
							objectKey: "status",
							value: el.status ?? "-",
							type: "status"
						}
					]
				}));

				setTableData(() => ({
					...res,
					...res.data,
					dataTable: (data as DataTable[]) ?? []
				}));
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const handleUserNameSearch = (newValue: string) => {
		setSearchUserName(newValue);

		const queryParams = new URLSearchParams();
		queryParams.set("page", "1");

		if (newValue) {
			queryParams.set("search?username", newValue);
		}

		if (searchPhone) {
			queryParams.set("search?phone", searchPhone);
		}

		navigate({
			pathname: location.pathname,
			search: queryParams.toString()
		});
	};

	const handlePhoneSearch = (newValue: string) => {
		setSearchPhone(newValue);

		const queryParams = new URLSearchParams();
		queryParams.set("page", "1");

		if (searchUserName) {
			queryParams.set("search?username", searchUserName);
		}

		if (newValue) {
			queryParams.set("search?phone", newValue);
		}

		navigate({
			pathname: location.pathname,
			search: queryParams.toString()
		});
	};

	const { isLoading: roleLoading } = useQuery<RoleResponse, ApiError>(
		["get-roles"],
		() => CommonService.getAllRoles(),
		{
			onSuccess(response) {
				const roles = response.data;
				// console.log(roles, "rolesss");
				const mapped = roles.map((role) => {
					return {
						valueStr: role.id,
						labelStr: role.name
					};
				});
				setRolesOptions(mapped);
			}
		}
	);

	const [state, setState] = React.useState<registerUser>();
	const { localDialog, setLocalDialog } = useLocalDialog();

	const forms: ElementForm[] = React.useMemo(() => {
		return [
			{
				label: "Nama",
				type: "text",
				value: state?.fullName,
				placeholder: "Tulis Nama Lengkap",
				onChange: (e: any) => {
					setState({
						...state,
						fullName: e.target.value
					});
				}
			},
			{
				label: "No. Telp",
				type: "text",
				value: state?.phone,
				placeholder: "Tulis No. Telp(WA)",
				onChange: (e: any) => {
					setState({
						...state,
						phone: e.target.value
					});
				}
			},
			{
				label: "Email",
				type: "text",
				value: state?.email,
				placeholder: "Tulis Email",
				onChange: (e: any) => {
					setState({
						...state,
						email: e.target.value
					});
				}
			},
			{
				label: "Role",
				type: "dropdown",
				value: state?.roles,
				placeholder: "Pilih Role User...",
				onChange: (e: any) => {
					setState({
						...state,
						roles: e
					});
				},
				isMulti: true,
				options: rolesOptions
			},
			{
				label: "Batching Plant",
				type: "dropdown",
				value: state?.batchingPlantIds,
				placeholder: "Pilih Batching Plant User...",
				onChange: (e: any) => {
					setState({
						...state,
						batchingPlantIds: e
					});
				},
				isMulti: true,
				options: batchingPlants
			}
		];
	}, [state, rolesOptions]);

	const { mutate, isLoading: loadingCreate } = useMutation<
		ApiResponseVehicle,
		ApiError,
		registerUser,
		ApiError
	>(
		async (data: registerUser) => {
			return await CommonService.register(data);
		},
		{
			onSuccess: (res) => {
				localDialog.onClose();
				refetch();
				setState((curr) => {
					return {
						...curr,
						fullName: "",
						email: "",
						phone: "",
						roles: [],
						batchingPlantIds: []
					};
				});
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const onSubmit = () => {
		const payload: any = {
			...state,
			roles: state?.roles?.map((role) => {
				return {
					role_id: role.valueStr,
					role_name: role.labelStr
				};
			}),
			batchingPlantIds: state?.batchingPlantIds?.map((bp: any) => bp.id)
			// type: (state?.type as options).valueStr as "ADMIN"
		};
		mutate(payload);
	};

	// if (isLoading || loadingCreate) {
	// 	return <Loading />;
	// }

	if (!tableData.data) {
		return <div>Loading...</div>;
	}

	return (
		<>
			<Header />
			{/* Page content */}
			{isEditUserOpen ? (
				<EditUser
					isOpen={isEditUserOpen}
					setIsOpen={setIsEditUserOpen}
					userId={selectedUserId}
					refetch={refetch}
				/>
			) : null}

			<Container
				style={{
					position: "relative",
					top: "-10rem"
				}}
				fluid
			>
				{/* Table */}
				<Row>
					<div className="col">
						<BMTable
							tableTitle=""
							hideFilter
							header={
								<>
									{userPermission && userPermission.create ? (
										<Button
											style={{ float: "right" }}
											onClick={() => {
												localDialog.onOpen();
											}}
											size="sm"
											color="success"
											className="confirm-button approve-button"
										>
											Buat Pengguna
										</Button>
									) : null}
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											paddingBottom: "0.5rem"
										}}
									>
										<Form>
											<FormGroup
												style={{
													display: "flex",
													gap: "1rem"
												}}
											>
												<Input
													placeholder="NAMA PENGGUNA"
													value={searchUserName}
													onChange={(e) =>
														handleUserNameSearch(
															e.target.value
														)
													}
												/>
												<Input
													placeholder="NO. TELP"
													value={searchPhone}
													onChange={(e) =>
														handlePhoneSearch(
															e.target.value
														)
													}
												/>
											</FormGroup>
										</Form>
									</div>
								</>
							}
							columns={usersColumn}
							// hideFilter
							tableData={tableData.dataTable as DataTable[]}
							currentPage={tableData.currentPage as number}
							totalItems={tableData.totalItems as number}
							options={{
								onRowClick(rowData, rowMeta) {
									if (
										userPermission &&
										userPermission.update
									) {
										setSelectedUserId(rowData[0] as string);
										setIsEditUserOpen(true);
									}
								}
							}}
						/>
						{/* <BTable
							header={
								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										paddingBottom: "0.5rem"
									}}
								>
									{userPermission && userPermission.create ? (
										<Button
											onClick={() => {
												localDialog.onOpen();
											}}
											size="sm"
											color="success"
											className="confirm-button approve-button"
										>
											Pengguna Baru
										</Button>
									) : null}
								</div>
							}
							hideFilter={true}
							currentPage={page}
							totalPage={tableData.totalPages as number}
							totalItem={tableData.totalItems as number}
							columns={[
								{
									key: "fullName",
									value: "Nama"
								},
								{
									key: "email",
									value: "Email"
								},
								{
									key: "phone",
									value: "No. Telp"
								},
								{
									key: "type",
									value: "Roles"
								},
								{
									key: "status",
									value: "Status"
								}
							]}
							data={tableData.dataTable as DataTable[]}
							rowOnClick={(data) => {
								if (userPermission && userPermission.update) {
									setSelectedUserId(data.id);
									setIsEditUserOpen(true);
								}
							}}
						/> */}
					</div>
				</Row>
			</Container>
			<LocalDialog
				isOpen={localDialog.isOpen}
				backdrop={true}
				header="Buat Pengguna Baru"
				onClick={onSubmit}
				onClose={() => {
					localDialog.onClose();
				}}
				rightBtnTitle="Buat"
				leftBtnTitle="Batal"
			>
				<BForm forms={forms} />
			</LocalDialog>
		</>
	);
};

export default Users;
