import React from "react";

import {Navigate, Outlet} from "react-router-dom"

const useAuth=()=>{
  const user=localStorage.getItem("AccessToken")
  if(user){
    return true
  } else {
    return false
  }
}

const  ProtectedRoutes=({children}: {children:any}) =>{

  const auth=useAuth()

  return auth? children : <Navigate to="/auth/login"/>
}

export default ProtectedRoutes;