import React from "react";
import MUIDataTable, {
	MUIDataTableColumnDef,
	MUIDataTableIsRowCheck,
	MUIDataTableProps
} from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Pagination } from "@mui/material";
import BForm from "./form";
import { CommonService } from "api/axios";
import { useQuery } from "react-query";
import { DateRangePicker } from "react-date-range";
import { useListSearchParams } from "hooks";
import {
	Button,
	Card,
	CardBody,
	Dropdown,
	DropdownMenu,
	DropdownToggle,
	Label
} from "reactstrap";

const getMuiTheme = () =>
	createTheme({
		components: {
			MuiPaper: {
				styleOverrides: {
					root: {
						boxShadow: "none !important",
						width: "100%",
						padding: "20px"
					}
				}
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						fontSize: "0.75rem",
						// textTransform: "capitalize",
						letterSpacing: "1px",
						// color: #525f7f;
						color: "#525f7f"
					},
					head: {
						fontSize: "0.85rem",
						textTransform: "uppercase"
					}
				}
			},
			MUIDataTableBodyRow: {
				styleOverrides: {
					root: {
						"&:nth-of-type(odd)": {
							backgroundColor: "#e9ecef"
						}
					}
				}
			},
			MUIDataTableBodyCell: {
				styleOverrides: {
					root: {
						cursor: "pointer"
					}
				}
			}
		}
	});

interface IFilterDate {
	state: DRange[];
	setState: React.Dispatch<
		React.SetStateAction<{
			date?: DRange[] | undefined;
			sales?:
				| {
						id?: string | undefined;
						fullName?: string | undefined;
						options?: options[] | undefined;
				  }
				| undefined;
		}>
	>;
}

interface IFilterSales {
	state?: any;
	isOpen: boolean;
	setState: React.Dispatch<
		React.SetStateAction<{
			date?: DRange[] | undefined;
			sales?:
				| {
						id?: string | undefined;
						fullName?: string | undefined;
						options?: options[] | undefined;
				  }
				| undefined;
		}>
	>;
}

type ChipStatusType =
	| "SUBMITTED"
	| "APPROVED"
	| "DECLINED"
	| "FINISHED"
	| "CLOSED";

interface IChipColors {
	SUBMITTED: string;
	APPROVED: string;
	DECLINED: string;
	FINISHED: string;
	CLOSED: string;
}
const chipColors = {
	SUBMITTED: "#ffc554", // yellow
	APPROVED: "#2dce89",
	FINISHED: "#2dce89", // green
	DECLINED: "#f5365c", // red
	CLOSED: "#f5365c"
} as IChipColors;

const FilterDate = ({ state, setState }: IFilterDate) => {
	return (
		<DateRangePicker
			onChange={(item) => {
				setState((prev) => ({
					...prev,
					date: [item.selection as DRange]
				}));
			}}
			months={1}
			direction="horizontal"
			scroll={{ enabled: false }}
			ranges={state}
		/>
	);
};

const FilterSales = ({ state, setState, isOpen }: IFilterSales) => {
	//option sales
	useQuery<IUsers, ApiError>(
		["query-filter-sales"],
		async () => {
			return await CommonService.getUsers(1, 99, "SALES");
		},
		{
			enabled: isOpen,
			onSuccess: (res) => {
				if (!res.data?.data) return;
				let preselected = {};
				const optionsSales = res.data.data.map((el) => {
					if (state.valueStr) {
						if (state.valueStr === el.id) {
							preselected = {
								...el,
								valueStr: el.id,
								labelStr: el.fullName
							};
						}
					}
					return {
						...el,
						valueStr: el.id,
						labelStr: el.fullName
					};
				});
				setState((prev) => ({
					...prev,
					sales: {
						// ...state,
						...preselected,
						options: optionsSales
					}
				}));
			}
		}
	);

	const forms: ElementForm[] = React.useMemo(() => {
		return [
			{
				type: "dropdown",
				value: state,
				placeholder: "Pilih Sales",
				onChange: (e: any) => {
					setState((prev) => ({
						...prev,
						sales: {
							...e,
							options: prev.sales?.options
						}
					}));
				},
				options: state?.options
			}
		] as ElementForm[];
	}, [state]);
	return <BForm forms={forms} />;
};

export default function MTable({
	rowsPerPageOptions = [15, 20, 25],
	rowsPerPage = 15,
	searchPlaceholder = "Cari",
	columns,
	tableData,
	tableTitle,
	showPrint = false,
	showDownload = false,
	showFilter = false,
	setPage,
	setRowsPerPage,
	totalItems,
	onSearchChange,
	selectedRows,
	setSelectedRows,
	setDeselectRows,
	currentPage,
	isSearchVisible = true,
	selectableRowsHideCheckboxes,
	options,
	header,
	onDownload,
	hideFilter,
	filterSales,
	filterDate,
	isSelect,
	disabled,
	typeSelectableRows,
	isRowSelectable
}: {
	rowsPerPageOptions?: number[];
	rowsPerPage?: number;
	searchPlaceholder?: string;
	columns: MUIDataTableColumnDef[];
	tableData: any[];
	tableTitle: string;
	showPrint?: boolean;
	showDownload?: boolean;
	showFilter?: boolean;
	setPage?: (page: number) => void;
	setRowsPerPage?: (rows: number) => void;
	totalItems: number;
	onSearchChange?: (searchQuery: string | null) => void;
	selectedRows?: number[];
	setSelectedRows?: (index: number) => void;
	setDeselectRows?: (index: number) => void;
	currentPage?: number;
	selectableRowsHideCheckboxes?: boolean;
	isSearchVisible?: boolean;
	options?: MUIDataTableProps["options"];
	header?: any;
	onDownload?: () => void;
	hideFilter?: boolean;
	filterSales?: boolean;
	filterDate?: boolean;
	isSelect?: boolean;
	disabled?: boolean;
	typeSelectableRows?: string;
	isRowSelectable?: (
		dataIndex: number,
		selectedRows?: MUIDataTableIsRowCheck
	) => boolean;
}) {
	const {
		page,
		date,
		setDate,
		clearParams,
		setParams,
		searchParams,
		removeParams
	} = useListSearchParams();
	const [state, setState] = React.useState<{
		date?: DRange[];
		sales?: {
			id?: string;
			fullName?: string;
			options?: options[];
			[key: string]: any;
		};
		[key: string]: any;
	}>({
		date: [
			{
				startDate: date.startDate,
				endDate: date.endDate,
				key: "selection"
			}
		],
		sales: {
			valueStr: searchParams.get("sales")
				? (searchParams.get("sales") as string)
				: undefined
		}
	});
	const [isOpen, setIsopen] = React.useState<boolean>(false);
	const [isShowCalendar, setIsShowCalendar] = React.useState(true);
	const toggle = () => setIsopen((curr) => !curr);
	const onSave = () => {
		toggle();
		const params = [];

		for (const key of Object.keys(state)) {
			if (key === "date") {
				if (state?.date?.[0]) {
					params.push({
						key: "date",
						value: state?.date?.[0]
					});
					// setDate(state?.date?.[0]);
				}
			} else {
				if (state[key]?.id) {
					params.push({
						key: key,
						value: state?.[key]?.id
					});
					// setParam(key, state[key]?.id);
				} else {
					removeParams("sales");
				}
			}
		}

		setParams(params);
	};
	const onClear = (key: "sales" | "date" | "all") => () => {
		if (key === "all") {
			toggle();
			clearParams();
			return;
		}

		// if (key === "date") {
		// 	setState({
		// 		...state,
		// 		date: []
		// 	});
		// 	removeParams("startDate");
		// 	removeParams("endDate");
		// 	return;
		// }
	};

	// const CustomHeader = () => {
	// 	return (
	// 		<Card className="">
	// 			<CardBody>
	// 				{!!header && header}
	// 				{!hideFilter && (
	// 					<Dropdown
	// 						isOpen={isOpen}
	// 						toggle={() => null}
	// 						className="float-right dropdown-min"
	// 					>
	// 						<DropdownToggle
	// 							className="text-light"
	// 							role="button"
	// 							size="sm"
	// 							color=""
	// 							onClick={toggle}
	// 						>
	// 							Filter{" "}
	// 							<i className="fas fa-regular fa-filter"></i>
	// 						</DropdownToggle>
	// 						<DropdownMenu className="dropdown-menu-arrow" end>
	// 							{filterSales && (
	// 								<>
	// 									<div className="dropdown-item d-flex justify-content-end">
	// 										{/* <Button onClick={() => {
	// 										onClear("sales")();
	// 									}} size="sm" color="secondary" outline>
	// 										<i className="ni ni-fat-delete" />
	// 									</Button> */}
	// 									</div>
	// 									<div className="dropdown-item">
	// 										<Label for="date">Sales</Label>
	// 										<div id="date">
	// 											<FilterSales
	// 												state={state?.sales}
	// 												setState={setState}
	// 												isOpen={isOpen}
	// 											/>
	// 										</div>
	// 									</div>
	// 								</>
	// 							)}

	// 							{/* Filter Date */}
	// 							<div className="dropdown-item d-flex justify-content-end">
	// 								<Button
	// 									onClick={() => {
	// 										if (!isShowCalendar) {
	// 											setState({
	// 												...state,
	// 												date: [
	// 													{
	// 														startDate:
	// 															date.startDate,
	// 														endDate:
	// 															date.endDate,
	// 														key: "selection"
	// 													}
	// 												]
	// 											});
	// 										} else {
	// 											setState({
	// 												...state,
	// 												date: []
	// 											});
	// 											removeParams("startDate");
	// 											removeParams("endDate");
	// 										}
	// 										// onClear("date")();
	// 										setIsShowCalendar((curr) => !curr);
	// 									}}
	// 									size="sm"
	// 									color="secondary"
	// 									outline
	// 								>
	// 									{/* <i className="ni ni-fat-delete" /> */}
	// 									{isShowCalendar
	// 										? "Seluruh Waktu"
	// 										: "Pilih Tanggal"}
	// 								</Button>
	// 							</div>

	// 							<div className="dropdown-item">
	// 								{isShowCalendar ? (
	// 									<>
	// 										<Label for="date">Date</Label>
	// 										<div id="date">
	// 											<FilterDate
	// 												state={
	// 													state.date as DRange[]
	// 												}
	// 												setState={setState}
	// 											/>
	// 										</div>
	// 									</>
	// 								) : null}
	// 							</div>

	// 							<div className="dropdown-item flex">
	// 								<Button color="primary" onClick={onSave}>
	// 									Save
	// 								</Button>
	// 								<Button onClick={onClear("all")}>
	// 									Clear
	// 								</Button>
	// 							</div>
	// 						</DropdownMenu>
	// 					</Dropdown>
	// 				)}

	// 				{/* Download Button */}
	// 				{onDownload && (
	// 					<Button
	// 						color="primary"
	// 						className="float-right mr-2 btn-sm shadow-none mb-2"
	// 						onClick={onDownload}
	// 					>
	// 						Download To Excel
	// 					</Button>
	// 				)}
	// 			</CardBody>
	// 		</Card>
	// 	);
	// };

	return (
		<ThemeProvider theme={getMuiTheme()}>
			<MUIDataTable
				title={tableTitle}
				columns={columns}
				data={tableData}
				components={{
					TableFooter(props) {
						const count =
							typeof props.options?.count === "number"
								? props.options?.count
								: 0;
						const rowsPerPage =
							typeof props.options?.rowsPerPage === "number"
								? props.options?.rowsPerPage
								: 0;
						const totalPage = count / rowsPerPage;

						return (
							<div className="pagination-wrapper">
								<Pagination
									count={Math.ceil(totalPage)}
									onChange={(_, page) => {
										if (props.options?.onChangePage) {
											props.options?.onChangePage(
												page - 1
											);
										}
									}}
									page={props.options?.page}
								/>
							</div>
						);
					}
				}}
				options={{
					serverSide: true,
					print: showPrint,
					download: showDownload,
					filter: showFilter,
					// pagination: false,

					selectableRowsHeader: false,
					viewColumns: false,
					selectableRowsHideCheckboxes: selectableRowsHideCheckboxes,
					searchPlaceholder: searchPlaceholder,
					rowsPerPage: rowsPerPage,
					rowsPerPageOptions: rowsPerPageOptions,
					onTableChange(action, tableState) {
						if (action === "changeRowsPerPage") {
							if (setRowsPerPage) {
								setRowsPerPage(tableState.rowsPerPage);
							}
						}
					},
					onChangePage(currentPage) {
						if (setPage) {
							setPage(currentPage + 1);
						}
					},
					page: currentPage,
					onSearchChange(searchText) {
						//
						if (onSearchChange) {
							onSearchChange(searchText);
							if (setPage) {
								setPage(1);
							}
						}
					},
					onSearchClose() {
						if (onSearchChange) {
							onSearchChange(null);
						}
					},
					count: totalItems,
					rowsSelected: selectedRows,
					onRowSelectionChange(
						currentRowsSelected,
						allRowsSelected,
						rowsSelected
					) {
						if (selectedRows && rowsSelected) {
							if (selectedRows?.length > rowsSelected?.length) {
								if (setDeselectRows) {
									setDeselectRows(
										currentRowsSelected[0].index
									);
								}
							} else {
								if (setSelectedRows) {
									setSelectedRows(
										currentRowsSelected[0].index
									);
								}
							}
						}
					},
					onRowsDelete(rowsDeleted, newTableData) {
						console.log(rowsDeleted, newTableData, "onRowsDelete");
					},
					selectableRows: "multiple",
					selectToolbarPlacement: "none",
					searchAlwaysOpen: false,
					search: isSearchVisible,
					isRowSelectable: isRowSelectable,
					...options
				}}
			/>
		</ThemeProvider>
	);
}
