import React, {
	ChangeEvent,
	Dispatch,
	SetStateAction,
	useEffect,
	useMemo,
	useState
} from "react";
import { LocalDialog, MTable, StatusChip } from "components";
import { useLocalDialog } from "hooks";
import {
	Button,
	Col,
	FormFeedback,
	FormGroup,
	Input,
	Label,
	Row
} from "reactstrap";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { useMutation, useQuery } from "react-query";
import { AccurateService, CommonService } from "api/axios";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import { NavLink } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDebounce } from "use-debounce";
import Select from "react-select";
import moment from "moment";
import InputMask from "react-input-mask";
import { BoxArrowUpRight } from "react-bootstrap-icons";
import { InputNumberFormat } from "@react-input/number-format";

const CreateCustomerSchema = Yup.object().shape({
	displayName: Yup.string()
		// .min(4, "Minimum 4 karakter")
		// .max(50, "Terlalu Panjang")
		.required("Nama Pelanggan Dibutuhkan"),
	type: Yup.string().required("Tipe Pelanggan Dibutuhkan"),
	nik: Yup.string().when("type", (type, schema) => {
		return type[0] === "INDIVIDU"
			? schema.required("NIK Dibutuhkan")
			: schema.optional();
	}),
	// .required("NIK Di butuhkan"),
	// .matches(/^\d{16}$/, "NIK harus 16 digit"),
	npwp: Yup.string()
		.min(15, "NPWP minimal 15 karakter")
		.when("type", (type, schema) => {
			return type[0] === "COMPANY"
				? schema.required("NPWP Dibutuhkan")
				: schema.optional();
		}),
	// .required("NPWP Dibutuhkan"),
	email: Yup.string().optional(),
	// .email("Email Invalid").notRequired(),
	name: Yup.string()
		// .min(4, "Minimum 4 Karakter")
		// .max(50, "Terlalu Panjang")
		.required("Nama PIC Dibutuhkan"),
	position: Yup.string()
		.max(50, "Terlalu Panjang")
		.required("Posisi PIC Dibutuhkan"),
	phone: Yup.string()
		.matches(/^\d+$/, "Nomor telepon harus angka")
		.max(50, "Terlalu Panjang")
		.required("No Telepon PIC Dibutuhkan"),
	address: Yup.object().shape({
		valueStr: Yup.string().required("Alamat Pembayaran Di butuhkan!"),
		labelStr: Yup.string().required("Alamat Pembayaran Di butuhkan!")
	}),
	description: Yup.string().optional(),
	paymentTerm: Yup.object().shape({
		valueStr: Yup.string().required("Syarat Pembayaran harus dipilih"),
		labelStr: Yup.string().required("Syarat Pembayaran harus dipilih")
	}),
	mobilizationFee: Yup.object().shape({
		valueStr: Yup.string().required(
			"Dikenakan Biaya Mobilisasi harus dipilih"
		),
		labelStr: Yup.string().required(
			"Dikenakan Biaya Mobilisasi harus dipilih"
		)
	}),
	mobilizationOption: Yup.object().shape({
		valueStr: Yup.string().required("Syarat Mobilisasi harus dipilih"),
		labelStr: Yup.string().required("Syarat Mobilisasi harus dipilih")
	}),
	customerDeveloper: Yup.object().shape({
		valueStr: Yup.string().required("Developer harus dipilih"),
		labelStr: Yup.string().required("Developer harus dipilih")
	})
	// .notRequired()
});

const CreateCustomerSchemaNoAccurate = Yup.object().shape({
	displayName: Yup.string().required("Nama Pelanggan Dibutuhkan"),
	type: Yup.string().required("Tipe Pelanggan Dibutuhkan"),
	nik: Yup.string().when("type", (type, schema) => {
		return type[0] === "INDIVIDU"
			? schema.required("NIK Dibutuhkan")
			: schema.optional();
	}),
	npwp: Yup.string()
		.min(15, "NPWP minimal 15 karakter")
		.when("type", (type, schema) => {
			return type[0] === "COMPANY"
				? schema.required("NPWP Dibutuhkan")
				: schema.optional();
		}),

	email: Yup.string().optional(),

	name: Yup.string().required("Nama PIC Dibutuhkan"),
	position: Yup.string()
		.max(50, "Terlalu Panjang")
		.required("Posisi PIC Dibutuhkan"),
	phone: Yup.string()
		.matches(/^\d+$/, "Nomor telepon harus angka")
		.max(50, "Terlalu Panjang")
		.required("No Telepon PIC Dibutuhkan"),
	address: Yup.object().shape({
		valueStr: Yup.string().required("Alamat Pembayaran Di butuhkan!"),
		labelStr: Yup.string().required("Alamat Pembayaran Di butuhkan!")
	}),
	description: Yup.string().optional(),
	mobilizationOption: Yup.object().shape({
		valueStr: Yup.string().required("Syarat Mobilisasi harus dipilih"),
		labelStr: Yup.string().required("Syarat Mobilisasi harus dipilih")
	}),
	customerDeveloper: Yup.object().shape({
		valueStr: Yup.string().required("Developer harus dipilih"),
		labelStr: Yup.string().required("Developer harus dipilih")
	})
	// .notRequired()
});

const mobilizationOptions = [
	{
		valueStr: "Total Pengiriman di Hari H <4m3",
		labelStr: "Total Pengiriman di Hari H <4m3"
	},
	{
		valueStr: "Free Mobilisasi Berdasarkan Pengajuan Sales",
		labelStr: "Free Mobilisasi Berdasarkan Pengajuan Sales"
	},
	{
		valueStr: "N/A",
		labelStr: "N/A"
	}
];

const mobilizationFee = [
	{
		valueStr: "Iya",
		labelStr: "Iya"
	},
	{
		valueStr: "Tidak",
		labelStr: "Tidak"
	}
];

const muiColumns = [
	{
		name: "nama",
		label: "NAMA",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "city",
		label: "KOTA",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "lastOrder",
		label: "PEMESANAN TERAKHIR",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "pic",
		label: "PIC",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "sales",
		label: "SALES",
		options: {
			filter: false,
			sort: false
		}
	}
];

const taxType = [
	{ valueStr: "BKN_PEMUNGUT_PPN", labelStr: "01 - Bukan Pemungut PPN" },
	{
		valueStr: "BESARAN_TERTENTU",
		labelStr: "05 - Besaran Tertentu (Pasal 9A ayat (1) UU PPN)"
	},
	{ valueStr: "DPP_NILAILAIN", labelStr: "04 - DPP Nilai Lain" },
	{ valueStr: "EXPBKP_TDKWJD", labelStr: "14 - Ekspor BKP Tidak Berwujud" },
	{ valueStr: "EXPBKP_WJD", labelStr: "13 - Ekspor BKP Berwujud" },
	{ valueStr: "EXP_JKP", labelStr: "15 - Ekspor JKP" },
	{ valueStr: "PAJAK_DIDEEMED", labelStr: "05 - Pajak di Deemed" },
	{
		valueStr: "PEMUNGUT_BENDAHARA_PEMERINTAH",
		labelStr: "02 - Pemungut Bendaharawan Pemerintah"
	},
	{ valueStr: "PEMUNGUT_PPN", labelStr: "03 - Pemungut Selain Bendaharawan" },
	{
		valueStr: "PENYERAHAN_ASSET",
		labelStr: "09 - Penyerahan Aset (Pasal 16D UU PPN)"
	},
	{
		valueStr: "PENYERAHAN_LAIN",
		labelStr:
			"06 - Penyerahan Lainnya, termasuk penyerahan kepada turis asing dalam rangka VAT refund"
	},
	{ valueStr: "PPN_DIBEBASKAN", labelStr: "08 - PPN Dibebaskan" },
	{ valueStr: "PPN_TDK_DIPUNGUT", labelStr: "07 - PPN Tidak Dipungut" }
];

const documentType = [
	{
		valueStr: "INVOICE",
		labelStr: "Faktur Pajak"
	},
	{
		valueStr: "EXPORT",
		labelStr: "Dokumen Ekspor (PEB)"
	},
	{
		valueStr: "DOCUMENT",
		labelStr: "Dokumen Dipersamakan FP"
	},
	{
		valueStr: "DIGUNGGUNG",
		labelStr: "Digunggung"
	}
];

const sourcLeadOptions = [
	{
		valueStr: "Website",
		labelStr: "Website"
	},
	{
		valueStr: "Tokopedia",
		labelStr: "Tokopedia"
	},
	{
		valueStr: "Shopee",
		labelStr: "Shopee"
	},
	{
		valueStr: "Direct Sales",
		labelStr: "Direct Sales"
	},
	{
		valueStr: "WATI",
		labelStr: "WATI"
	},
	{
		valueStr: "Agent",
		labelStr: "Agent"
	}
];

const Developers = [
	{
		valueStr: "N/A",
		labelStr: "N/A"
	},
	{
		valueStr: "Sinarmas Land",
		labelStr: "Sinarmas Land"
	},
	{
		valueStr: "Summarecon",
		labelStr: "Summarecon"
	},
	{
		valueStr: "Deltamas",
		labelStr: "Deltamas"
	},
	{
		valueStr: "CGST",
		labelStr: "CGST"
	},
	{
		valueStr: "Paramount",
		labelStr: "Paramount"
	},
	{
		valueStr: "Modernland",
		labelStr: "Modernland"
	},
	{
		valueStr: "Grand Wisata",
		labelStr: "Grand Wisata"
	},
	{
		valueStr: "Lippo Cikarang",
		labelStr: "Lippo Cikarang"
	}
];

export default function CreateCustomer({
	isOpen,
	setIsOpen,
	refetch
}: // refetch
{
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	refetch: () => void;
}) {
	const { localDialog } = useLocalDialog();
	const dispatch = useAppDispatch();
	// table project start
	const [page, setPage] = useState(1);
	const [searchQuery, setSearchQuery] = useState<string | null>("");
	const [totalItems, setTotalItems] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [projectData, setProjectData] = useState<
		{
			id: string;
			[key: string]: string | number;
		}[]
	>([]);
	const [selectedProject, setSelectedProject] = useState<string[]>([]);
	const [accurateCustomerNumber, setAccurateCustomerNumber] = useState("");
	const [searchQ] = useDebounce(searchQuery, 500);
	const [debouncedCustomerNumber] = useDebounce(accurateCustomerNumber, 500);
	const [referanceSelectedProjectIds, setReferanceSelectedProjectIds] =
		useState<string[]>([]);

	const { isAccurateDisabled: accurateStatusDisabled } = useAppSelector((state) => state.setting);

	//accurate disable
	const isAccurateDisabled =
		process.env.REACT_APP_IS_ACCURATE_DISABLED === "true" || accurateStatusDisabled;

	const {
		data,
		isLoading: loadingGetProject,
		refetch: refetchProject
	} = useQuery<ApiProjectCustomers, ApiError>(
		["query-getprojects", page, rowsPerPage, searchQ],
		async () => {
			return CommonService.getAllProjectCustomer(
				page,
				rowsPerPage,
				searchQ
			);
		},
		{
			enabled: false,
			onSuccess: (res) => {
				//
				const projects = res.data.data;
				setTotalItems(res.data.totalItems as number);
				setCurrentPage(res.data.currentPage as number);
				setProjectData(() => {
					return projects.map((project) => {
						let cityName = "-";
						if (project?.BillingAddress) {
							cityName = project?.BillingAddress.Postal?.City
								.name as string;
						}
						return {
							id: project.id,
							nama: project.name,
							city: cityName,
							lastOrder: project.DeliveryOrders[0]
								? moment(
										project.DeliveryOrders[0]?.date
								  ).format("DD/MM/YYYY")
								: "-",
							pic: project.Pic?.name,
							sales: project.User?.fullName
						};
					});
				});
			}
		}
	);

	// const selectedIndex = useMemo(() => {
	// 	const indexes: number[] = [];
	// 	projectData.forEach((project, index) => {
	// 		if (selectedProject.includes(project.id)) {
	// 			indexes.push(index);
	// 		}
	// 	});
	// 	return indexes;
	// }, [selectedProject, projectData]);

	const [addedProjectIds] = useMemo(() => {
		const addedIds: string[] = [];
		// const deletedIds: string[] = [];
		selectedProject.forEach((id) => {
			if (!referanceSelectedProjectIds.includes(id)) {
				addedIds.push(id);
			}
		});
		// referanceSelectedProjectIds.forEach((id) => {
		// 	if (!selectedProject.includes(id)) {
		// 		deletedIds.push(id);
		// 	}
		// });
		return [addedIds];
	}, [selectedProject, referanceSelectedProjectIds]);

	//table project end
	const [text, setText] = useState<string>("");
	const [searhcAddressQ] = useDebounce(text, 500);
	const { data: places } = useQuery<ApiPlaces, ApiError>(
		["query-places", searhcAddressQ],
		async () => {
			return CommonService.getPlaces(searhcAddressQ as string);
		},
		{
			enabled: searhcAddressQ.length ? true : false
		}
	);

	const optionPlaces = React.useMemo(() => {
		if (places?.result.length) {
			const data = places.result.map((el) => {
				// console.log(el, "element");

				return {
					...el,
					valueStr: el.place_id,
					labelStr: el.description
				};
			});

			return data;
		}

		return [];
	}, [places]);

	const { mutateAsync: createPic } = useMutation<
		ApiCreatePicResponse,
		ApiError,
		PayloadCreatePIC,
		ApiError
	>(async (data: PayloadCreatePIC) => {
		const res = await CommonService.createPIC(data);
		return res;
	});

	const { mutateAsync: createAddress } = useMutation<
		ApiResponseCreateAddress,
		ApiError,
		PayloadCreateAddress,
		ApiError
	>(async (data: PayloadCreateAddress) => {
		const res = await CommonService.createAddress(data);
		return res;
	});

	const { mutateAsync: createCustomer } = useMutation<
		ApiResponseCustomer,
		ApiError,
		PayloadCustomer,
		ApiError
	>(
		async (data: PayloadCustomer) => {
			const res = await CommonService.createCustomer(data);
			return res;
		},
		{
			// onSuccess: (res) => {
			// 	// localDialog.onClose();
			// 	// navigate("/database/customers");
			// },
			onError: (err) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const [searchCustomerName, setSearchCustomerName] = useState<string>("");
	const [searchQName] = useDebounce(searchCustomerName, 500);

	const { isLoading, data: customerList } = useQuery<ApiCustomers, ApiError>(
		["query-customers", page, searchQName],
		async () => {
			return await CommonService.getAllCustomer(page, 10, {
				search: searchQName
			});
		},
		{
			enabled: searchQName.length >= 3,
			// onSuccess: (res) => {

			// },
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		}
	}, [isOpen]);

	const labelStyle = {
		marginTop: "1em"
	};
	//AccurateEmployee
	const { data: paymentTerms, isLoading: paymentTermsLoading } = useQuery<
		ApiResponsePlain<PaymentTerm[]>,
		ApiError
	>(
		["query-accurate-payment-term"],
		async () => {
			return AccurateService.getAccuratePaymentTermApi({});
		},
		{
			enabled: true
		}
	);
	const { data: accurateEmployee } = useQuery<
		ApiResponsePlain<AccurateResponse<AccurateEmployee[]>>,
		ApiError
	>(
		["query-accurate-employee"],
		async () => {
			return AccurateService.getAccurateEmployeeApi({});
		},
		{
			enabled: true
		}
	);
	const { data: customerAccurate } = useQuery<
		ApiResponsePlain<CustomerAccurate[]>,
		ApiError
	>(
		["query-accurate-customer", debouncedCustomerNumber],
		async () => {
			return AccurateService.getAccurateCustomerApi({
				size: 1,
				keywords: debouncedCustomerNumber
			});
		},
		{
			enabled: !!debouncedCustomerNumber
		}
	);

	const paymentTermOptions = React.useMemo(() => {
		if (paymentTerms?.data) {
			return paymentTerms?.data.map((el) => {
				return {
					valueStr: el.name,
					labelStr:
						el.name +
						(el.dueDescription ? `- (${el.dueDescription})` : "")
				};
			});
		}
		return [];
	}, [paymentTerms]);

	const employeeOptions = React.useMemo(() => {
		if (
			accurateEmployee?.data?.d &&
			Array.isArray(accurateEmployee?.data?.d)
		) {
			return accurateEmployee?.data?.d?.map((el) => {
				return {
					valueStr: el.number,
					labelStr: el.name
				};
			});
		}
		return [];
	}, [accurateEmployee]);

	return (
		<LocalDialog
			isOpen={localDialog.isOpen}
			backdrop={true}
			header="Buat Pelanggan Baru"
			onClose={() => {
				localDialog.onClose();
				setTimeout(() => {
					setIsOpen(false);
				}, 500);
			}}
			rightBtnTitle="Edit"
			leftBtnTitle="Batalkan"
		>
			<Formik
				initialValues={{
					displayName: "",
					// accurateNumber: "",
					type: "",
					nik: "",
					npwp: "",
					name: "",
					position: "",
					phone: "",
					email: "",
					address: {
						labelStr: "",
						valueStr: ""
					},
					mobilizationFee: {
						labelStr: "",
						valueStr: ""
					},
					mobilizationOption: {
						labelStr: "",
						valueStr: ""
					},
					paymentTerm: {"valueStr":"Cash Before Delivery","labelStr":"Cash Before Delivery"} as OptionLine
						| OptionLine
						| undefined,
					customerTaxType: taxType[0] as OptionLine | undefined,
					documentCodeName: documentType[0] as OptionLine | undefined,
					salesmanNumber: null as OptionLine | null,
					leadSource: null as OptionLine | null,
					description: "",
					customerLimitAge: false, // customerLimitAge value
					customerLimitAgeValue: "", // customerLimitAgeValue value
					customerLimitAmount: false, // customerLimitAmount value
					customerLimitAmountValue: "",
					customerDeveloper: {
						labelStr: "",
						valueStr: ""
					}
				}}
				validationSchema={
					isAccurateDisabled
						? CreateCustomerSchemaNoAccurate
						: CreateCustomerSchema
				}
				onSubmit={async (values, { setSubmitting }) => {
					// same shape as initial values
					setSubmitting(true);
					// setTimeout(() => {
					// console.log(values, "onSubmitvalue");
					// 	setSubmitting(false);
					// }, 2000);
					// setIsLoading(true);
					// console.log(values, "values initial");

					try {
						// let placeResp;
						// if (values.address.valueStr !== "undefined") {
						const placeResp = await CommonService.getPlaceDetail(
							values.address.valueStr as string
						);
						// }

						const addressResp = await createAddress({
							line1: values.address.labelStr,
							// id: billingAddress.id,
							postalId: placeResp?.result?.PostalId,
							line2: values.description,
							lat: placeResp?.result?.lat,
							lon: placeResp?.result?.lon
						});
						// // }
						const picResponse = await createPic({
							name: values.name,
							position: values.position,
							type: "PROJECT",
							phone: values.phone,
							email: values.email
						});
						const picId = picResponse.data.id as string;
						const billingAddressId = addressResp.data.id as string;
						const projectIds = addedProjectIds;
						const res = await createCustomer({
							picId,
							billingAddressId,
							projectIds,
							displayName: values.displayName,
							type: values.type as "COMPANY" | "INDIVIDU",
							npwp: values.npwp.match(/\d+/g)?.join(""),
							nik: values.nik.match(/\d+/g)?.join(""),
							accurateData: {
								salesmanNumber: values.salesmanNumber?.valueStr,
								// customerNo: values.accurateNumber,
								termName: values.paymentTerm?.valueStr,
								documentCode: values.documentCodeName?.valueStr,
								customerTaxType:
									values.customerTaxType?.valueStr,
								customerLimitAge: values.customerLimitAge,
								customerLimitAgeValue:
									typeof values.customerLimitAgeValue ===
									"number"
										? values.customerLimitAgeValue
										: undefined,
								customerLimitAmount: values.customerLimitAmount,
								customerLimitAmountValue: parseFloat(
									values.customerLimitAmountValue
										.replace(",00", "")
										.replace(/\D+/g, "")
								),
								charField1: values.leadSource?.valueStr,
								charField2: values.mobilizationFee.valueStr,
								charField3: values.mobilizationOption.valueStr,
								charField4: values.customerDeveloper?.valueStr
							}
						});
						setIsOpen(false);
						dispatch(
							openAlert({
								body: res.message,
								color: "success"
							})
						);
						// await updateCustomer({
						// 	...values,
						// 	type: values.type as "COMPANY" | "INDIVIDU"
						// });

						// setSubmitting(false);
						refetch();
					} catch (error) {
						console.log(error, "error create customer");

						dispatch(
							openAlert({
								body: "Gagal menambah pelanggan baru",
								color: "danger"
							})
						);
					}
				}}
			>
				{({
					errors,
					handleChange,
					isSubmitting,
					values,
					setFieldValue
				}) => {
					return (
						<Form>
							<FormGroup>
								<Label htmlFor="displayName" style={labelStyle}>
									Nama Pelanggan{" "}
									<span className="text-danger ml-1">*</span>
								</Label>

								<Input
									name="displayName"
									invalid={!!errors.displayName}
									onChange={(e) => {
										handleChange(e);
										setSearchCustomerName(e.target.value);
									}}
									//setSearchCustomerName
									value={values.displayName}
									placeholder="Nama Pelanggan"
									autoComplete="off"
								/>
								<p style={{ color: "red" }}>
									Mohon untuk tidak input nama pelanggan
									menggunakan singkatan!
								</p>
								<b
									style={{
										color: "red"
									}}
								>
									{customerList?.data.data.length
										? ` Di
										temukan ${customerList?.data.data.length} nama pelanggan yang mirip dengan
												"${searchQName}"`
										: ""}
								</b>
								{customerList?.data?.data?.length ? (
									<div>
										<div>
											{customerList?.data?.data?.map(
												(cust) => {
													return (
														<div key={cust.id}>
															<NavLink
																to={`${location.pathname}/${cust.id}`}
																className="d-flex align-items-center"
															>
																<BoxArrowUpRight />
																<div
																	style={{
																		marginLeft:
																			"0.5em"
																	}}
																>
																	<StatusChip
																		value={
																			cust.type
																		}
																	/>
																</div>
																<p
																	style={{
																		marginBottom:
																			"0",
																		marginLeft:
																			"0.5em"
																	}}
																>
																	{
																		cust.displayName
																	}
																</p>
															</NavLink>
															<div className="d-flex"></div>
															<div className="d-flex">
																<label
																	style={{
																		marginBottom:
																			"0",
																		opacity:
																			"0.7",
																		fontSize:
																			"14px"
																	}}
																>
																	NPWP:{" "}
																</label>
																<p
																	style={{
																		marginBottom:
																			"0",
																		fontSize:
																			"14px",
																		fontWeight:
																			"bold",
																		marginLeft:
																			"0.5em"
																	}}
																>
																	{cust.npwp ||
																		"-"}
																</p>
															</div>
															<div className="d-flex">
																<label
																	style={{
																		marginBottom:
																			"0",
																		opacity:
																			"0.7",
																		fontSize:
																			"14px"
																	}}
																>
																	NIK:{" "}
																</label>
																<p
																	style={{
																		marginBottom:
																			"0",
																		fontSize:
																			"14px",
																		fontWeight:
																			"bold",
																		marginLeft:
																			"0.5em"
																	}}
																>
																	{cust.nik ||
																		"-"}
																</p>
															</div>
														</div>
													);
												}
											)}
										</div>
									</div>
								) : null}
								<FormFeedback invalid={!!errors.displayName}>
									{errors.displayName}
								</FormFeedback>

								<div className="input-style">
									<Label>
										Tipe Pelanggan
										<span className="text-danger ml-1">
											*
										</span>
									</Label>
									<Field
										component="select"
										name="type"
										onChange={(
											e: ChangeEvent<HTMLInputElement>
										) => {
											handleChange(e);
											// const restOfNumber =
											// 	values.accurateNumber.substring(
											// 		3
											// 	);
											// if (e.target.value === "COMPANY") {
											// 	setAccurateCustomerNumber(
											// 		"A02"
											// 	);
											// 	setFieldValue(
											// 		"accurateNumber",
											// 		"A02" + restOfNumber
											// 	);
											// } else if (
											// 	e.target.value === "INDIVIDU"
											// ) {
											// 	setAccurateCustomerNumber(
											// 		"B02"
											// 	);
											// 	setFieldValue(
											// 		"accurateNumber",
											// 		"B02" + restOfNumber
											// 	);
											// }
										}}
										className="form-control"
										multiple={false}
										value={values.type}
									>
										<option disabled value={""}>
											{" "}
											-- pilih tipe pelanggan --{" "}
										</option>
										<option value="COMPANY">
											PERUSAHAAN
										</option>
										<option value="INDIVIDU">
											INDIVIDU
										</option>
									</Field>
									<ErrorMessage
										name={"type"}
										component="div"
										className="error-msg"
									/>
								</div>

								{/* <div className="input-style">
									<Label
										htmlFor="displayName"
										style={labelStyle}
									>
										Nomor Accurate Pelanggan
									</Label>
									<Input
										name="accurateNumber"
										invalid={!!errors.accurateNumber}
										onChange={(e) => {
											handleChange(e);
										}}
										//setSearchCustomerName
										value={values.accurateNumber}
										placeholder="Nomor Accurate Pelanggan"
										autoComplete="off"
									/>
									<p
										style={{
											fontSize: "13px",
											marginBottom: "0"
										}}
									>
										{customerAccurate?.data[0]?.customerNo
											? `Nomor Accurate Terakhir : ${customerAccurate?.data[0]?.customerNo}`
											: null}
									</p>
								</div> */}
								{/* <FormFeedback invalid={!!errors.accurateNumber}>
									{errors.accurateNumber}
								</FormFeedback> */}
								<Label style={labelStyle}>
									NIK
									{values.type === "INDIVIDU" ? (
										<span className="text-danger ml-1">
											*
										</span>
									) : null}
								</Label>
								<InputMask
									mask="9999 9999 9999 9999 9999"
									maskChar=""
									name="nik"
									placeholder={`NIK Pelanggan ${
										!values.type
											? " - Pilih tipe pelanggan terlebih dahulu"
											: ""
									}`}
									onChange={handleChange}
									value={values.nik}
									className="form-control"
									disabled={
										values.type === "COMPANY" ||
										!values.type
									}
									autoComplete="off"
								/>
								{/* <Input
										name="nik"
										invalid={!!errors.nik}
										onChange={handleChange}
										value={values.nik}
										placeholder="NIK Pelanggan"
									/> */}

								{/* <FormFeedback invalid={!!errors.nik}>
									{errors.nik}
								</FormFeedback> */}
								<ErrorMessage
									name={"nik"}
									component="div"
									className="error-msg"
								/>
								<Label style={labelStyle}>
									NPWP{" "}
									{values.type === "COMPANY" ? (
										<span className="text-danger ml-1">
											*
										</span>
									) : null}
								</Label>
								<InputMask
									mask="99.999.999.9-999.9"
									maskChar=""
									placeholder={`NPWP Pelanggan ${
										!values.type
											? " - Pilih tipe pelanggan terlebih dahulu"
											: ""
									}`}
									onChange={handleChange}
									value={values.npwp}
									name="npwp"
									className="form-control"
									disabled={!values.type}
									autoComplete="off"
								/>
								<ErrorMessage
									name={"npwp"}
									component="div"
									className="error-msg"
								/>
								{/* <FormFeedback invalid={!!errors.npwp}>
									{errors.npwp}
								</FormFeedback> */}
							</FormGroup>
							<FormGroup>
								<Label style={labelStyle}>Sumber Lead</Label>
								<Select
									name="leadSource"
									value={values.leadSource}
									options={sourcLeadOptions}
									getOptionLabel={(option) => {
										if (option) {
											return option.labelStr;
										}
										return "";
									}}
									getOptionValue={(option) => {
										if (option) {
											return option.valueStr as string;
										}
										return "";
									}}
									onChange={(change) => {
										if (change) {
											setFieldValue("leadSource", {
												labelStr: change.labelStr,
												valueStr: change.valueStr
											});
										}
									}}
								/>
								<ErrorMessage
									name={"leadSource.labelStr"}
									component="div"
									className="error-msg"
								/>
							</FormGroup>
							{/* Developer dropdown */}
							<Label style={labelStyle}>
								Developer
								<span className="text-danger ml-1">*</span>
							</Label>
							<Select
								name="customerDeveloper"
								value={values.customerDeveloper}
								options={Developers}
								getOptionLabel={(option) =>
									option.labelStr as string
								}
								getOptionValue={(option) =>
									option.valueStr as string
								}
								onChange={(change) => {
									if (change) {
										setFieldValue("customerDeveloper", {
											labelStr: change.labelStr,
											valueStr: change.valueStr
										});
									}
								}}
							/>
							<ErrorMessage
								name={"customerDeveloper.labelStr"}
								component="div"
								className="error-msg"
							/>
							{isAccurateDisabled ? null : (
								<div>
									<Label style={labelStyle}>
										Dikenakan Biaya Mobilisasi?{" "}
										<span className="text-danger ml-1">
											*
										</span>
									</Label>
									<Select
										name="mobilizationFee"
										value={values.mobilizationFee}
										options={mobilizationFee}
										getOptionLabel={(option) =>
											option.labelStr as string
										}
										getOptionValue={(option) =>
											option.valueStr as string
										}
										onChange={(change) => {
											if (change) {
												setFieldValue(
													"mobilizationFee",
													{
														labelStr:
															change.labelStr,
														valueStr:
															change.valueStr
													}
												);
											}
										}}
									/>
									<ErrorMessage
										name={"mobilizationFee.labelStr"}
										component="div"
										className="error-msg"
									/>
								</div>
							)}
							{isAccurateDisabled ? null : (
								<div>
									<Label style={labelStyle}>
										Syarat Mobilisasi{" "}
										<span className="text-danger ml-1">
											*
										</span>
									</Label>
									<Select
										name="mobilizationOption"
										value={values.mobilizationOption}
										options={mobilizationOptions}
										getOptionLabel={(option) =>
											option.labelStr as string
										}
										getOptionValue={(option) =>
											option.valueStr as string
										}
										onChange={(change) => {
											if (change) {
												setFieldValue(
													"mobilizationOption",
													{
														labelStr:
															change.labelStr,
														valueStr:
															change.valueStr
													}
												);
											}
										}}
									/>
									<ErrorMessage
										name={"mobilizationOption.labelStr"}
										component="div"
										className="error-msg"
									/>
								</div>
							)}
							{isAccurateDisabled ? null : (
								<div>
									<FormGroup>
										<Label style={labelStyle}>
											Pilih Syarat Pembayaran{" "}
											<span className="text-danger ml-1">
												*
											</span>
										</Label>
										{paymentTermOptions.length ? (
											<Select
												name="paymentTerm"
												value={values.paymentTerm}
												options={paymentTermOptions}
												defaultValue={
													paymentTermOptions[0]
												}
												getOptionLabel={(option) =>
													option.labelStr as string
												}
												getOptionValue={(option) =>
													option.valueStr as string
												}
												onChange={(change) => {
													if (change) {
														setFieldValue(
															"paymentTerm",
															{
																labelStr:
																	change.labelStr,
																valueStr:
																	change.valueStr
															}
														);
													}
												}}
											/>
										) : null}

										<ErrorMessage
											name={"paymentTerm.labelStr"}
											component="div"
											className="error-msg"
										/>
									</FormGroup>
								</div>
							)}
							<FormGroup>
								<Label style={labelStyle}>
									Jenis Dokumen{" "}
									<span className="text-danger ml-1">*</span>
								</Label>
								<Select
									name="documentCodeName"
									value={values.documentCodeName}
									options={documentType}
									getOptionLabel={(option) =>
										option.labelStr as string
									}
									getOptionValue={(option) =>
										option.valueStr as string
									}
									onChange={(change) => {
										if (change) {
											setFieldValue("documentCodeName", {
												labelStr: change.labelStr,
												valueStr: change.valueStr
											});
										}
									}}
								/>
								<ErrorMessage
									name={"documentCodeName.labelStr"}
									component="div"
									className="error-msg"
								/>
							</FormGroup>
							<FormGroup>
								<Label style={labelStyle}>
									Jenis Transaksi{" "}
									<span className="text-danger ml-1">*</span>
								</Label>
								<Select
									name="customerTaxType"
									value={values.customerTaxType}
									options={taxType}
									getOptionLabel={(option) =>
										option.labelStr as string
									}
									getOptionValue={(option) =>
										option.valueStr as string
									}
									onChange={(change) => {
										if (change) {
											setFieldValue("customerTaxType", {
												labelStr: change.labelStr,
												valueStr: change.valueStr
											});
										}
									}}
								/>
								<ErrorMessage
									name={"customerTaxType.labelStr"}
									component="div"
									className="error-msg"
								/>
							</FormGroup>
							{isAccurateDisabled ? null : (
								<div>
									<FormGroup>
										<Label style={labelStyle}>
											Default Penjual (Accurate)
										</Label>
										<Select
											name="salesmanNumber"
											value={values.salesmanNumber}
											options={employeeOptions}
											getOptionLabel={(option) => {
												if (option) {
													return (
														option.labelStr +
														` - ${option.valueStr}`
													);
												}
												return "";
											}}
											getOptionValue={(option) => {
												if (option) {
													return option.valueStr as string;
												}
												return "";
											}}
											onChange={(change) => {
												if (change) {
													setFieldValue(
														"salesmanNumber",
														{
															labelStr:
																change.labelStr,
															valueStr:
																change.valueStr
														}
													);
												}
											}}
										/>
										<ErrorMessage
											name={"salesmanNumber.labelStr"}
											component="div"
											className="error-msg"
										/>
									</FormGroup>
								</div>
							)}
							<FormGroup>
								<Label style={labelStyle}>
									Pembatasan Piutang Pelanggan
								</Label>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										gap: "1em",
										justifyContent: "space-between",
										marginBottom: "1em"
									}}
								>
									<div
										className="d-flex"
										style={{ gap: "1em" }}
									>
										<input
											type="checkbox"
											name="customerLimitAge"
											id="customerLimitAge"
											checked={values.customerLimitAge}
											onChange={handleChange}
										/>
										<Label htmlFor="customerLimitAge">
											Jika ada faktur berumur lebih dari
										</Label>
									</div>
									<div
										className="d-flex"
										style={{
											width: "35%",
											gap: "1em",
											alignItems: "center"
										}}
									>
										{values.customerLimitAge ? (
											<Input
												type="number"
												name="customerLimitAgeValue"
												onChange={handleChange}
												value={
													values.customerLimitAgeValue
												}
											/>
										) : (
											<div
												style={{
													backgroundColor:
														"lightgrey",
													textDecoration:
														"line-through"
												}}
												className="form-control"
											>
												{values.customerLimitAgeValue}
											</div>
										)}
										<p>Hari</p>
									</div>
								</div>

								<div
									style={{
										display: "flex",
										alignItems: "center",
										gap: "1em",
										justifyContent: "space-between"
									}}
								>
									<div
										className="d-flex"
										style={{ gap: "1em" }}
									>
										<input
											type="checkbox"
											name="customerLimitAmount"
											id="customerLimitAmount"
											checked={values.customerLimitAmount}
											onChange={handleChange}
										/>
										<Label htmlFor="customerLimitAmount">
											Jika total piutang & pesanan
											melebihi
										</Label>
									</div>
									{values.customerLimitAmount ? (
										<>
											{/* <Input
												type="number"
												style={{
													width: "35%"
												}}
												name="customerLimitAmountValue"
												onChange={handleChange}
												value={
													values.customerLimitAmountValue
												}
											/> */}
											<InputNumberFormat
												locales="id-ID"
												format="currency"
												currency="IDR"
												name="customerLimitAmountValue"
												onChange={handleChange}
												value={
													values.customerLimitAmountValue
												}
												className="form-control"
												style={{
													width: "35%"
												}}
											/>
										</>
									) : (
										// <InputNumberFormat
										// 	locales="de-DE"
										// 	format="currency"
										// 	currency="EUR"
										// 	onChange={(event) =>
										// 		console.log(event.target.value)
										// 	} // "123.456,78 €"
										// />
										// <InputNumberFormat
										// 	locales="id-ID"
										// 	format="currency"
										// 	currency="IDR"
										// 	name="customerLimitAmountValue"
										// 	onChange={handleChange}
										// 	value={
										// 		values.customerLimitAmountValue
										// 	}
										// />
										<div
											style={{
												width: "35%",
												backgroundColor: "lightgrey",
												textDecoration: "line-through"
											}}
											className="form-control"
										>
											{values.customerLimitAmountValue}
										</div>
									)}
								</div>
							</FormGroup>
							<div className="dropdown-divider"></div>
							PIC
							<FormGroup>
								<Label style={labelStyle}>
									Nama{" "}
									<span className="text-danger ml-1">*</span>
								</Label>
								<Input
									name="name"
									invalid={!!errors.name}
									onChange={handleChange}
									value={values.name}
									placeholder="Nama PIC"
									autoComplete="off"
								/>

								<FormFeedback invalid={!!errors.name}>
									{errors.name}
								</FormFeedback>
								<Label style={labelStyle}>
									Posisi{" "}
									<span className="text-danger ml-1">*</span>
								</Label>
								<Input
									name="position"
									invalid={!!errors.position}
									onChange={handleChange}
									value={values.position}
									placeholder="Posisi PIC"
								/>

								<FormFeedback invalid={!!errors.position}>
									{errors.position}
								</FormFeedback>
								<Label style={labelStyle}>
									No. Telp{" "}
									<span className="text-danger ml-1">*</span>
								</Label>
								<Input
									name="phone"
									invalid={!!errors.phone}
									onChange={handleChange}
									value={values.phone}
									placeholder="No. Telepon PIC"
									autoComplete="off"
								/>

								<FormFeedback invalid={!!errors.phone}>
									{errors.phone}
								</FormFeedback>
								<Label style={labelStyle}>Email</Label>
								<Input
									name="email"
									invalid={!!errors.email}
									onChange={handleChange}
									value={values.email}
									placeholder="Email PIC"
									autoComplete="off"
								/>

								<FormFeedback invalid={!!errors.email}>
									{errors.email}
								</FormFeedback>
							</FormGroup>
							<div className="dropdown-divider"></div>
							<FormGroup>
								<Label>
									{" "}
									Alamat Pembayaran{" "}
									<span className="text-danger ml-1">
										*
									</span>{" "}
								</Label>
								<Select
									onInputChange={(text) => {
										setText(text);
									}}
									onChange={(change) => {
										// console.log(change, "change");
										if (change) {
											values.address.labelStr =
												change?.labelStr as string;
											values.address.valueStr =
												change.valueStr as string;
										}
									}}
									// onChange={handleChange}
									options={optionPlaces}
									getOptionLabel={(option: options) =>
										option.labelStr as string
									}
									getOptionValue={(option: options) =>
										option.valueStr as string
									}
									name="address"
									placeholder="Tulis alamat"
									value={values.address}
								/>
								<ErrorMessage
									name={"address.labelStr"}
									component="div"
									className="error-msg"
								/>
								<Label style={labelStyle}>
									{" "}
									Alamat Pembayaran Lengkap{" "}
								</Label>
								<Input
									name="description"
									invalid={!!errors.description}
									onChange={handleChange}
									value={values.description}
									placeholder="Detail Alamat Pembayaran"
								/>

								{/* <Field
									name="description"
									as="textarea"
									className="form-textarea"
								/> */}
								<FormFeedback invalid={!!errors.description}>
									{errors.description}
								</FormFeedback>
							</FormGroup>
							{/* <div className="dropdown-divider"></div> */}
							{/* <FormGroup>
									<MTable
										currentPage={currentPage}
										columns={muiColumns}
										tableData={projectData}
										tableTitle="Daftar proyek"
										rowsPerPage={rowsPerPage}
										rowsPerPageOptions={[5, 10, 15, 100]}
										setPage={(page: number) => {
											setPage(page);
										}}
										setRowsPerPage={(rows: number) => {
											setRowsPerPage(rows);
										}}
										totalItems={totalItems}
										onSearchChange={(
											text: string | null
										) => {
											setSearchQuery(text);
										}}
										selectedRows={selectedIndex}
										setSelectedRows={(index: number) => {
											setSelectedProject((curr) => {
												const newSelectedIds = [
													...curr
												];
												newSelectedIds.push(
													projectData[index]
														?.id as string
												);

												return newSelectedIds;
											});
										}}
										setDeselectRows={(index: number) => {
											setSelectedProject((curr) => {
												const newSelectedIds = [
													...curr
												];

												return newSelectedIds.filter(
													(id) =>
														id !==
														projectData[index]?.id
												);
											});
										}}
										searchPlaceholder="Cari nama proyek/ nama sales"
									/>
								</FormGroup> */}
							{/* <div className="dropdown-divider"></div> */}
							<div className="modal-custom-footer">
								<LoadingButton
									color="error"
									onClick={() => {
										localDialog.onClose();
										setTimeout(() => {
											setIsOpen(false);
										}, 500);
									}}
									loading={isSubmitting}
									disabled={isSubmitting}
								>
									<span
										style={{
											textTransform: "capitalize"
										}}
									>
										Batal
									</span>
								</LoadingButton>
								<LoadingButton
									color="error"
									variant="contained"
									type="submit"
									loading={isSubmitting}
									disabled={isSubmitting}
									sx={{
										backgroundColor: "red",
										color: "white"
									}}
								>
									<span
										style={{
											textTransform: "capitalize"
										}}
									>
										Buat
									</span>
								</LoadingButton>
							</div>
						</Form>
					);
				}}
			</Formik>
		</LocalDialog>
	);
}
