import React, { useMemo, useState } from "react";
import { DashboardService } from "api/axios";
import { Mtd } from "components";
import { MonthStartEnd } from "helper/WeekNumberToDateRange";
import moment from "moment";
import { useQuery } from "react-query";

const thisMonth = moment().endOf("month").format("YYYY-MM-DD");
const { startDate: initialMonthStartDate, endDate: initialMonthEndDate } =
	MonthStartEnd(thisMonth, 0);
export default function FlowMtd() {
	const [salesFunnel, setSalesFunnel] = useState<SalesFunnelResponse>(
		{} as SalesFunnelResponse
	);

	useQuery(
		["sales-dashboard"],
		async () => {
			return await DashboardService.getSalesFunnel({
				startDate: initialMonthStartDate,
				endDate: initialMonthEndDate,
				type: "monthly"
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				setSalesFunnel(res.data.data);
			}
		}
	);

	const [visitation, sph, po, so] = useMemo(() => {
		let visitation = "-";
		let sph = "-";
		let po = "-";
		let so = "-";

		if (salesFunnel.visitationCount) {
			let count = 0;
			salesFunnel.visitationCount.forEach((data) => {
				count += +data.count;
			});
			visitation = count.toString();
		}
		if (salesFunnel.sphCount) {
			let count = 0;
			salesFunnel.sphCount.forEach((data) => {
				count += +data.count;
			});
			sph = count.toString();
		}
		if (salesFunnel.poCount) {
			let count = 0;
			salesFunnel.poCount.forEach((data) => {
				count += +data.count;
			});
			po = count.toString();
		}
		if (salesFunnel.soCount) {
			let count = 0;
			salesFunnel.soCount.forEach((data) => {
				count += +data.count;
			});
			so = count.toString();
		}

		return [visitation, sph, po, so];
	}, [salesFunnel]);

	return (
		<>
			<Mtd
				value={visitation}
				title={`Kunjungan, ${moment(initialMonthStartDate).format(
					"MMM-YYYY"
				)} `}
			/>
			<Mtd
				value={sph}
				title={`SPH, ${moment(initialMonthStartDate).format(
					"MMM-YYYY"
				)} `}
			/>
			<Mtd
				value={po}
				title={`PO, ${moment(initialMonthStartDate).format(
					"MMM-YYYY"
				)} `}
			/>
			<Mtd
				value={so}
				title={`SO, ${moment(initialMonthStartDate).format(
					"MMM-YYYY"
				)} `}
			/>
		</>
	);
}
