import { LoadingButton } from "@mui/lab";
import { CommonService } from "api/axios";
import { LocalDialog } from "components";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { Button, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { openAlert } from "store/features/alert/AlertSlice";
import { useAppDispatch } from "store/hooks";
import * as Yup from "yup";

const labelStyle = {
	marginTop: "1em"
};

const dispatchSchema = Yup.object().shape({
	name: Yup.string().required("Nama Dispatcher harus diisi")
});

export default function CreateDispatcher({ refetch }: { refetch: () => void }) {
	const dispatch = useAppDispatch();
	const [isCreateDispatcherOpen, setIsCreateDispatcherOpen] = useState(false);
	const { mutateAsync: createDispatcher } = useMutation(
		(payload: { name: string }) => {
			return CommonService.postDispatcher(payload);
		},
		{
			// onSuccess: (res) => {
			// 	// localDialog.onClose();
			// 	// navigate("/database/customers");
			// },
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);
	return (
		<div className="d-flex justify-content-end">
			<Button
				onClick={() => setIsCreateDispatcherOpen((prev) => !prev)}
				size="sm"
				color="success"
				className="confirm-button approve-button"
			>
				Buat Dispatcher Baru
			</Button>
			<LocalDialog
				isOpen={isCreateDispatcherOpen}
				onClose={() => setIsCreateDispatcherOpen((prev) => !prev)}
				header="Buat Dispatcher Baru"
				rightBtnTitle="Tambah"
				leftBtnTitle="Batalkan"
				backdrop={true}
			>
				<Formik
					initialValues={{
						name: ""
					}}
					validationSchema={dispatchSchema}
					onSubmit={async (values, { resetForm }) => {
						console.log("sbumit");
						try {
							const res = await createDispatcher(values);
							setIsCreateDispatcherOpen((prev) => !prev);
							resetForm();
							refetch();
							dispatch(
								openAlert({
									body: "Berhasil Menambahkan Dispatcher",
									color: "success"
								})
							);
						} catch (error) {
							console.log(error, "errorsubmitdispatcher");
							dispatch(
								openAlert({
									body: "Gagal menambah dispatcher baru",
									color: "danger"
								})
							);
						}
					}}
				>
					{({ handleChange, values, errors, isSubmitting }) => {
						return (
							<Form>
								<FormGroup>
									<Label htmlFor="name" style={labelStyle}>
										Nama Dispatcher
									</Label>
									<Input
										name="name"
										invalid={!!errors.name}
										onChange={handleChange}
										//setSearchCustomerName
										value={values.name}
										placeholder="Nama Dispatcher"
									/>
									<FormFeedback>{errors.name}</FormFeedback>
									<div className="dropdown-divider"></div>
								</FormGroup>
								<div className="modal-custom-footer">
									<LoadingButton
										color="error"
										onClick={() => {
											setIsCreateDispatcherOpen(false);
										}}
										loading={isSubmitting}
										disabled={isSubmitting}
									>
										<span
											style={{
												textTransform: "capitalize"
											}}
										>
											Batal
										</span>
									</LoadingButton>
									<LoadingButton
										color="error"
										variant="contained"
										type="submit"
										loading={isSubmitting}
										disabled={isSubmitting}
										sx={{
											backgroundColor: "red",
											color: "white"
										}}
									>
										<span
											style={{
												textTransform: "capitalize"
											}}
										>
											Buat
										</span>
									</LoadingButton>
								</div>
							</Form>
						);
					}}
				</Formik>
			</LocalDialog>
		</div>
	);
}
