import { Marker } from "react-map-gl";
import styles from "./index.module.scss";
import React, { useEffect, useState } from "react";

const MarkerTM = (props: any) => {
	const [deg, setDeg] = useState(props?.direction || 0);

	function rotate(newDeg = 0) {
		setDeg((deg: number) => {
			let aR;
			let rot = deg || 0;
			aR = rot % 360;
			if (aR < 0) { aR += 360; }
			if (aR < 180 && (newDeg > (aR + 180))) { rot -= 360; }
			if (aR >= 180 && (newDeg <= (aR - 180))) { rot += 360; }
			rot += (newDeg - aR);
			return rot;
		});
	}

	useEffect(() => {
		rotate(props?.direction);
	}, [props?.direction]);
	return (
		<Marker {...props}>
			<div className={[styles.iconContainer, props.className].join(" ")}>
				<div className={styles.overlay}
					style={{ filter: props.labelGrey ? "grayscale(100%)" : "" }}>
					{props?.label && (<div className={styles.textContainer}>
						<p>{props.label}</p>
					</div>)}
				</div>
				<svg
					className={styles.iconSvg}
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
					viewBox="0 0 80 80"
					style={{ transform: `rotate(${deg}deg)`, filter: props.iconGrey ? "grayscale(100%)" : "" }}
				>
					<defs>
						<clipPath id="b">
							<path d="M0 0h80v80H0z" />
						</clipPath>
						<filter
							id="a"
							width="100%"
							height="100%"
							x="0%"
							y="0%"
							filterUnits="objectBoundingBox"
						>
							<feColorMatrix
								in="SourceGraphic"
								values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
							/>
						</filter>
						<mask id="d">
							<g filter="url(#a)">
								<path
									d="M0 0h80v80H0z"
									style={{
										fill: "#000",
										fillOpacity: 0.976471,
										stroke: "none"
									}}
								/>
							</g>
						</mask>
						<g id="c" clipPath="url(#b)">
							<path
								d="M39.371 9.074a42.5 42.5 0 0 1 9.184 1.074 3.4 3.4 0 0 1 2 1.704c1.035.246 1.59.898 1.668 1.964a3.493 3.493 0 0 1-.48 2.48c.077.204.14.411.183.63.05 2.246.05 4.492 0 6.742-.07.422-.207.816-.406 1.184.097.316.183.64.257.964.051 1.48.051 2.961 0 4.442-.195 1.262-.902 2.086-2.109 2.484a554.03 554.03 0 0 0 1.96 10.926c0 .723.075 1.441.224 2.148.05 4.368.05 8.739 0 13.11a3.065 3.065 0 0 1-.743 1.629c-.277.226-.547.445-.812.668a37.423 37.423 0 0 1-.52 2.52c-.375 1.288-1.191 2.175-2.445 2.663a11.654 11.654 0 0 1-.516 2c-.437.633-1.043.992-1.816 1.074-.742.028-1.48.051-2.223.075-.707.847-1.62 1.304-2.742 1.37-1.078-.058-1.969-.491-2.664-1.296-.719-.024-1.434-.047-2.148-.074-1.051-.11-1.73-.664-2.04-1.664-.12-.496-.245-.989-.367-1.485-1.187-.394-1.976-1.183-2.37-2.37-.25-.915-.462-1.84-.63-2.778-.972-.477-1.55-1.254-1.742-2.332-.05-4.371-.05-8.742 0-13.11a228.645 228.645 0 0 1 2.258-13.113c-.715-.258-1.195-.75-1.441-1.48a13.144 13.144 0 0 1-.297-1.555c.047-.8.086-1.605.11-2.41a9.502 9.502 0 0 0-.15-1.852c.012-.125.036-.25.075-.37a2.857 2.857 0 0 1-.555-1.442c-.05-2.102-.05-4.2 0-6.297.063-.356.125-.688.184-1a3.194 3.194 0 0 1-.516-1.703 6.4 6.4 0 0 1 .332-1.817c.317-.562.797-.894 1.446-1A4.295 4.295 0 0 1 31.89 10a49.35 49.35 0 0 1 3.704-.668 44.023 44.023 0 0 1 3.777-.258Zm0 0"
								style={{
									stroke: "none",
									fillRule: "evenodd",
									fill: "#000",
									fillOpacity: 1
								}}
							/>
						</g>
					</defs>
					<use xlinkHref="#c" mask="url(#d)" />
					<path
						d="M38.852 11.074c-1.075.09-2.16.164-3.258.223a19.598 19.598 0 0 1 3.258-.223Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#3f3c28",
							fillOpacity: 1
						}}
					/>
					<path
						d="M38.852 11.074a44.956 44.956 0 0 1 8.89.778c.867.289 1.336.894 1.406 1.816a.168.168 0 0 0-.113.074c-.012-.46-.246-.62-.703-.484a.227.227 0 0 0-.074.187 53.31 53.31 0 0 0-2.664-.816c.254-.047.34-.195.258-.445a.82.82 0 0 0-.48-.293 16.9 16.9 0 0 1 2.37.406c.266.121.488.293.664.52.07.035.145.046.223.035-.149-.41-.445-.672-.887-.778a46.168 46.168 0 0 0-2.52-.48.281.281 0 0 0-.222.11c.102.07.2.132.297.187a5.061 5.061 0 0 0-1.113-.04c-.207.22-.18.403.074.555-.016.047-.055.07-.11.074a29.228 29.228 0 0 0-8.296 0c-.055-.003-.094-.027-.11-.074.242-.156.27-.34.074-.554a3.622 3.622 0 0 0-1.668.257.67.67 0 0 0 0 .446c.075.062.16.086.258.074-.879.234-1.758.504-2.629.816a.243.243 0 0 0-.109-.148.835.835 0 0 0-.594-.04 1.856 1.856 0 0 0-.11.485.168.168 0 0 0-.112-.074c.148-1.05.73-1.668 1.742-1.852a10.1 10.1 0 0 0 .48-.148c.223-.016.446-.043.668-.074.63-.09 1.246-.188 1.852-.297 1.097-.059 2.183-.133 3.258-.223Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#fdc71d",
							fillOpacity: 1
						}}
					/>
					<path
						d="M43.965 11.445c.16-.03.281.02.367.149a.37.37 0 0 1-.441 0c.023-.051.05-.102.074-.149Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#dfdac5",
							fillOpacity: 1
						}}
					/>
					<path
						d="M33.742 11.594a9.698 9.698 0 0 1-.668.074c.098-.078.258-.129.48-.148a.25.25 0 0 1 .188.074Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#847f66",
							fillOpacity: 1
						}}
					/>
					<path
						d="M45.371 11.89h-.074a3.694 3.694 0 0 1-.297-.187.281.281 0 0 1 .223-.11c.843.137 1.683.298 2.52.481.44.106.737.367.886.778a.365.365 0 0 1-.223-.036 1.738 1.738 0 0 0-.664-.52 16.9 16.9 0 0 0-2.37-.405Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#e1dbc8",
							fillOpacity: 1
						}}
					/>
					<path
						d="m35.742 12.406-1.336.223a.323.323 0 0 1-.258-.074.67.67 0 0 1 0-.446 3.622 3.622 0 0 1 1.668-.257c.196.214.168.398-.074.554Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#293605",
							fillOpacity: 1
						}}
					/>
					<path
						d="M45.297 11.89h.074a.82.82 0 0 1 .48.294c.083.25-.003.398-.257.445l-1.336-.223c-.254-.152-.281-.336-.074-.554a5.061 5.061 0 0 1 1.113.039Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#293606",
							fillOpacity: 1
						}}
					/>
					<path
						d="M31.668 13.297v.668c-.23.183-.48.183-.742 0-.043.008-.07.031-.074.07a1.957 1.957 0 0 0-.852 0c-.035.348-.05.692-.035 1.04h-.297a8.883 8.883 0 0 1 .074-1.407h1.11a.168.168 0 0 1 .113.074c.015-.168.05-.328.11-.484a.835.835 0 0 1 .593.039Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#2c380a",
							fillOpacity: 1
						}}
					/>
					<path
						d="M49.148 13.668a2.302 2.302 0 0 1 1.184.074v1.332A.473.473 0 0 1 50 15a7.055 7.055 0 0 1-.035-1.035 5.661 5.661 0 0 0-.817.07c-.265.07-.535.07-.816 0-.02-.2-.043-.394-.074-.59a.227.227 0 0 1 .074-.187c.457-.137.691.023.703.484a.168.168 0 0 1 .113-.074Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#313b0f",
							fillOpacity: 1
						}}
					/>
					<path
						d="M31.668 13.965c.059.18.098.379.11.59.066.043.14.07.222.074a39.173 39.173 0 0 1 7.965-.664 40.46 40.46 0 0 1 8.035.664c.063-.024.125-.047.184-.074a5.85 5.85 0 0 1 .148-.52c.281.07.55.07.816 0v.223c-.097.867-.097 1.73 0 2.594a82.6 82.6 0 0 0 .149 6.964 10.297 10.297 0 0 0-1.629-.074c-5.14-.027-10.277 0-15.41.074a4.332 4.332 0 0 0-1.555 0h-.074c.055-.07.129-.093.223-.074v-9.707c.003-.039.03-.062.074-.07.262.183.512.183.742 0Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#feca1f",
							fillOpacity: 1
						}}
					/>
					<path
						d="m44.258 12.406 1.336.223c.886.246 1.777.52 2.664.816.031.196.054.39.074.59a5.85 5.85 0 0 0-.148.52c-.059.027-.121.05-.184.074a40.46 40.46 0 0 0-8.035-.664c-2.68-.05-5.332.172-7.965.664a.445.445 0 0 1-.223-.074c-.011-.211-.05-.41-.109-.59v-.668c.05.031.09.078.11.148.87-.312 1.75-.582 2.628-.816l1.336-.223c.016.047.055.07.11.074a29.228 29.228 0 0 1 8.296 0c.055-.003.094-.027.11-.074Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#3e411f",
							fillOpacity: 1
						}}
					/>
					<path
						d="M43.816 13.52a2.59 2.59 0 0 1-.148.445 3.79 3.79 0 0 0-.965-.074.328.328 0 0 1 .074-.262 7.157 7.157 0 0 1 1.04-.11Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#ded1b3",
							fillOpacity: 1
						}}
					/>
					<path
						d="M44.332 13.965h-.664a2.59 2.59 0 0 0 .148-.445h.075a32.24 32.24 0 0 0 1.48-.223c.399.023.645.223.738.594-.59.054-1.18.082-1.777.074Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#ffca1f",
							fillOpacity: 1
						}}
					/>
					<path
						d="M46.852 13.148c.425.07.832.196 1.222.372.035.32.051.64.035.96a19.062 19.062 0 0 1-1.48-.222c.23-.395.184-.754-.149-1.074a.994.994 0 0 1 .372-.036Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#fec91e",
							fillOpacity: 1
						}}
					/>
					<path
						d="M33.223 13.148c2.37.32 4.742.543 7.109.668a47.218 47.218 0 0 0-8.441.664c-.028-.324 0-.644.074-.96.398-.2.816-.325 1.258-.372Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#dfd0af",
							fillOpacity: 1
						}}
					/>
					<path
						d="M44.184 12.629c.914.098 1.8.273 2.668.52a.994.994 0 0 0-.372.035c.333.32.38.68.149 1.074a48.607 48.607 0 0 0-2.297-.293c.598.008 1.188-.02 1.777-.074-.093-.371-.34-.57-.738-.594-.492.086-.988.16-1.48.223.054-.313.152-.61.293-.891Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#edac00",
							fillOpacity: 1
						}}
					/>
					<path
						d="M42.926 12.48a6.4 6.4 0 0 1 1.258.149c-.141.281-.239.578-.293.89h-.075a7.157 7.157 0 0 0-1.039.11.328.328 0 0 0-.074.262h-.445c.14-.454.3-.895.484-1.336a.215.215 0 0 1 .184-.075Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#ddd191",
							fillOpacity: 1
						}}
					/>
					<path
						d="M41.074 12.332a9.986 9.986 0 0 1 1.852.148.215.215 0 0 0-.184.075c-.183.441-.344.883-.484 1.336-.59-.047-1.184-.07-1.778-.075a9.566 9.566 0 0 1 .594-1.484Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#eeae02",
							fillOpacity: 1
						}}
					/>
					<path
						d="M41.074 12.332a9.566 9.566 0 0 0-.594 1.484h-.148a87.606 87.606 0 0 1-7.11-.668 26.019 26.019 0 0 1 7.852-.816Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#ddd191",
							fillOpacity: 1
						}}
					/>
					<path
						d="M30.852 14.258v2.594h-.668a7.585 7.585 0 0 0-.075-.48c.063-.677.098-1.352.114-2.04.199-.07.41-.094.629-.074Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#283504",
							fillOpacity: 1
						}}
					/>
					<path
						d="M49.148 14.258c.22-.02.43.004.63.074.038.816.05 1.629.038 2.445-.207.075-.43.098-.668.075a11.518 11.518 0 0 1 0-2.594Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#303b0e",
							fillOpacity: 1
						}}
					/>
					<path
						d="M34.11 15.148c.316-.03.453.118.41.446.046 2.246.046 4.492 0 6.738-.06.29-.22.363-.485.223-.086-2.418-.097-4.84-.035-7.258.055-.04.09-.09.11-.149ZM36.035 15.148c.203-.039.34.036.41.223.047 2.32.047 4.64 0 6.961-.074.293-.246.367-.52.223-.01-1.223-.023-2.446-.034-3.664 0-1.266.047-2.512.144-3.743ZM43.742 15.148a.341.341 0 0 1 .332.149c.063 2.418.051 4.84-.039 7.258-.18.113-.34.086-.48-.075-.047-2.394-.047-4.789 0-7.183a.807.807 0 0 0 .187-.149Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#ecac00",
							fillOpacity: 1
						}}
					/>
					<path
						d="M45.965 19.074a.578.578 0 0 0-.113-.445c-.102.207-.141.43-.11.668h.223c.086 1.047.097 2.11.035 3.183-.172.2-.348.2-.52 0-.046-2.394-.046-4.789 0-7.183.172-.2.348-.2.52 0 .063 1.27.05 2.531-.035 3.777Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#edac00",
							fillOpacity: 1
						}}
					/>
					<path
						d="M45.816 15.297a.16.16 0 0 1 .11.074c.011.492.023.988.039 1.48a8.5 8.5 0 0 1-.113 1.555 8.169 8.169 0 0 1-.11-1.554c0-.524.024-1.043.074-1.555Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#dbd5ba",
							fillOpacity: 1
						}}
					/>
					<path
						d="M38.926 15.965c-.043.113-.09.223-.149.332-.394.05-.789.05-1.183 0a.582.582 0 0 1-.149-.406c.157-.258.39-.36.703-.297l.48.035a.41.41 0 0 1 .298.336ZM42.555 15.89c-.086.403-.348.551-.778.442l-.554-.035a.582.582 0 0 1-.149-.406.383.383 0 0 1 .223-.262 3.3 3.3 0 0 1 .96 0c.138.05.235.137.298.262Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#000",
							fillOpacity: 1
						}}
					/>
					<path
						d="M37.445 15.89a.582.582 0 0 0 .149.407c.394.05.789.05 1.183 0 .059-.11.106-.219.149-.332.097.152.074.3-.074.441a4.118 4.118 0 0 1-1.332.04c-.16-.176-.184-.36-.075-.555Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#3c4319",
							fillOpacity: 1
						}}
					/>
					<path
						d="M41.074 15.89a.582.582 0 0 0 .149.407l.554.035c.43.11.692-.039.778-.441.11.187.082.359-.075.515a4.118 4.118 0 0 1-1.332.04c-.16-.176-.183-.36-.074-.555Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#3a4117",
							fillOpacity: 1
						}}
					/>
					<path
						d="M30.184 16.852h.668v6.89c-.094-.02-.168.004-.223.074a.936.936 0 0 1-.629-.148c-.05-2.25-.05-4.496 0-6.742a.212.212 0 0 1 .184-.074Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#ecab00",
							fillOpacity: 1
						}}
					/>
					<path
						d="M49.816 16.777c.07.035.133.082.184.149.05 2.246.05 4.492 0 6.742-.21.137-.445.184-.703.148-.149-2.304-.195-4.628-.149-6.964.239.023.461 0 .668-.075Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#edac00",
							fillOpacity: 1
						}}
					/>
					<path
						d="M37.52 17a71.629 71.629 0 0 1 4.59-.074v.668h-4.294V17h-.296Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#ffc113",
							fillOpacity: 1
						}}
					/>
					<path
						d="M49.371 16.926c.191-.02.379.004.555.074.062.422.05.84-.035 1.258a.25.25 0 0 1-.114-.074 8.08 8.08 0 0 1-.035-1.11c-.172.031-.293-.02-.37-.148Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#ded8c0",
							fillOpacity: 1
						}}
					/>
					<path
						d="M37.52 17h.296v.594h4.293a43.725 43.725 0 0 0 .075 3.035c.293-.055.476-.227.558-.52.078.328-.035.563-.336.707a84.924 84.924 0 0 1-4.886 0 .79.79 0 0 1-.297-.41c.187.168.386.18.593.04v-.298c-.199-.035-.394-.05-.593-.039v.223a16.355 16.355 0 0 1-.04-2.664c.036-.27.149-.492.337-.668Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#eaaa05",
							fillOpacity: 1
						}}
					/>
					<path
						d="M33.223 18.184a.704.704 0 0 1-.075.41c-.363.113-.73.14-1.113.074a.43.43 0 0 1-.219-.41c0-.11.047-.2.149-.258.394-.05.789-.05 1.183 0 .055.05.082.113.075.184ZM48.184 18.184c-.036.425-.293.597-.778.52-.398.1-.633-.048-.703-.446 0-.11.05-.2.149-.258.394-.05.789-.05 1.183 0 .067.05.117.113.149.184Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#000",
							fillOpacity: 1
						}}
					/>
					<path
						d="M49.742 18.555h.223a.762.762 0 0 1-.074.445.438.438 0 0 1-.149-.445Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#ded7bb",
							fillOpacity: 1
						}}
					/>
					<path
						d="M33.223 18.184c.18.148.195.32.035.52-.125.07-.262.12-.406.148a2.271 2.271 0 0 1-.961-.11c-.157-.152-.184-.312-.075-.484a.43.43 0 0 0 .22.41c.382.066.75.039 1.112-.074a.704.704 0 0 0 .075-.41Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#3e441b",
							fillOpacity: 1
						}}
					/>
					<path
						d="M48.184 18.184c.129.503-.082.726-.63.668-.261-.032-.519-.067-.777-.11-.156-.152-.18-.312-.074-.484.07.398.305.547.703.445.485.078.742-.094.778-.52Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#40461d",
							fillOpacity: 1
						}}
					/>
					<path
						d="M45.965 19.074v.223h-.223c-.031-.238.008-.461.11-.668a.578.578 0 0 1 .113.445Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#ded7bd",
							fillOpacity: 1
						}}
					/>
					<path
						d="M37.223 20.406v-.297c.199-.011.394.004.593.04v.296c-.207.14-.406.13-.593-.039Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#8a7641",
							fillOpacity: 1
						}}
					/>
					<path
						d="M32.258 23.816v.887c-.57-.02-1.137.004-1.703.074.05-.32.097-.64.148-.96a4.332 4.332 0 0 1 1.555 0Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#ded0ae",
							fillOpacity: 1
						}}
					/>
					<path
						d="M47.668 23.742V25.223c-2.25-.012-4.496 0-6.742.035-.137.031-.274.07-.406.113a1.171 1.171 0 0 0-.852-.113c-.145.36-.191.742-.148 1.148h-.075a1.43 1.43 0 0 1-.074-.59h-2c.012-.3 0-.593-.039-.89a.489.489 0 0 0-.406-.297c-.926.004-1.864.004-2.817 0a.425.425 0 0 0-.406.223c-.023.347-.05.691-.074 1.039a1.082 1.082 0 0 0-.297.367 87.173 87.173 0 0 0 .04 6.148c-.36.168-.68.39-.966.668-.047-.668-.097-1.332-.148-2V31a85.058 85.058 0 0 0 0-4.89v-.075a3.19 3.19 0 0 0 0-1.332v-.887c5.133-.074 10.27-.101 15.41-.074Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#283503",
							fillOpacity: 1
						}}
					/>
					<path
						d="M47.668 23.742c.547-.02 1.09.008 1.629.074.086.297.086.594 0 .887h-1.629v-.96Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#ded0ac",
							fillOpacity: 1
						}}
					/>
					<path
						d="M34.11 24.629V31c1.015.012 2.027 0 3.038-.035a.352.352 0 0 0 .149-.113c.074-1.676.098-3.356.074-5.036h2a1.43 1.43 0 0 0 .074.59c-.476-.129-.675.047-.593.52a.674.674 0 0 0-.63.445c-.05.445-.05.887 0 1.332.13.266.34.442.63.52-.29.41-.563.828-.817 1.257v.891a15.18 15.18 0 0 0-3.555.738c-.37.102-.738.2-1.109.297a87.173 87.173 0 0 1-.039-6.148c.07-.145.172-.266.297-.367.023-.348.05-.692.074-1.04a.425.425 0 0 1 .406-.222Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#3f3f22",
							fillOpacity: 1
						}}
					/>
					<path
						d="M34.11 24.629c.953.004 1.89.004 2.816 0a.489.489 0 0 1 .406.297c.04.297.05.59.04.89.023 1.68 0 3.36-.075 5.036-.04.05-.09.09-.149.113A65.636 65.636 0 0 1 34.11 31v-6.371Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#6f6947",
							fillOpacity: 1
						}}
					/>
					<path
						d="M32.258 24.703a3.19 3.19 0 0 1 0 1.332.402.402 0 0 1-.11-.219 4.55 4.55 0 0 1-1.593 0 1.88 1.88 0 0 1 0-1.039c.566-.07 1.133-.093 1.703-.074Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#eaeae7",
							fillOpacity: 1
						}}
					/>
					<path
						d="M47.668 24.703h1.629c.047.024.098.047.148.074.059.196.098.391.11.594.136.121.234.27.297.445.05 1.48.05 2.961 0 4.442-.141.312-.375.512-.704.594h-7.257v.445h-.075c.012-.152-.004-.3-.039-.445-.074.023-.148.05-.222.074-.301-.059-.61-.098-.926-.11a1.134 1.134 0 0 1-.371-.296c-.738-.04-1.48-.051-2.223-.04.254-.43.528-.847.817-1.257a.946.946 0 0 1-.63-.52 5.842 5.842 0 0 1 0-1.332.674.674 0 0 1 .63-.445c-.082-.473.117-.649.593-.52a.185.185 0 0 1-.039.149c-.168-.102-.328-.09-.48.039.05.023.097.047.148.074-.191.168-.164.316.074.441a.791.791 0 0 0-.222.188c-.063.539-.086 1.07-.074 1.594a.69.69 0 0 0 .113.332c.316-.008.648-.008 1 0 .012-3.36.047-3.36.11 0 .05-.938.05-1.875 0-2.817-.18.024-.368.024-.555 0-.043-.406.003-.789.148-1.148.3-.07.586-.031.852.113.132-.043.27-.082.406-.113 2.246-.035 4.492-.047 6.742-.035v-.52Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#e7e7e5",
							fillOpacity: 1
						}}
					/>
					<path
						d="M41.074 25.445h6.89c.7.14.911.535.63 1.184a.585.585 0 0 1-.485.262 172.3 172.3 0 0 1-6.96 0c-.649-.149-.833-.532-.555-1.149.148-.133.308-.23.48-.297Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#fefefe",
							fillOpacity: 1
						}}
					/>
					<path
						d="M30.555 25.816a4.55 4.55 0 0 0 1.593 0c.016.09.055.164.11.22v.073c-.07.852-.098 1.72-.074 2.594a51.557 51.557 0 0 0-1.555 0c.023-.969 0-1.93-.074-2.887Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#ded0ae",
							fillOpacity: 1
						}}
					/>
					<path
						d="M39.445 26.406h.075c.187.024.375.024.554 0 .051.942.051 1.88 0 2.817-.062-3.36-.097-3.36-.11 0a21.323 21.323 0 0 0-1 0 .69.69 0 0 1-.112-.332c-.012-.524.011-1.055.074-1.594a.791.791 0 0 1 .222-.188c-.238-.125-.265-.273-.074-.441a2.628 2.628 0 0 0-.148-.074c.152-.13.312-.14.48-.04a.185.185 0 0 0 .04-.148Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#ded0b3",
							fillOpacity: 1
						}}
					/>
					<path
						d="M32.258 26.11a85.058 85.058 0 0 1 0 4.89 4.937 4.937 0 0 1-.074-1.184h-1.555a.18.18 0 0 1-.11-.074c-.011-.176-.027-.347-.039-.52a.916.916 0 0 1 .149-.519 51.557 51.557 0 0 1 1.555 0c-.024-.875.004-1.742.074-2.594Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#e9e9e7",
							fillOpacity: 1
						}}
					/>
					<path
						d="M30.629 29.816h1.555c-.024.407.004.805.074 1.184v.074a4.766 4.766 0 0 1-1.332-.11.903.903 0 0 1-.258-.335c-.04-.27-.05-.543-.04-.813Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#ded0ad",
							fillOpacity: 1
						}}
					/>
					<path
						d="M38.035 30.48c.742-.011 1.485 0 2.223.04.105.125.226.226.37.296.317.012.626.051.927.11.074-.024.148-.051.222-.074.035.144.051.293.04.445-1.266-.024-2.528 0-3.782.074v-.89Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#ddd1b7",
							fillOpacity: 1
						}}
					/>
					<path
						d="M46.629 30.852c.098.52.098 1.039 0 1.554a27.348 27.348 0 0 0-4.738-1.11v-.444h4.738Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#3f3f22",
							fillOpacity: 1
						}}
					/>
					<path
						d="M46.629 30.852h1.039V33.297a2.998 2.998 0 0 0-1.04-.89 4.162 4.162 0 0 0 0-1.555Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#273402",
							fillOpacity: 1
						}}
					/>
					<path
						d="M47.668 30.852h1.48a.355.355 0 0 1-.296.183A3.34 3.34 0 0 1 47.668 31v-.148Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#ded0a7",
							fillOpacity: 1
						}}
					/>
					<path
						d="M32.258 31.074c.05.668.101 1.332.148 2 0 .051-.023.074-.074.074-.074-.68-.098-1.37-.074-2.074Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#6f6847",
							fillOpacity: 1
						}}
					/>
					<path
						d="M41.816 31.297h.075c1.605.226 3.183.598 4.738 1.11.414.214.758.511 1.039.89.152.351.254.723.297 1.11h-.371c-.008-.298-.106-.348-.297-.15.016.044.039.083.074.114-4.469.035-8.937.047-13.406.035.183-.761.355-1.527.515-2.297a15.18 15.18 0 0 1 3.555-.738 48.735 48.735 0 0 1 3.781-.074Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#edac06",
							fillOpacity: 1
						}}
					/>
					<path
						d="M34.48 32.11c-.16.769-.332 1.535-.515 2.296a.49.49 0 0 0-.074.297 318.678 318.678 0 0 0-1.633 9.332 55.97 55.97 0 0 0-2.074.074 562.41 562.41 0 0 1 1.89-10.441c.086-.176.172-.348.258-.52.05 0 .074-.023.074-.074.285-.277.606-.5.965-.668.371-.097.738-.195 1.11-.297Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#f8c334",
							fillOpacity: 1
						}}
					/>
					<path
						d="M46.703 32.852c.445.199.695.546.742 1.039h-.222a1.297 1.297 0 0 0-.594-.926.168.168 0 0 0 .074-.113Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#fbe59d",
							fillOpacity: 1
						}}
					/>
					<path
						d="M47.965 34.406c.043.067.066.14.07.223-4.715.039-9.43.062-14.144.074a.49.49 0 0 1 .074-.297c4.469.012 8.937 0 13.406-.035a.273.273 0 0 1-.074-.113c.191-.2.289-.149.297.148h.37Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#fee080",
							fillOpacity: 1
						}}
					/>
					<path
						d="M48.035 34.629c.578 3.137 1.149 6.27 1.707 9.406-5.816.02-11.617.07-17.41.149-.062-.024-.086-.075-.074-.149.5-3.125 1.043-6.238 1.633-9.332 4.714-.012 9.43-.035 14.144-.074Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#fed650",
							fillOpacity: 1
						}}
					/>
					<path
						d="M49.742 44.035v1.633c.008 2.395.031 4.789.074 7.184-1.988-.024-3.964 0-5.925.074h-3.926c-2.531.05-5.05.05-7.559 0 .473.011.942 0 1.41-.035-.496-.04-.988-.051-1.484-.04.008-2.867-.016-5.718-.074-8.554l1.558-.074c-.496-.04-.988-.051-1.484-.04 5.793-.078 11.594-.128 17.41-.148Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#f83f26",
							fillOpacity: 1
						}}
					/>
					<path
						d="M32.258 44.035c-.012.074.012.125.074.149.496-.012.988 0 1.484.039-.52.023-1.039.05-1.558.074.058 2.836.082 5.687.074 8.555.496-.012.988 0 1.484.039-.468.035-.937.047-1.41.035-.062.027-.086.074-.074.148a45.868 45.868 0 0 0-2.074 0 212.808 212.808 0 0 1-.074-7.406v-1.559a55.97 55.97 0 0 1 2.074-.074Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#e71c0a",
							fillOpacity: 1
						}}
					/>
					<path
						d="M34.926 44.332h.445v.223c-.2.117-.371.078-.52-.11a.16.16 0 0 0 .075-.113Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#f89382",
							fillOpacity: 1
						}}
					/>
					<path
						d="M36.035 44.332H39v.223h-2.965v-.223Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#fe907e",
							fillOpacity: 1
						}}
					/>
					<path
						d="M41.816 44.332c.371-.023.743 0 1.11.074a.273.273 0 0 1-.074.114 3.3 3.3 0 0 1-.961 0 .206.206 0 0 1-.075-.188Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#fd8f7f",
							fillOpacity: 1
						}}
					/>
					<path
						d="M44.332 44.258c.89-.012 1.781 0 2.668.039.098.074.098.148 0 .223a56.37 56.37 0 0 1-2.816.035.376.376 0 0 1 .148-.297Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#f19686",
							fillOpacity: 1
						}}
					/>
					<path
						d="M47.742 44.258c.102-.012.2.004.293.039.074.05.086.113.04.183-.11.094-.22.094-.333 0-.082-.066-.082-.14 0-.222Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#f09686",
							fillOpacity: 1
						}}
					/>
					<path
						d="M30.184 45.668c-.02 2.469.007 4.937.074 7.406.379 2.13.777 4.254 1.187 6.371-.347.012-.691 0-1.039-.039a1.033 1.033 0 0 1-.48-.703c-.051-4.27-.051-8.543 0-12.812.031-.145.117-.22.258-.223ZM49.742 45.668c.14.004.227.078.258.223.05 4.27.05 8.543 0 12.812-.184.66-.613.91-1.297.742.348-2.125.692-4.246 1.04-6.37.066-.052.089-.126.073-.223a489.37 489.37 0 0 1-.074-7.184Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#858585",
							fillOpacity: 1
						}}
					/>
					<path
						d="M43.89 52.926c1.962-.074 3.938-.098 5.926-.074.016.097-.007.171-.074.222a.165.165 0 0 0-.074-.11c-1.926-.038-3.852-.05-5.777-.038Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#fb957f",
							fillOpacity: 1
						}}
					/>
					<path
						d="M39.965 52.926h3.926c1.925-.012 3.851 0 5.777.039.039.027.066.062.074.11-.347 2.124-.691 4.245-1.039 6.37-.172.883-.344 1.77-.52 2.664-.066.055-.09.13-.074.223-4.71.008-9.402.008-14.074 0a276.105 276.105 0 0 1-1.629-8.887v-.37c2.051.01 4.102 0 6.149-.04a12.65 12.65 0 0 0 1.41-.11Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#fed650",
							fillOpacity: 1
						}}
					/>
					<path
						d="M32.332 53.074a.52.52 0 0 0 .074.371c.496 2.98 1.04 5.942 1.63 8.887.175.895.374 1.785.593 2.668 1.164.363 2.348.613 3.555.742.043.242.066.492.074.738a18.215 18.215 0 0 1-3.332-.59.625.625 0 0 0-.223.075 5.954 5.954 0 0 0-.297-1 3.693 3.693 0 0 1-1.96-1.074 6.8 6.8 0 0 1-.52-1.633 86.262 86.262 0 0 1-.48-2.813c-.41-2.117-.81-4.242-1.188-6.37a45.868 45.868 0 0 1 2.074 0Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#f9c334",
							fillOpacity: 1
						}}
					/>
					<path
						d="M32.406 52.926c2.508.05 5.028.05 7.559 0a12.65 12.65 0 0 1-1.41.11c-2.047.038-4.098.05-6.149.038v.371a.52.52 0 0 1-.074-.37c-.012-.075.012-.122.074-.15Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#fdd769",
							fillOpacity: 1
						}}
					/>
					<path
						d="M48.184 62.11a3.633 3.633 0 0 1-.63 1.925c-.546.461-1.179.758-1.886.89-1.316.407-2.648.68-4 .817h-.074c-1.016.09-2.028.09-3.04 0h-.37A18.497 18.497 0 0 1 34.629 65a55.983 55.983 0 0 1-.594-2.668c4.672.008 9.363.008 14.074 0-.015-.094.008-.168.075-.223Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#fee080",
							fillOpacity: 1
						}}
					/>
					<path
						d="M45.668 64.926c-.05.32-.102.64-.148.965a.758.758 0 0 0-.262-.075c-1.121.34-2.27.563-3.442.664.016-.242.063-.476.149-.703-.098-.035-.195-.047-.297-.035a20.542 20.542 0 0 0 4-.816Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#f9c438",
							fillOpacity: 1
						}}
					/>
					<path
						d="M38.184 65.742h.37c.036.68.145 1.348.337 2 .527.707 1.18.844 1.96.406.165-.14.301-.3.407-.48a12.9 12.9 0 0 0 .336-1.926h.074c.102-.012.2 0 .297.035-.086.227-.133.461-.149.703a7.124 7.124 0 0 0-.148 1.114c-.285 1.058-.977 1.515-2.074 1.37-.645-.234-1.012-.69-1.114-1.37.02-.098-.007-.172-.074-.223a2.119 2.119 0 0 0-.148-.89 5.195 5.195 0 0 0-.074-.739Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#848484",
							fillOpacity: 1
						}}
					/>
					<path
						d="M38.555 65.742c1.011.09 2.023.09 3.039 0a12.9 12.9 0 0 1-.336 1.926 1.9 1.9 0 0 1-.406.48c-.782.438-1.434.301-1.961-.406a8.673 8.673 0 0 1-.336-2Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#706949",
							fillOpacity: 1
						}}
					/>
					<path
						d="M45.52 65.89a79.343 79.343 0 0 1-.336 1.555.673.673 0 0 1-.184.11c-1.11.039-2.223.05-3.332.039.02-.375.07-.746.148-1.114a16.856 16.856 0 0 0 3.442-.664.758.758 0 0 1 .262.075Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#edad0c",
							fillOpacity: 1
						}}
					/>
					<path
						d="M38.258 66.48c.113.278.16.575.148.891a.199.199 0 0 1-.074.184 92.47 92.47 0 0 1-3.11.039c-.167-.02-.253-.117-.257-.297-.113-.442-.2-.887-.262-1.332.07-.04.14-.063.223-.074 1.094.3 2.203.496 3.332.59Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#edad0a",
							fillOpacity: 1
						}}
					/>
					<path
						d="M38.406 67.371c.067.05.094.125.074.223a18.15 18.15 0 0 1-3.257 0 92.47 92.47 0 0 0 3.109-.04.199.199 0 0 0 .074-.183Zm0 0"
						style={{
							stroke: "none",
							fillRule: "evenodd",
							fill: "#ded290",
							fillOpacity: 1
						}}
					/>
				</svg>
			</div>
		</Marker>
	);
};
export default MarkerTM;
