import { HubService, InventoryService, OrderService } from "api/axios";
import { LocalDialog } from "components";
import BForm from "components/B/form";
import { useListSearchParams, useLocalDialog } from "hooks";
import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useMemo,
	useState
} from "react";
import { Label } from "reactstrap";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { openAlert } from "store/features/alert/AlertSlice";
import { useAppDispatch } from "store/hooks";
import { useDebounce } from "use-debounce";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormGroup } from "reactstrap";
import { FieldArray, Form, Formik } from "formik";
import Select from "react-select";

export default function ModalEditSKU({
	isOpen,
	setIsOpen,
	refetch,
	populatedSKU
}: {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	refetch: () => void;
	populatedSKU: any;
}) {
	const navigate = useNavigate();
	const params = useParams();
	const id = params?.id;
	const dispatch = useAppDispatch();
	const { page, size } = useListSearchParams();
	const { localDialog, setLocalDialog } = useLocalDialog();
	const [loadingButton, setIsLoadingButton] = useState(false);
	const [nameSku, setNameSku] = useState<string>("");
	const searchQSku = useDebounce(nameSku, 500);
	const [requestedProductsSelected, setRequestedProductsSelected] =
		React.useState<
			{
				offeringPrice: number;
				id: string;
				name: string;
				// displayName: string;
				quantity: number | null;
				unit: string;
				skuId: string;
				labelStr: string;
				valueStr: string;
				specification: any;
			}[]
		>([
			{
				offeringPrice: 0,
				id: "",
				name: "",
				// displayName: "",
				quantity: null,
				unit: "",
				skuId: "",
				labelStr: "",
				valueStr: "",
				specification: {}
			}
		]);
	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		}
	}, [isOpen]);

	// Data detail SO
	const { isLoading, data } = useQuery<{ data: ApiOnePO }, ApiError>(
		["query-purchase-order"],
		async () => {
			return await OrderService.getOnePO(id ?? "");
		},
		{
			enabled: true,
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			},
			refetchOnMount: true,
			cacheTime: 0
		}
	);

	// Get ALL SKU
	const { data: SKUS } = useQuery<any, ApiError>(
		["query-search-sku", searchQSku],
		async () => {
			return await InventoryService.getAllSKU({
				page,
				size: 20,
				skuName: searchQSku[0]
			});
		},
		{
			enabled: searchQSku[0]?.length ? true : false
		}
	);

		const { data: skuOrder } = useQuery<
		ApiResponsePlain<[keyof IskuSpec]>,
		ApiError
	>(
		["query-sku-order"],
		async () => {
			return await HubService.getSkuOrder();
		},
		{
			enabled: true
		}
	);

	const skuPreview = React.useCallback(
		(specifications: IskuSpec) => {
			const preview = (skuOrder?.data as [keyof IskuSpec])?.map((el) => {
				return specifications?.[el] as string;
			});
			const previewStr = preview?.join("");
			// if (skuSpecLength === previewStr?.length) {
			return previewStr;
			// }

			// return "-";
		},
		[skuOrder?.data]
	);

	const optionSKUS = useMemo(() => {
		if (SKUS?.data?.length) {
			const data = SKUS?.data.map((el: any) => {
				return {
					...el,
					valueStr: el.id,
					labelStr: el.technicalDescription
						? `${el.sku} - ${el.technicalDescription}`
						: el.sku
				};
			});
			return data;
		}
		return [];
	}, [SKUS]);

	const RequestedProducts = useMemo(() => {
		const products: {
			offeringPrice: number;
			id: string;
			name: string;
			quantity: number;
			unit: string;
			skuId: string;
			labelStr: string;
			valueStr: string;
			specification: IskuSpec;
		}[] = [];

		const requestedProd = data?.data?.PoProducts;
		if (requestedProd?.length) {
			requestedProd.forEach((prod: any) => {
				products.push({
					offeringPrice: prod.ReqProduct?.offeringPrice,
					id: prod.ReqProduct?.id,
					name: prod.ReqProduct?.product?.name,
					quantity: prod.requestedQuantity,
					unit:
						prod.ReqProduct?.product?.unit === "M3"
							? "M³"
							: prod.ReqProduct?.product?.unit,
					skuId: prod.ReqProduct?.SKU?.sku ?? null,
					labelStr: prod.ReqProduct?.product?.name,
					valueStr: prod.ReqProduct?.id,
					specification: prod.ReqProduct?.specifications
				});
			});
			setRequestedProductsSelected(products);
		}
		return products;
	}, [data]);

	const [payloadSO, setPayloadSO] = useState<any>([]);

	const initialPayloadSOArray = RequestedProducts.map(() => ({
		skuId: undefined
	}));

	const [payloadSOArray, setPayloadSOArray] = useState<any[]>(
		initialPayloadSOArray
	);

	const { mutateAsync, isLoading: loadingCreate } = useMutation<
		any,
		ApiError,
		any,
		ApiError
	>(
		async (data: any) => {
			return await OrderService.updateRequestedProductSKU(
				id as string,
				data
			);
		},
		{
			onSuccess: (res) => {
				localDialog.onClose();
				refetch();
				setIsLoadingButton(false);
				dispatch(
					openAlert({
						body: "Berhasil Mengubah SKU!",
						color: "success"
					})
				);
				setIsOpen(false);
			},
			onError: (err) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	return (
		<LocalDialog
			isOpen={localDialog.isOpen}
			backdrop={"static"}
			header="Pilih SKU Product"
			// onClick={onSubmit}
			// loading={loadingButton}
			onClose={() => {
				localDialog.onClose();
				setTimeout(() => {
					setIsOpen(false);
				}, 500);
				setPayloadSO([]);
				setIsLoadingButton(false);
				setIsOpen(false);
			}}
		>
			<Formik
				initialValues={{
					requestedProducts: requestedProductsSelected.map(
						(product) => {

							return {
							requestedProductId: product.id,
							skuId: {
								labelStr: "",
								valueStr: ""
							}
						};
						}
					)
				}}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					try {
						const payload = values.requestedProducts.map(
							(el: any) => {

								return {
									requestedProductId: el.requestedProductId,
									skuId: el.skuId.valueStr
								};
							}
						).filter((el: any) => el.skuId && el.requestedProductId );
						const response = await mutateAsync(payload);
						setSubmitting(false);
					} catch (error) {
						dispatch(
							openAlert({
								body: "Gagal mengubah SKU",
								color: "danger"
							})
						);
					}
				}}
			>
				{({
					errors,
					handleChange,
					isSubmitting,
					values,
					setFieldValue
				}) => {
					return (
						<Form>
							<FormGroup>
								<FieldArray name="requestedProducts">
									{() => (
										<div>
											{requestedProductsSelected?.map(
												(product, index) => (
													<div
														key={index}
														className=""
													>
														<p>
															SKU yang terpilih di SPH: <b>
																{skuPreview(product.specification	)}
															</b>
															</p>
														<Label
															name={`requestedProducts.[${index}].name`}
														>
															{product.name} *
														</Label>
														<Select
															noOptionsMessage={() =>
																"Ketik Nama SKU"
															}
															placeholder={
																"Silakan Pilih SKU Terlebih Dahulu"
															}
															onInputChange={(
																text
															) => {
																setNameSku(
																	text
																);
															}}
															options={optionSKUS}
															defaultValue={
																populatedSKU[index]
															}
															getOptionLabel={(
																option: options
															) =>
																option.labelStr as string
															}
															getOptionValue={(
																option: options
															) =>
																option.labelStr as string
															}
															onChange={(
																change
															) => {
																if (change) {

																	setFieldValue(
																		`requestedProducts[${index}].skuId`,
																		{
																			valueStr:
																				change.id,
																			labelStr:
																				change.description
																					? `${change.sku} - ${change.description}`
																					: change.sku
																		}
																	);
																	setFieldValue(
																		`requestedProducts[${index}].requestedProductId`,
																		product.id
																	);
																}
															}}
														/>
													</div>
												)
											)}
										</div>
									)}
								</FieldArray>
							</FormGroup>
							<div className="modal-custom-footer">
								<LoadingButton
									color="error"
									onClick={() => {
										localDialog.onClose();
										setTimeout(() => {
											setIsOpen(false);
										});
									}}
									loading={isSubmitting}
									disabled={isSubmitting}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Batal
									</span>
								</LoadingButton>
								<LoadingButton
									color="warning"
									variant="contained"
									type="submit"
									loading={loadingCreate}
									disabled={loadingCreate}
									// sx={{ backgroundColor: "red", color: "white" }}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Pilih
									</span>
								</LoadingButton>
							</div>
						</Form>
					);
				}}
			</Formik>
		</LocalDialog>
	);
}
