import React, { useMemo } from "react";
import MTable from "./MTable";
import { useListSearchParams } from "hooks";
import { MUIDataTableIsRowCheck, MUIDataTableProps } from "mui-datatables";
import {
	Button,
	Card,
	CardBody,
	Dropdown,
	DropdownMenu,
	DropdownToggle,
	Label
} from "reactstrap";
import BForm from "./form";
import { CommonService } from "api/axios";
import { useQuery } from "react-query";
import { DateRangePicker } from "react-date-range";
import { useAppSelector } from "store/hooks";
import { getRouteName, routeMapping } from "routes";

interface IFilterDate {
	state: DRange[];
	setState: React.Dispatch<
		React.SetStateAction<{
			date?: DRange[] | undefined;
			sales?:
				| {
						id?: string | undefined;
						fullName?: string | undefined;
						options?: options[] | undefined;
				  }
				| undefined;
		}>
	>;
}

interface IFilterSales {
	state?: any;
	isOpen: boolean;
	setState: React.Dispatch<
		React.SetStateAction<{
			date?: DRange[] | undefined;
			sales?:
				| {
						id?: string | undefined;
						fullName?: string | undefined;
						options?: options[] | undefined;
				  }
				| undefined;
		}>
	>;
}

type ChipStatusType =
	| "SUBMITTED"
	| "APPROVED"
	| "DECLINED"
	| "FINISHED"
	| "CLOSED";

interface IChipColors {
	SUBMITTED: string;
	APPROVED: string;
	DECLINED: string;
	FINISHED: string;
	CLOSED: string;
}
const chipColors = {
	SUBMITTED: "#ffc554", // yellow
	APPROVED: "#2dce89",
	FINISHED: "#2dce89", // green
	DECLINED: "#f5365c", // red
	CLOSED: "#f5365c"
} as IChipColors;

const FilterDate = ({ state, setState }: IFilterDate) => {
	return (
		<DateRangePicker
			onChange={(item) => {
				setState((prev) => ({
					...prev,
					date: [item.selection as DRange]
				}));
			}}
			months={1}
			direction="horizontal"
			scroll={{ enabled: false }}
			ranges={state}
		/>
	);
};

const FilterSales = ({ state, setState, isOpen }: IFilterSales) => {
	//option sales
	useQuery<IUsers, ApiError>(
		["query-filter-sales"],
		async () => {
			return await CommonService.getUsers(1, 99, "SALES");
		},
		{
			enabled: isOpen,
			onSuccess: (res) => {
				if (!res.data?.data) return;
				let preselected = {};
				const optionsSales = res.data.data.map((el) => {
					if (state.valueStr) {
						if (state.valueStr === el.id) {
							preselected = {
								...el,
								valueStr: el.id,
								labelStr: el.fullName
							};
						}
					}
					return {
						...el,
						valueStr: el.id,
						labelStr: el.fullName
					};
				});
				setState((prev) => ({
					...prev,
					sales: {
						// ...state,
						...preselected,
						options: optionsSales
					}
				}));
			}
		}
	);

	const forms: ElementForm[] = React.useMemo(() => {
		return [
			{
				type: "dropdown",
				value: state,
				placeholder: "Pilih Sales",
				onChange: (e: any) => {
					setState((prev) => ({
						...prev,
						sales: {
							...e,
							options: prev.sales?.options
						}
					}));
				},
				options: state?.options
			}
		] as ElementForm[];
	}, [state]);
	return <BForm forms={forms} />;
};

export default function BMTable({
	columns,
	tableData,
	tableTitle,
	totalItems,
	header,
	currentPage,
	hideFilter,
	filterSales,
	filterDate,
	onDownload,
	options,
	isHideSelect = true,
	selectedIndex,
	setSelectedRows,
	setDeselectRows,
	isRowSelectable
}: {
	columns: {
		name: string;
		label: string;
		options: {
			filter: boolean;
			sort: boolean;
		};
	}[];
	tableData: DataTable[];
	tableTitle: string;
	totalItems: number;
	header?: any;
	currentPage?: number;
	hideFilter?: boolean;
	filterSales?: boolean;
	filterDate?: boolean;
	onDownload?: () => void;
	options?: MUIDataTableProps["options"];
	isHideSelect?: boolean;
	selectedIndex?: number[];
	setSelectedRows?: (index: number) => void;
	setDeselectRows?: (index: number) => void;
	isRowSelectable?: (
		dataIndex: number,
		selectedRows?: MUIDataTableIsRowCheck
	) => boolean;
}) {
	const {
		page,
		setPage,
		setSize,
		date,
		setDate,
		clearParams,
		setParams,
		searchParams,
		removeParams
	} = useListSearchParams();

	const permission = useAppSelector((state) => state.user.permission);
	const adminPermission = useMemo(() => {
		const routeName = getRouteName(location.pathname, routeMapping);

		if (routeName) {
			return permission[routeName];
		}

		return {};
	}, [location, permission]);

	const [state, setState] = React.useState<{
		date?: DRange[];
		sales?: {
			id?: string;
			fullName?: string;
			options?: options[];
			[key: string]: any;
		};
		[key: string]: any;
	}>({
		date: [
			{
				startDate: date.startDate,
				endDate: date.endDate,
				key: "selection"
			}
		],
		sales: {
			valueStr: searchParams.get("sales")
				? (searchParams.get("sales") as string)
				: undefined
		}
	});
	const [isOpen, setIsopen] = React.useState<boolean>(false);
	const [isShowCalendar, setIsShowCalendar] = React.useState(true);
	const toggle = () => setIsopen((curr) => !curr);
	const onSave = () => {
		toggle();
		const params = [];

		for (const key of Object.keys(state)) {
			if (key === "date") {
				if (state?.date?.[0]) {
					params.push({
						key: "date",
						value: state?.date?.[0]
					});
					// setDate(state?.date?.[0]);
				}
			} else {
				if (state[key]?.id) {
					params.push({
						key: key,
						value: state?.[key]?.id
					});
					// setParam(key, state[key]?.id);
				} else {
					removeParams("sales");
				}
			}
		}

		setParams(params);
	};
	const onClear = (key: "sales" | "date" | "all") => () => {
		if (key === "all") {
			toggle();
			clearParams();
			return;
		}

		// if (key === "date") {
		// 	setState({
		// 		...state,
		// 		date: []
		// 	});
		// 	removeParams("startDate");
		// 	removeParams("endDate");
		// 	return;
		// }
	};

	// Mapper for data from BTable
	const mappedData = tableData.map((el) => {
		const mappedValueData = el.values.reduce(
			(result: { [key: string]: any }, value) => {
				result[value.objectKey] = value.value;
				return result;
			},
			{}
		);
		return {
			...mappedValueData
		};
	});

	return (
		<>
			{/* header comp */}
			<Card className="">
				<CardBody>
					{!!header && header}
					{!hideFilter && (
						<Dropdown
							isOpen={isOpen}
							toggle={() => null}
							className="float-right dropdown-min"
						>
							<DropdownToggle
								className="text-light"
								role="button"
								size="sm"
								color=""
								onClick={toggle}
							>
								Filter{" "}
								<i className="fas fa-regular fa-filter"></i>
							</DropdownToggle>
							<DropdownMenu className="dropdown-menu-arrow" end>
								{filterSales && (
									<>
										<div className="dropdown-item d-flex justify-content-end">
											{/* <Button onClick={() => {
											onClear("sales")();
										}} size="sm" color="secondary" outline>
											<i className="ni ni-fat-delete" />
										</Button> */}
										</div>
										<div className="dropdown-item">
											<Label for="date">Sales</Label>
											<div id="date">
												<FilterSales
													state={state?.sales}
													setState={setState}
													isOpen={isOpen}
												/>
											</div>
										</div>
									</>
								)}

								{/* Filter Date */}
								<div className="dropdown-item d-flex justify-content-end">
									<Button
										onClick={() => {
											if (!isShowCalendar) {
												setState({
													...state,
													date: [
														{
															startDate:
																date.startDate,
															endDate:
																date.endDate,
															key: "selection"
														}
													]
												});
											} else {
												setState({
													...state,
													date: []
												});
												removeParams("startDate");
												removeParams("endDate");
											}
											// onClear("date")();
											setIsShowCalendar((curr) => !curr);
										}}
										size="sm"
										color="secondary"
										outline
									>
										{/* <i className="ni ni-fat-delete" /> */}
										{isShowCalendar
											? "Seluruh Waktu"
											: "Pilih Tanggal"}
									</Button>
								</div>

								<div className="dropdown-item">
									{isShowCalendar ? (
										<>
											<Label for="date">Date</Label>
											<div id="date">
												<FilterDate
													state={
														state.date as DRange[]
													}
													setState={setState}
												/>
											</div>
										</>
									) : null}
								</div>

								<div className="dropdown-item flex">
									<Button color="primary" onClick={onSave}>
										Save
									</Button>
									<Button onClick={onClear("all")}>
										Clear
									</Button>
								</div>
							</DropdownMenu>
						</Dropdown>
					)}

					{/* Download Button */}
					{onDownload &&
					adminPermission &&
					adminPermission.downloadExcel ? (
						<Button
							color="primary"
							className="float-right mr-2 btn-sm shadow-none mb-2"
							onClick={onDownload}
						>
							Download Excel
						</Button>
					) : null}
				</CardBody>
			</Card>

			{/* Table */}
			<MTable
				columns={columns}
				header={header}
				hideFilter={hideFilter}
				filterDate={filterDate}
				filterSales={filterSales}
				onDownload={onDownload}
				tableData={mappedData}
				tableTitle={tableTitle}
				totalItems={totalItems}
				selectableRowsHideCheckboxes={isHideSelect}
				isSearchVisible={false}
				currentPage={currentPage}
				setPage={(page) => {
					setPage(page);
				}}
				setRowsPerPage={(size) => {
					setSize(size);
				}}
				options={options}
				selectedRows={selectedIndex}
				setSelectedRows={setSelectedRows}
				setDeselectRows={setDeselectRows}
				isRowSelectable={isRowSelectable}
			/>
		</>
	);
}
