import React, { useMemo, useState } from "react";
import { DashboardService } from "api/axios";
import { BarChart, Mtd } from "components";
import { MonthStartEnd } from "helper/WeekNumberToDateRange";
import moment from "moment";
import { useQuery } from "react-query";
import { getPriceFormatted } from "helper/product";

const thisMonth = moment().endOf("month").format("YYYY-MM-DD");

const { startDate: initialMonthStartDate, endDate: initialMonthEndDate } =
	MonthStartEnd(thisMonth, 0);

export default function DepositMtd() {
	const [depositData, setDepositData] = useState({} as DepositResponse);

	useQuery(
		["deposit-mtd"],
		async () => {
			// const { startDate, endDate } = dateRange;
			return await DashboardService.depositCount({
				startDate: initialMonthStartDate,
				endDate: initialMonthEndDate,
				type: "monthly"
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				setDepositData(res.data.data as DepositResponse);
				// setTotalPages(res.data.data.totalPages);
			}
		}
	);

	const totalDeposit = useMemo(() => {
		let totalDeposit = 0;
		if (depositData.deposit) {
			Object.keys(depositData.deposit).forEach((date) => {
				const count = depositData.deposit[date as any]
					? depositData.deposit[date as any]
					: 0;
				if (count) {
					totalDeposit += +count as number;
				}
			});
		}
		return totalDeposit;
	}, [depositData]);

	return (
		<Mtd
			value={getPriceFormatted(totalDeposit, "Rp ")}
			title={`Deposit ${moment(initialMonthStartDate).format(
				"MMM-YYYY"
			)}`}
		/>
	);
}
