import React from "react";
import moment from "moment";
import { Container, Row } from "reactstrap";
import { useQuery } from "react-query";

import useListSearchParams from "../../../hooks/useQuery";
import { ProductivityService } from "../../../api/axios";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import Header from "../../../components/Headers/Header";
import BTable from "../../../components/B/table";
import Loading from "../../../components/B/loading";
import {
	closeDialog,
	openDialog
} from "../../../store/features/dialog/DialogSlice";
import { dateStr, formatDate, toSheet } from "../../../helper";
import { openAlert } from "store/features/alert/AlertSlice";
import { StatusChip } from "components";
import { useNavigate } from "react-router-dom";
import BMTable from "components/B/BMTable";

const visitationColumn = [
	{
		name: "Project.User.fullName",
		label: "Nama Sales",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "clientName",
		label: "Pelanggan / PIC",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "Project.name",
		label: "Proyek",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "finishDate",
		label: "Tanggal",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "status",
		label: "Status",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				return <StatusChip value={value} />;
			}
		}
	}
];

export default function Visitations() {
	const [tableData, setTableData] = React.useState<ApiVisitations>({
		message: "",
		success: true,
		dataTable: []
	});
	const { page, date, searchParams } = useListSearchParams();
	const navigate = useNavigate();
	const salesId = searchParams.get("sales");
	const { bp } = useAppSelector((store) => store.batching);
	// query summary
	useQuery<ApiVisitations, ApiError>(
		["query-summary", date, salesId, bp?.valueStr],
		async () => {
			const sumDate = {
				...date
			};
			if (!date.endDate) {
				sumDate.startDate = moment()
					.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
					.toDate();
				sumDate.endDate = moment()
					.add(1, "days")
					.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
					.toDate();
			}
			return await ProductivityService.getAllVisitations(1, 1, {
				date: sumDate,
				salesId: salesId as string,
				batchingPlantId: bp?.valueStr
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				setTableData({
					...tableData,
					summary: res.data?.totalItems
				});
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	// query table
	const { isLoading } = useQuery<ApiVisitations, ApiError>(
		["query-visitations", page, date, salesId, bp?.valueStr],
		async () => {
			return await ProductivityService.getAllVisitations(page, 15, {
				date,
				salesId: salesId as string,
				batchingPlantId: bp?.valueStr
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const { success } = res;
				if (!success) return;
				const data = res.data?.data.map((el, index) => ({
					id: el.id,
					values: [
						{
							objectKey: "id",
							value: el.id,
							type: "text",
							render: false
						},
						// {
						// 	objectKey: "no",
						// 	value: (index + 1) + (15 * (page -1)), //15 is size data, look axios service,
						// 	type: "text",
						// },
						{
							objectKey: "Project.User.fullName",
							value: el.Project.User?.fullName,
							type: "text"
						},
						{
							objectKey: "clientName",
							value:
								el.Project.Customer?.displayName ??
								el.Project.mainPic.name,
							type: "text"
						},
						{
							objectKey: "Project.name",
							value: el.Project.name,
							type: "text"
						},
						// {
						// 	objectKey: "dateVisit",
						// 	value: formatDate(el.dateVisit),
						// 	type: "text"
						// },
						{
							objectKey: "finishDate",
							value: formatDate(el.finishDate),
							type: "text"
						},
						{
							objectKey: "status",
							value: el.status,
							type: "status"
						}
					]
				}));

				setTableData(() => ({
					...tableData,
					...res,
					dataTable: (data as DataTable[]) ?? []
				}));
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const { refetch, isLoading: loadingSheet } = useQuery<
		ApiVisitations,
		Error
	>(
		["query_table", date, salesId, bp?.valueStr],
		async () => {
			const sumDate = {
				...date
			};
			if (!date.endDate) {
				sumDate.startDate = moment()
					.set({ hour: 0, minute: 0, millisecond: 0 })
					.toDate();
				sumDate.endDate = moment()
					.add(1, "days")
					.set({ hour: 0, minute: 0, millisecond: 0 })
					.toDate();
			}
			return await ProductivityService.getAllVisitations(1, 1000, {
				date: sumDate,
				salesId: salesId as string,
				batchingPlantId: bp?.valueStr
			});
		},
		{
			enabled: false,
			refetchOnWindowFocus: false
		}
	);

	const dispatch = useAppDispatch();
	const downloadSheet = React.useMemo(
		() => () => {
			refetch().then((res) => {
				if (!res.data || (res?.data?.data?.data?.length ?? 0) < 1) {
					dispatch(
						openDialog({
							header: "Tidak ada laporan",
							body: `Tidak ada laporan di tanggal ${dateStr(
								date
							)}. Silahkan pilih tanggal lain`,
							onClick: "close"
						})
					);
					return;
				}

				if (res.data.data?.data) {
					const phases: any = {
						LAND_PREP: "Persiapan Lahan",
						FOUNDATION: "Pasang Ceker Ayam",
						FORMWORK: "Pemasangan Bekisting",
						FINISHING: "Finishing"
					};
					const customerTypes: any = {
						INDIVIDU: "Individu",
						COMPANY: "Perusahaan"
					};

					if (res.data.data.data) {
						const mappedProducts = res.data?.data?.data.map(
							(el, idx) => {
								return el.VisitationProducts.map((item) => {
									return {
										product: item.Product.name,
										quantity: item.quantity,
										pouringMethod: item.pouringMethod
										// unitPrice: `Rp ${getPriceFormatted(
										// 	product.offeringPrice
										// )}`
									};
								});
							}
						);
						const dataSheet = res.data.data.data.map((el, i) => {
							const productRow = mappedProducts[i]
								?.map((product) => product.product)
								.join(", ");

							const quantityRow = mappedProducts[i]
								?.map((quantity) => quantity.quantity)
								.join(", ");

							const methodRow = mappedProducts[i]?.map(
								(method) => method.pouringMethod
							);
							return {
								// No: i + 1,
								"Tanggal Jam Kunjungan": moment(
									el.finishDate
								).format("DD/MM/YYYY, HH:mm"),
								"PT / PIC":
									el.Project?.Customer?.displayName ??
									el.Project?.mainPic?.name,
								Proyek: el.Project?.name,
								"Tipe Pelanggan":
									customerTypes[el.customerType] ?? "",
								"Kunjungan No.": el.order,
								"Alamat Proyek":
									el.Project?.ShippingAddress?.line1,
								"Titik Proyek": `http://maps.google.com/maps?z=3&t=m&q=loc:${el.Project?.ShippingAddress?.lat}+${el.Project?.ShippingAddress?.lon}`,
								"Titik Visit": `http://maps.google.com/maps?z=3&t=m&q=loc:${el.address?.lat}+${el.address?.lon}`,
								Sales: el.Project?.User?.fullName,
								"PIC - Nama": el.Project?.mainPic?.name,
								"PIC - No. Telp.": `+62${el?.Project?.mainPic?.phone}`,
								"PIC - Jabatan": el.Project?.mainPic?.position,
								"Tipe Pembayaran":
									el.Project?.Customer?.type === "INDIVIDU"
										? "CASH"
										: "CREDIT",
								"Fase Proyek": phases[el.Project?.stage] ?? "",
								"Kebutuhan Produk": productRow,

								Metode: methodRow,
								Volume: quantityRow,
								"Perkiraan Waktu Kebutuhan": `Bulan ke-${el.estimationMonth}, Minggu ke-${el.estimationWeek}`,
								"Catatan Sales": el.visitNotes,
								"Kompetitor- pks- exclusif- masalah- harapan":
									el.Project.Competitors?.map(
										(v) =>
											`${v.name || "n/a"}, ${
												v.mou || "n/a"
											}, ${v.exclusive || "n/a"}, ${
												v.problem || "n/a"
											}, ${v.hope || "n/a"}`
									).join(", "),
								// "Tahap Selanjutnya": el.s,
								"Tahap Selanjutnya": el.status,
								// "Tanggal Kunjungan Selanjutnya": el.,
								"Alasan Closed Lost":
									el.rejectCategory &&
									`(${el.rejectCategory}) ${el.rejectNotes}`

								// "Kontak PIC": `+62${el?.Project.mainPic.phone}`,
								// "Visit": `Visit ke ${el.order}`,
								// "Tanggal Visit": moment(el.dateVisit).format("D-MMM, hh:mm A"),
								// "Status": el.status
							};
						});
						toSheet(dataSheet, date, "Kunjungan");
						dispatch(closeDialog());
					}
				}
			});
		},
		[date]
	);

	const onDownload = React.useMemo(
		() => () => {
			dispatch(
				openDialog({
					header: "Unduh laporan",
					body: `Apakah kamu yakin ingin mengunduh laporan ${dateStr(
						date
					)}?`,
					onClick: downloadSheet
				})
			);
		},
		[date]
	);

	if (isLoading || loadingSheet) {
		return <Loading />;
	}

	return (
		<>
			<Header data={tableData.summary} date={date} show />
			<Container className="mt--7" fluid>
				{/* Table */}
				<Row>
					<div className="col">
						<BMTable
							tableTitle=""
							onDownload={onDownload}
							filterSales
							columns={visitationColumn}
							tableData={tableData.dataTable as DataTable[]}
							totalItems={tableData.data?.totalItems as number}
							currentPage={tableData.data?.currentPage as number}
							options={{
								onRowClick(rowData, rowMeta) {
									if (tableData && tableData.dataTable) {
										const id =
											tableData.dataTable[
												rowMeta.rowIndex
											]?.id;
										if (id !== undefined) {
											navigate(
												`/productivity/visitation/${id}`
											);
										}
									}
								}
							}}
						/>
						{/* <BTable
							currentPage={page}
							totalPage={tableData.data?.totalPages as number}
							totalItem={tableData.data?.totalItems as number}
							columns={[
								// {
								// 	key: "id",
								// 	value: "No",
								// },
								{
									key: "salesName",
									value: "Sales Name"
								},
								{
									key: "clientName",
									value: "PT / PIC"
								},
								{
									key: "projectVisited",
									value: "Proyek"
								},
								// {
								// 	key: "dateVisit",
								// 	value: "Visit Date",
								// },
								{
									key: "finishDate",
									value: "Tanggal"
								},
								{
									key: "status",
									value: "Status"
								}
							]}
							data={tableData.dataTable as DataTable[]}
							onDownload={onDownload}
							filterSales
						/> */}
					</div>
				</Row>
			</Container>
		</>
	);
}
