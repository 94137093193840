import React from "react";
import { getStatusColor } from "helper";
type ChipStatusType =
	| "SUBMITTED"
	| "APPROVED"
	| "DECLINED"
	| "FINISHED"
	| "CLOSED";

interface IChipColors {
	SUBMITTED: string;
	APPROVED: string;
	DECLINED: string;
	FINISHED: string;
	CLOSED: string;
}

const chipColors = {
	SUBMITTED: "#ffc554", // yellow
	APPROVED: "#2dce89",
	FINISHED: "#2dce89", // green
	DECLINED: "#f5365c", // red
	CLOSED: "#f5365c"
} as IChipColors;

export default function StatusChip({ value }: { value: string }) {
	return (
		<span
			style={{
				// backgroundColor: chipColors[value as ChipStatusType]
				// 	? chipColors[value as ChipStatusType]
				// 	: "#e0e0e0",
				// color: "white",
				...getStatusColor(value)
				// padding: "10px",
				// fontSize: "86%"
			}}
			className="badge bg-null"
		>
			{value as string}
		</span>
	);
}
