import React from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { AccurateService } from "api/axios";
import { Header, LoadingSpinner } from "components";
import { Card, CardTitle, Container, Row } from "reactstrap";
import { CardContent } from "@mui/material";
import styles from "./index.module.scss";
import { Link } from "react-bootstrap-icons";
import { AccurateCreds, getAccurateCreds } from "helper";

export default function AccurateOauth() {
	const [searchParams] = useSearchParams();

	const { isLoading, error, isError } = useQuery(
		"accurateOauth",
		() =>
			AccurateService.getAccurateToken(
				searchParams.get("code") as string
			),
		{
			enabled: !!searchParams.get("code") && !getAccurateCreds(),
			onSuccess: (data) => {
				if (data) {
					const accurateCreds: AccurateCreds = {
						accessToken: `Bearer ${data.data?.access_token}`,
						refreshToken: data.data?.refresh_token,
						user: data.data?.user,
						session: data.data?.session,
						host: data.data?.host,
						expiredAt: data.data?.expires_in
							? new Date(
									new Date().getTime() +
										data.data?.expires_in * 1000
							  ).toISOString()
							: undefined
					};
					localStorage.setItem(
						"accurateCreds",
						JSON.stringify(accurateCreds)
					);
					// dispatch(setAccurateCreds(accurateCreds));
				}
			}
		}
	);

	return (
		<>
			<Header />
			<Container
				style={{
					position: "relative",
					top: "-10rem"
				}}
				fluid
			>
				<Row className="d-flex justify-content-center">
					<Card className="shadow p-4">
						<CardTitle className="d-flex justify-content-center">
							<img
								src="https://account.accurate.id/images/img/logo-accurate.svg"
								alt=""
							/>
						</CardTitle>
						{isError ? (
							<div className={styles.errorAccurate}>
								ada permasalahan silahkan kontak admin BOD
								{JSON.stringify(error)}
							</div>
						) : null}
						<CardContent>
							{isLoading && !isError ? (
								<div className="d-flex justify-content-center">
									<LoadingSpinner />
								</div>
							) : (
								<>
									{!isError && error ? (
										<div>
											{/* {(error as any)?.message} */}
											<p>
												Tidak dapat melanjutkan proses
												otorisasi. Hal ini bisa terjadi
												dikarenakan hal berikut ini:
											</p>
											{/* {JSON.stringify(
												(error as any).response?.data
													?.error?.data
											)} */}
											<ul>
												<li>
													{
														(error as any)?.response
															?.data?.error?.data
															?.error
													}
												</li>
												<li>
													{
														(error as any)?.response
															?.data?.error?.data
															?.error_description
													}
												</li>
											</ul>
										</div>
									) : (
										<div className="d-flex align-items-center flex-column">
											{getAccurateCreds()?.user ? (
												<>
													<div className="d-flex flex-column align-items-center mb-5">
														<div>
															<img
																src={require("../../assets/img/brand/brik-logo.png")}
																alt=""
																style={{
																	height: "50px"
																}}
															/>
														</div>
														<Link size={30} />
														<img
															src="https://account.accurate.id/images/img/logo-accurate.svg"
															alt=""
															style={{
																width: "100px",
																marginTop:
																	"10px"
															}}
														/>
													</div>
													<p>
														Telah masuk sebagai{" "}
														<b>
															{
																getAccurateCreds()
																	?.user?.name
															}
														</b>{" "}
														/{" "}
														<b>
															{
																getAccurateCreds()
																	?.user
																	?.nickname
															}
														</b>
													</p>
												</>
											) : null}
											<p>
												<b>
													Silahkan tutup tautan ini!
												</b>
											</p>
										</div>
									)}
								</>
							)}
						</CardContent>
					</Card>
				</Row>
			</Container>
		</>
	);
}
