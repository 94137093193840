import React, { useState, useEffect } from "react";
import {
	Container,
	Row,
	Form,
	FormGroup,
	Input,
	Button,
	Dropdown
} from "reactstrap";
import Select from "react-select";
import { useQuery } from "react-query";
import moment from "moment";

import { OrderService } from "api/axios";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { closeDialog, openDialog } from "store/features/dialog/DialogSlice";
import {
	dateStr,
	formatDate,
	formatLinkSheet,
	getStatusTrx,
	toSheet
} from "helper";
import { useListSearchParams } from "hooks";
import { BStatus, Header, StatusChip } from "components";
import { getPriceFormatted } from "helper/product";
import { openAlert } from "store/features/alert/AlertSlice";
import BMTable from "components/B/BMTable";
import { useNavigate, useLocation } from "react-router-dom";
import Files from "components/B/Files";
import { useDebounce } from "use-debounce";
import CreateQuotation from "./QuotationCreate";
import { updateQueryParams } from "helper/filter";
import AccurateChip from "components/B/AccurateChip";

const sphColumn = [
	{
		name: "accurateQuotationId",
		label: "Accurate",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: number) => {
				return <AccurateChip value={!!value} />;
			}
		}
	},
	{
		name: "number",
		label: "No. SPH",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "status",
		label: "Status",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				return <BStatus data={value.data}>{value.status}</BStatus>;
			}
		}
	},
	{
		name: "clientName",
		label: "Pelanggan",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "mainPic",
		label: "PIC",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "projectName",
		label: "Proyek",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "address",
		label: "Kota",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "clientCategory",
		label: "Tipe Pelanggan",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				if (value === undefined) {
					return "-";
				} else {
					return <StatusChip value={value} />;
				}
			}
		}
	},
	{
		name: "salesName",
		label: "Nama Sales",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "totalPrice",
		label: "Jumlah",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "files",
		label: "Dokumen",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				return <Files value={value} />;
			}
		}
	},
	{
		name: "dateIssued",
		label: "Tanggal Terbit",
		options: {
			filter: false,
			sort: false
		}
	}
];

export default function Quotations() {
	const [tableData, setTableData] = useState<ApiQuotations>({
		message: "",
		success: true,
		currentPage: 1,
		totalItems: 10,
		totalPages: 1,
		dataTable: []
	});
	const [showCreateForm, setShowCreateForm] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const [searchSphNumber, setSearchSphNumber] = useState<string>();
	const [searchQSphNumber] = useDebounce(searchSphNumber, 500);
	const [searchProjectName, setSearchProjectName] = useState<string>();
	const [searchQProjectName] = useDebounce(searchProjectName, 500);
	const [searchCustomerPic, setSearchCustomerPic] = useState<string>();
	const [searchQCustomerPic] = useDebounce(searchCustomerPic, 500);
	const [selectedStatus, setSelectedStatus] = useState<string | undefined>(
		undefined
	);
	const { page, date, searchParams, setParams } = useListSearchParams();
	const salesId = searchParams.get("sales");
	const { bp } = useAppSelector((store) => store.batching);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const sphNumberParam = queryParams.get("search?sph-number");
		const projectNameParam = queryParams.get("search?project-name");
		const customerPicParam = queryParams.get("search?customer-pic");

		if (sphNumberParam) {
			setSearchSphNumber(sphNumberParam);
		} else {
			setSearchSphNumber(undefined);
		}

		if (projectNameParam) {
			setSearchProjectName(projectNameParam);
		} else {
			setSearchProjectName(undefined);
		}

		if (customerPicParam) {
			setSearchCustomerPic(customerPicParam);
		} else {
			setSearchCustomerPic(undefined);
		}
	}, [location.search]);

	// query summary
	useQuery<ApiVisitations, ApiError>(
		["query-summary", date, salesId, bp?.valueStr],
		async () => {
			const sumDate = {
				...date
			};
			if (!date.endDate) {
				sumDate.startDate = moment()
					.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
					.toDate();
				sumDate.endDate = moment()
					.add(1, "days")
					.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
					.toDate();
			}
			return await OrderService.getAllQuotations(1, 1, {
				date: sumDate,
				salesId: salesId as string,
				batchingPlantId: bp?.valueStr
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				setTableData({
					...tableData,
					summary: res.data?.totalItems
				});
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);
	// query table
	const { refetch: RefetchAllQuotations } = useQuery<ApiQuotations, ApiError>(
		[
			"query-quotations",
			searchQSphNumber,
			searchQProjectName,
			searchQCustomerPic,
			selectedStatus,
			page,
			date,
			salesId,
			bp?.valueStr
		],
		async () => {
			return await OrderService.getAllQuotations(page, 15, {
				projectName: searchQProjectName,
				sphNumber: searchQSphNumber,
				date,
				salesId: salesId as string,
				batchingPlantId: bp?.valueStr,
				customerName: searchQCustomerPic,
				status: selectedStatus
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res.data?.data?.map((el, index) => {
					let cityName = "-";
					const project = el?.QuotationRequest?.Project;
					if (project?.BillingAddress) {
						if (project?.BillingAddress?.Postal?.City) {
							cityName = project?.BillingAddress.Postal?.City
								.name as string;
						}
					}
					if (project?.LocationAddress) {
						if (project?.LocationAddress?.Postal?.City) {
							cityName = project?.LocationAddress.Postal?.City
								.name as string;
						}
					}
					if (project?.ShippingAddress) {
						if (project?.ShippingAddress?.Postal?.City) {
							cityName = project?.ShippingAddress?.Postal?.City
								.name as string;
						}
					}
					return {
						id: el?.id,
						values: [
							{
								objectKey: "id",
								value: index + 1,
								type: "text",
								render: false
							},
							// {
							// 	objectKey: "no",
							// 	value: (index + 1) + (15 * (page - 1)), //15 is size data, look axios service,
							// 	type: "text",
							// },
							{
								objectKey: "number",
								value: el?.number ?? "-",
								type: "text"
							},
							{
								objectKey: "accurateQuotationId",
								value: el?.accurateQuotationId,
								type: "text"
							},
							{
								objectKey: "status",
								value: el.status
									? {
											status: el.status,
											data: el
									  }
									: "-",
								type: "status"
							},
							{
								objectKey: "projectName",
								value:
									el?.QuotationRequest?.Project?.name ?? "-",
								type: "text"
							},
							{
								objectKey: "clientName",
								value:
									el?.QuotationRequest?.Project?.Customer
										?.displayName ?? "-",
								type: "text"
							},
							{
								objectKey: "mainPic",
								value:
									el?.QuotationRequest?.Project?.mainPic
										?.name ?? "-",
								type: "text"
							},
							// {
							// 	objectKey: "clientPhone",
							// 	value: el?.QuotationRequest?.Project?.mainPic?.phone,
							// 	type: "text",
							// },
							{
								objectKey: "address",
								value: cityName,
								type: "text"
							},
							// {
							// 	objectKey: "deliveryDistance",
							// 	value: el?.QuotationRequest?.distance ?? "-",
							// 	type: "text",
							// },
							{
								objectKey: "clientCategory",
								value:
									el?.QuotationRequest?.Project?.Customer
										?.type === "COMPANY"
										? "PERUSAHAAN"
										: el?.QuotationRequest?.Project
												?.Customer?.type,
								type: "customerType"
							},
							{
								objectKey: "salesName",
								value:
									el?.QuotationRequest?.Project?.User
										?.fullName ?? "-",
								type: "text"
							},
							{
								objectKey: "totalPrice",
								value: `Rp ${getPriceFormatted(
									el?.QuotationRequest?.totalPrice
								)}`,
								type: "text",
								align: "end"
							},
							{
								objectKey: "files",
								value: el?.QuotationLetterFiles ?? "-",
								type: "files"
							},
							{
								objectKey: "dateIssued",
								value: formatDate(el?.createdAt),
								type: "text"
							}
						]
					};
				});

				setTableData(() => ({
					...tableData,
					...res,
					dataTable: (data as DataTable[]) ?? []
				}));
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const { refetch, isLoading: loadingSheet } = useQuery<ApiQuotations, Error>(
		["query-download-visitation", date, salesId, bp?.valueStr],
		async () => {
			if (!date.endDate) {
				date.endDate = date.startDate;
			}
			return await OrderService.getAllQuotations(1, 100, {
				date,
				salesId: salesId as string,
				batchingPlantId: bp?.valueStr
			});
		},
		{
			enabled: false,
			refetchOnWindowFocus: false
		}
	);

	const handleSphNumberSearch = (newValue: string) => {
		setSearchSphNumber(newValue);

		const queryParams = updateQueryParams({
			"search?sph-number": newValue,
			page: "1"
		});

		navigate({
			pathname: location.pathname,
			search: queryParams.toString()
		});
	};

	const handleProjectNameSearch = (newValue: string) => {
		setSearchProjectName(newValue);

		const queryParams = updateQueryParams({
			"search?project-name": newValue,
			page: "1"
		});

		navigate({
			pathname: location.pathname,
			search: queryParams.toString()
		});
	};

	const handleCustomerPicSearch = (newValue: string) => {
		setSearchCustomerPic(newValue);

		const queryParams = updateQueryParams({
			"search?customer-pic": newValue,
			page: "1"
		});

		navigate({
			pathname: location.pathname,
			search: queryParams.toString()
		});
	};

	const handleStatusChange = (newValue: any) => {
		setSelectedStatus(newValue?.value);

		const queryParams = updateQueryParams({
			"search?status": newValue,
			page: "1"
		});

		navigate({
			pathname: location.pathname,
			search: queryParams.toString()
		});
	};

	const dispatch = useAppDispatch();
	const downloadSheet = () => {
		refetch({}).then((res) => {
			if (!res.data || (res?.data?.data?.data?.length ?? 0) < 1) {
				dispatch(
					openDialog({
						header: "Tidak ada laporan",
						body: `Tidak ada laporan di tanggal ${dateStr(
							date
						)}. Silahkan pilih tanggal lain`,
						onClick: "close"
					})
				);
				return;
			}

			if (res.data.data?.data) {
				const mappedProducts = res.data?.data?.data.map((el, idx) => {
					return el.QuotationRequest.RequestedProducts.map(
						(product) => {
							return {
								product: product.displayName,
								quantity: product.quantity,
								unitPrice: `Rp ${getPriceFormatted(
									product.offeringPrice
								)}`
							};
						}
					);
				});
				const dataSheet = res.data?.data?.data?.map((el, i) => {
					const sphFile = el?.QuotationLetterFiles?.find(
						(el) => el?.type === "LETTER"
					);

					const productRow = mappedProducts[i]
						?.map((product) => product.product)
						.join(", ");

					const quantityRow = mappedProducts[i]
						?.map((quantity) => quantity.quantity)
						.join(", ");

					const priceRow = mappedProducts[i]
						?.map((price) => price.unitPrice)
						.join(", ");

					return {
						// No: i + 1,
						"Nama Sales":
							el?.QuotationRequest?.Project?.User?.fullName,
						"No SPH": el.number,
						"Nama Pelanggan":
							el?.QuotationRequest?.Project?.Customer
								?.displayName ?? "-",
						"Nama Proyek": el?.QuotationRequest?.Project?.name,
						Alamat: `http://maps.google.com/maps?z=3&t=m&q=loc:${el?.QuotationRequest?.Project?.ShippingAddress?.lat}+${el?.QuotationRequest?.Project?.ShippingAddress?.lon}`,
						Status: getStatusTrx(el?.status),
						"Tahap Proyek": el?.QuotationRequest?.Project?.stage,
						"Nama PIC":
							el?.QuotationRequest?.Project?.mainPic?.name,
						"Kontak PIC": `+62${el?.QuotationRequest?.Project?.mainPic?.phone}`,
						"Dokumen SPH": sphFile?.File?.url ?? "-",
						Produk: productRow,
						"Kuantitas (m3)": quantityRow,
						"Harga per m3": priceRow,
						"Total Harga": `Rp ${getPriceFormatted(
							el?.QuotationRequest?.totalPrice
						)}`,
						"Tanggal Terbit": formatDate(el?.createdAt)
					};
				});

				toSheet(dataSheet, date, "Quotation");
				dispatch(closeDialog());
			}
		});
	};

	const onDownload = () => {
		dispatch(
			openDialog({
				header: "Unduh laporan",
				body: `Apakah kamu yakin ingin mengunduh laporan ${dateStr(
					date
				)}?`,
				onClick: () => {
					downloadSheet();
				}
			})
		);
	};

	// if (isLoading || loadingSheet) {
	// 	return <Loading />;
	// }

	const statusOptions = [
		{ value: "DRAFT", label: "Diterbitkan" },
		{ value: "SUBMITTED", label: "Diajukan" },
		{ value: "CANCELLED", label: "Dibatalkan" }
	];

	return (
		<>
			<Header data={tableData.summary} date={date} show />
			<Container className="mt--7" fluid>
				{/* Table */}
				<Row>
					{showCreateForm ? (
						<CreateQuotation
							isOpen={showCreateForm}
							setIsOpen={setShowCreateForm}
							refetch={RefetchAllQuotations}
						/>
					) : null}
					<div className="col">
						<BMTable
							tableTitle=""
							header={
								<>
									<Button
										style={{ float: "right" }}
										size="sm"
										color="success"
										className="confirm-button approve-button"
										onClick={() => {
											setShowCreateForm(true);
										}}
									>
										Buat SPH
									</Button>
									<div
										style={{
											display: "flex"
										}}
									>
										<Form>
											<FormGroup
												style={{
													display: "flex",
													gap: "1rem",
													width: "100%"
												}}
											>
												<Input
													placeholder="NO. SPH"
													value={searchSphNumber}
													onChange={(e) =>
														handleSphNumberSearch(
															e.target.value
														)
													}
												/>
												<Input
													placeholder="NAMA PROYEK"
													value={searchProjectName}
													onChange={(e) =>
														handleProjectNameSearch(
															e.target.value
														)
													}
												/>
												<Input
													placeholder="NAMA PELANGGAN /PIC"
													value={searchCustomerPic}
													onChange={(e) =>
														handleCustomerPicSearch(
															e.target.value
														)
													}
												/>
												<Select
													name="quotationStatus"
													placeholder="Status"
													isClearable
													value={statusOptions.find(
														(option) =>
															option.value ===
															selectedStatus
													)}
													options={statusOptions}
													onChange={
														handleStatusChange
													}
													getOptionLabel={(option) =>
														option.label as string
													}
													getOptionValue={(option) =>
														option.value as string
													}
													styles={{
														container: (
															provided
														) => ({
															...provided,
															width: "100%"
														}),
														control: (
															provided
														) => ({
															...provided,
															width: "100%"
														})
													}}
												/>
											</FormGroup>
										</Form>
									</div>
								</>
							}
							columns={sphColumn}
							onDownload={onDownload}
							filterSales
							tableData={tableData.dataTable as DataTable[]}
							totalItems={tableData.data?.totalItems as number}
							currentPage={tableData.data?.currentPage as number}
							options={{
								onRowClick(rowData, rowMeta) {
									if (tableData && tableData.dataTable) {
										const id =
											tableData.dataTable[
												rowMeta.rowIndex
											]?.id;
										if (id !== undefined) {
											navigate(`/order/quotation/${id}`);
										}
									}
								}
							}}
						/>
						{/* <BTable
							currentPage={page}
							totalPage={tableData.data?.totalPages as number}
							totalItem={tableData.data?.totalItems as number}
							columns={[
								{
									key: "number",
									value: "No"
								},
								{
									key: "status",
									value: "Status"
								},
								{
									key: "clientName",
									value: "PT / PIC"
								},
								{
									key: "projectName",
									value: "Proyek"
								},
								// {
								// 	key: "clientPhone",
								// 	value: "Client's Phone Number",
								// },
								{
									key: "address",
									value: "Kota"
								},
								// {
								// 	key: "deliveryDistance",
								// 	value: "Delivery Distance",
								// },
								{
									key: "clientCategory",
									value: "Tipe"
								},
								{
									key: "salesName",
									value: "Sales"
								},
								{
									key: "totalPrice",
									value: "Total",
									align: "end"
								},
								{
									key: "files",
									value: "Files"
								},
								{
									key: "dateIssued",
									value: "Tanggal Terbit"
								}
							]}
							data={tableData.dataTable as DataTable[]}
							onDownload={onDownload}
							filterSales
						/> */}
					</div>
				</Row>
			</Container>
		</>
	);
}
