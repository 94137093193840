import React, { useMemo, useState } from "react";
import { DashboardService } from "api/axios";
import { Mtd } from "components";
import { MonthStartEnd } from "helper/WeekNumberToDateRange";
import moment from "moment";
import { useQuery } from "react-query";

const thisMonth = moment().endOf("month").format("YYYY-MM-DD");
const { startDate: initialMonthStartDate, endDate: initialMonthEndDate } =
	MonthStartEnd(thisMonth, 0);
export default function SalesSOMtd() {
	const [salesSo, setSalesSo] = useState({} as SalesSoResponse);

	useQuery(
		["sales-so"],
		async () => {
			return await DashboardService.salesSoCount({
				startDate: initialMonthStartDate,
				endDate: initialMonthEndDate,
				type: "monthly",
				page: 1,
				size: 1
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				setSalesSo(res.data.data as SalesSoResponse);
				// setTotalPages(res.data.data.totalPages);
			}
		}
	);

	const bestSales = useMemo(() => {
		let value = "-";
		if (salesSo.sales) {
			if (salesSo.sales[0]) {
				value = `${salesSo.sales[0].fullName},
               Total SO = ${salesSo.sales[0].numSalesOrders}
                `;
			}
		}
		return value;
	}, [salesSo]);

	return (
		<Mtd
			value={bestSales}
			title={`Top Sales, ${moment(initialMonthStartDate).format(
				"MMM-YYYY"
			)}`}
		/>
	);
}
