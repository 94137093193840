import { HubService } from "api/axios";
import { FormikHelpers } from "formik";
import React, { Fragment } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import { useDebounce } from "use-debounce";

export default function SkuSelector({
	skuSpecLength,
	setFieldValue,
	index,
	skuSpec,
	skuOrder
}: {
	skuSpecLength: number;
	setFieldValue: FormikHelpers<unknown>["setFieldValue"];
	index: number;
	skuSpec?: IskuSpec;
	skuOrder?: [keyof IskuSpec];
}) {
	const [skuKeyword, setSkuKeyword] = React.useState("");
	const skuPreview = React.useMemo(() => {
		const preview = (skuOrder as [keyof IskuSpec])?.map((el) => {
			return skuSpec?.[el] as string;
		});
		return preview?.join("") || "";
	}, [skuOrder, skuSpec]);

	React.useEffect(() => {
		setSkuKeyword(() => skuPreview);
	}, [skuPreview]);

	const [keywordsDebounced] = useDebounce(skuKeyword, 500);
	const { data: sku, isFetched } = useQuery<
		ApiResponsePagination<HubSku[]>,
		ApiError
	>(
		["query-sku-order", keywordsDebounced],
		async () => {
			return await HubService.getSku({
				keywords: keywordsDebounced
			});
		},
		{
			enabled:
				skuSpecLength > 0 && skuSpecLength === keywordsDebounced.length
		}
	);

	const defaultValue = React.useMemo(() => {
		const skuVersions =
			sku?.data?.data?.map((sku) => parseInt(sku.sku.slice(-2))) || [];
		const maxVersion = Math.max(...skuVersions);
		const selectedSku = sku?.data?.data?.find(
			(sku) => parseInt(sku.sku.slice(-2)) === maxVersion
		);
		return selectedSku;
	}, [sku]);

	React.useEffect(() => {
		setFieldValue(`requestedProducts.${index}.skuId`, defaultValue?.id);
		const sku = defaultValue?.sku || skuKeyword;
		setFieldValue(`requestedProducts.${index}.sku`, sku);
	}, [defaultValue, skuKeyword]);

	return (
		<Fragment>
			<label>SKU</label>
			<div>
				<Select
					options={sku?.data?.data}
					getOptionLabel={(option) =>
						option.sku.slice(0, option.sku.length - 2) +
						` - Versi: ${option.version}`
					}
					getOptionValue={(option) => option.id}
					placeholder={
						isFetched
							? sku?.data?.totalItems
								? `Ditemukan ${sku?.data?.totalItems} SKU`
								: "SKU tidak ditemukan"
							: "Isi Spesifikasi SKU terlebih dahulu"
					}
					isDisabled={!sku?.data?.totalItems}
					name={`requestedProducts.${index}.skuId`}
					onChange={(change) => {
						if (change) {
							setFieldValue(
								`requestedProducts.${index}.sku`,
								change.sku
							);
							setFieldValue(
								`requestedProducts.${index}.skuId`,
								change.id
							);
						}
					}}
					value={defaultValue}
				/>
				<p
					style={{
						fontSize: "small"
					}}
				>
					{isFetched
						? sku?.data?.totalItems
							? `Ditemukan ${sku?.data?.totalItems} SKU`
							: "SKU tidak ditemukan"
						: null}
				</p>
			</div>
		</Fragment>
	);
}
