import React from "react";
import { useParams } from "react-router-dom";

import "split-pane-react/esm/themes/default.css";
import styles from "../index.module.scss";
const extrasUrl = process.env.REACT_APP_EXTRAS;

const QcAssignmentDetail = () => {
	const { assignmentSetId } = useParams();

	return (
		<div className={styles.mainContainer}>
			<iframe
				className={styles.iframe}
				src={`${extrasUrl}/quality-control/assignment-set/${assignmentSetId}`}
			></iframe>
		</div>
	);
};

export default QcAssignmentDetail;
