import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { LocalDialog } from "components";
import { useLocalDialog } from "hooks";
import { useMutation, useQuery } from "react-query";
import { CommonService } from "api/axios";
import { useParams } from "react-router-dom";
import imageCompression from "browser-image-compression";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAppDispatch } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import { Button } from "reactstrap";

export default function EditDocuments({
	isOpen,
	setIsOpen,
	customerDocs,
	customerDetail,
	refetch
}: {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	customerDocs?: CustomerDocs[];
	customerDetail: {
		displayName: string;
		type: "COMPANY" | "INDIVIDU";
		ktp: string;
		npwp: string;
	};
	refetch: () => void;
}) {
	const params = useParams();
	const id = params?.id;
	const { localDialog } = useLocalDialog();
	const dispatch = useAppDispatch();
	const docs = customerDocs ? customerDocs : [];
	const [docsInput, setDocsInput] = useState<{ [key: string]: any }>({});

	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		}
	}, [isOpen]);

	const { mutateAsync: uploadFile, isLoading: loadingUpload } = useMutation<
		ApiResponseFiles,
		ApiError,
		FormData,
		ApiError
	>(async (_files: FormData) => {
		const res = await CommonService.uploadFile(_files);
		return res;
	});

	const { mutateAsync: updateCustomer, isLoading: loadingCustomer } =
		useMutation<ApiResponseCustomer, ApiError, PayloadCustomer, ApiError>(
			async (data: PayloadCustomer) => {
				const res = await CommonService.updateCustomer(
					id as string,
					data
				);
				return res;
			},
			{
				onSuccess: (res) => {
					localDialog.onClose();
					dispatch(
						openAlert({
							body: res.message,
							color: "success"
						})
					);
					// setIsLoading(false);
					setIsOpen(false);

					// navigate("/database/customers");
				},
				onError: (err) => {
					// setIsLoading(false);
					dispatch(
						openAlert({
							body: err.response?.data.message,
							color: "danger"
						})
					);
				}
			}
		);

	async function handleEditDocument() {
		try {
			const files = new FormData();

			files.append("name", "Update Customer BOD");
			Object.keys(docsInput).forEach((doc) => {
				files.append("photos", docsInput[doc].file as File);
			});
			const response = await uploadFile(files);

			const customerPayload = response.data.map((data) => {
				const payloadData: {
					fileId: string;
					documentId: string;
					File: { id: string };
					Document: { id: string; name?: string };
					id?: string;
				} = {
					fileId: data.id,
					documentId: data.name,
					File: { id: data.id },
					Document: {
						id: data.name
					}
				};
				for (const id of Object.keys(docsInput)) {
					if (data.name === docsInput[id].file.name) {
						payloadData.id = docsInput[id].customerDocId;
						payloadData.Document.name = docsInput[id].docName;
						break;
					}
				}
				if (!payloadData.id) {
					delete payloadData.id;
				}
				return payloadData;
			});

			const payload = {
				customerDocs: customerPayload as CustomerDocs[],
				...customerDetail
			};
			await updateCustomer(payload);
			refetch();
		} catch (err) {
			console.log(err, "err handleEditDocument");
			dispatch(
				openAlert({
					body: "Gagal Upload File",
					color: "danger"
				})
			);
		}
	}

	return (
		<LocalDialog
			isOpen={localDialog.isOpen}
			backdrop={true}
			header="Ubah Dokumen Pelanggan"
			onClose={() => {
				localDialog.onClose();
				setTimeout(() => {
					setIsOpen(false);
				}, 500);
			}}
		>
			<>
				<div className="docs-container">
					{docs.map((doc) => (
						<>
							<p>{doc.Document.name}</p>
							<div
								key={doc.id}
								className="customer-docs file-item"
								onClick={(e) => {
									if (docsInput[doc.Document.id]) {
										window.open(
											URL.createObjectURL(
												docsInput[doc.Document.id].file
											),
											"_blank"
										);
									} else if (doc.File) {
										window.open(doc.File?.url, "_blank");
									}
								}}
							>
								{doc.File || docsInput[doc.Document.id] ? (
									<>
										{doc?.File?.type === "pdf" ||
											docsInput[doc.Document.id]?.file
												.type === "application/pdf" ? (
											<iframe
												src={
													docsInput[doc.Document.id]
														? URL.createObjectURL(
															docsInput[
																doc.Document
																	.id
															]?.file
														)
														: doc?.File?.url
												}
												width="100%"
												height="200px"
											/>
										) : (
											<img
												src={
													docsInput[doc.Document.id]
														? URL.createObjectURL(
															docsInput[
																doc.Document
																	.id
															]?.file
														)
														: doc?.File?.url
												}
												alt={doc.Document.name}
											/>
										)}
									</>
								) : (
									<p>N/A</p>
								)}
							</div>
							<div className="document-action">
								<input
									type="file"
									name="fileInput"
									id="photoInput"
									accept=".png, .jpeg, .jpg, .pdf"
									onChange={async (e) => {
										const options = {
											maxSizeMB: 1,
											maxWidthOrHeight: 1920,
											useWebWorker: true
										};
										if (e.target.files) {
											let theFile = e.target.files[0];
											if (
												e.target.files[0]?.type ===
												"application/pdf"
											) {
												theFile = new File(
													[e.target.files[0]],
													doc.Document.id,
													{
														type: e.target.files[0]
															.type
													}
												);
											} else {
												const compressedFIle =
													await imageCompression(
														e.target
															.files[0] as File,
														options
													);
												theFile = new File(
													[compressedFIle],
													doc.Document.id,
													{
														type: compressedFIle.type
													}
												);
											}
											setDocsInput((curr) => {
												return {
													...curr,
													[doc.Document.id]: {
														file: theFile,
														customerDocId:
															doc.customerDocId,
														docName:
															doc.Document.name
													}
												};
											});
										}
									}}
								/>
								{/* {doc.File || docsInput[doc.Document.id] ? (
									<button
										className="view-img"
										onClick={(e) => {
											if (doc.File) {
												window.open(
													doc.File.url,
													"_blank"
												);
											} else if (
												docsInput[doc.Document.id]
											) {
												window.open(
													URL.createObjectURL(
														docsInput[
															doc.Document.id
														].file
													),
													"_blank"
												);
											}
										}}
									>
										Lihat Gambar
									</button>
								) : null} */}
							</div>
							<div className="dropdown-divider w-100"></div>
						</>
					))}
				</div>
				<div className="modal-custom-footer">
					<LoadingButton
						color="error"
						onClick={() => {
							setIsOpen(false);
						}}
						loading={loadingUpload || loadingCustomer}
						disabled={loadingUpload || loadingCustomer}
					>
						<span style={{ textTransform: 'capitalize' }}>Batal</span>

					</LoadingButton>
					<LoadingButton
						color="warning"
						variant="contained"
						loading={loadingUpload || loadingCustomer}
						disabled={loadingUpload || loadingCustomer}
						onClick={handleEditDocument}
					>
						<span style={{ textTransform: 'capitalize' }}>Ubah</span>


					</LoadingButton>
				</div>
			</>
		</LocalDialog>
	);
}
