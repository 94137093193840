/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { CommonService } from "api/axios";
// reactstrap components
import {
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Navbar,
	Nav,
	Container,
	Media
} from "reactstrap";
import { getAccurateCreds } from "helper";
import { useAppSelector } from "store/hooks";

const AdminNavbar = (props) => {
	const navigate = useNavigate();

	// const { isHideSideBar } = useAppSelector((state) => state.alert);
	const logout = useMutation(
		async () => {
			return await CommonService.logout();
		},
		{
			onSuccess: () => {
				localStorage.clear();
				navigate("/auth/login", {
					replace: true
				});
			},
			onError: (err) => {
				// dispatch(openAlert({
				// 	body: err.response?.data.message,
				// 	color: "danger"
				// }));
				localStorage.clear();
				navigate("/auth/login", {
					replace: true
				});
			}
		}
	);

	const [stopCondition, setStopCondition] = useState(false);

	useEffect(() => {
		let intervalId;
		let timeoutId;
		if (stopCondition) {
			intervalId = setInterval(() => {
				if (getAccurateCreds()?.user) {
					clearInterval(intervalId);
					setStopCondition(false);
					console.log("Function stopped!");
					window.location.reload();
				} else {
					console.log("Checking the condition...");
				}
			}, 5000); // 5000 milliseconds = 5 seconds

			// Stop the interval after 5 minutes
			timeoutId = setTimeout(() => {
				clearInterval(intervalId);
			}, 300000);
		}

		return () => {
			if (intervalId && timeoutId) {
				clearInterval(intervalId);
				clearTimeout(timeoutId);
			}
		};
	}, [stopCondition]);

	return (
		<>
			<Navbar className="navbar-top" expand="md" id="navbar-main">
				<Container fluid>
					<Link
						className="h4 mb-0 text-uppercase d-none d-lg-inline-block"
						to="/"
					>
						{props.brandText}
					</Link>
					{/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Search" type="text" />
              </InputGroup>
            </FormGroup>
          </Form> */}
					{/* {isHideSideBar ? null : (
						<Nav
							className="align-items-center d-none d-md-flex"
							navbar
						>
							<UncontrolledDropdown nav>
								<DropdownToggle className="pr-0" nav>
									<Media className="align-items-center">
										<Media className="ml-2 d-none d-lg-block">
											<span className="text-black mb-0 text-sm font-weight-bold">
												<div>
													<img
														src="https://account.accurate.id/images/img/logo-accurate.svg"
														alt=""
														style={{
															width: "60px",
															marginRight: "10px"
														}}
													/>
													{getAccurateCreds()?.user
														? getAccurateCreds()
																?.user?.nickname
														: "Login Accurate"}
												</div>
											</span>
										</Media>
									</Media>
								</DropdownToggle>
								<DropdownMenu
									className="dropdown-menu-arrow"
									end
								>
									{getAccurateCreds()?.user ? (
										<DropdownItem
											href="#pablo"
											onClick={(e) => {
												e.preventDefault();
												localStorage.removeItem(
													"accurateCreds"
												);
												window.location.reload();
											}}
										>
											<i className="ni ni-user-run" />
											<span>Logout Accurate</span>
										</DropdownItem>
									) : (
										<DropdownItem
											href="#pablo"
											onClick={(e) => {
												e.preventDefault();
												window.open(
													`https://account.accurate.id/oauth/authorize?client_id=ba58abb3-239b-4478-b162-eb3388259c2a&response_type=code&redirect_uri=${window.location.origin}/sl/accurate-oauth&scope=item_view item_save sales_invoice_view sales_invoice_save customer_view customer_save purchase_order_save sales_order_view delivery_order_save delivery_order_view branch_view warehouse_view`
												);
												setStopCondition(true);
											}}
										>
											<i className="ni ni-user-run" />
											<span>Login Accurate</span>
										</DropdownItem>
									)}
								</DropdownMenu>
							</UncontrolledDropdown>

							<UncontrolledDropdown nav>
								<DropdownToggle className="pr-0" nav>
									<Media className="align-items-center">
										<span className="avatar avatar-sm rounded-circle">
											<img
												alt="..."
												src={require("../../assets/img/placeholder/profile-placeholder.jpg")}
											/>
										</span>
										<Media className="ml-2 d-none d-lg-block">
											<span className="text-black mb-0 text-sm font-weight-bold">
												{localStorage.getItem("user") ??
													"-"}
											</span>
										</Media>
									</Media>
								</DropdownToggle>
								<DropdownMenu
									className="dropdown-menu-arrow"
									end
								>
									<DropdownItem
										href="#pablo"
										onClick={(e) => {
											e.preventDefault();
											logout.mutate();
										}}
									>
										<i className="ni ni-user-run" />
										<span>Logout</span>
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</Nav>
					)} */}
				</Container>
			</Navbar>
		</>
	);
};

export default AdminNavbar;
