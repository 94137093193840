import React from "react";
import { Loading, LocalDialog } from "components";
import { useDebounce } from "use-debounce";
// import BForm from "components/B/form";
import {
	QueryObserverResult,
	RefetchOptions,
	RefetchQueryFilters,
	useMutation,
	useQuery
} from "react-query";
import { CommonService, FinanceService, HubService } from "api/axios";
import { useDispatch } from "react-redux";
import { openAlert } from "store/features/alert/AlertSlice";
import { handleResponseFile, parseIDR } from "helper";
import moment from "moment";
import { useAppSelector } from "store/hooks";
import { ErrorMessage, Form, Formik } from "formik";
import { FormGroup, Input, Label } from "reactstrap";
import Select, { OptionProps } from "react-select";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import CurrencyInput from "react-currency-input-field";
import styles from "./createPayment.module.scss";
import AccurateCustomerSelector from "./AccurateCustomerSelector";

const depositSchema = Yup.object().shape({
	number: Yup.string().matches(
		/^DEP\/BRIK-\w+\/\d{4}\/\d{2}\/\d{5}$/,
		"Nomor Deposit Harus Seperti DEP/BRIK-XXX/2023/12/00001"
	),
	customer: Yup.object()
		.shape({
			valueStr: Yup.string().required("Customer harus diisi"),
			labelStr: Yup.string().required("Customer harus diisi")
		})
		.required("Customer harus dipilih"),
	batchingPlant: Yup.object()
		.shape({
			valueStr: Yup.string().required("Customer harus diisi"),
			labelStr: Yup.string().required("Customer harus diisi")
		})
		.required("Batching Plant harus dipilih"),
	// type: Yup.object()
	// 	.shape({
	// 		valueStr: Yup.string().required("Tipe harus diisi"),
	// 		labelStr: Yup.string().required("Tipe harus diisi")
	// 	})
	// 	.required("Tipe harus diisi")
	type: Yup.string().required("Tipe harus diisi"),
	amount: Yup.string().required("Jumlah harus diisi"),
	paymentDate: Yup.string().required("Tanggal Pembayaran harus diisi"),
	paymentFile: Yup.mixed()
		.required("File Pembayaran Dibutuhkan")
		// .test("fileFormat", "Only PDF files are allowed", (value) => {
		// 	if (value) {
		// 		const supportedFormats = ["pdf"];
		// 		return supportedFormats.includes(value.name.split(".").pop());
		// 	}
		// 	return true;
		// })
		.test("fileSize", "File harus lebih kecil dari 5MB", (value: any) => {
			if (value) {
				// return value.size <= 3145728;
				return value.size <= 5242880;
			}
			return true;
		}),
	customerAccurate: Yup.object().required("Pelanggan Accurate harus diisi"),
	description: Yup.string().optional()
});

const depositDisabledAccurateSchema = Yup.object().shape({
	number: Yup.string().matches(
		/^DEP\/BRIK-\w+\/\d{4}\/\d{2}\/\d{5}$/,
		"Nomor Deposit Harus Seperti DEP/BRIK-XXX/2023/12/00001"
	),
	customer: Yup.object()
		.shape({
			valueStr: Yup.string().required("Customer harus diisi"),
			labelStr: Yup.string().required("Customer harus diisi")
		})
		.required("Customer harus dipilih"),
	batchingPlant: Yup.object()
		.shape({
			valueStr: Yup.string().required("Customer harus diisi"),
			labelStr: Yup.string().required("Customer harus diisi")
		})
		.required("Batching Plant harus dipilih"),
	// type: Yup.object()
	// 	.shape({
	// 		valueStr: Yup.string().required("Tipe harus diisi"),
	// 		labelStr: Yup.string().required("Tipe harus diisi")
	// 	})
	// 	.required("Tipe harus diisi")
	type: Yup.string().required("Tipe harus diisi"),
	amount: Yup.string().required("Jumlah harus diisi"),
	paymentDate: Yup.string().required("Tanggal Pembayaran harus diisi"),
	paymentFile: Yup.mixed()
		.required("File Pembayaran Dibutuhkan")
		// .test("fileFormat", "Only PDF files are allowed", (value) => {
		// 	if (value) {
		// 		const supportedFormats = ["pdf"];
		// 		return supportedFormats.includes(value.name.split(".").pop());
		// 	}
		// 	return true;
		// })
		.test("fileSize", "File harus lebih kecil dari 5MB", (value: any) => {
			if (value) {
				// return value.size <= 3145728;
				return value.size <= 5242880;
			}
			return true;
		}),
	description: Yup.string().optional()
});
// const typeOptions = [
// 	{
// 		labelStr: "DEPOSIT",
// 		valueStr: "DEPOSIT"
// 	},
// 	{
// 		labelStr: "INVOICE",
// 		valueStr: "INVOICE"
// 	}
// ];

interface IProps {
	localDialog: {
		isOpen: boolean;
		onClose: () => void;
		onOpen: () => void;
	};
	setLocalDialog: React.Dispatch<
		React.SetStateAction<{
			isOpen: boolean;
			onClose: () => void;
			onOpen: () => void;
		}>
	>;
	handleCreateLoading: (isLoading: boolean) => void;
	refetch: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
	) => Promise<QueryObserverResult<ApiPayments, ApiError>>;
}

// const Option = (props: OptionProps<CustomerAccurate>) => {
// 	return (
// 		<div
// 			className={styles.selectCustom}
// 			{...("innerProps" in props && props.innerProps)}
// 			style={{
// 				cursor: props.isDisabled ? "default" : "pointer",
// 				backgroundColor: props.isDisabled ? "lightgray" : "white"
// 			}}
// 		>
// 			<div>
// 				<div className={styles.selectInfo}>
// 					<p>{props.data.name}</p>
// 					<b>{props.data.customerNo}</b>
// 				</div>
// 				<div className={styles.contact}>
// 					<p>
// 						HP: <b>{props.data.mobilePhone || "-"}</b>
// 					</p>
// 					<p>
// 						WA: <b>{props.data.whatsappNo || "-"}</b>
// 					</p>
// 					<p>
// 						email: <b>{props.data.email || "-"}</b>
// 					</p>
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

// const AccurateCustomerSelector = (props: {
// 	customers: CustomerAccurate[];
// 	setSearchCustomer: (value: string) => void;
// 	setFieldValue: (field: string, value: any) => void;
// 	values: any;
// 	errors: any;
// 	defaultAccurateCustomer?: CustomerAccurate;
// 	isLoading?: boolean;
// }) => {
// 	const {
// 		customers,
// 		setSearchCustomer,
// 		setFieldValue,
// 		values,
// 		errors,
// 		defaultAccurateCustomer
// 	} = props;
// 	React.useEffect(() => {
// 		setFieldValue("customerAccurate", defaultAccurateCustomer);
// 	}, [defaultAccurateCustomer]);

// 	return (
// 		<FormGroup>
// 			{isAccurateDisabled ? null : (
// 				<div>
// 					<Label htmlFor="searchCustomer">Pelanggan Accurate *</Label>

// 					<Select
// 						options={customers}
// 						components={{ Option }}
// 						getOptionLabel={(option: CustomerAccurate) =>
// 							option.name
// 						}
// 						getOptionValue={(option: CustomerAccurate) =>
// 							option.customerNo
// 						}
// 						onInputChange={(inputValue: string) => {
// 							setSearchCustomer(inputValue);
// 						}}
// 						onChange={(e) => {
// 							setFieldValue("customerAccurate", e);
// 						}}
// 						isMulti={false}
// 						value={values.customerAccurate}
// 						defaultValue={defaultAccurateCustomer}
// 						isDisabled={!values.customer}
// 						styles={{
// 							control(base, state) {
// 								return {
// 									...base,
// 									borderColor: errors.customerAccurate
// 										? "red"
// 										: "#cad1d7"
// 								};
// 							}
// 						}}
// 						// isLoading={customerListLoading || customerDetailLoading}
// 					/>
// 				</div>
// 			)}

// 			<ErrorMessage
// 				name={"customerAccurate"}
// 				component="div"
// 				className="error-msg"
// 			/>
// 		</FormGroup>
// 	);
// };

const CreatePayment = (props: IProps) => {
	const dispatch = useDispatch();
	const { localDialog, refetch: refetchList, handleCreateLoading } = props;
	const [nameCustomer, setNameCustomer] = React.useState<string>("");
	const [searchCustomerQ] = useDebounce(nameCustomer, 500);
	const [setselectedCustomerAccurateId, setSetselectedCustomerAccurateId] =
		React.useState("");
	const { data: customers } = useQuery<any, ApiError>(
		["query-search-customer", searchCustomerQ],
		async () => {
			return await CommonService.getAllCustomer(1, 20, {
				search: searchCustomerQ
			});
		},
		{
			enabled: searchCustomerQ?.length ? true : false
		}
	);
	const { isAccurateDisabled: accurateStatusDisabled } = useAppSelector((state) => state.setting);
	//accurate disable
	const isAccurateDisabled =
		process.env.REACT_APP_IS_ACCURATE_DISABLED === "true" || accurateStatusDisabled;

	const optionCustomer = React.useMemo(() => {
		if (customers?.data?.data?.length) {
			const data = customers?.data?.data.map((el: any) => {
				return {
					// ...el,
					valueStr: el.id,
					labelStr: el.displayName,
					customerAccurateId: el.customerAccurateId
				};
			});
			return data;
		}
		return [];
	}, [customers?.data?.data]);

	const [payload, setPayload] = React.useState<Partial<CreatePayment>>({});
	const { bp, options } = useAppSelector((store) => store.batching);

	// queries
	// DO
	const [page, setPage] = React.useState<number>(1);
	const [text, setText] = React.useState<string>("");
	const [searchInvoice] = useDebounce(text, 1000);
	const [searchCustomer, setSearchCustomer] = React.useState("");

	const [searchCustomerDebounced] = useDebounce(searchCustomer, 500);

	React.useEffect(() => {
		if (searchInvoice) {
			setOptionInvoices([]);
			setPage(1);
		}
	}, [searchInvoice]);

	const [optionInvoices, setOptionInvoices] = React.useState<options[]>([]);
	const { isLoading: loadingInvoice, refetch } = useQuery<
		ApiInvoices,
		ApiError
	>(
		[
			"query-invoices",
			page,
			searchInvoice,
			payload.project?.valueStr,
			bp?.valueStr
		],
		async () => {
			return await FinanceService.getAllInvoice(page, 15, {
				projectId: payload.project?.valueStr,
				batchingPlantId: bp?.valueStr
			});
		},
		{
			enabled: !!payload.project?.valueStr,
			onSuccess: (res) => {
				const options = res.data?.data.map((el) => ({
					id: el.id,
					labelStr: el.number,
					valueStr: el.id,
					amountPaid: el.amountPaid,
					total: el.total,
					amountDue: el.amountDue
				}));

				setOptionInvoices([
					...optionInvoices,
					...(options as options[])
				] as options[]);
			},
			onError: (err: ApiError) => {
				// dispatch(openAlert({
				// 	body: err.response?.data.message,
				// 	color: "danger"
				// }));
			}
		}
	);
	const {
		mutate: sendFile,
		isLoading: loadingUpload,
		mutateAsync: sendFileAsync
	} = useMutation<ApiResponseFiles, ApiError, FormData, ApiError>(
		async (_files: FormData) => {
			return await CommonService.uploadFile(_files);
		}
	);
	const { mutateAsync: createDownPaymentAccurate } = useMutation(
		async (data: unknown) => {
			return await HubService.createDownPayment(data);
		}
	);
	const [uploadFile, setUploadFile] = React.useState<File>();
	// states
	// const forms: ElementForm[] = React.useMemo(() => {
	// 	const base: ElementForm[] = [
	// 		{
	// 			label: "Nomor Pembayaran",
	// 			type: "text",
	// 			value: payload?.number,
	// 			placeholder: "DEP/BRIK/YYYY/MM/1234",
	// 			disable: false,
	// 			onChange: (e: any) => {
	// 				setPayload({
	// 					...payload,
	// 					number: e.target.value
	// 				});
	// 			}
	// 		},
	// 		{
	// 			label: "Nama Pelanggan",
	// 			type: "autocomplete",
	// 			value: payload.customer,
	// 			placeholder: "PT. Bangun Rancang Indonesia Kita",
	// 			loadOption: (search: string) => {
	// 				setNameCustomer(search);
	// 			},
	// 			onChange: (e: any) => {
	// 				setPayload({
	// 					...payload,
	// 					customer: e,
	// 					invoice: {}
	// 				});
	// 			},
	// 			options: optionCustomer
	// 		},
	// 		{
	// 			label: "Tipe",
	// 			value: payload?.type,
	// 			placeholder: "Pilih Tipe Deposit atau Invoice",
	// 			type: "dropdown",
	// 			onChange: (e: any) => {
	// 				setPayload({
	// 					...payload,
	// 					type: e,
	// 					invoice: {}
	// 				});
	// 			},
	// 			options: [
	// 				{
	// 					labelStr: "DEPOSIT",
	// 					valueStr: "DEPOSIT"
	// 				},
	// 				{
	// 					labelStr: "INVOICE",
	// 					valueStr: "INVOICE"
	// 				}
	// 			]
	// 		},
	// 		{
	// 			label: "Jumlah",
	// 			type: "money",
	// 			value: payload?.amount,
	// 			onChange: (e: any) => {
	// 				const value = formatIDR(e.target.value);
	// 				setPayload({
	// 					...payload,
	// 					amount: value
	// 				});
	// 			},
	// 			placeholder: "Jumlah"
	// 		},
	// 		{
	// 			label: "Tanggal Pembayaran",
	// 			type: "date",
	// 			value: payload?.paymentDate,
	// 			onChange: (e: any) => {
	// 				setPayload({
	// 					...payload,
	// 					paymentDate: e.target.value
	// 				});
	// 			},
	// 			placeholder: "Pilih Tanggal"
	// 		},
	// 		{
	// 			label: "Pilih File",
	// 			type: "file",
	// 			value: uploadFile,
	// 			placeholder: "Pilih File",
	// 			onChange: (e: any) => {
	// 				setUploadFile(e.target.files[0]);
	// 			}
	// 		}
	// 	];

	// 	if (payload.type?.valueStr === "INVOICE") {
	// 		base.splice(
	// 			3,
	// 			0,
	// 			{
	// 				label: "Invoice",
	// 				type: "autocomplete",
	// 				value: payload?.invoice,
	// 				placeholder: "INV/BRIK/",
	// 				onChange: (e: any) => {
	// 					setPayload({
	// 						...payload,
	// 						invoice: e
	// 					});
	// 				},
	// 				options: optionInvoices,
	// 				loadOption: (inputValue: string) => {
	// 					setText(inputValue);
	// 				},
	// 				onReachBottom: () => {
	// 					setPage(page + 1);
	// 				},
	// 				isLoading: loadingInvoice
	// 			},
	// 			{
	// 				label: "Jumlah Invoice",
	// 				type: "money",
	// 				value: formatIDR(payload?.invoice?.total ?? 0),
	// 				disable: true
	// 			},
	// 			{
	// 				label: "Jumlah Invoice Terbayar",
	// 				type: "money",
	// 				value: formatIDR(payload?.invoice?.amountPaid ?? 0),
	// 				disable: true
	// 			},
	// 			{
	// 				label: "Jumlah Invoice Terhutang",
	// 				type: "money",
	// 				value: formatIDR(payload?.invoice?.amountDue ?? 0),
	// 				disable: true
	// 			}
	// 		);
	// 	}

	// 	return base;
	// }, [
	// 	payload,
	// 	// optionProjects,
	// 	// loadingProjects,
	// 	loadingInvoice,
	// 	optionInvoices,
	// 	optionCustomer
	// ]);

	const {
		mutate,
		isLoading: loadingAdd,
		mutateAsync: postPayment
	} = useMutation<
		ApiResponseCreateInvoice,
		ApiError,
		Partial<PayloadPayment>,
		ApiError
	>(
		async (_payload: Partial<PayloadPayment>) => {
			const res = await FinanceService.createPayment(_payload);
			return res;
		},
		{
			onSuccess: (e) => {
				localDialog.onClose();
				setPayload({});
				handleCreateLoading(false);
				refetchList();
			},
			onError: (err) => {
				handleCreateLoading(false);
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const {
		data: detailAccurateCustomer,
		isLoading: customerDetailLoading,
		isFetched
	} = useQuery<ApiResponsePlain<Customer>>(
		["query-customer-accurate-details", setselectedCustomerAccurateId],
		async () => {
			return await HubService.getDetailCustomerAccurate(
				setselectedCustomerAccurateId
			);
		},
		{
			// onSuccess(data) {

			// },
			onError(err: any) {
				dispatch(
					openAlert({
						body: "message",
						color: "danger"
					})
				);
			},
			enabled: !!setselectedCustomerAccurateId
		}
	);
	const { data: branchAccurate, isLoading: branchLoading } = useQuery<
		ApiResponsePlain<AccurateResponse<Branch[]>>
	>(
		["query-branch-accurate", setselectedCustomerAccurateId, isAccurateDisabled],
		async () => {
			return await HubService.getBranchList();
		},
		{
			// onSuccess(data) {

			// },
			onError(err: any) {
				dispatch(
					openAlert({
						body: "message",
						color: "danger"
					})
				);
			},
			enabled: !isAccurateDisabled ? true : false
		}
	);

	const { data: customersAccurate, isLoading: customerListLoading } =
		useQuery<ApiResponsePlain<CustomerAccurate[]>>(
			[
				"query-customer-accurate",
				searchCustomerDebounced,
				detailAccurateCustomer?.data?.customerNo
			],
			async () => {
				return await HubService.getCustomersAccurate({
					keywords:
						detailAccurateCustomer?.data?.customerNo ||
						searchCustomerDebounced ||
						""
				});
			},
			{
				onSuccess(data) {
					if (detailAccurateCustomer?.data?.customerNo) {
						const defaultValueCustomer = data?.data?.find(
							(cust: CustomerAccurate) => {
								return (
									cust.customerNo ===
									detailAccurateCustomer?.data?.customerNo
								);
							}
						);
						if (defaultValueCustomer) {
							// setFieldValueContext(
							// 	"customerAccurate",
							// 	defaultValueCustomer
							// );
							// setPayload((prev) => ({
							// 	...prev,
							// 	customerAccurate: defaultValueCustomer
							// }));
						}
					}
					// setCustomersList((prev) => {
					// 	return prev.concat(data?.data?.d);
					// });
					// const defaultValueCustomer = data?.data?.d?.find(
					// 	(cust: CustomerAccurate) => {
					// 		return cust.id === customerAccurateId;
					// 	}
					// );
					// if (defaultValueCustomer) {
					// 	setPayloadDO((prev) => ({
					// 		...prev,
					// 		customerAccurate: defaultValueCustomer
					// 	}));
					// }
				},
				onError(err: any) {
					dispatch(
						openAlert({
							body: "message",
							color: "danger"
						})
					);
				},
				enabled: true
			}
		);

	const defaultAccurateCustomer = React.useMemo(
		() =>
			customersAccurate?.data?.find((cust) => {
				return (
					cust.customerNo === detailAccurateCustomer?.data?.customerNo
				);
			}),
		[customersAccurate?.data, detailAccurateCustomer?.data?.customerNo]
	);
	// const onSubmit = () => {
	// 	if (!uploadFile) {
	// 		dispatch(
	// 			openAlert({
	// 				body: "Harus upload bukti",
	// 				color: "danger"
	// 			})
	// 		);
	// 		return;
	// 	}
	// 	const _sendFile = new FormData();
	// 	_sendFile.append("photos", uploadFile as File);
	// 	_sendFile.append("name", "SIGNED_SO");
	// 	sendFile(_sendFile, {
	// 		onSuccess: (res) => {
	// 			const data = handleResponseFile(res.data, [uploadFile as File]);
	// 			const files = data.map((el) => ({ fileId: el.fileId }));
	// 			const _payload: Partial<PayloadPayment> = {
	// 				// projectId: payload.project?.id,
	// 				customerId: payload?.customer?.valueStr,
	// 				invoiceId: payload.invoice?.id,
	// 				amount: parseIDR(payload.amount as string),
	// 				type: payload.type?.valueStr,
	// 				paymentDate: moment(payload.paymentDate as Date).valueOf(),
	// 				files: files,
	// 				number: payload.number,
	// 				batchingPlantId: bp?.valueStr
	// 			};
	// 			handleCreateLoading(true);
	// 			mutate(_payload);

	// 			dispatch(
	// 				openAlert({
	// 					body: "Berhasil Membuat Pembayaran",
	// 					color: "success"
	// 				})
	// 			);
	// 		},
	// 		onError: (err: ApiError) => {
	// 			dispatch(
	// 				openAlert({
	// 					body: err.response?.data.message ?? "error upload file",
	// 					color: "danger"
	// 				})
	// 			);
	// 		}
	// 	});
	// };

	if (!localDialog.isOpen) return null;

	if (loadingAdd) {
		return <Loading />;
	}

	return (
		<LocalDialog
			isOpen={localDialog.isOpen}
			backdrop={"static"}
			// body={<BForm forms={forms} />}
			header="Buat Pembayaran Baru"
			onClose={() => {
				setPayload({});
				localDialog.onClose();
			}}
			fullScreen
		>
			{/* <>
				{JSON.stringify(payload.customer)}
				<BForm forms={forms} />
			</> */}
			<Formik
				initialValues={{
					batchingPlant: bp as options,
					number: "",
					customer: null as options | null,
					type: "DEPOSIT",
					amount: null as string | null,
					paymentDate: new Date().toISOString().split("T")[0],
					paymentFile: undefined as File | undefined,
					customerAccurate: null as CustomerAccurate | null,
					description: ""
				}}
				onSubmit={async (values, { setSubmitting }) => {
					// console.log("submit");
					setSubmitting(true);
					const _sendFile = new FormData();
					_sendFile.append("photos", values.paymentFile as File);
					_sendFile.append("name", "DEPOSIT");
					const res = await sendFileAsync(_sendFile);
					const data = handleResponseFile(res.data, [
						values.paymentFile as File
					]);
					const files = data.map((el) => ({
						fileId: el.fileId
					}));

					const _payload: Partial<PayloadPayment> = {
						// projectId: payload.project?.id,
						customerId: values?.customer?.valueStr,
						// invoiceId: payload.invoice?.id,
						amount: parseIDR(values.amount as string),
						type: values.type,
						paymentDate: moment(
							values.paymentDate as string
						).valueOf(),
						files: files,
						number: values.number,
						batchingPlantId: values.batchingPlant?.valueStr,
						customerNo: values.customerAccurate?.customerNo,
						description: values.description,
						taxStreet: detailAccurateCustomer?.data?.taxStreet
					};
					// const bPlant = values.batchingPlant?.labelStr
					// 	?.split("-")[1]
					// 	?.toLowerCase();
					// const branch = branchAccurate?.data?.d?.find(
					// 	(br) => br.name.toLowerCase() === bPlant
					// );

					handleCreateLoading(true);
					// mutate(_payload);
					// const resp =
					await postPayment(_payload);
					// const depNumber = resp.data?.number;
					// const payloadAccurate = {
					// 	dpAmount: parseIDR(values.amount as string),
					// 	branchName: branch?.name,
					// 	description: values.description,
					// 	number: depNumber,
					// 	customerNo: values.customerAccurate?.customerNo
					// };
					// await createDownPaymentAccurate(payloadAccurate);
					dispatch(
						openAlert({
							body: "Berhasil Membuat Pembayaran",
							color: "success"
						})
					);
				}}
				validationSchema={ isAccurateDisabled ? depositDisabledAccurateSchema : depositSchema }
			>
				{({
					errors,
					handleChange,
					values,
					setFieldValue,
					isSubmitting,
					touched
				}) => {
					return (
						<Form>
							{/* {JSON.stringify(values)} */}
							{/* <div className="d-flex justify-content-center">
								<ButtonGroup
									variant="contained"
									aria-label="Type"
								>
									<Button
										onClick={() => {
											setFieldValue("type", "DEPOSIT");
										}}
										style={{
											backgroundColor:
												values.type === "DEPOSIT"
													? "red"
													: "white",
											color:
												values.type === "DEPOSIT"
													? "white"
													: "black"
										}}
									>
										Deposit
									</Button>
									<Button
										onClick={() => {
											setFieldValue("type", "INVOICE");
										}}
										style={{
											backgroundColor: "grey",
											// values.type === "INVOICE"
											// 	? "red"
											// 	: "white",
											color:
												values.type === "INVOICE"
													? "white"
													: "black"
										}}
										disabled={true}
									>
										Invoice
									</Button>
								</ButtonGroup>
							</div> */}
							<FormGroup>
								<Label htmlFor="searchCustomer">
									Batching Plant*
								</Label>
								<Select
									onChange={(change) => {
										setFieldValue("batchingPlant", change);
									}}
									name="batchingPlant"
									value={values.batchingPlant}
									options={options}
									getOptionLabel={(option) =>
										option.labelStr as string
									}
									getOptionValue={(option) =>
										option.labelStr as string
									}
									placeholder="Pilih batching plant"
									styles={{
										control(base, state) {
											return {
												...base,
												borderColor:
													errors.batchingPlant
														? "red"
														: "#cad1d7"
											};
										}
									}}
								/>
								<ErrorMessage
									name={"batchingPlant"}
									component="div"
									className="error-msg"
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="number">Nomor Deposit</Label>
								<Input
									name="number"
									invalid={!!errors.number}
									onChange={handleChange}
									//setSearchCustomerName
									value={values.number}
									placeholder={
										"DEP/BRIK-XXX/YYYY/MM/00001 - kosongkan jika otomatis"
									}
								/>
								<ErrorMessage
									name={"number"}
									component="div"
									className="error-msg"
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="searchCustomer">
									Pelanggan *
								</Label>
								<Select
									onInputChange={(text) => {
										setNameCustomer(text);
									}}
									onChange={(change) => {
										setFieldValue("customer", change);
										setSetselectedCustomerAccurateId(
											change?.customerAccurateId || ""
										);
									}}
									name="customer"
									value={values.customer}
									options={optionCustomer}
									getOptionLabel={(option: options) =>
										option.labelStr as string
									}
									getOptionValue={(option: options) =>
										option.labelStr as string
									}
									noOptionsMessage={() =>
										"Ketik Nama Pelanggan"
									}
									placeholder="Pilih Pelanggan"
									styles={{
										control(base, state) {
											return {
												...base,
												borderColor: errors.customer
													? "red"
													: "#cad1d7"
											};
										}
									}}
								/>
								<ErrorMessage
									name={"customer"}
									component="div"
									className="error-msg"
								/>
							</FormGroup>
							{
								!isAccurateDisabled && (
									<AccurateCustomerSelector
										customers={customersAccurate?.data || []}
										setSearchCustomer={setSearchCustomer}
										setFieldValue={setFieldValue}
										values={values}
										errors={errors}
										defaultAccurateCustomer={
											defaultAccurateCustomer
										}
										isLoading={
											customerListLoading || customerDetailLoading
										}
									/>
								)
							}
							{/* <FormGroup>
								<Label htmlFor="searchCustomer">
									Pelanggan Accurate *
								</Label>

								<Select
									options={customersAccurate?.data}
									components={{ Option }}
									getOptionLabel={(
										option: CustomerAccurate
									) => option.name}
									getOptionValue={(
										option: CustomerAccurate
									) => option.customerNo}
									onInputChange={(inputValue: string) => {
										setSearchCustomer(inputValue);
									}}
									onChange={(e) => {
										setFieldValue("customerAccurate", e);
									}}
									isMulti={false}
									value={values.customerAccurate}
									defaultValue={defaultAccurateCustomer}
									isDisabled={!values.customer}
									styles={{
										control(base, state) {
											return {
												...base,
												borderColor:
													errors.customerAccurate
														? "red"
														: "#cad1d7"
											};
										}
									}}
									isLoading={
										customerListLoading ||
										customerDetailLoading
									}
								/>

								<ErrorMessage
									name={"customerAccurate"}
									component="div"
									className="error-msg"
								/>
							</FormGroup> */}
							<FormGroup>
								<Label htmlFor="amount">Jumlah *</Label>
								{/* <Input
									name="amount"
									type="number"
									invalid={!!errors.amount}
									onChange={handleChange}
									//setSearchCustomerName
									value={values.amount}
									placeholder={"Jumlah Pembayaran"}
								/> */}
								<CurrencyInput
									name="amount"
									placeholder="Jumlah Pembayaran"
									decimalsLimit={2}
									onValueChange={(value) =>
										// console.log(value, name, values)
										setFieldValue("amount", value)
									}
									prefix="Rp. "
									className="form-control"
									style={{
										borderColor: errors.amount ? "red" : ""
									}}
								/>
								<ErrorMessage
									name={"amount"}
									component="div"
									className="error-msg"
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="paymentDate">
									Tanggal Pembayaran
								</Label>
								<Input
									name="paymentDate"
									type="date"
									invalid={!!errors.paymentDate}
									onChange={handleChange}
									dateFormat="DD/MM/YYYY"
									locale="id id"
									value={values.paymentDate}
									placeholder={"Tanggal Pembayaran"}

									// min={new Date().toISOString().split("T")[0]}
								/>

								<ErrorMessage
									name={"paymentDate"}
									component="div"
									className="error-msg"
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="paymentFile">
									File Pembayaran
								</Label>
								<Input
									name="paymentFile"
									type="file"
									invalid={!!errors.paymentFile}
									onChange={(e) => {
										console.log(e, "e");

										// handleChange(e);
										if (e.target?.files) {
											setFieldValue(
												"paymentFile",
												e.target.files[0]
											);
										}
									}}
									// value={values.paymentFile}
									placeholder={"File Pembayaran"}
								/>

								<ErrorMessage
									name={"paymentFile"}
									component="div"
									className="error-msg"
								/>
							</FormGroup>

							<FormGroup>
								<Label htmlFor="description">Keterangan</Label>
								<Input
									name="description"
									type="textarea"
									invalid={!!errors.description}
									onChange={handleChange}
									// value={values.description}
									placeholder={"Keterangan Pembayaran"}
								/>

								<ErrorMessage
									name={"description"}
									component="div"
									className="error-msg"
								/>
							</FormGroup>

							<div className="modal-custom-footer">
								<LoadingButton
									color="error"
									onClick={() => {
										localDialog.onClose();
										setTimeout(() => {
											setPayload({});
											localDialog.onClose();
										});
									}}
									loading={isSubmitting}
									disabled={isSubmitting}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Batal
									</span>
								</LoadingButton>
								<LoadingButton
									color="error"
									variant="contained"
									type="submit"
									loading={isSubmitting}
									disabled={isSubmitting}
									sx={{
										backgroundColor: "red",
										color: "white"
									}}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Buat
									</span>
								</LoadingButton>
							</div>
						</Form>
					);
				}}
			</Formik>
		</LocalDialog>
	);
};

export default CreatePayment;
