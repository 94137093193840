import BCard from "components/B/card";
import React, { useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { HubService, OrderService } from "api/axios";
import { BTable, Loading, LocalDialog } from "components";
import { Badge, Button, Card, CardBody, Row, Table } from "reactstrap";
import { getPriceFormatted } from "helper/product";
import { ChevronLeft } from "react-bootstrap-icons";
import { formatComma, formatIDR, getStatusColor } from "helper";
import BStatus from "components/B/status";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import { getRouteName, routeMapping } from "routes";
import { LoadingButton } from "@mui/lab";

const PODetail = () => {
	const location = useLocation();
	const permission = useAppSelector((state) => state.user.permission);
	const poPermission = useMemo(() => {
		const routeName = getRouteName(location.pathname, routeMapping);

		if (routeName) {
			return permission[routeName];
		}
		return {};
	}, [location, permission]);
	const navigate = useNavigate();
	const params = useParams();
	const id = params?.id;
	const [projectDetails, setProjectDetails] = React.useState<any[]>([]);
	const [files, setFiles] = React.useState<any[]>([]);
	const [summary, setSummary] = React.useState<any[]>([]);
	const [updates, setUpdates] = React.useState<any[]>([]);
	const [sphNumber, setSphNumber] = React.useState<string>("");
	const [products, setProducts] = React.useState<any[]>([]);
	const [status, setStatus] = React.useState<string>("");
	const [isReject, setIsReject] = React.useState({ isOpen: false });

	const dispatch = useAppDispatch();
	const { isLoading, refetch, data } = useQuery<ApiOnePO, ApiError>(
		["query-quotations"],
		async () => {
			return await OrderService.getOnePO(id ?? "");
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const isCompany = !!res?.data?.Project?.companyName;
				const isCustomer = !!res?.data?.Project?.Customer;
				setSphNumber(res.data.number);
				setStatus(res.data.status);

				try {
					const products = res?.data.PoProducts.map((el) => {
						const quantity = el.requestedQuantity;
						const totalPriceProduct =
							quantity * el.ReqProduct.offeringPrice;
						// let totalPrice = el.totalPrice;
						// for (const poProd of res.data.PoProducts) {
						// 	if (poProd.ReqProduct.id === el.id) {
						// 		quantity = poProd.ReqProduct.requestedQuantity;
						// 		totalPrice =
						// 			quantity * el.ReqProduct.offeringPrice;
						// 	}
						// }
						return {
							...el,
							quantity,
							totalPriceProduct
						};
					});
					setProducts(products);
				} catch (e) {
					//
				}

				try {
					const projectDetails = [
						(!isCompany && !isCustomer) ||
						(isCompany && !isCustomer)
							? {
									type: "noCustomer"
							  }
							: null,
						isCustomer && {
							name: "Tipe Pelanggan",
							type: "customerTypeChip",
							data:
								res?.data?.Project.Customer?.type === "COMPANY"
									? "PERUSAHAAN"
									: res?.data?.Project.Customer?.type
						},
						isCompany && !isCustomer
							? {
									name: "Tipe Pelanggan",
									type: "customerType",
									data: "PERUSAHAAN"
							  }
							: null,
						isCustomer
							? {
									name: "Nama Pelanggan",
									data:
										res?.data?.Project.Customer
											?.displayName ?? "-"
							  }
							: null,
						{
							name: "Nama Proyek",
							data: res?.data.Project.name
						},
						isCompany
							? {
									name: "Perusahaan",
									data: res?.data?.Project?.Company
										?.displayName
							  }
							: null,
						{
							name: "PIC Utama",
							type: "pic",
							data: {
								name: res?.data.Project.mainPic.name,
								position: res?.data?.Project?.mainPic?.position,
								phone: res?.data?.Project?.mainPic?.phone,
								email: res?.data?.Project?.mainPic?.email
							}
						},
						res?.data?.Project?.Pics.length > 1 && {
							name: "PICs",
							type: "pics",
							data: {
								pics: res?.data?.Project?.Pics,
								mainPIC: res?.data?.Project?.mainPic
							}
						},
						{
							name: "Alamat Penagihan",
							type: "address",
							data: {
								line1: res?.data?.Project?.BillingAddress
									?.line1,
								lat: res?.data?.Project?.BillingAddress?.lat,
								lon: res?.data?.Project?.BillingAddress?.lon
							}
						},
						{
							name: "Alamat Pengiriman",
							type: "address",
							data: {
								line1: res?.data?.Project?.ShippingAddress
									?.line1,
								lat: res?.data?.Project?.ShippingAddress?.lat,
								lon: res?.data?.Project?.ShippingAddress?.lon
							}
						}
					];
					setProjectDetails(projectDetails);
				} catch (e) {
					//
				}

				try {
					const files =
						res?.data?.PurchaseOrderDocs?.map((v, idx) => {
							return {
								name: v?.type,
								type: "file",
								data: v?.File
							};
						}) ?? [];
					setFiles(files);
				} catch (e) {
					//
				}

				try {
					const totalPriceProduct = res.data.PoProducts.map((el) => {
						return (
							el.ReqProduct.offeringPrice * el.requestedQuantity
						);
					});
					const totalPriceSum = totalPriceProduct.reduce(
						(acc, currentValue) => {
							return acc + currentValue;
						},
						0
					);
					const totalDeliveryPrice =
						res?.data?.QuotationLetter.QuotationRequest
							?.totalDeliveryPrice;
					const summary = [
						{
							name: "Nama Sales",
							type: "text",
							data: res?.data?.Project?.User?.fullName
						},
						{
							name: "Tol",
							type: "boolean",
							data: res?.data?.QuotationLetter.QuotationRequest
								?.viaTol
						},
						{
							name: "Tipe Pembayaran",
							type: "chips",
							data: [
								res?.data?.QuotationLetter.QuotationRequest
									?.paymentType == "CREDIT"
									? {
											text: "Credit",
											color: "green",
											textColor: "white"
									  }
									: {
											text: "Cash",
											color: "blue",
											textColor: "white"
									  }
							]
						},
						res?.data.isExpired
							? {
									name: "Tanggal Kadaluwarsa",
									type: "date",
									data: new Date()
							  }
							: null,
						res?.data?.QuotationLetter.QuotationRequest?.distance
							? {
									name: "Jarak",
									data: `${res?.data?.QuotationLetter.QuotationRequest?.distance} km`
							  }
							: null,
						{
							type: "line"
						},
						{
							name: "Biaya Mobilisasi",
							type: "textBold",
							format: "bold"
						},

						{
							name: "5-6",
							type: "price",
							data: res?.data?.fiveToSix
						},
						{
							name: "<5",
							type: "price",
							data: res?.data?.lessThanFive
						},

						{
							type: "line"
						},
						totalDeliveryPrice
							? {
									name: "Sub total",
									type: "price",
									data: res?.data?.totalPrice
							  }
							: null,
						totalDeliveryPrice
							? {
									name: "Pengiriman (Kuantitas dibawah 7m3)",
									type: "price",
									data: totalDeliveryPrice
							  }
							: null,
						totalDeliveryPrice
							? {
									name: "Jumlah Keseluruhan",
									type: "price",
									data:
										res?.data?.totalPrice +
										(totalDeliveryPrice ?? 0),
									format: "bold"
							  }
							: {
									name: "Jumlah",
									type: "price",
									data: totalPriceSum,
									format: "bold"
							  }
					];
					setSummary(summary);

					// const npwp = res.data.
					const updates = [
						{
							name: "Foto KTP",
							type: "photo",
							data: res.data.Project.ProjectDocs.find(
								(el) => el.Document.name === "Foto KTP"
							)
						},
						{
							name: "Foto NPWP",
							type: "photo",
							data: res.data.Project.ProjectDocs.find(
								(el) => el.Document.name === "Foto NPWP"
							)
						}
					];

					setUpdates(updates);
				} catch (e) {
					//
				}
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const [localDialog, setLocalDialog] = React.useState({
		isOpen: false,
		onClose: () => setLocalDialog({ ...localDialog, isOpen: false }),
		onOpen: () => setLocalDialog({ ...localDialog, isOpen: true })
	});

	const patchStatus = useMutation(
		async (status: "CONFIRMED" | "DECLINED") => {
			return await OrderService.updatePO(id as string, status);
		},
		{
			onSuccess: (response) => {
				refetch();
				dispatch(
					openAlert({
						body: "Berhasil Merubah Status PO",
						color: "success"
					})
				);
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const handleButton = (status: "CONFIRMED" | "DECLINED") => () => {
		patchStatus.mutate(status);
		setIsReject({ isOpen: false });
	};

	const { data: skuSpec } = useQuery<
		ApiResponsePlain<SkuSpecification[]>,
		ApiError
	>(
		["sku-specification", true],
		async () => {
			return await HubService.getSKUSpecification();
		},
		{
			enabled: true,
			onSuccess(data) {
				console.log(data.data, "ini data skuSpec");
			}
		}
	);

	const castedElement = skuSpec?.data?.find((spec) => spec.name === "casted-element");
	// const productType = skuSpec?.data?.find((spec) => spec.name === "product-type");
	// const aggregateCombination = skuSpec?.data?.find((spec) => spec.name === "aggregate-combination");
	const cementitiousComposition = skuSpec?.data?.find((spec) => spec.name === "cementitious-component");

	if (isLoading || patchStatus.isLoading) {
		return <Loading />;
	}

	return (
		<div className="p-4 pt-6">
			<div className="d-flex flex-row align-items-center mb-2">
				<div
					className="b-back-btn border mr-3"
					onClick={() => {
						navigate(-1);
					}}
				>
					<ChevronLeft />
				</div>
				<h1 className="mb-0 mr-2">{data?.data.customerNumber}</h1>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						flex: 1,
						alignItems: "center"
					}}
				>
					<BStatus>{status}</BStatus>
					{(status === "SUBMITTED" || status === "CREATED") &&
					poPermission &&
					poPermission.update ? (
						<div>
							<Button
								onClick={handleButton("CONFIRMED")}
								size="sm"
								color="success"
								className="confirm-button approve-button"
							>
								Setujui
							</Button>
							<Button
								onClick={() => {
									setIsReject({ isOpen: true });
								}}
								size="sm"
								color="danger"
								className="confirm-button decline-button ml-1"
							>
								Tolak
							</Button>
						</div>
					) : null}
				</div>
			</div>
			<div className="d-flex flex-column flex-lg-row">
				<div className="d-flex flex-column mb-3" style={{ flex: 0.7, width: "75%" }}>
					<Card className="">
						<CardBody>
							<Table responsive hover striped>
								<thead>
									<tr>
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											No
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											Nama
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											Slump
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											Metode
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											Element Casted
										</th>
										{/* <th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											Jenis Beton
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											Aggregate Combination
										</th> */}
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											Cementitious Composition
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "right" }}
										>
											Harga
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "center" }}
										>
											Kuantitas
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "right" }}
										>
											Jumlah
										</th>
									</tr>
								</thead>
								<tbody>
									{products?.map((item, idx) => (
										<tr key={idx}>
											<td style={{ textAlign: "left" }}>
												{idx + 1}
											</td>
											<td style={{ textAlign: "left" }}>
												{item?.ReqProduct.product.name}  { ["B" , "D"].includes(item?.ReqProduct?.specifications?.["aggregate-combination"]) ? " - Screening" : null}
											</td>
											<td style={{ textAlign: "left" }}>
												{ item?.ReqProduct?.specifications?.["slump-a"] ? item?.ReqProduct?.specifications?.["slump-a"] + " ± " + item?.ReqProduct?.specifications?.["slump-b"]:
													item?.ReqProduct?.Product?.properties
														?.slump
												}
											</td>
											<td style={{ textAlign: "left" }}>
												{item?.withPump
													? "Dengan Pompa"
													: "Tanpa Pompa"}
											</td>
											<td style={{ textAlign: "left" }}>
												{item?.ReqProduct?.specifications && castedElement ? castedElement.values[item?.ReqProduct?.specifications?.["casted-element"]] : null}
											</td>
											{/* <td style={{ textAlign: "left" }}>
												{item?.ReqProduct?.specifications && productType ? productType.values[item?.ReqProduct?.specifications?.["product-type"]] : null}
											</td>
											<td style={{ textAlign: "left" }}>
												{item?.ReqProduct?.specifications && aggregateCombination ? aggregateCombination.values[item?.ReqProduct?.specifications?.["aggregate-combination"]] : null}
											</td> */}
											<td style={{ textAlign: "left" }}>
												{item?.ReqProduct?.specifications && cementitiousComposition ? cementitiousComposition.values[item?.ReqProduct?.specifications?.["cementitious-component"]] : null}
											</td>
											<td style={{ textAlign: "right" }}>
												{getPriceFormatted(
													item?.ReqProduct
														.offeringPrice,
													"Rp "
												)}
											</td>
											<td style={{ textAlign: "center" }}>
												{item?.quantity
													? formatComma(
															`${item.quantity} M³`
													  )
													: "0 M³"}
											</td>
											<td style={{ textAlign: "right" }}>
												{getPriceFormatted(
													item?.ReqProduct
														.offeringPrice *
														item?.quantity,
													"Rp "
												)}
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</CardBody>
					</Card>
					<BCard
						className="mt-3"
						title="Ringkasan PO"
						data={summary}
					/>
					<BCard
						className="mt-3"
						title="Dokumen Pelanggan"
						data={updates}
					/>
				</div>
				<div className="spacer-v" />
				<div className="d-flex flex-column" style={{ flex: 0.3 }}>
					<BCard title="Rincian Proyek" data={projectDetails} />
					<i className="bi bi-download"></i>
					<BCard className="mt-3" title="Dokumen" data={files} />
				</div>
			</div>
			{/* Reject SO  */}
			<LocalDialog
				isOpen={isReject.isOpen}
				backdrop={"static"}
				header="Penolakan Purchase Order"
				onClose={() => {
					setIsReject({ isOpen: false });
				}}
			>
				<span>Yakin ingin menolak Purchase Order ini?</span>

				<div
					className="modal-custom-footer"
					style={{ marginTop: "1rem" }}
				>
					<LoadingButton
						color="error"
						disabled={isLoading}
						onClick={() => {
							setIsReject({ isOpen: false });
						}}
					>
						<span style={{ textTransform: "capitalize" }}>
							Batal
						</span>
					</LoadingButton>
					<LoadingButton
						color="error"
						variant="contained"
						onClick={handleButton("DECLINED")}
						loading={isLoading}
						disabled={isLoading}
						sx={{ backgroundColor: "red", color: "white" }}
					>
						<span style={{ textTransform: "capitalize" }}>
							Ubah
						</span>
					</LoadingButton>
				</div>
			</LocalDialog>
		</div>
	);
};

export default PODetail;
