import { CommonService, OrderService } from "api/axios";
import { BStatus, LocalDialog } from "components";
import BCard from "components/B/card";
import BForm from "components/B/form";
import { formatTime, handleResponseFile } from "helper";
import { useLocalDialog } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { ChevronLeft } from "react-bootstrap-icons";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardBody, CardSubtitle, Table } from "reactstrap";
import { openAlert } from "store/features/alert/AlertSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { parseISO } from "date-fns";
import LoadingButton from "@mui/lab/LoadingButton";
import { getRouteName, routeMapping } from "routes";
import MultipleFile from "components/B/MultipleFile";
import moment from "moment";

function convertTimeToISOString(timeString: any) {
	const currentDate = new Date();
	const [hours, minutes] = timeString.split(":");
	currentDate.setHours(parseInt(hours));
	currentDate.setMinutes(parseInt(minutes));

	const isoString = currentDate.toISOString();

	return isoString;
}

export default function WODetil() {
	// Permission to edit and upload WO
	const location = useLocation();
	const permission = useAppSelector((state) => state.user.permission);
	const workOrderPermission = useMemo(() => {
		const routeName = getRouteName(location.pathname, routeMapping);

		if (routeName) {
			return permission[routeName];
		}
		return {};
	}, [location, permission]);

	const { userRoles } = useAppSelector((state) => state.user);
	const navigate = useNavigate();
	const params = useParams();
	const id = params?.id;
	const dispatch = useAppDispatch();
	const [status, setStatus] = React.useState<string>("");
	const [hasDo, setHasDo] = React.useState<boolean>();
	// States
	const [projectDetails, setProjectDetails] = useState<any[]>([]);
	const [summary, setSummary] = useState<any[]>([]);
	const [moistContent, setMoistContent] = useState<any[]>([]);
	const [files, setFiles] = React.useState<any[]>([]);

	// const [responseUpload, setResponseUpload] = useState<any>([]);

	const { isLoading, refetch, data } = useQuery<ApiOneWO, ApiError>(
		["query-products"],
		async () => {
			return await OrderService.getOneWO(id ?? "");
		},
		{
			enabled: true,
			onSuccess: (res) => {
				try {
					const isCompany =
						!!res?.DeliveryOrder?.Project?.companyName;
					const isCustomer = !!res?.DeliveryOrder?.Project?.Customer;

					const projectDetails = [
						(!isCompany && !isCustomer) ||
						(isCompany && !isCustomer)
							? {
									type: "noCustomer"
							  }
							: null,
						isCustomer && {
							name: "Tipe Pelanggan",
							type: "customerTypeChip",
							data:
								res?.DeliveryOrder?.Project?.Customer?.type ===
								"COMPANY"
									? "PERUSAHAAN"
									: res?.DeliveryOrder?.Project?.Customer
											?.type
						},
						isCompany && !isCustomer
							? {
									name: "Tipe Pelanggan",
									type: "customerType",
									data: "PERUSAHAAN"
							  }
							: null,
						isCustomer
							? {
									name: "Nama Pelanggan",
									data:
										res?.DeliveryOrder?.Project?.Customer
											?.displayName ?? "-"
							  }
							: null,
						{
							name: "Nama Proyek",
							data: res?.DeliveryOrder?.Project?.name
						},
						isCompany
							? {
									name: "Perusahaan",
									data: res?.DeliveryOrder?.Project?.Company
										?.displayName
							  }
							: null,
						{
							name: "PIC Utama",
							type: "pic",
							data: {
								name: res?.DeliveryOrder?.Project?.mainPic
									?.name,
								position:
									res?.DeliveryOrder?.Project?.mainPic
										?.position,
								phone: res?.DeliveryOrder?.Project?.mainPic
									?.phone,
								email: res?.DeliveryOrder?.Project?.mainPic
									?.email
							}
						},
						res?.DeliveryOrder?.Project?.Pics.length > 1 && {
							name: "PICs",
							type: "pics",
							data: {
								pics: res?.DeliveryOrder?.Project?.Pics,
								mainPIC: res?.DeliveryOrder?.Project?.mainPic
							}
						},
						{
							name: "Alamat Penagihan",
							type: "address",
							data: {
								line1: res?.DeliveryOrder?.Project
									?.BillingAddress?.line1,
								lat: res?.DeliveryOrder?.Project?.BillingAddress
									?.lat,
								lon: res?.DeliveryOrder?.Project?.BillingAddress
									?.lon
							}
						},
						{
							name: "Alamat Pengiriman",
							type: "address",
							data: {
								line1: res?.DeliveryOrder?.Project
									?.ShippingAddress?.line1,
								lat: res?.DeliveryOrder?.Project
									?.ShippingAddress?.lat,
								lon: res?.DeliveryOrder?.Project
									?.ShippingAddress?.lon
							}
						}
					];
					setProjectDetails(projectDetails);
				} catch (e) {
					//
				}

				try {
					setPayloadWO({
						startDate: res?.startDate,
						endDate: res?.endDate,
						numberOfBatches: res?.numberOfBatches,
						quantity: res?.quantity,
						notes: res?.notes
					});

					setHasFile(res?.WorkOrderFiles?.length > 0 ? true : false);
					setListFiles(res?.WorkOrderFiles);
					setInitialListFiles(res?.WorkOrderFiles);
				} catch (error) {
					console.log(error);
				}

				try {
					const summary = [
						{
							name: "SKU",
							type: "text",
							data: res?.SKU?.sku ?? "-"
						},
						!userRoles.includes("bpo") && {
							name: "Mix Design",
							type: "text",
							data: res?.MixDesign?.name ?? "-"
						},
						{
							name: "Waktu Mulai",
							type: "text",
							data: formatTime(res?.startDate)
						},
						{
							name: "Waktu Selesai",
							type: "text",
							data: formatTime(res?.endDate)
						},
						{
							name: "Kuantitas",
							type: "text",
							data: res?.quantity ?? "-"
						},
						{
							name: "Jumlah Batch",
							type: "text",
							data: res?.numberOfBatches ?? "-"
						},
						{
							name: "Notes",
							type: "text",
							data: res?.notes ?? "-"
						},
						{
							name: "No. TM",
							type: "text",
							data: `${
								res?.DeliveryOrder?.Vehicle?.internalId ??
								res?.DeliveryOrder?.Vehicle?.internal_id ??
								"-"
							} / ${
								res?.DeliveryOrder?.Vehicle?.plateNumber ??
								res?.DeliveryOrder?.Vehicle?.plate_number ??
								"-"
							}`
						},
						{
							name: "Nama Driver",
							type: "text",
							data: res?.DeliveryOrder?.User?.fullName ?? "-"
						},
						{
							name: "Tanggal Pengiriman",
							type: "text",
							data: moment(res?.date).format("yyyy-MM-DD")
						}
					];

					const moistureContent = [
						{
							name: "Pasir",
							type: "text",
							data: res?.mcPasir ? `${res?.mcPasir} %` : "-"
						},
						{
							name: "Abu Batu",
							type: "text",
							data: res?.mcAbuBatu ? `${res?.mcAbuBatu} %` : "-"
						},
						{
							name: "Split",
							type: "text",
							data: res?.mcSplit ? `${res?.mcSplit}  %` : "-"
						},
						{
							name: "Screening",
							type: "text",
							data: res?.mcScreening
								? `${res?.mcScreening} %`
								: "-"
						}
					];
					setSummary(summary);
					setMoistContent(moistureContent);
					setStatus(res?.status ?? "SUBMITTED");
					setHasDo(res?.DeliveryOrder ? true : false);
				} catch (error) {
					//
				}
				try {
					const files =
						res?.WorkOrderFiles?.map((v: any, idx: any) => {
							return {
								name: v?.type,
								type: "file",
								data: v?.file
							};
						}) ?? [];
					setFiles(files);
				} catch (e) {
					//
				}
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const [isOpen, setIsOpen] = useState(false);
	const [payloadWO, setPayloadWO] = useState<ApiWorkOrder>({});
	const { localDialog, setLocalDialog } = useLocalDialog();
	const [loading, setIsLoading] = useState(false);
	const [uploadFile, setUploadFile] = React.useState<any>([]);
	const [hasFile, setHasFile] = React.useState(false);
	// const [showFileForm, setShowFileForm] = React.useState(false);
	const [listFiles, setListFiles] = React.useState([]);
	const [initialListFiles, setInitialListFiles] = React.useState([]);
	const forms: ElementForm[] = React.useMemo(() => {
		const _form: ElementForm[] = [
			{
				label: "Waktu Mulai",
				type: "time",
				value: payloadWO?.startDate,
				onChange: (e: any) => {
					const value = e.target.value;

					// const [hoursStr, minutesStr] = value.split(":");
					// const today = new Date();
					// today.setHours(parseInt(hoursStr));
					// today.setMinutes(parseInt(minutesStr));

					setPayloadWO((prevPayload) => {
						return {
							...prevPayload,
							startDate: value
						};
					});
				},
				placeholder: "Masukan jam saat memulai batching",
				required: true
			},
			{
				label: "Waktu Selesai",
				type: "time",
				value: payloadWO?.endDate,
				onChange: (e: any) => {
					const value = e.target.value;

					// const [hoursStr, minutesStr] = value.split(":");
					// const today = new Date();
					// today.setHours(parseInt(hoursStr));
					// today.setMinutes(parseInt(minutesStr));

					setPayloadWO((prevPayload) => {
						return {
							...prevPayload,
							endDate: value
						};
					});
				},
				placeholder: "Masukan jam saat selesai batching",
				required: true
			},
			{
				label: "Jumlah Batch",
				type: "number",
				value: payloadWO?.numberOfBatches,
				onChange: (e: any) => {
					setPayloadWO((curr) => {
						return {
							...curr,
							numberOfBatches: e.target.value
						};
					});
				},
				placeholder: "cth. 5",
				required: true
			},
			{
				label: "Kuantitas",
				type: "number",
				value: payloadWO?.quantity,
				onChange: (e: any) => {
					setPayloadWO((curr) => {
						return {
							...curr,
							quantity: e.target.value
						};
					});
				},
				placeholder: "Masukan Kuantitas",
				required: true
			},
			{
				label: "Notes",
				type: "text",
				value: payloadWO?.notes,
				onChange: (e: any) => {
					setPayloadWO((curr) => {
						return {
							...curr,
							notes: e.target.value
						};
					});
				},
				placeholder: "Tambahkan catatan"
				// required: true
			}
			// {
			// 	label: "Hasil Produksi",
			// 	type: "file",
			// 	onChange: (e: any) => {
			// 		setUploadFile(e.target.files[0]);
			// 	},
			// 	placeholder: "Upload dokumen WO",
			// 	required: true
			// }
		];
		return _form;
	}, [payloadWO]);

	const { mutateAsync: sendFile, isLoading: loadingUpload } = useMutation<
		ApiResponseFiles,
		ApiError,
		FormData,
		ApiError
	>(async (_files: FormData) => {
		const res = await CommonService.uploadFile(_files);
		return res;
	});

	const { mutateAsync: uploadWOFile } = useMutation(async (data: any) => {
		return await OrderService.filesWO(id as string, data);
	});

	const { mutateAsync, isLoading: loadingCreate } = useMutation<
		ApiResponseWO,
		ApiError,
		ApiWorkOrder,
		ApiError
	>(
		async (data: ApiWorkOrder) => {
			return await OrderService.editWO(id as string, data);
		},
		{
			onSuccess: (res) => {
				localDialog.onClose();
				dispatch(
					openAlert({
						body: res.message,
						color: "success"
					})
				);
				setUploadFile([]);
				refetch();
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const patchStatus = useMutation(
		async (status: "CANCELLED") => {
			return await OrderService.patchWO(id as string, status);
		},
		{
			onSuccess: (response) => {
				refetch();
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const handleButton = React.useCallback(
		(status: "CANCELLED" | "COMPLETED") => (e: any) => {
			e.preventDefault();
			if (status === "COMPLETED") {
				localDialog.onOpen();
				return;
			}
			patchStatus.mutate(status);
		},
		[data]
	);

	const onSubmit = async () => {
		if (status === "CANCELLED") {
			dispatch(
				openAlert({
					body: "Work Order Yang Di cancel tidak dapat di ubah",
					color: "danger"
				})
			);
			return;
		}

		if (!listFiles || (!listFiles.length && !hasFile)) {
			dispatch(
				openAlert({
					body: "Harus upload bukti",
					color: "danger"
				})
			);
			return;
		}

		if (!payloadWO.startDate || payloadWO.startDate === "_") {
			dispatch(
				openAlert({
					body: "Waktu mulai diperlukan",
					color: "danger"
				})
			);
			return;
		}
		if (!payloadWO.endDate || payloadWO.endDate === "_") {
			dispatch(
				openAlert({
					body: "Waktu selesai diperlukan",
					color: "danger"
				})
			);
			return;
		}
		if (!payloadWO.numberOfBatches) {
			dispatch(
				openAlert({
					body: "Jumlah batch diperlukan",
					color: "danger"
				})
			);
			return;
		}
		if (!payloadWO.quantity) {
			dispatch(
				openAlert({
					body: "Kuantitas diperlukan",
					color: "danger"
				})
			);
			return;
		}

		const _payload = {
			startDate: convertTimeToISOString(payloadWO?.startDate),
			endDate: convertTimeToISOString(payloadWO?.endDate),
			numberOfBatches: payloadWO?.numberOfBatches,
			quantity: payloadWO?.quantity,
			notes: payloadWO?.notes,
			status: "COMPLETED"
		};

		const payloadWoFile = {
			files: listFiles
		};

		await mutateAsync(_payload);
		await uploadWOFile(payloadWoFile);
		refetch();
	};

	return (
		<>
			<div className="p-4 pt-6">
				<div className="d-flex flex-row align-items-center mb-2">
					<div
						className="b-back-btn border mr-3"
						onClick={() => {
							navigate(-1);
						}}
					>
						<ChevronLeft />
					</div>
					<h1 className="mb-0 mr-2">{data?.number}</h1>
					<div
						style={{
							display: "flex",
							// justifyContent: "space-between",
							flex: 1,
							alignItems: "center",
							marginLeft: "1rem",
							gap: "1rem"
						}}
					>
						<BStatus>{status}</BStatus>
						{status === "" ||
						(status !== "COMPLETED" &&
							!hasDo &&
							status !== "CANCELLED" &&
							workOrderPermission &&
							workOrderPermission.update) ? (
							<Button
								onClick={handleButton("CANCELLED")}
								size="sm"
								color="danger"
								className="confirm-button approve-button"
								style={{
									position: "absolute",
									right: "5rem"
								}}
							>
								Batal
							</Button>
						) : null}

						{workOrderPermission && workOrderPermission.update ? (
							<Button
								onClick={() => {
									localDialog.onOpen();
								}}
								size="sm"
								color="warning"
								className="confirm-button approve-button"
								style={{
									position: "absolute",
									right: "1.5rem"
								}}
							>
								{localDialog.isOpen ? "Simpan" : "Ubah"}
							</Button>
						) : null}
					</div>
				</div>
				{!userRoles.includes("bpo") && (
					<Card>
						<CardSubtitle className="mt-2 ml-3 text-bold text-sm">
							Rincian Mix Design
						</CardSubtitle>
						<CardBody>
							<Table responsive hover striped>
								<thead>
									<tr>
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											Nama
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											Status
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											GGBFS
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											Semen
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "right" }}
										>
											Fly Ash
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "center" }}
										>
											Pasir
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "right" }}
										>
											Abu Batu
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "right" }}
										>
											Split
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "right" }}
										>
											Screening
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "right" }}
										>
											Air
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "right" }}
										>
											Type D
										</th>
										<th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											Type F
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="text-center">
											{data?.MixDesign?.name}
										</td>
										<td className="text-center">
											{data?.MixDesign?.status}
										</td>
										<td className="text-center">
											{data?.MixDesign?.ggbfs}
										</td>
										<td className="text-center">
											{data?.MixDesign?.gbfs}
										</td>
										<td className="text-center">
											{data?.MixDesign?.semen}
										</td>
										<td className="text-center">
											{data?.MixDesign?.flyAsh}
										</td>
										<td className="text-center">
											{data?.MixDesign?.pasir}
										</td>
										<td className="text-center">
											{data?.MixDesign?.abuBatu}
										</td>
										<td className="text-center">
											{data?.MixDesign?.split}
										</td>
										<td className="text-center">
											{data?.MixDesign?.screening}
										</td>
										<td className="text-center">
											{data?.MixDesign?.air}
										</td>
										<td className="text-center">
											{data?.MixDesign?.typeD}
										</td>
										<td className="text-center">
											{data?.MixDesign?.typeF}
										</td>
										<td className="text-center">
											{data?.MixDesign?.washedMSand}
										</td>
										<td className="text-center">
											{data?.MixDesign?.splitFiveTen}
										</td>
										<td className="text-center">
											{data?.MixDesign?.splitTenTwenty}
										</td>
										<td className="text-center">
											{
												data?.MixDesign
													?.splitFiveTwentyFive
											}
										</td>

										<td className="text-center">
											{data?.MixDesign?.typeF}
										</td>
									</tr>
								</tbody>
							</Table>
						</CardBody>
					</Card>
				)}

				<div className="d-flex flex-column flex-lg-row">
					<div
						className="d-flex flex-column mb-3"
						style={{ flex: 0.7 }}
					>
						<BCard
							className="mt-3"
							title="Ringkasan WO"
							data={summary}
						/>

						<BCard
							className="mt-3"
							title="Moisture Content"
							data={moistContent}
						/>

						{/* <BCard
							className="mt-3"
							title="Signed SO"
							data={signedSo}
						/> */}
					</div>
					<div className="spacer-v" />
					<div
						className="d-flex flex-column mt-3"
						style={{ flex: 0.3 }}
					>
						{!userRoles.includes("bpo") && (
							<BCard
								title="Rincian Proyek"
								data={projectDetails}
							/>
						)}

						<i className="bi bi-download"></i>
						<BCard
							className="mt-3"
							title="Hasil Produksi"
							data={files}
						/>
					</div>
				</div>
			</div>

			<LocalDialog
				isOpen={localDialog.isOpen}
				backdrop={"static"}
				header="Ubah Work Order"
				// loading={loading}
				// onClick={onSubmit}
				onClose={() => {
					localDialog.onClose();
					setIsOpen(false);
					setListFiles([...initialListFiles]);
					setUploadFile([]);
				}}
			>
				<BForm forms={forms} />
				<MultipleFile
					// refetch={refetch}
					listFiles={listFiles}
					setFiles={setFiles}
					uploadFile={uploadFile}
					setUploadFile={setUploadFile}
					setListFiles={setListFiles}
				/>
				<div className="modal-custom-footer">
					<LoadingButton
						color="error"
						onClick={() => {
							localDialog.onClose();
							setIsOpen(false);
							setListFiles([...initialListFiles]);
							setUploadFile([]);
						}}
						// loading={loadingCreate}
						// disabled={loadingCreate}
					>
						<span style={{ textTransform: "capitalize" }}>
							Batal
						</span>
					</LoadingButton>
					<LoadingButton
						color="warning"
						variant="contained"
						type="submit"
						onClick={onSubmit}
						loading={loadingCreate}
						disabled={loadingCreate}
						// sx={{ backgroundColor: "red", color: "white" }}
					>
						<span style={{ textTransform: "capitalize" }}>
							Ubah
						</span>
					</LoadingButton>
				</div>
			</LocalDialog>
		</>
	);
}
