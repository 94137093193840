import React, { Ref, useCallback, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import MapGL, { MapRef } from "react-map-gl";
import MapboxTraffic from "@mapbox/mapbox-gl-traffic";
import _debounce from "lodash/debounce";
import "mapbox-gl/dist/mapbox-gl.css";
const MapBoxToken = "pk.eyJ1IjoiYWJ1ZGlhciIsImEiOiJja2ZsMzI2NWgwZnJ4MnNuZGV2cmVjNDZlIn0.EzNtpBvxScpZVaLkv9egWQ";

const applyToArray = (func: any, array: any[]) => func.apply(Math, array);
const containerStyle = {
	width: "100%",
	minHeight: "480px",
	height: "100%",
};

interface IItem {
	lon: number;
	lat: number;
	label?: string;
}

interface IProps {
	className?: string;
	items?: IItem[];
	children?: any;
	traffic?: boolean;
	onMapLoad?: any;
}

export default function BGLMap({
	className,
	items,
	children,
	traffic,
	onMapLoad
}: IProps = {
		items: []
	}) {
	const mapRef = useRef<MapRef>();
	const containerRef = useRef<HTMLDivElement>();
	const [map, setMap] = useState<google.maps.Map | null>(null);
	const [viewState, setViewState] = useState<any>({
		longitude: 106.5957038,
		latitude: -6.2780033,
		zoom: 10,
	});

	const resize = () => {
		if (mapRef?.current) {
			mapRef?.current?.resize();
		}
		fitBounds();
		console.log("resize");
	};

	const dResize = useCallback(_debounce(resize, 100), []);

	useEffect(() => {
		setTimeout(() => {
			fitBounds();
		}, 500);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			fitBounds();
		}, 500);
	}, [items?.length || 0]);

	const fitBounds = () => {
		if (items?.length) {
			setViewState((viewState: any) => {
				const _viewState = JSON.parse(JSON.stringify(viewState));
				if (items?.length) {
					const bounds = getBounds();
					mapRef?.current?.fitBounds(bounds, {
						padding: {
							left: 100,
							top: 100,
							right: 100,
							bottom: 100,
						},
						maxZoom: 16
					});
				}
				return _viewState;
			});
		}
	};

	const getBounds = () => {
		const pointsLong: number[] = [];
		const pointsLat: number[] = [];
		for (const item of (items || [])) {
			if (item && item?.lon && item?.lat) {
				pointsLong.push(item?.lon);
				pointsLat.push(item?.lat);
			}
		}
		const bounds: mapboxgl.LngLatBoundsLike = [
			[applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
			[applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)]
		];
		console.log(bounds, "bounds");
		return bounds;
	};

	const onLoad = useCallback(function callback(map: any) {
		console.log({ map, mapRef: mapRef?.current });
		fitBounds();
		setMap(map);

		const toggleTraffic = mapRef?.current?.addControl(new MapboxTraffic({
			// showTrafficButton: true,
		}));
		console.log({ toggleTraffic });
		const icon: any = document?.querySelector(
			"[src*=\"/src/assets/tm_icon.svg\"]"
		);
		if (icon) {
			icon.style.transition = "transform 1s linear";
		}
		const ro = new ResizeObserver((entries: ResizeObserverEntry[]) => {
			window.requestAnimationFrame((): void | undefined => {
				if (!Array.isArray(entries) || !entries.length) {
					return;
				}
				dResize();
			});
		});

		// Observe the map div container.
		ro.observe(containerRef?.current as Element);

		if (onMapLoad) onMapLoad({
			map, ref: mapRef
		});
	}, []);

	return (
		<div className={[styles.mapContainer, className].join(" ")}
			ref={containerRef as Ref<HTMLDivElement> | undefined}>
			<MapGL
				ref={mapRef as Ref<MapRef> | undefined}
				onLoad={onLoad}
				mapboxAccessToken={MapBoxToken}
				style={containerStyle}
				mapStyle="mapbox://styles/mapbox/streets-v9"
				initialViewState={viewState}
				antialias={true}
			>
				{children}
			</MapGL>
		</div>
	);
}
