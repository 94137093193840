import React, { useMemo, useState } from "react";
import { DashboardService } from "api/axios";
import { Mtd } from "components";
import { MonthStartEnd } from "helper/WeekNumberToDateRange";
import moment from "moment";
import { useQuery } from "react-query";

const thisMonth = moment().endOf("month").format("YYYY-MM-DD");
const { startDate: initialMonthStartDate, endDate: initialMonthEndDate } =
	MonthStartEnd(thisMonth, 0);
export default function ProductsMtd() {
	const [bestProducts, setBestProducts] =
		useState<BestSellingProductsChartData>(
			{} as BestSellingProductsChartData
		);

	useQuery(
		["sales-funnel"],
		async () => {
			return await DashboardService.bestSellingProduct({
				startDate: initialMonthStartDate,
				endDate: initialMonthEndDate,
				page: 1,
				size: 1
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				setBestProducts(res.data.data);
				// setTotalPages(res.data.data.totalPages);
			}
		}
	);

	const product = useMemo(() => {
		let value = "-";
		if (bestProducts.productsResponse) {
			if (bestProducts.productsResponse[0]) {
				value = `${bestProducts.productsResponse[0].display_name} , Terkirim = ${bestProducts.productsResponse[0].total_quantity} m³`;
			}
		}
		return value;
	}, [bestProducts]);

	return (
		<Mtd
			value={product}
			title={`Top Produk, ${moment(initialMonthStartDate).format(
				"MMM-YYYY"
			)}`}
		/>
	);
}
