import React from "react";
import {
	Col,
	Container,
	Form,
	FormGroup,
	Input,
	InputGroup,
	InputGroupText,
	Label,
	Row
} from "reactstrap";
import BDropdown from "./dropdown";
import Map from "./map";

const FormElement = (element: ElementForm) => {
	if (element.type === "switch") {
		return (
			<FormGroup switch>
				<Input
					type="switch"
					role="switch"
					onClick={element.onChange}
					checked={element.value as boolean}
				/>
				<Label check>Ya</Label>
			</FormGroup>
		);
	}

	if (element.type === "map") {
		return (
			<Container>
				<Map />
			</Container>
		);
	}

	if (element.type === "dropdown") {
		return <BDropdown {...element} />;
	}
	if (element.type === "autocomplete") {
		return <BDropdown {...element} />;
	}
	if (element.type === "pic") {
		const value = element.value as PICModel;
		return (
			<>
				<Row>
					<Col md="6">
						<InputGroup>
							<InputGroupText style={{ width: "4rem" }}>
								Nama
							</InputGroupText>
							<Input
								id={element.label}
								className="pl-3"
								placeholder="Tulis nama"
								type="text"
								value={value?.name as string}
								onChange={element.onChange("name")}
							/>
						</InputGroup>
					</Col>
					<Col md="6">
						<InputGroup>
							<InputGroupText style={{ width: "5rem" }}>
								Jabatan
							</InputGroupText>
							<Input
								id={element.label}
								className="pl-3"
								placeholder="Tulis jabatan"
								type="text"
								value={value?.position as string}
								onChange={element.onChange("position")}
							/>
						</InputGroup>
					</Col>
				</Row>
				<br />
				<Row>
					<Col md="6">
						<InputGroup>
							<InputGroupText style={{ width: "4rem" }}>
								+62
							</InputGroupText>
							<Input
								id={element.label}
								className="pl-3"
								placeholder="Tulis no hp"
								type="text"
								value={value?.phone as string}
								onChange={element.onChange("phone")}
							/>
						</InputGroup>
					</Col>
					<Col md="6">
						<InputGroup>
							<InputGroupText style={{ width: "5rem" }}>
								Email
							</InputGroupText>
							<Input
								id={element.label}
								className="pl-3"
								placeholder="Tulis email"
								type="text"
								value={value?.email as string}
								onChange={element.onChange("email")}
							/>
						</InputGroup>
					</Col>
				</Row>
			</>
		);
	}
	if (element.type === "quantity") {
		return (
			<Input
				id={element.label}
				placeholder={element.placeholder}
				type="number"
				value={element.value as number}
				onChange={element.onChange}
			/>
		);
	}

	if (element.type === "date") {
		return (
			<Input
				id={element.label}
				placeholder={element.placeholder}
				type={element.type}
				value={element.value as string}
				onChange={element.onChange}
				dateFormat="DD/MM/YYYY"
				locale="id id"
				min={element.min ? new Date().toISOString().split("T")[0] : ""}
			/>
		);
	}

	if (element.type === "money") {
		return (
			<InputGroup>
				<InputGroupText>Rp.</InputGroupText>
				{/* <div style={{ marginRight: "1rem"}}/> */}
				<Input
					id={element.label}
					placeholder={element.placeholder}
					type={"text"}
					value={element.value as string}
					onChange={element.onChange}
					disabled={element.disable}
					style={{
						paddingLeft: "1rem"
					}}
				/>
			</InputGroup>
		);
	}

	if (element.type === "time") {
		return (
			<Input
				name="time"
				type="time"
				className="form-control"
				value={element.value as string}
				onChange={element.onChange}
			/>
		);
	}

	if (element.type === "file") {
		return (
			<Input
				id={element.label}
				placeholder={element.placeholder}
				type={element.type}
				defaultValue={element.value as string}
				onChange={element.onChange}
				disabled={element.disable}
				style={element.style}
			/>
		);
	}

	if (element.type === "textarea") {
		return (
			<Input
				id={element.id}
				placeholder={element.placeholder}
				type="textarea"
				value={element.value as string}
				onChange={element.onChange}
				disabled={element.disable}
				style={element.style}
				rows={4}
			/>
		);
	}
	return (
		<Input
			id={element.label}
			placeholder={element.placeholder}
			type={element.type}
			value={element.value as string}
			onChange={element.onChange}
			disabled={element.disable}
			style={element.style}
		/>
	);
};

const BForm = (props: BFormProp) => {
	const { forms } = props;
	return (
		<Form>
			{forms.map((el, index) => (
				<Row key={index}>
					{
						<Col md="12">
							<FormGroup>
								<label>
									{el.label}
									{el.required && (
										<span
											style={{
												color: "red",
												marginLeft: "5px"
											}}
										>
											*
										</span>
									)}
								</label>
								<FormElement {...el} />
							</FormGroup>
						</Col>
					}
				</Row>
			))}
		</Form>
	);
};

export default BForm;
