import React, { Dispatch, SetStateAction, useEffect } from "react";
import { LocalDialog } from "components";
import { useLocalDialog } from "hooks";
import { Button } from "reactstrap";
export default function EditDialog({
	isOpen,
	setIsOpen,
	openEditDetail,
	openEditProject,
	openEditDocument
}: {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	openEditDetail: Dispatch<SetStateAction<boolean>>;
	openEditProject: Dispatch<SetStateAction<boolean>>;
	openEditDocument: Dispatch<SetStateAction<boolean>>;
}) {
	const { localDialog } = useLocalDialog();
	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		} else {
			localDialog.onClose();
		}
	}, [isOpen]);
	return (
		<LocalDialog
			isOpen={localDialog.isOpen}
			backdrop={"static"}
			header="Pilih Bagian Yang Ingin Diubah"
			onClose={() => {
				// localDialog.onClose();
				setIsOpen(false);
			}}
		>
			<div className="btn-dialog">
				<Button
					color="warning"
					type="submit"
					onClick={() => {
						setIsOpen(false);
						openEditDetail(true);
					}}
				>
					Ubah Detail Pelanggan
				</Button>
				<Button
					color="warning"
					type="submit"
					onClick={() => {
						setIsOpen(false);
						openEditProject(true);
					}}
				>
					Assign/Unassign Proyek
				</Button>
				<Button
					color="warning"
					type="submit"
					onClick={() => {
						setIsOpen(false);
						openEditDocument(true);
					}}
				>
					Ubah Dokumen Pelanggan
				</Button>
			</div>
		</LocalDialog>
	);
}
