import { OrderService, ProductivityService } from "api/axios";
import { BStatus, Header, Loading } from "components";
import BMTable from "components/B/BMTable";
import { useListSearchParams } from "hooks";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Input, Form, FormGroup, Row } from "reactstrap";
import { openAlert } from "store/features/alert/AlertSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useDebounce } from "use-debounce";
import moment from "moment";

const trialMixColumns = [
	{
		name: "No. Trial Mix",
		label: "No. Trial Mix",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "Status",
		label: "Status",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: string) => {
				return <BStatus data={value}>{value ?? "SUBMITTED"}</BStatus>;
			}
		}
	},
	{
		name: "Tanggal",
		label: "Tanggal Pengujian",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "Pelanggan",
		label: "Pelanggan",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "Proyek",
		label: "Proyek",
		options: {
			filter: false,
			sort: false
		}
	}
];

export default function TrialMix() {
	// Permission for each role to view or create WO
	const location = useLocation();
	const [searchTmNumber, setSearchTmNumber] = useState<string>();
	const [searchQTmNumber] = useDebounce(searchTmNumber, 500);
	const permission = useAppSelector((state) => state.user.permission);

	const navigate = useNavigate();
	const { date, page } = useListSearchParams();
	const dispatch = useAppDispatch();

	const [tableData, setTableData] = React.useState<any>({
		message: "",
		success: true,
		currentPage: 1,
		totalItems: 10,
		totalPages: 1,
		dataTable: []
	});

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const tmNumber = queryParams.get("search?tm-number");
		if (tmNumber) {
			setSearchTmNumber(tmNumber);
		} else {
			setSearchTmNumber("");
		}
	}, [location.search]);

	const { bp } = useAppSelector((store) => store.batching);

	const { isLoading, refetch } = useQuery<any, ApiError>(
		["query-trial-mixes", date, page, bp?.id, searchQTmNumber],
		async () => {
			return await ProductivityService.getAllTrialMix(page, 15, {
				batchingPlantId: bp?.id,
				date,
				tmNumber: searchQTmNumber
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res?.data?.data.map((el: any, index: any) => ({
					id: el.id,
					values: [
						{
							objectKey: "id",
							value: index + 1,
							type: "text",
							render: false
						},
						{
							objectKey: "No. Trial Mix",
							value: el.number,
							type: "text"
						},
						{
							objectKey: "Status",
							value: el.status,
							type: "text"
						},
						{
							objectKey: "Tanggal",
							value: moment(el.date).format("yyyy-MM-DD")
						},
						{
							objectKey: "Pelanggan",
							value: el.Customer?.displayName
						},
						{
							objectKey: "Proyek",
							value: el.Project?.displayName
						}
					]
				}));

				setTableData(() => ({
					...res,
					dataTable: (data as DataTable[]) ?? []
				}));
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const handleTmNumberSearch = (newValue: string) => {
		setSearchTmNumber(newValue);

		const queryParams = new URLSearchParams();
		queryParams.set("page", "1");

		if (newValue) {
			queryParams.set("search?tm-number", newValue);
		}

		navigate({
			pathname: location.pathname,
			search: queryParams.toString()
		});
	};

	if (isLoading) {
		return <Loading />;
	}

	return (
		<>
			<Header date={date} show />
			<Container className="mt--7" fluid>
				{/* Table */}
				<Row>
					<div className="col">
						<BMTable
							tableTitle=""
							header={
								<>
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											paddingBottom: "0.5rem"
										}}
									>
										<Form>
											<FormGroup
												style={{
													display: "flex",
													gap: "1rem"
												}}
											>
												<Input
													placeholder="NO. Trial Mix"
													value={searchTmNumber}
													onChange={(e) =>
														handleTmNumberSearch(
															e.target.value
														)
													}
												/>
											</FormGroup>
										</Form>
									</div>
								</>
							}
							columns={trialMixColumns}
							tableData={tableData.dataTable as DataTable[]}
							totalItems={tableData?.data?.totalItems as number}
							currentPage={tableData?.data?.currentPage as number}
							options={{
								onRowClick(rowData, rowMeta) {
									if (tableData && tableData.dataTable) {
										const id =
											tableData.dataTable[
												rowMeta.rowIndex
											]?.id;
										if (id !== undefined) {
											navigate(`/order/trial-mix/${id}`);
										}
									}
								}
							}}
						/>
					</div>
				</Row>
			</Container>
		</>
	);
}
