import React from "react";

const useLocalDialog = () => {
	const [localDialog, setLocalDialog] = React.useState({
		isOpen: false,
		onClose: () => setLocalDialog({ ...localDialog, isOpen: false }),
		onOpen: () => setLocalDialog({ ...localDialog, isOpen: true })
	});

	// const formsRender: ElementForm[] = React.useMemo(() => {
	// 	return forms;
	// }, dependencies);

	return {
		localDialog,
		setLocalDialog,
		// formsRender
	};
};

export default useLocalDialog;
