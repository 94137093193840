import React from "react";
import { BoxArrowUpRight, GeoAlt, GeoAltFill } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import { Badge, Card, CardBody } from "reactstrap";
import * as bsi from "react-bootstrap-icons";
import BForm from "./form";

interface IProps {
	data?: any;
	isEdit?: boolean;
}

const BAddressCard = (props: IProps) => {
	const { data, isEdit } = props;
	const location = useLocation();

	return (
		<Card className="b-address-card">
			{
				!data?.line1 && !data?.line1 ?
					<CardBody>
						{
							isEdit ?
								<BForm forms={[data?.formPostal, data?.formLine1]} />
								:
								<>
									<p className="b-address-card-line">N/A</p>
									{
										!data?.line2 &&
										<p className="b-address-card-line">N/A</p>
									}
								</>
						}
					</CardBody>
					:
					<CardBody>
						{
							isEdit ?
								<BForm forms={[data?.formPostal, data?.formLine1]} />
								:
								<>
									<p className="b-address-card-line">
										{data?.line1}
									</p>
									{
										data?.line2 &&
										<p className="b-address-card-line">
											{data?.line2}
										</p>
									}
								</>
						}
						{
							data?.line1 && data?.lon && data?.lat && !isEdit &&
							<div className="b-address-card-coord mt-2 d-flex align-items-center"
								onClick={(e) => {
									e.preventDefault();
									window.open(`https://www.google.com/maps/search/${data?.lat},${data?.lon}`, "_blank");
								}}
							>
								<GeoAlt size={16} />
								<p className="b-address-card-coords">{data?.lat?.slice(0, 10)}, {data?.lon?.slice(0, 10)}</p>
								<BoxArrowUpRight size={16} />
							</div>
						}
					</CardBody>}
		</Card >
	);
};

export default BAddressCard;
