import { LoadingButton } from "@mui/lab";
import { LocalDialog } from "components";
import { useLocalDialog } from "hooks";
import React, { useState } from "react";
import Select from "react-select";
import { formatDate } from "helper";
import { useMutation } from "react-query";
import { OrderService } from "api/axios";

export default function UpdateDo({
	deliveryOrders = [],
	refetch
}: {
	deliveryOrders?: ApiDeliveryOrder[];
	refetch?: () => void;
}) {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedCharfield9, setSelectedCharfield9] = useState({
		labelStr: "Belum",
		valueStr: "Belum"
	});
	const { localDialog } = useLocalDialog();

	const { mutateAsync: updateCharfield9, isLoading } = useMutation(
		async (data: UpdateCharfield9Payload) => {
			const res = await OrderService.putCharfield9(data);
			return res;
		},
		{
			onSuccess() {
				localDialog.onClose();
				setTimeout(() => {
					setIsOpen(false);
				}, 500);
				if (refetch) {
					refetch();
				}
			}
		}
	);

	return (
		<>
			<LoadingButton
				onClick={() => {
					setIsOpen(true);
				}}
				color="error"
				variant="contained"
			>
				Update hard copy
			</LoadingButton>
			<LocalDialog
				isOpen={isOpen}
				backdrop={true}
				header="Update Delivery Order Hard Copy"
				onClose={() => {
					localDialog.onClose();
					setTimeout(() => {
						setIsOpen(false);
					}, 500);
				}}
			>
				<div>
					<p>
						Perbarui {deliveryOrders.length} delivery order Hard
						Copy <b>{selectedCharfield9?.labelStr} </b>di terima
						oleh Osu
					</p>
					<div>
						{deliveryOrders.map((deli) => {
							return (
								<div
									key={deli.id}
									style={{
										border: "0.2px solid black",
										padding: "0.5rem",
										marginBottom: "10px"
									}}
								>
									<p>
										<b>{deli.number}</b>
									</p>
									<p>
										Hard Copy:
										{deli.accurateCharfield9 || "-"}
									</p>
									<p>
										dibuat:{" "}
										{deli.createdAt
											? formatDate(
													new Date(deli.createdAt)
											  )
											: "-"}
									</p>
								</div>
							);
						})}
					</div>
					<div
						style={{
							display: "flex",
							gap: "1rem"
						}}
					>
						<h1>Hard Copy Sudah Diterima OSu?</h1>
						<Select
							name="Hard Copy Sudah Diterima OSu?"
							value={selectedCharfield9}
							options={[
								{ labelStr: "Sudah", valueStr: "Sudah" },
								{ labelStr: "Belum", valueStr: "Belum" }
							]}
							getOptionLabel={(option) => {
								if (option) {
									return option.labelStr;
								}
								return "";
							}}
							getOptionValue={(option) => {
								if (option) {
									return option.valueStr as string;
								}
								return "";
							}}
							onChange={(change) => {
								if (change) {
									setSelectedCharfield9(change);
								}
							}}
						/>
					</div>
					<div className="modal-custom-footer">
						<LoadingButton
							color="error"
							onClick={() => {
								localDialog.onClose();
								setTimeout(() => {
									setIsOpen(false);
								}, 500);
							}}
							disabled={isLoading}
						>
							<span
								style={{
									textTransform: "capitalize"
								}}
							>
								Batal
							</span>
						</LoadingButton>
						<LoadingButton
							color="error"
							variant="contained"
							sx={{
								backgroundColor: "red",
								color: "white"
							}}
							loading={isLoading}
							onClick={async () => {
								const data = {
									charField9: selectedCharfield9.valueStr,
									deliveryOrders: deliveryOrders.map(
										(deli) => {
											return {
												accurateId:
													deli.deliveryOrderAccurateId,
												branchId:
													deli.accurateData.branchId,
												bodId: deli.id
											};
										}
									)
								} as UpdateCharfield9Payload;
								await updateCharfield9(data);
								localDialog.onClose();
								setTimeout(() => {
									setIsOpen(false);
								}, 500);
							}}
						>
							<span
								style={{
									textTransform: "capitalize"
								}}
							>
								Buat
							</span>
						</LoadingButton>
					</div>
				</div>
			</LocalDialog>
		</>
	);
}
