import React, { useEffect, useState } from "react";

import { AccurateService } from "api/axios";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { openAlert } from "store/features/alert/AlertSlice";
import { Header, LoadingSpinner } from "components";
import { Card, CardTitle, Container, Row, Button } from "reactstrap";
import { CardContent } from "@mui/material";
import { getAccurateCreds } from "helper";

export default function AccurateLogin({ isError, isLoading }) {
	const dispatch = useDispatch();

	const [stopCondition, setStopCondition] = useState(false);

	useEffect(() => {
		let intervalId;
		let timeoutId;
		if (stopCondition) {
			intervalId = setInterval(() => {
				if (getAccurateCreds()?.user) {
					clearInterval(intervalId);
					setStopCondition(false);
					console.log("Function stopped!");
					window.location.reload();
				} else {
					console.log("Checking the condition...");
				}
			}, 5000); // 5000 milliseconds = 5 seconds

			// Stop the interval after 5 minutes
			timeoutId = setTimeout(() => {
				clearInterval(intervalId);
			}, 300000);
		}

		return () => {
			if (intervalId && timeoutId) {
				clearInterval(intervalId);
				clearTimeout(timeoutId);
			}
		};
	}, [stopCondition]);

	return (
		<>
			<Header />
			<Container
				style={{
					position: "relative",
					top: "-10rem"
				}}
				fluid
			>
				<Row className="d-flex justify-content-center">
					<Card className="shadow p-4">
						<CardTitle className="d-flex justify-content-center">
							<img
								src="https://account.accurate.id/images/img/logo-accurate.svg"
								alt=""
							/>
						</CardTitle>
						<CardContent>
							{(isLoading || stopCondition) && (
								<div className="d-flex justify-content-center align-items-center flex-column">
									{stopCondition && (
										<p>
											Apabila telah melakukan login dan
											masih melihat halaman ini tolong{" "}
											<b>reload</b>!
										</p>
									)}
									<LoadingSpinner />
								</div>
							)}
							{isError && !stopCondition && (
								<div className="d-flex flex-column justify-content-center">
									<p>
										<b>Error:</b> Tolong Login Accurate
										Ulang! / Apabila telah melakukan login
										dan masih melihat halaman ini tolong{" "}
										<b>reload</b>!
									</p>
									<Button
										size="sm"
										color="primary"
										onClick={(e) => {
											e.preventDefault();
											window.open(
												`https://account.accurate.id/oauth/authorize?client_id=ba58abb3-239b-4478-b162-eb3388259c2a&response_type=code&redirect_uri=${window.location.origin}/sl/accurate-oauth&scope=item_view item_save sales_invoice_view sales_invoice_save customer_view customer_save purchase_order_save sales_order_view delivery_order_save delivery_order_view branch_view warehouse_view`
											);
											setStopCondition(true);
										}}
									>
										Login Accurate
									</Button>
								</div>
							)}
						</CardContent>
					</Card>
				</Row>
			</Container>
		</>
	);
}
