import React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const BSwitch = (props: any) => {
	const { switchType, ...innerProps } = props;
	const SW = styled(Switch)(({ theme }: any) => {
		const getAsBg = (svg: string) => {
			return `url('data:image/svg+xml;utf8,${svg}')`;
		};

		let activeBgImg = "none";
		let inactiveBgImg = "none";

		if (switchType == "camera") {
			activeBgImg = getAsBg(`<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><g fill="none" stroke="${encodeURIComponent(
				theme.palette.getContrastText(theme.palette.primary.main),
			)}" stroke-width="2"><path d="M16 16V8a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1z"/><path stroke-linejoin="round" d="m20 7l-4 3v4l4 3V7z"/></g></svg>`);
			inactiveBgImg = getAsBg(`<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="none" stroke="${encodeURIComponent(
				theme.palette.getContrastText(theme.palette.primary.main),
			)}" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m3 5l14 14M9 7h6a1 1 0 0 1 1 1v5.5a1 1 0 0 0 .4.8L20 17V7l-4 3m-1 7H5a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1"/></svg>`);
		}

		else if (switchType == "map") {
			activeBgImg = getAsBg(`<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 100 100"><path fill="${encodeURIComponent(
				theme.palette.getContrastText(theme.palette.primary.main),
			)}" d="M50.002 0C30.363 0 14.25 16.062 14.25 35.656c0 7.594 2.43 14.667 6.531 20.463l-.123-.191l23.203 40.11l.098.128c.914 1.194 1.807 2.135 2.855 2.828c1.049.694 2.35 1.11 3.625.983c2.553-.256 4.117-2.058 5.6-4.069l.078-.105l25.584-43.541l.016-.03c.6-1.084 1.042-2.187 1.418-3.261a35.239 35.239 0 0 0 2.615-13.315C85.75 16.062 69.64 0 50.002 0zm0 5C66.917 5 80.75 18.8 80.75 35.656c0 4.057-.806 7.927-2.262 11.483l-.027.062l-.022.063c-.324.932-.678 1.79-1.097 2.548L51.975 92.98c-1.172 1.56-2.027 2.021-2.034 2.022c-.003 0-.016.054-.367-.178c-.338-.223-.915-.774-1.588-1.638L24.928 53.324l-.065-.092c-3.533-4.994-5.613-11.047-5.613-17.576C19.25 18.801 33.087 5 50.002 5zm0 10.537c-11.164 0-20.172 8.976-20.172 20.12c0 11.142 9.009 20.118 20.172 20.118S70.17 46.8 70.17 35.656c0-11.142-9.004-20.119-20.168-20.119zm0 5c8.52 0 15.168 6.635 15.168 15.12c0 8.483-6.648 15.118-15.168 15.118S34.83 44.14 34.83 35.656c0-8.483 6.653-15.119 15.172-15.119z" color="${encodeURIComponent(
				theme.palette.getContrastText(theme.palette.primary.main),
			)}"/></svg>`);
			inactiveBgImg = getAsBg(`<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 100 100"><path fill="${encodeURIComponent(
				theme.palette.getContrastText(theme.palette.primary.main),
			)}" d="M50.002 0C30.363 0 14.25 16.062 14.25 35.656c0 7.524 2.39 14.533 6.424 20.3l2.605 4.501l3.659-3.658l-2.01-3.475l-.065-.092c-3.533-4.994-5.613-11.047-5.613-17.576C19.25 18.801 33.087 5 50.002 5c7.953 0 15.224 3.052 20.697 8.037l3.524-3.523C67.838 3.62 59.32 0 50.002 0zm0 15.537c-11.164 0-20.172 8.976-20.172 20.12c0 5.089 1.895 9.713 5 13.25l3.496-3.497c-2.189-2.615-3.496-6.001-3.496-9.754c0-8.483 6.653-15.119 15.172-15.119c3.749 0 7.133 1.286 9.75 3.447l3.496-3.496a20.11 20.11 0 0 0-13.246-4.95zM85.414 30.85l-4.668 4.668c0 .046.004.092.004.138c0 4.057-.806 7.927-2.262 11.483l-.027.062l-.022.063c-.324.932-.678 1.79-1.097 2.548L51.975 92.98c-1.172 1.56-2.027 2.021-2.034 2.022c-.003 0-.016.054-.367-.178c-.338-.223-.915-.774-1.588-1.638l-9.129-15.78l-3.658 3.658l8.662 14.973l.098.129c.914 1.194 1.807 2.135 2.855 2.828c1.049.694 2.35 1.11 3.625.983c2.553-.256 4.117-2.058 5.6-4.069l.078-.105l25.584-43.541l.016-.03c.6-1.084 1.042-2.187 1.418-3.261a35.239 35.239 0 0 0 2.615-13.315c0-1.63-.12-3.233-.336-4.806zm1.709-28.58L97.73 12.878L12.877 97.73L2.271 87.123z" color="${encodeURIComponent(
				theme.palette.getContrastText(theme.palette.primary.main),
			)}"/></svg>`);
		}

		return {
			padding: 8,
			"& .MuiSwitch-track": {
				borderRadius: 22 / 2,
				"&:before, &:after": {
					content: "\"\"",
					position: "absolute",
					top: "50%",
					transform: "translateY(-50%)",
					width: 16,
					height: 16,
				},
				"&:before": {
					backgroundImage: activeBgImg,
					left: 12,
				},
				"&:after": {
					backgroundImage: inactiveBgImg,
					right: 12,
				},
			},
			"& .MuiSwitch-thumb": {
				boxShadow: "none",
				width: 16,
				height: 16,
				margin: 2,
			},
		};
	});

	return (<SW {...innerProps} />);
};

export default BSwitch;

