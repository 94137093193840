import React, { useMemo, useState } from "react";
import { DashboardService } from "api/axios";
import { BarChart } from "components";
import { MonthStartEnd } from "helper/WeekNumberToDateRange";
import moment from "moment";
import { useQuery } from "react-query";

const thisMonth = moment().endOf("month").format("YYYY-MM-DD");

const { startDate: initialMonthStartDate, endDate: initialMonthEndDate } =
	MonthStartEnd(thisMonth, 3);
export default function DepositChart() {
	const [dateRange, setDateRange] = useState({
		startDate: initialMonthStartDate,
		endDate: initialMonthEndDate
	});
	const [filterType, setFilterType] = useState("monthly");

	const [depositData, setDepositData] = useState({} as DepositResponse);

	useQuery(
		["deposit", dateRange],
		async () => {
			const { startDate, endDate } = dateRange;
			return await DashboardService.depositCount({
				startDate,
				endDate,
				type: filterType
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				setDepositData(res.data.data as DepositResponse);
				// setTotalPages(res.data.data.totalPages);
			}
		}
	);

	const transformedData = useMemo(() => {
		const dataChart: {
			label: string;
			[key: string]: string | number;
		}[] = [];
		if (depositData.deposit) {
			Object.keys(depositData.deposit).forEach((date) => {
				const count = depositData.deposit[date as any]
					? depositData.deposit[date as any]
					: 0;

				dataChart.push({
					label: date,
					"Jumlah Deposit": count as number
				});
			});
		}
		return dataChart;
	}, [depositData]);

	return (
		<BarChart
			data={transformedData}
			labels={["Jumlah Deposit"]}
			title="Deposit"
			indexBy={"label"}
			layout="vertical"
			filterOnChange={({ dateRange, type }) => {
				setDateRange(dateRange);
				setFilterType(type);
			}}
			leftLegend="Rupiah/IDR"
			bottomLegend="Month/Date"
			initialDate={{
				startDate: initialMonthStartDate,
				endDate: initialMonthEndDate
			}}
		/>
	);
}
