import { useListSearchParams, useLocalDialog } from "hooks";
import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useMemo,
	useState
} from "react";
import { LocalDialog, MTable } from "components";
import { useMutation, useQuery } from "react-query";
import { CommonService } from "api/axios";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useAppDispatch } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import { LoadingButton } from "@mui/lab";

const muiColumns = [
	{
		name: "nama",
		label: "NAMA",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "city",
		label: "KOTA",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "lastOrder",
		label: "PEMESANAN TERAKHIR",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "pic",
		label: "PIC",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "sales",
		label: "SALES",
		options: {
			filter: false,
			sort: false
		}
	}
];

export default function EditProject({
	isOpen,
	setIsOpen,
	customerDetail,
	refetch
}: // customerDetail,
// refetch
{
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	customerDetail: {
		displayName: string;
		type: "COMPANY" | "INDIVIDU";
		ktp: string;
		npwp: string;
	};
	refetch: () => void;
}) {
	const dispatch = useAppDispatch();
	const params = useParams();
	const id = params?.id;
	const { localDialog } = useLocalDialog();
	const [page, setPage] = useState(1);
	const [searchQuery, setSearchQuery] = useState<string | null>("");
	const [totalItems, setTotalItems] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [projectData, setProjectData] = useState<
		{
			id: string;
			[key: string]: string | number;
		}[]
	>([]);
	const [selectedProject, setSelectedProject] = useState<string[]>([]);
	const [searchQ] = useDebounce(searchQuery, 500);
	const [referanceSelectedProjectIds, setReferanceSelectedProjectIds] =
		useState<string[]>([]);

	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		}
	}, [isOpen]);

	useQuery<ApiResponsePlain<CustomerDetailSelected>, ApiError>(
		["customer-detail", id],
		async () => {
			return await CommonService.getOneCustomer(id as string);
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const customer = res.data;
				// console.log(customer.Projects, "ApiOneCustomer");
				setSelectedProject(
					customer.Projects.map((project) => project.id)
				);
				setReferanceSelectedProjectIds(
					customer.Projects.map((project) => project.id)
				);
				// setCustomerDetail({
				// 	ktp: customer.nik,
				// 	npwp: customer.npwp,
				// 	pic: {
				// 		name: customer.Pic.name,
				// 		position: customer.Pic.position,
				// 		email: customer.Pic.email,
				// 		phone: customer.Pic.phone
				// 	},
				// 	billingAddress: {
				// 		line1: customer.BillingAddress.line1 as string,
				// 		line2: customer.BillingAddress.line2,
				// 		lat: customer.BillingAddress.lat,
				// 		lon: customer.BillingAddress.lon
				// 	},
				// 	name: customer.displayName,
				// 	type: customer.type,
				// 	availableDeposit:
				// 		customer.CustomerDeposit?.availableDeposit,
				// 	customerDocs: customer.CustomerDocs,
				// 	projects: customer.Projects.map((project) => {
				// 		let cityName = "-";
				// 		if (project.ShippingAddress) {
				// 			cityName = project.ShippingAddress.Postal?.City
				// 				.name as string;
				// 		}

				// 		return {
				// 			id: project.id,
				// 			nama: project.name,
				// 			city: cityName,
				// 			lastOrder: project.DeliveryOrders[0]
				// 				? moment(
				// 						project.DeliveryOrders[0]?.date
				// 				  ).format("DD/MM/YYYY")
				// 				: "-",
				// 			pic: project.Pic.name,
				// 			sales: project.User.fullName
				// 		};
				// 	})
				// });
			}
		}
	);

	const { mutateAsync: updateCustomer, isLoading } = useMutation<
		ApiResponseCustomer,
		ApiError,
		PayloadCustomer,
		ApiError
	>(
		async (data: PayloadCustomer) => {
			const res = await CommonService.updateCustomer(id as string, data);
			return res;
		},
		{
			onSuccess: (res) => {
				localDialog.onClose();
				dispatch(
					openAlert({
						body: res.message,
						color: "success"
					})
				);
				// setIsLoading(false);
				setIsOpen(false);
				refetch();
				// navigate("/database/customers");
			},
			onError: (err) => {
				// setIsLoading(false);
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const {
		data,
		isLoading: loadingGetProject,
		refetch: refetchProject
	} = useQuery<ApiProjectCustomers, ApiError>(
		["query-getprojects", page, rowsPerPage, searchQ],
		async () => {
			return CommonService.getAllProjectCustomer(
				page,
				rowsPerPage,
				searchQ,
				id
			);
		},
		{
			enabled: true,
			onSuccess: (res) => {
				//
				const projects = res.data.data;
				setTotalItems(res.data.totalItems as number);
				setCurrentPage(res.data.currentPage as number);
				setProjectData(() => {
					return projects.map((project) => {
						let cityName = "-";
						if (project?.BillingAddress) {
							cityName = project?.BillingAddress.Postal?.City
								.name as string;
						}
						return {
							id: project.id,
							nama: project.name,
							city: cityName,
							lastOrder: project.DeliveryOrders[0]
								? moment(
										project.DeliveryOrders[0]?.date
								  ).format("DD/MM/YYYY")
								: "-",
							pic: project.Pic?.name,
							sales: project.User?.fullName
						};
					});
				});
			}
		}
	);

	// const { data: places } = useQuery<ApiPlaces, ApiError>(
	// 	["query-places", searchGoogle],
	// 	async () => {
	// 		return CommonService.getPlaces(searchGoogle as string);
	// 	},
	// 	{
	// 		enabled: searchGoogle.length ? true : false
	// 	}
	// );

	const selectedIndex = useMemo(() => {
		const indexes: number[] = [];
		projectData.forEach((project, index) => {
			if (selectedProject.includes(project.id)) {
				indexes.push(index);
			}
		});
		return indexes;
	}, [selectedProject, projectData]);
	// console.log(selectedProject, "selectedProject");
	const [addedProjectIds, deletedProjectIds] = useMemo(() => {
		const addedIds: string[] = [];
		const deletedIds: string[] = [];
		selectedProject.forEach((id) => {
			if (!referanceSelectedProjectIds.includes(id)) {
				addedIds.push(id);
			}
		});
		referanceSelectedProjectIds.forEach((id) => {
			if (!selectedProject.includes(id)) {
				deletedIds.push(id);
			}
		});
		return [addedIds, deletedIds];
	}, [selectedProject, referanceSelectedProjectIds]);

	return (
		<LocalDialog
			isOpen={localDialog.isOpen}
			backdrop={true}
			header="Assign/Unassign Proyek"
			onClose={() => {
				// localDialog.onClose();
				localDialog.onClose();
				setTimeout(() => {
					setIsOpen(false);
				}, 500);
			}}
			// onClick={() => {
			// 	console.log("submit");
			// }}
			leftBtnTitle="Batal"
			rightBtnTitle="Edit"
		>
			<div
				style={{
					width: "1000px",
					height: "500px",
					display: "flex",
					flexDirection: "column"
				}}
			>
				<div
					style={{
						width: "100%",
						maxWidth: "100%",
						overflow: "auto",
						padding: "0 16px"
					}}
				>
					<MTable
						currentPage={currentPage}
						columns={muiColumns}
						tableData={projectData}
						tableTitle="Projek Pelanggan"
						rowsPerPage={rowsPerPage}
						rowsPerPageOptions={[5, 10, 15, 100]}
						setPage={(page: number) => {
							setPage(page);
						}}
						setRowsPerPage={(rows: number) => {
							setRowsPerPage(rows);
						}}
						totalItems={totalItems}
						onSearchChange={(text: string | null) => {
							setSearchQuery(text);
						}}
						selectedRows={selectedIndex}
						setSelectedRows={(index: number) => {
							setSelectedProject((curr) => {
								const newSelectedIds = [...curr];
								newSelectedIds.push(
									projectData[index]?.id as string
								);

								return newSelectedIds;
							});
						}}
						setDeselectRows={(index: number) => {
							setSelectedProject((curr) => {
								const newSelectedIds = [...curr];

								return newSelectedIds.filter(
									(id) => id !== projectData[index]?.id
								);
							});
						}}
						searchPlaceholder="Cari nama proyek / nama sales"
					/>
				</div>
			</div>

			<div className="modal-custom-footer">
				<LoadingButton
					color="error"
					onClick={() => {
						setIsOpen(false);
					}}
					loading={isLoading}
					disabled={isLoading}
				>
					<span style={{ textTransform: "capitalize" }}>Batal</span>
				</LoadingButton>
				<LoadingButton
					color="warning"
					variant="contained"
					loading={isLoading}
					disabled={isLoading}
					onClick={() => {
						updateCustomer({
							...customerDetail,
							deletedProjectIds: deletedProjectIds,
							addedProjectIds: addedProjectIds
						});
					}}
				>
					<span style={{ textTransform: "capitalize" }}>Ubah</span>
				</LoadingButton>
			</div>
		</LocalDialog>
	);
}
