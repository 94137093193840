import { getStatusColor, getStatusTrx, getStatusByType } from "helper";
import React from "react";
import { Badge } from "reactstrap";

interface IProps {
	children: string;
	className?: string;
	type?: "SO";
	data?: any;
}

const BStatus = ({ children, className, type, data }: IProps) => {
	const _status = getStatusByType(children, type, data) ?? children ?? "";
	return (
		<Badge className={className ?? ""} color="null" style={{ ...getStatusColor(_status), textTransform: "initial" }}>{getStatusTrx(_status)}</Badge>
	);
};

export default BStatus;
