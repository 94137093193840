import React from "react";
import { FormGroup, Label } from "reactstrap";
import Select, { OptionProps } from "react-select";
import styles from "./createPayment.module.scss";
import { ErrorMessage } from "formik";

const Option = (props: OptionProps<CustomerAccurate>) => {
	return (
		<div
			className={styles.selectCustom}
			{...("innerProps" in props && props.innerProps)}
			style={{
				cursor: props.isDisabled ? "default" : "pointer",
				backgroundColor: props.isDisabled ? "lightgray" : "white"
			}}
		>
			<div>
				<div className={styles.selectInfo}>
					<p>{props.data.name}</p>
					<b>{props.data.customerNo}</b>
				</div>
				<div className={styles.contact}>
					<p>
						HP: <b>{props.data.mobilePhone || "-"}</b>
					</p>
					<p>
						WA: <b>{props.data.whatsappNo || "-"}</b>
					</p>
					<p>
						Sales: <b>{props.data.salesmanNameList || "-"}</b>
					</p>
				</div>
			</div>
		</div>
	);
};

export default function AccurateCustomerSelector(props: {
	customers: CustomerAccurate[];
	setSearchCustomer: (value: string) => void;
	setFieldValue: (field: string, value: any) => void;
	values: any;
	errors: any;
	defaultAccurateCustomer?: CustomerAccurate;
	isLoading?: boolean;
	isDisabled?: boolean;
	label?: string;
	onChange?: (value: any) => void;
}) {
	const {
		customers,
		setSearchCustomer,
		setFieldValue,
		values,
		errors,
		defaultAccurateCustomer,
		isDisabled,
		label,
		onChange
	} = props;
	React.useEffect(() => {
		setFieldValue("customerAccurate", defaultAccurateCustomer);
	}, [defaultAccurateCustomer]);

	return (
		<FormGroup>
			<Label htmlFor="searchCustomer">
				{label ?? "Pelanggan Accurate *"}
			</Label>

			<Select
				options={customers}
				components={{ Option }}
				getOptionLabel={(option: CustomerAccurate) => option.name}
				getOptionValue={(option: CustomerAccurate) => option.customerNo}
				onInputChange={(inputValue: string) => {
					setSearchCustomer(inputValue);
				}}
				onChange={(e) => {
					if (onChange) {
						onChange(e);
					} else {
						setFieldValue("customerAccurate", e);
					}
				}}
				isMulti={false}
				value={values.customerAccurate}
				defaultValue={defaultAccurateCustomer}
				styles={{
					control(base, state) {
						return {
							...base,
							borderColor:
								errors.customerAccurate && !isDisabled
									? "red"
									: "#cad1d7"
						};
					}
				}}
				isDisabled={isDisabled}
				// isLoading={customerListLoading || customerDetailLoading}
			/>

			<ErrorMessage
				name={"customerAccurate"}
				component="div"
				className="error-msg"
			/>
		</FormGroup>
	);
}
