import React from "react";
import { Loading, LocalDialog } from "components";
import { useDebounce } from "use-debounce";
import BForm from "components/B/form";
import {
	QueryObserverResult,
	RefetchOptions,
	RefetchQueryFilters,
	useMutation,
	useQuery
} from "react-query";
import { CommonService, FinanceService, OrderService } from "api/axios";
import { useDispatch } from "react-redux";
import { openAlert } from "store/features/alert/AlertSlice";
import moment from "moment";
import { useAppSelector } from "store/hooks";

interface IProps {
	localDialog: {
		isOpen: boolean;
		onClose: () => void;
		onOpen: () => void;
	};
	setLocalDialog: React.Dispatch<
		React.SetStateAction<{
			isOpen: boolean;
			onClose: () => void;
			onOpen: () => void;
		}>
	>;
	handleCreateLoading: (isLoading: boolean) => void;
	refetch:
		| (<TPageData>(
				options?:
					| (RefetchOptions & RefetchQueryFilters<TPageData>)
					| undefined
		  ) => Promise<QueryObserverResult<ApiInvoices, ApiError>>)
		| (<TPageData>(
				options?:
					| (RefetchOptions & RefetchQueryFilters<TPageData>)
					| undefined
		  ) => Promise<QueryObserverResult<ApiOneInvoice, ApiError>>);
	data?: InvoiceDetail;
}

const CreateInvoice = (props: IProps) => {
	const dispatch = useDispatch();
	const { bp } = useAppSelector((store) => store.batching);
	const {
		localDialog,
		refetch: refetchList,
		handleCreateLoading,
		data
	} = props;
	const [payload, setPayload] = React.useState<CreateInvoiceModel>({
		selectedDOs: [],
		project: {},
		// ...data
		issuedDate: data?.issuedDate
			? moment(data.issuedDate).format("YYYY-MM-DD")
			: undefined,
		deliveredDate: data?.deliveredDate
			? moment(data.deliveredDate).format("YYYY-MM-DD")
			: undefined,
		customerReceivedDate: data?.customerReceivedDate
			? moment(data.customerReceivedDate).format("YYYY-MM-DD")
			: undefined,
		notes: data?.notes ?? undefined,
		batchingPlantId: bp?.valueStr
	});

	// queries
	// DO
	const [page, setPage] = React.useState<number>(1);
	const [text, setText] = React.useState<string>("");
	const [searchDO] = useDebounce(text, 1000);

	React.useEffect(() => {
		if (searchDO) {
			setOptionDOs([]);
			setPage(1);
		}
	}, [searchDO]);

	const [optionDOs, setOptionDOs] = React.useState<options[]>([]);
	const { isLoading, refetch } = useQuery<ApiDeliveries, ApiError>(
		["query-do", page, searchDO, payload.project.valueStr, bp?.valueStr],
		async () => {
			return await OrderService.getAllDODropdown(page, 15, {
				search: searchDO as string,
				projectId: payload?.project?.valueStr,
				status: "FINISHED",
				batchingPlantId: bp?.valueStr
			});
		},
		{
			enabled: !!payload.project.valueStr,
			onSuccess: (res) => {
				const options = res.data?.data.map((el) => ({
					id: el.id,
					labelStr: `No DO: ${el.number} / Produk: ${el.Schedule.SaleOrder.Product.displayName} / Jumlah: ${el.quantity}`,
					valueStr: el.id,
					number: el.number
				}));

				setOptionDOs([
					...optionDOs,
					...(options as options[])
				] as options[]);
			},
			onError: (err: ApiError) => {
				// dispatch(openAlert({
				// 	body: err.response?.data.message,
				// 	color: "danger"
				// }));
			}
		}
	);

	// Project
	const [pageProject, setPageProject] = React.useState<number>(1);
	const [textProject, setTextProject] = React.useState<string>("");
	const [searchProject] = useDebounce(textProject, 1000);

	React.useEffect(() => {
		if (searchProject) {
			setOptionProjects([]);
			setPageProject(1);
		}
	}, [searchProject]);

	const [optionProjects, setOptionProjects] = React.useState<options[]>([]);
	const { isLoading: loadingProjects } = useQuery<
		ApiResponseProjectInvoice,
		ApiError
	>(
		["query-projects", pageProject, searchProject],
		async () => {
			return await CommonService.getProjectInvoice({
				search: searchProject as string,
				page: pageProject,
				size: 15
			});
		},
		{
			enabled: localDialog.isOpen,
			onSuccess: (res) => {
				const options = res.data?.data.map((el) => ({
					id: el.id,
					labelStr: `Project: ${el.name} ${
						el?.Customer?.name
							? "/ Customer: " + el.Customer.name
							: ""
					}`,
					valueStr: el.id,
					name: el?.Customer?.name ?? el.name
				}));

				setOptionProjects([...optionProjects, ...options] as options[]);
			},
			onError: (err: ApiError) => {
				// dispatch(openAlert({
				// 	body: err.response?.data.message,
				// 	color: "danger"
				// }));
			}
		}
	);
	// states
	const forms: ElementForm[] = React.useMemo(() => {
		if (data) {
			return [
				{
					label: "Tanggal Terbit Tagihan",
					type: "date",
					value: payload?.issuedDate,
					onChange: (e: any) => {
						setPayload({
							...payload,
							issuedDate: e.target.value
						});
					},
					placeholder: "Pilih Tanggal"
				},
				{
					label: "Tanggal Terkirim",
					type: "date",
					value: payload?.deliveredDate,
					onChange: (e: any) => {
						setPayload({
							...payload,
							deliveredDate: e.target.value
						});
					},
					placeholder: "Pilih Tanggal"
				},
				{
					label: "Tanggal Pelanggan Terima",
					type: "date",
					value: payload?.customerReceivedDate,
					onChange: (e: any) => {
						setPayload({
							...payload,
							customerReceivedDate: e.target.value
						});
					},
					placeholder: "Pilih Tanggal"
				},
				{
					label: "Catatan",
					type: "text",
					value: payload?.notes,
					onChange: (e: any) => {
						setPayload({
							...payload,
							notes: e.target.value
						});
					},
					placeholder: "Tulis Catatan"
				}
			];
		}
		return [
			{
				label: "Nomor Tagihan",
				type: "text",
				value: payload?.number,
				placeholder: "INV/BRIK/YYYY/MM/1234",
				disable: false,
				onChange: (e: any) => {
					setPayload({
						...payload,
						number: e.target.value
					});
				}
			},
			{
				label: "Nama Pelanggan",
				type: "autocomplete",
				value: payload?.project,
				placeholder: "PT. Bangun Rancang Indonesia Kita",
				onChange: (e: any) => {
					setOptionDOs([]);
					setPage(1);
					refetch();
					setPayload({
						...payload,
						project: e,
						selectedDOs: []
					});
				},
				options: optionProjects,
				loadOption: (inputValue: string) => {
					setTextProject(inputValue);
				},
				onReachBottom: () => {
					setPageProject(page + 1);
				},
				isLoading: loadingProjects,
				for: "PROJECTINVOICE"
			},
			// {
			// 	label: "Jatuh Tempo",
			// 	type: "dropdown",
			// 	value: payload?.paymentDuration,
			// 	onChange: (e: any) => setPayload({
			// 		...payload,
			// 		paymentDuration: e
			// 	}),
			// 	placeholder: "45 Hari",
			// 	options: [
			// 		{
			// 			valueStr: "3",
			// 			labelStr: "3 Hari",
			// 		},
			// 		{
			// 			valueStr: "7",
			// 			labelStr: "7 Hari",
			// 		},
			// 		{
			// 			valueStr: "14",
			// 			labelStr: "14 Hari",
			// 		},
			// 		{
			// 			valueStr: "30",
			// 			labelStr: "30 Hari",
			// 		},
			// 		{
			// 			valueStr: "45",
			// 			labelStr: "45 Hari",
			// 		},
			// 		{
			// 			valueStr: "60",
			// 			labelStr: "60 Hari",
			// 		},
			// 		{
			// 			valueStr: "90",
			// 			labelStr: "90 Hari",
			// 		},
			// 	]
			// },
			// {
			// 	label: "Syarat Pembayaran",
			// 	type: "dropdown",
			// 	value: payload?.paymentCondition,
			// 	onChange: (e: any) => setPayload({
			// 		...payload,
			// 		paymentCondition: e
			// 	}),
			// 	placeholder: "Tagihan diterima",
			// 	options: [
			// 		{
			// 			valueStr: "after INV",
			// 			labelStr: "Tagihan Diterima",
			// 		},
			// 		{
			// 			valueStr: "after DO",
			// 			labelStr: "Pengiriman Selesai",
			// 		},
			// 	]
			// },
			{
				label: "Tanggal Terbit Tagihan",
				type: "date",
				value: payload?.issuedDate,
				onChange: (e: any) => {
					setPayload({
						...payload,
						issuedDate: e.target.value
					});
				},
				placeholder: "Pilih Tanggal"
			},
			{
				label: "Nomor Pengiriman",
				type: "autocomplete",
				value: payload?.selectedDOs,
				onChange: (e: any) => {
					setPayload({
						...payload,
						selectedDOs: e
					});
				},
				placeholder: "Select DO",
				options: optionDOs,
				multiple: true,
				loadOption: (inputValue: string) => {
					setText(inputValue);
				},
				onReachBottom: () => {
					setPage(page + 1);
				},
				isLoading: isLoading,
				for: "DOINVOICE"
			}
		];
	}, [payload, optionProjects, loadingProjects, isLoading, optionDOs]);

	const { mutate, isLoading: loadingAdd } = useMutation<
		ApiResponseCreateInvoice,
		ApiError,
		CreateInvoiceModel,
		ApiError
	>(
		async (_payload: CreateInvoiceModel) => {
			const res = await FinanceService.createInvoice(_payload);
			return res;
		},
		{
			onSuccess: (e) => {
				localDialog.onClose();
				setPayload({
					selectedDOs: [],
					project: {}
				});
				handleCreateLoading(false);
				refetchList();
			},
			onError: (err) => {
				handleCreateLoading(false);
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);
	const { mutate: updateInvoice } = useMutation<
		ApiResponseCreateInvoice,
		ApiError,
		UpdateInvoiceModel,
		ApiError
	>(
		async (_payload: UpdateInvoiceModel) => {
			const res = await FinanceService.updateInvoice(
				data?.id as string,
				_payload
			);
			return res;
		},
		{
			onSuccess: (e) => {
				localDialog.onClose();
				setPayload({
					selectedDOs: [],
					project: {}
				});
				handleCreateLoading(false);
				refetchList();
			},
			onError: (err) => {
				handleCreateLoading(false);
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const onSubmit = () => {
		if (data) {
			const _payload: UpdateInvoiceModel = {
				issuedDate: new Date(payload.issuedDate as string),
				deliveredDate: new Date(payload.deliveredDate as string),
				customerReceivedDate: new Date(
					payload.customerReceivedDate as string
				),
				notes: payload.notes as string
			};

			handleCreateLoading(true);
			updateInvoice(_payload);
			return;
		}
		const _payload: CreateInvoiceModel = {
			projectId: payload.project.valueStr as string,
			number: payload.number as string,
			notes: payload.notes as string,
			doIds: payload.selectedDOs.map((el) => el.id) as string[],
			issuedDate: payload.issuedDate,
			selectedDOs: [],
			project: {},
			batchingPlantId: bp?.valueStr
		};

		handleCreateLoading(true);
		mutate(_payload);
	};

	if (!localDialog.isOpen) return null;

	if (loadingAdd) {
		return <Loading />;
	}

	return (
		<LocalDialog
			isOpen={localDialog.isOpen}
			backdrop={"static"}
			// body={<BForm forms={forms} />}
			header="Buat Tagihan Baru"
			onClick={onSubmit}
			onClose={() => {
				localDialog.onClose();
			}}
			fullScreen
		>
			<BForm forms={forms} />
		</LocalDialog>
	);
};

export default CreateInvoice;
