import * as React from "react";
import FileItem from "../FileItem/FileItem";

export default function FileList({
	files,
	setListFiles
}: {
	files: any;
	setListFiles: any;
}) {
	return (
		// <p>item here</p>
		<li className="file-list" style={{ listStyleType: "none" }}>
			{files &&
				files.map((f: any, i: any) => (
					<FileItem
						key={i}
						files={f}
						indexFile={i}
						setFiles={setListFiles}
						listFiles={files}
					/>
				))}
		</li>
	);
}
