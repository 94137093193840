import { OrderService } from "api/axios";
import { BStatus, Header, LocalDialog } from "components";
import BMTable from "components/B/BMTable";
import { useListSearchParams } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Input, Form, FormGroup, Row } from "reactstrap";
import { getRouteName } from "routes";
import { openAlert } from "store/features/alert/AlertSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useDebounce } from "use-debounce";
import { formatComma } from "helper";
import moment from "moment";

const woColumns = [
	{
		name: "No. WO",
		label: "No. WO",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "Status",
		label: "Status",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				return <BStatus data={value}>{value ?? "SUBMITTED"}</BStatus>;
			}
		}
	},
	{
		name: "Tanggal",
		label: "Tanggal Pengiriman",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "No. DO",
		label: "No. DO",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "No. SKU",
		label: "No. SKU",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "kuantitas",
		label: "Kuantitas (m³) ",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "produk",
		label: "Produk",
		options: {
			filter: false,
			sort: false
		}
	}
];

export default function WorkOrder() {
	// Permission for each role to view or create WO
	const location = useLocation();
	const [searchWoNumber, setSearchWoNumber] = useState<string>();
	const [searchQWoNumber] = useDebounce(searchWoNumber, 500);
	const permission = useAppSelector((state) => state.user.permission);
	// const workOrderPermission = useMemo(() => {
	// 	const routeName = getRouteName(location.pathname, routeMapping);

	// 	if (routeName) {
	// 		return permission[routeName];
	// 	}
	// 	return {};
	// }, [location, permission]);

	const navigate = useNavigate();
	const { date, page } = useListSearchParams();
	const dispatch = useAppDispatch();

	const [tableData, setTableData] = React.useState<ApiWorkOrders>({
		message: "",
		success: true,
		currentPage: 1,
		totalItems: 10,
		totalPages: 1,
		dataTable: []
	});

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const woNumberParam = queryParams.get("search?wo-number");
		if (woNumberParam) {
			setSearchWoNumber(woNumberParam);
		} else {
			setSearchWoNumber("");
		}
	}, [location.search]);

	const { bp } = useAppSelector((store) => store.batching);

	const { isLoading, refetch } = useQuery<ApiWorkOrders, ApiError>(
		["query-products", date, page, searchQWoNumber, bp?.id],
		async () => {
			return await OrderService.getAllWO(page, 15, {
				woNumber: searchQWoNumber,
				date,
				batchingPlantId: bp?.id
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res?.data?.map((el, index) => ({
					id: el.id,
					values: [
						{
							objectKey: "id",
							value: index + 1,
							type: "text",
							render: false
						},
						{
							objectKey: "No. WO",
							value: el.number,
							type: "text"
						},
						{
							objectKey: "Status",
							value: el?.status ?? "SUBMITTED",
							type: "text"
						},
						{
							objectKey: "Tanggal",
							value: moment(el.date).format("yyyy-MM-DD")
						},
						{
							objectKey: "No. DO",
							value: el.DeliveryOrder?.number,
							type: "text"
						},
						{
							objectKey: "No. SKU",
							value: el.SKU?.sku,
							type: "text"
						},
						{
							objectKey: "kuantitas",
							value:
								el.DeliveryOrder?.quantity ?? "-"
									? formatComma(
										`${el.DeliveryOrder?.quantity} M³`
									)
									: "0 M³",
							type: "text",
							align: "end"
						},
						{
							objectKey: "produk",
							value: el.DeliveryOrder?.Schedule?.SaleOrder
								?.Product?.name,
							type: "text"
						}
					]
				}));

				setTableData(() => ({
					...res,
					dataTable: (data as DataTable[]) ?? []
				}));
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const handleWoNumberSearch = (newValue: string) => {
		setSearchWoNumber(newValue);

		const queryParams = new URLSearchParams();
		queryParams.set("page", "1");

		if (newValue) {
			queryParams.set("search?wo-number", newValue);
		}

		navigate({
			pathname: location.pathname,
			search: queryParams.toString()
		});
	};

	return (
		<>
			<Header date={date} show />
			<Container className="mt--7" fluid>
				{/* Table */}
				<Row>
					<div className="col">
						<BMTable
							tableTitle=""
							header={
								<>
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											paddingBottom: "0.5rem"
										}}
									>
										<Form>
											<FormGroup
												style={{
													display: "flex",
													gap: "1rem"
												}}
											>
												<Input
													placeholder="NO. WO"
													value={searchWoNumber}
													onChange={(e) =>
														handleWoNumberSearch(
															e.target.value
														)
													}
												/>
											</FormGroup>
										</Form>
									</div>
								</>
							}
							columns={woColumns}
							tableData={tableData.dataTable as DataTable[]}
							totalItems={tableData.totalItems as number}
							currentPage={tableData.currentPage as number}
							// onDownload={onDownload}
							filterSales
							options={{
								onRowClick(rowData, rowMeta) {
									if (tableData && tableData.dataTable) {
										const id =
											tableData.dataTable[
												rowMeta.rowIndex
											]?.id;
										if (id !== undefined) {
											navigate(
												`/order/work-orders/${id}`
											);
										}
									}
								}
							}}
						/>
					</div>
				</Row>
			</Container>
		</>
	);
}
