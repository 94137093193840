import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";

// Define a type for the slice state
interface AlertState {
	isOpen: boolean;
	color:
		| "primary"
		| "danger"
		| "secondary"
		| "warning"
		| "info"
		| "light"
		| "dark"
		| "success"
		| "";
	body: React.ReactChild;
	isHideSideBar: boolean;
}

interface PayloadActionDialog {
	color:
		| "primary"
		| "danger"
		| "secondary"
		| "warning"
		| "info"
		| "light"
		| "dark"
		| "success"
		| "";
	body: React.ReactChild;
}

// Define the initial state using that type
const initialState: AlertState = {
	isOpen: false,
	color: "",
	body: "",
	isHideSideBar: false
};

export const dialogSlice = createSlice({
	name: "modal",
	initialState,
	reducers: {
		openAlert: (state, action: PayloadAction<PayloadActionDialog>) => {
			const {
				payload: { body, color }
			} = action;
			state.color = color;
			state.body = body;
			state.isOpen = true;

			return state;
		},
		closeAlert: (state) => {
			return {
				...state,
				isOpen: false
			};
		},
		toggleAlert: (state) => {
			state.isOpen = !state.isOpen;
			return state;
		},
		setIsHideSideBar: (state, action: PayloadAction<boolean>) => {
			state.isHideSideBar = action.payload;
		}
	}
});

export const { openAlert, closeAlert, toggleAlert, setIsHideSideBar } =
	dialogSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.alert;

export default dialogSlice.reducer;
