import React, { useMemo, useEffect } from "react";
import { Button, Container, Row } from "reactstrap";
// core components
import Header from "../../../components/Headers/Header";
import BTable from "../../../components/B/table";
import useListSearchParams from "../../../hooks/useQuery";
import { useMutation, useQuery } from "react-query";
import { InventoryService } from "../../../api/axios";
import Loading from "../../../components/B/loading";
import { getPriceFormatted } from "helper/product";
import { useLocalDialog } from "hooks";
import { LocalDialog, StatusChip } from "components";
import BForm from "components/B/form";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { getRouteName, routeMapping } from "routes";
import { Check, X } from "react-bootstrap-icons";
import BMTable from "components/B/BMTable";

// Column BMTable
const vehiclesColumn = [
	{
		name: "internalId",
		label: "NO. TM",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "plate_number",
		label: "NO. POLISI",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "status",
		label: "Status",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				return <StatusChip value={value} />;
			}
		}
	},
	{
		name: "is_gps",
		label: "GPS",
		options: {
			filter: false,
			sort: false,
			customBodyRender: (value: any) => {
				return value === true ? (
					<Check size={24} color="#2dce89" />
				) : (
					<X size={24} color="#f5365c" />
				);
			}
		}
	},
	{
		name: "gps_type",
		label: "Tipe GPS",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "device_id",
		label: "GPS ID",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "type",
		label: "TIPE",
		options: {
			filter: false,
			sort: false
		}
	}
];

const Vehicles = () => {
	const location = useLocation();
	const permission = useAppSelector((state) => state.user.permission);
	const vehiclePermission = useMemo(() => {
		const routeName = getRouteName(location.pathname, routeMapping);

		if (routeName) {
			return permission[routeName];
		}
		return {};
	}, [location, permission]);
	const [tableData, setTableData] = React.useState<Omit<ApiProducts, "data">>(
		{
			message: "",
			success: true,
			currentPage: 1,
			totalItems: 10,
			totalPages: 1,
			dataTable: []
		}
	);
	const { page } = useListSearchParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { isLoading, refetch } = useQuery<ApiVehicles, ApiError>(
		["query-vehicle", page],
		async () => {
			return await InventoryService.getAllVehicles(page, 15);
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res.data?.map((el, i) => ({
					id: el.id,
					// 'plateNumber', 'type', 'isGps', 'deviceId', 'internalId', 'status'
					values: [
						{
							objectKey: "id",
							value: el.id, //15 is size data, look axios service,
							type: "text",
							render: false
						},
						{
							objectKey: "internalId",
							value: el?.internalId ?? el?.internal_id,
							type: "text"
						},
						{
							objectKey: "plate_number",
							value: el?.plateNumber ?? el?.plate_number,
							type: "text"
						},
						{
							objectKey: "status",
							value: el.status,
							type: "status"
						},
						{
							objectKey: "is_gps",
							value: el.is_gps,
							type: "boolean"
						},
						{
							objectKey: "type",
							value: el.type,
							type: "text"
						},
						{
							objectKey: "device_id",
							value: el.device_id,
							type: "text"
						},
						{
							objectKey: "gps_type",
							value: el.gps_type ? el.gps_type : "-",
							type: "text"
						}
					]
				}));

				setTableData(() => ({
					...res,
					dataTable: (data as DataTable[]) ?? []
				}));
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const [state, setState] = React.useState<IVehiclePayload>({
		internalId: "",
		isGps: false,
		plateNumber: "",
		status: "",
		type: "",
		gpsType: "",
		device_id: ""
	});
	const { localDialog, setLocalDialog } = useLocalDialog();

	const clearFormFields = () => {
		setState({
			internalId: "",
			isGps: false,
			plateNumber: "",
			status: "",
			type: "",
			gpsType: "",
			device_id: ""
		});
	};

	const forms: ElementForm[] = React.useMemo(() => {
		const _form: ElementForm[] = [
			{
				label: `NO. TM`,
				type: "text",
				value: state?.internalId,
				onChange: (e: any) =>
					setState({
						...state,
						internalId: e.target.value
					}),
				placeholder: "Masukan Nomer TM",
				required: true
			},
			{
				label: "No. Polisi",
				type: "text",
				value: state?.plateNumber,
				onChange: (e: any) =>
					setState({
						...state,
						plateNumber: e.target.value
					}),
				placeholder: "Masukan Plate Number",
				required: true
			},
			{
				label: "GPS",
				type: "switch",
				value: state?.isGps,
				onChange: (e: any) => {
					const newState = {
						...state,
						isGps: e.target.checked
					};
					if (!e.target.checked) {
						newState.gpsType = "";
						newState.device_id = "";
					}
					setState(newState);
				}
			}
		];

		if (state.isGps) {
			_form.push({
				label: "Tipe Gps",
				type: "dropdown",
				value: state?.gpsType,
				onChange: (e: any) =>
					setState({
						...state,
						gpsType: e
					}),
				placeholder: "Pilih Tipe",
				required: true,
				options: [
					{
						valueStr: "HINO",
						labelStr: "HINO"
					},
					{
						valueStr: "GPS_KU",
						labelStr: "GPS KU"
					},
					{
						valueStr: "PROVIEW",
						labelStr: "PROVIEW"
					}
				]
			});
			_form.push({
				label: "Nomor GPS",
				type: "text",
				value: `${state?.device_id ?? ""}`,
				onChange: (e: any) =>
					setState({
						...state,
						device_id: e.target.value
					}),
				placeholder: "Masukan Device ID",
				required: true
			});
		}

		return _form;

		// return [
		// 	{
		// 		label: "Nopol",
		// 		type: "text",
		// 		value: state?.plateNumber,
		// 		placeholder: "Tulis Nopol",
		// 		onChange: (e: any) => {
		// 			setState({
		// 				...state,
		// 				plateNumber: e.target.value
		// 			});
		// 		}
		// 	},
		// 	{
		// 		label: "Ada GPS?",
		// 		type: "switch",
		// 		value: state?.isGps,
		// 		placeholder: "Pilih File",
		// 		onChange: (e: any) => {
		// 			setState({
		// 				...state,
		// 				isGps: state?.isGps ? false : true
		// 			});
		// 		}
		// 	},
		// 	{
		// 		label: "Device Id",
		// 		type: "text",
		// 		value: state?.deviceId,
		// 		placeholder: "Tulis Device Id",
		// 		onChange: (e: any) => {
		// 			setState({
		// 				...state,
		// 				deviceId: e.target.value
		// 			});
		// 		}
		// 	},
		// 	{
		// 		label: "No TM",
		// 		type: "text",
		// 		value: state?.internalId,
		// 		placeholder: "Tulis No TM",
		// 		onChange: (e: any) => {
		// 			setState({
		// 				...state,
		// 				internalId: e.target.value
		// 			});
		// 		}
		// 	}
		// ];
	}, [state]);

	const { mutate, isLoading: loadingCreate } = useMutation<
		ApiResponseVehicle,
		ApiError,
		IVehiclePayload,
		ApiError
	>(
		async (data: IVehiclePayload) => {
			return await InventoryService.createVehicle(data);
		},
		{
			onSuccess: (res) => {
				localDialog.onClose();
				refetch();
			},
			onError: (err) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const onSubmit = () => {
		if (!state.internalId || !state.plateNumber) {
			dispatch(
				openAlert({
					body: "Semua field harus diisi",
					color: "danger"
				})
			);
			return;
		}

		if (!state.isGps) {
			setState({
				...state,
				gpsType: "",
				device_id: ""
			});

			const stateToSend = {
				...state,
				type: "Truck Mixer",
				status: "AWAITING",
				gpsType: "-",
				deviceId: "-"
			};

			mutate(stateToSend);
		} else if (!state.gpsType || !state.device_id) {
			dispatch(
				openAlert({
					body: "Semua field harus diisi",
					color: "danger"
				})
			);
			return;
		} else {
			const stateToSend = {
				...state,
				type: "Truck Mixer",
				status: "AWAITING",
				gpsType: state.gpsType?.valueStr,
				deviceId: state.device_id
			};

			mutate(stateToSend);
		}
	};

	if (isLoading || loadingCreate) {
		return <Loading />;
	}

	return (
		<>
			<Header />
			{/* Page content */}
			<Container
				style={{
					position: "relative",
					top: "-10rem"
				}}
				fluid
			>
				{/* Table */}
				<Row>
					<div className="col">
						<BMTable
							header={
								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										paddingBottom: "0.5rem"
									}}
								>
									<Button
										onClick={() => {
											clearFormFields();
											localDialog.onOpen();
											console.log("first");
										}}
										size="sm"
										color="success"
										className="confirm-button approve-button"
									>
										Buat Kendaraan
									</Button>
								</div>
							}
							tableTitle=""
							columns={vehiclesColumn}
							tableData={tableData.dataTable as DataTable[]}
							totalItems={tableData.totalItems as number}
							currentPage={tableData.currentPage as number}
							hideFilter
							options={{
								onRowClick(rowData, rowMeta) {
									if (tableData && tableData.dataTable) {
										const id =
											tableData.dataTable[
												rowMeta.rowIndex
											]?.id;
										if (id !== undefined) {
											navigate(
												`/database/vehicles/${id}`
											);
										}
									}
								}
							}}
						/>
						{/* <BTable
							header={
								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										paddingBottom: "0.5rem"
									}}
								>
									{vehiclePermission &&
									vehiclePermission.create ? (
										<Button
											onClick={() => {
												localDialog.onOpen();
											}}
											size="sm"
											color="success"
											className="confirm-button approve-button"
										>
											Kendaraan Baru
										</Button>
									) : null}
								</div>
							}
							hideFilter={true}
							currentPage={page}
							totalPage={tableData.totalPages as number}
							totalItem={tableData.totalItems as number}
							columns={[
								{
									key: "internal_id",
									value: "No. TM"
								},
								{
									key: "plate_number",
									value: "No. Polisi"
								},
								{
									key: "status",
									value: "Status"
								},
								{
									key: "is_gps",
									value: "Ada GPS?"
								},
								{
									key: "type",
									value: "Tipe"
								}
							]}
							data={tableData.dataTable as DataTable[]}
						/> */}
					</div>
				</Row>
			</Container>
			<LocalDialog
				isOpen={localDialog.isOpen}
				backdrop={"static"}
				header="Buat Kendaraan Baru"
				onClick={onSubmit}
				onClose={() => {
					localDialog.onClose();
				}}
			>
				<BForm forms={forms} />
			</LocalDialog>
		</>
	);
};

export default Vehicles;
