import React, { useMemo, useState, useEffect } from "react";
import { Button, Container, Row, Form, FormGroup, Input } from "reactstrap";
// core components
import Header from "../../../components/Headers/Header";
import useListSearchParams from "../../../hooks/useQuery";
import { useMutation, useQuery } from "react-query";
import { InventoryService } from "../../../api/axios";
import Loading from "../../../components/B/loading";
import { getPriceFormatted } from "helper/product";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import BMTable from "components/B/BMTable";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalDialog } from "hooks";
import { LocalDialog } from "components";
import BForm from "components/B/form";
import { getRouteName, routeMapping } from "routes";
import { useSelector } from "react-redux";
import { useDebounce } from "use-debounce";

// Column BMTable
const productColumn = [
	{
		name: "no",
		label: "NO",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "display_name",
		label: "Nama",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "category.parentName",
		label: "Kategori",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "category.name",
		label: "Sub Kategori",
		options: {
			filter: false,
			sort: false
		}
	},
	{
		name: "basePrice",
		label: "Harga Awal",
		options: {
			filter: false,
			sort: false
		}
	}
];

const Products = () => {
	const { bp } = useAppSelector((store) => store.batching);
	const [tableData, setTableData] = React.useState<Omit<ApiProducts, "data">>(
		{
			message: "",
			success: true,
			currentPage: 1,
			totalItems: 10,
			totalPages: 1,
			dataTable: []
		}
	);
	const navigate = useNavigate();
	const { page } = useListSearchParams();

	// permission create product
	const location = useLocation();
	const [searchProductName, setSearchProductName] = useState<string>();
	const [searchQProdName] = useDebounce(searchProductName, 500);
	const [searchCategory, setSearchCategory] = useState<string>();
	const [searchQCategory] = useDebounce(searchCategory, 500);
	const permission = useAppSelector((state) => state.user.permission);
	const productPermission = useMemo(() => {
		const routeName = getRouteName(location.pathname, routeMapping);

		if (routeName) {
			return permission[routeName];
		}
		return {};
	}, [location, permission]);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const prodNameParam = queryParams.get("search?product-name");
		const categoryParam = queryParams.get("search?sub-category");
		if (prodNameParam) {
			setSearchProductName(prodNameParam);
		} else {
			setSearchProductName("");
		}
		if (categoryParam) {
			setSearchCategory(categoryParam);
		} else {
			setSearchCategory("");
		}
	}, [location.search]);

	const dispatch = useAppDispatch();
	const { isLoading, refetch } = useQuery<ApiProducts, ApiError>(
		["query-products", searchQProdName, searchQCategory, page, bp?.valueStr],
		async () => {
			return await InventoryService.getAllProducts({
				search: searchQProdName,
				category: searchQCategory,
				page,
				batchingPlantId: bp?.valueStr
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res.data?.map((el, i) => ({
					id: el.id,
					values: [
						{
							objectKey: "id",
							value: el.id, //15 is size data, look axios service,
							type: "text",
							render: false
						},
						{
							objectKey: "no",
							value: i + 1 + 15 * (page - 1), //15 is size data, look axios service,
							type: "text"
						},
						{
							objectKey: "display_name",
							value: el.display_name,
							type: "text"
						},
						{
							objectKey: "category.parentName",
							value: el.Category.Parent.name,
							type: "text"
						},
						{
							objectKey: "category.name",
							value: el.Category.name,
							type: "text"
						},
						{
							objectKey: "basePrice",
							value: getPriceFormatted(
								el.Price.price || 0,
								"Rp "
							),
							type: "number",
							align: "end"
						}
					]
				}));

				setTableData(() => ({
					...res,
					dataTable: (data as DataTable[]) ?? []
				}));
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const handleProductNameSearch = (newValue: string) => {
		setSearchProductName(newValue);

		const queryParams = new URLSearchParams();
		queryParams.set("page", "1");

		if (newValue) {
			queryParams.set("search?product-name", newValue);
		}

		if (searchCategory) {
			queryParams.set("search?sub-category", searchCategory);
		}

		navigate({
			pathname: location.pathname,
			search: queryParams.toString(),
		});
	};

	const handleCategorySearch = (newValue: string) => {
		setSearchCategory(newValue);

		const queryParams = new URLSearchParams();
		queryParams.set("page", "1");

		if (searchProductName) {
			queryParams.set("search?product-name", searchProductName);
		}

		if (newValue) {
			queryParams.set("search?sub-category", newValue);
		}

		navigate({
			pathname: location.pathname,
			search: queryParams.toString(),
		});
	};

	const [optionCategory, setOptionCategory] = useState<any[]>([
		{
			labelStr: "NFA",
			valueStr: "NFA"
		},
		{
			labelStr: "FA",
			valueStr: "FA"
		}
	]);

	const [optionSKUS, setOptionSKUS] = useState<any[]>([]);
	useQuery<SKUDropdown, ApiError>(
		["query-skus"],
		async () => {
			return await InventoryService.getAllSKU({
				page
			});
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const data = res?.data?.map((el) => {
					return {
						...el,
						id: el.id,
						valueStr: el.id,
						labelStr: el.sku
					};
				});
				setOptionSKUS(data);
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const [payloadProduct, setPayloadProduct] = useState<IProductPayload>({
		skus: []
	});
	const { localDialog, setLocalDialog } = useLocalDialog();

	const forms: ElementForm[] = React.useMemo(() => {
		const _form: ElementForm[] = [
			{
				label: "Nama Produk",
				type: "text",
				value: payloadProduct.name,
				onChange: (e: any) => {
					setPayloadProduct({
						...payloadProduct,
						name: e.target.value
					});
				},
				placeholder: "Masukkan Nama Produk",
				required: true
			},
			{
				label: "Kategori Produk",
				type: "dropdown",
				value: payloadProduct.category,
				onChange: (e: any) => {
					setPayloadProduct({
						...payloadProduct,
						category: e
					});
				},
				placeholder: "Pilih Kategori Produk",
				options: optionCategory,
				required: true
			},
			// {
			// 	label: "Slump",
			// 	type: "text",
			// 	value: payloadProduct?.properties?.slump,
			// 	onChange: (e: any) => {
			// 		setPayloadProduct((prevPayload) => ({
			// 			...prevPayload,
			// 			properties: {
			// 				...prevPayload.properties,
			// 				fc: "",
			// 				fs: "",
			// 				sc: "",
			// 				slump: parseFloat(e.target.value)
			// 			}
			// 		}));
			// 	},
			// 	placeholder: "Masukkan Properti Slump",
			// 	required: true
			// },
			{
				label: "SKU",
				type: "dropdown",
				value: payloadProduct.skus,
				onChange: (e: any) => {
					setPayloadProduct({
						...payloadProduct,
						skus: e
					});
				},
				placeholder: "Pilih SKU",
				options: optionSKUS,
				isMulti: true
				// required: true
			},
			{
				label: "Harga",
				type: "text",
				value: payloadProduct?.price,
				onChange: (e: any) => {
					const newPrice = parseFloat(e.target.value);
					setPayloadProduct({
						...payloadProduct,
						price: !isNaN(newPrice) ? newPrice : 0
					});
				},
				placeholder: "Masukkan Harga Produk",
				required: true
			}
		];
		return _form;
	}, [payloadProduct]);

	const { mutate, isLoading: loadingCreate } = useMutation<
		ApiResponseProduct,
		ApiError,
		IProductPayload,
		ApiError
	>(
		async (data: IProductPayload) => {
			return await InventoryService.createProduct(data);
		},
		{
			onSuccess: (res) => {
				localDialog.onClose();
				refetch();
			},
			onError: (err) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const onSubmit = () => {
		const payload = {
			...payloadProduct,
			category: payloadProduct?.category?.valueStr,
			skus: payloadProduct?.skus?.map((sku) => sku.valueStr),
			unit: "M3",
			batchingPlantId: bp.valueStr
		};
		mutate(payload);
	};

	const resetPayloadProduct = () => {
		setPayloadProduct({
			skus: [],
			name: "",
			category: null,
			price: 0
		});
	};

	// if (isLoading) {
	// 	return <Loading />;
	// }

	return (
		<>
			<Header />
			{/* Page content */}
			<Container
				style={{
					position: "relative",
					top: "-10rem"
				}}
				fluid
			>
				{/* Table */}
				<Row>
					<div className="col">
						<BMTable
							tableTitle=""
							header={
								<>
									{productPermission &&
										productPermission.create ? (
										<Button
											style={{ float: "right" }}
											onClick={() => {
												localDialog.onOpen();
											}}
											size="sm"
											color="success"
											className="confirm-button approve-button"
										>
											Buat Produk
										</Button>
									) : null}
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											paddingBottom: "0.5rem"
										}}
									>
										<Form>
											<FormGroup
												style={{
													display: "flex",
													gap: "1rem"
												}}
											>
												<Input
													placeholder="NAMA PRODUK"
													value={searchProductName}
													onChange={(e) => handleProductNameSearch(e.target.value)}
												/>
												<Input
													placeholder="SUB KATEGORI"
													value={searchCategory}
													onChange={(e) => handleCategorySearch(e.target.value)}
												/>
											</FormGroup>
										</Form>
									</div>
								</>

							}
							columns={productColumn}
							tableData={tableData.dataTable as DataTable[]}
							totalItems={tableData.totalItems as number}
							currentPage={tableData.currentPage as number}
							options={{
								onRowClick(rowData, rowMeta) {
									if (tableData && tableData.dataTable) {
										const id =
											tableData.dataTable[
												rowMeta.rowIndex
											]?.id;
										if (id !== undefined) {
											navigate(
												`/database/products/${id}`
											);
										}
									}
								}
							}}
						/>
						{/* <BTable
							currentPage={page}
							totalPage={tableData.totalPages as number}
							totalItem={tableData.totalItems as number}
							columns={[
								{
									key: "id",
									value: "No"
								},
								{
									key: "name",
									value: "Name"
								},
								{
									key: "category",
									value: "Category"
								},
								{
									key: "sub",
									value: "Sub Category"
								},
								{
									key: "price",
									value: "Base Price",
									align: "end"
								}
							]}
							data={tableData.dataTable as DataTable[]}
							disabled
						/> */}
					</div>
				</Row>
			</Container>

			<LocalDialog
				isOpen={localDialog.isOpen}
				backdrop={"static"}
				header="Buat Produk Baru"
				onClick={onSubmit}
				onClose={() => {
					resetPayloadProduct();
					localDialog.onClose();
				}}
			>
				<BForm forms={forms} />
			</LocalDialog>
		</>
	);
};

export default Products;
