import React from "react";
import styles from "./index.module.scss";
import BStatus from "components/B/status";
import BSwitch from "components/B/Switch";

const LiveVehicleStatus = (props: any) => {
	console.log(props);
	return (
		<div
			className={[styles.mainContainer, props.className].join(" ")}
			style={{ "--color": props?.color } as React.CSSProperties}
		>
			<div
				className={[styles.headerContainer, styles.lineContainer].join(
					" "
				)}
			>
				<div className={styles.number}>
					<p>{props.internalId}</p>
					{(!!props.do?.BatchingPlant?.code || (!!props.selectedGpsType && props.gpsData?.[props.selectedGpsType]?.BatchingPlant?.code)) && (
						<BStatus>{props.do?.BatchingPlant?.code || props.gpsData?.[props.selectedGpsType]?.BatchingPlant?.code}</BStatus>
					)}
				</div>
				<BStatus>{props.do?.status || "INACTIVE"}</BStatus>
			</div>
			{/* {props.do && <p>{JSON.stringify(props.do, null, 2)}</p>} */}
			{props.do && (
				<div className={styles.doContainer}>
					<div className={styles.displayNameContainer}>
						<div className={styles.displayNameColor} />
						<a className={styles.link} href={`${window.location.origin}/database/customers/${props.do?.Project?.Customer?.id}`} target="_blank" rel="noreferrer">
							{props.do?.Project?.Customer?.displayName}
						</a>
					</div>
					{/* <p>{props.do?.updatedAt}</p> */}
				</div>
			)}
			{props.do && (
				<div className={styles.doContainer}>
					<div className={styles.lineContainer}>
						<div className={styles.number}>
							<a className={styles.link} href={`${window.location.origin}/order/delivery-orders/${props.do?.id}`} target="_blank" rel="noreferrer">
								{props.do?.number}
							</a>
						</div>
						<p>
							{props.do?.quantity} m<sup>3</sup>
						</p>
					</div>
					{/* <p>{props.do?.updatedAt}</p> */}
				</div>
			)}
			{props.hasCamera && (
				<div className={styles.lineContainer}>
					<p>Lihat Kamera</p>
					<BSwitch
						className={styles.switch}
						color="primary"
						checked={props.camShown}
						onChange={(_: any, c: any) => {
							props.onToggleShowCam(props.id, !props.camShown);
						}}
						disabled={props.camLoading || !props?.gpsData?.PROVIEW}
						inputProps={{ "aria-label": "controlled" }}
						switchType="camera"
					/>
				</div>
			)}
			{props.isGps && (
				<div className={styles.lineContainer}>
					<div className={styles.number}>
						<p>Lihat GPS</p>
						{props.gpsTypes?.length && (
							<select
								className={styles.select}
								value={props.selectedGpsType}
								onChange={(e) => {
									props.onChangeSelectedGpsType(
										props.id,
										e.target.value
									);
								}}
							>
								{props.gpsTypes?.includes("HINO") && (
									<option value="HINO">Hino</option>
								)}
								{props.gpsTypes?.includes("GPS_KU") && (
									<option value="GPS_KU">GPS Ku</option>
								)}
								{props.gpsTypes?.includes("TRACKSOLID") && (
									<option value="TRACKSOLID">
										Tracksolid
									</option>
								)}
								{props.gpsTypes?.includes("PROVIEW") && (
									<option value="PROVIEW">MDVR (Proview)</option>
								)}
							</select>
						)}
					</div>
					<BSwitch
						className={styles.switch}
						color="primary"
						checked={props.gpsShown}
						onChange={(_: any, c: any) => {
							props.onToggleShowGps(props.id, !props.gpsShown);
						}}
						inputProps={{ "aria-label": "controlled" }}
						switchType="map"
					/>
				</div>
			)}
		</div>
	);
};

export default LiveVehicleStatus;
