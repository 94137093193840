import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { LocalDialog } from "components";
import { useLocalDialog } from "hooks";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { useMutation, useQuery } from "react-query";
import { CommonService } from "api/axios";
import { useAppDispatch } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import { useParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDebounce } from "use-debounce";
import Select from "react-select";

const SignupSchema = Yup.object().shape({
	displayName: Yup.string(),
	// .min(4, "Minimum 4 karakter")
	// .max(50, "Terlalu Panjang")
	// .required("Dibutuhkan"),
	type: Yup.string(),
	// .required("Dibutuhkan"),
	nik: Yup.string(),
	// .required("NIK Di butuhkan"),
	// .matches(/^\d{16}$/, "NIK harus 16 digit"),
	npwp: Yup.string(),
	// .min(15, "NPWP minimal 15 karakter")
	// .required("NPWP Dibutuhkan"),
	email: Yup.string().notRequired(),
	// .email("Email Invalid").notRequired(),
	name: Yup.string(),
	// .min(4, "Minimum 4 Karakter")
	// .max(50, "Terlalu Panjang")
	// .required("Dibutuhkan"),
	position: Yup.string(),
	// .max(50, "Terlalu Panjang").required("Dibutuhkan"),
	phone: Yup.string(),
	// .matches(/^\d+$/, "Nomor telepon harus angka")
	// .max(50, "Terlalu Panjang")
	// .required("Dibutuhkan"),
	address: Yup.object(),
	// .required("Dibutuhkan"),
	description: Yup.string()
	// .notRequired()
});

export default function EditDetailModal({
	isOpen,
	setIsOpen,
	customerDetail,
	refetch,
	picDetail,
	billingAddress
}: {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	customerDetail: {
		displayName: string;
		type: "COMPANY" | "INDIVIDU";
		ktp: string;
		npwp: string;
	};
	billingAddress: {
		line1: string;
		line2: string;
		lat: string;
		lon: string;
		id: string;
		postalId: number;
	};
	picDetail: {
		name: string;
		position: string;
		phone: string;
		email: string;
		id: string;
	};
	refetch: () => void;
}) {
	const params = useParams();
	const id = params?.id;
	const { localDialog } = useLocalDialog();
	const dispatch = useAppDispatch();

	const [text, setText] = useState<string>("");
	const [searchGoogle] = useDebounce(text, 500);

	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		} else {
			localDialog.onClose();
		}
	}, [isOpen]);

	const { mutateAsync: updateCustomer } = useMutation<
		ApiResponseCustomer,
		ApiError,
		PayloadCustomer,
		ApiError
	>(
		async (data: PayloadCustomer) => {
			const res = await CommonService.updateCustomer(id as string, data);
			return res;
		},
		{
			onSuccess: (res) => {
				localDialog.onClose();
				dispatch(
					openAlert({
						body: res.message,
						color: "success"
					})
				);
				// setIsLoading(false);
				setIsOpen(false);

				// navigate("/database/customers");
			},
			onError: (err) => {
				// setIsLoading(false);
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const { mutateAsync: updatePic } = useMutation<
		ApiCreatePicResponse,
		ApiError,
		PayloadCreatePIC,
		ApiError
	>(async (data: PayloadCreatePIC) => {
		const res = await CommonService.updatePIC(picDetail.id as string, data);
		return res;
	});

	const { data: places } = useQuery<ApiPlaces, ApiError>(
		["query-places", searchGoogle],
		async () => {
			return CommonService.getPlaces(searchGoogle as string);
		},
		{
			enabled: searchGoogle.length ? true : false
		}
	);

	const { mutateAsync: updateAddress } = useMutation<
		ApiResponseCreateAddress,
		ApiError,
		PayloadCreateAddress,
		ApiError
	>(async (data: PayloadCreateAddress) => {
		const res = await CommonService.updateAddress(
			billingAddress.id as string,
			data
		);
		return res;
	});

	const { mutateAsync: createAddress } = useMutation<
		ApiResponseCreateAddress,
		ApiError,
		PayloadCreateAddress,
		ApiError
	>(async (data: PayloadCreateAddress) => {
		const res = await CommonService.createAddress(data);
		return res;
	});

	const { mutateAsync: createPic } = useMutation<
		ApiCreatePicResponse,
		ApiError,
		PayloadCreatePIC,
		ApiError
	>(async (data: PayloadCreatePIC) => {
		const res = await CommonService.createPIC(data);
		return res;
	});

	const optionPlaces = React.useMemo(() => {
		if (places?.result.length) {
			const data = places.result.map((el) => {
				// console.log(el, "element");

				return {
					...el,
					valueStr: el.place_id,
					labelStr: el.description
				};
			});

			return data;
		}

		// if (billingAddress?.line1) {
		// 	const selected = billingAddress.line1;
		// 	return [
		// 		{
		// 			valueStr: selected?.valueStr,
		// 			labelStr: selected?.labelStr
		// 		}
		// 	];
		// }

		return [];
	}, [places]);

	const labelStyle = {
		marginTop: "1em"
	};

	return (
		<LocalDialog
			isOpen={localDialog.isOpen}
			backdrop={true}
			header="Ubah Detail Pelanggan"
			onClose={() => {
				// localDialog.onClose();
				setIsOpen(false);
			}}
			rightBtnTitle="Edit"
			leftBtnTitle="Batalkan"
		>
			<Formik
				initialValues={{
					displayName: customerDetail.displayName,
					type: customerDetail.type,
					nik: customerDetail.ktp,
					npwp: customerDetail.npwp,
					name: picDetail.name,
					position: picDetail.position,
					phone: picDetail.phone,
					email: picDetail.email,
					address: {
						labelStr: billingAddress.line1,
						valueStr: `${billingAddress.id}`
					},
					description: billingAddress.line2
				}}
				validationSchema={SignupSchema}
				onSubmit={async (values, { setSubmitting }) => {
					// same shape as initial values
					setSubmitting(true);
					// setTimeout(() => {
					// console.log(values, "onSubmitvalue");
					// 	setSubmitting(false);
					// }, 2000);
					// setIsLoading(true);

					try {
						let placeResp;
						if (values.address.valueStr !== "undefined") {
							placeResp = await CommonService.getPlaceDetail(
								values.address.valueStr as string
							);
						}
						let newBillingAddressId = "";
						if (billingAddress.id) {
							await updateAddress({
								line1: values.address.labelStr,
								id: billingAddress.id,
								postalId:
									placeResp?.result?.PostalId ??
									billingAddress.postalId,
								line2: values.description,
								lat:
									placeResp?.result?.lat ??
									billingAddress.lat,
								lon:
									placeResp?.result?.lon ?? billingAddress.lon
							});
						} else {
							if (values.address.labelStr) {
								const addressResp = await createAddress({
									line1: values.address.labelStr,
									// id: billingAddress.id,
									postalId: placeResp?.result?.PostalId,
									line2: values.description,
									lat: placeResp?.result?.lat,
									lon: placeResp?.result?.lon
								});
								newBillingAddressId = addressResp.data
									.id as string;
							}
						}
						// }
						let newPicId = "";
						if (picDetail.id) {
							await updatePic({
								name: values.name,
								position: values.position,
								type: "PROJECT",
								phone: values.phone,
								email: values.email
							});
						} else {
							if (
								values.name &&
								values.position &&
								values.phone
							) {
								const picResponse = await createPic({
									name: values.name,
									position: values.position,
									type: "PROJECT",
									phone: values.phone,
									email: values.email
								});
								newPicId = picResponse.data.id as string;
							}
						}
						const payloadUpdateCustomer = {
							...values,
							name: values.displayName,
							type: values.type as "COMPANY" | "INDIVIDU"
						} as PayloadCustomer;
						if (newBillingAddressId) {
							payloadUpdateCustomer.billingAddressId =
								newBillingAddressId;
						}
						if (newPicId) {
							payloadUpdateCustomer.picId = newPicId;
						}
						await updateCustomer(payloadUpdateCustomer);
						setSubmitting(false);
						refetch();
					} catch (error) {
						console.log(error, "error update customer");

						dispatch(
							openAlert({
								body: "Gagal memperbarui data pelanggan",
								color: "danger"
							})
						);
					}
				}}
			>
				{({ errors, handleChange, isSubmitting, values }) => {
					return (
						<Form>
							<FormGroup>
								<Label htmlFor="displayName" style={labelStyle}>
									Nama
								</Label>
								<Input
									name="displayName"
									invalid={!!errors.displayName}
									onChange={handleChange}
									value={values.displayName}
								/>
								<FormFeedback invalid={!!errors.displayName}>
									{errors.displayName}
								</FormFeedback>
								<div className="input-style">
									<Label style={labelStyle}>
										Tipe Pelanggan
									</Label>
									<Field
										component="select"
										name="type"
										onChange={handleChange}
										className="form-control"
										multiple={false}
										value={values.type}
									>
										<option value="COMPANY">
											PERUSAHAAN
										</option>
										<option value="INDIVIDU">
											INDIVIDU
										</option>
									</Field>
								</div>
								<FormFeedback invalid={!!errors.type}>
									{errors.type}
								</FormFeedback>
								<Label style={labelStyle}>KTP</Label>
								<Input
									name="nik"
									invalid={!!errors.nik}
									onChange={handleChange}
									value={values.nik}
								/>
								<FormFeedback invalid={!!errors.nik}>
									{errors.nik}
								</FormFeedback>
								<Label style={labelStyle}>NPWP</Label>
								<Input
									name="npwp"
									invalid={!!errors.npwp}
									onChange={handleChange}
									value={values.npwp}
								/>
								<FormFeedback invalid={!!errors.npwp}>
									{errors.npwp}
								</FormFeedback>
							</FormGroup>
							<div className="dropdown-divider"></div>
							PIC
							<FormGroup>
								<Label style={labelStyle}>Nama</Label>
								<Input
									name="name"
									invalid={!!errors.name}
									onChange={handleChange}
									value={values.name}
								/>
								<FormFeedback invalid={!!errors.name}>
									{errors.name}
								</FormFeedback>
								<Label style={labelStyle}>Posisi</Label>
								<Input
									name="position"
									invalid={!!errors.position}
									onChange={handleChange}
									value={values.position}
								/>
								<FormFeedback invalid={!!errors.position}>
									{errors.position}
								</FormFeedback>
								<Label style={labelStyle}>No. Telp</Label>
								<Input
									name="phone"
									invalid={!!errors.phone}
									onChange={handleChange}
									value={values.phone}
								/>
								<FormFeedback invalid={!!errors.phone}>
									{errors.phone}
								</FormFeedback>
								<Label style={labelStyle}>Email</Label>
								<Input
									name="email"
									invalid={!!errors.email}
									onChange={handleChange}
									value={values.email}
								/>
								<FormFeedback invalid={!!errors.email}>
									{errors.email}
								</FormFeedback>
							</FormGroup>
							<div className="dropdown-divider"></div>
							<FormGroup>
								<Label>Alamat </Label>
								<Select
									onInputChange={(text) => {
										setText(text);
									}}
									onChange={(change) => {
										// console.log(change, "change");
										if (change) {
											values.address.labelStr =
												change?.labelStr as string;
											values.address.valueStr =
												change.valueStr as string;
										}
									}}
									// onChange={handleChange}
									options={optionPlaces}
									getOptionLabel={(option: options) =>
										option.labelStr as string
									}
									getOptionValue={(option: options) =>
										option.valueStr as string
									}
									name="address"
									placeholder="Tulis alamat"
									value={values.address}
								/>
								<FormFeedback invalid={!!errors.address}>
									{/* {errors.address} */}
								</FormFeedback>
								<Label style={labelStyle}>Alamat Lengkap</Label>
								<Input
									name="description"
									invalid={!!errors.description}
									onChange={handleChange}
									value={values.description}
								/>
								{/* <Field
									name="description"
									as="textarea"
									className="form-textarea"
								/> */}
								<FormFeedback invalid={!!errors.description}>
									{errors.description}
								</FormFeedback>
							</FormGroup>
							<div className="modal-custom-footer">
								<LoadingButton
									color="error"
									onClick={() => {
										setIsOpen(false);
									}}
									loading={isSubmitting}
									disabled={isSubmitting}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Batal
									</span>
								</LoadingButton>
								<LoadingButton
									color="warning"
									variant="contained"
									type="submit"
									loading={isSubmitting}
									disabled={isSubmitting}
									// sx={{ backgroundColor: "red", color: "white" }}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Ubah
									</span>
								</LoadingButton>
							</div>
						</Form>
					);
				}}
			</Formik>
		</LocalDialog>
	);
}
